.ImportProvider .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ImportProvider {
  width: calc(100% - 250px);
}
.ImportProvider .fileupload {
  position: relative;
  margin-right: 9px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.ImportProvider .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.ImportProvider .fileupload .fileupload-btn button:focus {
  outline: 0;
}

.ImportProvider .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.ImportProvider .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  width: 161px;
}
.ImportProvider .note {
  padding: 5px;
}
.ImportProvider .note a {
  color: #26acda;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.ImportProvider .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.ImportProvider .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.ImportProvider .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}
