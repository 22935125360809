.LoadPage {
  padding: 60px 0px;
}
.LoadPage .LoadPage-inner {
  text-align: center;
}
.LoadPage .paragraph {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
  margin-bottom: 28px;
}

@media (max-width: 1024px) {
  .LoadPage .paragraph {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .LoadPage {
    padding: 40px 0px;
  }
  .LoadPage .paragraph {
    font-size: 22px;
    line-height: 1.06;
    margin-bottom: 15px;
  }
}
