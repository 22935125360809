.ResetPassword {
  padding: 120px 0;
}
.ResetPassword .reset-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.ResetPassword .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 18px;
  margin-bottom: 22px;
  font-family: 'Heldane Display';
}
.ResetPassword .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 37px;
}
.ResetPassword .reset-btn {
  margin-top: 48px;
}
.ResetPassword .reset-btn > button {
  width: 100%;
  height: 48px;
  font-family: 'Calibre Regular';
  line-height: 50px;
}

@media (max-width: 1024px) {
  .ResetPassword {
    padding: 90px 0;
  }
  .ResetPassword .h3 {
    font-size: 30px;
    margin-bottom: 15px;
    padding-bottom: 14px;
  }
  .ResetPassword .reset-btn {
    margin-top: 42px;
  }
  .ResetPassword .paragraph {
    margin-bottom: 33px;
  }
}
@media (max-width: 767px) {
  .ResetPassword {
    padding: 70px 0;
  }
  .ResetPassword .h3 {
    font-size: 28px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  .ResetPassword .paragraph {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .ResetPassword .reset-btn {
    margin-top: 30px;
  }
}
