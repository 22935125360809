.ProviderCare {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 63px 44px 34px 47px;
  margin-bottom: 30px;
}
.ProviderCare .ProvidercareFocus .heading3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: #013648;
  margin-bottom: 5px;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
  padding-right: 40px;
  position: relative;
}
.ProviderCare .ProvidercareFocus .sub-para {
  font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
    color: #b2bcc4;
    margin-bottom: 28px;
}
.ProviderCare .ProvidercareFocus .TagsInput .multiselectsearch-block {
  min-height: 147px;
  margin-bottom: 20px;
}
.ProviderCare .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  border-radius: 2px;
}
.ProviderCare .ProvidercareFocus {
  padding-bottom: 13px;
  margin-bottom: 41px;
  border-bottom: 1px solid #f1f1f5;
}
.ProviderCare .titleHeading {
  display: flex;
  justify-content: space-between;
}
.ProviderCare .commonSave {
  display: flex;
}
.ProviderCare .commonSave BUTTON {
  margin-left: auto;
}
.ProviderCare .SpecialitiesClose {
  position: absolute;
  right: 10px;
  top: 3px;
}
.ProviderCare .SpecialitiesClose img {
  width: 20px;
}
.ProviderCare .ProvidercareFocus .required-info {
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #f37184;
}
.signup-form .TagsInput .multiselect-result div.each-tag,
.provider-Specialities .TagsInput .multiselect-result div.each-tag{
   display: flex !important;
   gap: 20px;
}

@media screen and (max-width: 1366px) {
  .ProviderCare {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .ProviderCare .ProvidercareFocus .heading3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .ProviderCare {
    padding: 10px;
  }
}
