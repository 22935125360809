.SingleRating .name {
  font-size: 16px;
  line-height: 2.13;
  letter-spacing: -0.2px;
  color: #013648;
  /* width: 98px; */
  margin-right: 7px;
}
.SingleRating .number {
  width: 20px;
  height: 14px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 0.84;
  color: #013648;
  margin-left: 8px;
  display: inline-block;
  line-height: 1.3;
}
.SingleRating .progressbar {
  width: 94px;
  margin-left: auto;
}
.SingleRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1400px) {
  .SingleRating .name {
    font-size: 14px;
    width: 85px;
    margin-right: 5px;
  }
  .SingleRating .progressbar {
    width: 76px;
  }
  .SingleRating .name {
    width: 85px;
  }
  .SingleRating .number {
    font-size: 14px;
  }
  .KScore .all-score .rating-content {
    padding: 0px 6px;
  }
}
@media (max-width: 767px) {
  .SingleRating .progressbar {
    width: clac(100% - 78px);
  }
}

