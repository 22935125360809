.ConsultRequest .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ConsultRequest {
  width: calc(100% - 250px);
}
.ConsultRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.ConsultRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.uploadFiles img.photoid{
width: 50px;
max-height: 100px;
position: absolute;
cursor: pointer;
top: 12px;
}
.ConsultRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.ConsultRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ConsultRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.ConsultRequest .heading .cell:first-child {
  min-width: 150px;
}
.ConsultRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.ConsultRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.ConsultRequest .cell.edit-cell {
  width: 20px;
}
.ConsultRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.ConsultRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.ConsultRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.ConsultRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.ConsultRequest .pagination LI.previous.disabled,
.ConsultRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.ConsultRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.ConsultRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.ConsultRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.ConsultRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.ConsultRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.ConsultRequest .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.ConsultRequest .pointer {
  cursor: pointer;
}
.ConsultRequest img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.ConsultRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .ConsultRequest .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.ConsultRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.ConsultRequest .actionbtndev {
  display: grid;
}
.ConsultRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ConsultRequest .search .Select-control SELECT {
  height: 40px;
}
/* .ConsultRequest .input-control INPUT {
  height: 52px;
} */
.ConsultRequest .Select-control.form-group:after {
  display: none;
}
.ConsultRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .ConsultRequest .search div {
  position: relative;
} */
/* .ConsultRequest .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.ConsultRequest .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.ConsultRequest .search button.btn-clear IMG {
  width: 15px;
}
.ConsultRequest .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.ConsultRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.ConsultRequest .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ConsultRequest .search DIV {
  position: relative;
}
.ConsultRequest .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
  margin-top: 5px;
}
.ConsultRequest .btn-view {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.ConsultRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.ConsultRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.ConsultRequest .sub-header{
  color: #013648;
  font-size: 36px;
  margin-bottom: 0;
}
.ConsultRequest .imgtextdisplay{
  display: flex;
  gap: 5px;
  align-items: center;
}
.ConsultRequest .imgtextdisplay span{
margin-top: 5px;
}
.ConsultRequest .imgtextdisplay img{
width: 20px;
}
.edit-btn{
  border: none;
  background: none;
  margin-left: 5px;
  cursor: pointer;
}
.ConsultRequest .imgtextdisplay.success{
  background: green;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  color: white;
  justify-content: center;
  }
  .ConsultRequest .imgtextdisplay.error{
    background: red;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    color: white;
    justify-content: center;
    }
  .ConsultRequest .imgtextdisplay.success img{
    width: 15px;
  }
  .ConsultRequest .imgtextdisplay.error img{
    width: 15px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(106%) contrast(101%);
  }
.photoid-div{
  display: grid;
  min-width: 38px;
}
.ConsultRequest .actionbtndev button {
  margin-left: 0;
}
.ConsultRequest .search INPUT{
  padding: 3px 15px 0 25px;
    height: 40px;
    border-radius: 2px;
    border: 1.5px solid rgba(40,84,99,.2);
    background-color: #fff;
    font-size: 18px;
    font-family: Calibre Regular;
    line-height: 40px;
    letter-spacing: -.34px;
    color: #013648;
    -webkit-appearance: none
}
.ConsultRequest .search BUTTON {
  padding: 5px 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  height: 40px;
  font-weight: 400!important;
  line-height: 33px;
  background-color: #013648;
  color: #fff;
  cursor: pointer;
  border: 0;
}
.ConsultRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .ConsultRequest .refresh-btn {
    margin-right: 15px;
  }
}
