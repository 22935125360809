.ReadMore .review-pragraph {
  font-size: 18px;
  line-height: 1.28;
  color: #013648;
  margin-top: 18px;
}
.ReadMore .readmore-link {
  color: #26acda;
  cursor: pointer;
}
