.CalendarEvent.rbc-event {
  background-color: #fff9f2;
  border: solid 1px #e2e2ea;
  border-left: 8px solid #fca43d;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 5px 0px 0px 7px;
  overflow: visible;
}
.CalendarEvent.rbc-event.video-visit {
  border-left: 8px solid #8d77ba;
  background-color: hsl(262, 100%, 98%);
}
.CalendarEvent.rbc-event.video-visit.in-person-visit {
  border-left: 8px solid #00cec9;
  background-color: #e8ffff;
}
.CalendarEvent .main-event {
  font-family: 'Calibre Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 0.88;
  letter-spacing: 0.11px;
  color: #013648;
  height: 100%;
}
.CalendarEvent .main-event .icon {
  margin-bottom: 5px;
  display: inline-block;
  right: 5px;
  width: 16px;
  position: absolute;
}
.CalendarEvent .main-event .icon IMG {
  width: 100%;
}
.CalendarEvent .main-event .time {
  line-height: 16px;
  padding-right: 25px;
  white-space: nowrap;
}
.CalendarEvent .main-event .hover-content.show {
  display: block;
}
.CalendarEvent .main-event .hover-content {
  display: none;
  width: 197px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  position: relative;
  z-index: 9;
  top: -90px;
  left: 73px;
  background-color: #fff9f2;
  border-left: 8px solid#fca43d;
}
.CalendarEvent .main-event .hover-content.video-visit {
  border-left: 8px solid #8d77ba;
  background-color: #f8f4ff;
}
.CalendarEvent .main-event .hover-content.video-visit.in-person-visit {
  border-left: 8px solid #00cec9;
  background-color: #e8ffff;
}
.CalendarEvent .main-event .hover-content.no-edit {
  top: -37px;
}
.CalendarEvent .main-event .hover-content .hover-content-date {
  padding: 11px 10px 2px 11px;
  margin-bottom: 4px;
  cursor: auto;
}
.CalendarEvent .main-event .hover-content .day {
  font-family: 'Calibre Medium';
  font-weight: 500;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 0.09px;
  color: #013648;
  margin-bottom: -4px;
  display: inline-block;
}
.CalendarEvent .main-event .hover-content .icon {
  width: 12px;
  position: relative;
  left: 4px;
  top: 3px;
}
.CalendarEvent .main-event .hover-content .time {
  font-size: 15px;
  line-height: 1.33;
  color: #013648;
}
.CalendarEvent .main-event .hover-content .edit {
  font-size: 16px;
  color: #013648;
  background-color: #fff;
  padding: 19px 10px;
  border-bottom: 1px solid #fafafb;
}

@media (hover: hover) {
  .CalendarEvent .hover-content .edit:hover {
    background-color: #efefef;
  }
}

@media (max-width: 1400px) {
  .CalendarEvent .main-event .time {
    font-size: 14px;
  }
  .CalendarEvent .main-event .icon {
    width: 10px;
  }
}
