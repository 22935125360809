.ReviewConfirm {
  padding: 26px 0 0 66px;
}
.ReviewConfirm .h3 {
  font-size: 24px;
  line-height: 1.21;
  color: #013648;
}
.ReviewConfirm .h3 SPAN {
  font-family: 'Calibre Medium';
}
.ReviewConfirm FORM {
  width: 398px;
}
.ReviewConfirm .box-content {
  margin-bottom: 11px;
}
.ReviewConfirm .box-content.existing-patient {
  margin-bottom: 20px;
}
.ReviewConfirm .box-content .title {
  font-size: 16px;
  line-height: 1.89;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 2px;
  margin-top: 17px;
}
.ReviewConfirm .box-content .box-inner-content {
  border-radius: 2px;
  background-color: #f1f1f1;
  padding: 12px 25px 8px;
}
.ReviewConfirm .box-content .box-inner-content .name {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #013648;
  position: relative;
  padding-left: 0px;
  margin-bottom: 11px;
}
.ReviewConfirm .box-content .box-inner-content .name .Checkbox-control {
  position: relative;
  left: 0;
  top: -17px;
  width: 20px;
  margin-right: 0;
}
.ReviewConfirm .box-content .box-inner-content .choose-other {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.ReviewConfirm .box-content .box-inner-content .choose-other SPAN {
  color: #26acda;
  cursor: pointer;
}
.ReviewConfirm .box-content .box-inner-content .Checkbox-control {
  display: inline-block;
  margin-right: 21px;
  margin-bottom: 0;
}
.ReviewConfirm .box-content .box-inner-content .Checkbox-control:last-child {
  margin-right: 0;
}
.ReviewConfirm .select-insurance .form-group {
  margin-bottom: 12px;
}
.ReviewConfirm .select-insurance .disable {
  opacity: 0.34;
}
.ReviewConfirm .select-insurance .disable .form-control[disabled] {
  background-color: #f8f8f8;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.ReviewConfirm .select-insurance .disable + .arrow {
  opacity: 0.1;
}
.ReviewConfirm .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.ReviewConfirm .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
.ReviewConfirm .Select-control .plan + .arrow {
  top: 22px;
  right: 27px;
}
.ReviewConfirm .select-insurance .Select-control.error .insuranceCompanyId{
  margin-bottom: 10px;
 
}
.ReviewConfirm .select-insurance .Select-control.errorpading .insuranceCompanyId{
  color: #013648;
 
}
.ReviewConfirm .select-insurance .insuranceMemberId{
  margin-bottom: 30px;
}

.ReviewConfirm .select-insurance .error.errorpading{
  margin-bottom: 30px;
}

.ReviewConfirm .select-insurance .insuranceMemberId span.error-text{

  bottom: -25px;
}
.ReviewConfirm .Input-control .form-control {
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #18222a;
}
.ReviewConfirm .checkbox-block.privacy {
  margin-top: 32px;
}
.ReviewConfirm .checkbox-block {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #013648;
  padding-left: 30px;
  position: relative;
  margin-bottom: 18px;
}
.ReviewConfirm .checkbox-block A {
  color: #3dbce7;
}
.ReviewConfirm .checkbox-block .Checkbox-control {
  position: absolute;
  left: 0px;
}
.ReviewConfirm .action-btn .CircleButton.WhiteButton {
  width: 100%;
}
.ReviewConfirm .Textarea TEXTAREA {
  height: 64px;
}
.ReviewConfirm .appoitment-smtime {
  display: none;
}
.ReviewConfirm .appoitment-smtime .title {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  display: block;
}
.ReviewConfirm .appoitment-smtime .time-detail {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: block;
}
.ReviewConfirm .appoitment-smtime .view-details {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  font-family: 'Calibre Medium';
  cursor: pointer;
}
.ReviewConfirm .Input-control.form-group .label {
  font-family: 'Calibre Regular';
}
.ReviewConfirm .Select-control .label {
  font-family: 'Calibre Regular';
}
.ReviewConfirm .Textarea.form-group .label {
  font-family: 'Calibre Regular';
}
.ReviewConfirm .phonenumber {
  position: relative;
}
.ReviewConfirm .phonenumber .update-number {
  position: absolute;
  bottom: 34px;
  right: 25px;
  font-size: 16px;
  line-height: 1.58;
  font-family: 'Calibre Medium';
  color: #3dbce7;
}
.ReviewConfirm .error-box {
  border: 1px solid red;
}
.ReviewConfirm .error-box .error-text {
  display: block;
  color: red;
  font-size: 13px;
  padding-top: 10px;
}
@media (max-width: 1400px) {
  .ReviewConfirm .divider-content,
  .ReviewConfirm FORM,
  .ReviewConfirm .ca-social .social-icon {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .ReviewConfirm {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .ReviewConfirm {
    padding-top: 31px;
  }
  .ReviewConfirm .Select-control.form-group:after {
    display: none;
  }
  .ReviewConfirm .h3 {
    font-size: 20px;
    line-height: 1.45;
    letter-spacing: normal;
    margin-bottom: 17px;
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .Input-control.form-group .label {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .Select-control .label {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .Textarea.form-group .label {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .box-content .title {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .box-content .box-inner-content {
    background-color: #f7f9f9;
  }
  .ReviewConfirm .box-content .box-inner-content .choose-other {
    font-size: 16px;
    line-height: 1.89;
    letter-spacing: -0.3px;
  }
  .ReviewConfirm .box-content .box-inner-content .choose-other SPAN {
    color: #3dbce7;
  }
  .ReviewConfirm .box-content .box-inner-content {
    padding: 7px 20px;
  }
  .ReviewConfirm .box-content.existing-patient .box-inner-content {
    padding: 16px 20px 14px;
  }
  .ReviewConfirm .box-content .box-inner-content .name {
    margin-bottom: 2px;
  }
  .ReviewConfirm .Input-control.form-group .label {
    margin-bottom: 2px;
  }
  .ReviewConfirm .Input-control.form-group {
    margin-bottom: 28px;
  }
  .ReviewConfirm .Select-control .plan + .arrow {
    right: 11px;
  }
  .ReviewConfirm .box-content {
    margin-bottom: 17px;
  }
  .ReviewConfirm .box-content .box-inner-content .Checkbox-control {
    font-family: 'Calibre Medium';
    margin-right: 16px;
  }
  .ReviewConfirm .appoitment-smtime {
    display: block;
    width: calc(100% - 181px);
  }
  .ReviewConfirm .action-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
    margin: 0;
    padding: 19px 30px 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e4e7;
  }
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton {
    letter-spacing: normal;
    min-width: 171px;
    width: auto;
    margin-left: 10px;
    height: 48px;
  }
  .ReviewConfirm .select-insurance .arrow::after {
    display: none;
  }
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton .sm-hide {
    display: none;
  }
  .ReviewConfirm .phonenumber .update-number {
    bottom: 38px;
  }
}
@media (max-width: 575px) {
  .ReviewConfirm .h3 {
    width: 100%;
    font-size: 20px;
  }
}
@media (max-width: 413px) {
  .ReviewConfirm .action-bottom {
    padding: 19px 15px 33px;
  }
  .ReviewConfirm .action-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .ReviewConfirm .appoitment-smtime {
    width: 100%;
    text-align: center;
  }
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@supports (-webkit-touch-callout: none) {
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton {
    padding-top: 3px;
  }
}
