.CustomFooter {
  padding: 21px 0;
}
.CustomFooter .footer-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CustomFooter .footer-menu-ul {
  display: flex;
  flex-wrap: wrap;
}
.CustomFooter .footer-menu-ul .menu-item-list .accessibilityWidget,
.CustomFooter .footer-menu-ul .menu-item-list a {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #979ea6;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #979ea6;
  display: inline-block;
}
.CustomFooter .footer-menu-ul .menu-item-list:last-child .accessibilityWidget,
.CustomFooter .footer-menu-ul .menu-item-list:last-child a {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
  padding-top: 0;
}
.CustomFooter .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: center;
  color: #979ea6;
  width: 100%;
  margin-top: 10px;
  z-index: 1;
}

@media (hover: hover) {
  .CustomFooter .footer-menu-ul .menu-item-list .accessibilityWidget:hover,
  .CustomFooter .footer-menu-ul .menu-item-list a:hover {
    color: #013648;
    text-decoration: none;
  }
}
@media (max-width: 1024px) {
  .CustomFooter .paragraph {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .CustomFooter .footer-menu-ul {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .CustomFooter .menu-item-list {
    width: 100%;
    text-align: center;
  }
  .CustomFooter .footer-menu-ul .menu-item-list a {
    margin-bottom: 10px;
    line-height: 10px;
    padding-top: 5px;
  }
  .CustomFooter .paragraph {
    width: 100%;
  }
  .CustomFooter .footer-menu-ul .menu-item-list:nth-child(odd) a {
    padding-right: 9px;
    margin-right: 9px;
    border-right: 1px solid #979ea6;
  }
  .CustomFooter .footer-menu-ul {
    margin-bottom: 14px;
  }
}
@media (max-width: 375px) {
  .CustomFooter .menu-item-list {
    width: 100%;
  }
  .CustomFooter .container {
    padding: 0 15px;
  }
}
