.ProviderInformation {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 52px 92px 39px 92px;
  margin-bottom: 31px;
}
.ProviderInformation .h3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  margin-bottom: 31px;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
}
.ProviderInformation .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 6px;
  border-radius: 2px;
}
.ProviderInformation .Textarea.form-group TEXTAREA {
  height: 160px;
}
.ProviderInformation .Input-control.link-container.required-link .lookup-link {
  color: #f37184;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderInformation .TagsInput .multiselectsearch-block {
  min-height: 147px;
}
.ProviderInformation .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 8px;
}
.ProviderInformation .Input-control.link-container {
  margin-bottom: 38px;
}
.ProviderInformation .Input-control.link-container .lookup-link {
  top: 44px;
}
.ProviderInformation .InputList .input-list-label {
  margin-bottom: 8px;
}
.ProviderInformation .TagsInput .form-label {
  line-height: 1.25;
  margin-bottom: 11px;
}
.ProviderInformation .InputList {
  margin-bottom: 38px;
}
.ProviderInformation .InputList .add-input SPAN {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderInformation .InputList .input-list-item .Input-control.form-group {
  margin-bottom: 28px;
}
.ProviderInformation .InputList .add-input {
  margin: 0;
}
.ProviderInformation.InputList .input-list-label {
  margin-bottom: 12px;
}
.ProviderInformation .required-link {
  margin-bottom: 39px;
}
.ProviderInformation .titleHeading {
  display: flex;
  justify-content: space-between;
}
.ProviderInformation .info-bottom {
  display: flex;
}
.ProviderInformation .info-bottom BUTTON {
  margin-left: auto;
}

@media screen and (max-width: 1366px) {
  .ProviderInformation {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .ProviderInformation .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .ProviderInformation {
    padding: 10px;
  }
}
