.SignUp {
  padding: 76px 0;
}
.SignUp .container {
  padding: 0 60px;
  width: 100%;
}
.SignUp .signup-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}
.SignUp .signup-row .step-ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 307px;
}
.SignUp .signup-row .step-li {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: rgba(1, 54, 72, 0.5);
  font-family: HeldaneDisplay-Regular;
}
.SignUp .signup-row .step-li.current {
  color: #013648;
}
.SignUp .middle-section {
  max-width: 418px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .SignUp .container {
    padding: 0 15px;
  }
  .SignUp .signup-row .step-ul {
    width: 270px;
  }
  .SignUp .signup-row .step-li {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (max-width: 1024px) {
  .SignUp .middle-section {
    padding-left: 75px;
  }
}
@media (max-width: 767px) {
  .SignUp {
    padding: 39px 0;
  }
  .SignUp .signup-row .step-ul {
    display: none;
  }
  .SignUp .middle-section {
    padding: 0;
  }
}
