.PatientDemographic {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 39px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientDemographic .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 48px;
}
.PatientDemographic .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 21px;
}
.PatientDemographic .heading2 .sub-label {
  font-size: 16px;
  line-height: 2;
  letter-spacing: normal;
  color: #013648;
  opacity: 0.4;
}
.PatientDemographic .demographic-container {
  margin-bottom: 37px;
}
.PatientDemographic .demographic-block {
  margin-bottom: 58px;
}
.PatientDemographic .demographic-block .Checkbox-control {
  margin-bottom: 20px;
  padding-left: 33px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #013648;
}
.PatientDemographic .demographic-block .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #26acda;
  border-color: #26acda;
}
.PatientDemographic .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 4px;
}
.PatientDemographic .two-input .light-txt {
  color: rgba(1, 54, 72, 0.4);
}
.PatientDemographic .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}

@media screen and (max-width: 1200px) {
  .PatientDemographic {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientDemographic .h3 {
    font-size: 20px;
    margin-bottom: 22px;
  }
  .PatientDemographic .demographic-block .Checkbox-control {
    margin-bottom: 14px;
  }
  .PatientDemographic .demographic-block {
    margin-bottom: 35px;
  }
}
