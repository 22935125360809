.PracticeAccount .box-panel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.PracticeAccount .box-header .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: normal;
  display: flex;
  align-items: center;
}
.PracticeAccount .box-header .heading3 IMG {
  width: 24px;
  margin-right: 13px;
  position: relative;
  top: -2px;
}
.PracticeAccount .box-header {
  padding: 14px 20px 7px;
  border-bottom: 1px solid #f1f1f5;
}
.PracticeAccount .box-body {
  padding: 20px;
}
.PracticeAccount .box-body SPAN A {
  font-size: 20px;
  line-height: 1.2;
  color: #26acda;
  margin-bottom: 28px;
  display: inline-block;
}
.PracticeAccount .box-body SPAN:last-child A {
  margin-bottom: 0px;
}
.PracticeAccount .box-body SPAN.completed SPAN {
  color: #013648;
  text-decoration: line-through;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 28px;
  display: inline-block;
  width: 100%;
}
.PracticeAccount .provider .providerCount {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.PracticeAccount .provider A {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #26acda;
}
.PracticeAccount .paymentInfomation SPAN {
  color: #013648;
  display: inline-block;
  width: 100%;
}
.PracticeAccount .paymentInfomation {
  font-size: 16px;
  line-height: 1.13;
  letter-spacing: normal;
  color: #b2bcc4;
  margin-top: 17px;
}
.PracticeAccount .palnleftdays {
  height: 48px;
  border-radius: 8px;
  background-color: rgba(61, 147, 166, 0.1);
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #3d93a6;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 16px 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (hover: hover) {
  .PracticeAccount .box-body SPAN A:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .PracticeAccount .box-body SPAN A {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .PracticeAccount .box-header .heading3 {
    font-size: 18px;
  }
  .PracticeAccount .box-body SPAN A,
  .PracticeAccount .box-body SPAN.completed SPAN {
    font-size: 16px;
    margin-bottom: 14px;
  }
}
