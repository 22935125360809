.PatientFooter {
  padding: 58px 0 54px;
}
.PatientFooter .footer-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.PatientFooter .footer-main .footer-left {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 64%;
  flex: 0 0 64%;
  max-width: 64%;
}
.PatientFooter .footer-main .footer-right {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 36%;
  flex: 0 0 36%;
  max-width: 36%;
}
.PatientFooter .footer-main .footer-left .footer-left-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.PatientFooter .footer-main .footer-left .footer-left-inner .widget-one {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 43%;
  flex: 0 0 43%;
  max-width: 43%;
  padding-right: 15px;
}
.PatientFooter .footer-main .footer-left .footer-left-inner .widget-two,
.PatientFooter .footer-main .footer-left .footer-left-inner .widget-three {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 28%;
  flex: 0 0 28%;
  max-width: 28%;
  padding-right: 15px;
  margin-top: 10px;
}
.PatientFooter .footer-main .footer-left img {
  max-width: 180px;
  width: 100%;
  margin-bottom: 15px;
}
.PatientFooter .footer-main .footer-left .footer-widget .paragraph {
  font-family: 'Calibre Regular';
  font-size: 18px;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #013648;
}
.PatientFooter .footer-main .footer-left .footer-widget .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183b56;
  margin-bottom: 35px;
}
Footer .footer-main .footer-left .footer-widget .footer-menu-ul {
  list-style-type: none;
}
.PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list {
  margin-bottom: 27px;
}
.PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a {
  font-family: 'Calibre Regular';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183b56;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.PatientFooter .footer-main .footer-right .footer-menu-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list {
  margin-right: 23px;
}
.PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list:last-child {
  margin-right: 0;
}
.PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list a img {
  width: 44px;
  height: 44px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.PatientFooter .footer-right-inner {
  margin-top: 20px;
}
.PatientFooter .footer-bottom {
  margin-top: 60px;
  position: relative;
}

.PatientFooter .footer-bottom .paragraph {
  font-family: 'Calibre Regular';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #979ea6;
  margin: 0;
}
@media (hover: hover) {
  .PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list a:hover img {
    opacity: 0.7;
  }
  .PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a:hover {
    color: #013648;
  }
}

@media (max-width: 1200px) {
  .PatientFooter .footer-bottom {
    margin-top: 150px;
  }
}

@media (max-width: 991px) {
  .PatientFooter .footer-main .footer-left {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .PatientFooter .footer-main .footer-right {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .PatientFooter .footer-bottom {
    margin-top: 0;
  }
  .PatientFooter {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .PatientFooter{
    background-size: 320px !important;
    background-position: left center !important;
  }
  .PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a {
    line-height: 1.25;
  }

  .PatientFooter .footer-main .footer-left .footer-left-inner .widget-one {
    display: none;
  }
  .PatientFooter .footer-main .footer-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .PatientFooter .footer-main .footer-right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 53px;
  }
  .PatientFooter .footer-main .footer-left .footer-left-inner .widget-two {
    flex: 0 0 42%;
    max-width: 42%;
  }
  .PatientFooter .footer-main .footer-left .footer-left-inner .widget-three {
    flex: 0 0 58%;
    max-width: 58%;
  }
  .PatientFooter .footer-main {
    flex-direction: column-reverse;
  }
  .PatientFooter .footer-main .footer-right .footer-menu-ul {
    justify-content: center;
  }
  .PatientFooter .footer-main .footer-left .footer-left-inner {
    flex-direction: row-reverse;
  }
  .PatientFooter .footer-main .footer-left .footer-widget .h3 {
    margin-bottom: 30px;
    line-height: 1.22;
  }
  .PatientFooter .footer-bottom {
    margin-top: 30px;
  }
  .PatientFooter .footer-bottom .paragraph {
    text-align: center;
    display: none;
  }
}
