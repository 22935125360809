.Sidebar {
  min-width: 250px;
  margin-top: 1px;
}
.Sidebar .Sidebar-inner-content {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 20px;
  overflow: auto;
  z-index: 3;
  background-color: #fff;
  border-top: 1px solid #f9f9f9;
  -webkit-overflow-scrolling: touch;
}
.Sidebar .medical-content {
  margin-top: 15px;
}
.Sidebar .medical-content .medical-text {
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 2px;
  color: #72787d;
  text-transform: uppercase;
  margin-bottom: 21px;
}
.Sidebar .medical-content .medical-name {
  position: relative;
  padding-left: 42px;
  font-family: 'Calibre Regular';
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 30px;
}
.Sidebar .medical-content .medical-name .medical-logo {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: -3px;
  border: none;
  border-radius: 7px;
}
.Sidebar .medical-content .medical-name img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #c6c6c6;
}
.Sidebar .menu {
  margin-top: 20px;
  margin-bottom: 130px;
  margin-left: -20px;
  margin-right: -20px;
}
.Sidebar .viewprofile {
  opacity: 0.4;
  color: #013648;
  position: absolute;
  bottom: 11px;
  left: 42px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .Sidebar {
    min-width: 200px;
  }
  .Sidebar .Sidebar-inner-content {
    width: 200px;
    padding: 20px 10px;
  }
  .Sidebar .medical-content .medical-name {
    font-size: 18px;
  }
  .MenuItem a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .Sidebar {
    position: fixed;
    top: 70px;
    bottom: 0;
    left: -200px;
    transition: 0.3s all linear;
    z-index: 8;
    margin-top: 0;
  }
  .Sidebar .Sidebar-inner-content {
    position: static;
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
