.Select-control.form-group SELECT {
  width: 100%;
  display: inline-block;
}
.Select-control {
  margin-bottom: 23px;
  position: relative;
}
.Select-control.form-group SELECT:disabled {
  background-color: #f8f8f8;
}
.Select-control.form-group SELECT {
  height: 52px;
  border-radius: 2px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  background-color: transparent;
  font-size: 18px;
  font-family: 'Calibre Regular';
  z-index: 2;
  position: relative;
  -webkit-appearance: none;
  line-height: 1.56;
  letter-spacing: -0.34px;
  padding: 3px 50px 0 25px;
  /* color: #013648; */
}
.Select-control.form-group:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 24px;
  bottom: 0;
  background-color: #fff;
}
.Select-control .select {
  position: relative;
  margin-bottom: 23px;
}
.Select-control.form-group SELECT:focus {
  outline: 0;
  border-color: #013648;
}
.Select-control .arrow {
  position: absolute;
  border: 9px solid #013648;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 45px;
  right: 27px;
  z-index: 1;
}
.Select-control .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.Select-control .error-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-size: 13px;
}
.Select-control.error SELECT {
  border-color: rgba(255, 0, 0, 0.4);
}
.Select-control.no-filled-select SELECT,
.Select-control.no-filled-select SELECT OPTION {
  color: #013648;
}
.Select-control.no-filled-select SELECT[required=""]:invalid,
.Select-control.no-filled-select SELECT[required=""] option[value=""] {
  color: rgba(1, 54, 72, 0.5);
}
.Select-control SELECT {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

@media screen and (max-width: 767px) {
  .Select-control.form-group SELECT {
    padding: 3px 30px 0 15px;
    font-size: 16px;
  }
  .Select-control .arrow {
    right: 11px;
  }
}
