.AfterAuthSchedule {
  padding-bottom: 70px;
  width: 100%;
}
.AfterAuthSchedule .AfterAuthSchedule-innercontent {
  display: flex;
  padding-left: 12px;
  padding-right: 20px;
}
.AfterAuthSchedule .AfterAuthSchedule-inner {
  text-align: center;
  min-width: 1200px;
}
.AfterAuthSchedule .AfterAuthSchedule-innercontent .calender-box {
  width: 100%;
  margin-left: 12px;
}
.AfterAuthSchedule .AfterAuthSchedule-innercontent .activity-box {
  width: 350px;
}
.AfterAuthSchedule .box-content {
  border-radius: 20px;
  background-color: #ffffff;
}
.AfterAuthSchedule .all-day-text {
  margin: 0;
  display: block;
  width: 100%;
  text-align: center;
  background: rgba(252, 90, 90, 0.08);
  border-top: 1px solid #ddd;
  font-size: 12px;
  line-height: normal;
  padding: 5px 0;
  letter-spacing: 0.1px;
  color: rgba(243, 113, 132, 0.44);
  height: 25px;
}

.AfterAuthSchedule .rbc-header A {
  display: inline-block;
  width: 100%;
  cursor: initial;
}
.AfterAuthSchedule .rbc-header.rbc-today .CalendarDayHeader {
  color: #fff;
}
.AfterAuthSchedule .rbc-header.rbc-today {
  background-color: #013648;
}
.AfterAuthSchedule .rbc-header {
  min-height: 56px;
  background-color: #fcfcfc;
}
.AfterAuthSchedule .rbc-time-header-gutter {
  width: 82px;
  display: flex;
  align-items: flex-end;
  padding: 0px;
  background-color: #fcfcfc;
}
.AfterAuthSchedule .rbc-time-header {
  min-width: 700px;
}
.AfterAuthSchedule .rbc-time-view .rbc-row {
  min-height: 56px;
}
.AfterAuthSchedule .rbc-allday-cell {
  max-height: 24px;
  background-color: rgba(252, 90, 90, 0.08);
}
.rbc-time-header-content .rbc-allday-cell {
  max-height: 60px;
  background-color: rgba(252, 90, 90, 0.08);
}

.AfterAuthSchedule .rbc-allday-cell .rbc-row-bg {
  max-height: 24px;
}
.AfterAuthSchedule .rbc-allday-cell .rbc-row-content .rbc-row {
  max-height: 12px;
  min-height: 12px;
}
.AfterAuthSchedule .rbc-allday-cell .rbc-row-content {
  max-height: 24px;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row-content {
  max-height: 60px;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row-content .rbc-row {
  max-height: 24px;
  min-height: 24px;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row-bg {
  max-height: 60px;
}
.AfterAuthSchedule
  .rbc-time-content
  .rbc-time-column.rbc-time-gutter
  .rbc-timeslot-group
  .rbc-label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  text-transform: lowercase;
  padding-left: 15px;
}
.AfterAuthSchedule .rbc-time-content {
  border-top: 1px solid #ddd;
  max-height: calc(100vh - 332px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  min-width: 678px;
}
.AfterAuthSchedule .rbc-time-view {
  border-left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AfterAuthSchedule .rbc-time-content .rbc-time-column .rbc-timeslot-group {
  height: 44px;
  border-left: solid 1px #f6f6f6;
}
.AfterAuthSchedule .rbc-time-content .rbc-time-column.rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0;
}
.AfterAuthSchedule .rbc-time-content > .rbc-day-slot .rbc-time-slot {
  border-top: solid 1px #f6f6f6;
}
.AfterAuthSchedule .rbc-time-content > .rbc-time-gutter {
  width: 82px;
}
.AfterAuthSchedule .rbc-day-slot .rbc-events-container {
  margin-right: unset;
}
.AfterAuthSchedule .rbc-current-time-indicator {
  height: 4px;
  border-style: solid;
  border-color: #6565b5;
  background-color: unset;
  z-index: 0;
}
.AfterAuthSchedule .monthly-activity-content {
  display: block !important;
  z-index: 9;
  bottom: 34px;
  right: 25px;
  position: absolute;
}
.AfterAuthSchedule .AppointmentDetail {
  box-shadow: -6px 7px 14px 0 rgba(55, 70, 95, 0.07);
}
.AfterAuthSchedule .monthly-activity-content .overlay {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .DateTime {
  font-size: 20px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .PatientDetails {
  padding: 17px 20px 18px 25px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .PatientDetails .Patient-name span {
  font-size: 16px;
}
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-address {
  padding: 18px 0 3px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-information {
  min-width: 240px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .link-span {
  font-size: 14px !important;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status {
  padding-left: 22px;
}
.AfterAuthSchedule
  .monthly-activity-content
  .AppointmentDetail
  .Patient-status
  .doctor-item
  .doctor-info
  .doctor-name {
  font-size: 16px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status .doctor-item > IMG {
  height: 37px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-location {
  padding: 25px 0;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-location IMG {
  width: 11px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-Appointment,
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-location {
  padding-left: 42px;
  font-size: 14px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .link-span {
  font-size: 16px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status-control {
  margin-top: 18px;
}
.AfterAuthSchedule
  .monthly-activity-content
  .AppointmentDetail
  .Patient-status-control
  .Select-control.form-group {
  margin-bottom: 0px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms .h3 {
  font-size: 18px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Questionnaire SPAN.h3 {
  font-size: 16px;
  font-family: 'Calibre Medium';
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Questionnaire SPAN {
  font-size: 14px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms .link-span {
  font-size: 14px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Questionnaire {
  margin-bottom: 17px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms {
  min-width: 179px;
  max-width: 179px;
}
.AfterAuthSchedule .rbc-time-header {
  min-width: 670px;
}
.AfterAuthSchedule .AppointmentDetail .Patient-status-control .Select-control.form-group select {
  width: 100%;
  line-height: 38px;
  font-size: 18px;
  padding-top: 0;
}
.AfterAuthSchedule .monthly-activity-content {
  width: 695px;
}
.AfterAuthSchedule
  .AppointmentDetail
  .PatientDetails-content
  .Patient-information
  .Patient-address
  SPAN,
.AfterAuthSchedule
  .AppointmentDetail
  .PatientDetails-content
  .Patient-information
  .Patient-data
  SPAN {
  font-size: 14.4px;
}
.AuthSchedulegoogleparent {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 0;
}
@media (hover: hover) {
  .InviteYourProviders .signup-bottom SPAN:hover {
    color: #013648;
  }
  .AfterAuthSchedule .rbc-time-content .rbc-day-slot:last-child .rbc-event .hover-content,
  .AfterAuthSchedule .rbc-time-content .rbc-day-slot:nth-last-child(2) .rbc-event .hover-content {
    left: -198px;
  }
}

@media (max-width: 1200px) {
  .AfterAuthSchedule .AfterAuthSchedule-innercontent {
    flex-wrap: wrap;
  }
  .AfterAuthSchedule .AfterAuthSchedule-innercontent .calender-box {
    margin-left: 0;
  }
  .AfterAuthSchedule .rbc-calendar {
    display: inline-grid;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .AfterAuthSchedule .rTable {
    width: 1140px;
  }
  .AfterAuthSchedule .table-wrapper {
    overflow-x: auto;
  }
  .AfterAuthSchedule .AppointmentDetail .PatientDetails-content {
    flex-wrap: nowrap;
  }
}

@media (max-width: 767px) {
  .AfterAuthSchedule {
    padding: 0 0 50px;
  }
  .AfterAuthSchedule .heading2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .AfterAuthSchedule .rbc-calendar {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .AfterAuthSchedule .rbc-time-view {
    overflow: unset;
  }
  .AfterAuthSchedule .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
  .AfterAuthSchedule .monthly-activity-content {
    width: auto;
    left: 30px;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .PatientDetails {
    padding-left: 15px;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-information {
    min-width: auto;
  }
  @supports (-webkit-touch-callout: none) {
    .CalendarToolbar .arrow-btn,
    .CalendarToolbar .cal-btn {
      padding: 0 6px;
    }
  }
}
@media (max-width: 575px) {
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-information {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }
  .AfterAuthSchedule .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 15px;
  }
}
