.PatientProfile .PatientHeader {
  height: 108px;
}
.PatientProfile .main-container {
  width: 100%;
  min-height: calc(100vh - 181px);
}
.PatientProfile .no-permission-text {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: rgba(38, 172, 218, 0.1);
  padding: 15px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #26acda;
}
.PatientProfile .AfterAuthSubHeader .main-inner-content {
  padding: 45px 43px 40px;
  padding-bottom: 30px;
}
.PatientProfile .setting-conettn {
  padding: 0px 40px;
}
.PatientProfile .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
}
.PatientProfile .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 46px;
  cursor: pointer;
  padding-bottom: 12px;
}
.PatientProfile .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.PatientProfile .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #26acda;
  position: relative;
}
.PatientProfile .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #26acda;
  border-radius: 4px 4px 0px 0px;
}
.PatientProfile .setting-tabs .tab-content {
  margin-bottom: 40px;
  margin-top: 50px;
  border-radius: 8px;
}
.PatientProfile {
  background-color: #f7f9f9;
  padding: 0px;
}
.PatientProfile .main-container {
  padding: 0px 125px;
}

@media screen and (max-width: 1400px) {
  .PatientProfile .main-container {
    padding: 0px 60px;
  }
  .PatientProfile .AfterAuthSubHeader .main-inner-content {
    padding: 30px 0;
  }
  .PatientProfile .setting-conettn {
    padding: 0;
  }
}
@media screen and (max-width: 1200px) {
  .PatientProfile .setting-tabs .tab-list-item {
    margin-right: 34px;
  }
  .PatientProfile .setting-tabs .tab-list-item:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .PatientProfile .main-container {
    padding: 0 30px;
  }
  .PatientProfile .setting-tabs .tab-list-item {
    padding-bottom: 4px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .PatientProfile .setting-tabs .tab-list-item {
    margin-right: 25px;
    font-size: 16px;
  }
}
@media screen and (max-width: 414px) {
  .PatientProfile .main-container {
    padding: 0 15px;
  }
}
