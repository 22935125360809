.AccountTab {
  background-color: #ffffff;
  border: solid 1px #e2e2ea;
  border-radius: 8px;
  padding: 35px 30px 138px 40px;
  width: 620px;
  max-width: 100%;
}
.AccountTab .password-instruction-text {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Calibre Regular';
}
.AccountTab .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin: 0 0 16px;
}
.AccountTab .password-form .h3 {
  margin-bottom: 24px;
}
.AccountTab .email-form .h3 {
  margin: 0 0 24px;
}
.AccountTab .password-form .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 8px;
}
.AccountTab .password-form .Input-control.form-group {
  margin-bottom: 22px;
}
.AccountTab .password-form .save-btn {
  margin-top: 11px;
}
.AccountTab .Input-control.form-group {
  width: 398px;
}
.AccountTab .edit-link {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #3dbce7;
  padding-left: 21px;
  margin-bottom: 20px;
  cursor: pointer;
  /* font-family: 'Calibre Medium'; */
}
.AccountTab .cancel-link {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #e85d72;
  padding-left: 22px;
  margin-bottom: 20px;
  cursor: pointer;
}
.AccountTab .email-form {
  margin-bottom: 18px;
}
.AccountTab .form-row {
  display: flex;
  align-items: center;
}
.AccountTab .save-btn .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  letter-spacing: normal;
  margin-right: 34px;
  line-height: 32px;
}
.AccountTab .update-wrapper {
  display: flex;
  margin-bottom: 40px;
}
.AccountTab .update-wrapper .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  margin-right: 19px;
}
.AccountTab .update-wrapper .para {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
  color: #72787d;
  font-family: 'Calibre Medium';
  font-weight: 500;
  max-width: 268px;
}
.AccountTab .accountEmail-form .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 16px;
}
@media screen and (max-width: 1024px) {
  .AccountTab {
    width: 100%;
  }
  .AccountTab .Input-control.form-group {
    width: 100%;
  }
  .AccountTab .form-row {
    flex-wrap: wrap;
  }
  .AccountTab .edit-link,
  .AccountTab .cancel-link {
    padding-left: 0;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .AccountTab {
    padding: 20px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AccountTab .update-wrapper .CircleButton.WhiteButton,
  .AccountTab .save-btn .CircleButton.WhiteButton {
    padding-top: 1px;
  }
}
