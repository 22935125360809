.EditPracticeSeoLink {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 40px 40px;
  margin-bottom: 40px;
}
.EditPracticeSeoLink .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 40px;
}
.EditPracticeSeoLink .Select-control .arrow {
  top: 55px;
}
.EditPracticeSeoLink .Textarea,
.EditPracticeSeoLink .TagsInput .form-col,
.EditPracticeSeoLink .Input-control{
  scroll-margin-top: 80px;
}
.EditPracticeSeoLink .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  margin-top: 17px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 1px;
  border: 1px solid #013648;
}
.EditPracticeSeoLink .Textarea.form-group TEXTAREA {
  height: 160px;
}
.EditPracticeSeoLink .Textarea.form-group .form-control:not(:placeholder-shown) {
  padding-left: 25px;
}
.EditPracticeSeoLink .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeSeoLink .Input-control.form-group {
  margin-bottom: 36px;
}
.EditPracticeSeoLink .Textarea.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeSeoLink .Textarea.form-group {
  margin-bottom: 34px;
}
.EditPracticeSeoLink .Select-control .label {
  line-height: 1.25;
  margin-bottom: 14px;
  display: block;
}
 .confirm-seolink.practice p {
  display: grid;
  grid-template-columns: 180px 1fr;
  padding-left: 15px;
}

@media screen and (max-width: 1366px) {
  .EditPracticeSeoLink {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeSeoLink .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .EditPracticeSeoLink .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 413px) {
  .confirm-seolink.practice p{
    display: inherit;
  }
}