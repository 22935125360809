.ProviderReschedule {
  background-color: white;
  height: auto;
  width: 50vw;
  padding: 0px;
  
}
.ProviderReschedule .days-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ProviderReschedule .days-checkbox-container .Select-days {
  width: 100%;
  height: 34px;
  border-radius: 9px;
  border: solid 0.9px #e2e2ea;
  display: flex;
  overflow: hidden;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control {
  flex: 1;
  position: relative;
  padding-left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control INPUT {
  width: 100%;
  height: 100%;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control .checkmark {
  width: 100%;
  height: 100%;
  border: none;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control .checkmark:after {
  display: none;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(0, 17, 51, 0.2);
}
.ProviderReschedule .select-event-day-content {
  display: flex;
}
.ProviderReschedule .select-event-day-content .Select-control.form-group SELECT {
  padding-right: 38px;
}
.ProviderReschedule .select-event-day-content .Select-control.form-group SELECT OPTION {
  font-family: sans-serif;
  font-size: 14px;
}
.ProviderReschedule .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.ProviderReschedule .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.ProviderReschedule .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0;
}
.ProviderReschedule .dailog-close-btn IMG {
  height: 100%;
  width: 100%;
}
.ProviderReschedule .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.ProviderReschedule .dailog-body {
  padding: 20px 32px 15px;
  /* min-height: 500px; */
}
.ProviderReschedule .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.ProviderReschedule .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.ProviderReschedule .virtual-field .Checkbox-control {
  font-size: 18px;
}
.ProviderReschedule .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.ProviderReschedule .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.ProviderReschedule .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.ProviderReschedule .action-btn .error {
  color: red;
  font-size: 16px;
  display: block;
  text-align: left;
  width: 100%;
}
.ProviderReschedule .action-btn {
  text-align: right;
}
.ProviderReschedule .dailog-body .Input-control .form-control {
  border: 0;
  padding-left: 50px;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.ProviderReschedule .select-date-content {
  padding-left: 42px;
  position: relative;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 34px;
  line-height: 1.8;
  z-index: 100;
  display: flex;
}
.ProviderReschedule .time-padding {
  padding: 8px 8px 5px 0;
  margin-right: 10px;
}
.ProviderReschedule span.small-label {
  display: block;
  line-height: 0.3;
  color: #a29d9dbf;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 5px;
}
.ProviderReschedule .gray-box {
  position: relative;
  z-index: 1;
}
.ProviderReschedule .gray-box .gray-back {
  background: #e9ebf2;
  padding: 8px 12px 5px 9px;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  position: absolute;
  left: -8px;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
  z-index: -1;
}
.ProviderReschedule .select-date-content .icon {
  position: absolute;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
}
.ProviderReschedule .select-date-content .icon IMG {
  width: 100%;
}
.ProviderReschedule .select-time .TimeManual .result {
  top: 33px;
}
.ProviderReschedule .select-date-content .select-time {
  padding-left: 0px;
  padding-right: 5px;
  font-weight: 700;
  display: inline-flex;
  width: 250px;
  position: relative;
  top: -2px;
}
.ProviderReschedule .time-devider {
  display: inline-block;
  padding: 0 5px;
  padding-top: 9px;
}
.ProviderReschedule .TimeManual .Input-control {
  margin-bottom: 0;
}
.ProviderReschedule .TimeManual .Input-control INPUT.form-control {
  display: inline-flex;
  padding-left: 10px;
  border: 1px solid black;
  height: 30px;
  padding-right: 10px;
}
.ProviderReschedule .end-date {
  /* color: rgba(1, 54, 72, 0.5);
  min-width: 140px; */
  margin-left: 15px;
}
.ProviderReschedule .select-picker-content {
  position: relative;
  display: inline-block;
  min-width: 135px;
}
.ProviderReschedule .select-picker-content .Datepicker-content {
  position: absolute;
  top: 35px;
  z-index: 99;
  /* height: 200px; */
  overflow: auto;
  padding-bottom: 10px;
  background-color: #FFF;
}
.ProviderReschedule .Datepicker-content.time.end-time {
  position: absolute;
}
.ProviderReschedule .datepicker-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.ProviderReschedule .location {
  position: relative;
  padding-left: 41px;
}
.ProviderReschedule .dailog-body .location .Select-control {
  width: 100%;
  background-color: #f1f2f2;
}
.ProviderReschedule .location .icon {
  position: absolute;
  left: 1px;
  top: 4px;
}
.ProviderReschedule .dailog-body .location .Select-control SELECT {
  padding-left: 18px;
  height: 37px;
}
.ProviderReschedule .dailog-body .location .Select-control SELECT OPTION {
  font-family: sans-serif;
  font-size: 14px;
}
.ProviderReschedule .dailog-body .location .Select-control:after {
  display: none;
}
.ProviderReschedule .dailog-body .location .Select-control .arrow {
  border: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 6px;
  right: 10px;
}
.ProviderReschedule .dropdown {
  position: relative;
}
.ProviderReschedule .dropdown .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all linear;
  z-index: 9;
  background-color: #ffffff;
  min-width: 300px;
}
.ProviderReschedule .dropdown .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.ProviderReschedule .dropdown .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
  min-width: 300px;
  z-index: 101;
}
.ProviderReschedule .virtual-field {
  cursor: pointer;
}
.ProviderReschedule .virtual-field .Checkbox-control {
  padding-left: 48px;
}
.ProviderReschedule .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.ProviderReschedule .disabled .Checkbox-control {
  color: rgba(1, 54, 72, 0.29);
}
.ProviderReschedule .disabled .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(1, 54, 72, 0.06);
}
.ProviderReschedule .select-event-day-content IMG {
  position: relative;
  top: 6px;
}
.ProviderReschedule .disabled .Select-control,
.ProviderReschedule .disabled IMG {
  opacity: 0.29;
}
.ProviderReschedule .cross-icon IMG {
  width: 100%;
}
@media (hover: hover) {
  .ProviderReschedule .dropdown .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
.ProviderReschedule .MyDatetime .rdtPicker{
width: 320px;
padding-top: 35px;
}
.ProviderReschedule  .MyDatetime.days .rdtPicker td{
  line-height: 26px;
  }
  .ProviderReschedule .MyDatetime .rdtPicker .rdtDays table{
    border-spacing: 23px 5px;
  }
  .ProviderReschedule .MyDatetime.days .rdtPicker th.rdtNext, 
  .ProviderReschedule .MyDatetime.days .rdtPicker th.rdtPrev{
  top: 10px;
  }
  .ProviderReschedule .MyDatetime.days .rdtPicker .rdtSwitch:after{
    width: 50%;
  }
  .ProviderReschedule .MyDatetime.days .rdtPicker .rdtSwitch{
    left: 24px;
    top: 22px;
  }
@media (max-width: 767px) {
  .ProviderReschedule .select-date-content,
  .ProviderReschedule .select-event-day-content {
    flex-wrap: wrap;
  }
  .ProviderReschedule .select-picker-content {
    margin-bottom: 15px;
  }
  .ProviderReschedule .dailog-body .Select-control {
    margin-bottom: 15px;
  }
  .ProviderReschedule .select-event-day-content {
    margin-bottom: 34px;
  }
  .ProviderReschedule .select-event-day-content .Select-control {
    width: 226px;
  }
  .ProviderReschedule .select-event-day-content .Select-control SELECT {
    padding-left: 20px;
  }
  .ProviderReschedule .select-picker-content.end-date {
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .ProviderReschedule {
    overflow: scroll;
  }
  .ProviderReschedule .select-picker-content .Datepicker-content{
    position: unset;
    top: 0px;
    left: 0 !important;

  }
  .MyDatetime .rdtPicker {
    position: relative;
  }
  .ProviderReschedule {
    width: 100%;
    height:90vh;
    overflow: scroll;
  }
}

@media (max-width: 545px) {
  .ProviderReschedule .select-picker-content.end-date {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
@media (max-width: 375px) {
  .ProviderReschedule .select-event-day-content .Select-control {
    width: 200px;
  }
  .ProviderReschedule .dailog-body {
    padding: 20px 15px 15px;
  }
  .ProviderReschedule .dailog-header {
    padding: 22px 15px 5px 15px;
  }
  .ProviderReschedule .dailog-close-btn{
    margin-left: 10px;
  }
  .ProviderReschedule .cross-icon {
    right: 15px;
  }
}
