.SpecialityModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 575px;
  margin: auto;
}
.SpecialityModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 41px 0 45px;
}
.SpecialityModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.SpecialityModal .modal-content-header .close img {
  width: 100%;
}
.SpecialityModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.SpecialityModal .modal-content-body {
  padding: 35px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.SpecialityModal .sub-text,
.SpecialityModal .question {
  font-size: 18px;
  line-height: 1.28;
  color: #013648;
}
.SpecialityModal .sub-text {
  margin-bottom: 39px;
}
.SpecialityModal .question {
  font-size: 24px;
  line-height: 1.13;
  margin-bottom: 25px;
}
.SpecialityModal .sf-select-answer.sf-list-content .sf-description-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}
.SpecialityModal .sf-select-answer.sf-list-content .sf-description-item span {
  border-radius: 21.6px;
  border: solid 1px #013648;
  border-top-left-radius: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  min-width: 325px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  padding: 11px 24px 9px;
  min-height: 47px;
}
.SpecialityModal .sf-select-answer.sf-list-content.txt-center .sf-description-item span {
  border-radius: 21.6px;
}
.SpecialityModal .sf-select-answer.sf-list-content .select-option span {
  background-color: #013648;
  color: #ffffff;
}
.SpecialityModal.sf-select-answer.sf-action {
  text-align: center;
  padding-top: 3px;
}
.SpecialityModal .sf-action .select-option-text {
  font-family: 'TT Commons';
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #b2bcc4;
  cursor: pointer;
}
.SpecialityModal .txt-center {
  text-align: center;
}

@media (hover: hover) {
  .SpecialityModal .sf-select-answer.sf-list-content .sf-description-item:hover span {
    background-color: #013648;
    color: #ffffff;
  }
  .SpecialityModal .sf-action .select-option-text:hover {
    color: #72787d;
  }
}

@media (max-width: 768px) {
  .SpecialityModal {
    width: 100%;
  }
  .SpecialityModal .sf-select-answer.sf-list-content .sf-description-item span {
    min-width: 100%;
  }
}
@media (max-width: 414px) {
  .SpecialityModal .modal-content-body {
    padding: 15px;
  }
  .SpecialityModal .modal-content-header {
    padding: 0 15px;
  }
  .SpecialityModal .modal-content-header .h3 {
    font-size: 27px;
  }
}
