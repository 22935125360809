.PatientVideoClosed {
  width: 100%;
  padding: 214px 20px 120px;
  text-align: center;
}
.PatientVideoClosed .sub-heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 9px;
}
.PatientVideoClosed .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 29px;
}
.PatientVideoClosed .doctor-img {
  width: 100px;
  height: 150px;
  display: block;
  margin: 0 auto 32px;
}
.PatientVideoClosed .doctor-img IMG {  
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.PatientVideoClosed .para {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 6px;
}
.PatientVideoClosed .home-videolink {
  padding: 5px 10px 0px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px #013648;
  max-width: 158px;
  width: 100%;
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: #013648; 
  display: inline-block;
  text-transform: capitalize;
}
@media (hover: hover) {
  .PatientVideoClosed .home-videolink:hover {
    background: #013648;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .PatientVideoClosed {
    padding: 170px 20px 120px;
  }
}

@media (max-width: 767px) {
  .PatientVideoClosed {
    padding: 150px 30px 120px;
  }
  .PatientVideoClosed .heading2 {
    margin-bottom: 59px;
  }
}

@media (max-width: 413px) {
  .PatientVideoClosed {
    padding: 150px 15px 120px;
  }
  .PatientVideoClosed .heading2 {
    font-size: 32px;
  }
  .PatientVideoClosed .para {
    font-size: 16px;
  }
}