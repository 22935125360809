.BuiltDoctors {
  padding-top: 70px;
  background-color: #fbfbf9;
  padding-bottom: 108px;
}
.BuiltDoctors .title {
  font-family: 'Heldane Display';
  font-size: 48px;
  line-height: 1.38;
  text-align: center;
  color: #013648;
  margin-bottom: 46px;
}
.BuiltDoctors .BuiltDoctors-testimonial {
  /* display: flex;
  border-radius: 9.5px;
  overflow: hidden; */
}

.BuiltDoctors .BuiltDoctors-testimonial  .slick-track{ margin-left: 0; display: flex }

.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
  width: 285px;
  /* min-width: 285px; */
  padding: 58px 49px 275px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 728px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-date {
  margin-top: auto;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
  width: 77%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 280px; */
  margin: auto;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name span {
  display: inline-block;
  width: 100%;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.harmonis {
  background-color: #E2DEDD;
}

.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.pipi {
  background-color: #F7B1AF;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.Onahau {
  background-color: #ECECEC;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.Sinbad {
  background-color: #D8E1F0;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name {
  opacity: 0.9;
  font-size: 16px;
  line-height: 1.22;
  color: #013648;
  margin-bottom: 27px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name .name {
  font-family: 'Heldane Display';
  font-size: 18px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-description {
  opacity: 0.8;
  font-size: 18px;
  line-height: 1.28;
  color: #013648;
  margin-bottom: 35px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-date span {
  display: inline-block;
  width: 100%;
  opacity: 0.79;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}

@media (max-width: 1200px) {
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    padding: 58px 20px 275px;
    width: 320px;
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
    width: 160px;
  } */
}

@media (max-width: 1024px) {
  .BuiltDoctors .title {
    font-size: 36px;
  }
  .BuiltDoctors {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    padding-bottom: 200px;
   
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name {
    font-size: 16px;
  }
}
@media (max-width: 990px) {
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    
    width: 360px;
  }
}
@media (max-width: 767px) {
  .BuiltDoctors .title {
    font-size: 48px;
    text-align: center;
    line-height: 1.08;
    margin-bottom: 70px;

  }
  .BuiltDoctors {
    padding-top: 0;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    /* min-width: 400px; */
    width: calc(100vw - 65px);
    margin-right: 37px;
    padding-left: 50px;
    padding-right: 30px;
    padding-bottom: 300px;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-date{
    margin-top: 30px;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img{
    width: 65%;
  }
  .BuiltDoctors .BuiltDoctors-testimonial {
    width: calc(100% + 30px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 15px;
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
    width: 226px;
  } */
}
@media (max-width: 670px) {
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img{
  width: 73%;
}
}
@media (max-width: 550px) {
.BuiltDoctors .title {
  font-size: 39px;
    margin-bottom: 30px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img{
  width: 77%;
}
}
@media (max-width: 470px) {
  .BuiltDoctors .title {
    font-size: 35px;
    
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem{
    height: 560px;
  } */
  }
@media (max-width: 413px) {
  .BuiltDoctors .title {
    font-size: 29px;
    margin-bottom: 30px;
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial {
    margin: 0px -15px;
    width: calc(100% + 30px);
  } */
  
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
    width: 100%;
}
}
@media (max-width: 350px) {
  .BuiltDoctors .title {
    font-size: 25px;
    
  }
  }