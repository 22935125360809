.VideoPersonScreen .main-participant {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #013648;
}
.VideoPersonScreen .videoscreen-inner {
  position: relative;
  height: calc(100vh - 70px);
  background: #013648;
}
.VideoPersonScreen .video-small {
  width: 99px;
  height: 176px;
  border-radius: 4.8px;
  position: fixed;
  bottom: 189px;
  right: 24px;
  border: solid 0.5px #979797;
  background-color: #1d1b1b;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: auto;
  left: auto;
}
.VideoPersonScreen .video-small .video-bottom {
  display: none;
}
.VideoPersonScreen .videoscreen-full {
  position: relative;
  height: 100vh;
  background: #013648;
}
.VideoPersonScreen .video-hide {
  display: none;
}
.VideoPersonScreen .other-participant {
  width: 331px;
  height: 186px;
  position: absolute;
  top: 20px;
  right: 21px;
  border-radius: 9px;
  border: solid 0.5px #979797;
  background-color: #1d1b1b;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
.VideoPersonScreen .other-participant.participant-hide {
  display: none;
}
.VideoPersonScreen .novideo-name {
  font-size: 36px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 0.89;
  letter-spacing: normal;
  color: #ffffff;
  position: absolute;
  top: 48.5%;
  left: 0;
  right: 0;
  text-align: center;
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.VideoPersonScreen .other-participant IMG {
  width: 54px;
  height: 81px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  margin-bottom: 23px;
}
.VideoPersonScreen .other-participant .participant-name {
  font-size: 24px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}
.VideoPersonScreen .other-participant VIDEO {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: #1d1b1b;
}
.VideoPersonScreen .video-bottom {
  position: absolute;
  bottom: 47px;
  max-width: 926px;
  width: 100%;
  padding: 21px 41px 24px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #ffffff;
  margin: 0 auto;
  animation-name: moveUp;
  animation-duration: 0.5s;
}
@keyframes moveUp {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 47px;
  }
}
.VideoPersonScreen .video-timer {
  animation-name: moveDown;
  animation-duration: 0.5s;
  transition: all 0.5s ease;
  opacity: 0;
}
@keyframes moveDown {
  0% {
    bottom: 47px;
  }
  100% {
    bottom: 0px;
  }
}
.VideoPersonScreen .video-action {
  display: flex;
  margin: 0 10px;
}
.VideoPersonScreen .video-scale {
  display: flex;
}
.VideoPersonScreen .video-action .middle-link {
  width: 54px;
  height: 54px;
  background: transparent;
  border: 0;
  outline: 0;
  border-radius: 100%;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
}
.VideoPersonScreen .video-action .middle-link IMG {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.VideoPersonScreen .video-action .middle-link:not(:last-child) {
  margin-right: 42px;
}
.VideoPersonScreen .video-bottom .scale-screen {
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 32px;
  height: 32px;
  background: transparent;
}
.VideoPersonScreen .video-bottom .scale-screen IMG {
  width: 100%;
  height: 100%;
}
.VideoPersonScreen .video-bottom .video-timing {
  display: flex;
  align-items: center;
}
.VideoPersonScreen .video-bottom .video-timing SPAN {
  font-size: 36px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 0.89;
  letter-spacing: normal;
  color: #013648;
  margin-top: 16px;
  position: relative;
  min-width: 100px;
}
.VideoPersonScreen .video-bottom .video-timing IMG {
  width: 22px;
  height: 22px;
  margin-right: 14px;
}
.VideoPersonScreen .video-sm-timing {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 125px;
}
.VideoPersonScreen .video-sm-timing IMG {
  width: 13px;
  height: 13px;
  margin-right: 7px;
  position: relative;
  top: -4px;
}
.VideoPersonScreen .video-sm-timing SPAN {
  font-size: 20.2px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 0.89;
  letter-spacing: normal;
  color: #ffffff;
}
.VideoPersonScreen .video-disable {
  display: none;
}

@media (max-width: 1280px) {
  .VideoPersonScreen .video-bottom {
    max-width: 826px;
  }
}

@media (max-width: 1199px) {
  .VideoPersonScreen .video-bottom {
    max-width: 90%;
    padding: 21px 20px 24px;
  }
  .VideoPersonScreen .video-action .middle-link:not(:last-child) {
    margin-right: 20px;
  }
  .VideoPersonScreen .video-action .middle-link {
    width: 45px;
    height: 45px;
  }
  .VideoPersonScreen .video-bottom .video-timing IMG {
    margin-right: 8px;
  }
  .VideoPersonScreen .video-bottom .video-timing SPAN {
    font-size: 30px;
    margin-top: 14px;
  }
  .VideoPersonScreen .other-participant {
    width: 240px;
    height: 146px;
  }
  .VideoPersonScreen .other-participant IMG {
    margin-bottom: 13px;
  }
  .VideoPersonScreen .other-participant .participant-name {
    font-size: 20px;
  }
  .VideoPersonScreen .video-bottom .scale-screen {
    width: 29px;
    height: 29px;
  }
}

@media (max-width: 767px) {
  .VideoPersonScreen .video-bottom {
    max-width: 268px;
    justify-content: center;
    padding: 15px 30px 14px;
    bottom: 31px;
  }
  .VideoPersonScreen .video-bottom.video-hide {
    display: none;
  }
  .VideoPersonScreen .videoscreen-inner {
    height: auto;
    position: absolute;
    top: 108px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .VideoPersonScreen .video-action .middle-link {
    width: 49px;
    height: 49px;
  }
  .VideoPersonScreen .video-action {
    margin: 0;
  }
  .VideoPersonScreen .video-action .middle-link:not(:last-child) {
    margin-right: 30px;
  }
  .VideoPersonScreen .other-participant IMG {
    width: 40px;
    height: 61px;
    margin-bottom: 0;
  }
  .VideoPersonScreen .other-participant {
    width: 99px;
    height: 178px;
    border-radius: 4.8px;
  }
  .VideoPersonScreen .other-participant VIDEO {
    border-radius: 4.8px;
  }
  .VideoPersonScreen .other-participant .participant-name {
    display: none;
  }
  .VideoPersonScreen .novideo-name {
    top: 44.5%;
    font-size: 15px;
  }
  .VideoPersonScreen .video-bottom .video-timing {
    display: none;
  }
  .VideoPersonScreen .video-scale {
    display: none;
  }
  .VideoPersonScreen .video-sm-timing {
    display: flex;
  }
  .VideoPersonScreen .video-sm-timing.video-hide {
    display: none;
  }
  @keyframes moveDown {
    0% {
      bottom: 31px;
    }
    100% {
      bottom: 0px;
    }
  }
  @keyframes moveUp {
    0% {
      bottom: 0px;
    }
    100% {
      bottom: 31px;
    }
  }
}

@media (max-width: 413px) {
  .VideoPersonScreen .novideo-name {
    font-size: 19px;
    padding: 0 15px;
  }
  .VideoPersonScreen .other-participant {
    height: 145px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .VideoPersonScreen .video-action .middle-link {
    width: 35px;
    height: 39px;
  }
  .VideoPersonScreen .video-action .middle-link:not(:last-child) {
    margin-right: 20px;
  }
  .VideoPersonScreen .video-bottom {
    max-width: 90%;
    padding: 7px 20px 7px;
    bottom: 10px;
  }
  .VideoPersonScreen .video-sm-timing {
    bottom: 69px;
  }
  .VideoPersonScreen .novideo-name {
    font-size: 28px;
    top: 63.5%;
  }
  @keyframes moveDown {
    0% {
      bottom: 10px;
    }
    100% {
      bottom: 0px;
    }
  }
  @keyframes moveUp {
    0% {
      bottom: 0px;
    }
    100% {
      bottom: 10px;
    }
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .VideoPersonScreen .other-participant {
    height: 118px;
  }
  .VideoPersonScreen .video-bottom {
    max-width: 210px;
  }
  .VideoPersonScreen .novideo-name {
    top: 53.5%;
    font-size: 15px;
  }
}
