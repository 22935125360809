.PatientSignUpStepOne .login-btn {
  margin-bottom: 47px;
  margin-top: 28px;
}
.PatientSignUpStepOne .login-btn > * {
  width: 100%;
  height: 45px;
}
.PatientSignUpStepOne .login-btn > button {
  line-height: 45px;
}
.PatientSignUpStepOne .login-bottom {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #013648;
}
.PatientSignUpStepOne .login-bottom A {
  color: #3dbce7;
  font-weight: 500;
}
.PatientSignUpStepOne .password-group .form-control {
  padding-right: 35px;
}
.PatientSignUpStepOne .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.PatientSignUpStepOne .ca-social .social-icon {
  display: inline-block;
  max-width: 100%;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  background-color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  cursor: pointer;
}
.PatientSignUpStepOne .ca-social {
  margin-bottom: 21px;
}
.PatientSignUpStepOne .ca-social .social-icon IMG {
  margin-right: 11px;
}
.PatientSignUpStepOne .divider-content {
  position: relative;
  margin-bottom: 21px;
}
.PatientSignUpStepOne .divider-content .Divider .container {
  width: 100%;
}
.PatientSignUpStepOne .divider-content > SPAN {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #72787d;
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fbfbf9;
  padding: 9px;
  display: inline-block;
}
.PatientSignUpStepOne .Input-control.transform-input INPUT {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.PatientSignUpStepOne .Input-control.transform-input INPUT:focus {
  border: solid 1px #013648;
  box-shadow: none;
}
.PatientSignUpStepOne .password-label {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  padding-left: 24px;
  width: 322px;
  max-width: 100%;
}
.termsofuse .checkbox-block {
  display: flex;
  margin-bottom: 10px;
}
.termsofuse .checkbox-block .Checkbox-control {
  margin-right: 4px;
  top: -4px;
}
.termsofuse .checkbox-block .p-4{
  margin: 0 4px;
}
.termsofuse .modal-content-header .close img {
  width: 70%;
}
@media (hover: hover) {
  .PatientSignUpStepOne .login-bottom A:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .PatientSignUpStepOne .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .PatientSignUpStepOne .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .PatientSignUpStepOne .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .PatientSignUpStepOne .login-btn {
    margin-bottom: 30px;
  }
}
