.Input-control {
  position: relative;
  margin-bottom: 12px;
}
.Input-control .form-control {
  color: #000;
  border: none;
  padding: 5px 25px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 52px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #18222a;
  -webkit-appearance: none;
}
.Input-control .form-control:focus {
  outline: none;
}
.Input-control.transform-input .form-control:focus {
  border-color: #013648;
  padding: 20px 25px 5px;
}
.Input-control .form-control:focus ~ .label,
.Input-control .form-control:not(:placeholder-shown) ~ .label {
  top: calc(25% - 0.5rem);
  transform: translate(0, 0%);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.26px;
  color: rgba(1, 54, 72, 0.5);
}
.Input-control .label {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(5px, -50%);
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
}

.Input-control .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}
.Input-control .form-control:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

.Input-control .view-img {
  position: absolute;
  right: 17px;
  width: 21px;
  height: 13px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}
.Input-control.form-group {
  margin-bottom: 23px;
}
.Input-control.form-group,
.Input-control.form-group .label,
.Input-control.form-group input {
  width: 100%;
  display: inline-block;
}
.Input-control.form-group input {
  padding: 3px 15px 0px 25px;
  height: 52px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.Input-control.form-group input.KalyMDRequestForm:read-only{
  background-color: #e8e8e8;
  cursor: not-allowed;
}
.Input-control.form-group .form-control:focus,
.Input-control.form-group .form-control:not(:placeholder-shown) {
  /* padding: 0px 15px; */
  border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.Input-control.form-group .form-control:focus {
  border: solid 1.5px #013648;
}
.Input-control.form-group .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.Input-control.form-group .view-img {
  top: calc(100% - 2rem);
}

.Input-control.link-container {
  position: relative;
}
.Input-control.link-container input {
  padding-right: 76px;
}
.Input-control.link-container .lookup-link {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
  position: absolute;
  right: 27px;
  top: 40px;
}

.Input-control .instruction-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 13px;
}
.Input-control .error-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-size: 13px;
}
.Input-control.form-group.error input.form-control,
.Input-control.error input.form-control {
  border-color: rgba(255, 0, 0, 0.4) !important;
}

.Input-control.transform-input {
  margin-bottom: 20px;
}
.Input-control.transform-input .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}
.Input-control.transform-input .form-control::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
}
.Input-control.transform-input .form-control:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}
.Input-control.transform-input .form-control:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
}
.Input-control.filled-focus-input .label  {
  top: calc(25% - 0.5rem);
  transform: translate(0, 0%);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.26px;
  color: rgba(1, 54, 72, 0.5);
}
.Input-control .form-control[disabled] {
  background-color: #f8f8f8;
  border-color: transparent !important;
}
/* edge issue resolved for transform input */
.Input-control.transform-input .label {
  position: absolute;
  left: 25px;
  top: 15px;
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
  transform: none;
}
.Input-control.transform-input.filled-focus-input .label {
  top: 6px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.26px;
  color: rgba(1, 54, 72, 0.5);
  transform: none;
}
.Input-control.transform-input.filled-focus-input .form-control {
  padding: 20px 25px 5px;
}
.Input-control .charectercount-text {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 1.38;
  color: #b2bcc4;
}
.Input-control.url-link input {
  padding-right: 45px;
}
.Input-control.url-link .angle-up,
.Input-control.url-link .angle-down {
  width: 18px;
  position: absolute;
  right: 20px;
  top: 26px;
}
.Input-control.url-link .angle-up {
  top: 10px;
  transform: rotate(180deg);
}
.Input-control SPAN.label IMG {
  width: 20px;
  position: relative;
  top: 6px;
  float: right;
  top: 0;
}

@media (hover: hover) {
  .Input-control.link-container .lookup-link:hover {
    color: #013648;
  }
}

@media screen and (max-width: 767px) {
  .Input-control.form-group input {
    padding: 3px 15px 0px 10px;
    font-size: 16px;
  }
  .Input-control.link-container .lookup-link {
    right: 11px;
  }
}
