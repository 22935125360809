.DoctorVideoExit {
  width: 100%;
  padding: 120px 20px;
}
.DoctorVideoExit .sub-heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 14px;
}
.DoctorVideoExit .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 77px;
}
.DoctorVideoExit .patient-list-block {
  max-width: 265px;
  width: 100%;
  margin: 0 auto;
}
.DoctorVideoExit .patient-list-block .patient-heading {
  font-size: 20px;
  line-height: 1.01;
  letter-spacing: -0.22px;
  color: #013648;
  margin-bottom: 29px;
  position: relative;
  padding-left: 29px;
}
.DoctorVideoExit .patient-list-block .patient-heading .person-img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -2px;
  left: 0;
}
.DoctorVideoExit .patient-list-block .appname-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.DoctorVideoExit .appname-block .appname-left {
  flex: 1;
  margin-right: 10px;
}
.DoctorVideoExit .appname-block .appoinment-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #013648;
}
.DoctorVideoExit .appname-block .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 1.25;
  color: #013648;
  display: block;
}
.DoctorVideoExit .appname-block .patient-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #26acda;
  cursor: pointer;
}
.DoctorVideoExit .link-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.DoctorVideoExit .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  display: inline-block;
}
.DoctorVideoExit .rejoin-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #3d93a6;
  max-width: 135px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #3d93a6;
  display: inline-block;
}
.DoctorVideoExit .room-txt {
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #72787d;
}
.DoctorVideoExit .no-room-txt {
  float: right;
}
.DoctorVideoExit .patient-list:not(:last-child) {
  border-bottom: 1px solid #e7ebeb;
  padding-bottom: 29px;
  margin-bottom: 17px;
}
@media (hover: hover) {
  .DoctorVideoExit .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
  .DoctorVideoExit .rejoin-videolink:hover {
    background: #3d93a6;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .DoctorVideoExit {
    padding: 80px 20px;
  }
}

@media (max-width: 767px) {
  .DoctorVideoExit {
    padding: 45px 30px;
  }
  .DoctorVideoExit .sub-heading {
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 8px;
  }
  .DoctorVideoExit .heading2 {
    margin-bottom: 87px;
  }
  .DoctorVideoExit .patient-list-block .patient-heading {
    font-size: 22px;
    letter-spacing: -0.24px;
    margin-bottom: 40px;
  }
  .DoctorVideoExit .appname-block .patient-name {
    font-size: 19.8px;
    letter-spacing: 0.12px;
  }
  .DoctorVideoExit .appname-block .patient-link {
    font-size: 17.6px;
    letter-spacing: 0.11px;
  }
  .DoctorVideoExit .appname-block .appoinment-time {
    font-size: 22px;
    letter-spacing: 0.18px;
  }
  .DoctorVideoExit .rejoin-videolink {
    border-radius: 4.4px;
    font-size: 17.6px;
    letter-spacing: -0.23px;
    border: solid 1.1px #3d93a6;
    max-width: 146px;
    padding: 7px 10px 0px;
  }
  .DoctorVideoExit .start-videolink {
    border-radius: 4.4px;
    font-size: 17.6px;
    letter-spacing: -0.23px;
    border: solid 1.1px #8d77ba;
    max-width: 140px;
    padding: 7px 10px 0px;
  }
  .DoctorVideoExit .room-txt {
    font-size: 19.8px;
    letter-spacing: 0.17px;
  }
  .DoctorVideoExit .patient-list:not(:last-child) {
    margin-bottom: 44px;
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .DoctorVideoExit .patient-list-block {
    max-width: 100%;
  }
}

@media (max-width: 413px) {
  .DoctorVideoExit {
    padding: 45px 15px;
  }
  .DoctorVideoExit .heading2 {
    font-size: 32px;
  }
}
