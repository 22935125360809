.ProviderProfile .profile-section {
  display: flex;
  margin: 0;
}
.ProviderProfile .profile-section .middlepanel {
  max-width: 100%;
  width: 607px;
  padding: 0;
  margin: 0 auto;
}
.ProviderProfile .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  margin-bottom: 36px;
  cursor: pointer;
}
.ProviderProfile .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.ProviderProfile .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}
.ProviderProfile .disabled-section {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 1200px) {
  .ProviderProfile .profile-section {
    flex-wrap: wrap;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .ProviderProfile .profile-section {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .ProviderProfile .profile-section .middlepanel {
    width: 100%;
    padding: 0;
  }
}
