.CalendarHolidayEvent {
  text-align: center;
  /* padding: 5px; */
  /* padding-top: 7px; */
  /* background-color: rgb(121, 134, 203); */
  /* color: #ffffff; */
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
  display: table;
  width: 100%;
  
}
.CalendarHolidayEvent span{
  display: table-cell;
    vertical-align: middle;
    background-color: rgb(121, 134, 203);
    color: #ffffff;
    height: 100%;
    padding: 8px 5px;
    vertical-align: middle;
}