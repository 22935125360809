.AdminLayout .wrap-content-row {
  display: flex;
  padding-top: 70px;
  background-color: #fafafb;
  min-height: 100vh;
}
.AdminLayout .main-content .main-inner-content {
  padding: 34px 40px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.AdminLayout .search INPUT {
  padding: 3px 15px 0px 25px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.AdminLayout .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.AdminLayout .search BUTTON:focus {
  outline: 0;
}
.AdminLayout .table-overflow {
  overflow: auto;
  width: 100%;
  box-shadow: 0px 0px 4px 0px #cacaca;
}
.AdminLayout .admin-provider-list TD {
  padding: 15px 15px 12px;
}
.AdminLayout .admin-provider-list TR {
  border-bottom: 1px solid #d8d8d8;
}

@media screen and (max-width: 768px) {
  .AdminLayout .AdminPatients,
  .AdminLayout .AdminJoinUs,
  .AdminLayout .ImportProvider,
  .AdminLayout .Configuration,
  .AdminLayout .Languages,
  .AdminLayout .Suffix,
  .AdminLayout .InsuranceCompany,
  .AdminLayout .InsurancePlans,
  .AdminLayout .GhostProfiles,
  .AdminLayout .AdminProviders{
    width: 100%;
  }
  .AdminLayout .AdminPatients .dashboard-panle,
  .AdminLayout .AdminJoinUs .dashboard-panle,
  .AdminLayout .ImportProvider .dashboard-panle,
  .AdminLayout .Configuration .dashboard-panle,
  .AdminLayout .Languages .dashboard-panle,
  .AdminLayout .dashboard-panle .dashboard-panle,
  .AdminLayout .InsuranceCompany .dashboard-panle,
  .AdminLayout .InsurancePlans .dashboard-panle,
  .AdminLayout .GhostProfiles .dashboard-panle,
  .AdminLayout .AdminProviders .dashboard-panle{
    padding-left: 20px;
  }
  .AdminLayout .AdminPatients .main-content .main-inner-content,
  .AdminLayout .AdminJoinUs .main-content .main-inner-content,
  .AdminLayout .ImportProvider .main-content .main-inner-content,
  .AdminLayout .Configuration .main-content .main-inner-content,
  .AdminLayout .Languages .main-content .main-inner-content,
  .AdminLayout .Suffix .main-content .main-inner-content,
  .AdminLayout .InsuranceCompany .main-content .main-inner-content,
  .AdminLayout .InsurancePlans .main-content .main-inner-content,
  .AdminLayout .GhostProfiles .main-content .main-inner-content,
  .AdminLayout .AdminProviders .main-content .main-inner-content{
    padding: 34px 20px 12px;
  }
}