 .TeamCard .name {
  font-family: 'Heldane Display';
  font-size: 18px;
  line-height: 1.2;
  color: #013648;
  margin-top: 25px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 5px;
}
.TeamCard .place {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #013648;
  width: 100%;
  margin-bottom: 5px;
}
.TeamCard .description {
  font-size: 16px;
  line-height: 1.25;
  color: rgba(1, 54, 72, 0.7);
  margin-top: 0;
  width: 100%;
}
.TeamCard {

  text-align: center;
  /* margin: 0 0 0 98px; */
  padding: 0 30px;
}

.TeamCard .team-img {
 
  display: flex;
}
.TeamCard .team-img img {
  /* margin: auto 0;
  max-width: 100%;
  width: 250px;
  height: 280px;
  object-fit: cover; */
  /* width: 100%;*/
  object-fit: cover; 
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

/*
 @media (max-width: 1446px) { 
  .TeamCard{
   margin: 0 20px 0 73px;
  }
 
}
@media (max-width: 1400px) { 
  .TeamCard{
    margin: 0 21px 0 68px;
  }
 
}
@media (max-width: 1100px) { 
  .TeamCard{
        margin: 0 14px 0 66px;
  }
 
}
@media (max-width: 1070px) { 
  .TeamCard{
        margin: 0 14px 0 63px;
  }
 
}
@media (max-width: 1046px) { 
  .TeamCard{
    margin: 0 0 0 54px;
  }
 
}
@media (max-width: 996px) { 
  .TeamCard{
    margin: 0 0 0 52px;
  }
 
}
@media (max-width: 990px) { 
  .TeamCard{
    margin: 0 0 0 49px;
  }
 
}
@media (max-width: 980px) { 
  .TeamCard{
    margin: 0 0 0 46px;
  }
 
}
@media (max-width: 970px) { 
  .TeamCard{
    margin: 0 0 0 46px;
  }
 
}
@media (max-width:966px) { 
  .TeamCard{
    margin: 0 0 0 43px;
  }
  
}
@media (max-width:964px) { 
  .TeamCard{
    margin: 0 0 0 42px;
  }
  
}
@media (max-width:956px) { 
  .TeamCard{
    margin: 0 0 0 40px;
  }
  
}
@media (max-width:950px) { 
  .TeamCard{
    width: 220px;
  }
  .TeamCard .team-img{
    width: 220px;
  }
}
@media (max-width:900px) { 
  .TeamCard{
    margin: 0 0 0 38px;
  }
  
}
@media (max-width:890px) { 
  .TeamCard{
    margin: 0 0 0 36px;
  }
  
}
@media (max-width:878px) { 
  .TeamCard{
    margin: 0 0 0 34px;
  }
  
}
@media (max-width:870px) { 
  .TeamCard{
    margin: 0 0 0 32px;
  }
  
}
@media (max-width:865px) { 
  .TeamCard{
    margin: 0 0 0 30px;
  }
  
}
@media (max-width:860px) { 
  .TeamCard{
    margin: 0 70px 0 70px;
  }
  
}
@media (max-width:767px) { 
  .TeamCard{
    margin: 0 58px 0 72px;
  }
  
}
@media (max-width:756px) { 
  .TeamCard{
    margin: 0 58px 0 66px;
  }
  
}
@media (max-width:740px) { 
  .TeamCard{
    margin: 0 55px 0 66px;
  }
  
}
@media (max-width:737px) { 
  .TeamCard{
    margin: 0 46px 0 66px;
  }
  
}
@media (max-width:729px) { 
  .TeamCard{
    margin: 0 4px 0 66px;
  }
  
}
@media (max-width:724px) { 
  .TeamCard{
    margin: 0 35px 0 66px;
  }
  
}
@media (max-width:717px) { 
  .TeamCard{
    margin: 0 35px 0 56px;
  }
  
}
@media (max-width:697px) { 
  .TeamCard{
    margin: 0 35px 0 54px;
  }
  
}
@media (max-width:693px) { 
  .TeamCard{
    margin: 0 35px 0 51px;
  }
  
}
@media (max-width:687px) { 
  .TeamCard{
    margin: 0 35px 0 48px;
  }
  
}
@media (max-width:680px) { 
  .TeamCard{
    margin: 0 35px 0 42px;
  }
  
}
@media (max-width:669px) { 
  .TeamCard{
    margin: 0 35px 0 42px;
  }
  
} */
@media (max-width:665px) { 
  .TeamCard{
 
    margin: 0 auto;
  }
  .TeamCard .team-img {
    width: auto;
  
    /* padding: 0 30px; */
}
}
@media (max-width:450px) { 
  .TeamCard .team-img {
    width: auto;
 
    /* padding: 0 30px; */
}
}
@media (max-width:350px) { 
  .TeamCard .team-img {
    width: auto;
   
    /* padding: 0 30px; */
}
}
/* @media (max-width:500px) { 
  .TeamCard{
    width: 52vw;
    margin: 0 36px;
  }
  
}
 */
/*@media (max-width: 991px) {
  .TeamCard .team-img img {
    height: 245px;
  }
}
@media (max-width: 767px) {
  .TeamCard,
  .TeamCard .description,
  .TeamCard .name,
  .TeamCard .place {
    width: 250px;
  }  
  .TeamCard .name {
    font-size: 18px;
    line-height: 1.22;
  }
  .TeamCard .place {
    font-size: 16px;
    line-height: 1.25;
  }
  .TeamCard .description {
    font-size: 16px;
    line-height: 1.25;
    margin-top: 12px;
  }
}
@media (max-width: 576px) {
  .TeamCard,
  .TeamCard .description,
  .TeamCard .name,
  .TeamCard .place {
    width: 250px;
  } 
} */
