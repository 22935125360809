.ProviderItem {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #f7f9f9;
}
.ProviderItem .provider-inner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 76.4px;
  padding: 15px 88px 15px 83px;
  cursor: pointer;
}
.ProviderItem .provider-image {
  min-width: 35px;
  width: 35px;
  display: flex;
  height: 54px;
  position: absolute;
  left: 20px;
  align-items: center;
}
.ProviderItem .provider-image img {
  border-radius: 3px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ProviderItem .name {
  position: relative;
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
}
.ProviderItem .dot-icon {
  width: 20px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 40%;
  background-color: #f7f9f9;
}
.ProviderItem .dot-icon img {
  width: 100%;
}
.ProviderItem .dot-icon .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
}
.ProviderItem .dot-icon.show-menu .dropdown-menu {
  display: block;
}
.ProviderItem .dot-icon .dropdown-menu span {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ProviderItem .dot-icon .dropdown-menu span.remove-user {
  color: #e85d72;
}
.ProviderItem .status {
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #013648;
}
.ProviderItem .status .status-text {
  display: inline-block;
  color: #3d93a6;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderItem .status .active {
  color: #3d93a6;
}
.ProviderItem .status .pending {
  color: #f2962d;
}
.ProviderItem .status .inactive {
  color: #e85d72;
}
.ProviderItem .email {
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #72787d;
}
.ProviderItem .profile-progress {
  display: flex;
  align-items: center;
}
.ProviderItem .profile-progress .name {
  font-size: 16px;
  line-height: 2.13;
  letter-spacing: -0.2px;
  color: #013648;
  width: 115px;
  margin-right: 12px;
  text-align: right;
}
.ProviderItem .profile-progress .number {
  font-size: 19px;
  line-height: 0.71;
  letter-spacing: normal;
  color: #013648;
  margin-left: 22px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.ProviderItem .profile-progress .progressBar .progress-bar {
  background-color: #3d93a6;
  border-radius: 7px;
}
.ProviderItem .profile-progress .progressbar {
  width: 105px;
  border-radius: 7px;
}
.ProviderItem .profile-progress.active .progressbar .ProgressBar .progress-bar {
  background-color: #3d93a6;
}
.ProviderItem .profile-progress.active .name {
  color: #013648;
}
.ProviderItem .profile-progress.active .number {
  color: #013648;
}
.ProviderItem .profile-progress.inactive .progressbar .ProgressBar .progress-bar {
  background-color: #e85d72;
}
.ProviderItem .profile-progress.inactive .name {
  color: #e85d72;
}
.ProviderItem .profile-progress.inactive .number {
  color: #e85d72;
}
.ProviderItem .profile-progress.pending .progressbar .ProgressBar .progress-bar {
  background-color: #f2962d;
}
.ProviderItem .profile-progress.pending .name {
  color: #f2962d;
}
.ProviderItem .profile-progress.pending .number {
  color: #f2962d;
}
.ProviderItem .member-detail {
  cursor: pointer;
  width: 55%;
}
.ProviderItem .member-rating {
  display: flex;
  align-items: center;
  position: relative;
}
.ProviderItem .overlay {
  z-index: 8;
}

@media (hover: hover) {
  .ProviderItem .dot-icon .dropdown-menu span:hover {
    background-color: #f2f2f2;
  }
}

@media screen and (max-width: 1199px) {
  .ProviderItem .member-detail {
    width: 100%;
  }
  .ProviderItem .profile-progress .name {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .ProviderItem .name {
    font-size: 16px;
  }
  .ProviderItem .dot-icon {
    top: 14%;
  }
  .ProviderItem .profile-progress .progressbar {
    width: 82px;
  }
  .ProviderItem .status .pending {
    display: inline-block;
    width: 100%;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 414px) {
  .ProviderItem .profile-progress {
    flex-wrap: wrap;
  }
  .ProviderItem .provider-inner-content {
    padding: 15px 32px 15px 50px;
  }
  .ProviderItem .provider-image {
    left: 7px;
  }
  .ProviderItem .dot-icon {
    right: 8px;
  }
  .ProviderItem .profile-progress .name {
    display: inline-block;
    width: 100%;
  }
}
