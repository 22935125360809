.Reviewsources .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Reviewsources {
  width: calc(100% - 250px);
}
.Reviewsources .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.Reviewsources .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Reviewsources .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Reviewsources .deleted-row {
  display: table-row;
  width: auto;
  clear: both;
  background-color: #f8e0e0;
}
.Reviewsources .ghostCount {
  top: auto;
  /* position: absolute; */
  margin-top: 15px;
  font-size: large;
  float: left;
}
.Reviewsources .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: transparent;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Reviewsources .heading .cell {
  background-color: #013648;
  color: white;
}
.Reviewsources .heading .cell:first-child {
  min-width: 150px;
}
.Reviewsources .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Reviewsources .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Reviewsources .cell.edit-cell {
  width: 20px;
}
.Reviewsources .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Reviewsources .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Reviewsources .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Reviewsources .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Reviewsources .pagination LI.previous.disabled,
.Reviewsources .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Reviewsources .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Reviewsources .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Reviewsources .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Reviewsources .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Reviewsources .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Reviewsources .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Reviewsources .pointer {
  cursor: pointer;
}
.Reviewsources img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Reviewsources .search {
  margin: 15px 0px;
  /* float: right; */
  display: flex;
  justify-content: space-between;
}
.Reviewsources .url-cell{
width: 200px;
display: block;
word-break: break-all;
}
.Reviewsources  .no-record{
  padding: 10px;
  font-size: 19px;
}
/* .Reviewsources .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Reviewsources .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.Reviewsources .search .Select-control SELECT {
  height: 40px;
}
/* .Reviewsources .input-control INPUT {
  height: 52px;
} */
.Reviewsources .Select-control.form-group:after {
  display: none;
}
.Reviewsources .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .Reviewsources .search div {
  position: relative;
} */
/* .Reviewsources .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Reviewsources .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Reviewsources .search button.btn-clear IMG {
  width: 15px;
}
.Reviewsources .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.Reviewsources .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Reviewsources .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.Reviewsources .search DIV {
  position: relative;
}
.Reviewsources .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.Reviewsources .btn-refresh {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2962d;
  border: none;
  margin-bottom: 5px;
}
.Reviewsources .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.Reviewsources .input-search {
  display: flex;
}
@media (max-width: 1024px) {
  .Reviewsources .refresh-btn {
    margin-right: 15px;
  }
  .Reviewsources .ghostCount {
    position: static;
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
.Reviewsources {
  width: calc(90%);
}
}
@media (max-width: 991px) {
  .Reviewsources .select-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .Reviewsources .input-search {
    width: 100%;
    display: flex;
  }
  .Reviewsources .search {
    float: none;
    flex-wrap: wrap;
  }
  .Reviewsources .input-search .input-control {
    flex: 1;
  }
  .Reviewsources .input-search .input-control INPUT {
    width: 100%;
  }
  .Reviewsources .search .Select-control {
    margin-right: 0px;
  }
}
