.SpecialityFlow {
  padding: 100px 60px 420px;
  display: flex;
  background-color: rgba(251, 251, 249, 0.8);
  position: relative;
  min-height: calc(100vh - 119px);
}
.SpecialityFlow .sf-sidebar .question-title {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.7;
  letter-spacing: normal;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.SpecialityFlow .sf-sidebar .question-title.answer {
  color: #b2bcc4;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.SpecialityFlow .sf-sidebar {
  display: flex;
  flex-direction: column;
  width: 150px;
  position: absolute;
  left: 60px;
  top: 104px;
}
.SpecialityFlow .sf-sidebar .arrow,
.SpecialityFlow > .arrow {
  position: absolute;
  top: -37px;
  left: 0;
  cursor: pointer;
}
.SpecialityFlow .sf-sidebar .arrow IMG,
.SpecialityFlow > .arrow IMG {
  width: 20px;
}
.SpecialityFlow > .arrow {
  display: none;
}
.SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
  font-family: 'Heldane Display';
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #013648;
  margin-bottom: 66px;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-inner-content .heading2 {
  margin-bottom: 46px;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-inner-content .description {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-action {
  display: block;
  text-align: center;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-action .CircleButton {
  margin: 0 13px;
}
.SpecialityFlow .sf-main-content .sf-list-content .sf-description-item SPAN {
  border-radius: 21.6px;
  background-color: rgba(1, 54, 72, 0.05);
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 100%;
  display: inline-block;
  margin-bottom: 18px;
  padding: 14px 24px 8px;
  border-top-left-radius: 0;
}
.SpecialityFlow .sf-main-content .sf-list-content .sf-description-item.active-item SPAN {
  background-color: #013648;
  color: #ffffff;
}
.SpecialityFlow .sf-main-content .sf-list-content.sf-description-content .sf-description-item SPAN {
  border-radius: 21.6px;
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 100%;
  display: inline-block;
  border-top-left-radius: 0;
  margin-bottom: 10px;
  padding: 0;
  background-color: transparent;
}
.SpecialityFlow
  .sf-main-content
  .sf-list-content.sf-description-content
  .sf-description-item:last-child
  SPAN {
  margin-bottom: 0;
}
.SpecialityFlow .sf-main-content .sf-list-content {
  width: 425px;
  margin: auto;
}
.SpecialityFlow .sf-main-content .sf-list-content.sf-description-content {
  border-radius: 4px;
  background-color: #f5f5f2;
  padding: 18px 24px;
}
.SpecialityFlow .sf-main-content {
  width: 700px;
  margin: 0 auto;
}
.SpecialityFlow .sf-main-content.sf-step-3 {
  width: 645px;
}
.SpecialityFlow .sf-main-content.sf-step-3 .pms-content .pms-list SPAN {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.85;
  color: #013648;
  text-align: left;
}
.SpecialityFlow .sf-action {
  display: flex;
  justify-content: space-around;
  width: 390px;
  margin: auto;
  margin-top: 36px;
}
.SpecialityFlow .sf-action .CircleButton.SecondaryButton {
  width: 136px;
  font-family: 'Calibre Regular';
}
.SpecialityFlow .sf-action .CircleButton.SecondaryButton:focus {
  outline: 0;
}
.SpecialityFlow .sf-select-answer.sf-list-content {
  width: 290px;
}
.SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.SpecialityFlow .sf-list-content.multi-col-option {
  width: 100%;
}
.SpecialityFlow .sf-step-3 .sf-select-answer.sf-list-content {
  margin-top: 25px;
}
.SpecialityFlow .sf-select-answer.sf-list-content.long-options {
  width: 478px;
  max-width: 100%;
}
.SpecialityFlow .sf-select-answer.sf-list-content .sf-description-item {
  display: inline-block;
  width: 100%;
}
.SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option .sf-description-item {
  width: 30%;
  margin: 0 10px;
}
.SpecialityFlow .sf-select-answer.sf-list-content .sf-description-item SPAN {
  border-radius: 21.6px;
  border-top-left-radius: 0;
  background-color: transparent;
  cursor: pointer;
  background-color: #e7ebeb;
}
.SpecialityFlow .sf-select-answer.sf-list-content .select-option SPAN {
  background-color: #013648;
  color: #ffffff;
}
@media (hover: hover) {
  .SpecialityFlow .sf-select-answer.sf-list-content .sf-description-item:hover SPAN {
    background-color: #013648;
    color: #ffffff;
  }
}
.SpecialityFlow .sf-select-answer.sf-action {
  text-align: center;
}
.SpecialityFlow .sf-action .select-option-text {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #72787d;
  font-family: 'TT Commons';
}
.SpecialityFlow .sf-main-content.sf-step-complete .select-option-text {
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.SpecialityFlow .sf-main-content.sf-step-complete .urgent-care-text {
  font-size: 20px;
  line-height: 2.65;
  color: #ef4444;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.SpecialityFlow .sf-main-content.sf-step-complete {
  width: 473px;
  /* margin-left: 116px; */
}
.SpecialityFlow .sf-main-content.sf-step-complete .sf-list-content {
  width: 473px;
}
.SpecialityFlow .sf-main-content.sf-step-complete .sf-inner-content .heading2 {
  margin-bottom: 44px;
}
.SpecialityFlow .sf-pain-select .Select-control.form-group SELECT {
  border-radius: 30.5px;
  border: solid 1.3px #013648;
  font-size: 18px;
  line-height: 3;
  letter-spacing: -0.34px;
  color: #013648;
}
.SpecialityFlow .sf-pain-select .Select-control .arrow {
  top: 24px;
}
.SpecialityFlow .sf-pain-select .Select-control.form-group:after {
  background-color: #fbfbf9;
}
.SpecialityFlow .sf-main-content .sf-list-content.pms-content {
  width: 592px;
}
.SpecialityFlow .pms-content .description {
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #013648;
  margin-bottom: 28px;
  display: inline-block;
}
.SpecialityFlow .pms-content .pms-list SPAN {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.85;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: HeldaneDisplay;
  font-size: 20px;
  line-height: 1.85;
}
.SpecialityFlow .pms-content .pms-list {
  margin-bottom: 36px;
  padding: 0 13%;
}
.SpecialityFlow .pms-content .Divider .container {
  width: 100%;
}
.SpecialityFlow .pms-content .Divider .container .horizontal-line {
  background-color: #b2bcc4;
}
.SpecialityFlow .pms-content .h3 {
  height: 39px;
  font-family: 'Heldane Display';
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #013648;
  margin-top: 30px;
  text-align: center;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.SpecialityFlow .sf-sidebar .doctor-info .doctor-img {
  width: 100px;
  height: 150px;
  display: inline-block;
  margin-top: 12px;
}
.SpecialityFlow .sf-sidebar .doctor-info .doctor-img IMG {
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.SpecialityFlow .sf-sidebar .doctor-info .doctor-name {
  display: inline-block;
  width: 100%;
  font-family: 'Heldane Display';
  font-size: 22px;
  line-height: 1.55;
  color: #013648;
  margin-top: 13px;
  margin-bottom: 3px;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT {
  width: 425px;
  max-width: 100%;
  height: 61px;
  border-radius: 6px;
  border: solid 1.3px #013648;
  background-color: #ffffff;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect .Input-control.form-group .view-img,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect .Input-control.form-group .view-img {
  left: 18px;
  top: calc(100% - 2.2rem);
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control::-webkit-input-placeholder,
.SpecialityFlow
  .sf-pain-select
  .AutoSuggestionMultiSelect
  INPUT.form-control::-webkit-input-placeholder {
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control::-moz-placeholder,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control:-ms-input-placeholder,
.SpecialityFlow
  .sf-pain-select
  .AutoSuggestionMultiSelect
  INPUT.form-control:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control:-moz-placeholder,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-main-content.description-without-gray-background {
  width: 592px;
  max-width: 100%;
}
.SpecialityFlow .sf-main-content.description-without-gray-background .heading2 {
  padding: 0px 10px;
  margin-bottom: 45px;
}
.SpecialityFlow .sf-main-content.description-without-gray-background span.description SPAN {
  text-align: center;
  display: inline-block;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.SpecialityFlow .sf-main-content.description-without-gray-background .sf-action {
  display: block;
  text-align: center;
  margin-top: 51px;
}
.SpecialityFlow .sf-main-content.description-without-gray-background .sf-action .CircleButton {
  margin: 0 13px;
}
.SpecialityFlow .multiselect-selected-options {
  margin: -5px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}
.SpecialityFlow .multiselect-selected-options.pain {
  width: 100%;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #abacac;
}
.SpecialityFlow .item-right {
  display: flex;
  justify-content: center;
}
.SpecialityFlow .error-msg {
  color: RGBA(255, 0, 0, 0.8);
  font-size: 18px;
  font-family: 'Calibre Regular';
  text-align: center;
}
.SpecialityFlow .item-right button[disabled] {
  color: #fff;
}
.SpecialityFlow .RequestaCallSMS button.SecondaryButton{
  width: 260px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 1199px) {
  .SpecialityFlow {
    padding: 100px 30px 420px;
  }
  .SpecialityFlow .sf-main-content {
    width: 670px;
  }
  .SpecialityFlow .sf-sidebar {
    left: 30px;
  }
  .SpecialityFlow .sf-main-content.sf-step-3,
  .SpecialityFlow .sf-main-content .sf-list-content.pms-content {
    width: 476px;
  }
}
.SpecialityFlow .kalymd-form.sf-action .CircleButton.SecondaryButton{
  width: 157px;
  /* font-size: 15px; */
}
@media (max-width: 1023px) {
  .SpecialityFlow .sf-sidebar .doctor-info .doctor-name {
    font-size: 18px;
  }
  .SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
    font-size: 32px;
    line-height: 36px;
  }
  .SpecialityFlow .sf-sidebar .question-title {
    font-size: 18px;
  }
  .SpecialityFlow .sf-sidebar {
    width: 17%;
  }
  .SpecialityFlow .sf-main-content {
    width: 400px;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background {
    width: 400px;
  }
  .SpecialityFlow .sf-main-content.sf-step-3,
  .SpecialityFlow .sf-main-content .sf-list-content.pms-content {
    width: 400px;
  }
  .SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option .sf-description-item {
    width: 48%;
    margin: 0px 4px;
  }
}

@media (max-width: 767px) {
  .SpecialityFlow {
    flex-wrap: wrap;
    padding: 63px 24px 100px;
  }
  .SpecialityFlow .sf-sidebar {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;
    left: 0;
  }
  .SpecialityFlow .sf-main-content.sf-step-complete .select-option-text {
    text-align: left;
    font-size: 18px;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background SPAN.description SPAN {
    text-align: left;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background .heading2 {
    padding: 0;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background .sf-action {
    text-align: left;
  }
  .SpecialityFlow
    .sf-main-content.description-without-gray-background
    .sf-action
    .CircleButton:first-child {
    margin-left: 0;
  }
  .SpecialityFlow span.sf-description-item{
    padding-left: 25%;
  }
  .SpecialityFlow .sf-list-content span.sf-description-item{
    padding-left: 0;
  }
  .SpecialityFlow .sf-main-content {
    margin-left: auto;
  }
  .SpecialityFlow .sf-main-content.sf-step-complete {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
    margin-bottom: 44px;
    text-align: center;
  }
  .SpecialityFlow .sf-main-content,
  .SpecialityFlow .sf-main-content .sf-list-content,
  .SpecialityFlow .sf-action {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-list-content {
    margin-left: 0;
  }
  .SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
    font-size: 28px;
    line-height: 32px;
  }
  .SpecialityFlow .sf-main-content.sf-step-complete .urgent-care-text {
    font-size: 24px;
    text-align: left;
    margin-top: 60px;
    font-family: 'Calibre Medium';
    line-height: 1.75;
  }
  .SpecialityFlow .sf-action .select-option-text {
    text-align: left;
    width: 100%;
  }
  .SpecialityFlow .sf-select-answer .sf-list-content .sf-description-item SPAN {
    width: 300px;
    border: solid 1px rgba(1, 54, 72, 0.2);
    font-family: 'Calibre Medium';
    max-width: 100%;
  }
  .SpecialityFlow .sf-select-answer .sf-action {
    justify-content: left;
    font-family: 'Calibre Medium';
  }
  .SpecialityFlow .sf-sidebar .arrow {
    display: none;
  }
  .SpecialityFlow > .arrow {
    display: block;
    top: 21px;
    left: 24px;
  }
  .SpecialityFlow .pms-content .description {
    font-size: 16px;
  }
  .SpecialityFlow .pms-content .description,
  .SpecialityFlow .pms-content .pms-list SPAN {
    text-align: left;
  }
  .SpecialityFlow .pms-content .pms-list SPAN {
    font-family: 'Calibre Medium';
    color: #013648;
    line-height: 1.22;
  }
  .SpecialityFlow .Divider {
    display: block;
  }
  .SpecialityFlow .pms-content .Divider .container {
    padding: 0;
  }
  .SpecialityFlow .pms-content .Divider .container .horizontal-line {
    background-color: rgba(1, 54, 72, 0.07);
  }
  .SpecialityFlow .pms-content .h3 {
    margin-top: 35px;
    font-size: 28px;
    height: auto;
    /* text-align: left; */
  }
  .SpecialityFlow .sf-action {
    justify-content: center;
    margin-top: 47px;
    margin-bottom: 39px;
  }
  .SpecialityFlow .pms-content .pms-list{
    padding: 0 5%;
  }
  .SpecialityFlow .sf-main-content.sf-step-3 .sf-action {
    /* text-align: left; */
  }
  .SpecialityFlow .sf-main-content.sf-step-3 .sf-action .CircleButton {
    margin-left: 0;
    margin-right: 26px;
    width: 100%;
    margin-bottom: 10px;
  }
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    margin-right: 40px;
  }
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton:last-child {
    margin-right: 0;
  }
  .SpecialityFlow .sf-main-content.sf-step-3 .sf-inner-content .description {
    text-align: left;
  }
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    width: 140px;
    background-color: #013648;
    color: #fff;
  }
  .SpecialityFlow .sf-action.complete-action .CircleButton.SecondaryButton {
    width: 100%;
  }
  .SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT,
  .SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-list-content .sf-description-item SPAN {
    margin-bottom: 13px;
    padding: 12px 22px 6px;
    letter-spacing: -0.2px;
  }
  .SpecialityFlow .sf-sidebar .doctor-info .doctor-img {
    width: 60px;
    height: 90px;
    position: ABSOLUTE;
    left: 0;
  }
  .SpecialityFlow .sf-sidebar .doctor-info .doctor-name {
    line-height: 1.22;
  }
  .SpecialityFlow .sf-sidebar .doctor-info {
    padding-left: 75px;
    position: relative;
  }
  .SpecialityFlow .sf-sidebar .doctor-info ~ SPAN {
    padding-left: 75px;
  }
  .SpecialityFlow .sf-main-content .sf-list-content.pms-content {
    width: 100%;
    margin: 0px;
  }
  .SpecialityFlow .sf-sidebar .question-title.answer {
    width: 100%;
    overflow: visible;
  }
}

@media (max-width: 600px) {
  .SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option .sf-description-item {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-list-content{
    text-align: center;
  }
  .SpecialityFlow .sf-main-content .sf-list-content .sf-pain-select{
    text-align: left;
  }
  .SpecialityFlow span.sf-description-item{
    padding-left: 0;
    text-align: left;
  }
}

@media (max-width: 375px) {
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    width: 115px;
  }
  .SpecialityFlow .pms-content .pms-list{
    padding: 0 
  }
}
@supports (-webkit-touch-callout: none) {
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    padding-top: 2px;
  }
}
