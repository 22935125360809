.InviteYourProviders .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.InviteYourProviders .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #013648;
  margin-bottom: 28px;
}
.InviteYourProviders .bottom-paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  text-align: center;
  color: rgba(1, 54, 72, 0.5);
  margin-bottom: 38px;
}
.InviteYourProviders .signup-btn {
  margin-bottom: 27px;
}
.InviteYourProviders .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
}
.InviteYourProviders .signup-bottom {
  text-align: center;
}
.InviteYourProviders .signup-bottom SPAN {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
  text-align: center;
  cursor: pointer;
}
.InviteYourProviders .providers-input {
  position: relative;
}
.InviteYourProviders .providers-instruction-text {
  position: absolute;
  left: 117px;
  top: 7px;
}
.InviteYourProviders .TagsInput .multiselectsearch-block {
  height: 322px;
}
.InviteYourProviders .Input-control .form-control {
  border-width: 1px;
}
.InviteYourProviders .Input-control.form-group .form-control:focus,
.InviteYourProviders .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.InviteYourProviders .Select-control.form-group SELECT {
  border-width: 1px;
}
@media (hover: hover) {
  .InviteYourProviders .signup-bottom SPAN:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .InviteYourProviders .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .InviteYourProviders .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .InviteYourProviders .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 30px;
    letter-spacing: normal;
  }
  .InviteYourProviders .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .InviteYourProviders .signup-btn .CircleButton {
    margin-top: 30px;
  }
  .InviteYourProviders .TagsInput {
    margin-bottom: 20px;
  }
}
