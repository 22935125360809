.EditVisitReasonModal {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 38px 0px 0px 75px;
  max-height: 723px;
  height: calc(100vh - 100px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 8px;
}
.EditVisitReasonModal .head-model {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}
.EditVisitReasonModal .head-model .icon {
  width: 18px;
  display: inline-block;
  margin-right: 10px;
  line-height: 2;
  position: relative;
  top: 4px;
}
.EditVisitReasonModal .head-model .icon IMG {
  width: 100%;
}
.EditVisitReasonModal .head-model SPAN {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  cursor: pointer;
}
.EditVisitReasonModal .head-model{
  background: white;
  position: fixed;
  /* width: 90%; */
}
.EditVisitReasonModal .pms-body{
  margin-top: 50px;
}
.EditVisitReasonModal .head-model .CircleButton {
  font-size: 16px;
  width: 111px;
  height: 32px;
  border-radius: 3px;
  line-height: 32px;
}
.EditVisitReasonModal .head-model .CircleButton.visitreasonSaveBtn {
  position: fixed;
  right: 50px;
}
.EditVisitReasonModal .pms-body {
  padding-top: 12px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 220px);
  padding-right: 42px;
}
.EditVisitReasonModal .pms-body .heading1 {
  font-family: 'Heldane Display';
  font-size: 36px;
  line-height: 0.94;
  color: #013648;
  margin-bottom: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 19px;
}
.EditVisitReasonModal .pms-body .pms-first-question {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.2px;
  color: #013648;
}
.EditVisitReasonModal .pms-body .pms-second-question {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 25px;
  border-top: solid 1px #b2bcc4;
  padding-top: 22px;
}
.EditVisitReasonModal .visit-reason-tab .tab-list {
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
  border-bottom: 1px solid rgba(156, 144, 144, 0.34);
  margin-top: 7px;
  display: flex;
  white-space: pre;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
.EditVisitReasonModal .visit-reason-tab .tab-list-item {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  margin-right: 30px;
  padding-bottom: 6px;
  display: inline-block;
  cursor: pointer;
}
.EditVisitReasonModal .visit-reason-tab .tab-list-active {
  background-color: #ffffff;
  opacity: 1;
  color: #013648;
  font-family: 'Calibre Medium';
}
.EditVisitReasonModal .visit-reason-tab .tab-list-item:last-child {
  padding-right: 14px;
}
.EditVisitReasonModal .add-time {
  margin-top: 22px;
}
.EditVisitReasonModal .add-time .Select-control .label {
  font-family: 'Calibre Regular';
}
.EditVisitReasonModal .add-time .Select-control.form-group SELECT {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  padding-left: 11px;
  padding-top: 8px;
  border: 1px solid #f8f8f8;
}
.EditVisitReasonModal .add-time .Select-control .arrow {
  right: 15px;
}
.EditVisitReasonModal .add-time .Select-control.form-group {
  width: 200px;
  margin-right: 30px;
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
}
.EditVisitReasonModal .add-time .Select-control.form-group:after {
  content: 'Minutes';
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.22;
  color: #b2bcc4;
  position: absolute;
  left: 40px;
  top: 43px;
  letter-spacing: -0.2px;
  background-color: transparent;
}
.EditVisitReasonModal .add-time .Select-control.form-group:before {
  content: '';
  background-color: #f8f8f8;
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  bottom: 0rem;
}
.EditVisitReasonModal .tab-content {
  margin-top: 16px;
  margin-bottom: 18px;
}
.EditVisitReasonModal .PainManagement-content .tab-list-item {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: normal;
  color: #b2bcc4;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-right: 28px;
  padding-bottom: 3px;
}
.EditVisitReasonModal .tab-content .visit-list {
  max-height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-left: 5px;
}
.EditVisitReasonModal .tab-content .visit-item .Checkbox-control .checkmark {
  border-radius: 1px;
  border: solid 1px #26acda;
}
.EditVisitReasonModal .tab-content .visit-item .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #26acda;
}
.EditVisitReasonModal .tab-content .visit-item > SPAN {
  margin-left: 30px;
  display: inline-block;
}
.EditVisitReasonModal .tab-content .visit-item {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
  margin-bottom: 9px;
}
.EditVisitReasonModal .tab-content .visit-item:last-child {
  margin-bottom: 0;
}
.EditVisitReasonModal .tab-content .visit-item:nth-child(1) .Checkbox-control {
  margin-top: 0px;
}
.EditVisitReasonModal .tab-content .visit-item .Checkbox-control {
  margin-top: 10px;
  color: #72787d;
}
.EditVisitReasonModal
  .tab-content
  .visit-item
  .Checkbox-control
  INPUT:checked
  ~ SPAN:not(.checkmark) {
  color: #013648;
}

@media screen and (max-width: 767px) {
  .EditVisitReasonModal {
    padding-left: 20px;
    padding-top: 25px;
  }
  .EditVisitReasonModal .head-model {
    padding-right: 20px;
    flex-wrap: wrap;
  }
  .EditVisitReasonModal .pms-body {
    padding-right: 20px;
  }
  .EditVisitReasonModal .pms-body .heading1 {
    font-size: 28px;
  }
  .EditVisitReasonModal .add-time .Select-control.form-group {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 550px) {
  .EditVisitReasonModal .head-model .CircleButton.visitreasonSaveBtn {
    right: 25px;
  }
}
@media screen and (max-width: 400px) {
  .EditVisitReasonModal .head-model .CircleButton.visitreasonSaveBtn {
    right: 9px;
  }
}
