.CancelAppointmentModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 586px;
  margin: auto;
}
.CancelAppointmentModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.CancelAppointmentModal .DoctorAppointmentTime .appointment-time {
  border: solid 0.8px #e85d72;
  background-color: #e85d72;
  color: #f7fffc;
}
.CancelAppointmentModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.CancelAppointmentModal .modal-content-header .close img {
  width: 100%;
}
.CancelAppointmentModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.CancelAppointmentModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.CancelAppointmentModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.CancelAppointmentModal .Divider {
  margin-top: 10px;
}

.CancelAppointmentModal .Divider .container {
  width: auto;
}
.CancelAppointmentModal .cancel-reason {
  margin-top: 18px;
  padding: 0px 50px;
}
.CancelAppointmentModal .cancel-reason .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  line-height: 1.61;
  letter-spacing: -0.18px;
  color: #013648;
  margin-bottom: 19px;
}
.CancelAppointmentModal .cancel-reason .cancel-reason-list > div {
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: -0.34px;
  color: #013648;
  min-height: 47px;
  border-radius: 21.6px;
  border: solid 1px #b2bcc4;
  margin-bottom: 12px;
  padding: 9px 24px 12px;
  border-top-left-radius: 0;
  cursor: pointer;
}
.CancelAppointmentModal .cancel-reason .cancel-reason-list > div.selected {
  background-color: #013648;
  color: #fff;
}
.CancelAppointmentModal .keep-appointment span {
  margin-top: 28px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
}

@media (hover: hover) {
  .CancelAppointmentModal .cancel-reason .cancel-reason-list > div:hover {
    background-color: #013648;
    color: #fff;
  }
  .CancelAppointmentModal .keep-appointment span:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  .CancelAppointmentModal {
    width: 100%;
  }
  .CancelAppointmentModal .cancel-reason {
    padding: 0px 0px;
  }
  .CancelAppointmentModal .modal-content-body {
    padding: 29px 20px;
  }
  .CancelAppointmentModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .CancelAppointmentModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .CancelAppointmentModal .modal-content-header {
    padding-left: 20px;
  }
  .CancelAppointmentModal .modal-content-body {
    max-height: calc(100vh - 184px);
  }
}
@media (max-width: 413px) {
  .CancelAppointmentModal .DoctorAppointmentTime {
    flex-wrap: wrap;
  }
  .CancelAppointmentModal .DoctorAppointmentTime .dc-info {
    padding-left: 0;
    width: 100%;
    margin-top: 10px;
  }
}
