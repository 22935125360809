.ReviewAccounts {
  width: 100%;
}
.ReviewAccounts .main-section-content {
  padding: 0 20px 0 40px;
}
.ReviewAccounts .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.ReviewAccounts .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
}
.ReviewAccounts .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewAccounts .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
}
.ReviewAccounts .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.ReviewAccounts .ReviewAccounts-content {
  padding: 0 40px;
}
.ReviewAccounts .box-panel {
  padding: 40px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  margin-top: 31px;
  margin-bottom: 30px;
}
.ReviewAccounts .tab-content {
  position: relative;
}
.ReviewAccounts .AfterAuthSubHeader .main-inner-content {
  padding-right: 460px;
}
.ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
  min-width: 160px;
}
.ReviewAccounts .ReviewAccounts-content {
  position: relative;
}
@media (max-width: 1400px) {
  .ReviewAccounts .box-panle {
    padding: 40px 20px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    right: 20px;
  }
  .ReviewAccounts .box-panle .search-review-list .Input-control {
    width: 230px;
  }
}

@media (max-width: 1200px) {
  .ReviewAccounts .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    top: 36px;
  }
  .ReviewAccounts .main-section-content {
    padding: 0 20px 0 20px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
    min-width: 134px;
  }
  .ReviewAccounts .ApprovalCard {
    padding: 11px 10px 18px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span {
    margin-right: 15px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span:last-child {
    margin-right: 0;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-right: 440px;
  }
}

@media (max-width: 1024px) {
  .ReviewAccounts .main-section-content {
    padding: 0 15px 0;
  }
  .ReviewAccounts .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
  .ReviewAccounts .ReviewAccounts-content {
    padding: 0px 15px;
  }
  .ReviewAccounts .box-panel {
    padding: 15px;
  }
  .ReviewAccounts {
    width: calc(100% - 200px);
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
    padding-right: 420px;
  }
  .ReviewAccounts .ApprovalCard {
    top: -150px;
    right: 15px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .ReviewAccounts .box-panle {
    padding: 20px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    margin-top: 10px;
  }
  .ReviewAccounts {
    width: 100%;
  }
  .ReviewAccounts .setting-tabs .tab-list-item {
    margin-right: 20px;
    font-size: 18px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .ReviewAccounts .ApprovalCard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span {
    margin-right: 10px;
  }
}
