.doctorNearMe{
 /* background-color: #fbfbf9;  */
 padding: 30px 0 60px 0;
 min-height: 210px;

}
.doctorNearMe .doctor-locations-div{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.doctorNearMe .doctor-locations-div .parent-label{
  width: 95%;
  display: block;
  padding: 10px 0 10px 0;
  font-size: 20px;
  position: relative;
  /* border-bottom: 1px solid #9999993b; */
  cursor: pointer;
  color: #013648;
}
/* .doctorNearMe .doctor-locations-div .parent-label:hover{
  background-color: black;
} */
.doctorNearMe .doctor-locations-div label.parent-label:last-of-type{
  /* width: 100%; */
}
.doctorNearMe .doctor-locations-div .parent-label .iconlabel{
  display: block;
  right: 10px;
  position: absolute;
  top: 12px;
}

.doctorNearMe .doctor-locations-div ul.each-spacility {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  opacity: 1;
  height: 100%;
  /* height: 0%;*/
  transition: all .5s ease; 
}
.doctorNearMe .doctor-locations-div .parent-doctorme.active ul.each-spacility{
  display: block;
  opacity: 1;
  /* transition: all 0.5s ease;
 
  height: 100%; */
}
.doctorNearMe .doctor-locations-div .parent-doctorme.active .parent-label{
  border: none;
  
}
.doctorNearMe .doctor-locations-div .parent-doctorme.active .parent-label .iconlabel img{
  transform: rotate(180deg);
}
.doctorNearMe .doctor-locations-div ul.each-spacility li {
  padding: 7px 0;
    font-size: 17px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-line: underline;
    text-decoration-style: dotted;
}
.doctorNearMe .doctor-locations-div ul.each-spacility li a {
color: #013648;
}
.doctorNearMe .doctor-locations-div ul.each-spacility li:hover {
  color:  rgb(43, 191, 237);
  text-decoration-style: solid;
}
.doctornearme-title{
  font-size: 30px;
}
@media (max-width: 1024px) {
  .doctorNearMe .doctor-locations-div{
  grid-template-columns:  1fr 1fr 1fr !important;
  }
}
@media (max-width: 767px) {
  .doctorNearMe .doctor-locations-div{
  grid-template-columns: 1fr 1fr !important;
  }
}
@media (max-width: 500px) {
  .doctorNearMe .doctor-locations-div{
  grid-template-columns: 1fr !important ;
  }
}