.PracticeLogoWrapper .PracticeLogo {
  display: flex;
}
.PracticeLogoWrapper {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 22px 70px 40px;
  margin-bottom: 20px;
}
.PracticeLogoWrapper .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #192022;
  margin-bottom: 44px;
}
.PracticeLogoWrapper .logo-img {
  min-width: 120px;
  width: 120px;
  height: 120px;
  border-radius: 5.6px;
  border: solid 0.8px #e2e2ea;
  display: inline-block;
  display: flex;
}
.PracticeLogoWrapper .logo-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.PracticeLogoWrapper .logo-inner-content .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.PracticeLogoWrapper .logo-inner-content {
  padding-left: 20px;
  width: calc(100% - 120px);
  padding-top: 10px;
}
.PracticeLogoWrapper .logo-inner-content .image-size {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 14px;
}
.PracticeLogoWrapper .circle-btn {
  height: 48px;
  border-radius: 24px;
  background-color: rgba(143, 146, 161, 0.2);
  border: 0;
  width: 117px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  text-align: center;
  color: #72787d;
  padding: 7px 5px 5px;
  cursor: pointer;
}
.PracticeLogoWrapper .circle-btn:focus {
  outline: 0;
}
.PracticeLogoWrapper .circle-btn.blue-btn {
  background-color: #26acda;
  color: #fff;
  margin-right: 10px;
}
.PracticeLogoWrapper .fileupload {
  position: relative;
  margin-right: 9px;
  margin-bottom: 5px;
}
.PracticeLogoWrapper .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.PracticeLogoWrapper .fileupload .fileupload-btn button:focus {
  outline: 0;
}

.PracticeLogoWrapper .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.PracticeLogoWrapper .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.PracticeLogoWrapper .logo-content-action {
  display: flex;
  flex-wrap: wrap;
}

@media (hover: hover) {
  .PracticeLogoWrapper .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .PracticeLogoWrapper {
    padding: 20px;
  }
  .PracticeLogoWrapper .logo-inner-content {
    padding-left: 15px;
  }
  .PracticeLogoWrapper .fileupload .fileupload-btn button {
    width: 148px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeLogoWrapper .fileupload .fileupload-btn button {
    width: 140px;
  }
  .PracticeLogoWrapper .circle-btn {
    width: 100px;
  }
  .PracticeLogoWrapper .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .PracticeLogoWrapper {
    padding: 10px;
  }
}

@media screen and (max-width: 375px) {
  .PracticeLogoWrapper .logo-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
}

@supports (-webkit-touch-callout: none) {
  .PracticeLogoWrapper .fileupload .fileupload-btn button {
    padding-top: 2px;
  }
}
