.AddInsurancePlansModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
}
.AddInsurancePlansModal .box-title{
  padding: 22px 31px 5px;
  background-color: #013648;
  display: flex;
    justify-content: space-between;
}
.AddInsurancePlansModal .box-title .cross-icon{
  position: absolute;
    width: 19px;
    top: 27px;
    cursor: pointer;
    right: 38px;
}
.AddInsurancePlansModal .box-title .cross-icon img{
  width: 100%;
}
.AddInsurancePlansModal .box-title span.heading{
  font-size: 20px;
    line-height: 1.98;
    color: #fff;
}
.AddInsurancePlansModal .box {
  background-color: #ffffff;
  padding: 30px 60px 40px 77px;
  padding-bottom: 0;
  /* background-color: #013648; */
}
.AddInsurancePlansModal .box .title {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}
.AddInsurancePlansModal .box .title .cross-icon {
  width: 19px;
  cursor: pointer;
}
.AddInsurancePlansModal .box .title .cross-icon IMG {
  width: 100%;
}
.AddInsurancePlansModal .search-input {
  margin-bottom: 10px;
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT {
  font-size: 16px;
  height: 38px;
  border-radius: 4px;
  background-color: #fafafb;
  border-color: transparent;
  padding-left: 40px;
  padding-top: 7px;
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT::placeholder {
  font-size: 16px;
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.5);
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT:-ms-input-placeholder {
  font-size: 16px;
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.5);
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT::-webkit-input-placeholder {
  font-size: 16px;
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.5);
}
.AddInsurancePlansModal .search-input.Input-control.form-group .view-img {
  left: 11px;
  top: 19px;
  width: 16px;
}
.AddInsurancePlansModal .Input-control.form-group .form-control:focus,
.AddInsurancePlansModal .Input-control.form-group .form-control:not(:placeholder-shown) {
  padding: 7px 15px 0 40px;
  border: solid 1.5px rgba(70, 104, 116, 0.2);
}
.AddInsurancePlansModal .insuranceplans-item:not(:last-child) {
  margin-bottom: 32px;
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Medium';
  border-bottom: solid 1px #e2e2ea;
  padding-bottom: 6px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.AddInsurancePlansModal .insuranceplans-item:last-child .insuranceplans-title {
  border-bottom: 0;
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-title IMG {
  margin-right: 30px;
  cursor: pointer;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #72787d;
  padding-left: 30px;
  margin-bottom: 3px;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control .checkmark {
  border-radius: 1px;
  border: solid 1px #26acda;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #26acda;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control INPUT:checked ~SPAN:not(.checkmark) {
  color: #013648;
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-content {
  display: none;
}
.AddInsurancePlansModal .insuranceplans-item.active-item .insuranceplans-content {
  display: block;
  padding-left: 5px;
}
.AddInsurancePlansModal .insuranceplans-item.active-item .insuranceplans-title IMG{
  transform: rotate(180deg);
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-title IMG {
  transform: rotate(0deg);
  transition: 0.3s all linear;
}
.AddInsurancePlansModal .insuranceplans-item .showmore {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #26acda;
  margin-top: 6px;
  display: inline-block;
  cursor: pointer;
}
.AddInsurancePlansModal .action-button-list {
  margin: 20px 0px;
  text-align: right;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AddInsurancePlansModal .action-button-list .error-text {
  color: red;
  font-size: 16px;
}
.AddInsurancePlansModal .action-button-list .CircleButton.clear {
  background-color: #999;
  border-color: #999;
  width: 111px;
  height: 32px;
  line-height: 32px;
}
.AddInsurancePlansModal .action-button-list .CircleButton.save {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  line-height: 32px;
  border: 1px solid #26acda;
  color: #fff;
}
.selectall-btn-div{
  position: relative;
  height: 25px;
}
.insurance-selectall{
  background-color: #26acda;
  border: 1px solid #26acda;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  line-height: 20px;
  position: absolute;
  right: 0;
  
}
.AddInsurancePlansModal .insuranceplans-list {
  max-height: calc(100vh - 320px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
}
.AddInsurancePlansModal .no-records {
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
  height: 100px;
}

@media (hover: hover) {
  .AddInsurancePlansModal .action-button-list .CircleButton.clear:hover {
    color: #ffffff;
    background-color: #999;
  }
  .AddInsurancePlansModal .action-button-list .CircleButton.save:hover {
    background-color: #ffffff;
    color: #26acda;
  }
}

@media (max-width: 1200px) {
  .AddInsurancePlansModal .box {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .AddInsurancePlansModal .action-button-list {
    margin-bottom: 0;
  }
}
