.writereview-public .ReviewVisitModal .modal-content-header {
  background-color: transparent;
}
.writereview-public .ReviewVisitModal .modal-content-header .close {
  display: none;
}
.writereview-public .ReviewVisitModal .modal-content-header .h3 {
  color: #013648;
}
.writereview-public .ReviewVisitModal {
  width: 100%;
}
.writereview-public .ReviewVisitModal .modal-content-body,
.writereview-public .ReviewVisitModal .modal-content-header,
.writereview-public .ReviewVisitModal .modal-content-footer {
  padding: 0;
}
.writereview-public .ReviewVisitModal .modal-content-body {
  height: auto;
  max-height: inherit;
  background-color: transparent;
}
.writereview-public .ReviewVisitModal .modal-content-footer {
  padding: 40px 0;
  background-color: transparent;
}
.writereview-public .ReviewVisitModal .Textarea .error-text {
  bottom: -30px;
}
.text-underline {
  text-decoration: underline;
}
@media (max-width: 500px) {
  .writereview-public .ReviewVisitModal .recent-visit-info .total-character {
    font-size: 14px;
  }
}
