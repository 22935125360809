.ProviderAccount {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 29px 40px 48px 37px;
  margin-bottom: 27px;
}
.ProviderAccount .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 30px;
  font-family: 'Calibre Medium';
}
.ProviderAccount .Input-control.link-container .lookup-link {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderAccount .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  border-radius: 2px;
  margin-top: 5px;
}
.ProviderAccount .Textarea.form-group TEXTAREA {
  height: 162px;
}
.ProviderAccount .Textarea.form-group .label {
  display: block;
  line-height: 1.25;
  margin-bottom: 5px;
}
.ProviderAccount .Textarea.form-group {
  margin-bottom: 23px;
}
.ProviderAccount .Select-control .label {
  line-height: 1.25;
  margin-bottom: 8px;
  display: block;
}
.ProviderAccount .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderAccount .Select-control .arrow {
  top: 50px;
}
.ProviderAccount .Input-control.link-container .lookup-link {
  top: 38px;
}
.ProviderAccount .Input-control.link-container {
  margin-bottom: 55px;
  margin-top: 5px;
}
.ProviderAccount .Input-control.form-group.link-container .label {
  margin-bottom: 2px;
}
.ProviderAccount .TagsInput .form-label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderAccount .TagsInput.suffix-tag-input .outer-suggetion-box {
  flex: 1;
}
.ProviderAccount .TagsInput.suffix-tag-input .multiselectsearch-block {
  min-height: 52px;
  padding: 11px 15px;
}
.ProviderAccount .titleHeading {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1366px) {
  .ProviderAccount {
    padding: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .ProviderAccount .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ProviderAccount .Textarea.form-group .form-control:focus,
  .ProviderAccount .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px;
  }
}

@media screen and (max-width: 413px) {
  .ProviderAccount {
    padding: 20px;
  }
}
