.InputList .add-input {
  margin-top: 7px;
  margin-bottom: 37px;
}
.InputList .add-input span {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
}
.InputList .add-input span img {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.InputList .input-list-item {
  position: relative;
}
.InputList .input-list-item .remove-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #f37184;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}
.InputList .input-list-item .remove-link img {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}
.InputList .input-list-item .Input-control.form-group .form-control {
  padding-right: 77px;
}
.InputList .input-list-label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 14px;
}
/* .InputList .input-list-label.parent-title {
  font-size: 18px;
} */
.InputList .input-list-label.parent-title .subTitle {
  font-size: 13.3px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #0136488f;
  
}
.InputList .input-list-label .inner-label {
  color: #013648;
  opacity: 0.4;
}
.InputList .input-list-item .Input-control.form-group {
  margin-bottom: 20px;
}
.InputList .status-icon IMG {
  top: -23px;
  right: 3px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
