.Addstaff {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.faq-xl .modal-wrapper{
max-width: 1024px !important;
}
.Addstaff .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.Addstaff .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
.dynamic-variables .variables{
border: 1px solid rgba(40, 84, 99, 0.2);
padding: 30px;
}

.main-label{
  font-family: 'Calibre Medium';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #013648;
    margin-bottom: 0;
    position: unset;
    top: unset;
    left: unset;
    transform: none;
}
.Addstaff ul{
  margin: 0;
    padding: 0;
    list-style: circle;
    font-size: 20px;
}

.Addstaff ul li{
  padding: 0 0 10px 0;
    
}
.validation-error{
  color: red;
    font-size: 13px;
    margin-top: 10px;
    display: block;
}
.Addstaff ul li img{
  width: 20px;
    height: 20px;
    background: #013648;
    position: relative;
    top: 6px;
    padding: 3px;
    border: 1px solid #8b8585;
    cursor: pointer;
}
.tox .tox-notification--warn, 
.tox .tox-notification--warning{
  display: none !important;
}
.Addstaff .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.Addstaff .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.Addstaff .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.Addstaff .cross-icon img {
  width: 100%;
}
.Addstaff .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.Addstaff .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.Addstaff .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.Addstaff .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.Addstaff .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.Addstaff .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.Addstaff .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.Addstaff .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.Addstaff .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.Addstaff .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.Addstaff .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.Addstaff .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.Addstaff .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.Addstaff .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Addstaff .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Addstaff .input-close {
  position: relative;
}
.Addstaff .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.Addstaff .img-preview {
  max-width: 100%;
}
.Addstaff .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
