.LandingPage {
  background-color: #ffffff;
}
.LandingPage .gradient {
  background-image: linear-gradient(-90deg, rgba(242, 150, 45, 0.08), rgb(61, 147, 166, 0.08));
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 767px) {
  .LandingPage {
    background-color: #ffffff;
  }
}
