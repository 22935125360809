.DoctorItem .DC-image {
  width: 100px;
  min-width: 100px;
  height: 150px;
}
.DoctorItem .DC-image > SPAN:first-child A {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.DoctorItem.DoctorItemOrg .DC-image IMG {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  /* object-fit: cover;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  /* border-bottom: 0; */
}
.DoctorItem .DC-image .doctor-link IMG {
  width: auto;
  height: 100%;
  border: none;
  padding-right: 5px;
}
.DoctorItem .DC-image > SPAN:first-child {
  width: 100%;
  height: 100%;
}
.DoctorItem .DC-image > SPAN:first-child {
  vertical-align: top;
}
.DoctorItem .DC-image SPAN,
.DoctorItem .DC-image A {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
}
.DoctorItem .DC-image A,
.DoctorItem .DC-image .see-availability {
  color: #26acda;
  cursor: pointer;
  white-space: nowrap;
}
.DoctorItem {
  display: flex;
  padding: 25px 0 20px;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.DoctorItem:last-child {
  border-bottom: solid 0px rgba(1, 54, 72, 0.07);
}
.DoctorItem .DC-image {
  display: inline-block;
  margin-top: 5px;
}
.DoctorItem .dc-information {
  margin-left: 40px;
  padding-bottom: 13px;
}
.DoctorItem .dc-information .dc-content .dc-name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
}
.DoctorItem .dc-information .dc-content .dc-name A {
  color: #013648;
}
.DoctorItem .dc-information .dc-content .dc-specialist {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.2px;
  color: #013648;
}
.DoctorItem .dc-information .dc-content .dc-address {
  font-size: 16px;
  line-height: 1.58;
  color: #587681;
  letter-spacing: -0.18px;
}
.DoctorItem .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorItem .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(1n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(2n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(3n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(4n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(5n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(6n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorItem .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 11px;
}
.DoctorItem .pt-category-box .neck {
  width: 35%;
}
.DoctorItem .pt-category-box .sciatica {
  width: 25%;
  background-color: #e85d72;
}
.DoctorItem .pt-category-box .herniated {
  width: 20%;
  background-color: #8d77ba;
}
.DoctorItem .pt-category-box .scoliosis {
  width: 20%;
  background-color: #f2962d;
}
.DoctorItem .progress-bar .dc-symptoms {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.4px;
  color: #a2a2a2;
}
.DoctorItem .progress-bar .dc-symptoms-text {
  font-size: 16px;
  line-height: 0.84;
  letter-spacing: -0.2px;
  color: #72787d;
  margin-top: 5px;
}
.DoctorItem .reviwe-content {
  position: relative;
}
.DoctorItem .dc-review {
  padding-right: 6px;
  min-width: 175px;
}
.DoctorItem .dc-review .reviwe-content .star {
  width: 41px;
  position: relative;
  top: 18px;
  left: 9px;
}
.DoctorItem .dc-review .reviwe-content .rating {
  font-family: 'Heldane Display';
  font-size: 44px;
  line-height: 0.71;
  letter-spacing: -0.96px;
  color: #013648;
  position: relative;
  top: 2px;
}
.DoctorItem .dc-review .reviwe-content .rating.grey {
  color: rgb(153, 153, 153);
}
.DoctorItem .dc-review .reviwe-content .info {
  width: 19px;
  padding-left: 5px;
}
.DoctorItem .dc-review .reviwe-content .social-icon {
  width: 19px;
  margin-top: 25px;
  margin-left: 7px;
  position: relative;
  right: 17px;
}
.DoctorItem .dc-review .reviwe-content .rating-text {
  width: 34px;
  height: 26px;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #013648;
  position: relative;
  top: 6px;
  right: 16px;
}
.DoctorItem .dc-info-content .dc-information {
  display: flex;
  justify-content: space-between;
}
.DoctorItem .dc-info-content {
  width: calc(100% - 101px);
  padding-right: 60px;
}
.DoctorItem .dc-review .reviwe-content > SPAN {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
}
.DoctorItem .dc-appointment-time {
  padding-top: 14px;
  margin-left: 40px;
  border-top: solid 2px rgba(1, 54, 72, 0.1);
}
.DoctorItem .timing-table {
  width: 508px;
  padding: 0px 29px 0px 44px;
}
.DoctorItem .table-wrapper {
  position: relative;
  display: inline-block;
}
.DoctorItem .table-wrapper .prev-link {
  position: absolute;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
.DoctorItem .table-wrapper .right-link {
  position: absolute;
  right: 0px;
  top: -4px;
  transform: rotate(180deg);
  cursor: pointer;
}
.DoctorItem .table-wrapper .prev-link IMG,
.DoctorItem .table-wrapper .right-link IMG {
  width: 24px;
}
.DoctorItem .table-wrapper .prev-link.disabled IMG,
.DoctorItem .table-wrapper .right-link.disabled IMG {
  opacity: 0.21;
  cursor: not-allowed;
}
.DoctorItem .timing-table .tr {
  display: flex;
  margin-right: 15px;
}
.DoctorItem .timing-table .th {
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: center;
  width: 83px;
}
.DoctorItem .timing-table .th .date {
  display: block;
  font-family: 'Calibre Regular';
  font-weight: normal;
  text-align: center;
}
.DoctorItem .timing-table .tr .th:last-child {
  padding-right: 0;
}
.DoctorItem .timing-table .td {
  padding: 0px 5px 5px;
}
.DoctorItem .timing-table .tr .td:last-child {
  padding-right: 0;
}
.DoctorItem .timing-table .tr:last-child .td {
  padding-bottom: 0;
}
.DoctorItem .timing-table .td .time {
  width: 73px;
  font-size: 14px;
  line-height: 1.31;
  letter-spacing: normal;
  border-radius: 13.8px;
  padding: 6px 3px 2px;
  text-align: center;
  vertical-align: middle;
  border: 0.7px solid rgba(1, 54, 72, 0.1);
  color: #013648;
}
.DoctorItem .timing-table .td.available .time {
  color: #fff;
  cursor: pointer;
  background-color: #013648;
  border: 1px solid #013648;
  font-size: 14px;
}
.DoctorItem.isVirtual .timing-table .td.available .time {
  color: #fff;
  background-color: #6a3acd;
  border: 1px solid #6a3acd;
}
.DoctorItem .timing-table .td.booked .time {
  background-color: #013648;
  color: #ffffff;
  cursor: not-allowed;
}
.DoctorItem .day-table {
  width: 100%;
  border-spacing: 0;
}
.DoctorItem .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DoctorItem .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.DoctorItem .day-table .tr .td:last-child {
  padding-right: 0;
}
.DoctorItem .day-table .tr:last-child .td {
  padding-bottom: 0;
}
.DoctorItem .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.DoctorItem .appointment-inner-block {
  margin-bottom: 46px;
}
.DoctorItem .appointments-day-block {
  padding-top: 10px;
}
.DoctorItem .appointment-inner-block:last-child {
  margin-bottom: 0;
}
.DoctorItem .time-container {
  max-height: 95px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorItem .time-container.show-more {
  max-height: 95px;
  overflow: auto;
}
.timing-table .more-avaliable {
  float: left;
  margin-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: #3d93a6;
  display: none;
}
.DoctorItem .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.DoctorItem .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.DoctorItem .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorItem .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorItem .DC-image .dc-profile-link .MyLink,
.DoctorItem .DC-image .dc-profile-link .see-availability {
  margin-top: 6px;
}
.DoctorItem .dc-profile-link {
  display: none;
}
.DoctorItem .dc-action > SPAN,
.DoctorItem .dc-action > SPAN:first-child {
  display: inline-block;
  width: 20px;
  margin-right: 9px;
}
.DoctorItem .dc-action > SPAN IMG {
  width: 100%;
  border: 0;
}
.DoctorItem .dc-review .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DoctorItem .dc-review .info-wrapper {
  display: inline-block;
  position: relative;
  top: -12px;
  left: 5px;
  line-height: 0;
}
.DoctorItem .progress-bar-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.DoctorItem .progress-bar-wrapper .progress-bar {
  width: 50%;
  padding: 0 14px;
}
/* .DoctorItem .dc-content {
  flex: 1;
} */
.DoctorItem .DC-image .person-visit,
.DoctorItem .DC-image .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 4px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 4px;
  padding-left: 36px;
  margin-bottom: 13px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.DoctorItem .DC-image .person-visit IMG,
.DoctorItem .DC-image .video-visit IMG {
  width: 20px;
  border: 0;
  position: absolute;
  left: 10px;
  top: 0;
}
.DoctorItem .DC-image .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.DoctorItem .pt-category-box .box:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.DoctorItem .dc-profile-link span {
  font-size: 15px;
  line-height: 1.68;
  letter-spacing: -0.28px;
  color: rgba(1, 54, 72, 0.5);
}
.DoctorItem .dc-profile-link A,
.DoctorItem .dc-profile-link .see-availability {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  cursor: pointer;
  display: inline-block;
}
.DoctorItem .dc-sm-distance {
  /* display: none; */
  font-size: 15px;
  line-height: 1.68;
  letter-spacing: -0.28px;
  color: #587681;
}

@media (hover: hover) {
  .DoctorItem .timing-table .td.available .time:hover {
    background-color: rgba(1, 54, 72, 0.1);
    color: #013648;
    border: 1px solid #013648;
  }
  .DoctorItem.isVirtual .timing-table .td.available .time:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #013648;
  }
  .DoctorItem .dc-review .info-wrapper:hover .info-content {
    display: block;
  }
}

@media (max-width: 1200px) {
  .DoctorItem .dc-appointment-time,
  .DoctorItem .dc-info-content .dc-information {
    margin-left: 15px;
  }
  .DoctorItem .dc-review {
    padding-right: 0;
  }
  .DoctorItem .timing-table .td .time {
    width: 65px;
  }
  .DoctorItem .timing-table .th {
    width: 75px;
  }
  .DoctorItem .timing-table {
    width: 470px;
  }
}
.DoctorItem .bookaappointment {
  display: flex;
  align-items: center;
  background-color: #013648;
  padding: 5px 30px;
  margin-bottom: 40px;
  border-radius: 25px;
  color: white;
  gap: 10px;
  font-size: 18px;
}
.DoctorItem .bookaappointment span {
  margin-top: 7px;
}
.DoctorItem .bookaappointment img {
  filter: invert(0%) sepia(66%) saturate(0%) hue-rotate(125deg) brightness(103%) contrast(103%);
}
@media (max-width: 1024px) {
  .DoctorItem .progress-bar-wrapper {
    margin: 0;
    flex-direction: column;
  }
  .DoctorItem .progress-bar-wrapper .progress-bar {
    width: 100%;
    padding: 0;
  }
  .DoctorItem .pt-category-box {
    width: 230px;
  }
  .DoctorItem .dc-information .dc-content .dc-address {
    color: #587681;
  }
  .DoctorItem .dc-info-content .dc-information {
    flex-wrap: wrap;
  }
  .DoctorItem .DC-image .dc-profile-link SPAN,
  .DoctorItem .DC-image .dc-profile-link A {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.34px;
    color: rgba(1, 54, 72, 0.5);
    margin-bottom: 8px;
  }
  .DoctorItem .DC-image .dc-profile-link A,
  .DoctorItem .DC-image .dc-profile-link .see-availability {
    color: #3dbce7;
    cursor: pointer;
  }
  .DoctorItem .DC-image .dc-profile-link A {
    margin: 0px 16px;
  }
  .DoctorItem .DC-image .dc-profile-link {
    display: none;
  }
  .DoctorItem .dc-review {
    width: 100%;
  }
  .DoctorItem {
    position: relative;
  }
  .DoctorItem .DC-image {
    position: absolute;
    left: 0;
    top: 20px;
  }
  .DoctorItem .dc-content {
    padding-left: 115px;
  }
  .DoctorItem .dc-info-content {
    width: 100%;
  }
  .DoctorItem .dc-appointment-time,
  .DoctorItem .dc-info-content .dc-information {
    margin-left: 0;
  }
  .DoctorItem .timing-table {
    padding: 0px 29px 0px 30px;
  }
  .DoctorItem .timing-table {
    width: 450px;
  }
  .DoctorItem .dc-content {
    padding-left: 115px;
  }
  .DoctorItem .dc-profile-link {
    margin-top: 19px;
    display: block;
  }
  .DoctorItem .dc-review .reviwe-content .social-icon,
  .DoctorItem .dc-review .reviwe-content .info {
    display: none;
  }
  .DoctorItem .dc-review .reviwe-content .rating-text {
    color: #203a70;
    right: 0px;
  }
  .DoctorItem .dc-review .reviwe-content .rating {
    font-size: 38px;
  }
  .DoctorItem .dc-profile-link A {
    margin: 0 30px;
  }
  .DoctorItem .dc-profile-link A:first-child {
    margin-left: 0;
  }
  .DoctorItem .dc-sm-distance {
    display: block;
  }
  .DoctorItemOrg .hideon-mobile {
    display: none !important;
  }
  .DoctorItemOrg .book-now {
    width: 175px;
  }
  .DoctorItemOrg.DoctorItem .bookaappointment {
    margin-bottom: 10px;
  }
  .DoctorItemOrg.DoctorItem .dc-info-content .dc-information {
    padding-bottom: 30px !important;
  }
}

@media (max-width: 767px) {
  .DoctorItem .pt-category-box {
    width: 100%;
  }
  .DoctorItem .table-content {
    overflow-x: auto;
    margin-right: -25px;
  }
  .DoctorItem .table-wrapper {
    width: 100%;
  }
  .DoctorItem .timing-table {
    padding: 0px;
  }
  .DoctorItem .table-wrapper .prev-link,
  .DoctorItem .table-wrapper .right-link {
    display: none;
  }
  .DoctorItem .DC-image {
    width: 71px;
    min-width: 71px;
    height: 104px;
  }
  .DoctorItem .dc-content {
    padding-left: 90px;
    padding-top: 0px;
    min-height: 206px;
  }
  .DoctorItem .dc-information .dc-content .dc-name {
    font-size: 20px;
  }
  .DoctorItem .dc-information .progress-bar {
    width: 100%;
  }
  .DoctorItem .dc-info-content .dc-information {
    position: relative;
  }
  .DoctorItem .dc-review {
    position: absolute;
    top: 159px;
    right: calc(100% - 74px);
    width: auto;
    min-width: auto;
  }
  .DoctorItem .dc-review .reviwe-content .rating {
    top: -13px;
    left: -6px;
    font-size: 26px;
  }
  .DoctorItem .dc-review .reviwe-content .rating-text {
    right: 5px;
    top: -15px;
    font-size: 12px;
    font-family: 'Calibre Medium';
    color: #3dbce7;
  }
  .DoctorItem .dc-information {
    padding-bottom: 22px;
  }
  .DoctorItem .dc-review .reviwe-content .star {
    top: -2px;
    left: -3px;
    width: 24px;
    height: 31px;
  }
  .DoctorItem .timing-table .th .date {
    font-family: 'Calibre Medium';
    font-weight: 500;
    display: inline-block;
    width: 100%;
  }
  .DoctorItem .dc-appointment-time {
    padding-top: 27px;
  }
  .DoctorItem .timing-table .th {
    padding-bottom: 16px;
    width: 84px;
  }
  .DoctorItem .timing-table .td .time {
    width: 75px;
  }
  .DoctorItem .time-container {
    overflow-y: visible;
    height: auto;
  }
  .DoctorItem .dc-profile-link {
    display: flex;
  }
  .DoctorItem .dc-profile-link A {
    margin: 0px 10px;
  }
  .DoctorItem.rating-content-show .dc-info-content .dc-information {
    padding-bottom: 80px;
  }
  .DoctorItem .DC-image .person-visit,
  .DoctorItem .DC-image .video-visit {
    font-size: 12px;
    padding-left: 20px;
    width: 72px;
  }
  .DoctorItem .DC-image .person-visit IMG,
  .DoctorItem .DC-image .video-visit IMG {
    left: 0;
    object-fit: contain;
  }
  .DoctorItem .DC-image .video-visit {
    padding-left: 18px;
  }
  .DoctorItem {
    padding-right: 15px;
  }
  .DoctorItem .timing-table .td .time {
    width: 68px;
  }
  .DoctorItem .timing-table .th {
    width: 76px;
  }
  .DoctorItem .timing-table .td {
    padding: 0px 4px 5px;
  }
  /* .DoctorItem .timing-table .td.available .time {
    background-color: #013648;
    color:#fff;
  } */
  /* .DoctorItem .dc-information .dc-content .dc-info-inner-content {
    min-height: 120px;
  } */
  .DoctorItem .timing-table {
    width: 390px;
  }
  .DoctorItem .dc-info-content {
    padding-right: 0;
  }
  .timing-table .more-avaliable {
    display: block;
  }
  .DoctorItem .time-container {
    overflow: hidden;
  }
  .DoctorItem .time-container.show-more {
    max-height: 100% !important;
    /* overflow: auto; */
  }
}

@media (max-width: 413px) {
  .DoctorItem .dc-profile-link SPAN {
    font-size: 14px;
  }
  .DoctorItem .dc-profile-link A,
  .DoctorItem .dc-profile-link .see-availability {
    font-size: 14px;
  }
  .DoctorItem .dc-content .dc-profile-link A IMG,
  .DoctorItem .dc-content .dc-profile-link .see-availability IMG {
    margin-right: 3px;
  }
  .DoctorItem .dc-content .dc-profile-link A {
    margin: 0px 6px 0 0;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .DoctorItem {
    border-top: solid 1px rgba(1, 54, 72, 0.07);
  }
}
