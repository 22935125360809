.ProviderPhotos .provider-logo {
  display: flex;
  margin-bottom: 42px;
}
.ProviderPhotos {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 29px 40px 38px;
  margin-bottom: 27px;
}
.ProviderPhotos .h3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
}
.ProviderPhotos .sub-txt {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  margin-bottom: 23px;
}
.ProviderPhotos .name {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.4px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 7px;
  margin-top: 25px;
}
.ProviderPhotos .logo-img {
  min-width: 120px;
  width: 120px;
  height: 169px;
}
.ProviderPhotos .logo-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6.4px;
  border: solid 0.8px rgba(1, 54, 72, 0.2);
}
.ProviderPhotos .logo-inner-content .label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-bottom: 3px;
}
.ProviderPhotos .logo-inner-content {
  padding-top: 0px;
  padding-left: 20px;
  width: calc(100% - 120px);
}
.ProviderPhotos .logo-inner-content .image-size {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.18px;
  color: #b2bcc4;
  display: inline-block;
  width: 100%;
}
.ProviderPhotos .circle-btn {
  height: 38px;
  border: 0;
  padding: 11px 5px 8px;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  font-family: 'Calibre Regular';
  width: 117px;
  border-radius: 2px;
  background-color: rgba(143, 146, 161, 0.2);
  letter-spacing: normal;
  color: #72787d;
}
.ProviderPhotos .circle-btn:focus {
  outline: 0;
}
.ProviderPhotos .circle-btn.blue-btn {
  background-color: #26acda;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
}
.ProviderPhotos .fileupload {
  position: relative;
  margin-right: 10px;
  margin-bottom: 5px;
}
.ProviderPhotos .fileupload .fileupload-btn button {
  height: 38px;
  border: 0;
  padding: 11px 5px 8px;
  width: 161px;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  background-color: #26acda;
  color: #fff;
  font-family: 'Calibre Regular';
  border-radius: 2px;
}
.ProviderPhotos .fileupload .fileupload-btn {
  display: block;
}
.ProviderPhotos .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.ProviderPhotos .logo-content-action {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.ProviderPhotos .photo-upload-row .filephoto-upload {
  padding: 0 8px;
}
.ProviderPhotos .photo-upload-row .filephoto-upload .fileupload-input {
  width: 93px;
  height: 93px;
  border-radius: 4px;
  opacity: 0.51;
  border: dashed 2px rgba(1, 54, 72, 0.5);
  background-color: #f7f9f9;
  position: relative;
  display: flex;
}
.ProviderPhotos .photo-upload-row .filephoto-upload .fileupload-input input {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.ProviderPhotos .photo-upload-row .filephoto-upload .fileupload-input img {
  width: 35px;
  height: 23px;
  margin: auto;
}
.ProviderPhotos .photo-upload-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.ProviderPhotos .photo-col {
  padding: 0 8px;
}
.ProviderPhotos .photo-col .photo-img {
  width: 93px;
  height: 93px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: solid 1px #ccd7da;
  background-color: #fafafb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProviderPhotos .photo-col .photo-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProviderPhotos .photo-col .photo-img video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProviderPhotos .additional-video {
  margin-top: 40px;
}
.ProviderPhotos .photo-col .remove-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #b2bcc4;
  display: block;
  cursor: pointer;
}
@media (hover: hover) {
  .ProviderPhotos .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .ProviderPhotos {
    padding: 20px;
  }
  .ProviderPhotos .logo-inner-content {
    padding-left: 15px;
    width: calc(100% - 120px);
  }
  .ProviderPhotos .fileupload .fileupload-btn button {
    width: 157px;
  }
}
@media screen and (max-width: 1200px) {
  .ProviderPhotos .fileupload .fileupload-btn button {
    width: 140px;
  }
  .ProviderPhotos .circle-btn {
    width: 100px;
  }
  .ProviderPhotos .name,
  .ProviderPhotos .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .ProviderPhotos .logo-img {
    width: 100px;
    min-width: 100px;
    height: 149px;
  }
  .ProviderPhotos .logo-inner-content {
    width: calc(100% - 100px);
  }
  .ProviderPhotos .name {
    margin-top: 0;
  }
}

@media screen and (max-width: 413px) {
  .ProviderPhotos {
    padding: 10px;
  }
}
