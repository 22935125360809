.CircleButton.WhiteButton {
  width: 232px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  font-size: 18px;
  line-height: 48px;
  letter-spacing: -0.34px;
  color: #013648;
  font-family: 'Calibre Medium';
  border: 0px;
  opacity: 1;
  cursor: pointer;
  outline: 0;
  border: 1px solid #013648;
}
.CircleButton.SecondaryButton:focus,
.CircleButton.WhiteButton:focus,
.CircleButton.smallClose:focus {
  outline: 0;
}
.CircleButton.SecondaryButton,
.CircleButton.smallClose {
  border-radius: 24px;
  border: solid 1px #013648;
  width: 232px;
  height: 48px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.34px;
  color: #013648;
  background-color: transparent;
  cursor: pointer;
}
.CircleButton.WhiteButton[disabled] {
  opacity: 0.5;
  color: #d8d8d8;
  background-color: #013648;
}
.CircleButton.SecondaryButton[disabled],
.CircleButton.smallClose[disabled] {
  background-color: #013648;
  opacity: 0.3;
}
.CircleButton.smallClose {
  width: auto;
  padding: 1px 20px;
  margin: 5px;
}
.CircleButtonWrapper {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.CircleButtonWrapper.with-close-icon {
  position: relative;
}
.CircleButtonWrapper.with-close-icon .CircleButton.smallClose {
  padding-right: 35px;
  z-index: 2;
  cursor: unset;
}
.CircleButtonWrapper .close-icon {
  position: absolute;
  height: 15px;
  top: 21px;
  right: 18px;
  cursor: pointer;
  z-index: 1;
}
@media (hover: hover) {
  .CircleButton.WhiteButton:hover {
    background-color: #013648;
    opacity: 1;
    border: 1px solid #013648;
    color: #fff;
  }
  .CircleButton.SecondaryButton:hover {
    background-color: #013648;
    color: #fff;
  }
  .CircleButton.WhiteButton[disabled]:hover {
    background-color: #013648;
    opacity: 0.5;
    color: #d8d8d8;
    border: 1px solid #013648;
    cursor: not-allowed;
  }
  .CircleButton.SecondaryButton[disabled]:hover {
    background-color: transparent;
    border: solid 1px #013648;
    color: #013648;
    opacity: 0.3;
    cursor: not-allowed;
  }
}
@supports (-webkit-touch-callout: none) {
  .CircleButton.WhiteButton {
    line-height: unset !important;
  }
}
@media (max-width: 767px) {
  .CircleButton.WhiteButton {
    background-color: #013648;
    color: #fff;
  }
}
.Header {
  background-color: #013648;
  height: 119px;
  display: flex;
}
.Header > DIV {
  width: 100%;
}
.Header .container {
  display: flex;
  width: 100%;
  padding: 0px 70px 0px 60px;
}
.Header .CircleButton.SecondaryButton {
  border: 1px solid #3d93a6;
  background: #3d93a6;
  color: #ffffff;
}
.Header .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.Header .header-content .right-col A {
  color: #ffffff;
}
.Header .header-content .right-col SPAN {
  display: inline-block;
  padding: 0px 30px;
  position: relative;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  font-family: 'Calibre Medium';
}
.Header .header-content .right-col SPAN:first-child {
  border-left: solid 1px #ffffff;
  padding-left: 40px;
}
.Header .header-content .right-col SPAN:last-child:after {
  display: none;
}
.Header .header-content .right-col SPAN:last-child {
  padding-right: 0px;
}
.Header .header-content .logo {
  width: 158px;
}
.Header .header-content .logo A {
  position: relative;
  top: 5px;
}
.Header .header-content .logo A IMG {
  width: 169px;
  cursor: pointer;
}
.Header .mobile-menu {
  display: none;
}
.Header .header-menu-overlay {
  display: none;
}
.Header .header-content .precticewith A {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #ffffff;
  display: inline-block;
  margin-right: 50px;
  opacity: 1;
  margin-top: 4px;
  font-family: 'Calibre Medium';
}
.Header .header-content .right-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Header .header-content .pricing-link {
  display: none;
}

@media (hover: hover) {
  .Header .header-content .right-col A:hover {
    text-decoration: none;
  }
  .Header .mobile-menu:hover SPAN:last-child {
    width: 20px;
  }
  .Header .CircleButton.SecondaryButton:hover {
    background: rgba(61, 147, 166, 0.8);
  }
  .Header .header-content .precticewith A:hover {
    color: rgba(255, 255, 255, 0.6);
  }
}

@media (max-width: 1200px) {
  .Header .container {
    padding: 0 30px;
  }
  .Header .header-content .precticewith A {
    margin-right: 30px;
  }
}

@media (max-width: 1024px) {
  .Header .header-content .precticewith A {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .Header {
    height: 108px;
  }
  .Header .header-content .logo A IMG {
    width: 128px;
  }
  .Header .header-content .precticewith {
    display: none;
  }
  .Header .right-col {
    transition: 0.3s all linear;
    position: fixed;
    right: -320px;
    top: 0;
    bottom: 0;
    background-color: #013648;
    width: 300px;
    padding-top: 100px;
    z-index: 1;
  }
  .Header .mobile-menu SPAN {
    width: 20px;
    display: inline-block;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    transition: 0.3s all linear;
  }
  .Header .header-content .right-col A,
  .Header .header-content .right-col BUTTON {
    color: #fff;
    text-align: center;
  }
  .Header .header-content .right-col BUTTON {
    border: solid 1px #3d93a6;
  }
  .Header .header-content .right-col SPAN {
    width: 100%;
    text-align: center;
    padding: 0px 0px;
    margin: 10px 0px;
  }
  .Header .mobile-menu SPAN:first-child {
    top: 0;
  }
  .Header .mobile-menu SPAN:nth-child(2) {
    top: 9px;
  }
  .Header .mobile-menu SPAN:last-child {
    top: 18px;
    width: 10px;
  }
  .Header .mobile-menu {
    width: 20px;
    height: 18px;
    position: relative;
    top: 13px;
    cursor: pointer;
    display: block;
    right: 0px;
  }
  .Header.menu-active .right-col {
    right: 0px;
    z-index: 3;
  }
  .Header.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .Header.menu-active .mobile-menu SPAN {
    background-color: #fff;
    transform: rotate(45deg);
  }
  .Header.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
  }
  .Header.menu-active .mobile-menu {
    position: fixed;
    right: 15px;
    top: 50px;
    z-index: 3;
  }
  .AdminLayoutHeader.menu-active + .wrap-content-row .Sidebar,
  .AfterAuthHeader.menu-active + .wrap-content-row .Sidebar {
    left: 0;
    z-index: 8;
    box-shadow: 1px 3px 3px 0px #e6e6e6;
  }

  .Header .header-menu-overlay {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .Header.menu-active .header-menu-overlay {
    display: block;
    z-index: 2;
  }
  .Header .header-content .pricing-link {
    display: block;
    padding-left: 40px;
  }
}

@media (max-width: 413px) {
  .Header .container {
    padding: 0px 15px;
  }
}

.CustomFooter {
  padding: 21px 0;
}
.CustomFooter .footer-block {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.CustomFooter .footer-menu-ul {
  display: flex;
  flex-wrap: wrap;
}
.CustomFooter .footer-menu-ul .menu-item-list .accessibilityWidget,
.CustomFooter .footer-menu-ul .menu-item-list a {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #979ea6;
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #979ea6;
  display: inline-block;
}
.CustomFooter .footer-menu-ul .menu-item-list:last-child .accessibilityWidget,
.CustomFooter .footer-menu-ul .menu-item-list:last-child a {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
  padding-top: 0;
}
.CustomFooter .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: center;
  color: #979ea6;
  width: 100%;
  margin-top: 10px;
  z-index: 1;
}

@media (hover: hover) {
  .CustomFooter .footer-menu-ul .menu-item-list .accessibilityWidget:hover,
  .CustomFooter .footer-menu-ul .menu-item-list a:hover {
    color: #013648;
    text-decoration: none;
  }
}
@media (max-width: 1024px) {
  .CustomFooter .paragraph {
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .CustomFooter .footer-menu-ul {
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
  .CustomFooter .menu-item-list {
    width: 100%;
    text-align: center;
  }
  .CustomFooter .footer-menu-ul .menu-item-list a {
    margin-bottom: 10px;
    line-height: 10px;
    padding-top: 5px;
  }
  .CustomFooter .paragraph {
    width: 100%;
  }
  .CustomFooter .footer-menu-ul .menu-item-list:nth-child(odd) a {
    padding-right: 9px;
    margin-right: 9px;
    border-right: 1px solid #979ea6;
  }
  .CustomFooter .footer-menu-ul {
    margin-bottom: 14px;
  }
}
@media (max-width: 375px) {
  .CustomFooter .menu-item-list {
    width: 100%;
  }
  .CustomFooter .container {
    padding: 0 15px;
  }
}

.Footer {
  padding: 54px 0 54px;
}
.Footer .footer-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Footer .footer-main .footer-left {
  flex: 0 0 64%;
  max-width: 64%;
}
.Footer .footer-main .footer-right {
  flex: 0 0 36%;
  max-width: 36%;
}
.Footer .footer-main .footer-left .footer-left-inner {
  display: flex;
}
.Footer .footer-main .footer-left .footer-left-inner .widget-one {
  flex: 0 0 43%;
  max-width: 43%;
  padding-right: 15px;
}
.Footer .footer-main .footer-left .footer-left-inner .widget-two,
.Footer .footer-main .footer-left .footer-left-inner .widget-three {
  flex: 0 0 28%;
  max-width: 28%;
  padding-right: 15px;
  margin-top: 10px;
}
.Footer .footer-main .footer-left img {
  max-width: 180px;
  width: 100%;
  margin-bottom: 15px;
  opacity: 0.9;
}
.Footer .footer-main .footer-left .footer-widget .paragraph {
  font-family: 'Calibre Regular';
  font-size: 18px;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: rgba(1, 54, 72, 0.7);
  display: none;
}
.Footer .footer-main .footer-left .footer-widget .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183b56;
  margin-bottom: 26px;
  margin-top: 7px;
}
Footer .footer-main .footer-left .footer-widget .footer-menu-ul {
  list-style-type: none;
}
.Footer .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list {
  margin-bottom: 27px;
}
.Footer .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a {
  font-family: 'Calibre Regular';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183b56;
  transition: all 0.3s ease;
}
.Footer .footer-main .footer-right .footer-menu-ul {
  display: flex;
  justify-content: flex-end;
}
.Footer .footer-main .footer-right .footer-menu-ul .menu-item-list {
  margin-right: 23px;
}
.Footer .footer-main .footer-right .footer-menu-ul .menu-item-list:last-child {
  margin-right: 0;
}
.Footer .footer-main .footer-right .footer-menu-ul .menu-item-list a img {
  width: 44px;
  height: 44px;
  transition: all 0.3s ease;
}
.Footer .footer-right-inner {
  margin-top: 23px;
}
.Footer .footer-bottom {
  margin-top: 200px;
}
.Footer .footer-bottom .paragraph {
  font-family: 'Calibre Regular';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #979ea6;
  margin: 0;
}

@media (hover: hover) {
  .Footer .footer-main .footer-right .footer-menu-ul .menu-item-list a:hover img {
    opacity: 0.7;
  }
  .Footer .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a:hover {
    color: #013648;
  }
}

@media (max-width: 1200px) {
  .Footer .footer-bottom {
    margin-top: 150px;
  }
}
@media (max-width: 991px) {
  .Footer .footer-main .footer-left {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .Footer .footer-main .footer-right {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .Footer .footer-bottom {
    margin-top: 100px;
  }
}
@media (max-width: 767px) {
  .Footer .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a {
    line-height: 1.25;
  }
  .Footer .footer-main .footer-left .footer-left-inner .widget-one {
    display: none;
  }
  .Footer .footer-main .footer-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .Footer .footer-main .footer-right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 53px;
  }
  .Footer .footer-main .footer-left .footer-left-inner .widget-two {
    flex: 0 0 42%;
    max-width: 42%;
  }
  .Footer .footer-main .footer-left .footer-left-inner .widget-three {
    flex: 0 0 58%;
    max-width: 58%;
  }
  .Footer .footer-main {
    flex-direction: column-reverse;
  }
  .Footer .footer-main .footer-right .footer-menu-ul {
    justify-content: flex-start;
  }
  .Footer .footer-main .footer-left .footer-left-inner {
    flex-direction: row-reverse;
  }
  .Footer .footer-main .footer-left .footer-widget .h3 {
    margin-bottom: 30px;
    line-height: 1.22;
  }
  .Footer .footer-bottom {
    margin-top: 30px;
  }
  .Footer .footer-bottom .paragraph {
    text-align: left;
  }
}

.RouteNotFound {
  padding: 60px 0px;
}
.RouteNotFound .RouteNotFound-inner {
  text-align: center;
}
.RouteNotFound .heading1 {
  font-size: 100px;
  font-weight: normal;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  margin-bottom: 30px;
  font-family: HeldaneDisplay;
  text-shadow: 2px 2px #74bdc7;
}
.RouteNotFound .paragraph {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
  margin-bottom: 28px;
}

@media (max-width: 767px) {
  .RouteNotFound {
    padding: 40px 0px;
  }
  .RouteNotFound .heading1 {
    font-size: 70px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .RouteNotFound .paragraph {
    font-size: 18px;
    line-height: 1.06;
    margin-bottom: 15px;
  }
}

.LoadPage {
  padding: 60px 0px;
}
.LoadPage .LoadPage-inner {
  text-align: center;
}
.LoadPage .paragraph {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
  margin-bottom: 28px;
}

@media (max-width: 1024px) {
  .LoadPage .paragraph {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .LoadPage {
    padding: 40px 0px;
  }
  .LoadPage .paragraph {
    font-size: 22px;
    line-height: 1.06;
    margin-bottom: 15px;
  }
}

.PrivacyPolicy {
  padding: 131px 0;
}
.PrivacyPolicy .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.PrivacyPolicy .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.PrivacyPolicy .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.PrivacyPolicy .title-txt {
  margin-bottom: 37px;
}
.PrivacyPolicy .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.PrivacyPolicy .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.PrivacyPolicy .back-yellow {
  background-color: yellow;
}
.PrivacyPolicy ol {
  list-style-position: outside;
}
.PrivacyPolicy .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}
.PrivacyPolicy .address-cell {
  vertical-align: top;
}

@media screen and (max-width: 1199px) {
  .PrivacyPolicy {
    padding: 90px 0;
  }
  .PrivacyPolicy .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .PrivacyPolicy {
    padding: 70px 0;
  }
  .PrivacyPolicy .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .PrivacyPolicy .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .PrivacyPolicy .title-txt {
    margin-bottom: 27px;
  }
  .PrivacyPolicy .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .PrivacyPolicy {
    padding: 50px 0;
  }
  .PrivacyPolicy .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .PrivacyPolicy .sub-header-title {
    margin-bottom: 15px;
  }
  .PrivacyPolicy .title-txt {
    margin-bottom: 20px;
  }
  .PrivacyPolicy .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.ReviewPolicy {
  padding: 131px 0;
}
.ReviewPolicy .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.ReviewPolicy .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.ReviewPolicy .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.ReviewPolicy .title-txt {
  margin-bottom: 37px;
}
.ReviewPolicy .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.ReviewPolicy .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.ReviewPolicy .back-yellow {
  background-color: yellow;
}
.ReviewPolicy ol {
  list-style-position: outside;
}
.ReviewPolicy .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}
.ReviewPolicy .address-cell {
  vertical-align: top;
}
.ReviewPolicy ol.alpha-type {
  list-style-type: upper-alpha;
}

@media screen and (max-width: 1199px) {
  .ReviewPolicy {
    padding: 90px 0;
  }
  .ReviewPolicy .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .ReviewPolicy {
    padding: 70px 0;
  }
  .ReviewPolicy .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .ReviewPolicy .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .ReviewPolicy .title-txt {
    margin-bottom: 27px;
  }
  .ReviewPolicy .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .ReviewPolicy {
    padding: 50px 0;
  }
  .ReviewPolicy .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .ReviewPolicy .sub-header-title {
    margin-bottom: 15px;
  }
  .ReviewPolicy .title-txt {
    margin-bottom: 20px;
  }
  .ReviewPolicy .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.Terms {
  padding: 131px 0;
}
.Terms .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.Terms .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.Terms .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.Terms .title-txt {
  margin-bottom: 37px;
}
.Terms .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.Terms .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.Terms .back-yellow {
  background-color: yellow;
}
.Terms ol {
  list-style-position: outside;
}
.Terms .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}

@media screen and (max-width: 1199px) {
  .Terms {
    padding: 90px 0;
  }
  .Terms .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .Terms {
    padding: 70px 0;
  }
  .Terms .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .Terms .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .Terms .title-txt {
    margin-bottom: 27px;
  }
  .Terms .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .Terms {
    padding: 50px 0;
  }
  .Terms .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .Terms .sub-header-title {
    margin-bottom: 15px;
  }
  .Terms .title-txt {
    margin-bottom: 20px;
  }
  .Terms .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.HIPAAPolicy {
  padding: 131px 0;
}
.HIPAAPolicy .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.HIPAAPolicy .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.HIPAAPolicy .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.HIPAAPolicy .title-txt {
  margin-bottom: 37px;
}
.HIPAAPolicy .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.HIPAAPolicy .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.HIPAAPolicy .back-yellow {
  background-color: yellow;
}
.HIPAAPolicy ol {
  list-style-position: outside;
}
.HIPAAPolicy .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}

@media screen and (max-width: 1199px) {
  .HIPAAPolicy {
    padding: 90px 0;
  }
  .HIPAAPolicy .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .HIPAAPolicy {
    padding: 70px 0;
  }
  .HIPAAPolicy .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .HIPAAPolicy .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .HIPAAPolicy .title-txt {
    margin-bottom: 27px;
  }
  .HIPAAPolicy .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .HIPAAPolicy {
    padding: 50px 0;
  }
  .HIPAAPolicy .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .HIPAAPolicy .sub-header-title {
    margin-bottom: 15px;
  }
  .HIPAAPolicy .title-txt {
    margin-bottom: 20px;
  }
  .HIPAAPolicy .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.CCPAPolicy {
  padding: 131px 0;
}
.CCPAPolicy .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.CCPAPolicy .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.CCPAPolicy .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.CCPAPolicy .title-txt {
  margin-bottom: 37px;
}
.CCPAPolicy .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.CCPAPolicy .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.CCPAPolicy .back-yellow {
  background-color: yellow;
}
.CCPAPolicy ol {
  list-style-position: outside;
}
.CCPAPolicy .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}
.CCPAPolicy table.tbl-with-style {
  border-collapse: collapse;
  margin: 15px 0px;
}
.CCPAPolicy table.tbl-with-style,
.CCPAPolicy table.tbl-with-style td,
.CCPAPolicy table.tbl-with-style th {
  border: 1px solid black;
  padding: 5px;
}
.CCPAPolicy table.tbl-with-style td:nth-child(3) {
  text-align: center;
  font-weight: bold;
}
.CCPAPolicy .address-cell {
  vertical-align: top;
}

@media screen and (max-width: 1199px) {
  .CCPAPolicy {
    padding: 90px 0;
  }
  .CCPAPolicy .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .CCPAPolicy {
    padding: 70px 0;
  }
  .CCPAPolicy .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .CCPAPolicy .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .CCPAPolicy .title-txt {
    margin-bottom: 27px;
  }
  .CCPAPolicy .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .CCPAPolicy {
    padding: 50px 0;
  }
  .CCPAPolicy .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .CCPAPolicy .sub-header-title {
    margin-bottom: 15px;
  }
  .CCPAPolicy .title-txt {
    margin-bottom: 20px;
  }
  .CCPAPolicy .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.HealthcareProviderAgreement {
  padding: 131px 0;
}
.HealthcareProviderAgreement .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.HealthcareProviderAgreement .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
  text-transform: uppercase;
}
.HealthcareProviderAgreement .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.HealthcareProviderAgreement .title-txt {
  margin-bottom: 37px;
  text-align: center;
}
.HealthcareProviderAgreement .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.HealthcareProviderAgreement .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.HealthcareProviderAgreement .back-yellow {
  background-color: yellow;
}
.HealthcareProviderAgreement ol {
  list-style-position: outside;
}
.HealthcareProviderAgreement .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}
.HealthcareProviderAgreement table.tbl-with-style {
  border-collapse: collapse;
  margin: 15px 0px;
}
.HealthcareProviderAgreement table.tbl-with-style,
.HealthcareProviderAgreement table.tbl-with-style td,
.HealthcareProviderAgreement table.tbl-with-style th {
  border: 1px solid black;
  padding: 5px;
}
.HealthcareProviderAgreement table.tbl-with-style td:nth-child(3) {
  text-align: center;
  font-weight: bold;
}
.HealthcareProviderAgreement .mb-10 {
  margin-bottom: 10px;
}
.HealthcareProviderAgreement .nobull {
  list-style-type: none;
}

@media screen and (max-width: 1199px) {
  .HealthcareProviderAgreement {
    padding: 90px 0;
  }
  .HealthcareProviderAgreement .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .HealthcareProviderAgreement {
    padding: 70px 0;
  }
  .HealthcareProviderAgreement .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .HealthcareProviderAgreement .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .HealthcareProviderAgreement .title-txt {
    margin-bottom: 27px;
  }
  .HealthcareProviderAgreement .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .HealthcareProviderAgreement {
    padding: 50px 0;
  }
  .HealthcareProviderAgreement .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .HealthcareProviderAgreement .sub-header-title {
    margin-bottom: 15px;
  }
  .HealthcareProviderAgreement .title-txt {
    margin-bottom: 20px;
  }
  .HealthcareProviderAgreement .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.AboutUs {
  padding: 131px 0;
}
.AboutUs .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.AboutUs .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.AboutUs .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.AboutUs .title-txt {
  margin-bottom: 37px;
}
.AboutUs .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
  .AboutUs {
    padding: 90px 0;
  }
  .AboutUs .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .AboutUs {
    padding: 70px 0;
  }
  .AboutUs .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .AboutUs .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .AboutUs .title-txt {
    margin-bottom: 27px;
  }
  .AboutUs .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .AboutUs {
    padding: 50px 0;
  }
  .AboutUs .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .AboutUs .sub-header-title {
    margin-bottom: 15px;
  }
  .AboutUs .title-txt {
    margin-bottom: 20px;
  }
  .AboutUs .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.FAQ {
  padding: 131px 0;
}
.FAQ .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.FAQ .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.FAQ .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.FAQ .title-txt {
  margin-bottom: 37px;
}
.FAQ .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
  .FAQ {
    padding: 90px 0;
  }
  .FAQ .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .FAQ {
    padding: 70px 0;
  }
  .FAQ .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .FAQ .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .FAQ .title-txt {
    margin-bottom: 27px;
  }
  .FAQ .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .FAQ {
    padding: 50px 0;
  }
  .FAQ .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .FAQ .sub-header-title {
    margin-bottom: 15px;
  }
  .FAQ .title-txt {
    margin-bottom: 20px;
  }
  .FAQ .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.ContactUs {
  padding: 131px 0;
}
.ContactUs .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.ContactUs .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.ContactUs .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.ContactUs .title-txt {
  margin-bottom: 37px;
}
.ContactUs .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
  .ContactUs {
    padding: 90px 0;
  }
  .ContactUs .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .ContactUs {
    padding: 70px 0;
  }
  .ContactUs .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .ContactUs .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .ContactUs .title-txt {
    margin-bottom: 27px;
  }
  .ContactUs .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .ContactUs {
    padding: 50px 0;
  }
  .ContactUs .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .ContactUs .sub-header-title {
    margin-bottom: 15px;
  }
  .ContactUs .title-txt {
    margin-bottom: 20px;
  }
  .ContactUs .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.Blog {
  padding: 131px 0;
}
.Blog .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.Blog .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.Blog .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.Blog .title-txt {
  margin-bottom: 37px;
}
.Blog .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
  .Blog {
    padding: 90px 0;
  }
  .Blog .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .Blog {
    padding: 70px 0;
  }
  .Blog .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .Blog .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .Blog .title-txt {
    margin-bottom: 27px;
  }
  .Blog .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .Blog {
    padding: 50px 0;
  }
  .Blog .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .Blog .sub-header-title {
    margin-bottom: 15px;
  }
  .Blog .title-txt {
    margin-bottom: 20px;
  }
  .Blog .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.MedicalTerms {
  padding: 131px 0;
}
.MedicalTerms .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.MedicalTerms .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.MedicalTerms .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.MedicalTerms .title-txt {
  margin-bottom: 37px;
}
.MedicalTerms .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
  .MedicalTerms {
    padding: 90px 0;
  }
  .MedicalTerms .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .MedicalTerms {
    padding: 70px 0;
  }
  .MedicalTerms .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .MedicalTerms .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .MedicalTerms .title-txt {
    margin-bottom: 27px;
  }
  .MedicalTerms .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .MedicalTerms {
    padding: 50px 0;
  }
  .MedicalTerms .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .MedicalTerms .sub-header-title {
    margin-bottom: 15px;
  }
  .MedicalTerms .title-txt {
    margin-bottom: 20px;
  }
  .MedicalTerms .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.LoadingPage {
  background-color: #fbfbf9;
  padding-top: 119px;
  min-height: calc(100vh - 62px);
  display: flex;
}
.LoadingPage .loading-content {
  width: 531px;
  margin: auto;
  text-align: center;
  padding: 50px 15px;
}
.LoadingPage .loading-content img {
  width: 140px;
  transform: translateZ(0);
  animation: circleanimation 2.5s infinite linear;
}
.LoadingPage .loading-content .h3 {
  font-family: 'Heldane Display';
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 70px;
}
.LoadingPage ~ .CustomFooter {
  display: none;
}

/* define animation function for above */
@keyframes circleanimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .LoadingPage .loading-content .h3 {
    font-size: 28px;
    margin-bottom: 50px;
  }
  .LoadingPage .loading-content {
    margin-top: 0;
    padding: 0 30px;
  }
  .LoadingPage {
    padding-top: 69px;
  }
  .LoadingPage .loading-content img {
    width: 90px;
  }
}

.PatientHeader {
  background-color: #013648;
  height: 119px;
  display: flex;
}
.PatientHeader > DIV {
  width: 100%;
}
.PatientHeader .container {
  display: flex;
  width: 100%;
  padding: 0px 60px;
}
.PatientHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.PatientHeader .header-content .right-col {
  display: flex;
  align-items: center;
}
.PatientHeader .header-content .right-col .menu-content SPAN {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #ffffff;
}
.PatientHeader .header-content .right-col .menu-content A {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #55bfdb;
}
.PatientHeader .header-content .right-col .menu-content SPAN:last-child {
  padding-right: 0px;
}
.PatientHeader .header-content .logo {
  width: 158px;
}
.PatientHeader .header-content .logo A {
  position: relative;
  top: 5px;
}
.PatientHeader .header-content .logo A IMG {
  width: 169px;
  cursor: pointer;
}
.PatientHeader .right-col.after-login .menu-content {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}
.PatientHeader .right-col.after-login .menu-content > SPAN {
  display: inline-block;
  padding: 0px 40px;
}
.PatientHeader .right-col.after-login .menu-content .submenu {
  position: relative;
}
.PatientHeader .right-col.after-login .menu-content .submenu .username {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #ffffff;
  position: relative;
  padding-right: 25px !important;
  cursor: pointer;
  display: inline-block;
}
.PatientHeader .right-col.after-login .menu-content .submenu .title {
  display: none;
}
.PatientHeader .right-col.after-login .menu-content .submenu .username > IMG {
  position: absolute;
  right: 0;
  top: 7px;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu {
  position: absolute;
  top: 80px;
  width: 279px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: 0.3s all linear;
  background-color: #ffffff;
  min-width: 264px;
  z-index: 9;
  overflow: hidden;
  display: none;
}
.PatientHeader .header-content .right-col .menu-content .submenu.show-menu .dropdown-menu {
  display: block;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu > SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.email IMG {
  width: 20px;
  position: absolute;
  left: 20px;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.email {
  color: #3dbce7;
  padding-left: 47px;
  position: relative;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout {
  padding-left: 47px;
  position: relative;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG.logout-sm {
  display: none;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.settings-web {
  padding-left: 47px;
  position: relative;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.settings-web IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.PatientHeader .header-content .right-col .menu-content A.home-link {
  text-decoration: none;
  color: #ffffff;
  margin-right: 39px;
}
.PatientHeader .overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.PatientHeader .mobile-menu {
  display: none;
}
.PatientHeader .logoblue {
  display: none;
}
.PatientHeader .logowhite {
  display: block;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu > SPAN.contact-us {
  display: none;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu .submenu-mobile-list {
  display: none;
}
.deletegraycolor {
  filter: invert(19%) sepia(10%) saturate(40%) hue-rotate(315deg) brightness(86%) contrast(89%);
}
.deletewhitecolor {
  filter: brightness(4.5);
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu .settings-mobile {
  display: none;
}
@media (hover: hover) {
  .PatientHeader .mobile-menu:hover SPAN:last-child {
    width: 23px;
  }
  .PatientHeader .header-content .right-col A:hover {
    text-decoration: none;
    color: rgba(85, 191, 219, 0.9);
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}

@media (max-width: 1200px) {
  .PatientHeader .container {
    padding: 0 30px;
  }
}
@media (max-width: 1024px) {
  .PatientHeader .header-content .right-col .menu-content .submenu.show-menu .dropdown-menu {
    top: 40px;
  }
}
@media (max-width: 767px) {
  .PatientHeader {
    height: 108px;
  }
  .PatientHeader .header-content .logo {
    width: 128px;
  }
  .PatientHeader .header-content .logo A IMG {
    width: 100%;
  }
  .PatientHeader .right-col {
    transition: 0.3s all linear;
    position: absolute;
    right: 0;
    top: -69px;
    left: 0;
    transition: 0.3s all linear;
    padding: 0 30px 20px;
    background-color: #013648;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    flex-wrap: wrap;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN {
    width: 100%;
    text-align: center;
    padding: 0px 0px;
    margin: 10px 0px;
    font-family: 'Calibre Regular';
    font-size: 24px;
    line-height: 1.83;
    letter-spacing: -0.24px;
    color: #fbfbf9;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.83;
    letter-spacing: -0.24px;
    color: #fbfbf9;
  }
  .PatientHeader .container {
    padding: 0 15px 0 15px;
  }
  .PatientHeader .mobile-menu SPAN {
    width: 23px;
    display: inline-block;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    transition: 0.3s all linear;
  }
  .PatientHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .PatientHeader .mobile-menu SPAN:nth-child(2) {
    top: 8px;
  }
  .PatientHeader .mobile-menu SPAN:last-child {
    top: 16px;
    width: 10px;
    left: 0;
  }
  .PatientHeader .mobile-menu {
    width: 23px;
    height: 15px;
    position: relative;
    cursor: pointer;
    display: block;
    margin: auto 0;
    top: -2px;
  }
  .PatientHeader.menu-active .right-col {
    right: 0px;
  }
  .PatientHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .PatientHeader.menu-active .mobile-menu SPAN {
    background-color: #013648;
    transform: rotate(45deg);
  }
  .PatientHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
  }
  .PatientHeader.menu-active .mobile-menu {
    top: 7px;
  }
  .PatientHeader.menu-active {
    background-color: #013648;
  }
  .PatientHeader .header-content .right-col {
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    top: -100%;
  }
  .PatientHeader.menu-active .header-content .right-col {
    top: 108px;
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
  .PatientHeader.menu-active .mobile-menu SPAN {
    background-color: #ffffff;
  }
  .PatientHeader.menu-active .logo IMG {
    display: none;
  }
  .PatientHeader.menu-active .logo .logowhite IMG,
  .PatientHeader.menu-active .logo .logowhite {
    display: block;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN {
    font-size: 18px;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN A {
    font-size: 16px;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu {
    position: relative;
    top: 0;
    box-shadow: none;
    width: 100%;
    display: block;
    background-color: transparent;
    margin-top: 27px;
    margin-bottom: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu {
    margin-bottom: 0;
    padding-right: 0 !important;
    margin-top: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu .title {
    text-align: left;
    font-family: 'Heldane Display';
    font-size: 36px;
    color: #fbfbf9;
    display: block;
    line-height: 1.28;
    word-break: break-all;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu > SPAN {
    font-family: 'Calibre Medium';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: #fbfbf9;
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 8px;
  }
  .PatientHeader .right-col.after-login .dropdown-menu > SPAN:last-child {
    font-size: 24px;
    line-height: 1.17;
    letter-spacing: -0.24px;
    color: #fbfbf9;
    font-family: 'Calibre Regular';
    border-bottom: 0;
    border-top: solid 1.5px rgba(255, 255, 255, 0.08);
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 0;
  }
  .PatientHeader .right-col .settings-mobile > SPAN:first-child {
    font-family: 'Calibre Medium';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: #fbfbf9;
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 8px;
  }
  .PatientHeader .right-col .settings-mobile > SPAN.setting-sm {
    position: relative;
    padding-left: 35px;
  }
  .PatientHeader .right-col .settings-mobile > SPAN.setting-sm IMG {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu > SPAN.contact-us A {
    opacity: 0.5;
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.38px;
    color: #fbfbf9;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu > SPAN.contact-us {
    position: absolute;
    right: 0;
    bottom: 0px;
    display: inline-block;
    width: AUTO;
    margin-top: 0;
    z-index: 2;
  }
  .PatientHeader .right-col .menu-content {
    width: 100%;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu > IMG {
    display: none;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu .settings-web {
    display: none;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu .submenu-mobile-list {
    display: inline-block;
    padding: 0;
    margin: 0;
    display: none;
    width: 100%;
    border-top: solid 1.5px rgba(255, 255, 255, 0.08);
    padding-top: 15px;
    margin-top: 13px;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu .submenu-mobile-list > A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 2.3;
    letter-spacing: -0.2px;
    color: #fbfbf9;
    text-align: left;
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu-mobile-list SPAN:last-child {
    border-bottom: 0 !important;
  }
  .PatientHeader
    .right-col.after-login
    .menu-content
    .settings-mobile.show-menu
    .submenu-mobile-list {
    display: block;
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu .settings-mobile {
    display: block;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu .title {
    display: block;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu .username {
    display: none;
  }
  .PatientHeader .container {
    padding: 0 30px 0 30px;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content SPAN.logout {
    padding-top: 20px;
    font-size: 24px;
    line-height: 28px;
    padding-left: 35px;
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG {
    left: 0;
    display: none;
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG.logout-sm {
    display: block;
  }
  .PatientHeader .header-content .right-col .menu-content .submenu.show-menu .dropdown-menu {
    top: 0px;
  }
  @media (hover: hover) {
    .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu SPAN:hover {
      background-color: transparent;
    }
  }
}

@media (max-width: 413px) {
  .PatientHeader .container {
    padding: 0 15px;
  }
}

.Input-control {
  position: relative;
  margin-bottom: 12px;
}
.Input-control .form-control {
  color: #000;
  border: none;
  padding: 5px 25px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 2px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 52px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #18222a;
  -webkit-appearance: none;
}
.Input-control .form-control:focus {
  outline: none;
}
.Input-control.transform-input .form-control:focus {
  border-color: #013648;
  padding: 20px 25px 5px;
}
.Input-control .form-control:focus ~ .label,
.Input-control .form-control:not(:placeholder-shown) ~ .label {
  top: calc(25% - 0.5rem);
  transform: translate(0, 0%);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.26px;
  color: rgba(1, 54, 72, 0.5);
}
.Input-control .label {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(5px, -50%);
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
}

.Input-control .form-control:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}
.Input-control .form-control:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

.Input-control .view-img {
  position: absolute;
  right: 17px;
  width: 21px;
  height: 13px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.Input-control.form-group {
  margin-bottom: 23px;
}
.Input-control.form-group,
.Input-control.form-group .label,
.Input-control.form-group input {
  width: 100%;
  display: inline-block;
}
.Input-control.form-group input {
  padding: 3px 15px 0px 25px;
  height: 52px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.Input-control.form-group input.KalyMDRequestForm:read-only{
  background-color: #e8e8e8;
  cursor: not-allowed;
}
.Input-control.form-group .form-control:focus,
.Input-control.form-group .form-control:not(:placeholder-shown) {
  /* padding: 0px 15px; */
  border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.Input-control.form-group .form-control:focus {
  border: solid 1.5px #013648;
}
.Input-control.form-group .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.Input-control.form-group .view-img {
  top: calc(100% - 2rem);
}

.Input-control.link-container {
  position: relative;
}
.Input-control.link-container input {
  padding-right: 76px;
}
.Input-control.link-container .lookup-link {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
  position: absolute;
  right: 27px;
  top: 40px;
}

.Input-control .instruction-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  font-size: 13px;
}
.Input-control .error-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-size: 13px;
}
.Input-control.form-group.error input.form-control,
.Input-control.error input.form-control {
  border-color: rgba(255, 0, 0, 0.4) !important;
}

.Input-control.transform-input {
  margin-bottom: 20px;
}
.Input-control.transform-input .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}
.Input-control.transform-input .form-control::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
}
.Input-control.transform-input .form-control:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}
.Input-control.transform-input .form-control:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
}
.Input-control.filled-focus-input .label  {
  top: calc(25% - 0.5rem);
  transform: translate(0, 0%);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.26px;
  color: rgba(1, 54, 72, 0.5);
}
.Input-control .form-control[disabled] {
  background-color: #f8f8f8;
  border-color: transparent !important;
}
/* edge issue resolved for transform input */
.Input-control.transform-input .label {
  position: absolute;
  left: 25px;
  top: 15px;
  pointer-events: none;
  transition: all 0.14s ease-in-out;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
  transform: none;
}
.Input-control.transform-input.filled-focus-input .label {
  top: 6px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.26px;
  color: rgba(1, 54, 72, 0.5);
  transform: none;
}
.Input-control.transform-input.filled-focus-input .form-control {
  padding: 20px 25px 5px;
}
.Input-control .charectercount-text {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 1.38;
  color: #b2bcc4;
}
.Input-control.url-link input {
  padding-right: 45px;
}
.Input-control.url-link .angle-up,
.Input-control.url-link .angle-down {
  width: 18px;
  position: absolute;
  right: 20px;
  top: 26px;
}
.Input-control.url-link .angle-up {
  top: 10px;
  transform: rotate(180deg);
}
.Input-control SPAN.label IMG {
  width: 20px;
  position: relative;
  top: 6px;
  float: right;
  top: 0;
}

@media (hover: hover) {
  .Input-control.link-container .lookup-link:hover {
    color: #013648;
  }
}

@media screen and (max-width: 767px) {
  .Input-control.form-group input {
    padding: 3px 15px 0px 10px;
    font-size: 16px;
  }
  .Input-control.link-container .lookup-link {
    right: 11px;
  }
}

.UILabel {
  position: fixed;
  right: 15px;
  top: -89px;
  background-color: #ff9836;
  padding: 20px;
  z-index: 999;
  color: #fff;
}

.MyDatetime .rdtPicker {
  width: 497px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.5);
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 24px 0;
  padding-top: 70px;
  padding-bottom: 0;
  font-family: 'Calibre Regular';
  cursor: auto;
}
.MyDatetime .rdtPicker td.rdtToday:before {
  display: none;
}
.MyDatetime .rdtPicker .rdtDays table {
  border-collapse: separate;
  border-spacing: 30px 10px;
}
.MyDatetime.months .rdtPicker,
.MyDatetime.years .rdtPicker {
  padding-top: 15px;
}
.MyDatetime.time .rdtPicker {
  padding-top: 30px;
  width: 307px;
  height: 200px;
}
.MyDatetime.time .rdtCounterSeparator {
  line-height: 117px;
}
.MyDatetime.days .rdtPicker .rdtSwitch {
  display: inline-block;
  position: absolute;
  left: 36px;
  right: 36px;
  width: 140px;
  top: 50px;
  text-align: left;
  border: 0;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #013648;
}
.MyDatetime.days .rdtPicker tfoot {
  display: none;
}
.MyDatetime.days .rdtPicker th.rdtNext,
.MyDatetime.days .rdtPicker th.rdtPrev {
  position: absolute;
  right: 64px;
  width: 26px;
  height: 26px;
  border-radius: 6.4px;
  border: solid 0.8px #e2e2ea;
  top: 37px;
}
.MyDatetime.days .rdtPicker th.rdtNext {
  right: 30px;
}
.MyDatetime.days .rdtPicker .rdtSwitch:after {
  content: '';
  height: 1px;
  background-color: #e2e2ea;
  position: absolute;
  left: 132px;
  right: 0;
  top: 0px;
  width: 240px;
}
.MyDatetime.days .rdtPicker th.rdtNext span,
.MyDatetime.days .rdtPicker th.rdtPrev span {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  position: relative;
  left: -1px;
}
.MyDatetime.days .rdtPicker .dow {
  font-family: 'Calibre Medium';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #92929d;
}
.MyDatetime.days .rdtPicker td {
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  color: #013648;
}
.MyDatetime .rdtPicker td.rdtActive{
  width: 30px;
  height: 33px;
  border-radius: 5px;
  background-color: rgba(1, 54, 72, 0.17);
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #013648;
  padding-top: 5px;
}
.MyDatetime.time .rdtCounter .rdtCount {
  height: 40%;
  line-height: 40px;
}
.MyDatetime .rdtPicker td.rdtDisabled{
  background: none;
  color: #c7bebe;
  cursor: not-allowed;
}

@media (hover: hover) {
  .MyDatetime .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #c7bebe;
    cursor: not-allowed;
  }
  .MyDatetime .rdtPicker td.rdtActive:hover {
    width: 30px;
    height: 33px;
    border-radius: 5px;
    background-color: rgba(1, 54, 72, 0.17);
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #013648;
    padding-top: 5px;
  }
  .MyDatetime.days .rdtPicker .rdtSwitch:hover {
    background-color: transparent;
  }
  .MyDatetime .rdtPicker td.rdtDay:hover {
    background: #d4dde0;
    cursor: pointer;
    border-radius: 5px;
  }
}

@media (max-width: 413px) {
  .MyDatetime.days .rdtPicker th.rdtNext {
    right: 10px;
  }
  .MyDatetime.days .rdtPicker th.rdtPrev {
    right: 40px;
  }
  .MyDatetime.days .rdtPicker .rdtSwitch {
    left: 15px;
  }
}

.PatientForm {
  height: calc(100vh - 150px);
}
.PatientForm .UILabel {
  top: 30px;
}
.PatientForm form {
  width: 350px;
  margin: auto;
  padding-top: 50px;
}
.PatientForm .select-appointment-date {
  position: relative;
}
.PatientForm .select-appointment-date .datepicker {
  position: absolute;
  z-index: 9;
  position: absolute;
  top: 55px;
}
.PatientForm .select-appointment-date .MyDatetime .rdtPicker {
  height: 350px;
  overflow: hidden;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch {
  top: 34px;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch:after {
  top: 10px;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker .dow {
  text-transform: uppercase;
  font-family: 'Calibre Semibold';
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker td {
  line-height: 1.7;
  padding-top: 5px;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker th.rdtNext,
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker th.rdtPrev {
  top: 30px;
}
.PatientForm .select-appointment-date .MyDatetime .rdtPicker .rdtDays table thead tr .dow {
  border-spacing: 20px 5px;
}
.PatientForm .select-appointment-date .MyDatetime .rdtPicker {
  padding-left: 0;
  padding-right: 0;
}
.PatientForm .Input-control.form-group input {
  padding-left: 60px;
  height: 55px;
}
.PatientForm .Input-control .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
}
.PatientForm .search {
  position: relative;
}
.PatientForm .search .search-result {
  padding: 12px 0px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  position: absolute;
  width: 500px;
  height: 400px;
  max-width: 100%;
  overflow-x: auto;
  top: 54px;
  z-index: 99;
  display: none;
}
.PatientForm .search.active .search-result {
  display: block;
}
.PatientForm .search-result .search-category {
  margin-bottom: 20px;
}
.PatientForm .search-result .search-category:last-child {
  margin-bottom: 0;
}
.PatientForm .search-result .search-category .h3 {
  opacity: 0.5;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #18222a;
  padding: 0px 18px;
}
.PatientForm .search-result .search-category span {
  display: inline-block;
  width: 100%;
}
.PatientForm .search-result .search-category .search-item {
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #013648;
  padding: 0px 18px;
  cursor: pointer;
}
.PatientForm .doctor-item {
  padding-left: 75px;
  position: relative;
  font-size: 20px;
  line-height: 1.05;
  color: #013648;
  display: inline-block;
  margin-bottom: 20px;
  min-height: 48px;
}
.PatientForm .doctor-item img {
  width: 40px;
  height: 48px;
  border-radius: 2px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  position: absolute;
  left: 19px;
}
.PatientForm .doctor-item span:last-child {
  font-size: 18px;
  color: #72787d;
}
.PatientForm .search-category.doctor {
  position: relative;
  padding-top: 30px;
}
.PatientForm .search-category.doctor:after {
  content: '';
  position: absolute;
  left: 19px;
  right: 19px;
  top: 0;
  height: 1px;
  background-color: rgba(151, 151, 151, 0.24);
}
@media (hover: hover) {
  .PatientForm .search-result .search-category .search-item:hover {
    background-color: #eaf4eb;
  }
}

.AutoSuggestionSelect {
  position: relative;
}
.AutoSuggestionSelect .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.AutoSuggestionSelect .result {
  width: 100%;
  min-width: 300px;
  max-height: 402px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 62px;
  padding: 18px 32px;
  z-index: 8;
}
.AutoSuggestionSelect .result .option {
  font-size: 16px;
  line-height: 1.95;
  color: #013648;
  cursor: pointer;
  width: 100%;
  display: block;

}
.AutoSuggestionSelect .result  button.option {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
}
.AutoSuggestionSelect .Input-control.form-group .view-img {
  position: absolute;
  left: 18px;
  top: calc(100% - 1.9rem);
  width: 20px;
  height: 20px;
}
.AutoSuggestionSelect INPUT {
  padding-left: 52px;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control {
  font-size: 20px;
  line-height: 1.64;
  letter-spacing: -0.38px;
  color: #b2bcc4;
  padding-top: 5px;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control::placeholder {
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #b2bcc4;
  opacity: 1;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control {
  padding-left: 52px;
  color: #013648;
}
@media (hover: hover) {
  .AutoSuggestionSelect .result .option:hover {
    color: #3dbce7;
  }
}
@media (max-width: 767px) {
  .AutoSuggestionSelect .result {
    max-height: 200px;
  }
}

.Divider .horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #e3e7ed;
  display: inline-block;
}
@media (max-width: 767px) {
  .Divider {
    display: none;
  }
}

.SpecialityModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 575px;
  margin: auto;
}
.SpecialityModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 41px 0 45px;
}
.SpecialityModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.SpecialityModal .modal-content-header .close img {
  width: 100%;
}
.SpecialityModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.SpecialityModal .modal-content-body {
  padding: 35px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.SpecialityModal .sub-text,
.SpecialityModal .question {
  font-size: 18px;
  line-height: 1.28;
  color: #013648;
}
.SpecialityModal .sub-text {
  margin-bottom: 39px;
}
.SpecialityModal .question {
  font-size: 24px;
  line-height: 1.13;
  margin-bottom: 25px;
}
.SpecialityModal .sf-select-answer.sf-list-content .sf-description-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 24px;
}
.SpecialityModal .sf-select-answer.sf-list-content .sf-description-item span {
  border-radius: 21.6px;
  border: solid 1px #013648;
  border-top-left-radius: 0;
  background-color: transparent;
  cursor: pointer;
  display: inline-block;
  min-width: 325px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  padding: 11px 24px 9px;
  min-height: 47px;
}
.SpecialityModal .sf-select-answer.sf-list-content.txt-center .sf-description-item span {
  border-radius: 21.6px;
}
.SpecialityModal .sf-select-answer.sf-list-content .select-option span {
  background-color: #013648;
  color: #ffffff;
}
.SpecialityModal.sf-select-answer.sf-action {
  text-align: center;
  padding-top: 3px;
}
.SpecialityModal .sf-action .select-option-text {
  font-family: 'TT Commons';
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #b2bcc4;
  cursor: pointer;
}
.SpecialityModal .txt-center {
  text-align: center;
}

@media (hover: hover) {
  .SpecialityModal .sf-select-answer.sf-list-content .sf-description-item:hover span {
    background-color: #013648;
    color: #ffffff;
  }
  .SpecialityModal .sf-action .select-option-text:hover {
    color: #72787d;
  }
}

@media (max-width: 768px) {
  .SpecialityModal {
    width: 100%;
  }
  .SpecialityModal .sf-select-answer.sf-list-content .sf-description-item span {
    min-width: 100%;
  }
}
@media (max-width: 414px) {
  .SpecialityModal .modal-content-body {
    padding: 15px;
  }
  .SpecialityModal .modal-content-header {
    padding: 0 15px;
  }
  .SpecialityModal .modal-content-header .h3 {
    font-size: 27px;
  }
}

.SpecialityFlow {
  padding: 100px 60px 420px;
  display: flex;
  background-color: rgba(251, 251, 249, 0.8);
  position: relative;
  min-height: calc(100vh - 119px);
}
.SpecialityFlow .sf-sidebar .question-title {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.7;
  letter-spacing: normal;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.SpecialityFlow .sf-sidebar .question-title.answer {
  color: #b2bcc4;
  width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.SpecialityFlow .sf-sidebar {
  display: flex;
  flex-direction: column;
  width: 150px;
  position: absolute;
  left: 60px;
  top: 104px;
}
.SpecialityFlow .sf-sidebar .arrow,
.SpecialityFlow > .arrow {
  position: absolute;
  top: -37px;
  left: 0;
  cursor: pointer;
}
.SpecialityFlow .sf-sidebar .arrow IMG,
.SpecialityFlow > .arrow IMG {
  width: 20px;
}
.SpecialityFlow > .arrow {
  display: none;
}
.SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
  font-family: 'Heldane Display';
  font-size: 48px;
  line-height: 52px;
  text-align: center;
  color: #013648;
  margin-bottom: 66px;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-inner-content .heading2 {
  margin-bottom: 46px;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-inner-content .description {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-action {
  display: block;
  text-align: center;
}
.SpecialityFlow .sf-main-content.sf-step-3 .sf-action .CircleButton {
  margin: 0 13px;
}
.SpecialityFlow .sf-main-content .sf-list-content .sf-description-item SPAN {
  border-radius: 21.6px;
  background-color: rgba(1, 54, 72, 0.05);
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 100%;
  display: inline-block;
  margin-bottom: 18px;
  padding: 14px 24px 8px;
  border-top-left-radius: 0;
}
.SpecialityFlow .sf-main-content .sf-list-content .sf-description-item.active-item SPAN {
  background-color: #013648;
  color: #ffffff;
}
.SpecialityFlow .sf-main-content .sf-list-content.sf-description-content .sf-description-item SPAN {
  border-radius: 21.6px;
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 100%;
  display: inline-block;
  border-top-left-radius: 0;
  margin-bottom: 10px;
  padding: 0;
  background-color: transparent;
}
.SpecialityFlow
  .sf-main-content
  .sf-list-content.sf-description-content
  .sf-description-item:last-child
  SPAN {
  margin-bottom: 0;
}
.SpecialityFlow .sf-main-content .sf-list-content {
  width: 425px;
  margin: auto;
}
.SpecialityFlow .sf-main-content .sf-list-content.sf-description-content {
  border-radius: 4px;
  background-color: #f5f5f2;
  padding: 18px 24px;
}
.SpecialityFlow .sf-main-content {
  width: 700px;
  margin: 0 auto;
}
.SpecialityFlow .sf-main-content.sf-step-3 {
  width: 645px;
}
.SpecialityFlow .sf-main-content.sf-step-3 .pms-content .pms-list SPAN {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.85;
  color: #013648;
  text-align: left;
}
.SpecialityFlow .sf-action {
  display: flex;
  justify-content: space-around;
  width: 390px;
  margin: auto;
  margin-top: 36px;
}
.SpecialityFlow .sf-action .CircleButton.SecondaryButton {
  width: 136px;
  font-family: 'Calibre Regular';
}
.SpecialityFlow .sf-action .CircleButton.SecondaryButton:focus {
  outline: 0;
}
.SpecialityFlow .sf-select-answer.sf-list-content {
  width: 290px;
}
.SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.SpecialityFlow .sf-list-content.multi-col-option {
  width: 100%;
}
.SpecialityFlow .sf-step-3 .sf-select-answer.sf-list-content {
  margin-top: 25px;
}
.SpecialityFlow .sf-select-answer.sf-list-content.long-options {
  width: 478px;
  max-width: 100%;
}
.SpecialityFlow .sf-select-answer.sf-list-content .sf-description-item {
  display: inline-block;
  width: 100%;
}
.SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option .sf-description-item {
  width: 30%;
  margin: 0 10px;
}
.SpecialityFlow .sf-select-answer.sf-list-content .sf-description-item SPAN {
  border-radius: 21.6px;
  border-top-left-radius: 0;
  background-color: transparent;
  cursor: pointer;
  background-color: #e7ebeb;
}
.SpecialityFlow .sf-select-answer.sf-list-content .select-option SPAN {
  background-color: #013648;
  color: #ffffff;
}
@media (hover: hover) {
  .SpecialityFlow .sf-select-answer.sf-list-content .sf-description-item:hover SPAN {
    background-color: #013648;
    color: #ffffff;
  }
}
.SpecialityFlow .sf-select-answer.sf-action {
  text-align: center;
}
.SpecialityFlow .sf-action .select-option-text {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #72787d;
  font-family: 'TT Commons';
}
.SpecialityFlow .sf-main-content.sf-step-complete .select-option-text {
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.SpecialityFlow .sf-main-content.sf-step-complete .urgent-care-text {
  font-size: 20px;
  line-height: 2.65;
  color: #ef4444;
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 40px;
}
.SpecialityFlow .sf-main-content.sf-step-complete {
  width: 473px;
  /* margin-left: 116px; */
}
.SpecialityFlow .sf-main-content.sf-step-complete .sf-list-content {
  width: 473px;
}
.SpecialityFlow .sf-main-content.sf-step-complete .sf-inner-content .heading2 {
  margin-bottom: 44px;
}
.SpecialityFlow .sf-pain-select .Select-control.form-group SELECT {
  border-radius: 30.5px;
  border: solid 1.3px #013648;
  font-size: 18px;
  line-height: 3;
  letter-spacing: -0.34px;
  color: #013648;
}
.SpecialityFlow .sf-pain-select .Select-control .arrow {
  top: 24px;
}
.SpecialityFlow .sf-pain-select .Select-control.form-group:after {
  background-color: #fbfbf9;
}
.SpecialityFlow .sf-main-content .sf-list-content.pms-content {
  width: 592px;
}
.SpecialityFlow .pms-content .description {
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  color: #013648;
  margin-bottom: 28px;
  display: inline-block;
}
.SpecialityFlow .pms-content .pms-list SPAN {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.85;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: HeldaneDisplay;
  font-size: 20px;
  line-height: 1.85;
}
.SpecialityFlow .pms-content .pms-list {
  margin-bottom: 36px;
  padding: 0 13%;
}
.SpecialityFlow .pms-content .Divider .container {
  width: 100%;
}
.SpecialityFlow .pms-content .Divider .container .horizontal-line {
  background-color: #b2bcc4;
}
.SpecialityFlow .pms-content .h3 {
  height: 39px;
  font-family: 'Heldane Display';
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
  color: #013648;
  margin-top: 30px;
  text-align: center;
  display: inline-block;
  width: 100%;
  text-align: center;
}
.SpecialityFlow .sf-sidebar .doctor-info .doctor-img {
  width: 100px;
  height: 150px;
  display: inline-block;
  margin-top: 12px;
}
.SpecialityFlow .sf-sidebar .doctor-info .doctor-img IMG {
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.SpecialityFlow .sf-sidebar .doctor-info .doctor-name {
  display: inline-block;
  width: 100%;
  font-family: 'Heldane Display';
  font-size: 22px;
  line-height: 1.55;
  color: #013648;
  margin-top: 13px;
  margin-bottom: 3px;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT {
  width: 425px;
  max-width: 100%;
  height: 61px;
  border-radius: 6px;
  border: solid 1.3px #013648;
  background-color: #ffffff;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect .Input-control.form-group .view-img,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect .Input-control.form-group .view-img {
  left: 18px;
  top: calc(100% - 2.2rem);
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control::-webkit-input-placeholder,
.SpecialityFlow
  .sf-pain-select
  .AutoSuggestionMultiSelect
  INPUT.form-control::-webkit-input-placeholder {
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control::-moz-placeholder,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT.form-control::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control:-ms-input-placeholder,
.SpecialityFlow
  .sf-pain-select
  .AutoSuggestionMultiSelect
  INPUT.form-control:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT.form-control:-moz-placeholder,
.SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT.form-control:-moz-placeholder {
  /* Firefox 18- */
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.SpecialityFlow .sf-main-content.description-without-gray-background {
  width: 592px;
  max-width: 100%;
}
.SpecialityFlow .sf-main-content.description-without-gray-background .heading2 {
  padding: 0px 10px;
  margin-bottom: 45px;
}
.SpecialityFlow .sf-main-content.description-without-gray-background span.description SPAN {
  text-align: center;
  display: inline-block;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.SpecialityFlow .sf-main-content.description-without-gray-background .sf-action {
  display: block;
  text-align: center;
  margin-top: 51px;
}
.SpecialityFlow .sf-main-content.description-without-gray-background .sf-action .CircleButton {
  margin: 0 13px;
}
.SpecialityFlow .multiselect-selected-options {
  margin: -5px;
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
}
.SpecialityFlow .multiselect-selected-options.pain {
  width: 100%;
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid #abacac;
}
.SpecialityFlow .item-right {
  display: flex;
  justify-content: center;
}
.SpecialityFlow .error-msg {
  color: RGBA(255, 0, 0, 0.8);
  font-size: 18px;
  font-family: 'Calibre Regular';
  text-align: center;
}
.SpecialityFlow .item-right button[disabled] {
  color: #fff;
}
.SpecialityFlow .RequestaCallSMS button.SecondaryButton{
  width: 260px !important;
  margin-bottom: 10px !important;
}
@media (max-width: 1199px) {
  .SpecialityFlow {
    padding: 100px 30px 420px;
  }
  .SpecialityFlow .sf-main-content {
    width: 670px;
  }
  .SpecialityFlow .sf-sidebar {
    left: 30px;
  }
  .SpecialityFlow .sf-main-content.sf-step-3,
  .SpecialityFlow .sf-main-content .sf-list-content.pms-content {
    width: 476px;
  }
}
.SpecialityFlow .kalymd-form.sf-action .CircleButton.SecondaryButton{
  width: 157px;
  /* font-size: 15px; */
}
@media (max-width: 1023px) {
  .SpecialityFlow .sf-sidebar .doctor-info .doctor-name {
    font-size: 18px;
  }
  .SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
    font-size: 32px;
    line-height: 36px;
  }
  .SpecialityFlow .sf-sidebar .question-title {
    font-size: 18px;
  }
  .SpecialityFlow .sf-sidebar {
    width: 17%;
  }
  .SpecialityFlow .sf-main-content {
    width: 400px;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background {
    width: 400px;
  }
  .SpecialityFlow .sf-main-content.sf-step-3,
  .SpecialityFlow .sf-main-content .sf-list-content.pms-content {
    width: 400px;
  }
  .SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option .sf-description-item {
    width: 48%;
    margin: 0px 4px;
  }
}

@media (max-width: 767px) {
  .SpecialityFlow {
    flex-wrap: wrap;
    padding: 63px 24px 100px;
  }
  .SpecialityFlow .sf-sidebar {
    width: 100%;
    margin-bottom: 30px;
    padding-bottom: 30px;
    position: relative;
    left: 0;
  }
  .SpecialityFlow .sf-main-content.sf-step-complete .select-option-text {
    text-align: left;
    font-size: 18px;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background SPAN.description SPAN {
    text-align: left;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background .heading2 {
    padding: 0;
  }
  .SpecialityFlow .sf-main-content.description-without-gray-background .sf-action {
    text-align: left;
  }
  .SpecialityFlow
    .sf-main-content.description-without-gray-background
    .sf-action
    .CircleButton:first-child {
    margin-left: 0;
  }
  .SpecialityFlow span.sf-description-item{
    padding-left: 25%;
  }
  .SpecialityFlow .sf-list-content span.sf-description-item{
    padding-left: 0;
  }
  .SpecialityFlow .sf-main-content {
    margin-left: auto;
  }
  .SpecialityFlow .sf-main-content.sf-step-complete {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
    margin-bottom: 44px;
    text-align: center;
  }
  .SpecialityFlow .sf-main-content,
  .SpecialityFlow .sf-main-content .sf-list-content,
  .SpecialityFlow .sf-action {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-list-content {
    margin-left: 0;
  }
  .SpecialityFlow .sf-main-content .sf-inner-content .heading2 {
    font-size: 28px;
    line-height: 32px;
  }
  .SpecialityFlow .sf-main-content.sf-step-complete .urgent-care-text {
    font-size: 24px;
    text-align: left;
    margin-top: 60px;
    font-family: 'Calibre Medium';
    line-height: 1.75;
  }
  .SpecialityFlow .sf-action .select-option-text {
    text-align: left;
    width: 100%;
  }
  .SpecialityFlow .sf-select-answer .sf-list-content .sf-description-item SPAN {
    width: 300px;
    border: solid 1px rgba(1, 54, 72, 0.2);
    font-family: 'Calibre Medium';
    max-width: 100%;
  }
  .SpecialityFlow .sf-select-answer .sf-action {
    justify-content: left;
    font-family: 'Calibre Medium';
  }
  .SpecialityFlow .sf-sidebar .arrow {
    display: none;
  }
  .SpecialityFlow > .arrow {
    display: block;
    top: 21px;
    left: 24px;
  }
  .SpecialityFlow .pms-content .description {
    font-size: 16px;
  }
  .SpecialityFlow .pms-content .description,
  .SpecialityFlow .pms-content .pms-list SPAN {
    text-align: left;
  }
  .SpecialityFlow .pms-content .pms-list SPAN {
    font-family: 'Calibre Medium';
    color: #013648;
    line-height: 1.22;
  }
  .SpecialityFlow .Divider {
    display: block;
  }
  .SpecialityFlow .pms-content .Divider .container {
    padding: 0;
  }
  .SpecialityFlow .pms-content .Divider .container .horizontal-line {
    background-color: rgba(1, 54, 72, 0.07);
  }
  .SpecialityFlow .pms-content .h3 {
    margin-top: 35px;
    font-size: 28px;
    height: auto;
    /* text-align: left; */
  }
  .SpecialityFlow .sf-action {
    justify-content: center;
    margin-top: 47px;
    margin-bottom: 39px;
  }
  .SpecialityFlow .pms-content .pms-list{
    padding: 0 5%;
  }
  .SpecialityFlow .sf-main-content.sf-step-3 .sf-action {
    /* text-align: left; */
  }
  .SpecialityFlow .sf-main-content.sf-step-3 .sf-action .CircleButton {
    margin-left: 0;
    margin-right: 26px;
    width: 100%;
    margin-bottom: 10px;
  }
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    margin-right: 40px;
  }
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton:last-child {
    margin-right: 0;
  }
  .SpecialityFlow .sf-main-content.sf-step-3 .sf-inner-content .description {
    text-align: left;
  }
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    width: 140px;
    background-color: #013648;
    color: #fff;
  }
  .SpecialityFlow .sf-action.complete-action .CircleButton.SecondaryButton {
    width: 100%;
  }
  .SpecialityFlow .sf-pain-select .AutoSuggestionSelect INPUT,
  .SpecialityFlow .sf-pain-select .AutoSuggestionMultiSelect INPUT {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-list-content .sf-description-item SPAN {
    margin-bottom: 13px;
    padding: 12px 22px 6px;
    letter-spacing: -0.2px;
  }
  .SpecialityFlow .sf-sidebar .doctor-info .doctor-img {
    width: 60px;
    height: 90px;
    position: ABSOLUTE;
    left: 0;
  }
  .SpecialityFlow .sf-sidebar .doctor-info .doctor-name {
    line-height: 1.22;
  }
  .SpecialityFlow .sf-sidebar .doctor-info {
    padding-left: 75px;
    position: relative;
  }
  .SpecialityFlow .sf-sidebar .doctor-info ~ SPAN {
    padding-left: 75px;
  }
  .SpecialityFlow .sf-main-content .sf-list-content.pms-content {
    width: 100%;
    margin: 0px;
  }
  .SpecialityFlow .sf-sidebar .question-title.answer {
    width: 100%;
    overflow: visible;
  }
}

@media (max-width: 600px) {
  .SpecialityFlow .sf-select-answer.sf-list-content.multi-col-option .sf-description-item {
    width: 100%;
  }
  .SpecialityFlow .sf-main-content .sf-list-content{
    text-align: center;
  }
  .SpecialityFlow .sf-main-content .sf-list-content .sf-pain-select{
    text-align: left;
  }
  .SpecialityFlow span.sf-description-item{
    padding-left: 0;
    text-align: left;
  }
}

@media (max-width: 375px) {
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    width: 115px;
  }
  .SpecialityFlow .pms-content .pms-list{
    padding: 0 
  }
}
@supports (-webkit-touch-callout: none) {
  .SpecialityFlow .sf-action .CircleButton.SecondaryButton {
    padding-top: 2px;
  }
}

.Checkbox-control {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 10px;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
}
.Checkbox-control A {
  color: #3dbce7;
}
.Checkbox-control INPUT:disabled {
  cursor: initial;
}
.Checkbox-control INPUT {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 18px;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1;
}
.Checkbox-control .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 18px;
  border-radius: 1.1px;
  border: solid 1px #013648;
}
.Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #013648;
}
.Checkbox-control .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.Checkbox-control INPUT:checked ~ .checkmark:after {
  display: block;
}
.Checkbox-control .checkmark:after {
  left: 6px;
  top: 1px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.Checkbox-control-label{
  cursor: pointer;
}
@media (hover: hover) {
  .Checkbox-control A:hover {
    color: #013648;
  }
}
@media (max-width: 767px) {
  .Checkbox-control .checkbox-container:not(:last-child) {
    margin-bottom: 23px;
  }
}
.is-disabled{
  border-color: #dcdcdc !important;
  cursor: not-allowed !important;
}

.kalymdrequestform {
  padding: 60px 0;
  background-color: #fbfbf9;
  min-height: calc(100vh - 281px);
}
.kalymdrequestform .kalymdrequestform-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.kalymdrequestform .h3 {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 26px;
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  font-weight: normal;
}
.kalymdrequestform .checkbox-block {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.kalymdrequestform .checkbox-block.readandacceptclass{
  justify-content: flex-start;

}
.kalymdrequestform .checkbox-block.readandacceptclass .Checkbox-control {
  margin-right: 0;
}
.kalymdrequestform .checkbox-block.readandacceptclass .labeldiv{
  letter-spacing: -0.24px;
  color: #013648;
  font-size: 18px;
}

.kalymdrequestform .Checkbox-control {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.kalymdrequestform .login-btn {
  margin-bottom: 47px;
  margin-top: 28px;
}
.kalymdrequestform .login-btn .CircleButton.WhiteButton {
  width: 100%;
  height: 45px;
  line-height: 45px;
}

.kalymdrequestform .checkbox-block .reset-link {
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: center;
  color: #3dbce7;
  cursor: pointer;
}

.kalymdrequestform .Input-control.transform-input input {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.kalymdrequestform .Input-control.transform-input input:focus {
  border: solid 1px #013648;
}

.kalymdrequestform .form-row {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
@media (hover: hover) {
  .kalymdrequestform .login-bottom a:hover {
    color: #013648;
  }
  .Login .checkbox-block .reset-link:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .kalymdrequestform {
    padding: 90px 0;
  }
  .kalymdrequestform .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
  .kalymdrequestform .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .kalymdrequestform .login-btn {
    margin-bottom: 37px;
  }
}
@media (max-width: 1199px) {
  .kalymdrequestform .form-row{
    display: block;
  }
}

@media (max-width: 767px) {
  .kalymdrequestform .divider-content .Divider {
    display: block;
  }
  .kalymdrequestform {
    padding: 32px 0;
    min-height: 100vh;
  }
  .kalymdrequestform .login-container {
    padding: 0 0px;
    width: 100%;
    max-width: 100%;
  }
  .kalymdrequestform .h3 {
    font-size: 28px;
    margin-bottom: 15px;
    padding-bottom: 12px;
    padding: 0 30px 15px;
    letter-spacing: 0;
    line-height: 1.21;
  }
  .kalymdrequestform .checkbox-block {
    margin-top: 25px;
    margin-bottom: 15px;
    padding-left: 11px;
  }
  .kalymdrequestform .login-btn {
    margin-bottom: 30px;
    margin-top: 23px;
  }
  .kalymdrequestform .ca-social {
    padding: 0 30px;
  }
  .kalymdrequestform .ca-social .social-icon {
    width: 100%;
    padding-top: 4px;
  }
  .kalymdrequestform .login-form {
    padding: 0px 30px;
  }
  .kalymdrequestform .login-form .Input-control.transform-input {
    margin-bottom: 20px;
  }
  .kalymdrequestform .ca-social .social-icon {
    font-size: 18px;
    border-radius: 2px;
    border: solid 1px rgba(1, 54, 72, 0.2);
    margin-bottom: 15px;
  }
  .kalymdrequestform .login-form .Input-control .form-control {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    color: #013648;
  }
  .kalymdrequestform .Checkbox-control {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    font-family: 'Calibre Medium';
  }
  .kalymdrequestform .checkbox-block .reset-link {
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -0.3px;
    font-family: 'Calibre Medium';
  }
  .kalymdrequestform .login-btn > * {
    width: 100%;
    height: 48px;
    line-height: 48px;
  }
  /* .kalymdrequestform ~ .CustomFooter {
    display: none;
  } */
}
@media (max-width: 413px) {
  .kalymdrequestform .login-form,
  .kalymdrequestform .ca-social {
    padding: 0px 15px;
  }
  .kalymdrequestform .h3 {
    padding: 0 15px 17px;
  }
  .kalymdrequestform .divider-content .Divider .container {
    padding: 0 15px;
  }
}

.PlaceAutocomplete{
    
}
.kalymdpopup .heading2{
  color: #013648;
  font-family: 'Heldane Display';
}
.kalymdpopup p{
  color: #013648;
  
}
iframe{
  width: 100%;
  border: none;
  min-height: 30vh;
}
.mega-modal-kalymd.Modal .modal-wrapper{
  width: 75%;
  max-width: 75%;
}
.mega-modal-kalymd.Modal .modal-wrapper .kalymdpopup{
width: auto !important;
height: 80vh;
}
.mega-modal-kalymd.Modal .modal-wrapper .kalymdpopup .modal-content-body{
  
  height: 80vh;
  }
  .mega-modal-kalymd.Modal .modal-wrapper .kalymdpopup .modal-content-body iframe{
    height: 100%;
  }
/* Absolute Center Spinner */
.Loader {
  position: fixed;
  z-index: 1001;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.Loader:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.15), rgba(0, 0, 0, 0.15));
  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.15), rgba(0, 0, 0, 0.15));
}

/* :not(:required) hides these rules from IE9 and below */
.Loader:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.Loader:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Textarea.form-group {
  margin-bottom: 23px;
  position: relative;
}
.Textarea.form-group,
.Textarea.form-group .label,
.Textarea.form-group TEXTAREA {
  width: 100%;
  display: inline-block;
}
.Textarea.form-group TEXTAREA {
  padding: 9px 15px;
  height: 116px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  resize: none;
}
.Textarea.form-group .form-control[disabled] {
  background-color: #f8f8f8;
}
.Textarea.form-group .form-control:focus,
.Textarea.form-group .form-control:not(:placeholder-shown) {
  padding: 9px 15px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.Textarea.form-group .form-control:focus {
  border: solid 1.5px #013648;
}
.Textarea.form-group .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.Textarea.error .form-control {
  border-color: rgba(255, 0, 0, 0.4);
}
.Textarea .error-text {
  color: red;
  font-size: 13px;
}
.Textarea .charcount-text {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 1.38;
  color: #b2bcc4;
}

.Select-control.form-group SELECT {
  width: 100%;
  display: inline-block;
}
.Select-control {
  margin-bottom: 23px;
  position: relative;
}
.Select-control.form-group SELECT:disabled {
  background-color: #f8f8f8;
}
.Select-control.form-group SELECT {
  height: 52px;
  border-radius: 2px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  background-color: transparent;
  font-size: 18px;
  font-family: 'Calibre Regular';
  z-index: 2;
  position: relative;
  -webkit-appearance: none;
  line-height: 1.56;
  letter-spacing: -0.34px;
  padding: 3px 50px 0 25px;
  /* color: #013648; */
}
.Select-control.form-group:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 24px;
  bottom: 0;
  background-color: #fff;
}
.Select-control .select {
  position: relative;
  margin-bottom: 23px;
}
.Select-control.form-group SELECT:focus {
  outline: 0;
  border-color: #013648;
}
.Select-control .arrow {
  position: absolute;
  border: 9px solid #013648;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 45px;
  right: 27px;
  z-index: 1;
}
.Select-control .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.Select-control .error-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-size: 13px;
}
.Select-control.error SELECT {
  border-color: rgba(255, 0, 0, 0.4);
}
.Select-control.no-filled-select SELECT,
.Select-control.no-filled-select SELECT OPTION {
  color: #013648;
}
.Select-control.no-filled-select SELECT[required=""]:invalid,
.Select-control.no-filled-select SELECT[required=""] option[value=""] {
  color: rgba(1, 54, 72, 0.5);
}
.Select-control SELECT {
  -webkit-appearance: none;
  appearance: none;
  border: none;
}

@media screen and (max-width: 767px) {
  .Select-control.form-group SELECT {
    padding: 3px 30px 0 15px;
    font-size: 16px;
  }
  .Select-control .arrow {
    right: 11px;
  }
}

.FacebookLoginButton {
  /* display: none !important; */
}


img.apple-img{
    width: 20px;
}
.apple-login{
    /* display: none !important; */
}
.CreatePasswordModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.CreatePasswordModal .modal-content-header {
  height: auto;
  min-height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.CreatePasswordModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.CreatePasswordModal .modal-content-header .close img {
  width: 100%;
}
.CreatePasswordModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
  word-break:break-word;
    padding: 10px;
    padding-left: 0;
}
.CreatePasswordModal .modal-content-body {
  padding: 23px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.CreatePasswordModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.CreatePasswordModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.CreatePasswordModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.CreatePasswordModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
@media (max-width: 767px) {
  .CreatePasswordModal .modal-content-body .Input-control .view-img {
    top: calc(100% - 1.8rem);
  }
  @supports (-webkit-touch-callout: none) {
    .CreatePasswordModal .modal-content-footer .CircleButton.WhiteButton {
      padding-top: 2px;
    }
  }
}
@media (max-width: 575px) {
  .CreatePasswordModal {
    width: 100%;
  }
  .CreatePasswordModal .modal-content-body {
    padding: 20px;
  }
  .CreatePasswordModal .modal-content-body .password-information {
    width: 100%;
  }
  .CreatePasswordModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .CreatePasswordModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .CreatePasswordModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}

.PrompOTPModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 370px;
  margin: auto;
}
.PrompOTPModal .modal-content-header {
  height: auto;
  min-height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.PrompOTPModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.PrompOTPModal .modal-content-header .close img {
  width: 100%;
}
.opttext {
  text-align: center;
}
.PrompOTPModal h3 {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  word-break: break-word;
  padding: 10px;
  margin: 0;
  padding-left: 0;
  text-align: center;
}
.PrompOTPModal .opttext-para {
  color: #013648;
  font-size: 15px;
}
.PrompOTPModal .Input-control .form-control {
  height: 42px;
  font-size: 16px;
}
.PrompOTPModal .Input-control.transform-input {
  margin-bottom: 20px;
}
.PrompOTPModal .error-span {
  margin-top: 5px;
  color: red;
  display: block;
  margin-bottom: 5px;
}
.PrompOTPModal .success-span {
  margin-top: 5px;
  color: green;
  display: block;
  margin-bottom: 5px;
}

.PrompOTPModal .Input-control.transform-input .label {
  top: 11px;
  font-size: 17px;
}
.PrompOTPModal .Input-control.transform-input.filled-focus-input .label {
  top: 6px;
  font-size: 14px;
}
.PrompOTPModal .CircleButton.WhiteButton {
  height: 32px;
  line-height: 33px;
}
.actiondiv {
  display: flex;
  justify-content: space-between;
}
.actiondiv span {
  font-family: 'Calibre Medium';
  color: #26acda;
  cursor: pointer;
}
.PrompOTPModal .modal-content-body {
  padding: 23px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.PrompOTPModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.PrompOTPModal .modal-content-footer {
  padding: 8px 45px 33px;
}
.PrompOTPModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.PrompOTPModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
@media (max-width: 767px) {
  .PrompOTPModal .modal-content-body .Input-control .view-img {
    top: calc(100% - 1.8rem);
  }
  @supports (-webkit-touch-callout: none) {
    .PrompOTPModal .modal-content-footer .CircleButton.WhiteButton {
      padding-top: 2px;
    }
  }
}
@media (max-width: 575px) {
  .PrompOTPModal {
    width: 100%;
  }
  .PrompOTPModal .modal-content-body {
    padding: 20px;
  }
  .PrompOTPModal .modal-content-body .password-information {
    width: 100%;
  }
  .PrompOTPModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .PrompOTPModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .PrompOTPModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}

.DateManual {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 28px;
}
.DateManual .date-manual-label {
  position: absolute;
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  top: -23px;
}
.DateManual .error-text {
  position: absolute;
  font-size: 13px;
  color: red;
  bottom: 0;
}
.DateManual .Input-control {
  display: inline-block;
}
.DateManual .Input-control.form-group INPUT {
  padding-left: 25px;
}
.DateManual .Input-control.form-group:nth-child(2) INPUT {
  border-right: 1.5px solid transparent;
  border-left: 1.5px solid transparent;
  border-radius: 0;
}
.DateManual .Input-control.form-group:nth-child(2) {
  border-radius: 0;
}
.DateManual .Input-control.form-group:nth-child(2) INPUT:focus {
  border-color: #013648;
}
.DateManual INPUT::placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
}

.ReserveAppointment {
  padding: 32px 0 0 66px;
}
.ReserveAppointment .h3 {
  width: 100%;
  height: 29px;
  font-size: 24px;
  line-height: 1.21;
  color: #013648;
}
.ReserveAppointment .h3 span {
  font-family: 'Calibre Medium';
}
.ReserveAppointment .already-login-text {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #013648;
  margin-top: 23px;
}
.ReserveAppointment .already-login-text A {
  font-family: 'Calibre Medium';
  color: #26acda;
}
.ReserveAppointment .ca-social .social-icon {
  padding-top: 5px;
  font-size: 18px;
  display: inline-block;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  border: solid 1px #013648;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  color: #000;
  cursor: pointer;
  letter-spacing: -0.34px;
}
.ReserveAppointment .ca-social {
  margin: 15px 0 20px;
}
.ReserveAppointment .ca-social .social-icon IMG {
  margin-right: 11px;
  position: relative;
  top: -2px;
}
.ReserveAppointment .divider-content {
  position: relative;
  margin-bottom: 21px;
  width: 399px;
}
.ReserveAppointment .divider-content .Divider .container {
  width: 100%;
}
.ReserveAppointment .divider-content > SPAN {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fff;
  padding: 9px;
  display: inline-block;
}
.ReserveAppointment FORM {
  width: 398px;
}
.ReserveAppointment .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.ReserveAppointment .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
  width: 100%;
}
.ReserveAppointment .Select-control .arrow {
  top: 22px;
  right: 20px;
}
.ReserveAppointment .Input-control .form-control {
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #18222a;
}
.ReserveAppointment .checkbox-block.privacy {
  margin-top: 32px;
}
.ReserveAppointment .checkbox-block {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #013648;
  padding-left: 30px;
  position: relative;
  margin-bottom: 18px;
}
.ReserveAppointment .checkbox-block A {
  color: #3dbce7;
}
.ReserveAppointment .checkbox-block .Checkbox-control {
  position: absolute;
  left: 0px;
}
.ReserveAppointment .action-btn .CircleButton.WhiteButton {
  width: 100%;
  font-family: 'Calibre Regular';
  height: 45px;
  line-height: 45px;
}
.ReserveAppointment .action-btn {
  margin-top: 35px;
}
.ReserveAppointment .appoitment-smtime {
  display: none;
}
.ReserveAppointment .appoitment-smtime .title {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  display: block;
}
.ReserveAppointment .appoitment-smtime .time-detail {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: block;
}
.ReserveAppointment .appoitment-smtime .view-details {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  font-family: 'Calibre Medium';
  cursor: pointer;
}
.ReserveAppointment .DateManual {
  margin-top: 20px;
}
.ReserveAppointment .Input-control.form-group,
.ReserveAppointment .DateManual .Input-control {
  margin-bottom: 20px;
}
.ReserveAppointment .DateManual .Input-control .form-control::placeholder {
  font-size: 15px;
  font-weight: normal;
}

.reserveappointmentnew .Input-control .form-control {
  height: 26px;
  font-size: 16px;
}
.reserveappointmentnew .Input-control.transform-input .label {
  top: 3px;
  font-size: 16px;
  /* display: none; */
}
.reserveappointmentnew .Input-control.transform-input.filled-focus-input .label {
  display: none;
}
.reserveappointmentnew .Input-control .form-control:placeholder-shown {
  color: rgba(1, 54, 72, 0.5);
  font-size: 16px;
}
.reserveappointmentnew .Input-control.transform-input.filled-focus-input .form-control {
  top: 3px;
  font-size: 16px;
  padding: 5px 25px 5px;
}
.reserveappointmentnew .CircleButton.WhiteButton {
  background-color: #00a806;
}
.reserveappointmentnew .action-btn {
  margin-top: 20px;
}
.reserveappointmentnew .checkbox-block.privacy,
.reserveappointmentnew .already-login-text {
  margin-top: 5px;
}
.reserveappointmentnew .DateManual {
  margin-top: 0;
}
.reserveappointmentnew .Select-control.form-group SELECT {
  height: 28px;
  font-size: 16px;
}
.reserveappointmentnew .Select-control .arrow {
  top: 10px;
}
.AppointmentRequest .appointment-request-detail .h3 {
  margin-bottom: 20px;
  font-size: 20px;
}
@media (max-width: 1400px) {
  .ReserveAppointment .divider-content,
  .ReserveAppointment FORM,
  .ReserveAppointment .ca-social .social-icon {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .ReserveAppointment {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .ReserveAppointment {
    padding-top: 32px;
  }
  .ReserveAppointment .already-login-text {
    margin-top: 18px;
  }
  .ReserveAppointment .ca-social {
    margin: 18px 0 24px;
  }
  .ReserveAppointment .ca-social .social-icon IMG {
    margin-right: 17px;
  }
  .ReserveAppointment .form-group-2-colum {
    margin: 0 -7px;
  }
  .ReserveAppointment .checkbox-block.privacy {
    margin-top: 29px;
    margin-bottom: 5px;
  }
  .ReserveAppointment .checkbox-block {
    line-height: 1.58;
    margin-bottom: 0px;
  }
  .ReserveAppointment .divider-content {
    margin-bottom: 18px;
  }
  .ReserveAppointment .form-group-2-colum .Input-control {
    width: 50%;
    margin-left: 7px;
    margin-right: 7px;
  }
  .ReserveAppointment .h3 {
    font-family: 'Calibre Medium';
    line-height: 1.45;
  }
  .ReserveAppointment .already-login-text A {
    color: #3dbce7;
  }
  .ReserveAppointment .ca-social .social-icon {
    border: solid 1px rgba(1, 54, 72, 0.2);
    font-family: 'Calibre Medium';
    letter-spacing: normal;
    padding-top: 3px;
  }
  .ReserveAppointment .divider-content > SPAN {
    color: rgba(1, 54, 72, 0.5);
    background-color: #fbfbf9;
  }
  .ReserveAppointment .Select-control.form-group:after {
    top: 0;
  }
  .ReserveAppointment .Select-control.form-group SELECT {
    padding: 3px 30px 0 25px;
    font-size: 18px;
  }
  .ReserveAppointment .appoitment-smtime {
    display: block;
    width: calc(100% - 181px);
  }
  .ReserveAppointment .action-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
    margin: 0;
    padding: 19px 30px 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e4e7;
  }
  .ReserveAppointment .action-bottom .CircleButton.WhiteButton {
    letter-spacing: normal;
    min-width: 171px;
    width: auto;
    margin-left: 10px;
    height: 48px;
    line-height: 48px;
  }
  .ReserveAppointment .divider-content .Divider .container {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .ReserveAppointment .h3 {
    width: 100%;

    font-size: 20px;
  }
}
@media (max-width: 413px) {
  .ReserveAppointment .action-bottom {
    padding: 19px 15px 15px;
  }
  .ReserveAppointment .action-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .ReserveAppointment .appoitment-smtime {
    width: 100%;
    text-align: center;
  }
  .ReserveAppointment .action-bottom .CircleButton.WhiteButton {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}
@media (max-width: 320px) {
  .ReserveAppointment .h3 {
    margin-bottom: 5px;
  }
  .ReserveAppointment .already-login-text {
    margin-top: 8px;
  }
}
@supports (-webkit-touch-callout: none) {
  .ReserveAppointment .action-bottom .CircleButton.WhiteButton {
    padding-top: 1px;
  }
}

.AddPatientModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.AddPatientModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.AddPatientModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.AddPatientModal .modal-content-header .close img {
  width: 100%;
}
.AddPatientModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.AddPatientModal .modal-content-body {
  padding: 23px 40px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AddPatientModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.AddPatientModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.AddPatientModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AddPatientModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.AddPatientModal .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.AddPatientModal .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
@media (max-width: 767px) {
  .AddPatientModal {
    width: 100%;
  }
  .AddPatientModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
  .AddPatientModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .AddPatientModal .modal-content-footer {
    padding: 15px;
  }
  .AddPatientModal .modal-content-body {
    padding: 20px 15px;
  }
}
@media (max-width: 439px) {
  .AddPatientModal .confirm-patient-select.Select-control.form-group:after {
    top: 49px;
  }
  .AddPatientModal .confirm-patient-select.Select-control .arrow {
    top: 69px;
  }
}

.ReviewConfirm {
  padding: 26px 0 0 66px;
}
.ReviewConfirm .h3 {
  font-size: 24px;
  line-height: 1.21;
  color: #013648;
}
.ReviewConfirm .h3 SPAN {
  font-family: 'Calibre Medium';
}
.ReviewConfirm FORM {
  width: 398px;
}
.ReviewConfirm .box-content {
  margin-bottom: 11px;
}
.ReviewConfirm .box-content.existing-patient {
  margin-bottom: 20px;
}
.ReviewConfirm .box-content .title {
  font-size: 16px;
  line-height: 1.89;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 2px;
  margin-top: 17px;
}
.ReviewConfirm .box-content .box-inner-content {
  border-radius: 2px;
  background-color: #f1f1f1;
  padding: 12px 25px 8px;
}
.ReviewConfirm .box-content .box-inner-content .name {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #013648;
  position: relative;
  padding-left: 0px;
  margin-bottom: 11px;
}
.ReviewConfirm .box-content .box-inner-content .name .Checkbox-control {
  position: relative;
  left: 0;
  top: -17px;
  width: 20px;
  margin-right: 0;
}
.ReviewConfirm .box-content .box-inner-content .choose-other {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.ReviewConfirm .box-content .box-inner-content .choose-other SPAN {
  color: #26acda;
  cursor: pointer;
}
.ReviewConfirm .box-content .box-inner-content .Checkbox-control {
  display: inline-block;
  margin-right: 21px;
  margin-bottom: 0;
}
.ReviewConfirm .box-content .box-inner-content .Checkbox-control:last-child {
  margin-right: 0;
}
.ReviewConfirm .select-insurance .form-group {
  margin-bottom: 12px;
}
.ReviewConfirm .select-insurance .disable {
  opacity: 0.34;
}
.ReviewConfirm .select-insurance .disable .form-control[disabled] {
  background-color: #f8f8f8;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.ReviewConfirm .select-insurance .disable + .arrow {
  opacity: 0.1;
}
.ReviewConfirm .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.ReviewConfirm .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
.ReviewConfirm .Select-control .plan + .arrow {
  top: 22px;
  right: 27px;
}
.ReviewConfirm .select-insurance .Select-control.error .insuranceCompanyId{
  margin-bottom: 10px;
 
}
.ReviewConfirm .select-insurance .Select-control.errorpading .insuranceCompanyId{
  color: #013648;
 
}
.ReviewConfirm .select-insurance .insuranceMemberId{
  margin-bottom: 30px;
}

.ReviewConfirm .select-insurance .error.errorpading{
  margin-bottom: 30px;
}

.ReviewConfirm .select-insurance .insuranceMemberId span.error-text{

  bottom: -25px;
}
.ReviewConfirm .Input-control .form-control {
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #18222a;
}
.ReviewConfirm .checkbox-block.privacy {
  margin-top: 32px;
}
.ReviewConfirm .checkbox-block {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #013648;
  padding-left: 30px;
  position: relative;
  margin-bottom: 18px;
}
.ReviewConfirm .checkbox-block A {
  color: #3dbce7;
}
.ReviewConfirm .checkbox-block .Checkbox-control {
  position: absolute;
  left: 0px;
}
.ReviewConfirm .action-btn .CircleButton.WhiteButton {
  width: 100%;
}
.ReviewConfirm .Textarea TEXTAREA {
  height: 64px;
}
.ReviewConfirm .appoitment-smtime {
  display: none;
}
.ReviewConfirm .appoitment-smtime .title {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  display: block;
}
.ReviewConfirm .appoitment-smtime .time-detail {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: block;
}
.ReviewConfirm .appoitment-smtime .view-details {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  font-family: 'Calibre Medium';
  cursor: pointer;
}
.ReviewConfirm .Input-control.form-group .label {
  font-family: 'Calibre Regular';
}
.ReviewConfirm .Select-control .label {
  font-family: 'Calibre Regular';
}
.ReviewConfirm .Textarea.form-group .label {
  font-family: 'Calibre Regular';
}
.ReviewConfirm .phonenumber {
  position: relative;
}
.ReviewConfirm .phonenumber .update-number {
  position: absolute;
  bottom: 34px;
  right: 25px;
  font-size: 16px;
  line-height: 1.58;
  font-family: 'Calibre Medium';
  color: #3dbce7;
}
.ReviewConfirm .error-box {
  border: 1px solid red;
}
.ReviewConfirm .error-box .error-text {
  display: block;
  color: red;
  font-size: 13px;
  padding-top: 10px;
}
@media (max-width: 1400px) {
  .ReviewConfirm .divider-content,
  .ReviewConfirm FORM,
  .ReviewConfirm .ca-social .social-icon {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .ReviewConfirm {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .ReviewConfirm {
    padding-top: 31px;
  }
  .ReviewConfirm .Select-control.form-group:after {
    display: none;
  }
  .ReviewConfirm .h3 {
    font-size: 20px;
    line-height: 1.45;
    letter-spacing: normal;
    margin-bottom: 17px;
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .Input-control.form-group .label {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .Select-control .label {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .Textarea.form-group .label {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .box-content .title {
    font-family: 'Calibre Medium';
  }
  .ReviewConfirm .box-content .box-inner-content {
    background-color: #f7f9f9;
  }
  .ReviewConfirm .box-content .box-inner-content .choose-other {
    font-size: 16px;
    line-height: 1.89;
    letter-spacing: -0.3px;
  }
  .ReviewConfirm .box-content .box-inner-content .choose-other SPAN {
    color: #3dbce7;
  }
  .ReviewConfirm .box-content .box-inner-content {
    padding: 7px 20px;
  }
  .ReviewConfirm .box-content.existing-patient .box-inner-content {
    padding: 16px 20px 14px;
  }
  .ReviewConfirm .box-content .box-inner-content .name {
    margin-bottom: 2px;
  }
  .ReviewConfirm .Input-control.form-group .label {
    margin-bottom: 2px;
  }
  .ReviewConfirm .Input-control.form-group {
    margin-bottom: 28px;
  }
  .ReviewConfirm .Select-control .plan + .arrow {
    right: 11px;
  }
  .ReviewConfirm .box-content {
    margin-bottom: 17px;
  }
  .ReviewConfirm .box-content .box-inner-content .Checkbox-control {
    font-family: 'Calibre Medium';
    margin-right: 16px;
  }
  .ReviewConfirm .appoitment-smtime {
    display: block;
    width: calc(100% - 181px);
  }
  .ReviewConfirm .action-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
    margin: 0;
    padding: 19px 30px 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e4e7;
  }
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton {
    letter-spacing: normal;
    min-width: 171px;
    width: auto;
    margin-left: 10px;
    height: 48px;
  }
  .ReviewConfirm .select-insurance .arrow::after {
    display: none;
  }
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton .sm-hide {
    display: none;
  }
  .ReviewConfirm .phonenumber .update-number {
    bottom: 38px;
  }
}
@media (max-width: 575px) {
  .ReviewConfirm .h3 {
    width: 100%;
    font-size: 20px;
  }
}
@media (max-width: 413px) {
  .ReviewConfirm .action-bottom {
    padding: 19px 15px 33px;
  }
  .ReviewConfirm .action-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .ReviewConfirm .appoitment-smtime {
    width: 100%;
    text-align: center;
  }
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@supports (-webkit-touch-callout: none) {
  .ReviewConfirm .action-bottom .CircleButton.WhiteButton {
    padding-top: 3px;
  }
}

.Upload {
  position: relative;
  height: 52px;
  border-radius: 2.2px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  background-color: #ffffff;
  padding: 5px;
  padding-left: 52px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 23px;
  width: 100%;
}
.Upload input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.Upload .error-text {
  position: absolute;
  top: 53px;
  left: 0px;
  color: red;
}
.Upload img {
  width: 42px;
  height: 42px;
  opacity: 0.51;
  border-radius: 1.8px;
  background-color: #f7f9f9;
  position: absolute;
  left: 5px;
  top: 3px;
}
.Upload .filename {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #26acda;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 57px;
  right: 10px;
  top: 13px;
}
.Upload .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  position: absolute;
  top: -24px;
  left: 0;
}
@media (max-width: 414px) {
  .Upload input {
    width: 100%;
  }
}

.Marker {
  position: relative;
}
.Marker .marker-image {
  height: 46px;
}
.Marker .doctor-detail-map {
  position: absolute;
  bottom: 46px;
  left: -139px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  border-radius: 8px;
  width: 317px;
  z-index: 9;
}
.Marker .doctor-card:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid #fff;
}
.Marker .doctor-container {
  width: 317px;
  overflow: hidden;
}
.Marker .doctor-child-container {
  display: flex;
  position: relative;
  left: 0;
}
.Marker .doctor-slider {
  position: absolute;
  top: -48px;
  right: 0;
  background: white;
  justify-content: space-between;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  cursor: auto;
}
.Marker .pagination-div {
  font-size: 20px;
  padding: 0 5px;
  cursor: pointer;
}
.Marker .pagination-div.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.Marker .doctor-card {
  min-width: 317px;
  min-height: 119px;
  padding: 12px 11px;
  display: flex;
  border-bottom: solid 1px rgba(1, 54, 72, 0.1);
}
.Marker .doctor-card:last-child {
  border-bottom: unset;
}
.Marker .doctor-detail-map .dc-img {
  width: 60px;
  min-width: 60px;
  height: 90px;
}
.Marker .doctor-detail-map .dc-img img {
  border-radius: 2.4px;
  border: solid 0.6px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Marker .doctor-detail-map .dc-info {
  padding-left: 11px;
  width: 100%;
}
.Marker .doctor-detail-map .dc-details .dc-name {
  font-family: 'Heldane Display';
  font-size: 14px;
  line-height: 1.46;
  color: #013648;
  word-break: break-all;
}
.Marker .doctor-detail-map .dc-details .dc-specialist {
  font-family: 'Calibre Regular';
  font-size: 12px;
  line-height: 1.26;
  letter-spacing: normal;
  color: #013648;
  word-break: break-all;
}
.Marker .doctor-detail-map .dc-details .dc-address {
  font-family: 'Calibre Regular';
  font-size: 12px;
  line-height: 1.17;
  color: #72787d;
  word-break: break-all;
}
.Marker .doctor-detail-map .dc-content {
  display: flex;
  justify-content: space-between;
}
.Marker .doctor-detail-map .dc-rating img {
  width: 24px;
  height: 23px;
  object-fit: cover;
  position: relative;
  top: 4px;
  left: 5px;
}
.Marker .doctor-detail-map .dc-rating {
  position: relative;
  display: flex;
}
.Marker .doctor-detail-map .dc-rating .rating-text {
  font-family: 'Heldane Display';
  font-size: 28.8px;
  line-height: 0.71;
  letter-spacing: -0.58px;
  color: #013648;
}
.Marker .doctor-detail-map .dc-action {
  display: flex;
}
.Marker .doctor-detail-map .dc-action a,
.Marker .doctor-detail-map .dc-action span {
  font-family: 'Calibre Regular';
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.26px;
  color: #26acda;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
}
.Marker .doctor-detail-map .dc-action span {
  margin-left: 18px;
}

@media (hover: hover) {
  .Marker .doctor-detail-map .dc-action a:hover,
  .Marker .doctor-detail-map .dc-action span:hover {
    opacity: 0.7;
  }
}

@media (max-width: 767px) {
  /* .Marker {
    position: static;
  }
  .Marker .doctor-detail-map {
    position: fixed;
    left: -48%;
    right: 30px;
    bottom: -212px;
  }
  .Marker .doctor-card:before {
    display: none;
  } */
}


.AppointmentRequest .appointment-map {
  height: 107px;
  width: 100%;
}
.AppointmentRequest .appointment-request-detail {
  padding-bottom: 25px;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
}
.AppointmentRequest .appointment-request-detail .h3 {
  font-size: 24px;
  line-height: 0.79;
  color: #013648;
  margin-bottom: 46px;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .title {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .Specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .address {
  margin-top: 25px;
  margin-bottom: 24px;
  line-height: 1.25;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: flex;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .address .icon {
  width: 17px;
  margin-right: 14px;
  display: inline-block;
  position: relative;
  top: -3px;
}
.AppointmentRequest .appointment-doctor {
  display: flex;
}
.AppointmentRequest .appointment-doctor .doctor-img {
  min-width: 100px;
  width: 100px;
  display: inline-block;
  height: 150px;
}
.AppointmentRequest .appointment-doctor .doctor-img img {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AppointmentRequest .appointment-doctor .appointment-doctor-info {
  padding-left: 33px;
  width: calc(100% - 100px);
}
.AppointmentRequest .questionnaire {
  display: flex;
  padding-top: 28px;
  padding-bottom: 30px;
}
.AppointmentRequestthanks .questionnaire {
  justify-content: space-between;
  padding-bottom: 0;
}
.AppointmentRequest .qu-right {
  width: 55%;
}
.AppointmentRequestthanks .qu-left,
.AppointmentRequestthanks .qu-right {
  width: 49% !important;
}
.AppointmentRequest .qu-left {
  width: 45%;
  padding-right: 5px;
}
.AppointmentRequest .qu-left .logo-icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
  display: inline-block;
}
.AppointmentRequest .qu-left .logo-icon img {
  width: 100%;
}
.AppointmentRequest .qu-left .title {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
  margin-top: 6px;
}
.AppointmentRequest .qu-left .questionnaire-list span {
  display: inline-block;
  width: 100%;
}
.AppointmentRequest .qu-left .questionnaire-list {
  font-size: 18px;
  line-height: 1.67;
  color: #013648;
  margin-top: 14px;
}
.AppointmentRequest .qu-right {
  border-radius: 4px;
  background-color: #f1f1f1;
  display: flex;
}
.AppointmentRequest .qu-right .appointment-time {
  margin: auto;
  padding: 30px 0 30px;
  text-align: center;
}
.AppointmentRequest .qu-right .appointment-time .time {
  text-align: left;
  width: 132px;
  min-width: 132px;
  min-height: 132px;
  border-radius: 4.8px;
  background-color: #013648;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.45px;
  color: #f7fffc;
  margin: auto;
  margin-bottom: 29px;
  display: flex;
  padding: 18px 11px;
  /* padding-right: 0; */
}
.AppointmentRequest .qu-right .appointment-time .time > span {
  margin: auto;
}
.AppointmentRequest .qu-right .appointment-time .edit-appointment {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: center;
  color: #26acda;
  cursor: pointer;
  text-decoration: underline;
}
.AppointmentRequest .qu-right .appointment-time .time .day,
.AppointmentRequest .qu-right .appointment-time .time .date {
  display: inline-block;
  width: 100%;
}
.AppointmentRequest .qu-right .appointment-time .time .day {
  font-family: 'Calibre Medium';
}
.AppointmentRequest .h3 {
  position: relative;
}
.AppointmentRequest .back-arrow {
  position: absolute;
  left: -130px;
  top: -5px;
  width: 25px;
  cursor: pointer;
}
.AppointmentRequest .person-visit,
.AppointmentRequest .video-visit {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-family: 'Calibre Medium';
  font-size: 24px;
  line-height: 1.19;
  letter-spacing: -0.34px;
  color: #013648;
  position: relative;
  padding-left: 24px;
  margin-bottom: 25px;
}
.AppointmentRequest .video-visit img {
  width: 46px;
  position: absolute;
  left: -15px;
  top: -13px;
}
.AppointmentRequest .person-visit img {
  width: 45px;
  position: absolute;
  left: -21px;
  top: -12px;
}
.AppointmentRequest .qu-right.inPerson {
  background-color: #cce3eac9;
}
.AppointmentRequest .qu-right.inVideo {
  background-color: #eeebf5;
}
.AppointmentRequest .video-visit {
  color: #8d77ba;
}
.appointmentRequestnewview .appointment-request-detail .h3 {
  margin-bottom: 20px;
  font-size: 20px;
}
.appointmentRequestnewview .appointment-doctor .doctor-img {
  height: 100px;
}
.appointmentRequestnewview .appointment-request-detail .appointment-doctor-info .title {
  font-size: 20px;
}
.appointmentRequestnewview .appointment-request-detail .appointment-doctor-info .address {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #e85d72;
}
.appointmentRequestnewview .appointment-request-detail .appointment-doctor-info .address img {
  filter: invert(47%) sepia(69%) saturate(1210%) hue-rotate(316deg) brightness(96%) contrast(90%);
}
.appointmentRequestnewview .qu-right .appointment-time {
  padding: 10px 0 10px;
}
.appointmentRequestnewview .person-visit,
.appointmentRequestnewview .video-visit {
  margin-bottom: 10px;
  font-size: 18px;
}
.appointmentRequestnewview .person-visit img {
  width: 38px;
  left: -16px;
}
.appointmentRequestnewview .qu-right .appointment-time .time {
  text-align: center;
  font-size: 20px;
  min-height: 115px;
  margin-bottom: 15px;
}
.appointmentRequestnewview .qu-right .appointment-time .edit-appointment {
  font-size: 16px;
}

@media (max-width: 991px) {
  .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
  .AppointmentRequest .qu-left {
    width: 100%;
  }
  .AppointmentRequest .qu-right {
    width: 100%;
  }
  .AppointmentRequest .qu-left .questionnaire-list {
    margin-bottom: 15px;
  }
  .AppointmentRequest .appointment-doctor {
    flex-wrap: wrap;
  }
  .AppointmentRequest .appointment-doctor .appointment-doctor-info {
    padding-left: 0;
    margin-top: 15px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .AppointmentRequest .appointment-request-detail .h3 {
    display: none;
  }
  .AppointmentRequest .appointment-doctor .doctor-img {
    width: 70px;
    height: 99px;
    min-width: 70px;
  }
  .AppointmentRequest .appointment-doctor .appointment-doctor-info {
    width: calc(100% - 70px);
    padding-left: 17px;
  }
  .AppointmentRequest .questionnaire {
    display: none;
  }
  .AppointmentRequestthanks .questionnaire {
    display: block;
  }
  .AppointmentRequestthanks .qu-left,
  .AppointmentRequestthanks .qu-right {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .AppointmentRequest .appointment-request-detail {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .address {
    margin-bottom: 0;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .title {
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 12px;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .Specialist {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    margin-bottom: 3px;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .address {
    margin-top: 0;
    letter-spacing: normal;
    opacity: 0.4;
  }
  .AddPatientModal .form-group-2-colum {
    flex-wrap: wrap;
  }
  .AddPatientModal .form-group-2-colum .Input-control {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .AppointmentRequest .qu-right,
  .AppointmentRequest .qu-left {
    width: 100%;
  }
}

.AppointmentBookedModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 678px;
  margin: auto;
}
.AppointmentBookedModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.AppointmentBookedModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.AppointmentBookedModal .modal-content-header .close img {
  width: 100%;
}
.AppointmentBookedModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.AppointmentBookedModal .modal-content-body {
  padding: 36px 100px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AppointmentBookedModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.AppointmentBookedModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.AppointmentBookedModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AppointmentBookedModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.AppointmentBookedModal .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.AppointmentBookedModal .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
.AppointmentBookedModal .AppointmentRequest .qu-right .appointment-time .time {
  margin-bottom: 0;
}
.AppointmentBookedModal .AppointmentRequest .appointment-doctor .doctor-img {
  width: 120px;
  min-width: 120px;
  height: 179px;
}
@media (max-width: 767px) {
  .AppointmentBookedModal {
    width: 100%;
  }
  .AppointmentBookedModal .modal-content-body {
    height: calc(100vh - 180px);
  }
  .AppointmentBookedModal .AppointmentRequest .appointment-map {
    display: block;
    margin-top: 15px;
    width: 100%;
  }
  .AppointmentBookedModal .AppointmentRequest .questionnaire {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .AppointmentBookedModal .modal-content-body {
    padding: 36px 30px;
  }
  .AppointmentBookedModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .AppointmentBookedModal .modal-content-header {
    padding-left: 20px;
  }
  .AppointmentBookedModal .AppointmentRequest .appointment-doctor .doctor-img {
    width: 70px;
    min-width: 70px;
    height: 115px;
  }
  .AppointmentBookedModal .modal-content-header {
    height: auto;
    padding: 10px 20px;
  }
}
@media (max-width: 575px) {
  .AppointmentBookedModal .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
}

.DoctorAppointmentTime {
  display: flex;
}
.DoctorAppointmentTime .appointment-time {
  min-width: 120px;
  width: 120px;
  min-height: 140px;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  border: solid 0.8px #013648;
  background-color: #013648;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.45px;
  color: #f7fffc;
  margin-bottom: 9px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DoctorAppointmentTime .appointment-time span {
  display: inline-block;
  width: 100%;
}
.DoctorAppointmentTime .appointment-time .day {
  font-family: 'Calibre Medium';
}
.DoctorAppointmentTime .dc-info {
  padding-left: 23px;
}
.DoctorAppointmentTime .dc-info .name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.DoctorAppointmentTime .dc-info .dc-specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.DoctorAppointmentTime .dc-info .address {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  display: flex;
  align-items: flex-start;
}
.DoctorAppointmentTime .dc-info .address {
  padding-top: 17px;
}
.DoctorAppointmentTime .dc-info .address .icon {
  width: 14px;
  margin-right: 14px;
  display: inline-block;
}

.RescheduleAppointmentModal {
  background-color: #fff;
  /* border: solid 1px #e2e2ea; */
  border-radius: 4px;
  overflow: hidden;
  width: 586px;
  max-width: 100%;
  margin: auto;
}
.RescheduleAppointmentModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.RescheduleAppointmentModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.RescheduleAppointmentModal .modal-content-header .close img {
  width: 100%;
}
.RescheduleAppointmentModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.RescheduleAppointmentModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.RescheduleAppointmentModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.RescheduleAppointmentModal .Divider {
  margin-top: 10px;
}

.RescheduleAppointmentModal .Divider .container {
  width: auto;
}
.RescheduleAppointmentModal .dc-appointment-time {
  padding-top: 40px;
}
.RescheduleAppointmentModal .timing-table {
  width: 100%;
}
.RescheduleAppointmentModal .table-wrapper {
  position: relative;
  display: inline-block;
  padding: 0px 53px 0px 52px;
}
.RescheduleAppointmentModal .table-wrapper .prev-link {
  position: absolute;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
.RescheduleAppointmentModal .table-wrapper .right-link {
  position: absolute;
  right: 0;
  top: -4px;
  transform: rotate(180deg);
  cursor: pointer;
}
.RescheduleAppointmentModal .table-wrapper .prev-link img,
.RescheduleAppointmentModal .table-wrapper .right-link img {
  width: 24px;
}
.RescheduleAppointmentModal .table-wrapper .prev-link.disabled img,
.RescheduleAppointmentModal .table-wrapper .right-link.disabled img {
  opacity: 0.21;
  cursor: not-allowed;
}
.RescheduleAppointmentModal .timing-table .tr {
  display: flex;
}
.RescheduleAppointmentModal .timing-table .tr {
  margin: 0px -5px;
}
.RescheduleAppointmentModal .timing-table .th {
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: center;
  width: 83px;
}
.RescheduleAppointmentModal .timing-table .th .date {
  display: block;
  font-family: 'Calibre Regular';
  font-weight: normal;
  text-align: center;
}
.RescheduleAppointmentModal .timing-table .tr .th:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .timing-table .td {
  padding: 4px 5px 4px;
}
.RescheduleAppointmentModal .timing-table .tr .td:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .timing-table .tr:last-child .td {
  padding-bottom: 0;
}
.RescheduleAppointmentModal .timing-table .td .time {
  font-size: 14px;
  line-height: 1.31;
  letter-spacing: normal;
  border-radius: 13.8px;
  padding: 6px 3px 2px;
  text-align: center;
  vertical-align: middle;
  border: 0.7px solid rgba(1, 54, 72, 0.1);
  color: #013648;
  min-width: 73px;
}
.RescheduleAppointmentModal .timing-table .td.available .time {
  color: #fff;
  border: 1px solid #013648;
  cursor: pointer;
  background-color: #013648
}
.RescheduleAppointmentModal .appointment-video .timing-table .td.available .time {
  color: #fff;
  border: 1px solid #6a3acd;
  cursor: pointer;
  background-color: #6a3acd
}
.RescheduleAppointmentModal .timing-table .td.booked .time {
  background-color: rgba(1, 54, 72, 0.1);
  border: 0.7px solid #013648;
  color: #013648;
  cursor: not-allowed;
}
.RescheduleAppointmentModal .day-table {
  width: 100%;
  border-spacing: 0;
}
.RescheduleAppointmentModal .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RescheduleAppointmentModal .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.RescheduleAppointmentModal .day-table .tr .td:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .day-table .tr:last-child .td {
  padding-bottom: 0;
}
.RescheduleAppointmentModal .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.RescheduleAppointmentModal .appointment-inner-block {
  margin-bottom: 46px;
}
.RescheduleAppointmentModal .appointments-day-block {
  padding-top: 10px;
}
.RescheduleAppointmentModal .appointment-inner-block:last-child {
  margin-bottom: 0;
}
.RescheduleAppointmentModal .dc-appointment-time .more-time {
  margin-top: 37px;
  cursor: pointer;
}
.RescheduleAppointmentModal .dc-appointment-time .more-time span {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: right;
  color: #26acda;
}

@media (hover: hover) {
  .RescheduleAppointmentModal .timing-table .td.available .time:hover {
    background-color: rgba(1,54,72,.1);
    color: #013648;
    border: 1px solid #013648;
  }
  .RescheduleAppointmentModal .appointment-video .timing-table .td.available .time:hover{
    background-color: #fff !important;
    color: #013648;
    border: 1px solid #013648;
  }
}

@media (max-width: 767px) {
  .RescheduleAppointmentModal {
    width: 100%;
  }
  .RescheduleAppointmentModal .DoctorAppointmentTime {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .RescheduleAppointmentModal .DoctorAppointmentTime .dc-info {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
  .RescheduleAppointmentModal .appointment-inner-block {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .RescheduleAppointmentModal .modal-content-header {
    padding-left: 20px;
  }
  .RescheduleAppointmentModal .modal-content-body {
    padding: 29px 20px;
  }
  .RescheduleAppointmentModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .RescheduleAppointmentModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .RescheduleAppointmentModal .modal-content-body {
    height: calc(100vh - 200px);
  }
  /* .RescheduleAppointmentModal .timing-table .td.available .time {
    background-color: #013648;
    border: 0px;
    color: #fff;
  } */
}

.VerifyPhoneNumberModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.VerifyPhoneNumberModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.VerifyPhoneNumberModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.VerifyPhoneNumberModal .modal-content-header .close IMG {
  width: 100%;
}
.VerifyPhoneNumberModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.VerifyPhoneNumberModal .modal-content-body {
  padding: 23px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.VerifyPhoneNumberModal .modal-content-body .resendcode-text {
  display: inline-block;
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.VerifyPhoneNumberModal .modal-content-body .resendcode-text SPAN {
  color: #26acda;
  cursor: pointer;
}
.VerifyPhoneNumberModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.VerifyPhoneNumberModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.VerifyPhoneNumberModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.VerifyPhoneNumberModal .modal-content-body .text-info {
  width: 318px;
  height: 20px;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
  margin-bottom: 43px;
}
.VerifyPhoneNumberModal .modal-content-body .text-info SPAN {
  font-family: 'Calibre Medium';
}
.VerifyPhoneNumberModal .modal-content-footer .contact-us-text {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
  text-align: center;
  margin-top: 85px;
}
.VerifyPhoneNumberModal .modal-content-footer {
  padding-top: 0;
}
.VerifyPhoneNumberModal .modal-content-footer .contact-us-text A {
  color: #26acda;
}
@media screen and (max-width: 767px) {
  .VerifyPhoneNumberModal {
    width: 100%;
  }
  .VerifyPhoneNumberModal .modal-content-body {
    padding: 20px 15px;
  }
  .VerifyPhoneNumberModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
}

.EmailInputModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.EmailInputModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.EmailInputModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.EmailInputModal .modal-content-header .close IMG {
  width: 100%;
}
.EmailInputModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.EmailInputModal .modal-content-body {
  padding: 23px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.EmailInputModal .modal-content-body .resendcode-text {
  display: inline-block;
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.EmailInputModal .modal-content-body .resendcode-text SPAN {
  color: #26acda;
  cursor: pointer;
}
.EmailInputModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.EmailInputModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.EmailInputModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.EmailInputModal .modal-content-body .text-info {
  width: 318px;
  height: 20px;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
  margin-bottom: 43px;
}
.EmailInputModal .modal-content-body .text-info SPAN {
  font-family: 'Calibre Medium';
}
.EmailInputModal .modal-content-footer .contact-us-text {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
  text-align: center;
  margin-top: 85px;
}
.EmailInputModal .modal-content-footer {
  padding-top: 0;
}
.EmailInputModal .modal-content-footer .contact-us-text A {
  color: #26acda;
}
@media screen and (max-width: 767px) {
  .EmailInputModal {
    width: 100%;
  }
  .EmailInputModal .modal-content-body {
    padding: 20px 15px;
  }
  .EmailInputModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
}

.ConfirmAppointment .ConfirmAppointment-content .left-content {
  background-color: #fbfbf9;
  width: 55%;
  padding-top: 36px;
  padding-left: 13.9%;
  padding-right: 81px;
}
.ConfirmAppointment .ConfirmAppointment-content .right-content {
  background-color: #fff;
  width: 50%;
  padding-right: 13.2%;
}
.ConfirmAppointment .ConfirmAppointment-content {
  justify-content: space-between;
  display: flex;
}
.ConfirmAppointment .reserve-appointment {
  height: 57.6px;
  font-size: 14px;
  line-height: 1.37;
  letter-spacing: -0.19px;
  color: rgba(1, 54, 72, 0.5);
  width: 100%;
  margin-top: 56px;
  margin-bottom: 50px;
}
.ConfirmAppointment .reserve-appointment a {
  color: #3dbce7;
}
.ConfirmAppointment .CustomFooter {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-top: 62px;
}
.ConfirmAppointment .footer-block:after {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
  width: 52.6%;
  background-color: #fbfbf9;
}
.ConfirmAppointment .CustomFooter .container {
  width: 100%;
}
.ConfirmAppointment .CustomFooter .footer-menu-ul {
  z-index: 1;
}
.ConfirmAppointment .left-content .title-heading {
  display: none;
}
.ConfirmAppointment .ReviewConfirm .reserve-appointment {
  display: none;
  margin-top: 24px;
}
@media (max-width: 1400px) {
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    padding-left: 100px;
    padding-right: 60px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content {
    padding-right: 60px;
  }
  .ConfirmAppointment .AppointmentRequest .back-arrow {
    left: -50px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    padding-left: 70px;
    padding-right: 30px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content {
    padding-right: 30px;
    padding-left: 30px;
  }
  .ConfirmAppointment .CustomFooter {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    width: 100%;
  }
  .ConfirmAppointment .footer-block:after {
    width: 50.6%;
  }
}
@media (max-width: 1200px) {
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    width: 55%;
  }
  .AppointmentRequest .appointment-map {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ConfirmAppointment {
    padding-bottom: 118px;
  }
  .ConfirmAppointment .CustomFooter {
    padding-bottom: 41px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    width: 100%;
  }
  .ConfirmAppointment .CustomFooter {
    padding-top: 41px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content {
    background-color: #fbfbf9;
  }
  .ConfirmAppointment .CustomFooter {
    background-color: #fbfbf9;
  }
  .ConfirmAppointment .ConfirmAppointment-content {
    flex-wrap: wrap;
  }
  .ConfirmAppointment .footer-block:after {
    display: none;
  }
  .ReserveAppointment .divider-content .Divider {
    display: block;
  }
  .ConfirmAppointment .reserve-appointment {
    display: none;
  }
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    background-color: #eaf4eb;
    padding-top: 10px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading {
    font-family: 'Heldane Display';
    font-size: 28px;
    line-height: 1.21;
    color: #fff;
    padding-top: 36px;
    position: relative;
    border-bottom: 1px solid #e0e4e7;
    padding-bottom: 18px;
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 18px;
    display: block;
    background-color: #023648;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading .title-mobile {
    display: flex;
    justify-content: space-between;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .address {
    font-size: 16px;
    line-height: 1.25;
    color: #013648;
    opacity: 1;
    margin-top: 43px;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading img {
    width: 18px;
    /* position: absolute; */
    top: -20px;
    cursor: pointer;
    right: 0;
  }
  .ConfirmAppointment.showappointment-mobile .reserve-appointment {
    display: block;
  }
  .ConfirmAppointment.showappointment-mobile .AppointmentRequest .questionnaire {
    display: flex;
    flex-wrap: nowrap;
  }
  .ConfirmAppointment.showappointment-mobile .left-content {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 11;
    overflow: auto;
    padding-top: 112px;
    padding-bottom: 85px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-overflow-scrolling: touch;
  }
  .ConfirmAppointment.showappointment-mobile .AppointmentRequest .appointment-doctor .doctor-img {
    height: 105px;
  }
  .ConfirmAppointment.showappointment-mobile .appointment-request-detail {
    border-bottom: solid 1px rgba(32, 58, 112, 0.08);
    padding-bottom: 20px;
  }
  .ConfirmAppointment.showappointment-mobile .reserve-appointment {
    display: none;
  }
  .ConfirmAppointment .ReviewConfirm .reserve-appointment {
    display: block;
  }
  .CustomFooter .menu-item-list {
    width: auto;
  }
}

@media (max-width: 413px) {
  .ConfirmAppointment {
    padding-bottom: 148px;
  }
  .ConfirmAppointment .CustomFooter,
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ConfirmAppointment.showappointment-mobile .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading {
    padding-right: 0;
  }
}

.SelectDate {
  position: relative;
}
.SelectDate .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 8;
}
.SelectDate .datepicker .overlay-month-change {
  position: absolute;
  left: 34px;
  top: 31px;
  width: 131px;
  height: 35px;
  z-index: 10;
  opacity: 0.6;
}
.SelectDate .datepicker {
  position: absolute;
  z-index: 9;
  top: 80px;
}
.SelectDate .MyDatetime .rdtPicker {
  overflow: hidden;
}
.SelectDate .MyDatetime.days .rdtPicker .rdtSwitch {
  top: 34px;
}
.SelectDate .MyDatetime.days .rdtPicker .rdtSwitch:after {
  top: 10px;
}
.SelectDate .MyDatetime.days .rdtPicker .dow {
  text-transform: uppercase;
  font-family: 'Calibre Semibold';
}
.SelectDate .MyDatetime.days .rdtPicker td {
  line-height: 1.7;
  padding-top: 5px;
}
.SelectDate .MyDatetime.days .rdtPicker th.rdtNext,
.SelectDate .MyDatetime.days .rdtPicker th.rdtPrev {
  top: 30px;
}
.SelectDate .MyDatetime .rdtPicker .rdtDays table thead tr .dow {
  border-spacing: 20px 5px;
}
.SelectDate .MyDatetime .rdtPicker {
  padding-left: 0;
  padding-right: 0;
}
.SelectDate .Input-control.form-group .view-img {
  left: 18px;
  width: 17px;
  height: 17px;
}
.SelectDate .Input-control.form-group input {
  padding-left: 53px;
}
@media (max-width: 768px) {
  .MyDatetime .rdtPicker ,
  .SelectDate .MyDatetime .rdtPicker {
    width: 360px;
  }
  
  .SelectDate .MyDatetime .rdtPicker .rdtSwitch:after {
    width: 82px;
  }
}
@media (max-width: 413px) {
  .SelectDate .MyDatetime .rdtPicker {
    width: 290px;
  }
  .SelectDate .MyDatetime .rdtPicker .rdtSwitch:after {
    width: 81px;
    left: 128px;
  }
  .SelectDate .MyDatetime .rdtPicker .rdtDays table {
    border-spacing: 18px 10px;
  }
}

.DoctorViewAvailabilityModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 1003px;
  margin: 0 auto;
  max-width: 95%;
}
.DoctorViewAvailabilityModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 57px 0 47px;
}
.DoctorViewAvailabilityModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.DoctorViewAvailabilityModal .modal-content-header .close IMG {
  width: 100%;
}
.DoctorViewAvailabilityModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.DoctorViewAvailabilityModal .modal-content-body {
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorViewAvailabilityModal:last-child {
  border-bottom: solid 0px rgba(1, 54, 72, 0.07);
}
.DoctorViewAvailabilityModal .dc-information {
  margin-left: 40px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.DoctorViewAvailabilityModal .dc-information .dc-content .dc-name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.DoctorViewAvailabilityModal .dc-information .dc-content .dc-specialist {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: normal;
  color: #013648;
}
.DoctorViewAvailabilityModal .dc-information .dc-content .dc-address {
  font-size: 16px;
  line-height: 1.78;
  color: #72787d;
}
.DoctorViewAvailabilityModal .progress-bar-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.DoctorViewAvailabilityModal .progress-bar-wrapper .progress-bar {
  width: 50%;
  padding: 0 9px;
}
.DoctorViewAvailabilityModal .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorViewAvailabilityModal .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 11px;
}
.DoctorViewAvailabilityModal .progress-bar .dc-symptoms {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.4px;
  color: #72787d;
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(1n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(2n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(3n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(4n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(5n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(6n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorViewAvailabilityModal .progress-bar .dc-symptoms-text {
  font-size: 16px;
  line-height: 0.84;
  color: #b2bcc4;
  display: none;
}
.DoctorViewAvailabilityModal .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  position: absolute;
  padding: 0;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorViewAvailabilityModal .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorViewAvailabilityModal .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorViewAvailabilityModal .pt-category-box .box:hover .dc-symptoms-result {
  display: flex;
  z-index: 3;
}
.DoctorViewAvailabilityModal .reviwe-content {
  position: relative;
}
.DoctorViewAvailabilityModal .dc-review {
  min-width: 175px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .star {
  width: 41px;
  position: relative;
  top: 21px;
  left: 5px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .rating {
  font-family: 'Heldane Display';
  font-size: 44px;
  line-height: 0.71;
  letter-spacing: -0.96px;
  color: #013648;
  position: relative;
  top: 4px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .info {
  width: 19px;
  padding-left: 5px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .rating-text {
  width: 34px;
  height: 26px;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #013648;
  position: relative;
  top: 22px;
  right: 16px;
}
.DoctorViewAvailabilityModal .dc-info-content {
  width: 100%;
  display: flex;
  padding: 10px 132px 10px 124px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content > SPAN {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
}
.DoctorViewAvailabilityModal .DC-image {
  width: 101px;
  display: inline-block;
  margin-top: 5px;
}
.DoctorViewAvailabilityModal .DC-image IMG {
  width: 100%;
  min-width: 100px;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
}
.DoctorViewAvailabilityModal .DC-image SPAN,
.DoctorViewAvailabilityModal .DC-image A {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
  margin-bottom: 8px;
}
.DoctorViewAvailabilityModal .DC-image A {
  color: #26acda;
}
.DoctorViewAvailabilityModal .DC-image > SPAN {
  flex-wrap: wrap;
  display: flex;
}
.DoctorViewAvailabilityModal .dc-time-info {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  /* background-color: #fbfbf9; */
  padding: 27px 47px 0;
  display: flex;
}
.DoctorViewAvailabilityModal .dc-time {
  padding-left: 70px;
  width: 100%;
}
.DoctorViewAvailabilityModal .dc-time .dc-address {
  font-size: 16px;
  line-height: 1.22;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-bottom: 12px;
  margin-bottom: 21px;
}
.DoctorViewAvailabilityModal .dc-time .dc-address > div:first-child {
  font-family: 'Calibre Medium';
}
.DoctorViewAvailabilityModal .dc-time-list {
  height: 500px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .day {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 0.9;
  color: #013648;
  margin-bottom: 15px;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time {
  margin-left: -6px;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box {
  width: 72px;
  height: 28px;
  border-radius: 13.8px;
  font-size: 15px;
  line-height: 1.22;
  text-align: center;
  color: #fff;
  display: inline-block;
  line-height: 28px;
  cursor: pointer;
  margin: 0px 6px 10px;
  padding-top: 1px;
  background-color: #013648;
  border: 1px solid #013648;
}
.DoctorViewAvailabilityModal .videomode .dc-time-list .time-list-item .time .time-box {
  background-color: #6a3acd;
  color: #fff;
  border: 1px solid #6a3acd;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box.booked {
  cursor: not-allowed;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box.booked {
  background-color: #013648;
  color: #ffffff;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item {
  border-top: solid 1px rgba(32, 58, 112, 0.08);
  padding-top: 15px;
  /* cursor: pointer; */
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item:first-child {
  border: 0;
}
.DoctorViewAvailabilityModal .appointments-day-block {
  padding-top: 10px;
  width: 407px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
.DoctorViewAvailabilityModal .prev-link,
.DoctorViewAvailabilityModal .right-link {
  cursor: pointer;
}
.DoctorViewAvailabilityModal .prev-link.disabled,
.DoctorViewAvailabilityModal .right-link.disabled {
  cursor: not-allowed;
  opacity: 0.21;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-heading {
  margin-bottom: 27px;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-bottom: 16px;
}
.DoctorViewAvailabilityModal .appointment-block .heading-txt {
  font-size: 20px;
  line-height: 0.79;
  color: #114236;
  font-family: 'Calibre Medium';
  width: calc(100% - 80px);
  padding-right: 10px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;
  position: relative;
  top: 8px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link A {
  display: block;
  line-height: 0;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link .prev-link {
  margin-right: 20px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link IMG {
  width: 30px;
  height: 25px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link .prev-link {
  margin-right: 6px;
}
.DoctorViewAvailabilityModal .day-table .tr {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px -2px;
}
.DoctorViewAvailabilityModal .day-table {
  width: 100%;
  border-spacing: 0;
}
.DoctorViewAvailabilityModal .day-table .td {
  padding: 2px;
  width: 14.28%;
}
.DoctorViewAvailabilityModal .day-table .day.selected {
  background-color: #013648;
  color: #fbfbf9;
}
.DoctorViewAvailabilityModal .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.DoctorViewAvailabilityModal .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.DoctorViewAvailabilityModal .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
  cursor: not-allowed;
}
.DoctorViewAvailabilityModal .dc-review .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DoctorViewAvailabilityModal .dc-review .info-wrapper {
  display: inline-block;
  position: relative;
  top: -9px;
  left: 5px;
  line-height: 0;
}
.DoctorViewAvailabilityModal .DC-image .person-visit,
.DoctorViewAvailabilityModal .DC-image .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 4px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 4px;
  padding-left: 36px;
  margin-bottom: 13px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.DoctorViewAvailabilityModal .DC-image .person-visit IMG,
.DoctorViewAvailabilityModal .DC-image .video-visit IMG {
  max-width: 27px;
  min-width: 27px;
  width: 27px;
  min-height: auto;
  height: auto;
  border: 0;
  position: absolute;
  left: 10px;
  top: 0;
}
.DoctorViewAvailabilityModal .DC-image .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.DoctorViewAvailabilityModal .dc-review .info-wrapper:hover .info-content {
  display: block;
}
.DoctorViewAvailabilityModal .week-time {
  display: none;
}

@media (hover: hover) {
  .DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box:hover {
    background-color: #fff;
    color: #013648;
  }
  .DoctorViewAvailabilityModal .videomode .dc-time-list .time-list-item .time .time-box:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid #013648;
  }
}

@media (max-width: 1024px) {
  .DoctorViewAvailabilityModal .modal-content-header {
    padding: 0 20px 0 30px;
  }
  .DoctorViewAvailabilityModal {
    width: 100%;
  }
  .DoctorViewAvailabilityModal .dc-info-content {
    padding: 10px 30px;
  }
  .DoctorViewAvailabilityModal .dc-time-info {
    padding: 27px 30px 0;
    flex-wrap: wrap;
  }
  .DoctorViewAvailabilityModal .dc-time {
    padding-left: 0;
    margin-top: 50px;
  }
  .DoctorViewAvailabilityModal .dc-time-list {
    height: auto;
  }
  .DoctorViewAvailabilityModal .dc-review {
    position: absolute;
    right: 26px;
    min-width: auto;
    top: 7px;
  }
  .DoctorViewAvailabilityModal {
    min-width: 700px;
  }
  .DoctorViewAvailabilityModal .modal-content-body {
    position: relative;
  }
  .DoctorViewAvailabilityModal .dc-information {
    padding-right: 105px;
  }
}

@media (max-width: 767px) {
  .DoctorViewAvailabilityModal {
    min-width: 90%;
  }
  .DoctorViewAvailabilityModal .dc-information {
    margin-left: 0px;
    padding-right: 0px;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-name {
    font-size: 18px;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .rating {
    font-size: 26px;
    top: 2px;
    left: -3px;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .star {
    width: 24px;
    top: 8px;
    left: 0px;
  }
  .DoctorViewAvailabilityModal .DC-image IMG {
    width: 71px;
    min-width: 71px;
    height: 104px;
  }
  .DoctorViewAvailabilityModal .DC-image {
    width: 71px;
    position: absolute;
  }
  .DoctorViewAvailabilityModal .appointments-day-block {
    width: 100%;
    padding-right: 0;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content {
    width: 100%;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-name,
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-specialist,
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-address {
    margin-left: 90px;
    padding-right: 0;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content .progress-bar-wrapper {
    margin-top: 30px;
  }
  .DoctorViewAvailabilityModal .progress-bar-wrapper .progress-bar {
    width: 100%;
  }
  .DoctorViewAvailabilityModal .dc-information {
    position: relative;
    display: block;
  }
  .DoctorViewAvailabilityModal .reviwe-content {
    display: flex;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .rating-text {
    position: static;
    font-size: 12px;
    color: #3dbce7;
    padding-left: 13px;
    padding-top: 14px;
    display: inline-block;
  }
  .DoctorViewAvailabilityModal .dc-review {
    position: static;
    padding-left: 90px;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content > SPAN {
    text-align: left;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .info {
    display: none;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content > SPAN {
    width: auto;
  }
  .DoctorViewAvailabilityModal .modal-content-header {
    padding: 15px 16px;
    font-size: 16px;
    height: auto;
  }
  .DoctorViewAvailabilityModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .DoctorViewAvailabilityModal .dc-time-info {
    padding: 31px 15px 0;
    border-top: solid 1px rgba(1, 54, 72, 0.1);
  }
  .DoctorViewAvailabilityModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit,
  .DoctorViewAvailabilityModal .DC-image .video-visit {
    font-size: 12px;
    padding-left: 18px;
    width: 70px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit IMG,
  .DoctorViewAvailabilityModal .DC-image .video-visit IMG {
    left: 0px;
    width: 21px;
    min-width: 14px;
    top: 3px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit {
    padding-left: 20px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit IMG {
    width: 24px;
    top: 2px;
    left: -2px;
  }
  .DoctorViewAvailabilityModal {
    min-width: 100%;
    border-radius: 0px;
    border: 0;
  }
  /* .DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box {
    background-color: #013648;
    color:#fff;
  } */
  .DoctorViewAvailabilityModal .modal-content-body {
    max-height: calc(100vh - 0px);
    background-color: #fbfbf9;
  }
  .DoctorViewAvailabilityModal .modal-content-header {
    padding-top: 37px;
    background-color: #fbfbf9;
  }
  .DoctorViewAvailabilityModal .dc-info-content {
    padding: 15px 15px 10px;
    min-height: 162px;
  }
  .DoctorViewAvailabilityModal .DC-image {
    margin-top: 0;
  }
  .DoctorViewAvailabilityModal .progress-bar-wrapper {
    display: none;
  }
  /* .DoctorViewAvailabilityModal .dc-time-info .dc-calender {
    display: none;
  } */
  .DoctorViewAvailabilityModal .dc-time {
    margin-top: 0;
    margin-bottom: 140px;
  }
  .DoctorViewAvailabilityModal .dc-time .dc-address {
    margin-bottom: 0;
  }
  /* .DoctorViewAvailabilityModal .week-time {
    display: flex;
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action {
    display: flex;
    align-items: center;
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action IMG {
    width: 25px;
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action .right-link {
    transform: rotate(180deg);
    margin-left: 30px;
    position: relative;
    top: -7px;
  }
  .DoctorViewAvailabilityModal .week-time .date-title {
    margin: 23px 46px 20px 0;
    font-size: 24px;
    line-height: 0.75;
    color: #013648;
    font-family: 'Calibre Medium';
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action .disable IMG {
    opacity: 0.5;
  } */
}

@media (max-width: 550px) {
  .DoctorViewAvailabilityModal .day-table .day{
    height: 40px;
  }
}


.DoctorItem .DC-image {
  width: 100px;
  min-width: 100px;
  height: 150px;
}
.DoctorItem .DC-image > SPAN:first-child A {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.DoctorItem .DC-image IMG {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.DoctorItem .DC-image .doctor-link IMG {
  width: auto;
  height: 100%;
  border: none;
  padding-right: 5px;
}
.DoctorItem .DC-image > SPAN:first-child {
  width: 100%;
  height: 100%;
}
.DoctorItem .DC-image > SPAN:first-child {
  vertical-align: top;
}
.DoctorItem .DC-image SPAN,
.DoctorItem .DC-image A {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
}
.DoctorItem .DC-image A,
.DoctorItem .DC-image .see-availability {
  color: #26acda;
  cursor: pointer;
  white-space: nowrap;
}
.DoctorItem {
  display: flex;
  padding: 25px 0 20px;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.DoctorItem:last-child {
  border-bottom: solid 0px rgba(1, 54, 72, 0.07);
}
.DoctorItem .DC-image {
  display: inline-block;
  margin-top: 5px;
}
.DoctorItem .dc-information {
  margin-left: 40px;
  padding-bottom: 13px;
}
.DoctorItem .dc-information .dc-content .dc-name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
}
.DoctorItem .dc-information .dc-content .dc-name A {
  color: #013648;
}
.DoctorItem .dc-information .dc-content .dc-specialist {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.2px;
  color: #013648;
}
.DoctorItem .dc-information .dc-content .dc-address {
  font-size: 16px;
  line-height: 1.58;
  color: #587681;
  letter-spacing: -0.18px;
}
.DoctorItem .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorItem .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(1n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(2n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(3n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(4n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(5n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(6n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorItem .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 11px;
}
.DoctorItem .pt-category-box .neck {
  width: 35%;
}
.DoctorItem .pt-category-box .sciatica {
  width: 25%;
  background-color: #e85d72;
}
.DoctorItem .pt-category-box .herniated {
  width: 20%;
  background-color: #8d77ba;
}
.DoctorItem .pt-category-box .scoliosis {
  width: 20%;
  background-color: #f2962d;
}
.DoctorItem .progress-bar .dc-symptoms {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.4px;
  color: #a2a2a2;
}
.DoctorItem .progress-bar .dc-symptoms-text {
  font-size: 16px;
  line-height: 0.84;
  letter-spacing: -0.2px;
  color: #72787d;
  margin-top: 5px;
}
.DoctorItem .reviwe-content {
  position: relative;
}
.DoctorItem .dc-review {
  padding-right: 6px;
  min-width: 175px;
}
.DoctorItem .dc-review .reviwe-content .star {
  width: 41px;
  position: relative;
  top: 18px;
  left: 9px;
}
.DoctorItem .dc-review .reviwe-content .rating {
  font-family: 'Heldane Display';
  font-size: 44px;
  line-height: 0.71;
  letter-spacing: -0.96px;
  color: #013648;
  position: relative;
  top: 2px;
}
.DoctorItem .dc-review .reviwe-content .rating.grey {
  color: rgb(153, 153, 153);
}
.DoctorItem .dc-review .reviwe-content .info {
  width: 19px;
  padding-left: 5px;
}
.DoctorItem .dc-review .reviwe-content .social-icon {
  width: 19px;
  margin-top: 25px;
  margin-left: 7px;
  position: relative;
  right: 17px;
}
.DoctorItem .dc-review .reviwe-content .rating-text {
  width: 34px;
  height: 26px;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #013648;
  position: relative;
  top: 6px;
  right: 16px;
}
.DoctorItem .dc-info-content .dc-information {
  display: flex;
  justify-content: space-between;
}
.DoctorItem .dc-info-content {
  width: calc(100% - 101px);
  padding-right: 60px;
}
.DoctorItem .dc-review .reviwe-content > SPAN {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
}
.DoctorItem .dc-appointment-time {
  padding-top: 14px;
  margin-left: 40px;
  border-top: solid 2px rgba(1, 54, 72, 0.1);
}
.DoctorItem .timing-table {
  width: 508px;
  padding: 0px 29px 0px 44px;
}
.DoctorItem .table-wrapper {
  position: relative;
  display: inline-block;
}
.DoctorItem .table-wrapper .prev-link {
  position: absolute;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
.DoctorItem .table-wrapper .right-link {
  position: absolute;
  right: 0px;
  top: -4px;
  transform: rotate(180deg);
  cursor: pointer;
}
.DoctorItem .table-wrapper .prev-link IMG,
.DoctorItem .table-wrapper .right-link IMG {
  width: 24px;
}
.DoctorItem .table-wrapper .prev-link.disabled IMG,
.DoctorItem .table-wrapper .right-link.disabled IMG {
  opacity: 0.21;
  cursor: not-allowed;
}
.DoctorItem .timing-table .tr {
  display: flex;
  margin-right: 15px;
}
.DoctorItem .timing-table .th {
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: center;
  width: 83px;
}
.DoctorItem .timing-table .th .date {
  display: block;
  font-family: 'Calibre Regular';
  font-weight: normal;
  text-align: center;
}
.DoctorItem .timing-table .tr .th:last-child {
  padding-right: 0;
}
.DoctorItem .timing-table .td {
  padding: 0px 5px 5px;
}
.DoctorItem .timing-table .tr .td:last-child {
  padding-right: 0;
}
.DoctorItem .timing-table .tr:last-child .td {
  padding-bottom: 0;
}
.DoctorItem .timing-table .td .time {
  width: 73px;
  font-size: 14px;
  line-height: 1.31;
  letter-spacing: normal;
  border-radius: 13.8px;
  padding: 6px 3px 2px;
  text-align: center;
  vertical-align: middle;
  border: 0.7px solid rgba(1, 54, 72, 0.1);
  color: #013648;
}
.DoctorItem .timing-table .td.available .time {
  color: #fff;
  cursor: pointer;
  background-color: #013648;
  border: 1px solid #013648;
  font-size: 14px;
}
.DoctorItem.isVirtual .timing-table .td.available .time {
  color: #fff;
  background-color: #6a3acd;
  border: 1px solid #6a3acd;
}
.DoctorItem .timing-table .td.booked .time {
  background-color: #013648;
  color: #ffffff;
  cursor: not-allowed;
}
.DoctorItem .day-table {
  width: 100%;
  border-spacing: 0;
}
.DoctorItem .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DoctorItem .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.DoctorItem .day-table .tr .td:last-child {
  padding-right: 0;
}
.DoctorItem .day-table .tr:last-child .td {
  padding-bottom: 0;
}
.DoctorItem .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.DoctorItem .appointment-inner-block {
  margin-bottom: 46px;
}
.DoctorItem .appointments-day-block {
  padding-top: 10px;
}
.DoctorItem .appointment-inner-block:last-child {
  margin-bottom: 0;
}
.DoctorItem .time-container {
  max-height: 95px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorItem .time-container.show-more {
  max-height: 95px;
  overflow: auto;
}
.timing-table .more-avaliable {
  float: left;
  margin-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: #3d93a6;
  display: none;
}
.DoctorItem .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.DoctorItem .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.DoctorItem .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorItem .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorItem .DC-image .dc-profile-link .MyLink,
.DoctorItem .DC-image .dc-profile-link .see-availability {
  margin-top: 6px;
}
.DoctorItem .dc-profile-link {
  display: none;
}
.DoctorItem .dc-action > SPAN,
.DoctorItem .dc-action > SPAN:first-child {
  display: inline-block;
  width: 20px;
  margin-right: 9px;
}
.DoctorItem .dc-action > SPAN IMG {
  width: 100%;
  border: 0;
}
.DoctorItem .dc-review .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DoctorItem .dc-review .info-wrapper {
  display: inline-block;
  position: relative;
  top: -12px;
  left: 5px;
  line-height: 0;
}
.DoctorItem .progress-bar-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.DoctorItem .progress-bar-wrapper .progress-bar {
  width: 50%;
  padding: 0 14px;
}
/* .DoctorItem .dc-content {
  flex: 1;
} */
.DoctorItem .DC-image .person-visit,
.DoctorItem .DC-image .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 4px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 4px;
  padding-left: 36px;
  margin-bottom: 13px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.DoctorItem .DC-image .person-visit IMG,
.DoctorItem .DC-image .video-visit IMG {
  width: 20px;
  border: 0;
  position: absolute;
  left: 10px;
  top: 0;
}
.DoctorItem .DC-image .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.DoctorItem .pt-category-box .box:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.DoctorItem .dc-profile-link span {
  font-size: 15px;
  line-height: 1.68;
  letter-spacing: -0.28px;
  color: rgba(1, 54, 72, 0.5);
}
.DoctorItem .dc-profile-link A,
.DoctorItem .dc-profile-link .see-availability {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  cursor: pointer;
  display: inline-block;
}
.DoctorItem .dc-sm-distance {
  /* display: none; */
  font-size: 15px;
  line-height: 1.68;
  letter-spacing: -0.28px;
  color: #587681;
}

@media (hover: hover) {
  .DoctorItem .timing-table .td.available .time:hover {
    background-color: rgba(1, 54, 72, 0.1);
    color: #013648;
    border: 1px solid #013648;
  }
  .DoctorItem.isVirtual .timing-table .td.available .time:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #013648;
  }
  .DoctorItem .dc-review .info-wrapper:hover .info-content {
    display: block;
  }
}

@media (max-width: 1200px) {
  .DoctorItem .dc-appointment-time,
  .DoctorItem .dc-info-content .dc-information {
    margin-left: 15px;
  }
  .DoctorItem .dc-review {
    padding-right: 0;
  }
  .DoctorItem .timing-table .td .time {
    width: 65px;
  }
  .DoctorItem .timing-table .th {
    width: 75px;
  }
  .DoctorItem .timing-table {
    width: 470px;
  }
}

@media (max-width: 1024px) {
  .DoctorItem .progress-bar-wrapper {
    margin: 0;
    flex-direction: column;
  }
  .DoctorItem .progress-bar-wrapper .progress-bar {
    width: 100%;
    padding: 0;
  }
  .DoctorItem .pt-category-box {
    width: 230px;
  }
  .DoctorItem .dc-information .dc-content .dc-address {
    color: #587681;
  }
  .DoctorItem .dc-info-content .dc-information {
    flex-wrap: wrap;
  }
  .DoctorItem .DC-image .dc-profile-link SPAN,
  .DoctorItem .DC-image .dc-profile-link A {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.34px;
    color: rgba(1, 54, 72, 0.5);
    margin-bottom: 8px;
  }
  .DoctorItem .DC-image .dc-profile-link A,
  .DoctorItem .DC-image .dc-profile-link .see-availability {
    color: #3dbce7;
    cursor: pointer;
  }
  .DoctorItem .DC-image .dc-profile-link A {
    margin: 0px 16px;
  }
  .DoctorItem .DC-image .dc-profile-link {
    display: none;
  }
  .DoctorItem .dc-review {
    width: 100%;
  }
  .DoctorItem {
    position: relative;
  }
  .DoctorItem .DC-image {
    position: absolute;
    left: 0;
    top: 20px;
  }
  .DoctorItem .dc-content {
    padding-left: 115px;
  }
  .DoctorItem .dc-info-content {
    width: 100%;
  }
  .DoctorItem .dc-appointment-time,
  .DoctorItem .dc-info-content .dc-information {
    margin-left: 0;
  }
  .DoctorItem .timing-table {
    padding: 0px 29px 0px 30px;
  }
  .DoctorItem .timing-table {
    width: 450px;
  }
  .DoctorItem .dc-content {
    padding-left: 115px;
  }
  .DoctorItem .dc-profile-link {
    margin-top: 19px;
    display: block;
  }
  .DoctorItem .dc-review .reviwe-content .social-icon,
  .DoctorItem .dc-review .reviwe-content .info {
    display: none;
  }
  .DoctorItem .dc-review .reviwe-content .rating-text {
    color: #203a70;
    right: 0px;
  }
  .DoctorItem .dc-review .reviwe-content .rating {
    font-size: 38px;
  }
  .DoctorItem .dc-profile-link A {
    margin: 0 30px;
  }
  .DoctorItem .dc-profile-link A:first-child {
    margin-left: 0;
  }
  .DoctorItem .dc-sm-distance {
    display: block;
  }
}

@media (max-width: 767px) {
  .DoctorItem .pt-category-box {
    width: 100%;
  }
  .DoctorItem .table-content {
    overflow-x: auto;
    margin-right: -25px;
  }
  .DoctorItem .table-wrapper {
    width: 100%;
  }
  .DoctorItem .timing-table {
    padding: 0px;
  }
  .DoctorItem .table-wrapper .prev-link,
  .DoctorItem .table-wrapper .right-link {
    display: none;
  }
  .DoctorItem .DC-image {
    width: 71px;
    min-width: 71px;
    height: 104px;
  }
  .DoctorItem .dc-content {
    padding-left: 90px;
    padding-top: 0px;
    min-height: 206px;
  }
  .DoctorItem .dc-information .dc-content .dc-name {
    font-size: 20px;
  }
  .DoctorItem .dc-information .progress-bar {
    width: 100%;
  }
  .DoctorItem .dc-info-content .dc-information {
    position: relative;
  }
  .DoctorItem .dc-review {
    position: absolute;
    top: 159px;
    right: calc(100% - 74px);
    width: auto;
    min-width: auto;
  }
  .DoctorItem .dc-review .reviwe-content .rating {
    top: -13px;
    left: -6px;
    font-size: 26px;
  }
  .DoctorItem .dc-review .reviwe-content .rating-text {
    right: 5px;
    top: -15px;
    font-size: 12px;
    font-family: 'Calibre Medium';
    color: #3dbce7;
  }
  .DoctorItem .dc-information {
    padding-bottom: 22px;
  }
  .DoctorItem .dc-review .reviwe-content .star {
    top: -2px;
    left: -3px;
    width: 24px;
    height: 31px;
  }
  .DoctorItem .timing-table .th .date {
    font-family: 'Calibre Medium';
    font-weight: 500;
    display: inline-block;
    width: 100%;
  }
  .DoctorItem .dc-appointment-time {
    padding-top: 27px;
  }
  .DoctorItem .timing-table .th {
    padding-bottom: 16px;
    width: 84px;
  }
  .DoctorItem .timing-table .td .time {
    width: 75px;
  }
  .DoctorItem .time-container {
    overflow-y: visible;
    height: auto;
  }
  .DoctorItem .dc-profile-link {
    display: flex;
  }
  .DoctorItem .dc-profile-link A {
    margin: 0px 10px;
  }
  .DoctorItem.rating-content-show .dc-info-content .dc-information {
    padding-bottom: 80px;
  }
  .DoctorItem .DC-image .person-visit,
  .DoctorItem .DC-image .video-visit {
    font-size: 12px;
    padding-left: 20px;
    width: 72px;
  }
  .DoctorItem .DC-image .person-visit IMG,
  .DoctorItem .DC-image .video-visit IMG {
    left: 0;
    object-fit: contain;
  }
  .DoctorItem .DC-image .video-visit {
    padding-left: 18px;
  }
  .DoctorItem {
    padding-right: 15px;
  }
  .DoctorItem .timing-table .td .time {
    width: 68px;
  }
  .DoctorItem .timing-table .th {
    width: 76px;
  }
  .DoctorItem .timing-table .td {
    padding: 0px 4px 5px;
  }
  /* .DoctorItem .timing-table .td.available .time {
    background-color: #013648;
    color:#fff;
  } */
  /* .DoctorItem .dc-information .dc-content .dc-info-inner-content {
    min-height: 120px;
  } */
  .DoctorItem .timing-table {
    width: 390px;
  }
  .DoctorItem .dc-info-content {
    padding-right: 0;
  }
  .timing-table .more-avaliable {
    display: block;
  }
  .DoctorItem .time-container {
    overflow: hidden;
  }
  .DoctorItem .time-container.show-more {
    max-height: 100% !important;
    /* overflow: auto; */
  }
}

@media (max-width: 413px) {
  .DoctorItem .dc-profile-link SPAN {
    font-size: 14px;
  }
  .DoctorItem .dc-profile-link A,
  .DoctorItem .dc-profile-link .see-availability {
    font-size: 14px;
  }
  .DoctorItem .dc-content .dc-profile-link A IMG,
  .DoctorItem .dc-content .dc-profile-link .see-availability IMG {
    margin-right: 3px;
  }
  .DoctorItem .dc-content .dc-profile-link A {
    margin: 0px 6px 0 0;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .DoctorItem {
    border-top: solid 1px rgba(1, 54, 72, 0.07);
  }
}

.ElasticSearch .active .Input-control.form-group {
  z-index: 1;
}
.ElasticSearch .Input-control.form-group input {
  padding-left: 60px;
  height: 55px;
}
.ElasticSearch .Input-control.form-group input:focus {
  border: solid 1.5px #013648;
}
.ElasticSearch .Input-control .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
}
.ElasticSearch .search {
  position: relative;
}
.ElasticSearch .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.ElasticSearch .search .search-result {
  padding: 12px 0px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  position: absolute;
  min-width: 300px;
  width: 100%;
  overflow-x: auto;
  top: 54px;
  z-index: 99;
  display: none;
  max-height: 400px;
}
.ElasticSearch .search.active .search-result {
  display: block;
}
.ElasticSearch .search-result .search-category {
  margin-bottom: 20px;
}
.ElasticSearch .search-result .search-category:last-child {
  margin-bottom: 0;
}
.ElasticSearch .search-result .search-category .h3 {
  opacity: 0.5;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #18222a;
  padding: 0px 18px;
}
.ElasticSearch .search-result .search-category span {
  display: inline-block;
  width: 97%;
}
.ElasticSearch .search-result .search-category .search-item {
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #013648;
  padding: 0px 18px;
  cursor: pointer;
}
.ElasticSearch .search-result .search-category .doctor-item b,
.ElasticSearch .search-result .search-category .search-item b {
  color: #46a6c7;
  font-weight: 500;
}
.ElasticSearch .doctor-item {
  padding-left: 75px;
  position: relative;
  font-size: 20px;
  line-height: 1.05;
  color: #013648;
  display: inline-block;
  margin-bottom: 20px;
  min-height: 48px;
  cursor: pointer;
}
.ElasticSearch .doctor-item img {
  width: 40px;
  min-width: 40px;
  height: 48px;
  border-radius: 2px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  position: absolute;
  left: 19px;
  object-fit: cover;
}
.ElasticSearch .doctor-item span:last-child {
  font-size: 18px;
  color: #72787d;
}
.ElasticSearch .search-category.doctor {
  position: relative;
  padding-top: 30px;
}
.ElasticSearch .search-category.doctor:after {
  content: '';
  position: absolute;
  left: 19px;
  right: 19px;
  top: 0;
  height: 1px;
  background-color: rgba(151, 151, 151, 0.24);
}
.ElasticSearch .Input-control.form-group input.form-control::placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
}
.ElasticSearch .Input-control.form-group input.form-control:-moz-placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
}
.ElasticSearch .search-result .loader {
  text-align: center;
}
.ElasticSearch .search-result .loader img {
  margin-top: 15px;
  height: 30px;
  transform: translateZ(0);
  animation: circleanimation 2.5s infinite linear;
}
@media (hover: hover) {
  .ElasticSearch .search-result .search-category .search-item:hover {
    /* background-color: #eaf4eb; */
    color: #46a6c7;
  }
}
@media (max-width: 767px) {
  .ElasticSearch .search .search-result {
    min-width: auto;
    margin: 0;
    text-align: left;
  }
  .ElasticSearch .Input-control.form-group input.form-control::placeholder {
    font-size: 18px;
  }
  .ElasticSearch .Input-control.form-group input.form-control:-moz-placeholder {
    font-size: 18px;
  }
}

.Gender {
  position: relative;
  display: inline-block;
}
.Gender .dropdown-menu {
  position: absolute;
  top: 50px;
  box-shadow: 0 0 14px 0 #d8d3d3;
  left: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: all 0.3s linear;
  background-color: #fff;
  min-width: 230px;
  z-index: 20;
  padding: 10px 0 10px;
}
.Gender .dropdown-menu.show-menu {
  visibility: visible;
  opacity: 1;
}
.Gender .dropdown-menu .dropdown-option {
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #013648;
  font-family: 'Calibre Medium';
}
.Gender .dropdown-menu .dropdown-option .count {
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.6);
}
.Gender .dropdown-menu .Checkbox-control {
  margin: 0;
}
.Gender .dropdown-menu .dropdown-bottom {
  padding: 8px 20px;
  border-top: 1px solid #dbdbdb;
  text-align: right;
  padding: 8px 20px 0;
}
.Gender .dropdown-menu .CircleButton.WhiteButton {
  margin-right: 0;
  color: #013648;
  line-height: 32px;
}
.Gender.selected > .CircleButton.SecondaryButton {
  background-color: #013648;
  color: #ffffff;
}
.Gender .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .Gender .dropdown-menu {
    min-width: 190px;
  }
  .Gender .dropdown-menu .CircleButton.WhiteButton {
    color: #fff;
  }
}

@media (hover: hover) {
  .Gender .dropdown-menu .CircleButton.WhiteButton:hover {
    color: #fff;
  }
}
.SortBy {
  position: relative;
  display: inline-block;
}
.SortBy .dropdown-menu {
  position: absolute;
  top: 50px;
  box-shadow: 0 0 14px 0 #d8d3d3;
  left: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: all 0.3s linear;
  background-color: #fff;
  min-width: 230px;
  z-index: 20;
  padding: 10px 0 10px;
}
.SortBy .dropdown-menu.show-menu {
  visibility: visible;
  opacity: 1;
  right: 8px;
  left: auto;
}
.SortBy .dropdown-menu .dropdown-option {
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #013648;
  font-family: 'Calibre Medium';
}
.SortBy .dropdown-menu .dropdown-option .count {
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.6);
}
.SortBy .dropdown-menu .Checkbox-control {
  margin: 0;
}
.SortBy .dropdown-menu .dropdown-bottom {
  padding: 8px 20px;
  border-top: 1px solid #dbdbdb;
  text-align: right;
  padding: 8px 20px 0;
}
.SortBy .dropdown-menu .CircleButton.WhiteButton {
  margin-right: 0;
  color: #013648;
  line-height: 32px;
}
.SortBy.selected > .CircleButton.SecondaryButton {
  background-color: #013648;
  color: #ffffff;
}
.SortBy .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .SortBy .dropdown-menu {
    min-width: 190px;
  }
  .SortBy .dropdown-menu .CircleButton.WhiteButton {
    color: #fff;
  }
}

@media (hover: hover) {
  .Gender .dropdown-menu .CircleButton.WhiteButton:hover {
    color: #fff;
  }
}

.Availability {
  position: relative;
  display: inline-block;
}
.Availability .dropdown-menu {
  position: absolute;
  top: 50px;
  box-shadow: 0 0 14px 0 #d8d3d3;
  left: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: all 0.3s linear;
  background-color: #fff;
  min-width: 230px;
  z-index: 20;
  padding: 10px 0 10px;
}
.Availability .dropdown-menu.show-menu {
  visibility: visible;
  opacity: 1;
}
.Availability .dropdown-menu .dropdown-option {
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #013648;
  font-family: 'Calibre Medium';
}
.Availability .dropdown-menu .dropdown-option .count {
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.6);
}
.Availability .dropdown-menu .Checkbox-control {
  margin: 0;
}
.Availability .dropdown-menu .dropdown-bottom {
  padding: 8px 20px;
  border-top: 1px solid #dbdbdb;
  text-align: right;
  padding: 8px 20px 0;
}
.Availability .dropdown-menu .CircleButton.WhiteButton {
  margin-right: 0;
  color: #013648;
  line-height: 32px;
}
.Availability.selected > .CircleButton.SecondaryButton {
  background-color: #013648;
  color: #ffffff;
}
.Availability .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (hover: hover) {
  .Availability .dropdown-menu .CircleButton.WhiteButton:hover {
    color: #fff;
  }
}
@media (max-width: 767px) {
  .Availability .dropdown-menu .CircleButton.WhiteButton {
    color: #fff;
  }
}
.InsuranceFilterSelect {
  position: relative;
}
.InsuranceFilterSelect.active .Input-control.form-group {
  z-index: 1;
}
.InsuranceFilterSelect .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.InsuranceFilterSelect .selected-search-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.InsuranceFilterSelect .Input-control.form-group .form-control:focus {
  border: solid 1px #013648;
}
.InsuranceFilterSelect .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.InsuranceFilterSelect .result {
  width: 100%;
  min-width: 290px;
  max-height: 402px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 52px;
  padding: 18px 32px;
  z-index: 8;
}
.InsuranceFilterSelect .result .option.company {
  opacity: 0.5;
}
.InsuranceFilterSelect .result .option {
  font-size: 20px;
  line-height: 1.26;
  color: #013648;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.InsuranceFilterSelect .result .option.plan {
  cursor: pointer;
}
.InsuranceFilterSelect .Input-control.form-group .view-img {
  position: absolute;
  left: 18px;
  top: calc(100% - 1.9rem);
  width: 20px;
  height: 20px;
}
.InsuranceFilterSelect input {
  padding-left: 52px;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control {
  opacity: 1;
  font-size: 20px;
  line-height: 1.64;
  letter-spacing: -0.38px;
  color: #013648;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control {
  padding-left: 52px;
  color: #013648;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control::placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control:-moz-placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
}
.InsuranceFilterSelect .result .loader {
  text-align: center;
}
.InsuranceFilterSelect .result .loader img {
  margin-top: 15px;
  height: 30px;
  transform: translateZ(0);
  animation: circleanimation 2.5s infinite linear;
}
.InsuranceFilterSelect .backdivclass{
/* background-color: #023648;
position: relative;
padding: 5px;
 */
 margin-bottom: 10px;
}

.InsuranceFilterSelect .backdivclass span{
  color: #0000ED;
  cursor: pointer;
/* color: #ffffff;
    margin-top: 5px;
    position: absolute;
    margin-left: 5px;
    font-size: 17px; */
}
.InsuranceFilterSelect .selectedCompany-text {
  display: block;
  /* grid-template-columns: 124px 1fr; */
  margin-bottom: 10px;
  margin-top: 5px;
  color:#023648
}
.InsuranceFilterSelect .selectedCompany-text span{
  font-size: 16px;
  color:#023648;

}
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT{
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  padding-left: 5px !important;
  margin-bottom: 20px;
  max-height: 35px;
  padding-top: 3px !important;
}
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT, 
.InsuranceFilterSelect .Input-control.form-group.plansearch .form-control:not(:placeholder-shown),
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT:focus,
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT:hover{
  border: solid 1.5px rgba(40, 84, 99, 0.2) !important;
  padding-left: 5px !important;
  padding-top: 3px !important;
}
@media (hover: hover) {
  .InsuranceFilterSelect .result .option.plan:hover {
    color: #3dbce7;
  }
}

@media (max-width: 767px) {
  .InsuranceFilterSelect .Input-control.form-group input.form-control::placeholder {
    font-size: 18px;
  }
  .InsuranceFilterSelect .Input-control.form-group input.form-control:-moz-placeholder {
    font-size: 18px;
  }
}

.InfiniteScroll {
  height: 100vh;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}
.InfiniteScroll .infinite-scroll-loader {
  text-align: center;
}
.InfiniteScroll .infinite-scroll-loader img {
  margin: 15px;
  height: 45px;
  transform: translateZ(0);
  animation: circleanimation 2.5s infinite linear;
}

.DoctorListing .filter .Input-control .view-img {
  left: 18px;
  height: 17px;
  top: 25px;
}
.DoctorListing {
  height: 100vh;
}
.DoctorListing .InfiniteScroll {
  height: calc(100vh - 100px);
  overflow: hidden;
}
.DoctorListing.sticky-header .InfiniteScroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorListing .filter .Input-control INPUT {
  font-family: 'Calibre Regular';
}
.DoctorListing .filter .Input-control.zipcode INPUT {
  padding-top: 10px;
  -webkit-appearance: none;
}
.DoctorListing .filter .Input-control.transform-input.zipcode .form-control:focus,
.DoctorListing .filter .Input-control.transform-input.zipcode .form-control:not(:placeholder-shown),
.DoctorListing .filter .Input-control.transform-input.filled-focus-input.zipcode .form-control {
  padding-top: 8px;
}
.DoctorListing .filter .ElasticSearch {
  min-width: 426px;
  margin-right: 4px;
}
.DoctorListing .filter .ElasticSearch .Input-control.form-group INPUT:focus {
  border: solid 1px #013648;
}
.DoctorListing .filter {
  background-color: #013648;
  display: flex;
  padding: 5px 60px 25px;
}
.DoctorListing .filter .Input-control INPUT {
  padding-left: 53px;
  border: solid 1px rgba(1, 54, 72, 0.1);
  border-radius: 4px;
}
.DoctorListing .filter .Input-control .label {
  display: none;
}
.DoctorListing .filter .Input-control {
  margin: 0 2px;
  flex: 1 1;
}
.DoctorListing .filter .ElasticSearch .Input-control {
  margin-left: 0;
}
.DoctorListing .filter .ElasticSearch .Input-control.form-group INPUT,
.DoctorListing .filter .Input-control INPUT {
  height: 49px;
}
.DoctorListing .filter .Input-control.form-group INPUT {
  padding-top: 5px;
}
.DoctorListing .filter .Input-control.transform-input .form-control:focus,
.DoctorListing .filter .Input-control.transform-input .form-control:not(:placeholder-shown),
.DoctorListing .filter .Input-control.transform-input.filled-focus-input .form-control {
  padding: 5px 0px 5px 53px;
}
.DoctorListing .filter .SelectDate {
  margin-right: 4px;
  flex: 1 1;
  border: 0;
}
.DoctorListing .filter .SelectDate .form-control {
  border: solid 1px rgba(1, 54, 72, 0.1);
}
.DoctorListing .search-result {
  padding: 10px 60px 10px;
  display: flex;
  align-items: center;
  min-height: 82px;
}
.DoctorListing .search-result .search-text {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 60%;
  padding-right: 15px;
}
.DoctorListing .search-result .search-text .search-item {
  color: #b2bcc4;
}
.DoctorListing .search-result .search-text .search-item > SPAN {
  padding-right: 5px;
}
.DoctorListing .search-result .search-text .search-item > SPAN:not(:first-child) {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}
.DoctorListing .search-result .search-text .search-item > SPAN:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  left: 0px;
  top: 11px;
  background-color: #b2bcc4;
}
.DoctorListing .search-action .CircleButton {
  height: 30px;
  border-radius: 15.1px;
  border: solid 1px #013648;
  line-height: 32px;
  display: inline-block;
  width: auto;
  padding: 0px 18px;
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
  margin-right: 11px;
}
.DoctorListing .search-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.DoctorListing .search-action BUTTON {
  z-index: 1;
  position: relative;
}
.DoctorListing .search-action .SwitchToggle {
  display: inline-flex;
  align-items: center;
  margin-bottom: auto;
  padding-left: 0;
}
.DoctorListing .SwitchToggle .switch-label {
  order: 1;
  margin-right: 12px;
}
.DoctorListing .SwitchToggle.switchImage .switchContainer {
  position: relative;
  order: 2;
  margin-right: 9px;
  top: auto;
}
.DoctorListing .doctor-list-content {
  background-color: #fbfbf9;
}
.DoctorListing .doctor-list-content {
  padding: 0 27;
}
.DoctorListing .doctor-list-wrapper {
  display: flex;
}
.DoctorListing .doctor-list-wrapper .doctor-list-content {
  width: 59%;
  padding-left: 60px;
  padding-right: 27px;
}
.DoctorListing .doctor-list-wrapper .map {
  width: 41%;
  height: calc(100vh - 100px);
  transform: translateZ(0px);
  position: relative;
}
.DoctorListing.sticky-header .doctor-list-content {
  width: 59%;
  height: calc(100vh - 100px);
  transform: translateZ(0px);
  position: relative;
}
.DoctorListing.sticky-header .doctor-list-content.full-map {
  position: fixed;
  top: 101px;
  bottom: 0;
  left: 0;
}
.DoctorListing .doctor-list-wrapper .map.full-map {
  position: fixed;
  top: 101px;
  bottom: 0;
  right: 0;
}
.DoctorListing .doctor-list-wrapper .map .MyGoogleMap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}
.DoctorListing .mapouter {
  position: relative;
  text-align: right;
  height: 100%;
  width: 100%;
}
.DoctorListing .gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}
.DoctorListing .CustomFooter {
  display: none;
}
.DoctorListing .provider-not-available {
  border-radius: 2px;
  background-color: #f1f1f1;
  padding: 25px;
  margin-top: 40px;
}
.DoctorListing .provider-not-available .h3 {
  font-family: 'Heldane Display';
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.1px;
  color: #013648;
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
}
.DoctorListing .provider-not-available .h3 IMG {
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: none;
}
.DoctorListing .provider-not-available .message {
  font-size: 18px;
  line-height: 1.17;
  letter-spacing: -0.34px;
  color: #013648;
  max-width: 507px;
}
.DoctorListing .provider-not-available .message A {
  color: #26acda;
}
.DoctorListing .search-btn {
  width: 93px;
  height: 49px;
  border-radius: 4px;
  border: 1px solid #3d93a6;
  cursor: pointer;
  margin-left: 6px;
  vertical-align: top;
  background-color: #3d93a6;
}
.DoctorListing .search-btn .mg-icon {
  display: block;
  margin: auto;
}
.DoctorListing .search-btn .mghover-icon {
  display: none;
}
.DoctorListing .search-btn:focus {
  outline: 0;
}
.DoctorListing .InsuranceFilterSelect .result {
  min-width: auto;
  width: 100%;
}
/*Sticky Header*/
/* .DoctorListing.sticky-header {
  padding-top: 101px;
} */
.DoctorListing .filter.sticky-filter {
  position: fixed;
  top: 0px;
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  left: 0;
  right: 0;
}
.DoctorListing.sticky-header .filter.sticky-filter {
  visibility: visible;
  opacity: 1;
}
.DoctorListing .filter .logo {
  width: 160px;
  margin-right: 30px;
  padding-top: 3px;
  display: none;
}
.DoctorListing .filter .logo IMG {
  width: 100%;
}
.DoctorListing .filter.sticky-filter .logo {
  display: block;
}
.DoctorListing .filter.sticky-filter .search-btn {
  width: 56px;
}
.DoctorListing .filter.sticky-filter .ElasticSearch {
  min-width: 280px;
}
.DoctorListing .filter.sticky-filter {
  padding: 30px 60px 19px;
}
.DoctorListing .map-overlay {
  display: none;
}
.DoctorListing .filter .CircleButton.WhiteButton {
  display: none;
}
.DoctorListing .search-warp {
  display: none;
}
.DoctorListing .search-btn SPAN {
  display: none;
}
.DoctorListing .filter .Input-control.form-group INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control.form-group INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control.form-group INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control.form-group INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .filter .Input-control INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.DoctorListing .Gender .dropdown-menu,
.DoctorListing .Language .dropdown-menu,
.DoctorListing .Availability .dropdown-menu {
  left: auto;
  right: 10px;
}
.DoctorListing .tab-menus {
  display: flex;
}
.DoctorListing .tab-menus SPAN {
  font-size: 20px;
  line-height: 1.01;
  letter-spacing: -0.38px;
  text-align: center;
  color: #013648;
  position: relative;
  cursor: pointer;
}
.DoctorListing .tab-menus SPAN.active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  height: 3px;
  background-color: #013648;
}
.DoctorListing .tab-menus SPAN:not(:first-child) {
  margin-left: 35px;
}
.DoctorListing .search-btn[disabled] {
  opacity: 0.5;
  color: #d8d8d8;
  cursor: not-allowed;
}
.DoctorListing .doctor-list-content .DoctorItem:first-child {
  border-top: 0;
}
.DoctorListing .DoctorItem .pt-category-box {
  margin-top: 25px;
  margin-bottom: 7px;
}
.DoctorListing .DoctorItem .progress-bar .dc-symptoms-text {
  margin-top: 0px;
  display: none;
}
.DoctorListing .DoctorItem .dc-information {
  padding-bottom: 19px;
}
@media (hover: hover) {
  .DoctorListing .search-action .CircleButton:hover {
    color: #fff;
  }
  .DoctorListing .filter.show-filter .CircleButton.WhiteButton:hover {
    border: 1px solid #72787d;
  }
  .DoctorListing .search-btn:enabled:hover IMG {
    display: none;
  }
  .DoctorListing .search-btn:enabled:hover .mghover-icon {
    display: block;
    margin: 0 auto;
  }
  .DoctorListing .search-btn:enabled:hover {
    background-color: rgba(61,147,166,0.8);
  }
}

@media (max-width: 1366px) {
  .DoctorListing .doctor-list-wrapper .map {
    width: 35%;
  }
  .DoctorListing .doctor-list-wrapper .doctor-list-content {
    width: 65%;
  }
  .DoctorListing.sticky-header .filter .ElasticSearch {
    min-width: 280px;
  }
  .DoctorListing .filter .ElasticSearch {
    min-width: 322px;
  }
  .DoctorListing .InfiniteScroll {
    padding-right: 0px;
  }
}

@media (max-width: 1280px) {
  .DoctorListing .search-action .CircleButton {
    font-size: 15px;
    margin-right: 5px;
    padding: 0 10px;
  }
}

@media (max-width: 1200px) {
  .DoctorListing.sticky-header .filter {
    padding: 30px 30px 19px;
  }
  .DoctorListing .filter {
    padding: 15px 30px;
  }
  .DoctorListing .search-result {
    padding: 16px 30px 12px;
  }
  .DoctorListing .doctor-list-wrapper .doctor-list-content {
    padding-left: 30px;
  }
  .DoctorListing .search-btn {
    width: 48px;
  }
  .DoctorListing .filter .Input-control INPUT {
    padding-left: 42px;
    padding-right: 15px;
  }
  .DoctorListing .filter .Input-control .view-img {
    left: 12px;
  }
  .DoctorListing .search-result .search-text {
    width: 66%;
  }
  .DoctorListing.sticky-header .filter .logo {
    display: flex;
    width: 120px;
    margin-right: 0;
  }
  .DoctorListing.sticky-header .filter .logo IMG {
    margin: auto;
  }
  .DoctorListing.sticky-header .filter .search-btn {
    width: 44px;
  }
  .DoctorListing.sticky-header .filter .ElasticSearch {
    min-width: 233px;
  }
  .DoctorListing .filter .ElasticSearch {
    min-width: 300px;
  }
  .DoctorListing .chooseinsurance {
    width: 178px;
  }
  .DoctorListing .InsuranceFilterSelect .Input-control.form-group input.form-control {
    padding-left: 42px;
  }
}

@media (max-width: 1024px) {
  .DoctorListing .search-result .search-text {
    width: 59%;
  }
  .DoctorListing .search-action .CircleButton {
    padding: 0 10px;
    margin-right: 4px;
    font-size: 14px
  }
  .DoctorListing .search-action .SortBy > .CircleButton {
    margin-right: 0;
  }
  .DoctorListing .tab-menus SPAN:not(:first-child) {
    margin-left: 38px;
  }
  .DoctorListing .filter {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .DoctorListing .filter .ElasticSearch {
    min-width: initial;
    width: 49%;
    margin: 0;
  }
  .DoctorListing .filter .ElasticSearch .Input-control,
  .DoctorListing .filter .SelectDate .Input-control,
  .DoctorListing .filter .InsuranceFilterSelect .Input-control {
    width: 100%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .DoctorListing .filter .Input-control,
  .DoctorListing .filter .SelectDate {
    width: 49%;
    flex: inherit;
    margin: 0;
  }
  .DoctorListing .filter .chooseinsurance {
    width: calc(49% - 70px);
  }
  .DoctorListing .filter.sticky-filter .logo {
    position: absolute;
    left: 16px;
    top: 20px;
  }
  .DoctorListing .filter.sticky-filter {
    padding-left: 150px;
  }
  .DoctorListing .doctor-list-wrapper .map {
    height: calc(100vh - 180px);
  }
  .DoctorListing .InfiniteScroll,
  .DoctorListing.sticky-header .doctor-list-content {
    height: calc(100vh - 180px);
  }
  /* .DoctorListing.sticky-header .doctor-list-content.full-map{
    top: 173px;
  } */

  /* .DoctorListing .doctor-list-wrapper .map.full-map {
    top: 173px;
  } */
  .DoctorListing .SwitchToggle .switch-label {
    margin-right: 5px;
  }
  .DoctorListing .SwitchToggle.switchImage .switchContainer {
    margin-right: 5px;
  }
  /* .DoctorListing .filter.sticky-filter{
    top:-180px;
  } */
}

@media (max-width: 767px) {
  .DoctorListing.sticky-header .filter {
    position: relative;
    padding-left: 24px;
    padding-top: 30px;
    padding-top: 12px;
    z-index: 2;
  }
  .DoctorListing.sticky-header .filter .logo {
    display: none;
  }
  .DoctorListing .filter .Input-control,
  .DoctorListing .filter .SelectDate {
    width: 100%;
  }
  .DoctorListing .search-btn {
    width: 100%;
    border-radius: 24px;
  }
  .DoctorListing.sticky-header {
    padding-top: 0;
  }
  .DoctorListing .filter .ElasticSearch .Input-control {
    margin-bottom: 10px;
  }
  .DoctorListing .filter .Input-control,
  .DoctorListing .filter .SelectDate .Input-control {
    margin-bottom: 10px;
  }
  .DoctorListing.sticky-header .filter .search-btn {
    width: 100%;
  }
  .DoctorListing .filter .Input-control.form-group INPUT,
  .DoctorListing .filter .Input-control INPUT {
    font-size: 20px;
    padding-left: 53px !important;
    padding-top: 5px;
    height: 57px;
  }
  .DoctorListing .doctor-list-wrapper .map {
    display: none;
  }
  .DoctorListing .doctor-list-wrapper .doctor-list-content {
    width: 100%;
    height: 100vh;
  }
  .DoctorListing .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 100%;
  }
  .DoctorListing .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 350px;
  }
  .DoctorListing .MyDatetime.days .rdtPicker .rdtSwitch:after {
    width: 93px;
  }
  .DoctorListing .MyDatetime .rdtPicker .rdtDays TABLE {
    border-spacing: 25px 10px;
  }
  .DoctorListing .search-result .search-text {
    display: block;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 12px;
    padding-left: 30px;
    -webkit-overflow-scrolling: touch;
  }
  .DoctorListing .filter .Input-control .view-img {
    top: 28px;
    left: 16px;
    height: 22px;
  }
  .DoctorListing .filter .ElasticSearch .Input-control .view-img {
    top: 29px;
  }
  .DoctorListing.sticky-header .filter {
    padding-right: 24px;
    padding-left: 24px;
  }
  .DoctorListing .filter .InsuranceFilterSelect {
    width: 100%;
  }
  /* .DoctorListing .PatientHeader .header-content .logo a img {
    width: 128px;
  } */
  .DoctorListing .PatientHeader .container {
    padding: 0 24px;
  }
  .DoctorListing .filter {
    height: 83px;
    overflow: hidden;
    padding: 11px 24px 11px;
    position: relative;
    z-index: 1;
  }
  .DoctorListing .filter .zipcode {
    visibility: hidden;
  }
  .DoctorListing .filter.show-filter .map-overlay {
    display: none;
  }
  .DoctorListing .filter.show-filter {
    height: 100%;
    overflow: visible;
  }
  .DoctorListing .filter.show-filter .ElasticSearch {
    width: 100%;
  }
  .DoctorListing .filter.show-filter .CircleButton.WhiteButton {
    width: 100%;
    display: block;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 1.12;
    letter-spacing: -0.34px;
    text-align: center;
    color: #ffffff;
  }
  .DoctorListing .search-result {
    padding: 26px 0px 18px;
    border-bottom: 1px solid #e8eceb;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
  }
  .DoctorListing .search-result .search-text {
    padding-left: 15px;
  }
  .DoctorListing .search-btn {
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 5px;
    background-color: #3d93a6;
  }
  .DoctorListing .search-btn SPAN {
    font-family: 'Calibre Medium';
    font-size: 18px;
    line-height: 50px;
    text-align: center;
    display: block;
    color: #ffffff;
  }
  .DoctorListing .search-btn IMG,
  .DoctorListing .search-btn .mg-icon {
    display: none;
  }
  .DoctorListing .map-overlay {
    display: block;
    font-size: 18px;
    line-height: 1.22;
    color: #ffffff;
    font-family: 'Calibre Medium';
    position: absolute;
    right: 31px;
    top: 33px;
    z-index: 1;
  }
  .DoctorListing .map-overlay .list-text {
    display: none;
  }
  .DoctorListing .search-warp {
    position: absolute;
    width: calc(100% - 111px);
    height: 56px;
    top: 11px;
    display: block;
    z-index: 3;
  }
  .DoctorListing .filter .ElasticSearch .Input-control.form-group INPUT {
    height: 57px;
  }
  .DoctorListing .filter.show-filter .zipcode {
    visibility: visible;
  }
  .DoctorListing .filter.show-filter .search-warp {
    display: none;
  }
  .DoctorListing .filter .Input-control.transform-input .form-control:focus,
  .DoctorListing .filter .Input-control.transform-input.filled-focus-input .form-control {
    padding-top: 2px;
  }

  .DoctorListing .rating-content-show .reviwe-content .rating-text {
    top: 0;
  }
  .DoctorListing .rating-content-show .DoctorItem .dc-review .reviwe-content .star {
    top: 22px;
  }
  .DoctorListing .rating-content-show .DoctorItem .dc-review .reviwe-content .rating {
    top: 2px;
  }
  .DoctorListing.show-map .map {
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 186px;
    bottom: 0;
    width: 100%;
    height: calc(100vh - 186px);
    z-index: 1;
  }
  .DoctorListing.show-map {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
  }
  .DoctorListing.show-map .doctor-list-wrapper .doctor-list-content {
    display: none;
  }
  .DoctorListing.show-map .map-overlay .list-text {
    display: block;
  }
  .DoctorListing.show-map .map-overlay .map-text {
    display: none;
  }
  .DoctorListing .DoctorItem .table-content {
    padding-bottom: 15px;
  }
  .DoctorListing .filter .ElasticSearch {
    width: calc(100% - 50px);
  }
  .DoctorListing .filter .Input-control.form-group INPUT::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control.form-group INPUT::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control.form-group INPUT:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control.form-group INPUT:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control INPUT::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control INPUT::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control INPUT:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
  }
  .DoctorListing .filter .Input-control INPUT:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }
  .DoctorListing.sticky-header .InfiniteScroll {
    height: auto;
    position: static;
    padding-right: 0;
  }
  .DoctorListing .InfiniteScroll {
    height: auto;
  }
  .DoctorListing.sticky-header .doctor-list-content.full-map {
    position: static;
    top: 0;
    height: auto;
  }
  .DoctorListing .filter.sticky-filter {
    display: none;
  }
  .DoctorListing.sticky-header {
    height: auto;
  }
  .DoctorListing .Gender .dropdown-menu,
  .DoctorListing .Availability .dropdown-menu {
    left: 0;
  }
  .DoctorListing .search-action {
    width: 100%;
    padding-top: 21px;
    border-top: 1px solid #eaedec;
    padding-left: 24px;
    padding-right: 24px;
  }
  .DoctorListing .tab-menus SPAN {
    white-space: nowrap;
  }
  .DoctorListing .tab-menus SPAN:last-child {
    padding-right: 30px;
  }
  .DoctorListing .doctor-list-wrapper .doctor-list-content {
    padding-right: 0;
    padding-left: 24px;
  }
  .DoctorListing .tab-menus {
    padding-left: 9px;
  }
  .DoctorListing .DoctorItem .progress-bar .dc-symptoms-text {
    display: none;
  }
  .DoctorListing .DoctorItem .pt-category-box {
    margin-top: 11px;
  }
  .DoctorItem .progress-bar-wrapper {
    padding-top: 11px;
  }
  .DoctorItem .dc-profile-link A {
    margin-left: 0;
    margin-right: 39px;
  }
  .DoctorItem .dc-profile-link A IMG,
  .DoctorItem .dc-profile-link .see-availability IMG {
    margin-right: 8px;
  }
  .DoctorListing .provider-not-available {
    margin-right: 25px;
  }
  .DoctorListing .dc-main-contant.sticky-search .search-result {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 1;
  }
  .DoctorListing .dc-main-contant.sticky-search {
    padding-top: 129px;
  }
  .DoctorListing .search-action .CircleButton {
    padding: 0 12px;
    margin-right: 8px;
    font-size: 14px
  }
  @media (hover: hover) {
    .DoctorListing .search-btn:enabled:hover SPAN {
      color: #013648;
    }
    .DoctorListing .search-btn:enabled:hover .mg-icon {
      display: none;
    }
  }
}

@media (max-width: 413px) {
  .DoctorListing .filter {
    padding: 11px 15px 11px;
  }
  .DoctorListing .PatientHeader .container {
    padding: 0px 15px;
  }
  .DoctorListing .map-overlay {
    right: 15px;
  }
  .DoctorListing .doctor-list-wrapper .doctor-list-content {
    padding-left: 15px;
    padding-right: 0px;
  }
  .DoctorItem .table-content {
    margin-right: -15px;
  }
  .DoctorListing .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 289px;
  }
  .DoctorListing .MyDatetime.days .rdtPicker .rdtSwitch:after {
    width: 54px;
  }
  .DoctorListing .MyDatetime .rdtPicker .rdtDays TABLE {
    border-spacing: 10px 10px;
  }
  .DoctorListing.sticky-header .filter {
    padding-left: 15px;
    padding-right: 15px;
  }
  .DoctorListing .filter .ElasticSearch {
    width: calc(100% - 50px);
  }
  .DoctorListing .search-warp {
    width: calc(100% - 80px);
  }
  .DoctorListing .search-result .search-text {
    padding-left: 15px;
  }
  .DoctorListing .tab-menus SPAN:last-child {
    padding-right: 0px;
  }
  .DoctorListing .tab-menus {
    padding-left: 0px;
  }
  .DoctorListing .search-action {
    padding-left: 15px;
    padding-right: 15px;
  }
  .DoctorListing .search-action .CircleButton {
    padding: 0 8px;
    margin-right: 5px;
    font-size: 14px;
  }
  .DoctorListing .Language .dropdown-menu{
    right: -11px;
  }
}

@supports (-webkit-touch-callout: none) {
  .DoctorListing .search-btn SPAN {
    display: inline-block;
    padding-top: 2px;
  }
  .DoctorItem .timing-table .td .time {
    padding-top: 5px;
  }
}

.Language {
  position: relative;
  display: inline-block;
}
.Language .dropdown-menu {
  position: absolute;
  top: 50px;
  box-shadow: 0 0 14px 0 #d8d3d3;
  left: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: all 0.3s linear;
  background-color: #fff;
  min-width: 230px;
  z-index: 20;
  padding: 10px 0 10px;
}
.Language .dropdown-option-wrapper {
  max-height: 230px;
  overflow: auto;
}
.Language .dropdown-menu.show-menu {
  visibility: visible;
  opacity: 1;
}
.Language .dropdown-menu .dropdown-option {
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #013648;
  font-family: 'Calibre Medium';
}
.Language .dropdown-menu .dropdown-option .count {
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.6);
}
.Language .dropdown-menu .Checkbox-control {
  margin: 0;
}
.Language .dropdown-menu .dropdown-bottom {
  padding: 8px 20px;
  border-top: 1px solid #dbdbdb;
  text-align: right;
  padding: 8px 20px 0;
}
.Language .dropdown-menu .CircleButton.WhiteButton {
  margin-right: 0;
  color: #013648;
  line-height: 32px;
}
.Language.selected > .CircleButton.SecondaryButton {
  background-color: #013648;
  color: #ffffff;
}
.Language .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (hover: hover) {
  .Language .dropdown-menu .CircleButton.WhiteButton:hover {
    color: #ffffff;
  }
}
@media (max-width: 767px) {
  .Language .dropdown-menu .CircleButton.WhiteButton {
    color: #fff;
  }
}

.DoctorItem .DC-image {
  width: 100px;
  min-width: 100px;
  height: 150px;
}
.DoctorItem .DC-image > SPAN:first-child A {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.DoctorItem.DoctorItemOrg .DC-image IMG {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  /* object-fit: cover;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  /* border-bottom: 0; */
}
.DoctorItem .DC-image .doctor-link IMG {
  width: auto;
  height: 100%;
  border: none;
  padding-right: 5px;
}
.DoctorItem .DC-image > SPAN:first-child {
  width: 100%;
  height: 100%;
}
.DoctorItem .DC-image > SPAN:first-child {
  vertical-align: top;
}
.DoctorItem .DC-image SPAN,
.DoctorItem .DC-image A {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
}
.DoctorItem .DC-image A,
.DoctorItem .DC-image .see-availability {
  color: #26acda;
  cursor: pointer;
  white-space: nowrap;
}
.DoctorItem {
  display: flex;
  padding: 25px 0 20px;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.DoctorItem:last-child {
  border-bottom: solid 0px rgba(1, 54, 72, 0.07);
}
.DoctorItem .DC-image {
  display: inline-block;
  margin-top: 5px;
}
.DoctorItem .dc-information {
  margin-left: 40px;
  padding-bottom: 13px;
}
.DoctorItem .dc-information .dc-content .dc-name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
}
.DoctorItem .dc-information .dc-content .dc-name A {
  color: #013648;
}
.DoctorItem .dc-information .dc-content .dc-specialist {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.2px;
  color: #013648;
}
.DoctorItem .dc-information .dc-content .dc-address {
  font-size: 16px;
  line-height: 1.58;
  color: #587681;
  letter-spacing: -0.18px;
}
.DoctorItem .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorItem .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 0.2);
}
.DoctorItem .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(1n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(2n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(3n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(4n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(5n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 1);
}
.DoctorItem .pt-category-box .box.active:nth-of-type(6n),
.DoctorItem .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorItem .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 11px;
}
.DoctorItem .pt-category-box .neck {
  width: 35%;
}
.DoctorItem .pt-category-box .sciatica {
  width: 25%;
  background-color: #e85d72;
}
.DoctorItem .pt-category-box .herniated {
  width: 20%;
  background-color: #8d77ba;
}
.DoctorItem .pt-category-box .scoliosis {
  width: 20%;
  background-color: #f2962d;
}
.DoctorItem .progress-bar .dc-symptoms {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.4px;
  color: #a2a2a2;
}
.DoctorItem .progress-bar .dc-symptoms-text {
  font-size: 16px;
  line-height: 0.84;
  letter-spacing: -0.2px;
  color: #72787d;
  margin-top: 5px;
}
.DoctorItem .reviwe-content {
  position: relative;
}
.DoctorItem .dc-review {
  padding-right: 6px;
  min-width: 175px;
}
.DoctorItem .dc-review .reviwe-content .star {
  width: 41px;
  position: relative;
  top: 18px;
  left: 9px;
}
.DoctorItem .dc-review .reviwe-content .rating {
  font-family: 'Heldane Display';
  font-size: 44px;
  line-height: 0.71;
  letter-spacing: -0.96px;
  color: #013648;
  position: relative;
  top: 2px;
}
.DoctorItem .dc-review .reviwe-content .rating.grey {
  color: rgb(153, 153, 153);
}
.DoctorItem .dc-review .reviwe-content .info {
  width: 19px;
  padding-left: 5px;
}
.DoctorItem .dc-review .reviwe-content .social-icon {
  width: 19px;
  margin-top: 25px;
  margin-left: 7px;
  position: relative;
  right: 17px;
}
.DoctorItem .dc-review .reviwe-content .rating-text {
  width: 34px;
  height: 26px;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #013648;
  position: relative;
  top: 6px;
  right: 16px;
}
.DoctorItem .dc-info-content .dc-information {
  display: flex;
  justify-content: space-between;
}
.DoctorItem .dc-info-content {
  width: calc(100% - 101px);
  padding-right: 60px;
}
.DoctorItem .dc-review .reviwe-content > SPAN {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
}
.DoctorItem .dc-appointment-time {
  padding-top: 14px;
  margin-left: 40px;
  border-top: solid 2px rgba(1, 54, 72, 0.1);
}
.DoctorItem .timing-table {
  width: 508px;
  padding: 0px 29px 0px 44px;
}
.DoctorItem .table-wrapper {
  position: relative;
  display: inline-block;
}
.DoctorItem .table-wrapper .prev-link {
  position: absolute;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
.DoctorItem .table-wrapper .right-link {
  position: absolute;
  right: 0px;
  top: -4px;
  transform: rotate(180deg);
  cursor: pointer;
}
.DoctorItem .table-wrapper .prev-link IMG,
.DoctorItem .table-wrapper .right-link IMG {
  width: 24px;
}
.DoctorItem .table-wrapper .prev-link.disabled IMG,
.DoctorItem .table-wrapper .right-link.disabled IMG {
  opacity: 0.21;
  cursor: not-allowed;
}
.DoctorItem .timing-table .tr {
  display: flex;
  margin-right: 15px;
}
.DoctorItem .timing-table .th {
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: center;
  width: 83px;
}
.DoctorItem .timing-table .th .date {
  display: block;
  font-family: 'Calibre Regular';
  font-weight: normal;
  text-align: center;
}
.DoctorItem .timing-table .tr .th:last-child {
  padding-right: 0;
}
.DoctorItem .timing-table .td {
  padding: 0px 5px 5px;
}
.DoctorItem .timing-table .tr .td:last-child {
  padding-right: 0;
}
.DoctorItem .timing-table .tr:last-child .td {
  padding-bottom: 0;
}
.DoctorItem .timing-table .td .time {
  width: 73px;
  font-size: 14px;
  line-height: 1.31;
  letter-spacing: normal;
  border-radius: 13.8px;
  padding: 6px 3px 2px;
  text-align: center;
  vertical-align: middle;
  border: 0.7px solid rgba(1, 54, 72, 0.1);
  color: #013648;
}
.DoctorItem .timing-table .td.available .time {
  color: #fff;
  cursor: pointer;
  background-color: #013648;
  border: 1px solid #013648;
  font-size: 14px;
}
.DoctorItem.isVirtual .timing-table .td.available .time {
  color: #fff;
  background-color: #6a3acd;
  border: 1px solid #6a3acd;
}
.DoctorItem .timing-table .td.booked .time {
  background-color: #013648;
  color: #ffffff;
  cursor: not-allowed;
}
.DoctorItem .day-table {
  width: 100%;
  border-spacing: 0;
}
.DoctorItem .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DoctorItem .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.DoctorItem .day-table .tr .td:last-child {
  padding-right: 0;
}
.DoctorItem .day-table .tr:last-child .td {
  padding-bottom: 0;
}
.DoctorItem .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.DoctorItem .appointment-inner-block {
  margin-bottom: 46px;
}
.DoctorItem .appointments-day-block {
  padding-top: 10px;
}
.DoctorItem .appointment-inner-block:last-child {
  margin-bottom: 0;
}
.DoctorItem .time-container {
  max-height: 95px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorItem .time-container.show-more {
  max-height: 95px;
  overflow: auto;
}
.timing-table .more-avaliable {
  float: left;
  margin-left: 10px;
  padding-top: 10px;
  cursor: pointer;
  color: #3d93a6;
  display: none;
}
.DoctorItem .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.DoctorItem .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}

.DoctorItem .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorItem .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorItem .DC-image .dc-profile-link .MyLink,
.DoctorItem .DC-image .dc-profile-link .see-availability {
  margin-top: 6px;
}
.DoctorItem .dc-profile-link {
  display: none;
}
.DoctorItem .dc-action > SPAN,
.DoctorItem .dc-action > SPAN:first-child {
  display: inline-block;
  width: 20px;
  margin-right: 9px;
}
.DoctorItem .dc-action > SPAN IMG {
  width: 100%;
  border: 0;
}
.DoctorItem .dc-review .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DoctorItem .dc-review .info-wrapper {
  display: inline-block;
  position: relative;
  top: -12px;
  left: 5px;
  line-height: 0;
}
.DoctorItem .progress-bar-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.DoctorItem .progress-bar-wrapper .progress-bar {
  width: 50%;
  padding: 0 14px;
}
/* .DoctorItem .dc-content {
  flex: 1;
} */
.DoctorItem .DC-image .person-visit,
.DoctorItem .DC-image .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 4px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 4px;
  padding-left: 36px;
  margin-bottom: 13px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.DoctorItem .DC-image .person-visit IMG,
.DoctorItem .DC-image .video-visit IMG {
  width: 20px;
  border: 0;
  position: absolute;
  left: 10px;
  top: 0;
}
.DoctorItem .DC-image .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.DoctorItem .pt-category-box .box:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.DoctorItem .dc-profile-link span {
  font-size: 15px;
  line-height: 1.68;
  letter-spacing: -0.28px;
  color: rgba(1, 54, 72, 0.5);
}
.DoctorItem .dc-profile-link A,
.DoctorItem .dc-profile-link .see-availability {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  cursor: pointer;
  display: inline-block;
}
.DoctorItem .dc-sm-distance {
  /* display: none; */
  font-size: 15px;
  line-height: 1.68;
  letter-spacing: -0.28px;
  color: #587681;
}

@media (hover: hover) {
  .DoctorItem .timing-table .td.available .time:hover {
    background-color: rgba(1, 54, 72, 0.1);
    color: #013648;
    border: 1px solid #013648;
  }
  .DoctorItem.isVirtual .timing-table .td.available .time:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #013648;
  }
  .DoctorItem .dc-review .info-wrapper:hover .info-content {
    display: block;
  }
}

@media (max-width: 1200px) {
  .DoctorItem .dc-appointment-time,
  .DoctorItem .dc-info-content .dc-information {
    margin-left: 15px;
  }
  .DoctorItem .dc-review {
    padding-right: 0;
  }
  .DoctorItem .timing-table .td .time {
    width: 65px;
  }
  .DoctorItem .timing-table .th {
    width: 75px;
  }
  .DoctorItem .timing-table {
    width: 470px;
  }
}
.DoctorItem .bookaappointment {
  display: flex;
  align-items: center;
  background-color: #013648;
  padding: 5px 30px;
  margin-bottom: 40px;
  border-radius: 25px;
  color: white;
  grid-gap: 10px;
  gap: 10px;
  font-size: 18px;
}
.DoctorItem .bookaappointment span {
  margin-top: 7px;
}
.DoctorItem .bookaappointment img {
  filter: invert(0%) sepia(66%) saturate(0%) hue-rotate(125deg) brightness(103%) contrast(103%);
}
@media (max-width: 1024px) {
  .DoctorItem .progress-bar-wrapper {
    margin: 0;
    flex-direction: column;
  }
  .DoctorItem .progress-bar-wrapper .progress-bar {
    width: 100%;
    padding: 0;
  }
  .DoctorItem .pt-category-box {
    width: 230px;
  }
  .DoctorItem .dc-information .dc-content .dc-address {
    color: #587681;
  }
  .DoctorItem .dc-info-content .dc-information {
    flex-wrap: wrap;
  }
  .DoctorItem .DC-image .dc-profile-link SPAN,
  .DoctorItem .DC-image .dc-profile-link A {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: -0.34px;
    color: rgba(1, 54, 72, 0.5);
    margin-bottom: 8px;
  }
  .DoctorItem .DC-image .dc-profile-link A,
  .DoctorItem .DC-image .dc-profile-link .see-availability {
    color: #3dbce7;
    cursor: pointer;
  }
  .DoctorItem .DC-image .dc-profile-link A {
    margin: 0px 16px;
  }
  .DoctorItem .DC-image .dc-profile-link {
    display: none;
  }
  .DoctorItem .dc-review {
    width: 100%;
  }
  .DoctorItem {
    position: relative;
  }
  .DoctorItem .DC-image {
    position: absolute;
    left: 0;
    top: 20px;
  }
  .DoctorItem .dc-content {
    padding-left: 115px;
  }
  .DoctorItem .dc-info-content {
    width: 100%;
  }
  .DoctorItem .dc-appointment-time,
  .DoctorItem .dc-info-content .dc-information {
    margin-left: 0;
  }
  .DoctorItem .timing-table {
    padding: 0px 29px 0px 30px;
  }
  .DoctorItem .timing-table {
    width: 450px;
  }
  .DoctorItem .dc-content {
    padding-left: 115px;
  }
  .DoctorItem .dc-profile-link {
    margin-top: 19px;
    display: block;
  }
  .DoctorItem .dc-review .reviwe-content .social-icon,
  .DoctorItem .dc-review .reviwe-content .info {
    display: none;
  }
  .DoctorItem .dc-review .reviwe-content .rating-text {
    color: #203a70;
    right: 0px;
  }
  .DoctorItem .dc-review .reviwe-content .rating {
    font-size: 38px;
  }
  .DoctorItem .dc-profile-link A {
    margin: 0 30px;
  }
  .DoctorItem .dc-profile-link A:first-child {
    margin-left: 0;
  }
  .DoctorItem .dc-sm-distance {
    display: block;
  }
  .DoctorItemOrg .hideon-mobile {
    display: none !important;
  }
  .DoctorItemOrg .book-now {
    width: 175px;
  }
  .DoctorItemOrg.DoctorItem .bookaappointment {
    margin-bottom: 10px;
  }
  .DoctorItemOrg.DoctorItem .dc-info-content .dc-information {
    padding-bottom: 30px !important;
  }
}

@media (max-width: 767px) {
  .DoctorItem .pt-category-box {
    width: 100%;
  }
  .DoctorItem .table-content {
    overflow-x: auto;
    margin-right: -25px;
  }
  .DoctorItem .table-wrapper {
    width: 100%;
  }
  .DoctorItem .timing-table {
    padding: 0px;
  }
  .DoctorItem .table-wrapper .prev-link,
  .DoctorItem .table-wrapper .right-link {
    display: none;
  }
  .DoctorItem .DC-image {
    width: 71px;
    min-width: 71px;
    height: 104px;
  }
  .DoctorItem .dc-content {
    padding-left: 90px;
    padding-top: 0px;
    min-height: 206px;
  }
  .DoctorItem .dc-information .dc-content .dc-name {
    font-size: 20px;
  }
  .DoctorItem .dc-information .progress-bar {
    width: 100%;
  }
  .DoctorItem .dc-info-content .dc-information {
    position: relative;
  }
  .DoctorItem .dc-review {
    position: absolute;
    top: 159px;
    right: calc(100% - 74px);
    width: auto;
    min-width: auto;
  }
  .DoctorItem .dc-review .reviwe-content .rating {
    top: -13px;
    left: -6px;
    font-size: 26px;
  }
  .DoctorItem .dc-review .reviwe-content .rating-text {
    right: 5px;
    top: -15px;
    font-size: 12px;
    font-family: 'Calibre Medium';
    color: #3dbce7;
  }
  .DoctorItem .dc-information {
    padding-bottom: 22px;
  }
  .DoctorItem .dc-review .reviwe-content .star {
    top: -2px;
    left: -3px;
    width: 24px;
    height: 31px;
  }
  .DoctorItem .timing-table .th .date {
    font-family: 'Calibre Medium';
    font-weight: 500;
    display: inline-block;
    width: 100%;
  }
  .DoctorItem .dc-appointment-time {
    padding-top: 27px;
  }
  .DoctorItem .timing-table .th {
    padding-bottom: 16px;
    width: 84px;
  }
  .DoctorItem .timing-table .td .time {
    width: 75px;
  }
  .DoctorItem .time-container {
    overflow-y: visible;
    height: auto;
  }
  .DoctorItem .dc-profile-link {
    display: flex;
  }
  .DoctorItem .dc-profile-link A {
    margin: 0px 10px;
  }
  .DoctorItem.rating-content-show .dc-info-content .dc-information {
    padding-bottom: 80px;
  }
  .DoctorItem .DC-image .person-visit,
  .DoctorItem .DC-image .video-visit {
    font-size: 12px;
    padding-left: 20px;
    width: 72px;
  }
  .DoctorItem .DC-image .person-visit IMG,
  .DoctorItem .DC-image .video-visit IMG {
    left: 0;
    object-fit: contain;
  }
  .DoctorItem .DC-image .video-visit {
    padding-left: 18px;
  }
  .DoctorItem {
    padding-right: 15px;
  }
  .DoctorItem .timing-table .td .time {
    width: 68px;
  }
  .DoctorItem .timing-table .th {
    width: 76px;
  }
  .DoctorItem .timing-table .td {
    padding: 0px 4px 5px;
  }
  /* .DoctorItem .timing-table .td.available .time {
    background-color: #013648;
    color:#fff;
  } */
  /* .DoctorItem .dc-information .dc-content .dc-info-inner-content {
    min-height: 120px;
  } */
  .DoctorItem .timing-table {
    width: 390px;
  }
  .DoctorItem .dc-info-content {
    padding-right: 0;
  }
  .timing-table .more-avaliable {
    display: block;
  }
  .DoctorItem .time-container {
    overflow: hidden;
  }
  .DoctorItem .time-container.show-more {
    max-height: 100% !important;
    /* overflow: auto; */
  }
}

@media (max-width: 413px) {
  .DoctorItem .dc-profile-link SPAN {
    font-size: 14px;
  }
  .DoctorItem .dc-profile-link A,
  .DoctorItem .dc-profile-link .see-availability {
    font-size: 14px;
  }
  .DoctorItem .dc-content .dc-profile-link A IMG,
  .DoctorItem .dc-content .dc-profile-link .see-availability IMG {
    margin-right: 3px;
  }
  .DoctorItem .dc-content .dc-profile-link A {
    margin: 0px 6px 0 0;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .DoctorItem {
    border-top: solid 1px rgba(1, 54, 72, 0.07);
  }
}

.ConfirmAppointmentThanks .ConfirmAppointmentThanks-content {
  background-color: #fbfbf9;

  padding: 7% 15%;
  padding-bottom: 0%;
}

.ConfirmAppointmentThanks .ConfirmAppointmentThanks-content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.ConfirmAppointmentThanks .reserve-appointment {
  height: 57.6px;
  font-size: 14px;
  line-height: 1.37;
  letter-spacing: -0.19px;
  color: rgba(1, 54, 72, 0.5);
  width: 100%;
  margin-top: 56px;
  margin-bottom: 50px;
}
.ConfirmAppointmentThanks .reserve-appointment a {
  color: #3dbce7;
}
.ConfirmAppointmentThanks .CustomFooter {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-top: 62px;
}
.ConfirmAppointmentThanks .footer-block:after {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
  width: 52.6%;
  background-color: #fbfbf9;
}
.ConfirmAppointmentThanks .CustomFooter .container {
  width: 100%;
}
.ConfirmAppointmentThanks .CustomFooter .footer-menu-ul {
  z-index: 1;
}
.ConfirmAppointmentThanks .title-heading {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ConfirmAppointmentThanks .title-heading h1 {
  color: #023648;
  font-family: 'Heldane Display';
  font-size: 45px;
  line-height: 0.94;
  text-align: center;
  /* min-height: 68px; */
  letter-spacing: -0.2px;
  margin-top: 20px;
  margin-bottom: 16px;
  font-weight: normal;
}
.ConfirmAppointmentThanks .title-heading h3 {
  color: #023648;
  /* font-family: 'Heldane Display'; */
  font-size: 30px;
  line-height: 0.94;
  text-align: center;
  /* min-height: 68px; */
  letter-spacing: -0.2px;
  margin-top: 0;
  margin-bottom: 13px;
  font-weight: normal;
}
.ConfirmAppointmentThanks .ReviewConfirm .reserve-appointment {
  display: none;
  margin-top: 24px;
}
.AppointmentRequest .appointment-map {
  height: 350px;
}
.goback-div {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.goback-div a {
  font-size: 18px;
  color: #023648;
  cursor: pointer;
}
.goback-div a:hover {
  color: #0479a2;
}
.PatientHeader.small {
  height: 60px;
}
@media (max-width: 1400px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    padding-left: 100px;
    padding-right: 60px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content {
    padding-right: 60px;
  }
  .ConfirmAppointmentThanks .AppointmentRequest .back-arrow {
    left: -50px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    padding-left: 70px;
    padding-right: 30px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content {
    padding-right: 30px;
    padding-left: 30px;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    width: 100%;
  }
  .ConfirmAppointmentThanks .footer-block:after {
    width: 50.6%;
  }
}
@media (max-width: 1200px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    width: 55%;
  }
  .AppointmentRequest .appointment-map {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ConfirmAppointmentThanks {
    padding-bottom: 118px;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    padding-bottom: 41px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    width: 100%;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    padding-top: 41px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content {
    background-color: #fbfbf9;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    background-color: #fbfbf9;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content {
    flex-wrap: wrap;
  }
  .ConfirmAppointmentThanks .footer-block:after {
    display: none;
  }
  .ReserveAppointment .divider-content .Divider {
    display: block;
  }
  .ConfirmAppointmentThanks .reserve-appointment {
    display: none;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    background-color: #eaf4eb;
    padding-top: 10px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .title-heading {
    font-family: 'Heldane Display';
    font-size: 28px;
    line-height: 1.21;
    color: #fff;
    padding-top: 36px;
    position: relative;
    border-bottom: 1px solid #e0e4e7;
    padding-bottom: 18px;
    padding-right: 100px;
    margin-bottom: 18px;
    display: block;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .address {
    font-size: 16px;
    line-height: 1.25;
    color: #013648;
    opacity: 1;
    margin-top: 43px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .title-heading img {
    width: 18px;
    position: absolute;
    top: -20px;
    cursor: pointer;
    right: 0;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .reserve-appointment {
    display: block;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .AppointmentRequest .questionnaire {
    display: flex;
    flex-wrap: nowrap;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 11;
    overflow: auto;
    padding-top: 50px;
    padding-bottom: 85px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-overflow-scrolling: touch;
  }
  .ConfirmAppointmentThanks.showappointment-mobile
    .AppointmentRequest
    .appointment-doctor
    .doctor-img {
    height: 105px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .appointment-request-detail {
    border-bottom: solid 1px rgba(32, 58, 112, 0.08);
    padding-bottom: 20px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .reserve-appointment {
    display: none;
  }
  .ConfirmAppointmentThanks .ReviewConfirm .reserve-appointment {
    display: block;
  }
  .CustomFooter .menu-item-list {
    width: auto;
  }
  .ConfirmAppointmentThanks .title-heading h1 {
    font-size: 35px;
  }
  .ConfirmAppointmentThanks .title-heading h3 {
    font-size: 22px;
  }
}

@media (max-width: 413px) {
  .ConfirmAppointmentThanks {
    padding-bottom: 148px;
  }
  .ConfirmAppointmentThanks .CustomFooter,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .title-heading {
    padding-right: 0;
  }
}


.ReviewWidget .widget-preview {
  width: 270px;
  height: 180px;
  background-color: #026ca2;
  margin: auto;
  cursor: pointer;
}
.ReviewWidget .widget-preview.rounded {
  width: 180px;
  height: 180px;
  border-radius: 90px;
  text-align: center;
}
.ReviewWidget .widget-preview.square {
  width: 165px;
  height: 165px;
}
.ReviewWidget .widget-preview.mini-rectangle {
  width: 145px;
  height: 75px;
}
.ReviewWidget .dimantion-text {
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  color: #474747;
}
.ReviewWidget .widget-preview {
  padding: 15px 20px;
  position: relative;
}
.ReviewWidget .widget-preview.mini-rectangle {
  padding: 11px 8px 9px 11px;
}
.ReviewWidget .widget-preview.mini-rectangle .preview-text {
  font-size: 13px;
  line-height: 1.1;
}
.ReviewWidget .widget-preview .preview-text {
  font-size: 28px;
  color: #fff;
  font-family: 'Calibre Light';
  padding-right: 60px;
}
.ReviewWidget .widget-preview.square .preview-text {
  padding-top: 10px;
}
.ReviewWidget .widget-preview.rounded .preview-text {
  text-align: center;
  padding-right: 0px;
}
.ReviewWidget .widget-preview .preview-text span {
  display: inline-block;
  width: 100%;
}
.ReviewWidget .widget-preview .star {
  display: inline-flex;
  padding-right: 25px;
  position: relative;
}
.ReviewWidget .widget-preview .star img {
  width: 12px;
}
.ReviewWidget .widget-preview .star > span {
  display: inline-block;
  margin-right: 7px;
}
.ReviewWidget .widget-preview .star > .rating {
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 14px;
  color: #ffffff;
}
.ReviewWidget .widget-preview .certificate-img {
  width: 50px;
  position: absolute;
  right: 25px;
  top: 0;
}
.ReviewWidget .widget-preview.mini-rectangle .certificate-img {
  width: 43px;
  right: 3px;
  top: 2px;
}
.ReviewWidget .widget-preview.rounded .certificate-img {
  display: none;
}

.VideoChat * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.VideoChat header {
    background: #f0293e;
    color: #fff;
    text-align: center;
    flex-grow: 0;
    margin-bottom: 2em;
}

.VideoChat h1 {
    font-weight: 300;
    padding: 0.4em 0;
}

.VideoChat #root {
    min-height: 100vh;
}

.VideoChat .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.VideoChat main {
    background: #ffffff;
    flex-grow: 1;
    width: 100%;
    position: relative;
    display: inline-flex;
}

.VideoChat form {
    max-width: 300px;
    margin: 0 auto;
}

.VideoChat h2 {
    font-weight: 300;
    margin-bottom: 1em;
    text-align: center;
}

.VideoChat form>div {
    width: 100%;
    margin-bottom: 1em;
}

.VideoChat form>div>label {
    display: block;
    margin-bottom: 0.3em;
}

.VideoChat form>div>input {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 0.4em;
    border-radius: 6px;
    border: 1px solid #333e5a;
}

.VideoChat button {
    background: #333e5a;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid transparent;
    margin: auto 10px;
}

.VideoChat button:hover {
    filter: brightness(150%);
}

.VideoChat .room {
    position: relative;
    width: 75%;
    padding: 10px;
}

.VideoChat .room>h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
}

.VideoChat .local-participant {
    text-align: center;
    margin-bottom: 2em;
    width: 100%;
}

.VideoChat .remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2em 2em;
    width: 100%;
    display: block;
}

.VideoChat .participant {
    background: #333e5a;
    padding: 10px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
}

.VideoChat .participant:last-child {
    margin-top: 10px;
}

.VideoChat .participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}

.VideoChat video {
    width: 100%;
    max-width: 320px;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
}

.VideoChat .video-action {
    width: 100%;
    display: inline-block;
}

.VideoChat .video-action button {
    float: right;
}

.VideoChat footer {
    background: #333e5a;
    color: #fff;
    text-align: center;
    flex-grow: 0;
    padding: 1em 0;
}

.VideoChat footer a {
    color: #fff;
}

.VideoChat .message {
    position: relative;
    width: 25%;
    padding: 10px;
}

.VideoChat .message .message-history {
    min-height: 500px;
    border: #cec8c8 2px solid;
    width: 100%;
    padding: 5px;
    max-height: 500px;
    overflow: scroll;
}

.VideoChat .message .chat {
    width: 80%;
    margin: 5px;
    padding: 5px;
    line-height: 20px;
    border-radius: 5px;
    font-size: 14px;
}

.VideoChat .message .my-chat {
    background-color: #658e29;
    color: white;
}

.VideoChat .message .remote-chat {
    background-color: #4e4f4e;
    color: white;
}

.VideoChat .message .system-chat {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.VideoChat .message .chat .name {
    font-weight: bolder;
    font-size: 15px;
    line-height: 20px;
}

.VideoChat .message .chat .date {
    width: 100%;
    display: block;
    text-align: end;
    font-size: 12px;
}

.VideoChat .message .send-msg-text {
    width: 100%;
}

.VideoChat .message .send-msg-btn {
    width: 100%;
    margin: 0;
}

.VideoChat .error-box {
    border: 2px solid gray;
    min-height: 200px;
    margin-top: 10px;
}

.VideoChat .error-box h4 {
    background: gray;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
}

.VideoChat .message .loading {
    position: absolute;
    top: 515px;
    width: 100%;
    z-index: 11;
}
.Overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.VideoLayoutHeader {
  background-color: #ffffff;
  height: 70px;
  display: flex;
  z-index: 8;
  padding: 0 28px 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.VideoLayoutHeader > DIV {
  width: 100%;
}
.VideoLayoutHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.VideoLayoutHeader .header-content .exit-room-link {
  padding: 11px 18px 6px;
  border-radius: 4px;
  border: solid 1px #e85d72;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.21px;
  text-align: center;
  color: #e85d72;
  display: block;
  background: none;
  cursor: pointer;
}
.VideoLayoutHeader .header-content .right-col SPAN {
  display: inline-block;
  padding: 0px 0px;
  position: relative;
}
.VideoLayoutHeader .header-content .right-col SPAN:last-child {
  padding-right: 0px;
}
.VideoLayoutHeader .header-content .logo {
  width: 111px;
}
.VideoLayoutHeader .header-content .logo A IMG {
  width: 100%;
  cursor: pointer;
}
.VideoLayoutHeader .header-content .logo A {
  position: relative;
  top: 5px;
  display: inline-block;
}
@media (hover: hover) {
  .VideoLayoutHeader .header-content .exit-room-link:hover {
    background: #e85d72;
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .VideoLayoutHeader {
    height: 108px;
    padding: 0 30px;
  }
  .VideoLayoutHeader .header-content .exit-room-link {
    display: none;
  }
  .VideoLayoutHeader .header-content .logo {
    width: 128px;
  }
}

@media (max-width: 413px) {
  .VideoLayoutHeader {
    padding: 0 15px;
  }
  .VideoLayoutHeader .header-content .logo {
    width: 111px;
  }
}

@media (max-width: 360px) {
  .VideoLayoutHeader .header-content .logo {
    width: 105px;
  }
}

.VideoLayout .wrap-content-row {
  display: flex;
  background-color: #fafafb;
  padding-top: 70px;
  min-height: 100vh;
}
.VideoLayout .wrap-content-row-full {
  display: flex;
  background-color: #fafafb;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .VideoLayout .wrap-content-row {
    padding-top: 108px;
  }
}

.PatientTab .patient-details {
  padding: 51px 30px;
  height: calc(100vh - 349px);
  overflow-y: auto;
}
.PatientTab .date-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: #013648;
}
.PatientTab .time-duration {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.17px;
  color: #013648;
  margin-bottom: 21px;
}
.PatientTab .sidebar-bottom {
  min-height: 59px;
}
.PatientTab .patientname-block {
  margin-bottom: 15px;
}
.PatientTab .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: -0.09px;
  color: #013648;
  width: 100%;
  display: block;
}
.PatientTab .patient-link {
  font-family: 'Calibre Regular';
  font-weight: normal;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.11px;
  color: #26acda;
  cursor: pointer;
}
.PatientTab .patient-address {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 21px;
}
.PatientTab .patient-data {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 38px;
  width: 180px;
  max-width: 100%;
}
.PatientTab .patient-data SPAN {
  display: block;
}
.PatientTab .questionnaire .heading3 {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: #013648;
}
.PatientTab .questionnaire SPAN {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: normal;
  color: #013648;
  display: block;
  margin-bottom: 1px;
}
.PatientTab .upcoming-appoinment {
  padding: 16px 25px 42px 30px;
  border-top: 1px solid #f1f1f5;
}
.PatientTab .appoinment-heading {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: -0.2px;
  color: #013648;
  padding-left: 31px;
  padding-right: 20px;
  position: relative;
}
.PatientTab .appoinment-heading SPAN {
  display: block;
}
.PatientTab .appoinment-heading .schedule-icon {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  top: 4px;
}
.PatientTab .appoinment-heading .down-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  height: 9px;
  top: 10px;
}
.PatientTab .appoinment-detail {
  padding-top: 15px;
  display: none;
}
.PatientTab .appoinment-detail.show-appoinment {
  display: block;
}
.PatientTab .appname-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  flex-wrap: wrap;
}
.PatientTab .appname-block .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 1.25;
  color: #013648;
}
.PatientTab .appname-block .patient-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #26acda;
}
.PatientTab .appname-block .appname-left {
  flex: 1 1;
  margin-right: 10px;
}
.PatientTab .appname-block .appoinment-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #013648;
}
.PatientTab .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  display: inline-block;
}
.PatientTab .noroom-txt {
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: #a2a2a2;
}
.PatientTab .link-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.PatientTab .participant-video {
  display: none;
}
@media (hover: hover) {
  .PatientTab .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .PatientTab .appoinment-heading {
    font-size: 16px;
    padding-left: 28px;
  }
  .PatientTab .appoinment-heading .schedule-icon {
    top: 2px;
  }
  .PatientTab .appoinment-heading .down-arrow {
    top: 8px;
  }
  .PatientTab .noroom-txt {
    font-size: 16px;
  }
  .PatientTab .patient-details {
    padding: 15px 23px 15px 24px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 10px 34px 20px 24px;
  }
}

@media (max-width: 767px) {
  .PatientTab .patient-details {
    padding: 33px 25px;
  }
  .PatientTab .appoinment-heading {
    font-size: 18px;
    padding-left: 31px;
  }
  .PatientTab .appoinment-heading .schedule-icon {
    top: 4px;
  }
  .PatientTab .appoinment-heading .down-arrow {
    top: 10px;
  }
  .PatientTab .noroom-txt {
    font-size: 18px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 13px 30px 20px;
  }
  .PatientTab .participant-video {
    width: 99px;
    height: 176px;
    border-radius: 4.8px;
    position: fixed;
    bottom: 189px;
    right: 24px;
    border: solid 0.5px #979797;
    background-color: #1d1b1b;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .PatientTab .participant-video VIDEO {
    border-radius: 4.8px;
    width: 100%;
    height: 100%;
    background-color: #1d1b1b;
  }
}

@media (max-width: 413px) {
  .PatientTab .patient-details {
    padding: 30px 15px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 13px 15px 20px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .PatientTab .patient-details {
    padding: 12px 23px 12px 24px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 10px 34px 10px 24px;
  }
  .PatientTab .appoinment-detail {
    padding-top: 8px;
  }
  .PatientTab .appname-block {
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .PatientTab .patient-details {
    padding: 12px 25px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 10px 30px;
  }
  .PatientTab .participant-video {
    bottom: 100px;
  }
}

.NextVideoAppointment .upcoming-appoinment {
  padding: 16px 25px 42px 30px;
  border-top: 1px solid #f1f1f5;
}
.NextVideoAppointment .no-room-txt {
  float: right;
}

@media (max-width: 1199px) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 10px 34px 20px 24px;
  }
}

@media (max-width: 767px) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 13px 30px 20px;
  }
}

@media (max-width: 413px) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 13px 15px 20px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 10px 34px 10px 24px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 10px 30px;
  }
}

.AppointmentDetail {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  border-left: 8px solid #199daf;
  /* width: 695px; */
  z-index: 1;
  position: relative;
}
.AppointmentDetail.pending {
  border-left-color: #B2BCC4;
}
.AppointmentDetail.confirmed {
  border-left-color: #199daf;
}
.AppointmentDetail.cancelled {
  border-left-color: #f37184;
}
.AppointmentDetail.completed {
  border-left-color: #f1f1f1;
}
.AppointmentDetail.cancelled .PatientDetails .DateTime {
  text-decoration: line-through;
}
.AppointmentDetail .DateTime {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 20px;
}
.AppointmentDetail .PatientDetails {
  padding: 17px 20px 18px 30px;
  width: 100%;
}
.AppointmentDetail .Patientinner-Details {
  display: flex;
  margin-top: 8px;
}
.AppointmentDetail .PatientDetails .Patient-name SPAN {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Medium';
}
.AppointmentDetail .PatientDetails-content {
  display: flex;
  justify-content: space-between;
}
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-address {
  padding: 20px 0;
}
.AppointmentDetail .Patient-data {
  padding: 14px 0 3px;
}
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-address SPAN,
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-data SPAN {
  font-size: 16px;
  line-height: 1.25;
  color: #72787d;
}
.AppointmentDetail .Patient-information {
  border-right: solid 1px #ededed;
  padding-right: 22px;
  width: 51%;
  min-width: 260px;
}
.AppointmentDetail .Patient-information SPAN {
  display: inline-block;
  width: 100%;
}
.AppointmentDetail .PatientDetails .Patient-name .link-span,
.AppointmentDetail .PatientDetails .Patient-address .link-span,
.AppointmentDetail .PatientDetails .Patient-data .link-span,
.AppointmentDetail .Patient-symptoms .link-span,
.AppointmentDetail .Patient-status .notes .link-span {
  font-size: 16px;
  line-height: 1.25;
  color: #26acda !important;
  cursor: pointer;
}
.AppointmentDetail .Patient-status .doctor-item {
  display: flex;
}
.AppointmentDetail .Patient-status .doctor-item > IMG {
  width: 27px;
  height: 41px;
  border-radius: 2.5px;
  border: solid 0.6px rgba(1, 54, 72, 0.2);
  margin-right: 15px;
  object-fit: cover;
}
.AppointmentDetail .Patient-status .doctor-item .doctor-info .doctor-name,
.AppointmentDetail .Patient-status .doctor-item .doctor-info .doctor-name SPAN,
.AppointmentDetail .Patient-status .doctor-item .doctor-info > SPAN {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}
.AppointmentDetail .Patient-status .doctor-item .doctor-info > SPAN {
  width: 100%;
  display: inline-block;
}
.AppointmentDetail .Patient-status .doctor-item .doctor-info .doctor-name + SPAN {
  vertical-align: top;
}
.AppointmentDetail .Patient-status {
  /* width: 47%; */
  padding-left: 30px;
}
.AppointmentDetail .Patient-location {
  padding: 28px 0 30px;
}
.AppointmentDetail .Patient-location,
.AppointmentDetail .Patient-Appointment {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  padding-left: 45px;
  position: relative;
}
.AppointmentDetail .Patient-location IMG,
.AppointmentDetail .Patient-Appointment IMG {
  position: absolute;
  left: 7px;
}
.AppointmentDetail .Patient-status-control {
  margin-top: 28px;
}
.AppointmentDetail .cancelled-time {
  font-size: 16px;
  line-height: 1.25;
  color: #b2bcc4;
  margin-bottom: 18px;
}
.AppointmentDetail .Patient-status-control .cancelled-by {
  border-radius: 4px;
  background-color: #f7f9f9;
  font-size: 18px;
  line-height: 38px;
  color: #013648;
  padding: 2px 15px 0px 15px;
  white-space: nowrap;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group {
  height: 38px;
  background-color: #f7f9f9;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group SELECT {
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  background-color: transparent;
  padding-left: 15px;
  font-size: 20px;
  line-height: 2.2;
  letter-spacing: normal;
  height: 38px;
  width: 100%;
  padding-right: 40px;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group .arrow:after {
  content: '';
  width: 1px;
  height: 38px;
  background-color: rgba(1, 54, 72, 0.2);
  position: absolute;
  left: -17px;
  top: -23px;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group:after {
  display: none;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group .arrow {
  top: 16px;
  right: 8px;
  border-top-color: #8f92a1;
  opacity: 0.8;
  border-top-width: 7px;
}
.AppointmentDetail .Patient-symptoms {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #f7f9f9;
  padding: 25px;
  max-width: 232px;
  min-width: 232px;
}
.AppointmentDetail .Patient-symptoms .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 14px;
  display: inline-block;
  width: 100%;
}
.AppointmentDetail .Questionnaire {
  margin-bottom: 35px;
}
.AppointmentDetail .Questionnaire SPAN {
  font-size: 16px;
  line-height: 1.5;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.AppointmentDetail .Questionnaire SPAN.h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-family: 'Calibre Medium';
  line-height: 1.44;
}
.AppointmentDetail .Patient-symptoms A {
  font-size: 16px;
  line-height: 1.25;
  color: #26acda;
}
.AppointmentDetail .Patient-status .notes A {
  width: 166px;
  height: 20px;
  font-size: 16px;
  line-height: 1.25;
  color: #26acda;
}
.AppointmentDetail .Patient-status-control SELECT {
  font-size: 18px;
  color: #013648;
}
.AppointmentDetail .appointment-btn {
  margin-bottom: 16px;
}
.AppointmentDetail .appointment-btn SPAN {
  width: 97px;
  height: 25px;
  border-radius: 12.3px;
  background-color: #cce3eac9;
  display: inline-block;
  text-align: left;
  position: relative;
  padding-left: 31px;
  font-family: 'Calibre Medium';
  font-size: 14.4px;
  line-height: 30px;
  color: #013648;
  letter-spacing: -0.27px;
}
.AppointmentDetail .appointment-btn SPAN.video {
  width: 107px;
  background-color: #eeebf5;
  color: #8d77ba;
}
.AppointmentDetail .appointment-btn SPAN IMG {
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
}
.AppointmentDetail .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  margin-bottom: 31px;
  display: inline-block;
}
@media (hover: hover) {
  .AppointmentDetail .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1366px) {
  .AppointmentDetail .Patient-information {
    min-width: auto;
  }
  .AppointmentDetail .Patient-status {
    padding-left: 20px;
  }
  .AppointmentDetail .Patient-symptoms {
    min-width: 178px;
    width: 178px;
  }
  .AfterAuthDashboard .panel-right {
    width: 250px;
    min-width: auto;
  }
}
@media (max-width: 1200px) {
  .AppointmentDetail .Patient-symptoms {
    max-width: 200px;
    width: 200px;
    padding: 25px 10px;
  }
  .AppointmentDetail .PatientDetails-content .Patient-information .Patient-address SPAN,
  .AppointmentDetail .PatientDetails-content .Patient-information .Patient-data SPAN {
    font-size: 14px;
  }
  .AppointmentDetail .Questionnaire SPAN {
    font-size: 14px;
  }
  .AppointmentDetail .Questionnaire SPAN.h3 {
    font-size: 16px;
  }
  .AppointmentDetail .Patient-symptoms {
    min-width: auto;
  }
}

@media (max-width: 1024px) {
  .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
  .AppointmentDetail .Patient-symptoms {
    max-width: 100%;
    min-width: 100%;
    border-top-right-radius: 0px;
  }
}

@media (max-width: 767px) {
  .AppointmentDetail .PatientDetails,
  .AppointmentDetail .Patient-symptoms {
    padding-left: 15px;
  }
}

.TimeManual {
  position: relative;
}
.TimeManual .result {
  width: 100%;
  min-width: 120px;
  max-height: 191px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 62px;
  padding: 2px 10px;
  z-index: 8;
}
.TimeManual .result .option b {
  color: #3dbce7;
}
.TimeManual .result .option {
  font-size: 18px;
  line-height: 1.95;
  color: #013648;
  cursor: pointer;
}
.TimeManual .Overlay {
  z-index: 1;
}
@media (hover: hover) {
  .TimeManual .result .option:hover {
    color: #3dbce7;
  }
}
.ProviderReschedule {
  background-color: white;
  height: auto;
  width: 50vw;
  padding: 0px;
  
}
.ProviderReschedule .days-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.ProviderReschedule .days-checkbox-container .Select-days {
  width: 100%;
  height: 34px;
  border-radius: 9px;
  border: solid 0.9px #e2e2ea;
  display: flex;
  overflow: hidden;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control {
  flex: 1 1;
  position: relative;
  padding-left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control INPUT {
  width: 100%;
  height: 100%;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control .checkmark {
  width: 100%;
  height: 100%;
  border: none;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control .checkmark:after {
  display: none;
}
.ProviderReschedule .days-checkbox-container .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(0, 17, 51, 0.2);
}
.ProviderReschedule .select-event-day-content {
  display: flex;
}
.ProviderReschedule .select-event-day-content .Select-control.form-group SELECT {
  padding-right: 38px;
}
.ProviderReschedule .select-event-day-content .Select-control.form-group SELECT OPTION {
  font-family: sans-serif;
  font-size: 14px;
}
.ProviderReschedule .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.ProviderReschedule .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.ProviderReschedule .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0;
}
.ProviderReschedule .dailog-close-btn IMG {
  height: 100%;
  width: 100%;
}
.ProviderReschedule .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.ProviderReschedule .dailog-body {
  padding: 20px 32px 15px;
  /* min-height: 500px; */
}
.ProviderReschedule .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.ProviderReschedule .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.ProviderReschedule .virtual-field .Checkbox-control {
  font-size: 18px;
}
.ProviderReschedule .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.ProviderReschedule .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.ProviderReschedule .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.ProviderReschedule .action-btn .error {
  color: red;
  font-size: 16px;
  display: block;
  text-align: left;
  width: 100%;
}
.ProviderReschedule .action-btn {
  text-align: right;
}
.ProviderReschedule .dailog-body .Input-control .form-control {
  border: 0;
  padding-left: 50px;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.ProviderReschedule .select-date-content {
  padding-left: 42px;
  position: relative;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 34px;
  line-height: 1.8;
  z-index: 100;
  display: flex;
}
.ProviderReschedule .time-padding {
  padding: 8px 8px 5px 0;
  margin-right: 10px;
}
.ProviderReschedule span.small-label {
  display: block;
  line-height: 0.3;
  color: #a29d9dbf;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 5px;
}
.ProviderReschedule .gray-box {
  position: relative;
  z-index: 1;
}
.ProviderReschedule .gray-box .gray-back {
  background: #e9ebf2;
  padding: 8px 12px 5px 9px;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  position: absolute;
  left: -8px;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
  z-index: -1;
}
.ProviderReschedule .select-date-content .icon {
  position: absolute;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
}
.ProviderReschedule .select-date-content .icon IMG {
  width: 100%;
}
.ProviderReschedule .select-time .TimeManual .result {
  top: 33px;
}
.ProviderReschedule .select-date-content .select-time {
  padding-left: 0px;
  padding-right: 5px;
  font-weight: 700;
  display: inline-flex;
  width: 250px;
  position: relative;
  top: -2px;
}
.ProviderReschedule .time-devider {
  display: inline-block;
  padding: 0 5px;
  padding-top: 9px;
}
.ProviderReschedule .TimeManual .Input-control {
  margin-bottom: 0;
}
.ProviderReschedule .TimeManual .Input-control INPUT.form-control {
  display: inline-flex;
  padding-left: 10px;
  border: 1px solid black;
  height: 30px;
  padding-right: 10px;
}
.ProviderReschedule .end-date {
  /* color: rgba(1, 54, 72, 0.5);
  min-width: 140px; */
  margin-left: 15px;
}
.ProviderReschedule .select-picker-content {
  position: relative;
  display: inline-block;
  min-width: 135px;
}
.ProviderReschedule .select-picker-content .Datepicker-content {
  position: absolute;
  top: 35px;
  z-index: 99;
  /* height: 200px; */
  overflow: auto;
  padding-bottom: 10px;
  background-color: #FFF;
}
.ProviderReschedule .Datepicker-content.time.end-time {
  position: absolute;
}
.ProviderReschedule .datepicker-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.ProviderReschedule .location {
  position: relative;
  padding-left: 41px;
}
.ProviderReschedule .dailog-body .location .Select-control {
  width: 100%;
  background-color: #f1f2f2;
}
.ProviderReschedule .location .icon {
  position: absolute;
  left: 1px;
  top: 4px;
}
.ProviderReschedule .dailog-body .location .Select-control SELECT {
  padding-left: 18px;
  height: 37px;
}
.ProviderReschedule .dailog-body .location .Select-control SELECT OPTION {
  font-family: sans-serif;
  font-size: 14px;
}
.ProviderReschedule .dailog-body .location .Select-control:after {
  display: none;
}
.ProviderReschedule .dailog-body .location .Select-control .arrow {
  border: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 6px;
  right: 10px;
}
.ProviderReschedule .dropdown {
  position: relative;
}
.ProviderReschedule .dropdown .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all linear;
  z-index: 9;
  background-color: #ffffff;
  min-width: 300px;
}
.ProviderReschedule .dropdown .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.ProviderReschedule .dropdown .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
  min-width: 300px;
  z-index: 101;
}
.ProviderReschedule .virtual-field {
  cursor: pointer;
}
.ProviderReschedule .virtual-field .Checkbox-control {
  padding-left: 48px;
}
.ProviderReschedule .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.ProviderReschedule .disabled .Checkbox-control {
  color: rgba(1, 54, 72, 0.29);
}
.ProviderReschedule .disabled .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(1, 54, 72, 0.06);
}
.ProviderReschedule .select-event-day-content IMG {
  position: relative;
  top: 6px;
}
.ProviderReschedule .disabled .Select-control,
.ProviderReschedule .disabled IMG {
  opacity: 0.29;
}
.ProviderReschedule .cross-icon IMG {
  width: 100%;
}
@media (hover: hover) {
  .ProviderReschedule .dropdown .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
.ProviderReschedule .MyDatetime .rdtPicker{
width: 320px;
padding-top: 35px;
}
.ProviderReschedule  .MyDatetime.days .rdtPicker td{
  line-height: 26px;
  }
  .ProviderReschedule .MyDatetime .rdtPicker .rdtDays table{
    border-spacing: 23px 5px;
  }
  .ProviderReschedule .MyDatetime.days .rdtPicker th.rdtNext, 
  .ProviderReschedule .MyDatetime.days .rdtPicker th.rdtPrev{
  top: 10px;
  }
  .ProviderReschedule .MyDatetime.days .rdtPicker .rdtSwitch:after{
    width: 50%;
  }
  .ProviderReschedule .MyDatetime.days .rdtPicker .rdtSwitch{
    left: 24px;
    top: 22px;
  }
@media (max-width: 767px) {
  .ProviderReschedule .select-date-content,
  .ProviderReschedule .select-event-day-content {
    flex-wrap: wrap;
  }
  .ProviderReschedule .select-picker-content {
    margin-bottom: 15px;
  }
  .ProviderReschedule .dailog-body .Select-control {
    margin-bottom: 15px;
  }
  .ProviderReschedule .select-event-day-content {
    margin-bottom: 34px;
  }
  .ProviderReschedule .select-event-day-content .Select-control {
    width: 226px;
  }
  .ProviderReschedule .select-event-day-content .Select-control SELECT {
    padding-left: 20px;
  }
  .ProviderReschedule .select-picker-content.end-date {
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .ProviderReschedule {
    overflow: scroll;
  }
  .ProviderReschedule .select-picker-content .Datepicker-content{
    position: unset;
    top: 0px;
    left: 0 !important;

  }
  .MyDatetime .rdtPicker {
    position: relative;
  }
  .ProviderReschedule {
    width: 100%;
    height:90vh;
    overflow: scroll;
  }
}

@media (max-width: 545px) {
  .ProviderReschedule .select-picker-content.end-date {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
@media (max-width: 375px) {
  .ProviderReschedule .select-event-day-content .Select-control {
    width: 200px;
  }
  .ProviderReschedule .dailog-body {
    padding: 20px 15px 15px;
  }
  .ProviderReschedule .dailog-header {
    padding: 22px 15px 5px 15px;
  }
  .ProviderReschedule .dailog-close-btn{
    margin-left: 10px;
  }
  .ProviderReschedule .cross-icon {
    right: 15px;
  }
}

.ViewPatientOverlayModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
}
.ViewPatientOverlayModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.ViewPatientOverlayModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ViewPatientOverlayModal .modal-content-header .close IMG {
  width: 100%;
}
.ViewPatientOverlayModal .modal-content-header .heading3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.ViewPatientOverlayModal .modal-content-body {
  padding: 30px 33px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ViewPatientOverlayModal .modal-content-body .InfiniteScroll {
  max-height: calc(100vh - 244px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.ViewPatientOverlayModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.ViewPatientOverlayModal .patient-info .link {
  color: #26acda;
  cursor: pointer;
  margin-top: 16px;
}
.ViewPatientOverlayModal .insurance-info .link-main {
  margin-top: 16px;
}
.ViewPatientOverlayModal .insurance-info .link {
  color: #26acda;
}
.ViewPatientOverlayModal .patient-info {
  margin-right: 60px;
}
.ViewPatientOverlayModal .patient-info,
.ViewPatientOverlayModal .insurance-info {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  min-width: 230px;
  width: 230px;
}
.ViewPatientOverlayModal .patient-details {
  display: flex;
}
.ViewPatientOverlayModal .patient-counts-details {
  display: flex;
  justify-content: center;
  margin-top: 33px;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.ViewPatientOverlayModal .patient-counts-details .box {
  border-radius: 4px;
  background-color: #f5f5f2;
  padding: 8px 3px;
  text-align: center;
  width: 133px;
}
.ViewPatientOverlayModal .patient-counts-details .box .number {
  font-family: 'Calibre Medium';
  font-size: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #013648;
  display: inline-block;
  margin-bottom: 9px;
  margin-top: 12px;
}
.ViewPatientOverlayModal .patient-counts-details .box .text,
.ViewPatientOverlayModal .patient-counts-details .box .subtext {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #72787d;
  line-height: 1.25;
}
.ViewPatientOverlayModal .patient-appointment-head {
  justify-content: space-between;
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2ea;
  margin-top: 30px;
}
.ViewPatientOverlayModal .patient-appointment-head .title {
  font-size: 20px;
  letter-spacing: 0.17px;
  color: #013648;
  display: inline-block;
  margin: auto 0;
}
.ViewPatientOverlayModal .patient-appointment-head .title SPAN {
  display: inline-block;
  color: #b2bcc4;
}
.ViewPatientOverlayModal .patient-appointment-head .Select-control {
  margin-bottom: 0;
  display: inline-block;
}
.ViewPatientOverlayModal .patient-appointment-head .Select-control .active-dropdown {
  width: 133px;
  height: 40px;
  transform: rotate(-360deg);
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-left: 20px;
  line-height: 41px;
  padding-right: 30px;
  padding-top: 1px;
  color: #44444f;
}
.ViewPatientOverlayModal .patient-appointment-head .Select-control .arrow {
  position: absolute;
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 17px;
  right: 15px;
  z-index: 1;
}
.ViewPatientOverlayModal .patient-appointment-dtails {
  padding-top: 16px;
}
.ViewPatientOverlayModal .AppointmentDetail {
  margin-bottom: 15px;
}
.ViewPatientOverlayModal .AppointmentDetail .PatientDetails {
  padding-top: 17px;
  padding-left: 25px;
}
.ViewPatientOverlayModal .AppointmentDetail .DateTime {
  font-size: 20px;
  margin-bottom: 13px;
}
.ViewPatientOverlayModal .AppointmentDetail .PatientDetails .Patient-name SPAN {
  font-size: 16px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-name A,
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-data A {
  display: none;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-address {
  padding-bottom: 10px;
  padding-top: 8px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-address SPAN,
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-data SPAN,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name SPAN,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info > SPAN,
.ViewPatientOverlayModal .AppointmentDetail .Patient-location {
  line-height: 1.13;
}
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name SPAN,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info > SPAN {
  font-size: 16px;
}

.ViewPatientOverlayModal .AppointmentDetail .Patient-information {
  width: 225px;
  padding-right: 10px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status {
  padding-left: 10px;
  min-width: 225px;
  width: 225px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-Appointment {
  display: none;
}
.ViewPatientOverlayModal .AppointmentDetail .doctor-item > IMG {
  width: 24px;
  height: 37px;
  margin-right: 16px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-location IMG {
  height: 14px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-location SPAN {
  padding-left: 24px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms {
  width: 185px;
  min-width: 185px;
  padding-right: 5px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms > .heading3 {
  font-size: 18px;
  margin-bottom: 14px;
  display: inline-block;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms .Questionnaire .heading3 {
  line-height: 1.54;
  margin-bottom: 0;
  font-size: 16px;
  font-family: 'Calibre Medium';
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-address > SPAN {
  display: inline-block;
  margin-bottom: 8px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group SELECT,
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group .arrow:after,
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group {
  height: 34px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group SELECT {
  padding-right: 34px;
  font-size: 18px;
  color: #013648;
  line-height: 1.7;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group .arrow {
  border-width: 6px;
  border-top-color: #8f92a1;
  opacity: 0.8;
  border-top-width: 7px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group {
  width: 207px;
  margin-bottom: 0;
}
.ViewPatientOverlayModal .AppointmentDetail .Questionnaire {
  margin-bottom: 21px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-location {
  align-items: flex-start;
  padding-bottom: 51px;
}
.ViewPatientOverlayModal .AppointmentDetail .Questionnaire SPAN {
  line-height: 1.31;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms A {
  font-size: 15px;
}

@media (hover: hover) {
  .ViewPatientOverlayModal .patient-info .link:hover,
  .ViewPatientOverlayModal .insurance-info .link:hover {
    opacity: 0.8;
  }
}

@media (max-width: 1200px) {
  .ViewPatientOverlayModal .AppointmentDetail .Patientinner-Details {
    flex-wrap: nowrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information {
    border-right: solid 1px #ededed;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .ViewPatientOverlayModal .AppointmentDetail .Patientinner-Details {
    flex-wrap: nowrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information {
    border-right: solid 1px #ededed;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    margin-top: 0;
  }
  .ViewPatientOverlayModal .AppointmentDetail .PatientDetails-content {
    flex-wrap: nowrap;
  }
  .ViewPatientOverlayModal {
    width: 730px;
  }
  .ViewPatientOverlayModal .modal-content-body {
    padding: 30px 17px;
  }
}
@media (max-width: 767px) {
  .ViewPatientOverlayModal {
    width: 100%;
  }
  .ViewPatientOverlayModal .patient-details {
    flex-wrap: wrap;
  }
  .ViewPatientOverlayModal .patient-counts-details {
    flex-wrap: wrap;
    justify-content: unset;
  }
  .ViewPatientOverlayModal .patient-info {
    margin-bottom: 15px;
  }
  .ViewPatientOverlayModal .patient-counts-details .box {
    margin-bottom: 15px;
    width: 32%;
    margin: 0 3px 10px;
  }
  .ViewPatientOverlayModal .patient-info,
  .ViewPatientOverlayModal .insurance-info {
    width: 50%;
    margin-right: 0;
    padding-right: 10px;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-location {
    padding-bottom: 10px;
  }
  .ViewPatientOverlayModal .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms {
    width: 100%;
    padding-left: 15px;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information,
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    min-width: 50%;
    width: 50%;
  }
  .ViewPatientOverlayModal .AppointmentDetail .PatientDetails {
    padding-left: 15px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .ViewPatientOverlayModal {
    width: 100%;
  }
  .ViewPatientOverlayModal .modal-content-body {
    padding: 20px;
  }
  .ViewPatientOverlayModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .ViewPatientOverlayModal .modal-content-header .heading3 {
    font-size: 24px;
  }
  .ViewPatientOverlayModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .ViewPatientOverlayModal .patient-counts-details .box {
    width: 48%;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information {
    border-right: 0;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 20px;
  }

  .ViewPatientOverlayModal .AppointmentDetail .Patient-information,
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    min-width: 100%;
    width: 100%;
  }
}
@media (max-width: 414px) {
  .ViewPatientOverlayModal .modal-content-body {
    padding: 15px;
  }
  .ViewPatientOverlayModal .patient-counts-details .box {
    width: 100%;
  }
  .ViewPatientOverlayModal .patient-appointment-head {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .ViewPatientOverlayModal .patient-appointment-head .title {
    width: 100%;
    margin-top: 5px;
  }
  .ViewPatientOverlayModal .patient-appointment-head .Select-control {
    margin-left: auto;
  }
}

.ChatTab .chat-details {
  padding: 13px 23px 30px 24px;
  height: calc(100vh - 349px);
  overflow-y: auto;
}
.ChatTab .message-box {
  margin-bottom: 38px;
}
.ChatTab .message-box .time-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: -0.2px;
  color: #013648;
  display: flex;
}
.ChatTab .message-box .time-name .time {
  font-size: 16px;
  line-height: normal;
  font-family: 'Calibre Regular';
  font-weight: normal;
  letter-spacing: normal;
  color: #a2a2a2;
}
.ChatTab .btn-send-msg {
  background: transparent;
  outline: 0;
  border: 0;
  box-shadow: none;
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 0;
  padding: 0px;
  cursor: pointer;
}
.ChatTab .btn-send-msg IMG {
  width: 100%;
  height: 100%;
}
.ChatTab .outgoing-msg .time-name .time {
  margin-left: 10px;
  order: 2;
}
.ChatTab .outgoing-msg .time-name .name {
  order: 1;
}
.ChatTab .incoming-msg {
  text-align: right;
}
.ChatTab .incoming-msg .file-box {
  margin-left: auto;
}
.ChatTab .incoming-msg .time-name {
  justify-content: flex-end;
}
.ChatTab .incoming-msg .time-name .time {
  margin-right: 10px;
  order: 1;
}
.ChatTab .incoming-msg .time-name .name {
  order: 2;
}
.ChatTab .message-box .msg {
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #013648;
}
.ChatTab .upcoming-appoinment {
  padding: 16px 34px 32px 24px;
  border-top: 1px solid #f1f1f5;
}
.ChatTab .appoinment-heading {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: -0.2px;
  color: #013648;
  padding-left: 31px;
  padding-right: 20px;
  position: relative;
}
.ChatTab .appoinment-heading SPAN {
  display: block;
}
.ChatTab .appoinment-heading .schedule-icon {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  top: 4px;
}
.ChatTab .appoinment-heading .down-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  height: 9px;
  top: 10px;
}
.ChatTab .appoinment-detail {
  padding-top: 15px;
  display: none;
}
.ChatTab .appoinment-detail.show-appoinment {
  display: block;
}
.ChatTab .appname-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.ChatTab .appname-block .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 1.25;
  color: #013648;
  width: 100%;
  display: block;
}
.ChatTab .appname-block .patient-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #26acda;
}
.ChatTab .appname-block .appname-left {
  flex: 1 1;
}
.ChatTab .appname-block .appoinment-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #013648;
}
.ChatTab .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  display: inline-block;
}
.ChatTab .noroom-txt {
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: #a2a2a2;
}
.ChatTab .link-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ChatTab .file-box {
  width: 262px;
  max-width: 100%;
  padding: 14px 17px 10px;
  display: flex;
  border-radius: 4px;
  background-color: #fbfbf9;
}
.ChatTab .file-box .file-img {
  width: 47px;
  height: 57px;
}
.ChatTab .file-box .file-img IMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ChatTab .file-box .file-detail {
  padding-left: 15px;
  flex: 1 1;
  text-align: left;
}
.ChatTab .file-box .file-name {
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: -0.2px;
  color: #282a2a;
}
.ChatTab .file-box .file-size {
  font-size: 16px;
  line-height: 1.06;
  letter-spacing: -0.2px;
  color: #282a2a;
}
.ChatTab .file-box .file-link {
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.18px;
  color: #26acda;
  display: inline-block;
  margin-top: 3px;
}
.ChatTab .typemsg-box {
  padding: 20px 23px 25px 24px;
  background-color: #fbfbf9;
}
.ChatTab .typemsg-box .typemsg-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 21px;
}
.ChatTab .typemsg-box .receiver-name {
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.18px;
  color: #013648;
}
.ChatTab .typemsg-box .msg-input {
  margin-bottom: 0;
  padding-right: 33px;
}
.ChatTab .typemsg-box .msg-input INPUT {
  border: 0;
  height: auto;
  padding: 0;
  border-radius: 0;
  background: transparent;
  font-size: 16px;
  letter-spacing: -0.18px;
}
.ChatTab .typemsg-box .msg-input INPUT.form-control::placeholder {
  color: #72787d;
}
.ChatTab .typemsg-box .msg-input.form-group .form-control:focus {
  border: 0;
}
.ChatTab .typemsg-box .msg-input INPUT:not(:placeholder-shown) {
  border: 0;
}
.ChatTab .typemsg-box .fileupload-input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #b2bcc4;
  background-color: #ffffff;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #72787d;
  width: 80px;
  height: 28px;
  padding-top: 5px;
}
.ChatTab .typemsg-box .fileupload-input IMG {
  width: 17px;
  height: 15px;
  margin-left: 6px;
  top: -3px;
  position: relative;
}
.ChatTab .typemsg-box .fileupload-input INPUT {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.ChatTab .patient-schedule {
  border-top: 1px solid #f1f1f5;
  padding: 21px 30px 32px;
  margin-top: 17px;
}
.ChatTab .patient-schedule-inner {
  position: relative;
  padding-left: 35px;
}
.ChatTab .patient-schedule .date-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: #013648;
}
.ChatTab .patient-schedule .time-duration {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.17px;
  color: #013648;
}
.ChatTab .patient-schedule .schedule-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}
.ChatTab .patient-sm-schedule {
  display: none;
}
.ChatTab .participant-video {
  display: none;
}
.ChatTab .message-info {
  padding: 5px 5px;
  margin-bottom: 7px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background-color: rgba(234, 244, 235, 0.3);
  color: #696969;
  font-size: 12px;
  text-align: center;
}
.ChatTab .msg-area {
  position: relative;
}
@media (hover: hover) {
  .ChatTab .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .ChatTab .appoinment-heading {
    font-size: 16px;
    padding-left: 28px;
  }
  .ChatTab .appoinment-heading .schedule-icon {
    top: 2px;
  }
  .ChatTab .appoinment-heading .down-arrow {
    top: 8px;
  }
  .ChatTab .noroom-txt {
    font-size: 16px;
  }
  .ChatTab .chat-details {
    padding: 15px 23px 15px 24px;
  }
  .ChatTab .upcoming-appoinment {
    padding: 10px 34px 20px 24px;
  }
  .ChatTab .file-box {
    padding: 14px 10px 10px;
  }
}

@media (max-width: 767px) {
  .ChatTab .typemsg-box .msg-input {
    margin-top: 6px;
  }
  .ChatTab .btn-send-msg {
    top: 4px;
  }
  .ChatTab .chat-details {
    padding: 18px 30px 15px;
  }
  .ChatTab .appoinment-heading {
    font-size: 18px;
    padding-left: 31px;
  }
  .ChatTab .message-box {
    margin-bottom: 65px;
  }
  .ChatTab .patient-schedule {
    margin-top: 0px;
    padding: 20px 25px 20px;
  }
  .ChatTab .appoinment-heading .schedule-icon {
    top: 4px;
  }
  .ChatTab .appoinment-heading .down-arrow {
    top: 10px;
  }
  .ChatTab .noroom-txt {
    font-size: 18px;
  }
  .ChatTab .upcoming-appoinment {
    padding: 13px 30px 20px;
    display: none;
  }
  .ChatTab .typemsg-box .receiver-name {
    display: none;
  }
  .ChatTab .message-box .time-name {
    font-size: 21.6px;
    letter-spacing: -0.24px;
  }
  .ChatTab .message-box .time-name .time {
    font-size: 19.2px;
  }
  .ChatTab .message-box .msg {
    font-size: 21.6px;
    letter-spacing: -0.24px;
  }
  .ChatTab .file-box {
    width: 314px;
    padding: 18px 20px 12px;
  }
  .ChatTab .upload-doc {
    display: none;
  }
  .ChatTab .msg-area {
    width: 100%;
  }
  .ChatTab .file-box .file-img {
    width: 56px;
    height: 68px;
  }
  .ChatTab .file-box .file-detail {
    padding-left: 18px;
  }
  .ChatTab .file-box .file-name {
    font-size: 19.2px;
    letter-spacing: -0.24px;
  }
  .ChatTab .file-box .file-size {
    font-size: 19.2px;
    letter-spacing: -0.24px;
  }
  .ChatTab .file-box .file-link {
    font-size: 19.2px;
    letter-spacing: -0.21px;
  }
  .ChatTab .typemsg-box {
    display: flex;
    padding: 31px 20px;
  }
  .ChatTab .typemsg-box .fileupload-input {
    height: 28px;
    width: 42px;
  }
  .ChatTab .typemsg-box .fileupload-input IMG {
    width: 19px;
    height: 16px;
    margin-left: 0px;
  }
  .ChatTab .typemsg-box .typemsg-top {
    order: 2;
    margin-left: 15px;
  }
  .ChatTab .sidebar-bottom .patient-schedule {
    display: none;
  }
  .ChatTab .patient-sm-schedule {
    display: block;
    background-color: rgba(234, 244, 235, 0.3);
  }
  .ChatTab .patient-sm-schedule .date-time {
    font-size: 20px;
    line-height: normal;
    letter-spacing: normal;
    color: #013648;
  }
  .ChatTab .patient-sm-schedule .time-duration {
    font-size: 20px;
    line-height: normal;
    letter-spacing: normal;
    color: #26acda;
  }
  .ChatTab .patient-sm-schedule .schedule-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .ChatTab .participant-video {
    width: 99px;
    height: 176px;
    border-radius: 4.8px;
    position: fixed;
    bottom: 189px;
    right: 24px;
    border: solid 0.5px #979797;
    background-color: #1d1b1b;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ChatTab .participant-video VIDEO {
    border-radius: 4.8px;
    width: 100%;
    height: 100%;
    background-color: #1d1b1b;
  }
}

@media (max-width: 413px) {
  .ChatTab .chat-details {
    padding: 20px 15px;
  }
  .ChatTab .upcoming-appoinment {
    padding: 13px 15px 20px;
  }
  .ChatTab .message-box {
    margin-bottom: 30px;
  }
  .ChatTab .patient-sm-schedule .date-time {
    display: block;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .ChatTab .typemsg-box {
    padding: 10px 23px 10px 24px;
  }
  .ChatTab .typemsg-box .typemsg-top {
    margin-bottom: 8px;
  }
  .ChatTab .chat-details {
    padding: 12px 23px 12px 24px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .ChatTab .chat-details {
    padding: 12px 30px;
  }
  .ChatTab .typemsg-box {
    padding: 10px 20px;
  }
  .ChatTab .participant-video {
    bottom: 100px;
  }
}

.VideoSidebar {
  background: #ffffff;
  height: calc(100vh - 70px);
  overflow: hidden;
}
.VideoSidebar .video-sidebar-top {
  background-color: rgba(234, 244, 235, 0.3);
  padding: 25px 24px 22px;
}
.VideoSidebar .video-sidebar-top .room-name {
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #013648;
}
.VideoSidebar .video-sidebar-top .name {
  font-size: 20px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #013648;
}
.VideoSidebar .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: flex;
  width: 100%;
}
.VideoSidebar .tab-list-item {
  font-size: 18px;
  line-height: 1.12;
  letter-spacing: -0.2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 33px 10px 17px;
  flex: 1 1;
  text-align: center;
  color: #72787d;
}
.VideoSidebar .tab-list-item .tab-icon {
  margin-right: 7px;
  width: 16px;
}
.VideoSidebar .tab-list-item.tab-list-active {
  color: #013648;
  position: relative;
}
.VideoSidebar .tab-list-item.tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #013648;
}

@media (max-width: 767px) {
  .VideoSidebar {
    height: calc(100vh - 108px);
  }
  .VideoSidebar .video-sidebar-top {
    display: none;
  }
  .VideoSidebar .tab-list {
    position: fixed;
    left: auto;
    right: 30px;
    top: 0;
    z-index: 8;
    width: auto;
    text-align: right;
    border: 0;
  }
  /* .VideoSidebar .tab-content {
    display: none;
  } */
  .VideoSidebar .tab-list-item {
    width: auto;
    padding: 47px 0 32px;
    font-size: 21.6px;
    letter-spacing: -0.24px;
  }
  .VideoSidebar .tab-list-item:not(:last-child) {
    margin-right: 20px;
  }
  .VideoSidebar .tab-list-item .tab-icon {
    margin-right: 8px;
    width: 20px;
  }
  .VideoSidebar .tab-list-item .tab-icon IMG {
    width: 100%;
  }
}
@media (max-width: 485px) {
  .VideoSidebar .tab-list {
   top: 28px;
  }
}
@media (max-width: 413px) {
  .VideoSidebar .tab-list {
    right: 15px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .VideoSidebar .video-sidebar-top {
    padding: 10px 24px 10px;
  }
  .VideoSidebar .tab-list-item {
    padding: 13px 10px 10px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .VideoSidebar .tab-list-item {
    padding: 47px 0 38px;
  }
}

.VideoPersonScreen .main-participant {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #013648;
}
.VideoPersonScreen .videoscreen-inner {
  position: relative;
  height: calc(100vh - 70px);
  background: #013648;
}
.VideoPersonScreen .video-small {
  width: 99px;
  height: 176px;
  border-radius: 4.8px;
  position: fixed;
  bottom: 189px;
  right: 24px;
  border: solid 0.5px #979797;
  background-color: #1d1b1b;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: auto;
  left: auto;
}
.VideoPersonScreen .video-small .video-bottom {
  display: none;
}
.VideoPersonScreen .videoscreen-full {
  position: relative;
  height: 100vh;
  background: #013648;
}
.VideoPersonScreen .video-hide {
  display: none;
}
.VideoPersonScreen .other-participant {
  width: 331px;
  height: 186px;
  position: absolute;
  top: 20px;
  right: 21px;
  border-radius: 9px;
  border: solid 0.5px #979797;
  background-color: #1d1b1b;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}
.VideoPersonScreen .other-participant.participant-hide {
  display: none;
}
.VideoPersonScreen .novideo-name {
  font-size: 36px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 0.89;
  letter-spacing: normal;
  color: #ffffff;
  position: absolute;
  top: 48.5%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translateY(-50%);
}
.VideoPersonScreen .other-participant IMG {
  width: 54px;
  height: 81px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  margin-bottom: 23px;
}
.VideoPersonScreen .other-participant .participant-name {
  font-size: 24px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}
.VideoPersonScreen .other-participant VIDEO {
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: #1d1b1b;
}
.VideoPersonScreen .video-bottom {
  position: absolute;
  bottom: 47px;
  max-width: 926px;
  width: 100%;
  padding: 21px 41px 24px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  border-radius: 12px;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  background-color: #ffffff;
  margin: 0 auto;
  animation-name: moveUp;
  animation-duration: 0.5s;
}
@keyframes moveUp {
  0% {
    bottom: 0px;
  }
  100% {
    bottom: 47px;
  }
}
.VideoPersonScreen .video-timer {
  animation-name: moveDown;
  animation-duration: 0.5s;
  transition: all 0.5s ease;
  opacity: 0;
}
@keyframes moveDown {
  0% {
    bottom: 47px;
  }
  100% {
    bottom: 0px;
  }
}
.VideoPersonScreen .video-action {
  display: flex;
  margin: 0 10px;
}
.VideoPersonScreen .video-scale {
  display: flex;
}
.VideoPersonScreen .video-action .middle-link {
  width: 54px;
  height: 54px;
  background: transparent;
  border: 0;
  outline: 0;
  border-radius: 100%;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
}
.VideoPersonScreen .video-action .middle-link IMG {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.VideoPersonScreen .video-action .middle-link:not(:last-child) {
  margin-right: 42px;
}
.VideoPersonScreen .video-bottom .scale-screen {
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  outline: 0;
  border: 0;
  width: 32px;
  height: 32px;
  background: transparent;
}
.VideoPersonScreen .video-bottom .scale-screen IMG {
  width: 100%;
  height: 100%;
}
.VideoPersonScreen .video-bottom .video-timing {
  display: flex;
  align-items: center;
}
.VideoPersonScreen .video-bottom .video-timing SPAN {
  font-size: 36px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 0.89;
  letter-spacing: normal;
  color: #013648;
  margin-top: 16px;
  position: relative;
  min-width: 100px;
}
.VideoPersonScreen .video-bottom .video-timing IMG {
  width: 22px;
  height: 22px;
  margin-right: 14px;
}
.VideoPersonScreen .video-sm-timing {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 125px;
}
.VideoPersonScreen .video-sm-timing IMG {
  width: 13px;
  height: 13px;
  margin-right: 7px;
  position: relative;
  top: -4px;
}
.VideoPersonScreen .video-sm-timing SPAN {
  font-size: 20.2px;
  font-family: 'Calibre Light';
  font-weight: 300;
  line-height: 0.89;
  letter-spacing: normal;
  color: #ffffff;
}
.VideoPersonScreen .video-disable {
  display: none;
}

@media (max-width: 1280px) {
  .VideoPersonScreen .video-bottom {
    max-width: 826px;
  }
}

@media (max-width: 1199px) {
  .VideoPersonScreen .video-bottom {
    max-width: 90%;
    padding: 21px 20px 24px;
  }
  .VideoPersonScreen .video-action .middle-link:not(:last-child) {
    margin-right: 20px;
  }
  .VideoPersonScreen .video-action .middle-link {
    width: 45px;
    height: 45px;
  }
  .VideoPersonScreen .video-bottom .video-timing IMG {
    margin-right: 8px;
  }
  .VideoPersonScreen .video-bottom .video-timing SPAN {
    font-size: 30px;
    margin-top: 14px;
  }
  .VideoPersonScreen .other-participant {
    width: 240px;
    height: 146px;
  }
  .VideoPersonScreen .other-participant IMG {
    margin-bottom: 13px;
  }
  .VideoPersonScreen .other-participant .participant-name {
    font-size: 20px;
  }
  .VideoPersonScreen .video-bottom .scale-screen {
    width: 29px;
    height: 29px;
  }
}

@media (max-width: 767px) {
  .VideoPersonScreen .video-bottom {
    max-width: 268px;
    justify-content: center;
    padding: 15px 30px 14px;
    bottom: 31px;
  }
  .VideoPersonScreen .video-bottom.video-hide {
    display: none;
  }
  .VideoPersonScreen .videoscreen-inner {
    height: auto;
    position: absolute;
    top: 108px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
  }
  .VideoPersonScreen .video-action .middle-link {
    width: 49px;
    height: 49px;
  }
  .VideoPersonScreen .video-action {
    margin: 0;
  }
  .VideoPersonScreen .video-action .middle-link:not(:last-child) {
    margin-right: 30px;
  }
  .VideoPersonScreen .other-participant IMG {
    width: 40px;
    height: 61px;
    margin-bottom: 0;
  }
  .VideoPersonScreen .other-participant {
    width: 99px;
    height: 178px;
    border-radius: 4.8px;
  }
  .VideoPersonScreen .other-participant VIDEO {
    border-radius: 4.8px;
  }
  .VideoPersonScreen .other-participant .participant-name {
    display: none;
  }
  .VideoPersonScreen .novideo-name {
    top: 44.5%;
    font-size: 15px;
  }
  .VideoPersonScreen .video-bottom .video-timing {
    display: none;
  }
  .VideoPersonScreen .video-scale {
    display: none;
  }
  .VideoPersonScreen .video-sm-timing {
    display: flex;
  }
  .VideoPersonScreen .video-sm-timing.video-hide {
    display: none;
  }
  @keyframes moveDown {
    0% {
      bottom: 31px;
    }
    100% {
      bottom: 0px;
    }
  }
  @keyframes moveUp {
    0% {
      bottom: 0px;
    }
    100% {
      bottom: 31px;
    }
  }
}

@media (max-width: 413px) {
  .VideoPersonScreen .novideo-name {
    font-size: 19px;
    padding: 0 15px;
  }
  .VideoPersonScreen .other-participant {
    height: 145px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .VideoPersonScreen .video-action .middle-link {
    width: 35px;
    height: 39px;
  }
  .VideoPersonScreen .video-action .middle-link:not(:last-child) {
    margin-right: 20px;
  }
  .VideoPersonScreen .video-bottom {
    max-width: 90%;
    padding: 7px 20px 7px;
    bottom: 10px;
  }
  .VideoPersonScreen .video-sm-timing {
    bottom: 69px;
  }
  .VideoPersonScreen .novideo-name {
    font-size: 28px;
    top: 63.5%;
  }
  @keyframes moveDown {
    0% {
      bottom: 10px;
    }
    100% {
      bottom: 0px;
    }
  }
  @keyframes moveUp {
    0% {
      bottom: 0px;
    }
    100% {
      bottom: 10px;
    }
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .VideoPersonScreen .other-participant {
    height: 118px;
  }
  .VideoPersonScreen .video-bottom {
    max-width: 210px;
  }
  .VideoPersonScreen .novideo-name {
    top: 53.5%;
    font-size: 15px;
  }
}

.VideoVisitBlock {
  display: block;
  width: 100%;
}
.VideoVisitBlock .video-visit-row {
  display: flex;
  flex-wrap: wrap;
}
.VideoVisitBlock .video-visit-row .video-left {
  flex: 1 1;
}
.VideoVisitBlock .video-visit-row .video-right {
  width: 320px;
}
.VideoVisitBlock .hide-sidebar {
  display: none;
}

@media (max-width: 1199px) {
  .VideoVisitBlock .video-visit-row .video-right {
    width: 270px;
  }
}

@media (max-width: 767px) {
  .VideoVisitBlock .video-visit-row .video-right {
    width: 100%;
  }
  .VideoVisitBlock .video-visit-row .video-left {
    flex: auto;
    width: 100%;
  }
}

.WaitingRoomBlock {
  background-color: #013648;
  padding: 97px 20px 45px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.WaitingRoomBlock .sub-heading {
  opacity: 0.6;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.13px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
}
.WaitingRoomBlock .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 38px;
}
.WaitingRoomBlock .doctor-img {
  width: 127px;
  height: 189px;
  display: block;
  margin: 0 auto 40px;
}
.WaitingRoomBlock .doctor-img IMG {
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.WaitingRoomBlock .detail-block {
  border-top: 1px solid rgba(241, 241, 245, 0.3);
  padding-top: 25px;
  text-align: center;
  max-width: 250px;
  width: 100%;
  margin: 0 auto 90px;
}
.WaitingRoomBlock .detail-heading {
  position: relative;
  font-size: 20px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: -0.22px;
  color: #ffffff;
  padding-left: 37px;
  margin-bottom: 16px;
  display: inline-block;
}
.WaitingRoomBlock .detail-heading .schedule-icon {
  position: absolute;
  left: 12px;
  top: 0;
  width: 18px;
  height: 18px;
}
.WaitingRoomBlock .appoinment-detail {
  text-align: center;
}
.WaitingRoomBlock .appoinment-detail SPAN {
  display: block;
}
.WaitingRoomBlock .appoinment-detail .name {
  opacity: 0.8;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #ffffff;
  margin-bottom: 4px;
}
.WaitingRoomBlock .appoinment-detail .date-time {
  opacity: 0.8;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.WaitingRoomBlock .appoinment-detail .time-duration {
  opacity: 0.8;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.17px;
  color: #ffffff;
}
.WaitingRoomBlock .sub-heading2 {
  opacity: 0.6;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.WaitingRoomBlock .waiting-bottom {
  margin-top: 145px;
}
.WaitingRoomBlock .waiting-bottom .para {
  opacity: 0.29;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
}
.WaitingRoomBlock .appoinment-link {
  opacity: 0.61;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  text-align: center;
  color: #ffffff;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .WaitingRoomBlock .heading2 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .WaitingRoomBlock {
    padding: 85px 30px 65px;
  }
  .WaitingRoomBlock .appoinment-wrapper {
    position: relative;
    padding-left: 100px;
    text-align: left;
    margin-bottom: 130px;
  }
  .WaitingRoomBlock .heading2 {
    font-size: 24px;
    line-height: 1.42;
    text-align: left;
    margin-bottom: 16px;
    font-family: 'Calibre Regular';
  }
  .WaitingRoomBlock .sub-heading {
    margin-bottom: 40px;
  }
  .WaitingRoomBlock .doctor-img {
    position: absolute;
    left: 0;
    top: -4px;
    width: 80px;
    height: 119px;
    margin: 0;
  }
  .WaitingRoomBlock .doctor-img IMG {
    border-radius: 2.5px;
    border: solid 0.6px #f1f1f1;
  }
  .WaitingRoomBlock .detail-block {
    max-width: 100%;
    text-align: left;
    margin: 0;
  }
  .WaitingRoomBlock .detail-heading {
    font-size: 16.4px;
    letter-spacing: -0.18px;
    margin-bottom: 20px;
    padding-left: 22px;
  }
  .WaitingRoomBlock .appoinment-detail {
    text-align: left;
  }
  .WaitingRoomBlock .sub-heading2 {
    max-width: 212px;
    width: 100%;
    margin: 0 auto;
  }
  .WaitingRoomBlock .detail-heading .schedule-icon {
    left: 0;
    top: -1px;
    width: 15px;
    height: 15px;
  }
  .WaitingRoomBlock .waiting-bottom {
    margin-top: 115px;
  }
  .WaitingRoomBlock .appoinment-detail .date-time {
    font-size: 15px;
  }
  .WaitingRoomBlock .appoinment-detail .time-duration {
    font-size: 15px;
  }
}

@media (max-width: 413px) {
  .WaitingRoomBlock {
    padding: 85px 15px 65px;
  }
  .WaitingRoomBlock .sub-heading {
    font-size: 21px;
  }
  .WaitingRoomBlock .sub-heading2 {
    font-size: 21px;
  }
}

.DoctorVideoExit {
  width: 100%;
  padding: 120px 20px;
}
.DoctorVideoExit .sub-heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 14px;
}
.DoctorVideoExit .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 77px;
}
.DoctorVideoExit .patient-list-block {
  max-width: 265px;
  width: 100%;
  margin: 0 auto;
}
.DoctorVideoExit .patient-list-block .patient-heading {
  font-size: 20px;
  line-height: 1.01;
  letter-spacing: -0.22px;
  color: #013648;
  margin-bottom: 29px;
  position: relative;
  padding-left: 29px;
}
.DoctorVideoExit .patient-list-block .patient-heading .person-img {
  width: 16px;
  height: 16px;
  position: absolute;
  top: -2px;
  left: 0;
}
.DoctorVideoExit .patient-list-block .appname-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
}
.DoctorVideoExit .appname-block .appname-left {
  flex: 1 1;
  margin-right: 10px;
}
.DoctorVideoExit .appname-block .appoinment-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #013648;
}
.DoctorVideoExit .appname-block .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 1.25;
  color: #013648;
  display: block;
}
.DoctorVideoExit .appname-block .patient-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #26acda;
  cursor: pointer;
}
.DoctorVideoExit .link-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.DoctorVideoExit .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  display: inline-block;
}
.DoctorVideoExit .rejoin-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #3d93a6;
  max-width: 135px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #3d93a6;
  display: inline-block;
}
.DoctorVideoExit .room-txt {
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #72787d;
}
.DoctorVideoExit .no-room-txt {
  float: right;
}
.DoctorVideoExit .patient-list:not(:last-child) {
  border-bottom: 1px solid #e7ebeb;
  padding-bottom: 29px;
  margin-bottom: 17px;
}
@media (hover: hover) {
  .DoctorVideoExit .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
  .DoctorVideoExit .rejoin-videolink:hover {
    background: #3d93a6;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .DoctorVideoExit {
    padding: 80px 20px;
  }
}

@media (max-width: 767px) {
  .DoctorVideoExit {
    padding: 45px 30px;
  }
  .DoctorVideoExit .sub-heading {
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 8px;
  }
  .DoctorVideoExit .heading2 {
    margin-bottom: 87px;
  }
  .DoctorVideoExit .patient-list-block .patient-heading {
    font-size: 22px;
    letter-spacing: -0.24px;
    margin-bottom: 40px;
  }
  .DoctorVideoExit .appname-block .patient-name {
    font-size: 19.8px;
    letter-spacing: 0.12px;
  }
  .DoctorVideoExit .appname-block .patient-link {
    font-size: 17.6px;
    letter-spacing: 0.11px;
  }
  .DoctorVideoExit .appname-block .appoinment-time {
    font-size: 22px;
    letter-spacing: 0.18px;
  }
  .DoctorVideoExit .rejoin-videolink {
    border-radius: 4.4px;
    font-size: 17.6px;
    letter-spacing: -0.23px;
    border: solid 1.1px #3d93a6;
    max-width: 146px;
    padding: 7px 10px 0px;
  }
  .DoctorVideoExit .start-videolink {
    border-radius: 4.4px;
    font-size: 17.6px;
    letter-spacing: -0.23px;
    border: solid 1.1px #8d77ba;
    max-width: 140px;
    padding: 7px 10px 0px;
  }
  .DoctorVideoExit .room-txt {
    font-size: 19.8px;
    letter-spacing: 0.17px;
  }
  .DoctorVideoExit .patient-list:not(:last-child) {
    margin-bottom: 44px;
    padding-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .DoctorVideoExit .patient-list-block {
    max-width: 100%;
  }
}

@media (max-width: 413px) {
  .DoctorVideoExit {
    padding: 45px 15px;
  }
  .DoctorVideoExit .heading2 {
    font-size: 32px;
  }
}

.PatientVideoExit {
  width: 100%;
  padding: 214px 20px 120px;
  text-align: center;
}
.PatientVideoExit .sub-heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 9px;
}
.PatientVideoExit .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 29px;
}
.PatientVideoExit .doctor-img {
  width: 100px;
  height: 150px;
  display: block;
  margin: 0 auto 28px;
}
.PatientVideoExit .doctor-img IMG {  
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.PatientVideoExit .rejoin-videolink {
  padding: 5px 10px 0px;
  border-radius: 4px;
  background-color: #3d93a6;
  border: solid 1px #3d93a6;
  max-width: 158px;
  width: 100%;
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: #ffffff; 
  display: inline-block;
  text-transform: capitalize;
}
@media (hover: hover) {
  .PatientVideoExit .rejoin-videolink:hover {
    background: transparent;
    color: #3d93a6;
  }
}

@media (max-width: 1199px) {
  .PatientVideoExit {
    padding: 170px 20px 120px;
  }
}

@media (max-width: 767px) {
  .PatientVideoExit {
    padding: 165px 30px 120px;
  }
  .PatientVideoExit .heading2 {
    margin-bottom: 69px;
  }
}

@media (max-width: 413px) {
  .PatientVideoExit {
    padding: 165px 15px 120px;
  }
  .PatientVideoExit .heading2 {
    font-size: 32px;
  }
  .PatientVideoExit .para {
    font-size: 16px;
  }
}
.PatientVideoClosed {
  width: 100%;
  padding: 214px 20px 120px;
  text-align: center;
}
.PatientVideoClosed .sub-heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 9px;
}
.PatientVideoClosed .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 29px;
}
.PatientVideoClosed .doctor-img {
  width: 100px;
  height: 150px;
  display: block;
  margin: 0 auto 32px;
}
.PatientVideoClosed .doctor-img IMG {  
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.PatientVideoClosed .para {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 6px;
}
.PatientVideoClosed .home-videolink {
  padding: 5px 10px 0px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 1px #013648;
  max-width: 158px;
  width: 100%;
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: #013648; 
  display: inline-block;
  text-transform: capitalize;
}
@media (hover: hover) {
  .PatientVideoClosed .home-videolink:hover {
    background: #013648;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .PatientVideoClosed {
    padding: 170px 20px 120px;
  }
}

@media (max-width: 767px) {
  .PatientVideoClosed {
    padding: 150px 30px 120px;
  }
  .PatientVideoClosed .heading2 {
    margin-bottom: 59px;
  }
}

@media (max-width: 413px) {
  .PatientVideoClosed {
    padding: 150px 15px 120px;
  }
  .PatientVideoClosed .heading2 {
    font-size: 32px;
  }
  .PatientVideoClosed .para {
    font-size: 16px;
  }
}

.gospin {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin:-60px 0 0 -60px;
    animation:spin 4s linear infinite;
}
@keyframes spin { 
    100% { 
        transform:rotate(360deg); 
    } 
}
.Introduction {
  padding-bottom: 30px;
}
.Introduction .intro-info .paragraph {
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
  margin-bottom: 36px;
}
.Introduction .intro-info .heading1 {
  font-size: 64px;
  line-height: 1.03;
  color: #013648;
  margin: 36px 0 43px;
  font-family: 'Heldane Display';
}
.Introduction .intro-info .CircleButton{
  font-family: 'Calibre Regular';
  font-size: 18px;
}
.Introduction .intro-info SPAN:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 14px;
  background-color: #344563;
  left: 0;
  top: 10px;
}
.Introduction .intro-info SPAN {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  font-family: 'Calibre Semibold';
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
}
.Introduction .intro-info {
  margin-top: 111px;
}
.Introduction .intro-banner {
  background-repeat: no-repeat;
  background-position: 100px 47px;
  width: calc(100% + 28px);
  padding-right: 28px;
  position: relative;
}

.Introduction .intro-banner IMG {
  margin-top: 40px;
  margin-left: 100px;
}
.Introduction .intro-inner-content {
  display: flex;
  flex-wrap: wrap;
}
.Introduction .intro-inner-content .col-left {
  width: 45%;
}
.Introduction .intro-inner-content .col-right {
  width: 55%;
}
/* .Introduction .shapemask {
  position: absolute;
  right: 0;
  bottom: -23px;
} */

@media (max-width: 1200px) {
  .Introduction .intro-banner {
    background-position: 0px 47px;
  }
  .Introduction .intro-banner IMG {
    margin-left: 89px;
    width: 84%;
  }
  .Introduction .intro-info .heading1 {
    font-size: 54px;
  }
  .Introduction .intro-banner {
    width: calc(98% + 28px);
  }
}
@media (max-width: 991px) {
  .Introduction .intro-info .heading1 {
    font-size: 42px;
  }
  .Introduction .intro-banner,
  .Introduction .intro-banner IMG {
    width: 100%;
  }
  .Introduction .intro-banner IMG {
    margin-left: 40px;
  }
  .Introduction .intro-banner {
    background-position: 34px 52px;
    background-size: 92%;
  }
}
@media (max-width: 767px) {
  .Introduction .intro-info SPAN {
    font-size: 18px;
    line-height: 1.22;
  }
  .Introduction .intro-inner-content .col-left {
    width: 100%;
  }
  .Introduction .intro-inner-content .col-right {
    width: 100%;
  }
  .Introduction .intro-banner IMG {
    width: 100%;
    margin-top: 63px;
    margin-left: 28px;
  }
  .Introduction .intro-banner {
    max-width: 406px;
    margin: auto;
    background-position: -8px 36px;
    background-size: 100%;
  }
  .Introduction .intro-info {
    margin-top: 40px;
  }
  .Introduction .intro-info .heading1 {
    margin: 40px 0;
    font-size: 48px;
    line-height: 1.17;
  }
  .Introduction .intro-info .paragraph {
    font-size: 20px;
    margin-bottom: 28px;
    line-height: 1.6;
  }
  .Introduction button.withus-btn {
    width: 244px;
  }
}

.BetterTools {
  padding: 85px 0 75px;
}
.BetterTools .tools-inner-content .title span {
  width: 390px;
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
  display: inline-block;
  margin-left: 86px;
  vertical-align: top;
}
.BetterTools .tools-inner-content {
  padding: 0 30px;
}
.BetterTools .tools-inner-content .title .heading2 {
  display: inline-block;
  width: 500px;
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Heldane Display';
}
.BetterTools .tools-inner-content .title {
  margin-bottom: 48px;
  padding: 0px 35px;
}

@media (max-width: 1200px) {
  .BetterTools .tools-inner-content .title .heading2 {
    font-size: 54px;
    width: 450px;
  }
  .BetterTools .tools-inner-content .title SPAN {
    width: 350px;
    margin-left: 25px;
  }
  .dashboard-content IMG {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .BetterTools {
    padding: 50px 0;
  }
  .BetterTools .tools-inner-content .title .heading2 {
    width: auto;
  }
  .BetterTools .tools-inner-content .title .heading2 {
    font-size: 42px;
    width: 335px;
  }
  .BetterTools .tools-inner-content .title {
    padding: 0px 0px;
  }
  .BetterTools .tools-inner-content .title SPAN {
    width: 300px;
    margin-top: 0px;
  }
}
@media (max-width: 767px) {
  .BetterTools .tools-inner-content .title .heading2 {
    width: 100%;
    font-size: 48px;
    line-height: 1.17;
    margin-bottom: 30px;
  }
  .BetterTools .tools-inner-content .title SPAN {
    width: 100%;
    margin-left: 0;
    font-size: 20px;
    line-height: 1.6;
    color: #013648;
  }
  .BetterTools .tools-inner-content {
    padding: 0;
  }
  .BetterTools {
    padding: 90px 0 59px;
  }
  .BetterTools .dashboard-content {
    margin-bottom: 28px;
  }
}

.ToolProcesses {
  padding: 0 40px;
}

.ToolProcesses .toolprocess-points .toolprocess-points-content {
  width: 32%;
  padding: 20px 24px 0;
  background-color: rgba(242, 150, 45, 0.1);
  border-radius: 4px;
  border: solid 1px #f2962d;
}
.ToolProcesses .toolprocess-points .toolprocess-points-content:nth-child(2) {
  border: solid 1px #26acda;
  background-color: rgba(38, 172, 218, 0.1);
}
.ToolProcesses .toolprocess-points .toolprocess-points-content:nth-child(3) {
  border: solid 1px #3d93a6;
  background-color: rgba(61, 147, 166, 0.1);
}
.ToolProcesses .toolprocess-points .toolprocess-points-content .paragraph {
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  margin-bottom: 32px;
  letter-spacing: -0.2px;
}
.ToolProcesses .toolprocess-points .toolprocess-points-content span {
  font-size: 36px;
  line-height: 1.56;
  color: #013648;
  font-family: 'Heldane Display';
  letter-spacing: -0.2px;
}
.ToolProcesses .toolprocess-points .toolprocess-points-content .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 0.2px;
  color: #013648;
  margin: 6px 0 4px;
  font-weight: 500;
}
.ToolProcesses .toolprocess-points {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .ToolProcesses {
    padding: 0 0px;
  }
}
@media (max-width: 767px) {  
  .ToolProcesses .toolprocess-points .toolprocess-points-content span {
    font-size: 48px;
  }
  .ToolProcesses .toolprocess-points .toolprocess-points-content .paragraph {
    font-size: 16px;
    line-height: 1.25;
    color: #013648;
  }
  .ToolProcesses .toolprocess-points .toolprocess-points-content {
    width: 100%;
    margin-bottom: 20px;
  }
  .ToolProcesses .toolprocess-points .toolprocess-points-content span {
    line-height: 1.6;
  }
}

.Taking {
  padding: 65px 0 90px;
}
.Taking .traking-points {
  padding: 0px 132px 0 189px;
  display: flex;
  flex-wrap: wrap;
}
.Taking .heading2 {
  font-size: 64px;
  line-height: 1.03;
  text-align: center;
  color: #013648;
  font-family: 'Heldane Display';
  max-width: 670px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Taking .traking-action {
  margin-top: 89px;
}
.Taking .traking-action .CircleButton.WhiteButton {
  width: 245px;
  font-family: 'Calibre Regular';
}
.Taking .Patient-Treat {
  margin-top: 125px;
  z-index: 1;
  position: relative;
}
.Taking .TakingPoint .Patient-Treat .h3 {
  font-family: 'Calibre Regular';
  font-size: 18px;  
  line-height: 0.85;
  letter-spacing: -0.23px;  
}
.Taking .Patient-Treat .pt-category-box span {
  width: 69px;
  height: 19px;
  border-radius: 2px;
  background-color: #3d93a6;
  display: inline-block;
  margin-right: 5px;
}
.Taking .Patient-Treat .pt-category-box .Herniated-box {
  width: 60px;
  background-color: #e85d72;
}
.Taking .Patient-Treat .pt-category-box .Sciatica-box {
  width: 40px;
  background-color: #8d77ba;
}
.Taking .Patient-Treat .pt-category-box .blue-box {
  width: 40px;
  background-color: #26acda;
}
.Taking .Patient-Treat .pt-category-box .Scoliosis-box {
  width: 40px;
  background-color: #f2962d;
}
.Taking .Patient-Treat .pt-category-name {
  width: 245px;
  margin-top: 8px;
}
.Taking .Patient-Treat .pt-category-name span {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.79;
  letter-spacing: -0.3px;
  color: #1aa2b4;
  display: inline-block;
  width: 50%;
  height: 29px;
  margin-bottom: 5px;
}
.Taking .Patient-Treat .pt-category-name .Herniated {
  color: #8874c9;
}
.Taking .Patient-Treat .pt-category-name .Sciatica {
  color: #f37184;
}
.Taking .Patient-Treat .pt-category-name .Scoliosis {
  color: #fca43d;
}

.Taking .TakingPoint {
  position: relative;
  width: 50%;
}
.Taking .TakingPoint .paragraph {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.Taking .TakingPoint .h3 {
  font-family: 'Calibre Medium';
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: 21px;
  margin-top: 230px;
  color: #013648;
}
.Taking .TakingPoint.patient .h3 {
  margin-top: 289px;
  z-index: 2;
}
.Taking .TakingPoint.fair .h3 {
  margin-top: 262px;
}
.Taking .TakingPoint.data .h3 {
  margin-top: 33px;
}
.Taking .TakingPoint.professional .h3 {
  margin-top: 262px;
}
.Taking .TakingPoint .images {
  position: absolute;
  top: 119px;
  left: 182px;
}
.Taking .TakingPoint.fair .images {
  position: absolute;
  top: 75px;
  left: 25px;
}
.Taking .TakingPoint.data .images {
  top: 80px;
  left: 20px;
}
.Taking .TakingPoint.professional .images {
  top: 49px;
  left: 75px;
}
.Taking .TakingPoint.professional .images img{
  width: 225px;
}
.Taking .TakingPoint.patient .images {
  left: 51px;
  top: 78px;
}
.Taking .TakingPoint .images .h3 {
  font-size: 103.7px;
  line-height: 0.76;
  letter-spacing: -2.07px;
  color: #013648;
  position: absolute;
  top: -250px;
  left: 80px;
  font-family: 'Heldane Display';
}
.Taking .TakingPoint .images .text {
  font-size: 35.2px;
  line-height: 1.42;
  color: #013648;
  position: absolute;
  top: 48px;
  font-family: 'Heldane Display';
  z-index: 1;
  left: 7px;
}
.Taking .TakingPoint.patient,
.Taking .TakingPoint.professional {
  padding-left: 59px;
}

.Taking .TakingPoint .rating-img {
  position: absolute;
  top: 22px;
  width: 178px;
  right: 40px;
}
.Taking .TakingPoint .rating-img img {
  max-width: 100%;
}

.Taking .fair .social-media-fair {
  position: absolute;
  left: -28px;
  top: 55px;
}
.Taking .TakingPoint .Patient-Treat .h3 {
  margin-bottom: 15px;
}
.Taking .TakingPoint.data .paragraph,
.Taking .TakingPoint.fair .paragraph {
  padding-right: 50px;
}
.Taking .TakingPoint.patient .social-media-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .Taking .heading2 {
    font-size: 54px;
  }
  .Taking .TakingPoint.data .paragraph,
  .Taking .TakingPoint.fair .paragraph {
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .Taking {
    padding: 50px 0;
  }
  .Taking .heading2 {
    font-size: 42px;
  }

  .Taking .traking-points {
    padding: 0;
  }
  .Taking .traking-action {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .Taking .Patient-Treat {
    margin-top: 52px;
  }
  .Taking .TakingPoint.fair {
    order: 1;
  }
  .Taking .TakingPoint.patient {
    order: 2;
  }
  .Taking .TakingPoint.data {
    order: 3;
  }
  .Taking .TakingPoint.professional {
    order: 4;
  }
  .Taking {
    padding: 95px 0 60px;
    border-top: solid 1px #e3e7ed;
  }
  .Taking .heading2 {
    text-align: left;
    font-size: 48px;
    line-height: 1.17;
  }
  .Taking .traking-action {
    /* margin-top: 35px; */
    text-align: left;
    margin-top: 36px;
    padding-top: 40px;
    border-top: 1px solid #e3e7ed;
  }
  .Taking .TakingPoint .paragraph {
    line-height: 1.25;
  }
  .Taking .TakingPoint .h3 {
    margin-top: 238px;
    font-size: 18px;
    margin-bottom: 17px;
  }

  .Taking .TakingPoint {
    width: 100%;
  }

  .Taking .TakingPoint.patient,
  .Taking .TakingPoint.professional {
    padding-left: 0;
  }
  .Taking .TakingPoint.data .h3 {
    margin-top: 26px;
  }
  .Taking .TakingPoint.professional .h3 {
    margin-top: 190px;
  }
  .Taking .TakingPoint.professional .images {
    top: 14px;
    left: 59px;
  }
  .Taking .TakingPoint.professional .images img {
    width: 195px;
  }
  .Taking .TakingPoint.patient .images {
    left: 87px;
    top: 12px;
  }
  .Taking .TakingPoint.data .images {
    top: 57px;
    left: 100px;
  }
  .Taking .TakingPoint.data .images img {
    width: 127px;
    height: 104px;
  }
  .Taking .TakingPoint .images {
    position: absolute;
    top: 63px;
    left: 182px;
  }
  .Taking .TakingPoint .images .h3 {
    top: -249px;
    font-size: 86.4px;
    line-height: 0.76;
    letter-spacing: -1.73px;
  }
  .Taking .TakingPoint .images .text {
    width: 78px;
    height: 75px;
    left: 14px;
    top: 37px;
  }
  .Taking .TakingPoint .images .text img {
    width: 100%;
  }
  .Taking .TakingPoint.fair .images {
    position: absolute;
    top: 73px;
    left: 30px;
  }
  .Taking .TakingPoint.patient .h3 {
    margin-top: 220px;
    line-height: 1.22;
    margin-bottom: 19px;
    left: 73px;
    top: -215px;
  }
  .Taking .TakingPoint.fair .h3 {
    margin-top: 250px;
  }
  .Taking .TakingPoint.patient .social-media-mobile {
    display: block;
    width: 110px;
    position: absolute;
    bottom: 7px;
    left: 14px;
  }
  .Taking .traking-action .CircleButton.WhiteButton {
    font-family: 'Calibre Medium';
  }
  .Taking .TakingPoint.patient .social-media-mobile{
    display: none;
  }
}
@media (max-width: 375px) {
  .Taking .TakingPoint.fair .images {
    left: 115px;
  }
  .Taking .TakingPoint .images {
    left: 160px;
  }
  .Taking .TakingPoint.data .images {
    left: 80px;
  }
  .Taking .TakingPoint.professional .images {
    left: 61px;
  }
  .Taking .TakingPoint.patient .images {
    left: 66px;
  }
}
@media (max-width: 360px) {
  .Taking .TakingPoint.fair .images {
    left: 30px;
  }
  .Taking .TakingPoint .images {
    left: 138px;
  }
  .Taking .TakingPoint.data .images {
    left: 60px;
  }
  .Taking .TakingPoint.professional .images {
    left: 36px;
  }
  .Taking .TakingPoint.patient .images {
    left: 55px;
  }
}

 .TeamCard .name {
  font-family: 'Heldane Display';
  font-size: 18px;
  line-height: 1.2;
  color: #013648;
  margin-top: 25px;
  width: 100%;
  font-weight: bold;
  margin-bottom: 5px;
}
.TeamCard .place {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: normal;
  color: #013648;
  width: 100%;
  margin-bottom: 5px;
}
.TeamCard .description {
  font-size: 16px;
  line-height: 1.25;
  color: rgba(1, 54, 72, 0.7);
  margin-top: 0;
  width: 100%;
}
.TeamCard {

  text-align: center;
  /* margin: 0 0 0 98px; */
  padding: 0 30px;
}

.TeamCard .team-img {
 
  display: flex;
}
.TeamCard .team-img img {
  /* margin: auto 0;
  max-width: 100%;
  width: 250px;
  height: 280px;
  object-fit: cover; */
  /* width: 100%;*/
  object-fit: cover; 
  height: 100%;
  margin: 0 auto;
  border-radius: 5px;
}

/*
 @media (max-width: 1446px) { 
  .TeamCard{
   margin: 0 20px 0 73px;
  }
 
}
@media (max-width: 1400px) { 
  .TeamCard{
    margin: 0 21px 0 68px;
  }
 
}
@media (max-width: 1100px) { 
  .TeamCard{
        margin: 0 14px 0 66px;
  }
 
}
@media (max-width: 1070px) { 
  .TeamCard{
        margin: 0 14px 0 63px;
  }
 
}
@media (max-width: 1046px) { 
  .TeamCard{
    margin: 0 0 0 54px;
  }
 
}
@media (max-width: 996px) { 
  .TeamCard{
    margin: 0 0 0 52px;
  }
 
}
@media (max-width: 990px) { 
  .TeamCard{
    margin: 0 0 0 49px;
  }
 
}
@media (max-width: 980px) { 
  .TeamCard{
    margin: 0 0 0 46px;
  }
 
}
@media (max-width: 970px) { 
  .TeamCard{
    margin: 0 0 0 46px;
  }
 
}
@media (max-width:966px) { 
  .TeamCard{
    margin: 0 0 0 43px;
  }
  
}
@media (max-width:964px) { 
  .TeamCard{
    margin: 0 0 0 42px;
  }
  
}
@media (max-width:956px) { 
  .TeamCard{
    margin: 0 0 0 40px;
  }
  
}
@media (max-width:950px) { 
  .TeamCard{
    width: 220px;
  }
  .TeamCard .team-img{
    width: 220px;
  }
}
@media (max-width:900px) { 
  .TeamCard{
    margin: 0 0 0 38px;
  }
  
}
@media (max-width:890px) { 
  .TeamCard{
    margin: 0 0 0 36px;
  }
  
}
@media (max-width:878px) { 
  .TeamCard{
    margin: 0 0 0 34px;
  }
  
}
@media (max-width:870px) { 
  .TeamCard{
    margin: 0 0 0 32px;
  }
  
}
@media (max-width:865px) { 
  .TeamCard{
    margin: 0 0 0 30px;
  }
  
}
@media (max-width:860px) { 
  .TeamCard{
    margin: 0 70px 0 70px;
  }
  
}
@media (max-width:767px) { 
  .TeamCard{
    margin: 0 58px 0 72px;
  }
  
}
@media (max-width:756px) { 
  .TeamCard{
    margin: 0 58px 0 66px;
  }
  
}
@media (max-width:740px) { 
  .TeamCard{
    margin: 0 55px 0 66px;
  }
  
}
@media (max-width:737px) { 
  .TeamCard{
    margin: 0 46px 0 66px;
  }
  
}
@media (max-width:729px) { 
  .TeamCard{
    margin: 0 4px 0 66px;
  }
  
}
@media (max-width:724px) { 
  .TeamCard{
    margin: 0 35px 0 66px;
  }
  
}
@media (max-width:717px) { 
  .TeamCard{
    margin: 0 35px 0 56px;
  }
  
}
@media (max-width:697px) { 
  .TeamCard{
    margin: 0 35px 0 54px;
  }
  
}
@media (max-width:693px) { 
  .TeamCard{
    margin: 0 35px 0 51px;
  }
  
}
@media (max-width:687px) { 
  .TeamCard{
    margin: 0 35px 0 48px;
  }
  
}
@media (max-width:680px) { 
  .TeamCard{
    margin: 0 35px 0 42px;
  }
  
}
@media (max-width:669px) { 
  .TeamCard{
    margin: 0 35px 0 42px;
  }
  
} */
@media (max-width:665px) { 
  .TeamCard{
 
    margin: 0 auto;
  }
  .TeamCard .team-img {
    width: auto;
  
    /* padding: 0 30px; */
}
}
@media (max-width:450px) { 
  .TeamCard .team-img {
    width: auto;
 
    /* padding: 0 30px; */
}
}
@media (max-width:350px) { 
  .TeamCard .team-img {
    width: auto;
   
    /* padding: 0 30px; */
}
}
/* @media (max-width:500px) { 
  .TeamCard{
    width: 52vw;
    margin: 0 36px;
  }
  
}
 */
/*@media (max-width: 991px) {
  .TeamCard .team-img img {
    height: 245px;
  }
}
@media (max-width: 767px) {
  .TeamCard,
  .TeamCard .description,
  .TeamCard .name,
  .TeamCard .place {
    width: 250px;
  }  
  .TeamCard .name {
    font-size: 18px;
    line-height: 1.22;
  }
  .TeamCard .place {
    font-size: 16px;
    line-height: 1.25;
  }
  .TeamCard .description {
    font-size: 16px;
    line-height: 1.25;
    margin-top: 12px;
  }
}
@media (max-width: 576px) {
  .TeamCard,
  .TeamCard .description,
  .TeamCard .name,
  .TeamCard .place {
    width: 250px;
  } 
} */

.Team .title .heading2 {
  font-size: 48px;
  line-height: 1.04;
  color: #013648;
  font-family: 'Heldane Display';
}
.Team .title .heading2.text-bold{
  font-weight: bold;
}
.Team {
  padding: 30px 0px;
}

.Team .slick-slider.team-slider {
  padding: 50px 55px 0 55px;
}
.Team .slick-slider.team-slider .slick-track{ margin-left: 0;display: flex; }

/* .Team .team-slider {
  margin-top: 82px;
}
.Team .team-slider .team-slider-content {
  padding: 0 0px;
}
.Team .slick-slider.team-slider .slick-track {
  margin-left: -45px;
}
@media (max-width: 1024px) {
  .Team .slick-slider.team-slider .slick-track {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .Team {
    padding: 50px 0;
  }
  .Team .title .heading2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .Team {
    padding: 40px 0;
    overflow: hidden;
  }
  .Team .title .heading2 {
    font-size: 48px;
    line-height: 1.17;
  }
  .Team .title {
    text-align: left;
  }
  .Team .team-slider {
    width: 800px;
  }
  .Team .team-slider .team-slider-content {
    padding: 0 15px;
  }
  .Team .team-slider {
    margin-top: 40px;
  }
  .Team .slick-slider.team-slider .slick-track {
    margin-left: 155px;
  }
}*/
@media (max-width: 665px) {
  .Team .slick-slider.team-slider {
    padding: 0;
    padding-top: 30px;
  }
  .Team{
    padding-top: 0;
  }
} 
@media (max-width: 350px) {
.Team .title .heading2.text-bold{
  font-size: 24px;
}
}
.KalypsysAdvisors {
  padding: 85px 0px;
}
.KalypsysAdvisors .title .heading2 {
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 38px;
line-height: 118.18%;
text-align: center;
color: #013648;
}

.KalypsysAdvisors .team-slider {
  margin-top: 28px;
}
.KalypsysAdvisors .team-slider .TeamCard .team-img{
  width: 215px;
  height: 223px;
  overflow: hidden;
}
.KalypsysAdvisors .team-slider .TeamCard .team-img img{
  object-fit: cover;
}
.KalypsysAdvisors .team-slider .TeamCard .name{
  margin-top: 25px;
  font-family:'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 118.18%;
  color: #013648;
}
.KalypsysAdvisors .team-slider .TeamCard .place{
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 118.18%;
  color: #013648;
}
.KalypsysAdvisors .team-slider .TeamCard .description{
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 118.18%;
width: 218px;
color: #013648;
margin-top: 20px;
}
.KalypsysAdvisors .team-slider .team-slider-content {
  padding: 0 0px;
}
.KalypsysAdvisors .slick-slider.team-slider .slick-track {
  margin-left: -45px;
}
@media (max-width: 1024px) {
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .KalypsysAdvisors {
    padding: 50px 0;
  }
  .KalypsysAdvisors .title .heading2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .KalypsysAdvisors {
    padding: 40px 0;
    overflow: hidden;
  }
  .KalypsysAdvisors .title .heading2 {
    font-size: 48px;
    line-height: 1.17;
  }
  .KalypsysAdvisors .title {
    text-align: left;
  }
  .KalypsysAdvisors .team-slider {
    width: 800px;
  }
  .KalypsysAdvisors .team-slider .team-slider-content {
    padding: 0 15px;
  }
  .KalypsysAdvisors .team-slider {
    margin-top: 40px;
  }
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: 155px;
  }
}
@media (max-width: 576px) {
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: auto;
  }
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: auto;
  }
  .KalypsysAdvisors .slick-initialized .slick-slide {
    width: 300px !important;
    padding-left: 30px;
  }
}

.Joinkalypsys {
  padding: 64px 0;
  background-repeat: no-repeat;
  background-position: 15px, right;
}
.Joinkalypsys .title .heading2 {
  font-size: 48px;
  line-height: 1.17;
  color: #013648;
  font-family: 'Heldane Display';
  margin-bottom: 15px;
}
.Joinkalypsys .title SPAN {
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.24px;
  color: #013648;
  display: inline-block;
}
.Joinkalypsys .join-form {
  width: 400px;
  margin: auto;
  margin-top: 43px;
}
.Joinkalypsys .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Joinkalypsys .form-row > .two-input {
  width: 49%;
}
.Joinkalypsys .join-form .joinform-action {
  margin-top: 25px;
}
.Joinkalypsys .join-form .joinform-action button.withus-btn {
  width: 201px;
}
.Joinkalypsys .join-form .Select-control SELECT{
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
}
.Joinkalypsys .fileupload{
  min-height: 52px;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    font-size: 18px;
    line-height: 1.22;
    color: #013648;
    /* margin-top: 14px; */
    padding-right: 142px;
    word-break: break-all;
    border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.Joinkalypsys .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}

.Joinkalypsys .fileupload .fileupload-btn input {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 1;
}
.Joinkalypsys .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 4px;
}
.Joinkalypsys .fileupload .preview-img {
  width: 100%;
  display: grid;
  grid-template-columns:  1fr;
}
.Joinkalypsys .fileupload .preview-img-multy{
  grid-template-columns: 55px 1fr;
}
.Joinkalypsys .fileupload .preview-img img{
  max-width: 50px;
  max-height: 33px;
}
.Joinkalypsys .fileupload .preview-img span{
  position: relative;
    display: block;
    top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.split-screeninput .Input-control.form-group .fileupload,
.split-screeninput .Input-control.form-group input{
  width: 100%;
height: 60px;
background: #FFFFFF;
border: 1px solid #013648 !important;
box-sizing: border-box;
border-radius: 10px
}
.split-screeninput  .fileupload .fileupload-btn button{
 background-color: #809BA4;
}
.split-screeninput .successmessage{
background-repeat: no-repeat;
width: 91px;
height: 91px;
position: relative;
margin: 0 auto;
}
.split-screeninput .successmessage img{
  position: absolute;
  top: 35%;
  left: 35%;
}
.split-screeninput .great-text{
  width: 198px;
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 118.18%;
margin: 0 auto;
text-align: center;
margin-top: 20px;
color: #013648;

}
.split-screeninput .infotext{
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 137%;
  text-align: center;  
  color: #013648;
}
.Joinkalypsys .join-form .split-screeninput .Select-control SELECT{
  background: #FFFFFF;
border: 1px solid #013648;
box-sizing: border-box;
border-radius: 10px;
z-index: 2;
}
.Joinkalypsys .join-form .CircleButton.WhiteButton{
  padding-top: 2px;
}
.Joinkalypsys .join-form .split-screeninput .Select-control .arrow{
  z-index: 3;
}
.Joinkalypsys .join-form .split-screeninput .Select-control.form-group:after,
.Joinkalypsys .join-form .split-screeninput .Select-control SELECT::after{
  background: transparent;
}
.info-providers-photoid{
  color: #809ba4;
  font-size: 14px;
}
@media (max-width: 1350px){
  .Joinkalypsys {
    background-size: 270px !important;
    background-position: 6% center, 94% center !important;
  }
}
@media (max-width: 1060px){
  .Joinkalypsys {
    background-size: 197px !important;
    background-position: 3% center, 97% center !important;
  }
}
@media (max-width: 991px) {
  .Joinkalypsys .title .heading2 {
    font-size: 42px;
  }
  .Joinkalypsys {
    padding: 50px 0;
  }
}
@media (max-width: 840px){
  .Joinkalypsys {
    background-size: 171px !important;
    background-position: 1% center, 99% center !important;
  }
}
@media (max-width: 767px) {
  .Joinkalypsys {
    padding: 65px 0 40px;
  }
  .Joinkalypsys {
    background-image: none !important;
  }
  .Joinkalypsys .join-form {
    width: 100%;
  }
  .Joinkalypsys .title .heading2 {
    font-size: 48px;
    line-height: 1.17;
    margin-bottom: 25px;
  }
}

.LandingPage {
  background-color: #ffffff;
}
.LandingPage .gradient {
  background-image: linear-gradient(-90deg, rgba(242, 150, 45, 0.08), rgb(61, 147, 166, 0.08));
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 767px) {
  .LandingPage {
    background-color: #ffffff;
  }
}

.PatientLandingHeader {
  background-color: transparent;
  height: 110px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
.PatientLandingHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
  position: relative;
}
.PatientLandingHeader .header-content .right-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.PatientLandingHeader .header-content .left-col {
  padding-left: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .PatientLandingHeader .header-content .right-col .menu-content {
  margin-left: auto;
} */
.desktopPricing {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.22px;
  padding-right: 50px;
  border-right: 1px solid #ffffff2e;
}
.mobile-Pricing {
  display: none !important;
}
.PatientLandingHeader .header-content .precticewith A {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #023648;
  display: inline-block;
  margin-right: 50px;
  opacity: 1;
  background: #ffffff;
  border-radius: 26.5px;
  padding: 5px 30px;
  width: 183px;
  padding-top: 10px;
}
.PatientLandingHeader .header-content .right-col .menu-content A {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.56;
  letter-spacing: -0.34px;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN {
  display: inline-block;
  padding: 0px 18px;
  position: relative;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN:first-child {
  padding-left: 49px;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN::after {
  content: '';
  position: absolute;
  width: 1px;
  background: #b2bcc4;
  left: 0;
  top: 0;
  bottom: 0px;
}
.PatientLandingHeader .header-content .right-col .menu-content.afternnone SPAN:after {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN:last-child:after {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN:last-child {
  padding-right: 0px;
}
.PatientLandingHeader .header-content .logo {
  width: 158px;
}
.PatientLandingHeader .header-content .logo A {
  position: relative;
  top: 5px;
  cursor: initial;
}
.PatientLandingHeader .header-content .logo A IMG {
  width: 169px;
  cursor: pointer;
}
.PatientLandingHeader .logowhite {
  display: block;
}
.PatientLandingHeader .logoblue {
  display: none;
}
.PatientLandingHeader .header-bottom {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content SPAN.login-mobile {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content .login-web {
  padding-left: 0;
  position: relative;
  cursor: pointer;
}
.PatientLandingHeader .login-web > SPAN {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.22px;
  padding-left: 50px;
}
.PatientLandingHeader .login-web > SPAN IMG {
  position: relative;
  top: 2px;
  left: 2px;
}
.PatientLandingHeader .right-col .menu-content .login-web .dropdown-menu {
  width: 197px;
  border-radius: 2px;
  background-color: #ffffff;
  position: absolute;
  right: 25px;
  padding-left: 0;
  transition: 0.3s all linear;
  top: 50px;
  opacity: 0;
  visibility: hidden;
}
.PatientLandingHeader .right-col .menu-content .login-web:hover .dropdown-menu {
  top: 30px;
  opacity: 1;
  visibility: visible;
}
.PatientLandingHeader .right-col .menu-content .login-web SPAN:first-child IMG {
  transition: 0.3s all linear;
}
.PatientLandingHeader .right-col .menu-content .login-web:hover SPAN:first-child IMG {
  transform: rotate(180deg);
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu > SPAN,
.PatientLandingHeader .menu-content .login-web .dropdown-menu > SPAN:first-child {
  width: 100%;
  padding: 14px 19px;
  line-height: normal;
  display: inline-block;
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN A {
  font-size: 16px;
  width: 100%;
  display: inline-block;
  color: #013648;
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu A > SPAN {
  font-family: 'Calibre Medium';
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN:not(:last-child) {
  border-bottom: 1px solid #f1f1f5;
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN:after {
  display: none;
}
.PatientLandingHeader .mobile-menu {
  display: none;
}
.PatientLandingHeader .header-content .right-col {
  padding-right: 86px;
}
@media (hover: hover) {
  .PatientLandingHeader .header-content .precticewith A:hover {
    /* color: rgba(255,255,255,0.6); */
  }
  .PatientLandingHeader .header-content .right-col .menu-content A:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  .PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN A:hover {
    color: rgba(1, 54, 72, 0.6);
  }
}

@media (max-width: 1200px) {
  .PatientLandingHeader .header-content .left-col {
    padding-left: 30px;
  }
  .PatientLandingHeader .header-content .right-col {
    padding-right: 30px;
  }
  .PatientLandingHeader .header-content .precticewith A {
    margin-right: 30px;
  }
}

@media (max-width: 1024px) {
  .PatientLandingHeader .header-content .logo A IMG {
    width: 130px;
  }
  .PatientLandingHeader .header-content .precticewith A {
    font-size: 18px;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN {
    padding: 0px 20px;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN:first-child {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .PatientLandingHeader {
    height: 110px;
    position: static;
    background-color: #013648;
  }
  .desktopPricing {
    display: none;
  }
  .mobile-Pricing {
    display: inline-block !important;
  }
  .PatientLandingHeader .header-content .right-col {
    transition: 0.3s all linear;
    position: absolute;
    right: 0;
    top: -69px;
    left: 0;
    transition: 0.3s all linear;
    padding: 0 30px 20px;
    background-color: #013648;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    flex-wrap: wrap;
  }
  .PatientLandingHeader .header-content .right-col A,
  .PatientLandingHeader .header-content .right-col BUTTON {
    color: #fff;
    text-align: center;
  }
  .PatientLandingHeader .header-content .right-col BUTTON {
    border: solid 1px #ffffff;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN,
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN:first-child {
    text-align: left;
    padding: 10px 0;
    display: inline-block;
    width: 100%;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN A {
    font-family: 'Calibre Regular';
    font-size: 24px;
    line-height: 1.83;
    letter-spacing: -0.24px;
    color: #fbfbf9;
  }
  .PatientLandingHeader .header-content .precticewith {
    display: none;
  }
  .PatientLandingHeader.menu-active {
    background-color: #013648;
  }
  .PatientLandingHeader.menu-active .header-content .right-col {
    display: block;
    top: 69px;
    opacity: 1;
    visibility: visible;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN {
    background-color: #fff;
  }
  .PatientLandingHeader .header-content .logo A IMG {
    width: 128px;
  }
  /*hamburger icon*/
  .PatientLandingHeader .mobile-menu SPAN {
    width: 20px;
    display: inline-block;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    transition: 0.3s all linear;
    border-radius: 4px;
  }
  .PatientLandingHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .PatientLandingHeader .mobile-menu SPAN:nth-child(2) {
    top: 9px;
  }
  .PatientLandingHeader .mobile-menu SPAN:last-child {
    top: 18px;
    width: 10px;
  }
  .PatientLandingHeader .mobile-menu {
    width: 20px;
    height: 18px;
    position: absolute;
    top: 8px;
    cursor: pointer;
    right: 30px;
  }
  .PatientLandingHeader.menu-active .right-col {
    right: 0px;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN {
    background-color: #fff;
    transform: rotate(45deg);
    top: 9px;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 9px;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN::after {
    display: none;
  }
  .PatientLandingHeader .header-content .right-col .menu-content {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
  }
  .PatientLandingHeader .header-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: solid 1px rgba(255, 255, 255, 0.08);
  }
  .PatientLandingHeader .header-bottom .provider-login {
    width: 100%;
    margin-bottom: 27px;
  }
  .PatientLandingHeader .header-bottom .provider-login A {
    color: #fbfbf9 !important;
  }
  .PatientLandingHeader .header-bottom > SPAN {
    display: inline-flex;
  }
  .PatientLandingHeader .header-bottom > SPAN A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.38px;
    color: rgba(251, 251, 249, 0.5) !important;
    display: inline-block;
  }
  .PatientLandingHeader .header-bottom .list-practice A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: rgba(251, 251, 249, 0.5) !important;
  }
  .PatientLandingHeader .header-bottom > SPAN A > SPAN {
    color: #fbfbf9;
  }
  .PatientLandingHeader.menu-active + .PatientLanding {
    margin-top: 108px;
  }
  .PatientLandingHeader .logo .logowhite {
    display: block;
  }
  .PatientLandingHeader.menu-active .logo IMG {
    display: none;
  }
  .PatientLandingHeader.menu-active .logo .logowhite {
    display: block;
  }
  .PatientLandingHeader .header-content .right-col .menu-content SPAN.login-mobile {
    display: block;
  }
  .PatientLandingHeader .header-content .right-col .menu-content .login-web {
    display: none;
  }

  @media (hover: hover) {
    .PatientLandingHeader .mobile-menu:hover SPAN:last-child {
      width: 20px;
    }
  }
}
@media (max-width: 413px) {
  .PatientLandingHeader .header-content .left-col {
    padding-left: 15px;
  }
  .PatientLandingHeader .mobile-menu {
    right: 15px;
  }
  .PatientLandingHeader .header-content .right-col {
    padding: 0 15px 20px;
  }
}

.ListyourpracticeIntro {
  background-color: #013648;
  display: flex;
  position: relative;
  overflow: hidden;
}
.ListyourpracticeIntro .Ac-left{
  width: 100%;
    padding-top: 150px;
    padding-left: 137px;
    padding-right: 137px;
    position: relative;
}
.ListyourpracticeIntro .Ac-left .practice-banner{
 display: grid;
 grid-template-columns: 60% 40%;
 padding: 0 100px;
 padding-bottom: 95px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .mainheadding {
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 131.18%;
color: #FFFFFF;
margin-top: 10px;
margin-bottom: 10px;
padding-right: 10px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .textpara {
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 143.5%;
color: rgba(255, 255, 255, 0.6);
margin-top: 10px;
margin-bottom: 60px;
padding-right: 15px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .listyourpractice{
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 33.5px;
  padding: 13px 60px;
  color: #013648;
  font-size: 18px;
  padding-top: 17px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .landingpage{
  position: relative;
    float: right;
    top: 30px;
    width: 400px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .design{
position: absolute;
}
.ListyourpracticeIntro .Ac-left .practice-banner .design1{
  right: -118px;
  top: 62px;
  }
  .ListyourpracticeIntro .Ac-left .practice-banner .design2{
    right: -68px;
    top: 161px;
    }
    .ListyourpracticeIntrobodyText .box div{
      padding: 10px
    }
    .img-listyourpractice .listyourpractice{
      display: none;
    }
    @media (max-width: 1400px) {
  .ListyourpracticeIntro .Ac-left .practice-banner{
    padding:0;
    padding-bottom: 50px;
}
    }
    @media (max-width: 1250px) {
      .ListyourpracticeIntro .Ac-left{
        padding-left:50px;
        padding-right:50px;
        padding-top: 150px;
    }
    
        }
        @media (max-width: 1200px) {
        .ListyourpracticeIntro .Ac-left .practice-banner .mainheadding{
          font-size: 45px;
      
        }
        .ListyourpracticeIntro .Ac-left .practice-banner  .text-div{
          padding: 30px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .landingpage{
          width: 100%;
        }
    
      }
      @media (max-width: 997px) {
        .ListyourpracticeIntro .Ac-left .practice-banner .mainheadding{
          font-size: 36px;
      
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .textpara{
          font-size: 20px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner  .text-div{
          padding: 20px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .mainheadding .text-div{
          padding: 30px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .landingpage{
          width: 100%;
        }
    
      }
      @media (max-width: 767px) {
        .ListyourpracticeIntro .Ac-left{
          padding-left:50px;
          padding-right:50px;
          padding-top: 0;
      }
      .ListyourpracticeIntro .Ac-left .practice-banner  .text-div{
        padding: 0px;
        text-align: center;
      }
      .text-div .listyourpractice{
        display: none;
            }
            .ListyourpracticeIntro .Ac-left .practice-banner .textpara{
              margin-bottom: 15px;
            }
            .ListyourpracticeIntro .Ac-left .practice-banner{
              padding-bottom: 30px;
            }
            .img-listyourpractice{
              text-align: center;
            }
            .img-listyourpractice .listyourpractice{
              display: inline-block;
              margin-top: 50px;
            }
    }
    @media (max-width: 650px) {
      .ListyourpracticeIntro .Ac-left{
        padding-left:30px;
        padding-right:30px;
        padding-top: 0;
    }
    .Differentiateyourpractice .heading {
      font-size: 40px;
    }
    .Joinkalypsys .split-screeninput .form-row > .two-input{
      width: 100%;
    }
    .Joinkalypsys .split-screeninput .form-row {
      display: grid;
      justify-content:initial;
      width: 100%;
    }
    
    }
    @media (max-width: 650px) {
    .Team .title .heading2 {
      font-size: 40px;
      line-height: 1.17;
      text-align: center;
  }
}
  @media (max-width: 500px) {
    .Team .title .heading2 {
      font-size: 32px;
      line-height: 1.17;
  }
}
@media (max-width: 400px) {
  .Team .title .heading2 {
    font-size: 28px;
    line-height: 1.17;
}
}
.ListyourpracticeIntrobodyText{
margin-top: 50px;
}
.ListyourpracticeIntrobodyText .maintext{
  width: 660px;
  height: 126px;
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 35px;
line-height: 122.5%;
text-align: center;
margin: 0 auto;
color: #013648;
margin-bottom: 30px;
}
.ListyourpracticeIntrobodyText .box{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  column-gap: 20px;
  height: 207px;
}
.ListyourpracticeIntrobodyText .box .fst{
  background: rgba(170, 149, 215, 0.15);
  border: 1px solid #AA95D7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 25px;
  padding-bottom: 20px;
}
.ListyourpracticeIntrobodyText .box .sec{
  background: #E5F3F6;
  border: 1px solid #77CAE5;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 25px;
  padding-bottom: 20px;
}
.ListyourpracticeIntrobodyText .box .thr{
  background: rgba(244, 161, 71, 0.15);
border: 1px solid #F4A147;
box-sizing: border-box;
border-radius: 6px;
padding: 0 25px;
padding-bottom: 20px;
}
.ListyourpracticeIntrobodyText .box .headding{
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 118.18%;
  color: #013648;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 23px;
}
.ListyourpracticeIntrobodyText .box .paragraph{
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 143.5%;
text-align: center;
color: #013648;
margin-bottom: 0;
}
.Differentiateyourpractice {
  margin-top:50px;
  text-align: center;
  margin-bottom: 80px;
}
.Differentiateyourpractice .heading {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 118.18%;
  max-width: 670px;
  color: #013648;
  margin: 0 auto;
  margin-bottom: 30px;
}
.Differentiateyourpractice .content {
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 143.5%;
  text-align: center;
  margin: 0 auto;
  color: #013648;
  width: 472px;
  margin-bottom: 34px;
}
.Differentiateyourpractice .googleLogo {
  margin: 0 auto;
  width: 238px;
}
.Differentiateyourpractice .Strengthen {
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 118.18%;
  text-align: center;
  color: #013648;
}
.Differentiateyourpractice .Ensureyouronline {
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 143.5%;
  text-align: center;
  color: #013648;
  width: 501px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.Differentiateyourpractice .listyourpractice {
  width: 193px;
  height: 41px;
  background: #013648;
  border: 1px solid #013648;
  box-sizing: border-box;
  border-radius: 33.5px;
  color: #fff;
  padding: 10px 42px;
  padding-top: 13px;
  margin-bottom: 30px;
  display: inline-block;
}
.TakingPoint-parent-professional .h3{
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 118.18%;
  text-align: center;
  color: #013648;
  margin-bottom: 20px;
}
.TakingPoint-parent-professional img{
  width: 230px;
}
.TakingPoint-parent-professional .paragraph{
  font-family:'Calibre Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 143.5%;
    text-align: center;
    color: #013648;
    width: 501px;
    margin: 0 auto;
    margin-bottom: 50px;
}
.Differentiateyourpractice .Acommitment {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 118.18%;
  text-align: center;
  color: #013648;
  margin-top: 0;
  margin-bottom: 20px;
}
.Differentiateyourpractice .price-per-month {
  width: 411px;
  height: 482px;
  background: rgba(86, 192, 218, 0.2);
  border: 1px solid #56c0da;
  box-sizing: border-box;
  border-radius: 9px;
  margin: 0 auto;
}
.Differentiateyourpractice .price-per-month .permonth {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 137%;
  margin-top: 40px;
  color: #3d93a5;
  margin-bottom: 20px;
}
.Differentiateyourpractice .price-per-month .discription {
  font-family: 'Gilroy-Regular';
  font-size: 18px;
  line-height: 147%;
  text-align: center;
  color: #013648;
  width: 254px;
  margin: 0 auto;
}
.Differentiateyourpractice .price-per-month .discription2box {
  background: rgba(255, 255, 255, 0.73);
  border: 1px solid #bed9df;
  box-sizing: border-box;
  border-radius: 5px;
  width: 352px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 106px;
  padding: 14px;
  margin-bottom: 50px;
  padding-top: 19px;
}
.Differentiateyourpractice .price-per-month .discription2box .discription {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.5%;
  text-align: center;
  color: #e75d72;
  width: 272px;
}
.Differentiateyourpractice .price-per-month .listyour-practice {
  background: #013648;
  border: 1px solid #013648;
  box-sizing: border-box;
  border-radius: 33.5px;
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 95%;
color: #FFFFFF;
padding: 10px 60px;
}
.doctorprofiledashboard{
  width: 100%;
}
.commitment-fair-session{
  margin-top: 30px;
}
.TakingPoint-parent-professional{
  margin-bottom: 30px;
}
.googleLogo-div{
  width: 259px;
  margin: 0 auto;
  height: 68px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}
.googleLogo-div img{
  width: 74%;
  margin: 0 auto;
 }
 .googleLogo-div img.healthgrades-svg{
  width: 95%;
  top: -7px;
  position: inherit;
 }
 .googleLogo-div img.google-svg{
   width: 76%;
 }
@media (max-width: 1200px) {
  .Differentiateyourpractice .doctorprofiledashboard{
width: 100%;
}
}
@media (max-width: 948px) {
  .ListyourpracticeIntrobodyText .box{
    height: auto;
  }
  
}
@media (max-width: 767px) {
.ListyourpracticeIntro .Ac-left .practice-banner{
  grid-template-columns: 1fr;
}
.ListyourpracticeIntrobodyText .box div{
  margin-bottom: 30px;
}
.Differentiateyourpractice{
  margin-top: 0; 
 }
.ListyourpracticeIntrobodyText .box{
  grid-template-columns: 1fr;
  height: auto;
  
}
.Differentiateyourpractice .heading,
.Differentiateyourpractice .content,
.Differentiateyourpractice .Ensureyouronline,
.ListyourpracticeIntrobodyText .maintext{
  width: 100%;
  text-align: center;
}
.TakingPoint-parent-professional .paragraph{
  width: 100%;
}
.ListyourpracticeIntrobodyText .maintext{
  font-size: 27px;
}
.Differentiateyourpractice{
  margin-bottom: 40px;
}

.Differentiateyourpractice .heading{
  font-size: 45px;
}
.Differentiateyourpractice .content{
  font-size: 18px;
}
}
@media (max-width: 500px) {
.Differentiateyourpractice .price-per-month{
width: 100%;
}
.Differentiateyourpractice .price-per-month .discription2box{
  width: 95%;
  /* height: 119px; */
}
.Joinkalypsys .title .heading2{
  font-size: 42px;
}
}
@media (max-width: 400px) {
  .Differentiateyourpractice .Strengthen,
.TakingPoint-parent-professional .h3{
font-size: 19px;
}
.Differentiateyourpractice .heading{
  font-size: 30px;
}
.Differentiateyourpractice .Ensureyouronline,
.TakingPoint-parent-professional .paragraph{
  font-size: 16px;
}
.Differentiateyourpractice .price-per-month .discription2box .discription{
  width: 100%;
}
.Differentiateyourpractice .price-per-month .discription{
  width: 90%;
}
}
@media (max-width: 360px){
  .Differentiateyourpractice .price-per-month {
    height: 530px;
  }
}
.PatientFooter {
  padding: 58px 0 54px;
}
.PatientFooter .footer-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PatientFooter .footer-main .footer-left {
  flex: 0 0 64%;
  max-width: 64%;
}
.PatientFooter .footer-main .footer-right {
  flex: 0 0 36%;
  max-width: 36%;
}
.PatientFooter .footer-main .footer-left .footer-left-inner {
  display: flex;
}
.PatientFooter .footer-main .footer-left .footer-left-inner .widget-one {
  flex: 0 0 43%;
  max-width: 43%;
  padding-right: 15px;
}
.PatientFooter .footer-main .footer-left .footer-left-inner .widget-two,
.PatientFooter .footer-main .footer-left .footer-left-inner .widget-three {
  flex: 0 0 28%;
  max-width: 28%;
  padding-right: 15px;
  margin-top: 10px;
}
.PatientFooter .footer-main .footer-left img {
  max-width: 180px;
  width: 100%;
  margin-bottom: 15px;
}
.PatientFooter .footer-main .footer-left .footer-widget .paragraph {
  font-family: 'Calibre Regular';
  font-size: 18px;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #013648;
}
.PatientFooter .footer-main .footer-left .footer-widget .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183b56;
  margin-bottom: 35px;
}
Footer .footer-main .footer-left .footer-widget .footer-menu-ul {
  list-style-type: none;
}
.PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list {
  margin-bottom: 27px;
}
.PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a {
  font-family: 'Calibre Regular';
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183b56;
  transition: all 0.3s ease;
}

.PatientFooter .footer-main .footer-right .footer-menu-ul {
  display: flex;
  justify-content: flex-end;
}
.PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list {
  margin-right: 23px;
}
.PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list:last-child {
  margin-right: 0;
}
.PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list a img {
  width: 44px;
  height: 44px;
  transition: all 0.3s ease;
}
.PatientFooter .footer-right-inner {
  margin-top: 20px;
}
.PatientFooter .footer-bottom {
  margin-top: 60px;
  position: relative;
}

.PatientFooter .footer-bottom .paragraph {
  font-family: 'Calibre Regular';
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #979ea6;
  margin: 0;
}
@media (hover: hover) {
  .PatientFooter .footer-main .footer-right .footer-menu-ul .menu-item-list a:hover img {
    opacity: 0.7;
  }
  .PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a:hover {
    color: #013648;
  }
}

@media (max-width: 1200px) {
  .PatientFooter .footer-bottom {
    margin-top: 150px;
  }
}

@media (max-width: 991px) {
  .PatientFooter .footer-main .footer-left {
    flex: 0 0 70%;
    max-width: 70%;
  }
  .PatientFooter .footer-main .footer-right {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .PatientFooter .footer-bottom {
    margin-top: 0;
  }
  .PatientFooter {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .PatientFooter{
    background-size: 320px !important;
    background-position: left center !important;
  }
  .PatientFooter .footer-main .footer-left .footer-widget .footer-menu-ul .menu-item-list a {
    line-height: 1.25;
  }

  .PatientFooter .footer-main .footer-left .footer-left-inner .widget-one {
    display: none;
  }
  .PatientFooter .footer-main .footer-left {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .PatientFooter .footer-main .footer-right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 53px;
  }
  .PatientFooter .footer-main .footer-left .footer-left-inner .widget-two {
    flex: 0 0 42%;
    max-width: 42%;
  }
  .PatientFooter .footer-main .footer-left .footer-left-inner .widget-three {
    flex: 0 0 58%;
    max-width: 58%;
  }
  .PatientFooter .footer-main {
    flex-direction: column-reverse;
  }
  .PatientFooter .footer-main .footer-right .footer-menu-ul {
    justify-content: center;
  }
  .PatientFooter .footer-main .footer-left .footer-left-inner {
    flex-direction: row-reverse;
  }
  .PatientFooter .footer-main .footer-left .footer-widget .h3 {
    margin-bottom: 30px;
    line-height: 1.22;
  }
  .PatientFooter .footer-bottom {
    margin-top: 30px;
  }
  .PatientFooter .footer-bottom .paragraph {
    text-align: center;
    display: none;
  }
}

.newFooter{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.newFooter .main-title{
  font-family: 'Calibre Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #013648;
    opacity: .8;
}
.newFooter .each-block ul{
  padding: 0;
  list-style: none;
}
.newFooter .each-block ul li{
  margin-bottom: 5px;
  font-family: 'Calibre Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}
.newFooter .each-block ul li a {
  color: #013648;
  /* text-decoration: underline;
  text-decoration-style: dashed; */
  text-decoration-thickness: 1px;
  cursor: pointer;
}
.newFooter .each-block ul li a:hover {
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  text-decoration: underline;
}
.footer-sociallink{
  display: none;
  text-align: center;
}
.footer-sociallink.show{
  display: block
}
.mobilefooternew{
  color: #979ea6;
  text-align: center;
  font-size: 19px;
  padding: 20px 0;
  display: none
}
.mobilefooternew.show{
  display: block;
}
.mobilefooternew a{
  margin-right: 5px;
  margin-left: 5px;
  color: #013648;
  display: inline-block;
}
.mobilefooternew a:hover{
text-decoration: underline;
}
.avoid-margin{
  margin-top: 0 !important;
}
.avoid-margin .hideonmobile{
  display: none;
}
.footercopy-right{
  color: #979ea6;
}
.accessibilityWidget{
  color: #013648;
  text-decoration-thickness: 1px;
  cursor: pointer;
}
.accessibilityWidget:hover{
  text-decoration: underline;
}
@media (max-width: 1000px) {
  .hideonmobile,
  .new-footer-parent{
    display: none;
  }
  .mobilefooternew{
    display: block;
  }
  .footer-sociallink{
    display: block;
    text-align: center;
    
  }
  .footer-sociallink img{
    width: 30px;
    height: 30px;
    margin-right:5px;
    margin-left: 5px;
  }
}

@media (max-width: 991px) {
  .newFooter{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 600px) {
  .newFooter{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .newFooter{
    display: grid;
    grid-template-columns: 1fr ;
  }
}
.writereview-public .ReviewVisitModal .modal-content-header {
  background-color: transparent;
}
.writereview-public .ReviewVisitModal .modal-content-header .close {
  display: none;
}
.writereview-public .ReviewVisitModal .modal-content-header .h3 {
  color: #013648;
}
.writereview-public .ReviewVisitModal {
  width: 100%;
}
.writereview-public .ReviewVisitModal .modal-content-body,
.writereview-public .ReviewVisitModal .modal-content-header,
.writereview-public .ReviewVisitModal .modal-content-footer {
  padding: 0;
}
.writereview-public .ReviewVisitModal .modal-content-body {
  height: auto;
  max-height: inherit;
  background-color: transparent;
}
.writereview-public .ReviewVisitModal .modal-content-footer {
  padding: 40px 0;
  background-color: transparent;
}
.writereview-public .ReviewVisitModal .Textarea .error-text {
  bottom: -30px;
}
.text-underline {
  text-decoration: underline;
}
@media (max-width: 500px) {
  .writereview-public .ReviewVisitModal .recent-visit-info .total-character {
    font-size: 14px;
  }
}

.AnswerRating .rating {
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  display: inline-flex;
  overflow: hidden;
  margin-bottom: 30px;
}
.AnswerRating .rating > span {
  width: 37px;
  height: 37px;
  background-color: #ffffff;
  display: inline-block;
  display: flex;
  border-right: solid 1px #e2e2ea;
}
.AnswerRating .rating > span:last-child {
  border-right: 0;
}
.AnswerRating .rating > span img {
  width: 20px;
  height: 19px;
  margin: auto;
}
.AnswerRating .rating > span {
  cursor: pointer;
}
.AnswerRating .rating > span.disable img {
  opacity: 0.2;
}
.AnswerRating .answer-text {
  font-size: 18px;
  line-height: 2;
  letter-spacing: -0.23px;
  color: #013648;
  margin-bottom: 4px;
}
.AnswerRating .answer-text.no-select {
  color: #e85d72;
}

.ReviewVisitModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 678px;
}
.ReviewVisitModal .error-text {
  position: absolute;
  right: 35px;
  bottom: 4px;
  font-size: 16px;
  line-height: 2.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #e85d72;
  width: 298px;
}
.ReviewVisitModal .anonymous-txt {
  font-size: 16px;
}
.ReviewVisitModal .Textarea .error-text {
  left: 0;
  right: auto;
  width: auto;
  bottom: -2px;
}
.ReviewVisitModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.ReviewVisitModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ReviewVisitModal .modal-content-header .close img {
  width: 100%;
}
.ReviewVisitModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.ReviewVisitModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 220px);
  overflow: auto;
  background-color: #fbfbf9;
  -webkit-overflow-scrolling: touch;
}
.ReviewVisitModal .modal-content-footer {
  padding: 23px 35px 33px;
  background-color: #fbfbf9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 46px;
  position: relative;
}
.ReviewVisitModal .modal-content-footer .CircleButton {
  width: 298px;
  height: 45px;
  border-radius: 22.5px;
  font-size: 18px;
  line-height: 45px;
  letter-spacing: -0.34px;
  text-align: center;
  font-family: 'Calibre Regular';
}
.ReviewVisitModal .Divider {
  margin-top: 10px;
}
.ReviewVisitModal .AppointmentItem {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding: 0;
}
.ReviewVisitModal .AppointmentItem:last-child {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.ReviewVisitModal .AppointmentItem .ap-dc-info {
  display: flex;
}
.ReviewVisitModal .AppointmentItem .ap-dc-info .dc-img {
  min-width: 67px;
  min-height: 100px;
  width: 67px;
  height: 100px;
}
.ReviewVisitModal .AppointmentItem .ap-dc-info .dc-img img {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.ReviewVisitModal .AppointmentItem .dc-info {
  padding-left: 26px;
}
.ReviewVisitModal .AppointmentItem .dc-info .name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.ReviewVisitModal .AppointmentItem .dc-info .dc-specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.ReviewVisitModal .AppointmentItem .dc-info .address {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.ReviewVisitModal .AppointmentItem .dc-info .dc-guardian {
  padding-top: 23px;
}
.ReviewVisitModal .AppointmentItem .dc-info .address .icon,
.ReviewVisitModal .AppointmentItem .dc-info .dc-guardian .icon {
  width: 14px;
  margin-right: 14px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time {
  width: 120px;
  min-height: 120px;
  margin-left: auto;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  background-color: #198754;
  border: 0;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.38px;
  color: #fff;
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time span {
  display: inline-block;
  width: 100%;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time .day {
  font-family: 'Calibre Medium';
}
.ReviewVisitModal .AppointmentItem .ins-info {
  color: #26acda;
  cursor: pointer;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .book-appointment {
  position: relative;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .book-appointment .appointment-action {
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 0 10px 1px #eaeaea;
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  overflow: hidden;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo {
  width: 200px;
  min-width: 200px;
}
.̀ReviewVisitModal .AppointmentItem.confirmed .ap-dc-timeinfo .appointment-time,
.ReviewVisitModal .AppointmentItem.completed .ap-dc-timeinfo .appointment-time {
  background-color: #198754;
  border-color: #198754;
  color: #f1f1f1;
}
.ReviewVisitModal .AppointmentItem.pending .ap-dc-timeinfo .appointment-time {
  border: solid 1px #013648;
  background-color: transparent;
  color: #013648;
}
.ReviewVisitModal .AppointmentItem.pending .dc-info .address,
.ReviewVisitModal .AppointmentItem.pending .dc-info .dc-guardian {
  color: #013648;
}

.ReviewVisitModal .recent-visit-info {
  padding-top: 12px;
}
.ReviewVisitModal .recent-visit-info .visit-description {
  position: relative;
}
.ReviewVisitModal .recent-visit-info .total-character {
  text-align: right;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #b2bcc4;
  position: absolute;
  right: 0;
  bottom: 0;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-text {
  font-size: 18px;
  font-family: 'Calibre Medium';
  line-height: 1.68;
  letter-spacing: -0.18px;
  color: #013648;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list .answer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list {
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-top: 33px;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list:first-child {
  padding-top: 0;
}
.ReviewVisitModal .privacy-text {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.2px;
  color: #72787d;
  margin-top: 27px;
}
.ReviewVisitModal .Checkbox-control {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .ReviewVisitModal {
    width: 100%;
    border-radius: 0;
    min-width: 100%;
    max-width: 100%;
  }
  .ReviewVisitModal .modal-content-body {
    height: calc(100vh - 300px);
  }
  .ReviewVisitModal .modal-content-footer {
    padding-bottom: 30px;
  }
  .ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list .answer {
    flex-wrap: wrap;
  }
  .ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list .answer .AnswerRating {
    width: 100%;
  }
  .ReviewVisitModal .Checkbox-control {
    width: 100%;
    margin-bottom: 15px;
  }
  .ReviewVisitModal .modal-content-footer {
    flex-wrap: wrap;
  }
  .ReviewVisitModal .modal-content-footer .CircleButton {
    width: 100%;
  }
  .ReviewVisitModal .modal-content-footer .error-text {
    position: absolute;
    width: 100%;
    display: inline-block;
    text-align: center;
    left: 0;
    line-height: normal;
    bottom: 5px;
    margin-top: 10px;
  }
  .ReviewVisitModal .ap-dc-timeinfo {
    margin-bottom: 23px;
  }
  .ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time {
    margin-bottom: 0;
  }
  .ReviewVisitModal .AppointmentItem .dc-info .name {
    font-size: 18px;
  }
  .ReviewVisitModal .AppointmentItem .dc-info .dc-specialist {
    font-size: 16px;
  }
  .ReviewVisitModal .modal-content-body {
    padding: 29px 30px;
  }
  .ReviewVisitModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .ReviewVisitModal .AppointmentItem {
    padding-bottom: 30px;
  }
  .ReviewVisitModal .modal-content-header {
    padding: 0 20px 0 20px;
    height: 65px;
  }
  .ReviewVisitModal .modal-content-header .h3 {
    font-size: 24px;
  }
}
@media (max-width: 413px) {
  .ReviewVisitModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .ReviewVisitModal .modal-content-body {
    padding: 29px 20px;
  }
  .ReviewVisitModal .modal-content-body {
    max-height: calc(100vh - 222px);
  }
  .ReviewVisitModal .modal-content-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.InvitePractice {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
}
.InvitePractice .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.InvitePractice .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.InvitePractice .modal-content-header .close IMG {
  width: 100%;
}
.InvitePractice .modal-content-header .heading3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.InvitePractice .modal-content-body {
  padding: 30px 33px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.InvitePractice .modal-content-body .InfiniteScroll {
  max-height: calc(100vh - 244px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.InvitePractice h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 5px;
  font-family: 'Calibre Medium';
 
}
.InvitePractice .each-spec{
  font-size: 18px;
    line-height: 1.4;
    letter-spacing: -.24px;
    color: #013648;
    margin-bottom: 3px;
}
.InvitePractice .sub-para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -.2px;
  color: #b2bcc4;
  margin-bottom: 2px;
}
.InvitePractice .footer-div{
  margin-top: 20px;
   border-top: 1px solid #b2bcc4;
    padding-top: 20px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.InvitePractice .footer-div button{
  width: 150px;
  height: 35px;
  line-height: 35px;
}
.InvitePractice .error-span{
color: red;
}
.InvitePractice .Input-control.column3.form-group.error{
  margin-bottom: 5px;
}
.InvitePractice .form-row {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.InvitePractice .form-row .Select-control.form-group{
  width: 100%;
}
.InvitePractice .add-invitation{
  display: flex;
  justify-content: flex-end;
}
.InvitePractice .add-invitation.top{
  display: flex;
  justify-content: space-between;
}
.InvitePractice .add-invitation img{
  width: 40px;
}
.InvitePractice .seperater-div{
border-top: 1px solid #013648;
margin-bottom: 10px;
}
.InvitePractice .count{
  padding: 9px;
    border-radius: 50%;
    background-color: #013648;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
}


@media (max-width: 1024px) {
 
  .InvitePractice {
    width: 730px;
  }
  .InvitePractice .modal-content-body {
    padding: 30px 17px;
  }
  .InvitePractice .form-row {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .InvitePractice {
    width: 100%;
  }

  
}
@media (max-width: 575px) {
  .InvitePractice {
    width: 100%;
  }
  .InvitePractice .modal-content-body {
    padding: 20px;
  }
  .InvitePractice .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .InvitePractice .modal-content-header .heading3 {
    font-size: 24px;
  }
  .InvitePractice .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  

 
}
@media (max-width: 414px) {
  .InvitePractice .modal-content-body {
    padding: 15px;
  }
  
}

.AfterAuthHeader {
  background-color: #ffffff;
  height: 70px;
  display: flex;
  z-index: 8;
  padding: 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.AfterAuthHeader > DIV {
  width: 100%;
}
.AfterAuthHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.AfterAuthHeader .header-content .right-col A {
  color: #013648;
}
.AfterAuthHeader .header-content .right-col SPAN {
  display: inline-block;
  padding: 0px 0px;
  position: relative;
}
.AfterAuthHeader .header-content .right-col SPAN:last-child {
  padding-right: 0px;
}
.AfterAuthHeader .header-content .logo {
  width: 111px;
}
.AfterAuthHeader .header-content .logo A IMG {
  width: 100%;
  cursor: pointer;
}
.AfterAuthHeader .header-content .logo A {
  position: relative;
  top: 5px;
  display: inline-block;
}
.AfterAuthHeader .mobile-menu {
  display: none;
}
.AfterAuthHeader .header-content .right-col .user .user-content > DIV {
  display: flex;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-pic {
  width: 27px;
  height: 41px;
  margin-right: 16px;
  display: inline-block;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-pic IMG {
  width: 100%;
  border-radius: 2.5px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AfterAuthHeader .header-content .right-col .user .user-content .staff-default-img {
  width: 27px;
  height: 41px;
  margin-right: 16px;
  display: inline-block;
  border-radius: 2.5px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  padding: 8px 3px;
}
.AfterAuthHeader .header-content .right-col .user .user-content .staff-default-img IMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-info .user-post {
  opacity: 0.4;
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #013648;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-info .user-name {
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.4;
  color: #013648;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-info SPAN {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-info {
  position: relative;
  padding-right: 60px;
}
.AfterAuthHeader .header-content .right-col .user .user-content .user-info .arrowPointer {
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
}
.AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: 0.3s all linear;
  background-color: #ffffff;
  min-width: 300px;
}
.AfterAuthHeader .header-content .right-col .user .user-content .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
}
.AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 16px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.email IMG {
  width: 20px;
  position: absolute;
  left: 20px;
}
.AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.email {
  color: #3dbce7;
  padding-left: 47px;
  position: relative;
}
.AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.logout {
  padding-left: 47px;
  position: relative;
}
.AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.logout IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.AfterAuthHeader .header-content .right-col .user .user-content {
  position: relative;
}
@media (hover: hover) {
  .AfterAuthHeader .header-content .right-col A:hover {
    text-decoration: none;
  }
  .AfterAuthHeader .header-content .right-col .user .user-content .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
@media (max-width: 1024px) {
  .AfterAuthHeader {
    padding: 0 10px;
  }
  .AfterAuthHeader .header-content .right-col .user .user-content > DIV {
    display: flex;
  }
}
@media (max-width: 767px) {
  .AfterAuthHeader .header-content {
    padding-left: 35px;
  }
  .AfterAuthHeader .mobile-menu {
    width: 20px;
    height: 18px;
    position: absolute;
    top: 22px;
    cursor: pointer;
    display: block;
    left: 15px;
  }
  .AfterAuthHeader .mobile-menu SPAN {
    width: 20px;
    display: inline-block;
    height: 2px;
    background-color: #013648;
    position: absolute;
    transition: 0.3s all linear;
  }
  .AfterAuthHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .AfterAuthHeader .mobile-menu SPAN:nth-child(2) {
    top: 7px;
  }
  .AfterAuthHeader .mobile-menu SPAN:last-child {
    top: 14px;
    width: 10px;
  }
  .AfterAuthHeader.menu-active .right-col {
    right: 0px;
  }
  .AfterAuthHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .AfterAuthHeader.menu-active .mobile-menu SPAN {
    background-color: #013648;
    transform: rotate(45deg);
  }
  .AfterAuthHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
  }
  .AfterAuthHeader.menu-active .mobile-menu {
    position: fixed;
    left: 15px;
    top: 30px;
    z-index: 2;
  }
  .AfterAuthHeader .header-content .right-col SPAN,
  .AfterAuthHeader .header-content .right-col .user .user-content .user-info .user-name {
    line-height: 1;
  }
  @media (hover: hover) {
    .AfterAuthHeader .mobile-menu:hover SPAN:last-child {
      width: 20px;
    }
  }
}
@media (max-width: 414px) {
  .AfterAuthHeader .header-content .right-col .user .user-content .user-info .user-name {
    font-size: 16px;
  }

  .AfterAuthHeader .header-content .right-col .user .user-content .user-pic {
    margin-left: 8px;
    margin-right: 8px;
  }
  .AfterAuthHeader .header-content .logo {
    min-width: 98px;
  }
  .AfterAuthHeader .header-content .right-col .user .user-content .user-info .user-name,
  .AfterAuthHeader .header-content .right-col .user .user-content .user-info .user-post {
    width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .AfterAuthHeader .header-content .right-col .user .user-content .user-info {
    padding-right: 25px;
  }
  .AfterAuthHeader .header-content .right-col .user .user-content .user-info .arrowPointer {
    right: 7px;
  }
}

.MenuItem A {
  font-family: 'Calibre Regular';
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
}
.MenuItem.active A:after {
  content: '';
  width: 8px;
  height: 32px;
  border-radius: 100px;
  background-color: #013648;
  position: absolute;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  left: 0px;
  top: 10px;
}
.MenuItem {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  position: relative;
}
.MenuItem A IMG {
  width: 20px;
  margin-right: 20px;
  vertical-align: middle;
}
.MenuItem img.arrow {
  position: absolute;
  right: 18px;
  top: 16px;
  cursor: pointer;
}
.MenuItem.submenu {
  padding-top: 15px;
}
.MenuItem.submenu A {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  padding-left: 40px;
}
.MenuItem.submenu.sub-menu-active {
  background-color: #fafafb;
}
.pending-count.main{
  margin-left: 5px;
  background-color: #023648;
    color: #fff;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 50%;
    font-size: 16px;

}
.MenuItem.submenu .pending-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  top: 6px;
  padding: 0px 5px;
  border-radius: 4px;
  background-color: rgba(232, 93, 114, 0.1);
  height: 27px;
  color: #f2962d;
  margin-left: 4px;
}
.MenuItem.submenu.sub-menu-active .pending-count {
  color: #72787d;
}

.DoctorList .doctor-item IMG {
  width: 27px;
  height: 41px;
  border-radius: 2.5px;
  border: solid 0.6px rgba(1, 54, 72, 0.2);
  margin-right: 9px;
  object-fit: cover;
}
.DoctorList .doctor-item .doctor-info .doctor-name {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 20px;
}
.DoctorList .doctor-item .doctor-info > SPAN {
  width: 100%;
  display: inline-block;
}
.DoctorList .doctor-item {
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 15px;
}
.DoctorList .viewprofile.provider-list {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.35;
  color: #013648;
  position: absolute;
  bottom: 0px;
  left: 45px;
}

.DoctorList .doctor-detail {
  display: inline-flex;
  padding: 8px 9px 8px 9px;
  width: 100%;
  cursor: pointer;
}
.DoctorList .doctor-item.active {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fafafb;
}
.DoctorList .doctor-item.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: #013648;
}
.DoctorList .search-input .view-img {
  width: 16px;
  height: 16px;
  left: 11px;
  right: auto;
  top: 45%;
}
.DoctorList .search-input INPUT.form-control {
  padding-left: 37px;
  height: 38px;
  border-radius: 10px;
  background-color: #fafafb;
  border: 0;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
}
.DoctorList .search-input INPUT.form-control::placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 2.25;
  color: #013648;
}
.DoctorList .search-input.Input-control.form-group INPUT.form-control:focus,
.DoctorList .search-input.Input-control.form-group .form-control:not(:placeholder-shown) {
  padding-left: 37px;
  border: 0;
}
.DoctorList .h3 {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 2px;
  color: #72787d;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 18px;
  cursor: pointer;
  margin-bottom: 15px;
  padding-right: 15px;
}
.DoctorList .toggle-icon IMG {
  position: absolute;
  right: 0;
  top: 2px;
  transform: rotate(0deg);
  transition: 0.3s all linear;
}
.DoctorList .search-input {
  margin-bottom: 20px;
}
.DoctorList.open-accodian .toggle-icon .h3 IMG {
  transform: rotate(180deg);
}
.DoctorList {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.DoctorList.open-accodian {
  height: auto;
}
.DoctorList .h3 .toggle-icon {
  position: absolute;
  right: 0;
  top: 2px;
  transform: rotate(180deg);
  transition: 0.3s all linear;
  height: 14px;
  width: 14px;
}
.DoctorList.open-accodian .h3 .toggle-icon {
  transform: rotate(0deg);
}
@media (hover: hover) {
  .DoctorList .doctor-detail:hover {
    position: relative;
    background-color: rgba(1, 54, 72, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
  .DoctorList .viewprofile.provider-list:hover {
    font-weight: bold;
  }
}

.Sidebar {
  min-width: 250px;
  margin-top: 1px;
}
.Sidebar .Sidebar-inner-content {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 20px;
  overflow: auto;
  z-index: 3;
  background-color: #fff;
  border-top: 1px solid #f9f9f9;
  -webkit-overflow-scrolling: touch;
}
.Sidebar .medical-content {
  margin-top: 15px;
}
.Sidebar .medical-content .medical-text {
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 2px;
  color: #72787d;
  text-transform: uppercase;
  margin-bottom: 21px;
}
.Sidebar .medical-content .medical-name {
  position: relative;
  padding-left: 42px;
  font-family: 'Calibre Regular';
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 20px;
}
.Sidebar .medical-content .medical-name .medical-logo {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: -3px;
  border: 1px solid #fafafb;
  border-radius: 7px;
}
.Sidebar .medical-content .medical-name img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Sidebar .menu {
  margin-top: 20px;
  margin-bottom: 130px;
  margin-left: -20px;
  margin-right: -20px;
}
.Sidebar .viewprofile {
  opacity: 0.4;
  color: #013648;
  position: absolute;
  bottom: 2px;
  left: 42px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .Sidebar {
    min-width: 200px;
  }
  .Sidebar .Sidebar-inner-content {
    width: 200px;
    padding: 20px 10px;
  }
  .Sidebar .medical-content .medical-name {
    font-size: 18px;
  }
  .MenuItem a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .Sidebar {
    position: fixed;
    top: 70px;
    bottom: 0;
    left: -200px;
    transition: 0.3s all linear;
    z-index: 8;
    margin-top: 0;
  }
  .Sidebar .Sidebar-inner-content {
    position: static;
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.AfterAuthLayout .wrap-content-row {
  display: flex;
  padding-top: 70px;
  background-color: #fafafb;
  min-height: 100vh;
}

.AfterAuthSubHeader .main-inner-content .title {
  display: inline-block;
  display: flex;
  align-items: center;
}
.AfterAuthSubHeader .main-inner-content span {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.AfterAuthSubHeader .main-inner-content {
  padding: 34px 40px 44px;
}
.AfterAuthSubHeader .main-inner-content span {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  display: inline-block;
}
.AfterAuthSubHeader .main-inner-content .name {
  font-size: 36px;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  padding-left: 30px;
  position: relative;
  font-family: 'Heldane Display';
  top: 2px;
}
.AfterAuthSubHeader .main-inner-content .name:after {
  content: '';
  position: absolute;
  left: 15px;
  top: -7px;
  bottom: 0;
  width: 1px;
  background-color: rgba(1, 54, 72, 0.2);
}

@media (max-width: 1200px) {
  .AfterAuthSubHeader .main-inner-content {
    padding: 36px 20px 60px;
  }
  .AfterAuthSubHeader .main-inner-content .name {
    font-size: 24px;
  }
  .AfterAuthSubHeader .main-inner-content span {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .AfterAuthSubHeader .main-inner-content {
    padding: 36px 15px 60px;
  }
}
@media (max-width: 767px) {
  .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
    display: inline-block;
    width: 100%;
    margin-top: 5px;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
  .AfterAuthSubHeader .main-inner-content {
    padding: 20px 15px;
  }
}

.DashboardCount {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 32px;
  flex-wrap: wrap;
}
.DashboardCount .Counter-box {
  border-radius: 8px;
  background-color: #ffffff;
  text-align: center;
  padding: 22px 22px 17px;
  width: 23.5%;
  position: relative;
  margin-bottom: 10px;
}
.DashboardCount .Counter-box .count-title {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.DashboardCount .Counter-box .count {
  font-size: 48px;
  text-align: center;
  color: #013648;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DashboardCount .Counter-box .count .indicater {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  grid-gap: 7px;
  gap: 7px;
  line-height: 20px;
}
.DashboardCount .Counter-box .count .indicater.positive{
  color: #22B04C;
}
.DashboardCount .Counter-box .count .indicater.positive .shape{
  border-color: transparent transparent #22B04C;
  border-style: dashed dashed solid;
  border-width: 0 8px 8px;
  display: inline-block;
  font-size: 0;
  height: 0;
  line-height: 0;
  padding-top: 1px;
  position: relative;
  top: -1px;
  width: 0;
}
.DashboardCount .Counter-box .count .indicater.negative{
  color: #ED1C24;
  flex-direction: column-reverse;
  grid-gap: 0;
  gap: 0;
}
.DashboardCount .Counter-box .count .indicater.negative .shape{
  border-color: #ED1C24 transparent transparent;
  border-style: solid dashed dashed;
  border-width: 8px 8px 0;
  display: inline-block;
  font-size: 0;
  height: 0;
  line-height: 0;
  padding-top: 1px;
  position: relative;
  top: -1px;
  width: 0;
}
.DashboardCount .Counter-box .count .indicater.normal{
  color: #7F7F7F;
  flex-direction: row-reverse;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}
.DashboardCount .Counter-box .count .indicater.normal .shape{
  width: 20px;
  height: 6px;
  background: #7F7F7F;
  margin-bottom: 7px;
}
.DashboardCount .Counter-box .parcent IMG {
  width: 12px;
  margin-left: 9px;
}
.DashboardCount .Counter-box .parcent {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #199daf;
  font-family: 'Calibre Medium';
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.DashboardCount .Counter-box .decrement {
  color: #f37184;
}
.DashboardCount .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DashboardCount .info-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 0;
}
.DashboardCount .info-wrapper:hover .info-content{
 display: block;
}
@media (max-width: 1200px) {
  .DashboardCount .Counter-box {
      width: 24%;
      padding: 22px 15px 17px;
  } 
}
@media (max-width: 1024px) {
  .DashboardCount {
    flex-wrap: wrap;
    padding-top: 0;
  }
  .DashboardCount .Counter-box {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .DashboardCount {
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .DashboardCount .Counter-box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.Appointments-item {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  padding-right: 25px;
}
.Appointments-item .appoinment-icon {
  position: absolute;
  right: 0;
  top: 10px;
  width: 20px;
  height: 20px;
}
.Appointments-item .appoinment-icon IMG {
  width: 100%;
}
.Appointments-item:last-child {
  margin-bottom: 0;
}
.Appointments-item .Patient-name {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
}
.Appointments-item .appointment-time {
  width: 133px;
  height: 20px;
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.Appointments-item .icon IMG {
  width: 18px;
  height: 14px;
  opacity: 0.8;
}
.Appointments-item .icon {
  position: absolute;
  right: 0;
  top: 15px;
}

.UpcomingAppointments .box-panel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.UpcomingAppointments .box-header .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: normal;
}
.UpcomingAppointments .box-header {
  padding: 14px 20px 0;
  border-bottom: 1px solid #f1f1f5;
}
.UpcomingAppointments .box-footer {
  background-color: rgba(38, 172, 218, 0.1);
  text-align: center;
  padding: 14px;
  cursor: pointer;
}
.UpcomingAppointments .box-footer SPAN {
  color: #26acda;
  font-size: 16px;
  line-height: 1.25;
}
.UpcomingAppointments .box-body {
  padding: 20px;
}
.UpcomingAppointments .box-body.no-records {
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
}

@media (hover: hover) {
  .UpcomingAppointments .box-footer SPAN:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .UpcomingAppointments .box-header {
    padding: 14px 15px 0;
  }
  .UpcomingAppointments .box-body {
    padding: 14px 15px 0;
  }
}
@media (max-width: 767px) {
  .UpcomingAppointments .box-header {
    padding: 14px 10px 0;
  }
  .UpcomingAppointments .box-header .heading3 {
    font-size: 18px;
  }
  .UpcomingAppointments .box-body {
    padding: 20px 10px 0;
  }
  .Appointments-item .Patient-name {
    font-size: 16px;
  }
}

.ProgressBar {
  display: flex;
  height: 8px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: rgba(1, 54, 72, 0.2);
  border-radius: 0.25rem;
}
.ProgressBar .progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #013648;
  transition: width 0.6s ease;
}

.FinishProfile .box-panel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.FinishProfile .box-header .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: normal;
  margin-bottom: 0px;
}
.FinishProfile .box-header {
  padding: 10px 20px;
  border-bottom: 1px solid #f1f1f5;
}
.FinishProfile .box-body {
  padding: 20px;
}
.FinishProfile .box-body SPAN A {
  font-size: 20px;
  line-height: 1.2;
  color: #26acda;
  margin-bottom: 28px;
  display: inline-block;
}
.FinishProfile .box-body SPAN:last-child A {
  margin-bottom: 0px;
}
.FinishProfile .box-body span.completed SPAN {
  color: #013648;
  text-decoration: line-through;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 28px;
  display: inline-block;
  width: 100%;
}
.FinishProfile .ProgressBar {
  height: 4px;
  border-radius: 2.5px;
  background-color: #e2e2ea;
  width: 149px;
  max-width: 100%;
}
.FinishProfile .ProgressBar .progress-bar {
  background-color: #26acda;
  border-radius: 2.5px;
}

@media (hover: hover) {
  .FinishProfile .box-body SPAN A:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .FinishProfile .box-body SPAN A {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .FinishProfile .box-header .heading3 {
    font-size: 18px;
  }
  .FinishProfile .box-body SPAN ,
  .FinishProfile .box-body SPAN.completed SPAN {
    font-size: 16px;
    margin-bottom: 14px;
  }
}

.PracticeAccount .box-panel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.PracticeAccount .box-header .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: normal;
  display: flex;
  align-items: center;
}
.PracticeAccount .box-header .heading3 IMG {
  width: 24px;
  margin-right: 13px;
  position: relative;
  top: -2px;
}
.PracticeAccount .box-header {
  padding: 14px 20px 7px;
  border-bottom: 1px solid #f1f1f5;
}
.PracticeAccount .box-body {
  padding: 20px;
}
.PracticeAccount .box-body SPAN A {
  font-size: 20px;
  line-height: 1.2;
  color: #26acda;
  margin-bottom: 28px;
  display: inline-block;
}
.PracticeAccount .box-body SPAN:last-child A {
  margin-bottom: 0px;
}
.PracticeAccount .box-body SPAN.completed SPAN {
  color: #013648;
  text-decoration: line-through;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 28px;
  display: inline-block;
  width: 100%;
}
.PracticeAccount .provider .providerCount {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.PracticeAccount .provider A {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #26acda;
}
.PracticeAccount .paymentInfomation SPAN {
  color: #013648;
  display: inline-block;
  width: 100%;
}
.PracticeAccount .paymentInfomation {
  font-size: 16px;
  line-height: 1.13;
  letter-spacing: normal;
  color: #b2bcc4;
  margin-top: 17px;
}
.PracticeAccount .palnleftdays {
  height: 48px;
  border-radius: 8px;
  background-color: rgba(61, 147, 166, 0.1);
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #3d93a6;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 16px 0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (hover: hover) {
  .PracticeAccount .box-body SPAN A:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .PracticeAccount .box-body SPAN A {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .PracticeAccount .box-header .heading3 {
    font-size: 18px;
  }
  .PracticeAccount .box-body SPAN A,
  .PracticeAccount .box-body SPAN.completed SPAN {
    font-size: 16px;
    margin-bottom: 14px;
  }
}

.ChangeCardModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 575px;
  margin: auto;
}
.ChangeCardModal .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  background-color: #013648;
  /* border-bottom: 1px solid #f1f1f5; */
}
.ChangeCardModal .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.ChangeCardModal .modal-content-header .close IMG {
  width: 100%;
}
.ChangeCardModal .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.ChangeCardModal .modal-content-body {
  padding: 23px 41px 13px 47px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ChangeCardModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.ChangeCardModal .modal-content-footer {
  padding: 23px 47px 33px;
}
.ChangeCardModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.ChangeCardModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.ChangeCardModal .card-description {
  margin-top: 13px;
}
.ChangeCardModal .card-description .description {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #72787d;
}
.ChangeCardModal .stripe-input {
  margin-top: 20px;
  -webkit-box-align: center;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 0.2em;
  padding: 8px 6px;
  outline: 0;
  margin-bottom: 25px;
}
.ChangeCardModal .card-input {
  margin-top: 20px;
  align-items: center;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 0.2em;
  display: flex;
  padding: 8px 6px;
  outline: 0;
  margin-bottom: 25px;
}
.ChangeCardModal .card-input .Input-control.form-group {
  position: relative;
  top: 2px;
}
.ChangeCardModal .card-input .Input-control.form-group INPUT {
  border: unset;
  margin: unset;
  padding: unset;
  outline: unset;
  height: auto;
}
.ChangeCardModal .card-input .Input-control.form-group INPUT:focus {
  outline: 0;
  border: 0;
}
.ChangeCardModal .card-input IMG {
  margin-right: 5px;
}
.ChangeCardModal .card-input .Input-control.form-group {
  margin: unset;
}
.ChangeCardModal .card-input .CVC {
  width: 10%;
}
.ChangeCardModal .card-input .expiryDate {
  width: 15%;
}
.ChangeCardModal .card-input .cardnumber {
  width: 70%;
}
.ChangeCardModal .modal-content-footer BUTTON {
  height: 32px;
  border-radius: 2px;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
  background-color: #f1f1f1;
  border: 0;
  margin-right: 10px;
  padding: 8px 15px 9px;
  cursor: pointer;
  outline: 0;
}
.ChangeCardModal .modal-content-footer .save {
  background-color: #26acda;
  color: #ffffff;
}
.ChangeCardModal .modal-content-footer .save:disabled {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .ChangeCardModal .modal-content-body .Input-control .view-img {
    top: calc(100% - 1.8rem);
  }
  .ChangeCardModal .modal-content-footer {
    padding: 23px 20px 33px;
  }
  .ChangeCardModal .modal-content-body,
  .ChangeCardModal .modal-content-header {
    padding: 23px 20px 13px 20px;
  }
  @supports (-webkit-touch-callout: none) {
    .ChangeCardModal .modal-content-footer .CircleButton.WhiteButton {
      padding-top: 2px;
    }
  }
}
@media (max-width: 575px) {
  .ChangeCardModal {
    width: 100%;
  }
  .ChangeCardModal .modal-content-body {
    padding: 20px;
  }
  .ChangeCardModal .modal-content-body .password-information {
    width: 100%;
  }
  .ChangeCardModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .ChangeCardModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .ChangeCardModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}

.DisconnectSocialConform .CircleButton.WhiteButton{
  line-height: 16px;
  font-size: 16px;
}
.PaymentModal-PlanSelection {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
  position: relative;
}
.PaymentModal-PlanSelection .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.PaymentModal-PlanSelection .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.PaymentModal-PlanSelection .modal-content-header .close IMG {
  width: 100%;
}
.PaymentModal-PlanSelection .modal-content-header .close.logout{
  width: 70px;
  display: flex;
  align-items: center;
 }
 .PaymentModal-PlanSelection .modal-content-header .close.logout span{
  display: inline-block;
  margin-top: 5px;
  padding-left: 5px;
  color: #fff;
 }
.PaymentModal-PlanSelection .modal-content-header .close.logout img{
  width: 20px;
    filter:invert(100%) sepia(0%) saturate(7490%) hue-rotate(344deg) brightness(153%) contrast(104%)
}
.PaymentModal-PlanSelection .list-history {
  padding: 33px;
}
ul.eachHistory {
  list-style: none;
  padding: 0;
}
li.eachHistory {
  display: flex;
  padding: 5px 10px;
  grid-gap: 10px;
  gap: 10px;
}
li.eachHistory div{
  flex: 0 0 1;
}
li.eachHistory:nth-child(odd) {
  background-color: #eaeaea;
}
li.eachHistory:nth-child(even) {
  background-color: #f9f9f9;
}
li.eachHistory img {
  width: 20px;
  margin-right: 15px;
}
li.eachHistory p {
  margin-bottom: 5px;
}
.PaymentModal-PlanSelection .modal-content-body {
  overflow: scroll;
  min-height: 350px;
  max-height: 70vh;
  scroll-behavior: smooth;
}
.PaymentModal-PlanSelection .modal-content-body .rtable {
  overflow: scroll;
  max-height: 350px;
}
.PaymentModal-PlanSelection .heading3 {
  font-size: 36px;
  text-transform: capitalize;
  color: #fff;
}
.mt-10 {
  margin-top: 10px;
}
.PaymentSession-ParentDiv{
  padding: 30px;
}
.PaymentSession-ParentDiv .paymentSession .product-list{
  width: auto;
  justify-content: center;
}
.PaymentSession-ParentDiv .stripe-ccinput,
.PaymentSession-ParentDiv .paymentSession .promocodediv{
  max-width: 400px;
}
#PaymentSession-ParentDiv-id .paymentSession .stripe-input{
  overflow: hidden;
}
@media screen and (max-width: 850px) {
  .PaymentModal-PlanSelection {
    width: 750px;
  }
}
@media screen and (max-width: 766px) {
  .PaymentModal-PlanSelection {
    width: 95%;
  }
}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 766px) {
  .select-date-content.end-date {
   position: relative !important;
  }
}


.paymentSession {
  min-height: 400px;
}
.h3 {
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.paymentSession .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 26px;
}
.paymentSession .product-list{
display: flex;
flex-direction: row;
width: 700px;
margin-bottom: 15px;

grid-gap: 5px;

gap: 5px;
}
.paymentSession .plan-card{
padding: 20px;
background-color: #02364805;
width: 280px;
cursor: pointer;
display: flex;
flex-direction: column;
}
.paymentSession .plan-card:hover{
background-color: #859ba36b;
}
.paymentSession .plan-card.active{
  background-color: #859ba31f;
}
.paymentSession .plan-card .logo img{
width: 150px;
}
.paymentSession .plan-card .tilte h3{
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}

.paymentSession .plan-card .discription p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #859BA3;
  margin-bottom: 26px;
  /* min-height: 80px; */
}
.paymentSession .plan-card .pricing h3 {
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  margin-top: 13px;
  font-family: HeldaneDisplay;
  display: flex;
}
.paymentSession .plan-card .pricing .delelement h3,
.paymentSession .plan-card .pricing .delelement{
 color: red;
 font-size: 20px;
}

.paymentSession .plan-card .subscribe{
  height: 50px !important;
  margin-right: 0!important;
  width: 100% !important;
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  /* margin: 0px 15px 15px; */
  cursor: pointer;
  text-align: center;
  width: 27.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border: 0px;
  font-family: "Heldane Display";
  background-color: rgb(1, 54, 72);
}
.paymentSession .plan-card .pricing h3 span.price-label{
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color:  #859BA3;
  margin-bottom: 13px;
  
  font-family: HeldaneDisplay;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.paymentSession .plan-card .pricing h3.main-price{
  margin-bottom: 0;
}
.paymentSession .plan-card .pricing  .delelement h3{
  margin: 0;
}
.paymentSession .plan-card .pricing  .delelement h3 span.price-label{
  color: red;
  font-size: 10px;
}
.paymentSession .plan-card .subtitle h4{
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color:  #859BA3;
  margin-bottom: 5px;
  font-family: HeldaneDisplay;
}
.paymentSession .stripe-input {
  border-width: 1px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  padding: 13px 15px 0px 15px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  overflow-y: hidden;
}
.paymentSession .product-info ul{
  padding-left: 5px;
}
.paymentSession .product-info ul li{
  list-style-type: none;
  display: flex;line-height: 2;

}
.paymentSession .product-info ul li img.bullet{
 width: 20px;
 margin-right: 5px;
}
.paymentSession .product-info ul li span.text{
  padding-top: 5px;
  color:  #859BA3;
  
 }
 .paymentSession .promobaner{
  padding: 20px;
  border: 10px solid #55BFDB;
  color: #55BFDB;
  margin-bottom: 15px;
 }
 .paymentSession .promobaner h4{
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 10px;
  
  font-family: HeldaneDisplay;
 }
 .paymentSession .promobaner h6{
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 5px;
  
  font-family: HeldaneDisplay;
 }
 .paymentSession .promobaner p{
  margin: 0;
 }
 .paymentSession .selectedPlanParent{
width: 700px;
padding-top: 20px;
padding-bottom: 20px;
border-top: 1px solid #859ba354;
border-bottom: 1px solid #859ba354;
margin-bottom: 15px;
 }
.paymentSession .selectedPlanParent .selectedPlan{
  /* padding-left: 30px; */
  display: flex;
  flex-direction: column;
  width: 400px;
}
.paymentSession .selectedPlanParent .selectedPlan div{
  display: grid;
  grid-template-columns: 40% 60%;
  margin-bottom: 7px;
}
.paymentSession .selectedPlanParent .selectedPlan div label{
  font-size: 20px;
  color: #013648;
}
.paymentSession .promocodediv{
  display: flex;
  margin-bottom: 30px;
  align-items: baseline;
  justify-content: space-between;
}
.paymentSession .promocodediv button{
  height: 50px !important;
  margin-right: 0!important;
  width: 110px;
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 15px 15px;
  cursor: pointer;
  text-align: center;
  width: 27.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border: 0px;
  font-family: "Heldane Display";
  background-color: rgb(1, 54, 72);
}
.paymentSession .promocodediv button:disabled{
  opacity: 0.5;
color: #d8d8d8;
background-color: #013648;
}
.paymentSession .promocodediv p.error{
color: red;
margin: 0;
margin-top: 5px;
}
.paymentSession .promocodediv  p.success{
  color: #55BFDB;
  margin: 0;
  margin-top: 5px;
  }
.paymentSession .elementlabel {
  font-family: 'Calibre Medium';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #013648;
}
.paymentSession .promocodediv input{
  border-width: 1px;
    border: solid 1.5px rgba(1, 54, 72, 0.2);
    padding: 5px 15px 0px 15px;
    height: 52px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 18px;
    font-family: 'Calibre Regular';
    line-height: 1.56;
    letter-spacing: -0.34px;
    color: #013648;
    width: 275px;
}
@media (max-width: 991px) {
  .paymentSession .plan-card {
    width: 240px;
  }
}
@media (max-width: 700px) {
  .paymentSession .selectedPlanParent,
  .paymentSession .product-list{
    width: 100%;
  }
 
  
}
@media (max-width: 640px) {
  .paymentSession .product-list{
   flex-direction: column;

  }
  .paymentSession .promocodediv{
    display: grid;
    grid-template-columns: 1fr;
  }
  .paymentSession .promocodediv button {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .paymentSession .promocodediv input{
    width: 100%;
  }
  .paymentSession .plan-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .paymentSession .selectedPlanParent .selectedPlan{
    width: 100%;
  }
  .paymentSession .CircleButton.WhiteButton{
    width: 100%;
  }
}

.AfterAuthDashboard .dashboard-panle {
  display: flex;
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.AfterAuthDashboard {
  width: calc(100% - 250px);
}
.AfterAuthDashboard .panel-left {
  width: 100%;
  z-index: 5;
}
.AfterAuthDashboard .panel-right {
  padding-left: 40px;
  min-width: 274px;
  width: 274px;
}
.AfterAuthDashboard .monthly-activity {
  display: block;
  width: 100%;
}
.AfterAuthDashboard .monthly-activity .AppointmentDetail {
  margin-bottom: 15px;
}
.AfterAuthDashboard
  .monthly-activity
  .AppointmentDetail
  .Patient-status-control
  .Select-control.form-group
  select {
  padding-top: 0px;
}
.AfterAuthDashboard .monthly-activity .title-content .title {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
}
.AfterAuthDashboard .monthly-activity .title-content .title:after {
  content: '';
  height: 1px;
  background-color: #f1f1f5;
  left: 0;
  right: 0;
  top: 8px;
  position: absolute;
}
.AfterAuthDashboard .monthly-activity .title-content .title SPAN {
  display: inline-block;
  padding: 0px 17px;
  background-color: #fafafb;
  z-index: 1;
  position: relative;
}
.AfterAuthDashboard .monthly-activity .title-content {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
}
.AfterAuthDashboard .monthly-activity .title-content .Select-control {
  margin-bottom: 0;
  margin-left: 16px;
}
.AfterAuthDashboard .monthly-activity .title-content .Select-control SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 22px;
  padding-left: 16px;
  width: 115px;
  color: #013648;
}
.AfterAuthDashboard .monthly-activity .title-content .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 7px;
}
.AfterAuthDashboard .monthly-activity .title-content .Select-control.form-group:after {
  display: none;
}
.AfterAuthDashboard .dashboard-panle .monthly-activity .InfiniteScroll {
  margin-right: -10px;
}

@media (max-width: 1200px) {
  .AfterAuthDashboard .dashboard-panle {
    display: flex;
    padding: 0px 20px 40px 20px;
  }
  .AfterAuthDashboard .panel-right {
    padding-left: 20px;
  }
  .AfterAuthDashboard .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .AfterAuthDashboard .AppointmentDetail .Patient-information {
    padding-right: 0;
    border-right: 0;
  }
  .AfterAuthDashboard .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 30px;
    min-width: auto;
  }
}

@media (max-width: 1024px) {
  .AfterAuthDashboard {
    width: calc(100% - 200px);
  }
}

@media (max-width: 767px) {
  .AfterAuthDashboard .dashboard-panle {
    flex-wrap: wrap;
    padding: 0px 15px;
  }
  .AfterAuthDashboard .panel-right {
    padding-left: 0px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .AfterAuthDashboard .panel-right > DIV {
    width: 48%;
    display: inline-block;
    vertical-align: top;
  }
  .AfterAuthDashboard .AppointmentDetail {
    margin-bottom: 30px;
  }
  .AfterAuthDashboard {
    width: 100%;
  }
  .AfterAuthDashboard .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
}

@media (max-width: 414px) {
  .AfterAuthDashboard .panel-right > DIV {
    width: 100%;
  }
}

.AccountTab {
  background-color: #ffffff;
  border: solid 1px #e2e2ea;
  border-radius: 8px;
  padding: 35px 30px 138px 40px;
  width: 620px;
  max-width: 100%;
}
.AccountTab .password-instruction-text {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Calibre Regular';
}
.AccountTab .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin: 0 0 16px;
}
.AccountTab .password-form .h3 {
  margin-bottom: 24px;
}
.AccountTab .email-form .h3 {
  margin: 0 0 24px;
}
.AccountTab .password-form .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 8px;
}
.AccountTab .password-form .Input-control.form-group {
  margin-bottom: 22px;
}
.AccountTab .password-form .save-btn {
  margin-top: 11px;
}
.AccountTab .Input-control.form-group {
  width: 398px;
}
.AccountTab .edit-link {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #3dbce7;
  padding-left: 21px;
  margin-bottom: 20px;
  cursor: pointer;
  /* font-family: 'Calibre Medium'; */
}
.AccountTab .cancel-link {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #e85d72;
  padding-left: 22px;
  margin-bottom: 20px;
  cursor: pointer;
}
.AccountTab .email-form {
  margin-bottom: 18px;
}
.AccountTab .form-row {
  display: flex;
  align-items: center;
}
.AccountTab .save-btn .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  letter-spacing: normal;
  margin-right: 34px;
  line-height: 32px;
}
.AccountTab .update-wrapper {
  display: flex;
  margin-bottom: 40px;
}
.AccountTab .update-wrapper .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  margin-right: 19px;
}
.AccountTab .update-wrapper .para {
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: normal;
  color: #72787d;
  font-family: 'Calibre Medium';
  font-weight: 500;
  max-width: 268px;
}
.AccountTab .accountEmail-form .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 16px;
}
@media screen and (max-width: 1024px) {
  .AccountTab {
    width: 100%;
  }
  .AccountTab .Input-control.form-group {
    width: 100%;
  }
  .AccountTab .form-row {
    flex-wrap: wrap;
  }
  .AccountTab .edit-link,
  .AccountTab .cancel-link {
    padding-left: 0;
    margin-left: auto;
  }
}
@media screen and (max-width: 767px) {
  .AccountTab {
    padding: 20px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AccountTab .update-wrapper .CircleButton.WhiteButton,
  .AccountTab .save-btn .CircleButton.WhiteButton {
    padding-top: 1px;
  }
}

.MyImageCrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.MyImageCrop .modal-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 20px 20px 20px 42px;
}
.MyImageCrop .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.MyImageCrop .modal-content-header .close img {
  width: 100%;
}
.MyImageCrop .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
  margin-bottom: 0;
}
.MyImageCrop .modal-content-body {
  text-align: center;
}
.MyImageCrop .modal-content-body{
  padding: 10px;
  /* overflow: scroll; */
}

.MyImageCrop .modal-content-body,
.MyImageCrop .ReactCrop {
  max-height: calc(100vh - 200px);
}
.cropinfotext{
  width: 100%;
  display: block;
}
.MyImageCrop .modal-content-body{
  height: auto;
}

.MyImageCrop .modal-content-footer {
  padding: 10px 15px 10px;
  text-align: right;
  margin-top: 10px;
}
.MyImageCrop .modal-content-footer .CircleButton {
  height: auto;
  line-height: normal;
  margin-left: 15px;
  min-width: 111px;
  height: 39px;
  padding-top: 0;
  line-height: 41px;
  margin-bottom: 10px;
}
.MyImageCrop .content {
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  background-color: white;
  overflow: hidden;
}
.MyImageCrop .ReactCrop .ReactCrop__image,
.MyImageCrop .ReactCrop > div {
  /* height: 100%; */
  max-height: 55vh;
}

@media (max-width: 767px) {
  .MyImageCrop .content{
    width: 90%;
  }
  .MyImageCrop .modal-content-footer{
    text-align: center;
  }
}
@media (max-width: 550px){
  .MyImageCrop .ReactCrop .ReactCrop__image,
.MyImageCrop .ReactCrop > div {
  max-height: 55vh;
}
}
.PracticeLogoWrapper .PracticeLogo {
  display: flex;
}
.PracticeLogoWrapper {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 22px 70px 40px;
  margin-bottom: 20px;
}
.PracticeLogoWrapper .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #192022;
  margin-bottom: 44px;
}
.PracticeLogoWrapper .logo-img {
  min-width: 120px;
  width: 120px;
  height: 120px;
  border-radius: 5.6px;
  border: solid 0.8px #e2e2ea;
  display: inline-block;
  display: flex;
}
.PracticeLogoWrapper .logo-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.PracticeLogoWrapper .logo-inner-content .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.PracticeLogoWrapper .logo-inner-content {
  padding-left: 20px;
  width: calc(100% - 120px);
  padding-top: 10px;
}
.PracticeLogoWrapper .logo-inner-content .image-size {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 14px;
}
.PracticeLogoWrapper .circle-btn {
  height: 48px;
  border-radius: 24px;
  background-color: rgba(143, 146, 161, 0.2);
  border: 0;
  width: 117px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  text-align: center;
  color: #72787d;
  padding: 7px 5px 5px;
  cursor: pointer;
}
.PracticeLogoWrapper .circle-btn:focus {
  outline: 0;
}
.PracticeLogoWrapper .circle-btn.blue-btn {
  background-color: #26acda;
  color: #fff;
  margin-right: 10px;
}
.PracticeLogoWrapper .fileupload {
  position: relative;
  margin-right: 9px;
  margin-bottom: 5px;
}
.PracticeLogoWrapper .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.PracticeLogoWrapper .fileupload .fileupload-btn button:focus {
  outline: 0;
}

.PracticeLogoWrapper .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.PracticeLogoWrapper .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.PracticeLogoWrapper .logo-content-action {
  display: flex;
  flex-wrap: wrap;
}

@media (hover: hover) {
  .PracticeLogoWrapper .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .PracticeLogoWrapper {
    padding: 20px;
  }
  .PracticeLogoWrapper .logo-inner-content {
    padding-left: 15px;
  }
  .PracticeLogoWrapper .fileupload .fileupload-btn button {
    width: 148px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeLogoWrapper .fileupload .fileupload-btn button {
    width: 140px;
  }
  .PracticeLogoWrapper .circle-btn {
    width: 100px;
  }
  .PracticeLogoWrapper .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .PracticeLogoWrapper {
    padding: 10px;
  }
}

@media screen and (max-width: 375px) {
  .PracticeLogoWrapper .logo-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
}

@supports (-webkit-touch-callout: none) {
  .PracticeLogoWrapper .fileupload .fileupload-btn button {
    padding-top: 2px;
  }
}

.InputList .add-input {
  margin-top: 7px;
  margin-bottom: 37px;
}
.InputList .add-input span {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
}
.InputList .add-input span img {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.InputList .input-list-item {
  position: relative;
}
.InputList .input-list-item .remove-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #f37184;
  position: absolute;
  right: 15px;
  top: 17px;
  cursor: pointer;
}
.InputList .input-list-item .remove-link img {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}
.InputList .input-list-item .Input-control.form-group .form-control {
  padding-right: 77px;
}
.InputList .input-list-label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 14px;
}
/* .InputList .input-list-label.parent-title {
  font-size: 18px;
} */
.InputList .input-list-label.parent-title .subTitle {
  font-size: 13.3px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #0136488f;
  
}
.InputList .input-list-label .inner-label {
  color: #013648;
  opacity: 0.4;
}
.InputList .input-list-item .Input-control.form-group {
  margin-bottom: 20px;
}
.InputList .status-icon IMG {
  top: -23px;
  right: 3px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.PracticeOverview {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 40px 40px;
  margin-bottom: 40px;
}
.PracticeOverview .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 40px;
}
.PracticeLocation .Input-control,
.practiceOverview-inner .Input-control{
  scroll-margin-top: 80px;
}
.practiceOverview-inner .Textarea{
  scroll-margin-top: 80px;
}

.PracticeOverview .Select-control .arrow {
  top: 55px;
}
.PracticeOverview .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  margin-top: 17px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 1px;
  border: 1px solid #013648;
}
.PracticeOverview .Textarea.form-group TEXTAREA {
  height: 160px;
}
.PracticeOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
  padding-left: 25px;
}
.PracticeOverview .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.PracticeOverview .Input-control.form-group {
  margin-bottom: 36px;
}
.PracticeOverview .Textarea.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.PracticeOverview .Textarea.form-group {
  margin-bottom: 34px;
}
.PracticeOverview .Select-control .label {
  line-height: 1.25;
  margin-bottom: 14px;
  display: block;
}

@media screen and (max-width: 1366px) {
  .PracticeOverview {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeOverview .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PracticeOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px !important;
  }
}

.PracticeLocation {
  border-radius: 2px;
  background-color: #f7f9f9;
  margin-bottom: 30px;
}
.PracticeLocation input:disabled {
  background-color: #f1f1f5 !important;
}
.PracticeLocation:last-child {
  margin-bottom: 0;
}
.PracticeLocation .location-content {
  display: none;
  padding: 9px 29px 40px;
}
.PracticeLocation.show .location-content {
  display: block;
}
.PracticeLocation .location-header {
  position: relative;
  font-size: 24px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #013648;
  padding: 31px 43px 18px 29px;
  border-radius: 2px;
  min-height: 82px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.PracticeLocation .location-header:after {
  content: '';
  position: absolute;
  right: 31px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #013648;
}
.PracticeLocation.show .location-header:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #013648;
  border-top: 0;
}
.PracticeLocation .toggle-icon {
  margin-right: 29px;
  position: relative;
  width: 19px;
  height: 19px;
}
.PracticeLocation .toggle-icon SPAN {
  height: 2px;
  border-radius: 0.9px;
  background-color: #013648;
  opacity: 0.2;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
}
.PracticeLocation .toggle-icon SPAN:first-child {
  top: 0;
}
.PracticeLocation .toggle-icon SPAN:nth-child(2) {
  top: 4px;
}
.PracticeLocation .toggle-icon SPAN:nth-child(3) {
  top: 9px;
}
.PracticeLocation .website-label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 14px;
}
.PracticeLocation .website-label .inner-label {
  color: rgba(1, 54, 72, 0.4);
}
.PracticeLocation .Input-control.form-group .label .inner-label {
  color: rgba(1, 54, 72, 0.4);
}
.PracticeLocation .add-email SPAN {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #3d93a6;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 40px;
}
.PracticeLocation .add-email SPAN IMG {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
}
.PracticeLocation .form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PracticeLocation .form-row .column-9 {
  width: calc(100% - 98px);
  padding-right: 11px;
}
.PracticeLocation .form-row .column-3 {
  width: 98px;
}
.PracticeLocation .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
}
.PracticeLocation .save-delete-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PracticeLocation .save-delete-wrapper .delete-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #f37184;
  margin-left: 15px;
  cursor: pointer;
}
.PracticeLocation .Input-control.form-group.firstEmail {
  margin-bottom: 20px;
}
.PracticeLocation .Input-control .correct-icon {
  width: 16px;
  height: 16px;
  background-color: #3d93a6;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 6px;
}
.PracticeLocation .Input-control .correct-icon IMG {
  width: 9px;
}
.PracticeLocation .Input-control .close-icon {
  width: 16px;
  height: 16px;
  background-color: #e85d72;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 6px;
}
.PracticeLocation .Input-control .close-icon IMG {
  width: 8px;
}
.PracticeLocation .Select-control.form-group SELECT.active-dropdown {
  border-radius: 4px;
  height: 38px;
  border-width: 1px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #3d93a6;
}
.PracticeLocation .Select-control.form-group SELECT.active-dropdown ~ .arrow {
  border: 0;
  border-left: solid 1px #e2e2ea;
  top: 24px;
  right: 0px;
  width: 36px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PracticeLocation .Select-control.form-group SELECT.active-dropdown ~ .arrow:after {
  content: '';
  border: 8px solid rgba(143, 146, 161, 0.8);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: relative;
  top: 4px;
}
.PracticeLocation .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 6px;
}
.PracticeLocation .Input-control.form-group {
  margin-bottom: 36px;
}
.PracticeLocation .location-form .Input-control.form-group {
  margin-bottom: 14px;
}
.PracticeLocation .location-form .input-list-label {
  margin-bottom: 9px;
}
.PracticeLocation .location-form .Input-control.form-group.location-name {
  margin-bottom: 36px;
}
.PracticeLocation .location-form .Select-control.form-group {
  margin-top: 25px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1366px) {
  .PracticeLocation .location-header {
    padding: 18px 43px 18px 20px;
  }
  .PracticeLocation .location-content {
    padding: 6px 20px 40px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeLocation .location-header {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .PracticeLocation .save-delete-wrapper {
    flex-wrap: wrap;
  }
  .PracticeLocation .save-delete-wrapper .delete-link {
    margin-top: 15px;
    margin-left: 0;
  }
}

.SwitchToggle {
  margin-bottom: 25px;
  padding-left: 55px;
  position: relative;
}
.SwitchToggle .switch-label {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
}
.SwitchToggle .switchContainer {
  position: absolute;
  left: 0;
  top: -4px;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.SwitchToggle .switchContainer input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.SwitchToggle .switchContainer .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b2c2c8;
  transition: 0.4s;
}
.SwitchToggle .switchContainer .slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: all 0.3s ease;
}
.SwitchToggle .switchContainer input:checked + .slider {
  background-color: #26acda;
}
.SwitchToggle .switchContainer input:focus + .slider {
  box-shadow: 0 0 1px #26acda;
}
.SwitchToggle .switchContainer input:checked + .slider:before {
  transform: translateX(26px);
  left: -3px;
}
.SwitchToggle .switchContainer .slider.round {
  border-radius: 13px;
}
.SwitchToggle .switchContainer .slider.round:before {
  border-radius: 13px;
}
.SwitchToggle.switchImage {
  padding-left: 75px;
}
.SwitchToggle.switchImage .switchContainer {
  height: 31px;
  width: 67px;
}
.SwitchToggle.switchImage .switch-label {
  font-size: 16px;
  line-height: 1.26;
  letter-spacing: -0.3px;
}
.SwitchToggle.switchImage .switchContainer .slider img {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transition: all 0.3s ease;
}
.SwitchToggle.switchImage .switchContainer .slider img:last-child {
  display: none;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider {
  background-color: #8d77ba;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider img {
  left: auto;
  right: 0;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider img:first-child {
  display: none;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider img:last-child {
  display: block;
}
.SwitchToggle.switchImage .switchContainer .slider.round {
  border-radius: 15.5px;
}
.SwitchToggle.switchImage .switchContainer .slider {
  border: solid 1px #8d77ba;
  background-color: rgba(141, 119, 186, 0.15);
}
.SwitchToggle.switchImage .switchContainer .slider:before {
  display: none;
}
.SwitchToggle.switch-type-2 {
  display: inline-block;
}
.SwitchToggle.switch-type-2 .switchContainer input:checked + .slider:before {
  transform: translateX(8px);
}
.SwitchToggle.switch-type-2 .switchContainer .slider.round:before {
  transform: translateX(39px);
}
.SwitchToggle.switch-type-2 .switchContainer .slider:before {
  height: 27px;
  width: 27px;
}
.SwitchToggle.switch-type-2 .switchContainer {
  width: 74px;
  height: 33px;
}
.SwitchToggle.switch-type-2 .switchContainer .slider.round {
  border-radius: 16px;
}
.SwitchToggle.switch-type-2 .switchContainer input:checked + .slider:after {
  content: 'On';
  font-family: 'Calibre Medium';
  font-size: 18px;
  line-height: 1.78;
  text-align: right;
  color: #ffffff;
  position: absolute;
  right: 14px;
  top: 3px;
}

.PracticeLocations {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 36px 40px 40px;
  margin-bottom: 40px;
}
.PracticeLocations .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 44px;
}
.PracticeLocations .add-location {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  width: 100%;
  height: 92px;
  border-radius: 2px;
  border: dashed 1px #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.PracticeLocations .add-location .plus-icon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: rgba(1, 54, 72, 0.2);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -3px;
}
.PracticeLocations .add-location .plus-icon img {
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
}

@media screen and (max-width: 1366px) {
  .PracticeLocations {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeLocations .h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) {
  .PracticeLocations {
    padding: 10px;
  }
}

.PatientRegistration {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 20px 40px 40px;
  margin-bottom: 40px;
}
.PatientRegistration .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 39px;
}
.PatientRegistration .label-content {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
}
.PatientRegistration .label-content span {
  margin-right: 10px;
}
.PatientRegistration .label-content .uploadfile-text {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.PatientRegistration .fileupload {
  min-height: 52px;
  border-radius: 2px;
  background-color: #f7f9f9;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  margin-top: 14px;
  padding-right: 142px;
  word-break: break-all;
}
.PatientRegistration .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 4px;
}
.PatientRegistration .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}
.PatientRegistration .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.PatientRegistration-inner .removebtn {
  float: right;
  display: inline-block;
  color: #f37184;
  cursor: pointer;
}
@media screen and (max-width: 1366px) {
  .PatientRegistration {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .PatientRegistration .h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) {
  .PatientRegistration {
    padding: 10px;
  }
}

.PracticeTab {
  display: flex;
  margin: 0 -15px;
}
.PracticeTab .leftpanle {
  padding: 0 15px;
  width: 45.5%;
}
.PracticeTab .rightpanle {
  padding: 0 15px;
  width: 54.5%;
}

@media (max-width: 1280px) {
  .PracticeTab {
    margin: 0 -5px;
  }
  .PracticeTab .leftpanle,
  .PracticeTab .rightpanle {
    padding: 0 5px;
  }
}

@media (max-width: 1024px) {
  .PracticeTab {
    flex-wrap: wrap;
    margin: 0;
  }
  .PracticeTab .leftpanle,
  .PracticeTab .rightpanle {
    width: 100%;
    padding: 0;
  }
}

.ProviderItem {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #f7f9f9;
}
.ProviderItem .provider-inner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 76.4px;
  padding: 15px 88px 15px 83px;
  cursor: pointer;
}
.ProviderItem .provider-image {
  min-width: 35px;
  width: 35px;
  display: flex;
  height: 54px;
  position: absolute;
  left: 20px;
  align-items: center;
}
.ProviderItem .provider-image img {
  border-radius: 3px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ProviderItem .name {
  position: relative;
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
}
.ProviderItem .dot-icon {
  width: 20px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 40%;
  background-color: #f7f9f9;
}
.ProviderItem .dot-icon img {
  width: 100%;
}
.ProviderItem .dot-icon .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
}
.ProviderItem .dot-icon.show-menu .dropdown-menu {
  display: block;
}
.ProviderItem .dot-icon .dropdown-menu span {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ProviderItem .dot-icon .dropdown-menu span.remove-user {
  color: #e85d72;
}
.ProviderItem .status {
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #013648;
}
.ProviderItem .status .status-text {
  display: inline-block;
  color: #3d93a6;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderItem .status .active {
  color: #3d93a6;
}
.ProviderItem .status .pending {
  color: #f2962d;
}
.ProviderItem .status .inactive {
  color: #e85d72;
}
.ProviderItem .email {
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #72787d;
}
.ProviderItem .profile-progress {
  display: flex;
  align-items: center;
}
.ProviderItem .profile-progress .name {
  font-size: 16px;
  line-height: 2.13;
  letter-spacing: -0.2px;
  color: #013648;
  width: 115px;
  margin-right: 12px;
  text-align: right;
}
.ProviderItem .profile-progress .number {
  font-size: 19px;
  line-height: 0.71;
  letter-spacing: normal;
  color: #013648;
  margin-left: 22px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.ProviderItem .profile-progress .progressBar .progress-bar {
  background-color: #3d93a6;
  border-radius: 7px;
}
.ProviderItem .profile-progress .progressbar {
  width: 105px;
  border-radius: 7px;
}
.ProviderItem .profile-progress.active .progressbar .ProgressBar .progress-bar {
  background-color: #3d93a6;
}
.ProviderItem .profile-progress.active .name {
  color: #013648;
}
.ProviderItem .profile-progress.active .number {
  color: #013648;
}
.ProviderItem .profile-progress.inactive .progressbar .ProgressBar .progress-bar {
  background-color: #e85d72;
}
.ProviderItem .profile-progress.inactive .name {
  color: #e85d72;
}
.ProviderItem .profile-progress.inactive .number {
  color: #e85d72;
}
.ProviderItem .profile-progress.pending .progressbar .ProgressBar .progress-bar {
  background-color: #f2962d;
}
.ProviderItem .profile-progress.pending .name {
  color: #f2962d;
}
.ProviderItem .profile-progress.pending .number {
  color: #f2962d;
}
.ProviderItem .member-detail {
  cursor: pointer;
  width: 55%;
}
.ProviderItem .member-rating {
  display: flex;
  align-items: center;
  position: relative;
}
.ProviderItem .overlay {
  z-index: 8;
}

@media (hover: hover) {
  .ProviderItem .dot-icon .dropdown-menu span:hover {
    background-color: #f2f2f2;
  }
}

@media screen and (max-width: 1199px) {
  .ProviderItem .member-detail {
    width: 100%;
  }
  .ProviderItem .profile-progress .name {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .ProviderItem .name {
    font-size: 16px;
  }
  .ProviderItem .dot-icon {
    top: 14%;
  }
  .ProviderItem .profile-progress .progressbar {
    width: 82px;
  }
  .ProviderItem .status .pending {
    display: inline-block;
    width: 100%;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 414px) {
  .ProviderItem .profile-progress {
    flex-wrap: wrap;
  }
  .ProviderItem .provider-inner-content {
    padding: 15px 32px 15px 50px;
  }
  .ProviderItem .provider-image {
    left: 7px;
  }
  .ProviderItem .dot-icon {
    right: 8px;
  }
  .ProviderItem .profile-progress .name {
    display: inline-block;
    width: 100%;
  }
}

.RemoveStripeProvider {
  width: 570px;
}
.RemoveStripeProvider .modal-content-footer .CircleButton.WhiteButton {
  width: 150px;
}
.RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton{
  width: 175px;
}
.RemoveStripeProvider .modal-content-footer {
  display: flex;
  flex-direction: row-reverse;
  grid-gap: 5px;
  gap: 5px;
}
.RemoveStripeProvider .modal-content-footer{
  padding: 11px 15px 15px;
  border-top: .5px solid #01364824
}

@media screen and (max-width: 600px) {
  .RemoveStripeProvider {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .RemoveStripeProvider .modal-content-footer{
    flex-direction: column-reverse;
  }
  .RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton,
  .RemoveStripeProvider .modal-content-footer .CircleButton.WhiteButton {
    width: 100%;
  }
 
}
.AddProviderToPractice {
  padding: 42px 26px 0px 37px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  max-width: 100%;
  width: 100%;
  margin-top: 11px;
}
.AddProviderToPractice .h3 {
  font-family: 'Calibre Regular';
  font-weight: normal;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 5px;
  display: block;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.AddProviderToPractice .email-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  width: 416px;
}
.AddProviderToPractice .email-row .Input-control.email.form-group {
  width: 100%;
}
.AddProviderToPractice .email-row .Input-control.form-group {
  width: 50%;
  padding: 0px 8px;
}
.AddProviderToPractice .add-provider-form {
  display: flex;
  margin-left: -8px;
}
.AddProviderToPractice .add-provider-form .Input-control .error-text {
  left: 8px;
}
.AddProviderToPractice .action {
  padding-top: 9px;
}
.AddProviderToPractice .action .CircleButton.WhiteButton {
  width: 144px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  margin-left: 15px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.23px;
  color: #ffffff;
  font-family: 'Calibre Regular';
  font-weight: normal;
  border: 0;
  padding: 2px 0;
}
.AddProviderToPractice .para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #b2bcc4;
  width: 398px;
}

@media screen and (max-width: 1366px) {
  .AddProviderToPractice {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .AddProviderToPractice .add-provider-form {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .AddProviderToPractice .email-row {
    width: 100%;
  }
  .AddProviderToPractice .h3 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .AddProviderToPractice .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .AddProviderToPractice {
    padding: 10px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AddProviderToPractice .action .CircleButton.WhiteButton {
    padding-top: 2px;
  }
}

.AddingProviderSeatModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 575px;
  margin: auto;
}
.AddingProviderSeatModal .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  /* border-bottom: 1px solid #f1f1f5; */
  background-color: #013648;
}
.AddingProviderSeatModal .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.AddingProviderSeatModal .modal-content-header .close IMG {
  width: 100%;
}
.AddingProviderSeatModal .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.AddingProviderSeatModal .modal-content-body {
  padding: 23px 41px 13px 47px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AddingProviderSeatModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.AddingProviderSeatModal .modal-content-footer {
  padding: 23px 47px 33px;
}
.AddingProviderSeatModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AddingProviderSeatModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}

.AddingProviderSeatModal .provider-description .description SPAN {
  color: #013648;
}
.AddingProviderSeatModal .provider-description .description {
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: normal;
  color: #72787d;
}
.AddingProviderSeatModal .provider-description .name {
  font-size: 24px;
  font-family: 'Calibre Medium';
  line-height: 0.96;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 16px;
}
.AddingProviderSeatModal .provider-description .email {
  height: 30px;
  background-color: rgba(1, 54, 72, 0.1);
  display: inline-flex;
  padding: 0 14px;
  margin-bottom: 28px;
}
.AddingProviderSeatModal .provider-description .email IMG {
  width: 13px;
  margin-left: 10px;
  cursor: pointer;
}
.AddingProviderSeatModal .provider-description .email SPAN {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  padding-top: 2px;
}
.AddingProviderSeatModal .modal-content-footer BUTTON {
  height: 32px;
  border-radius: 2px;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
  background-color: #f1f1f1;
  border: 0;
  margin-right: 10px;
  padding: 6px 15px 9px;
  cursor: pointer;
  outline: 0;
}
.AddingProviderSeatModal .modal-content-footer .save {
  background-color: #26acda;
  color: #ffffff;
}
.AddingProviderSeatModal .modal-content-footer {
  display: flex;
  justify-content: space-between;
}
.AddingProviderSeatModal .modal-content-footer IMG {
  object-fit: contain;
}
@media (max-width: 767px) {
  .AddingProviderSeatModal {
    width: 100%;
  }
  .AddingProviderSeatModal .modal-content-footer {
    flex-wrap: wrap;
  }
  .AddingProviderSeatModal .modal-content-footer IMG {
    margin-top: 15px;
    margin-left: auto;
  }
  .AddingProviderSeatModal .modal-content-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .AddingProviderSeatModal .modal-content-header,
  .AddingProviderSeatModal .modal-content-footer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.TagsInput {
  margin-bottom: 18px;
}
.TagsInput .form-label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: -0.2px;
  color: #013648;
}
.TagsInput .form-col {
  position: relative;
}
.TagsInput .multiselectsearch-block {
  position: relative;
  display: grid;
  flex-wrap: wrap;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 2px;
  padding: 25px 16px;
  margin-bottom: 21px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  align-content: flex-start;
}
.TagsInput .multiselectsearch-block .multiselect-result {
  display: flex;
  flex-wrap: wrap;
}
.TagsInput .multiselectsearch-block .form-input {
  width: auto;
  height: 32px;
  border: 0;
  background: transparent;
  border-radius: 0;
  padding: 5px;
  margin: 0;
}
.TagsInput .multiselectsearch-block .form-input:focus {
  border: 0;
}
.TagsInput .multiselectsearch-block .multiselect-result div {
  background-color: rgba(1, 54, 72, 0.1);
  padding: 1px 12px 1px 14px;
  margin-right: 17px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}
.TagsInput .multiselectsearch-block .multiselect-result div img {
  margin-left: 8px;
}
.TagsInput .instruction-block {
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  max-width: 330px;
  width: 100%;
  position: relative;
  padding-left: 35px;
}
.TagsInput .only-Toggle{
display: contents !important;
}
.TagsInput .only-Toggle .SwitchToggle {
  display: contents !important;
}
.TagsInput .only-Toggle .SwitchToggle .switchContainer{
margin: 0;
margin-top: 3px;
background: transparent;
}
.TagsInput .instruction-block img {
  position: absolute;
  left: 0;
  top: 5px;
}
.TagsInput .instruction-block a {
  color: #979797;
  text-decoration: underline;
}
.TagsInput .form-input.custom-input-box {
  width: 100%;
  border: 1px solid #99999938;
  border-radius: 2px
}
.TagsInput .outer-suggetion-box {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  
}
.TagsInput .autocomplete-box {
  background: #fff;
  padding: 7px 0;
  position: absolute;
  top: 35px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* max-width: 150px; */
  width: 100%;
}
.TagsInput .autocomplete-box div {
  padding: 7px 10px;
  cursor: pointer;
}
.TagsInput .multiselectsearch-block .multiselect-result div {
  display: inline-block;
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  padding-top: 3px;
}
.TagsInput .multiselectsearch-block .multiselect-result div img {
  cursor: pointer;
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
}
.TagsInput .multiselectsearch-disabled {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 4px 4px 0px 0px;
  border: 0;
  padding: 12px 13px;
  margin-bottom: 16px;
}
.TagsInput .multiselect search-disabled .multiselect-result {
  display: flex;
  flex-wrap: wrap;
}
.TagsInput .multiselectsearch-disabled .multiselect-result div {
  background: rgba(0, 0, 0, 0.1);
  color: #484848;
  padding: 1px 12px 1px 14px;
  margin-right: 17px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
}
.TagsInput .input-detail {
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  text-align: right;
  color: #484848;
  position: absolute;
  right: 0;
  bottom: -30px;
}
.TagsInput .error-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-size: 13px;
}
.TagsInput .multiselectsearch-block .multiselect-result .rejected-approval {
  background-color: rgba(232, 93, 114, 0.1);
  color: #e85d72;
}
.TagsInput .multiselectsearch-block .multiselect-result .pending-approval {
  background-color: rgba(242, 150, 45, 0.1);
  color: #f2962d;
}
.TagsInput .required-info {
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #f37184;
}
.TagsInput .SwitchToggle .switchContainer input:checked + .slider{
  background-color: #35b255;
}
@media (hover: hover) {
  .TagsInput .instruction-block a:hover {
    color: #174c99;
  }
  .TagsInput .autocomplete-box div:hover {
    background: #e8e8e8;
  }
}

@media (max-width: 767px) {
  .TagsInput .multiselectsearch-block {
    padding: 16px 17px;
    padding-right: 0;
  }
  .TagsInput .multiselectsearch-block .multiselect-result div {
    font-size: 16px;
  }
  .TagsInput .multiselectsearch-block .multiselect-result div img {
    width: 10px;
  }
}

.ProviderOverview {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 33px 95px 37px 90px;
  margin-bottom: 27px;
}
.ProviderOverview .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 30px;
  font-family: 'Calibre Medium';
}
.ProviderOverview .Input-control.link-container .lookup-link {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderOverview .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  border-radius: 2px;
  margin-top: 5px;
}
.ProviderOverview .Textarea.form-group TEXTAREA {
  height: 162px;
}
.ProviderOverview .Textarea.form-group .label {
  display: block;
  line-height: 1.25;
  margin-bottom: 5px;
}
.ProviderOverview .Textarea.form-group {
  margin-bottom: 23px;
}
.ProviderOverview .Select-control .label {
  line-height: 1.25;
  margin-bottom: 8px;
  display: block;
}
.ProviderOverview .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderOverview .Select-control .arrow {
  top: 50px;
}
.ProviderOverview .Input-control.link-container .lookup-link {
  top: 38px;
}
.ProviderOverview .Input-control.link-container {
  margin-bottom: 55px;
  margin-top: 5px;
}
.ProviderOverview .Input-control.form-group.link-container .label {
  margin-bottom: 2px;
}
.confirm-seolink p{
  display: grid;
  grid-template-columns: 160px 1fr;
  padding-left: 15px;
}
.confirm-seolink p span{
  word-break: break-all;
}
.ProviderOverview .TagsInput .form-label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderOverview .TagsInput.suffix-tag-input .outer-suggetion-box {
  flex: 1 1;
}
.ProviderOverview .TagsInput.suffix-tag-input .multiselectsearch-block {
  min-height: 52px;
  padding: 11px 15px;
}
.ProviderOverview .titleHeading {
  display: flex;
  justify-content: space-between;
}
.ProviderOverview .overview-bottom {
  display: flex;
}
.ProviderOverview .overview-bottom BUTTON {
  margin-left: auto;
}
.Provider-TabViewEach .Textarea,
.Provider-TabViewEach .TagsInput .form-col,
.Provider-TabViewEach .Input-control{
  scroll-margin-top: 80px;
}
.ProviderOverview .error-text input{
  color: #E85D72;
}
.custom-footer-warning{
  display: flex;
  flex-flow: column;
}
.ConfirmTemplate .custom-footer-warning .CircleButton{
  width: 100% !important;
  margin-bottom: 10px !important;
  margin-left: 0 !important;
}
@media screen and (max-width: 1366px) {
  .ProviderOverview {
    padding: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .ProviderOverview .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ProviderOverview .Textarea.form-group .form-control:focus,
  .ProviderOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px;
  }
}

@media screen and (max-width: 413px) {
  .ProviderOverview {
    padding: 20px;
  }
  .confirm-seolink p{
    display: inherit;
  }
}

.ProviderInformation {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 52px 92px 39px 92px;
  margin-bottom: 31px;
}
.ProviderInformation .h3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  margin-bottom: 31px;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
}
.ProviderInformation .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 6px;
  border-radius: 2px;
}
.ProviderInformation .Textarea.form-group TEXTAREA {
  height: 160px;
}
.ProviderInformation .Input-control.link-container.required-link .lookup-link {
  color: #f37184;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderInformation .TagsInput .multiselectsearch-block {
  min-height: 147px;
}
.ProviderInformation .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 8px;
}
.ProviderInformation .Input-control.link-container {
  margin-bottom: 38px;
}
.ProviderInformation .Input-control.link-container .lookup-link {
  top: 44px;
}
.ProviderInformation .InputList .input-list-label {
  margin-bottom: 8px;
}
.ProviderInformation .TagsInput .form-label {
  line-height: 1.25;
  margin-bottom: 11px;
}
.ProviderInformation .InputList {
  margin-bottom: 38px;
}
.ProviderInformation .InputList .add-input SPAN {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderInformation .InputList .input-list-item .Input-control.form-group {
  margin-bottom: 28px;
}
.ProviderInformation .InputList .add-input {
  margin: 0;
}
.ProviderInformation.InputList .input-list-label {
  margin-bottom: 12px;
}
.ProviderInformation .required-link {
  margin-bottom: 39px;
}
.ProviderInformation .titleHeading {
  display: flex;
  justify-content: space-between;
}
.ProviderInformation .info-bottom {
  display: flex;
}
.ProviderInformation .info-bottom BUTTON {
  margin-left: auto;
}

@media screen and (max-width: 1366px) {
  .ProviderInformation {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .ProviderInformation .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .ProviderInformation {
    padding: 10px;
  }
}

.ProviderPhotos .provider-logo {
  display: flex;
  margin-bottom: 42px;
}
.ProviderPhotos {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 29px 40px 38px;
  margin-bottom: 27px;
}
.ProviderPhotos .h3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
}
.ProviderPhotos .sub-txt {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  margin-bottom: 23px;
}
.ProviderPhotos .name {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.4px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 7px;
  margin-top: 25px;
}
.ProviderPhotos .logo-img {
  min-width: 120px;
  width: 120px;
  height: 169px;
}
.ProviderPhotos .logo-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6.4px;
  border: solid 0.8px rgba(1, 54, 72, 0.2);
}
.ProviderPhotos .logo-inner-content .label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-bottom: 3px;
}
.ProviderPhotos .logo-inner-content {
  padding-top: 0px;
  padding-left: 20px;
  width: calc(100% - 120px);
}
.ProviderPhotos .logo-inner-content .image-size {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: -0.18px;
  color: #b2bcc4;
  display: inline-block;
  width: 100%;
}
.ProviderPhotos .circle-btn {
  height: 38px;
  border: 0;
  padding: 11px 5px 8px;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  font-family: 'Calibre Regular';
  width: 117px;
  border-radius: 2px;
  background-color: rgba(143, 146, 161, 0.2);
  letter-spacing: normal;
  color: #72787d;
}
.ProviderPhotos .circle-btn:focus {
  outline: 0;
}
.ProviderPhotos .circle-btn.blue-btn {
  background-color: #26acda;
  color: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
}
.ProviderPhotos .fileupload {
  position: relative;
  margin-right: 10px;
  margin-bottom: 5px;
}
.ProviderPhotos .fileupload .fileupload-btn button {
  height: 38px;
  border: 0;
  padding: 11px 5px 8px;
  width: 161px;
  font-size: 18px;
  line-height: 1.22;
  text-align: center;
  background-color: #26acda;
  color: #fff;
  font-family: 'Calibre Regular';
  border-radius: 2px;
}
.ProviderPhotos .fileupload .fileupload-btn {
  display: block;
}
.ProviderPhotos .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.ProviderPhotos .logo-content-action {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.ProviderPhotos .photo-upload-row .filephoto-upload {
  padding: 0 8px;
}
.ProviderPhotos .photo-upload-row .filephoto-upload .fileupload-input {
  width: 93px;
  height: 93px;
  border-radius: 4px;
  opacity: 0.51;
  border: dashed 2px rgba(1, 54, 72, 0.5);
  background-color: #f7f9f9;
  position: relative;
  display: flex;
}
.ProviderPhotos .photo-upload-row .filephoto-upload .fileupload-input input {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.ProviderPhotos .photo-upload-row .filephoto-upload .fileupload-input img {
  width: 35px;
  height: 23px;
  margin: auto;
}
.ProviderPhotos .photo-upload-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.ProviderPhotos .photo-col {
  padding: 0 8px;
}
.ProviderPhotos .photo-col .photo-img {
  width: 93px;
  height: 93px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: solid 1px #ccd7da;
  background-color: #fafafb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProviderPhotos .photo-col .photo-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProviderPhotos .photo-col .photo-img video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ProviderPhotos .additional-video {
  margin-top: 40px;
}
.ProviderPhotos .photo-col .remove-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #b2bcc4;
  display: block;
  cursor: pointer;
}
@media (hover: hover) {
  .ProviderPhotos .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .ProviderPhotos {
    padding: 20px;
  }
  .ProviderPhotos .logo-inner-content {
    padding-left: 15px;
    width: calc(100% - 120px);
  }
  .ProviderPhotos .fileupload .fileupload-btn button {
    width: 157px;
  }
}
@media screen and (max-width: 1200px) {
  .ProviderPhotos .fileupload .fileupload-btn button {
    width: 140px;
  }
  .ProviderPhotos .circle-btn {
    width: 100px;
  }
  .ProviderPhotos .name,
  .ProviderPhotos .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .ProviderPhotos .logo-img {
    width: 100px;
    min-width: 100px;
    height: 149px;
  }
  .ProviderPhotos .logo-inner-content {
    width: calc(100% - 100px);
  }
  .ProviderPhotos .name {
    margin-top: 0;
  }
}

@media screen and (max-width: 413px) {
  .ProviderPhotos {
    padding: 10px;
  }
}

.EditVisitReasonModal {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 38px 0px 0px 75px;
  max-height: 723px;
  height: calc(100vh - 100px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 8px;
}
.EditVisitReasonModal .head-model {
  display: flex;
  justify-content: space-between;
  padding-right: 50px;
}
.EditVisitReasonModal .head-model .icon {
  width: 18px;
  display: inline-block;
  margin-right: 10px;
  line-height: 2;
  position: relative;
  top: 4px;
}
.EditVisitReasonModal .head-model .icon IMG {
  width: 100%;
}
.EditVisitReasonModal .head-model SPAN {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  cursor: pointer;
}
.EditVisitReasonModal .head-model{
  background: white;
  position: fixed;
  /* width: 90%; */
}
.EditVisitReasonModal .pms-body{
  margin-top: 50px;
}
.EditVisitReasonModal .head-model .CircleButton {
  font-size: 16px;
  width: 111px;
  height: 32px;
  border-radius: 3px;
  line-height: 32px;
}
.EditVisitReasonModal .head-model .CircleButton.visitreasonSaveBtn {
  position: fixed;
  right: 50px;
}
.EditVisitReasonModal .pms-body {
  padding-top: 12px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 220px);
  padding-right: 42px;
}
.EditVisitReasonModal .pms-body .heading1 {
  font-family: 'Heldane Display';
  font-size: 36px;
  line-height: 0.94;
  color: #013648;
  margin-bottom: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 19px;
}
.EditVisitReasonModal .pms-body .pms-first-question {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.2px;
  color: #013648;
}
.EditVisitReasonModal .pms-body .pms-second-question {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 25px;
  border-top: solid 1px #b2bcc4;
  padding-top: 22px;
}
.EditVisitReasonModal .visit-reason-tab .tab-list {
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
  border-bottom: 1px solid rgba(156, 144, 144, 0.34);
  margin-top: 7px;
  display: flex;
  white-space: pre;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
}
.EditVisitReasonModal .visit-reason-tab .tab-list-item {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  margin-right: 30px;
  padding-bottom: 6px;
  display: inline-block;
  cursor: pointer;
}
.EditVisitReasonModal .visit-reason-tab .tab-list-active {
  background-color: #ffffff;
  opacity: 1;
  color: #013648;
  font-family: 'Calibre Medium';
}
.EditVisitReasonModal .visit-reason-tab .tab-list-item:last-child {
  padding-right: 14px;
}
.EditVisitReasonModal .add-time {
  margin-top: 22px;
}
.EditVisitReasonModal .add-time .Select-control .label {
  font-family: 'Calibre Regular';
}
.EditVisitReasonModal .add-time .Select-control.form-group SELECT {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  padding-left: 11px;
  padding-top: 8px;
  border: 1px solid #f8f8f8;
}
.EditVisitReasonModal .add-time .Select-control .arrow {
  right: 15px;
}
.EditVisitReasonModal .add-time .Select-control.form-group {
  width: 200px;
  margin-right: 30px;
  display: inline-block;
  position: relative;
  margin-bottom: 30px;
}
.EditVisitReasonModal .add-time .Select-control.form-group:after {
  content: 'Minutes';
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.22;
  color: #b2bcc4;
  position: absolute;
  left: 40px;
  top: 43px;
  letter-spacing: -0.2px;
  background-color: transparent;
}
.EditVisitReasonModal .add-time .Select-control.form-group:before {
  content: '';
  background-color: #f8f8f8;
  position: absolute;
  left: 0;
  right: 0;
  top: 25px;
  bottom: 0rem;
}
.EditVisitReasonModal .tab-content {
  margin-top: 16px;
  margin-bottom: 18px;
}
.EditVisitReasonModal .PainManagement-content .tab-list-item {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: normal;
  color: #b2bcc4;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-right: 28px;
  padding-bottom: 3px;
}
.EditVisitReasonModal .tab-content .visit-list {
  max-height: auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-left: 5px;
}
.EditVisitReasonModal .tab-content .visit-item .Checkbox-control .checkmark {
  border-radius: 1px;
  border: solid 1px #26acda;
}
.EditVisitReasonModal .tab-content .visit-item .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #26acda;
}
.EditVisitReasonModal .tab-content .visit-item > SPAN {
  margin-left: 30px;
  display: inline-block;
}
.EditVisitReasonModal .tab-content .visit-item {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
  margin-bottom: 9px;
}
.EditVisitReasonModal .tab-content .visit-item:last-child {
  margin-bottom: 0;
}
.EditVisitReasonModal .tab-content .visit-item:nth-child(1) .Checkbox-control {
  margin-top: 0px;
}
.EditVisitReasonModal .tab-content .visit-item .Checkbox-control {
  margin-top: 10px;
  color: #72787d;
}
.EditVisitReasonModal
  .tab-content
  .visit-item
  .Checkbox-control
  INPUT:checked
  ~ SPAN:not(.checkmark) {
  color: #013648;
}

@media screen and (max-width: 767px) {
  .EditVisitReasonModal {
    padding-left: 20px;
    padding-top: 25px;
  }
  .EditVisitReasonModal .head-model {
    padding-right: 20px;
    flex-wrap: wrap;
  }
  .EditVisitReasonModal .pms-body {
    padding-right: 20px;
  }
  .EditVisitReasonModal .pms-body .heading1 {
    font-size: 28px;
  }
  .EditVisitReasonModal .add-time .Select-control.form-group {
    width: 100%;
    margin-right: 0;
  }
}
@media screen and (max-width: 550px) {
  .EditVisitReasonModal .head-model .CircleButton.visitreasonSaveBtn {
    right: 25px;
  }
}
@media screen and (max-width: 400px) {
  .EditVisitReasonModal .head-model .CircleButton.visitreasonSaveBtn {
    right: 9px;
  }
}

.VisitReasons {
  margin-bottom: 28px;
  border-bottom: 1px solid #f1f1f5;
  position: relative;
}
.blockEdit{
  position: absolute;
  width: 100%;
  height: 101%;
  top: -5%;
  background-color:#ffffff66;
  cursor: not-allowed;
}
.VisitReasons .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
}
.VisitReasons .heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.VisitReasons .heading .required-info {
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #f37184;
  margin-right: auto;
  margin-left: 20px;
  margin-top: 4px;
}
.VisitReasons .heading .CircleButton.WhiteButton {
  width: 173px;
  height: 32px;
  background-color: #26acda;
  border: 1px solid #26acda;
  font-size: 16px;
  line-height: 34px;
  color: #ffffff;
  font-family: 'Calibre Regular';
  margin-top: -5px;
  border-radius: 2px;
}
.VisitReasons .visit-tab-content {
  margin-top: 30px;
  margin-bottom: 57px;
  border-radius: 8px;
  max-height: 245px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.VisitReasons .tab-list {
  display: flex;
  white-space: pre;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding-left: 0px;
}
.VisitReasons .visit-tab-list-item {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: normal;
  color: #b2bcc4;
  font-family: 'Calibre Regular';
  margin-right: 28px;
  padding-bottom: 3px;
  display: inline-block;
  cursor: pointer;
}
.VisitReasons .visit-tab-list-active {
  background-color: #ffffff;
  border: solid #013648;
  border-width: 0px 0px 1px 0px;
  opacity: 1;
  color: #013648;
}
.VisitReasons .visit-reason-tab .visit-tab-content .visit-list {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #72787d;
  margin-bottom: 3px;
}
.VisitReasons .visit-reason-tab .visit-tab-content .visit-list:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .VisitReasons .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .VisitReasons .heading .CircleButton.WhiteButton {
    margin-top: 0;
  }
}

.ProviderPercentage {
  padding-bottom: 34px;
  margin-bottom: 17px;
  border-bottom: 1px solid #f1f1f5;
}
.ProviderPercentage:last-child {
  border-bottom: 0;
}
.ProviderPercentage .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 2px;
  font-family: 'Calibre Medium';
}
.ProviderPercentage .h3 .per-txt {
  color: #b2bcc4;
}
.ProviderPercentage .sub-para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  margin-bottom: 28px;
}
.ProviderPercentage .add-input {
  margin-top: 16px;
  margin-bottom: 20px;
}
.ProviderPercentage .add-input SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderPercentage .add-input SPAN IMG {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.ProviderPercentage .action-block {
  display: flex;
  align-items: center;
}
.ProviderPercentage .action-block .error-txt {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #f37184;
  margin-left: 21px;
  margin-top: 5px;
}
.ProviderPercentage .pt-category-box {
  width: 250px;
  margin: 0 -3px 30px;
  display: flex;
}
.ProviderPercentage .pt-category-box .box-wrapper {
  padding: 0 2px;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(1) .box {
  background-color: #3d93a6;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(2) .box {
  background-color: #e85d72;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(3) .box {
  background-color: #8d77ba;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(4) .box {
  background-color: #f2962d;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(5) .box {
  background-color: #ec95a2;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(6) .box {
  background-color: #f0e63a;
}
.ProviderPercentage .pt-category-box .box {
  width: 100%;
  height: 19px;
  border-radius: 2px;
  background-color: #3d93a6;
  display: inline-block;
}
.ProviderPercentage .pt-category-box .sciatica-box {
  width: 250px;
  background-color: #3d93a6;
}
.ProviderPercentage .remove-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
}
.ProviderPercentage .remove-link .close-icon IMG {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner {
  display: flex;
  align-items: center;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .column9 {
  width: calc(100% - 190px);
  position: relative;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .column3 {
  width: 100px;
}
.ProviderPercentage .percentage-wrapper .percentage-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 14px;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderPercentage .percentage-wrapper .percentage-label .column9 {
  width: calc(100% - 190px);
  position: relative;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .AutoSuggestionSelect {
  margin-right: 10px;
}
.ProviderPercentage .AutoSuggestionSelect .Input-control.form-group input.form-control {
  padding-left: 25px;
}
.ProviderPercentage .percentage-wrapper .percentage-label .column3 {
  width: 100px;
  margin-left: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .Input-control.form-group {
  margin-bottom: 10px;
  margin-right: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .Input-control.form-group INPUT {
  padding-left: 25px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .column9:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  width: 6px;
  background-color: #3d93a6;
  z-index: 1;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(1) .column9::before {
  background-color: #3d93a6;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(2) .column9::before {
  background-color: #e85d72;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(3) .column9::before {
  background-color: #8d77ba;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(4) .column9::before {
  background-color: #f2962d;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(5) .column9::before {
  background-color: #ec95a2;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(6) .column9::before {
  background-color: #f0e63a;
}
.ProviderPercentage .other-input {
  padding: 3px 15px 0px 25px;
  height: 52px;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  display: flex;
  align-items: center;
  border: solid 1px #f1f1f1;
  background-color: #fbfcfc;
}
.ProviderPercentage .other-control SPAN:first-child {
  color: #013648;
}
.ProviderPercentage .other-control SPAN {
  font-size: 18px;
  line-height: 1.22;
  color: #72787d;
  white-space: nowrap;
}
.ProviderPercentage .percentage-inner.other .Input-control .form-control[disabled] {
  height: 52px;
  border-radius: 2px;
  border: solid 1px #f1f1f1;
  background-color: #fbfcfc;
}
.ProviderPercentage .other-info-icon {
  position: absolute;
  right: 53px;
  top: 17px;
}
.ProviderPercentage .percentage-inner.other .other-control.column9:before {
  bottom: 0;
}
.ProviderPercentage .other-control {
  margin-right: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner.other {
  position: relative;
  align-items: flex-start;
}
.ProviderPercentage .Input-control.error INPUT.form-control {
  border-color: #f37184;
}
.ProviderPercentage .titleheading {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .ProviderPercentage .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ProviderPercentage .percentage-wrapper .percentage-inner .column9 {
    width: calc(100% - 140px);
  }
  .ProviderPercentage .percentage-wrapper .percentage-label .column9 {
    width: calc(100% - 140px);
  }
  .ProviderPercentage .percentage-wrapper .percentage-label .column3 {
    width: 80px;
    min-width: 80px;
  }
  .ProviderPercentage .percentage-wrapper .percentage-inner .column3 {
    width: 80px;
    min-width: 80px;
  }
  .ProviderPercentage .AutoSuggestionSelect .Input-control.form-group input.form-control {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }
  .ProviderPercentage .remove-link .remove-text {
    display: none;
  }
  .ProviderPercentage .action-block .error-txt {
    margin-left: 12px;
  }
  .ProviderPercentage .other-input{
    flex-wrap: wrap;
    overflow: hidden;
  }
  .ProviderPercentage .other-input SPAN{
    font-size: 16px;
  }
  .ProviderPercentage .other-info-icon{
    right:15px;
  }
}
@media screen and (max-width: 375px) {
  .ProviderPercentage .action-block {
    flex-wrap: wrap;
  }
  .ProviderPercentage .action-block .error-txt {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
}

.ProviderCare {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 63px 44px 34px 47px;
  margin-bottom: 30px;
}
.ProviderCare .ProvidercareFocus .heading3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: #013648;
  margin-bottom: 5px;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
  padding-right: 40px;
  position: relative;
}
.ProviderCare .ProvidercareFocus .sub-para {
  font-size: 16px;
    line-height: 1.25;
    letter-spacing: -0.2px;
    color: #b2bcc4;
    margin-bottom: 28px;
}
.ProviderCare .ProvidercareFocus .TagsInput .multiselectsearch-block {
  min-height: 147px;
  margin-bottom: 20px;
}
.ProviderCare .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  border-radius: 2px;
}
.ProviderCare .ProvidercareFocus {
  padding-bottom: 13px;
  margin-bottom: 41px;
  border-bottom: 1px solid #f1f1f5;
}
.ProviderCare .titleHeading {
  display: flex;
  justify-content: space-between;
}
.ProviderCare .commonSave {
  display: flex;
}
.ProviderCare .commonSave BUTTON {
  margin-left: auto;
}
.ProviderCare .SpecialitiesClose {
  position: absolute;
  right: 10px;
  top: 3px;
}
.ProviderCare .SpecialitiesClose img {
  width: 20px;
}
.ProviderCare .ProvidercareFocus .required-info {
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #f37184;
}
.signup-form .TagsInput .multiselect-result div.each-tag,
.provider-Specialities .TagsInput .multiselect-result div.each-tag{
   display: flex !important;
   grid-gap: 20px;
   gap: 20px;
}

@media screen and (max-width: 1366px) {
  .ProviderCare {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .ProviderCare .ProvidercareFocus .heading3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .ProviderCare {
    padding: 10px;
  }
}

.ProviderProfile .profile-section {
  display: flex;
  margin: 0;
}
.ProviderProfile .profile-section .middlepanel {
  max-width: 100%;
  width: 607px;
  padding: 0;
  margin: 0 auto;
}
.ProviderProfile .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  margin-bottom: 36px;
  cursor: pointer;
}
.ProviderProfile .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.ProviderProfile .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}
.ProviderProfile .disabled-section {
  pointer-events: none;
  opacity: 0.5;
}

@media screen and (max-width: 1200px) {
  .ProviderProfile .profile-section {
    flex-wrap: wrap;
    margin: 0;
  }
}

@media screen and (max-width: 1024px) {
  .ProviderProfile .profile-section {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .ProviderProfile .profile-section .middlepanel {
    width: 100%;
    padding: 0;
  }
}

.ProviderAccount {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 29px 40px 48px 37px;
  margin-bottom: 27px;
}
.ProviderAccount .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 30px;
  font-family: 'Calibre Medium';
}
.ProviderAccount .Input-control.link-container .lookup-link {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderAccount .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  border-radius: 2px;
  margin-top: 5px;
}
.ProviderAccount .Textarea.form-group TEXTAREA {
  height: 162px;
}
.ProviderAccount .Textarea.form-group .label {
  display: block;
  line-height: 1.25;
  margin-bottom: 5px;
}
.ProviderAccount .Textarea.form-group {
  margin-bottom: 23px;
}
.ProviderAccount .Select-control .label {
  line-height: 1.25;
  margin-bottom: 8px;
  display: block;
}
.ProviderAccount .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderAccount .Select-control .arrow {
  top: 50px;
}
.ProviderAccount .Input-control.link-container .lookup-link {
  top: 38px;
}
.ProviderAccount .Input-control.link-container {
  margin-bottom: 55px;
  margin-top: 5px;
}
.ProviderAccount .Input-control.form-group.link-container .label {
  margin-bottom: 2px;
}
.ProviderAccount .TagsInput .form-label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderAccount .TagsInput.suffix-tag-input .outer-suggetion-box {
  flex: 1 1;
}
.ProviderAccount .TagsInput.suffix-tag-input .multiselectsearch-block {
  min-height: 52px;
  padding: 11px 15px;
}
.ProviderAccount .titleHeading {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1366px) {
  .ProviderAccount {
    padding: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .ProviderAccount .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ProviderAccount .Textarea.form-group .form-control:focus,
  .ProviderAccount .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px;
  }
}

@media screen and (max-width: 413px) {
  .ProviderAccount {
    padding: 20px;
  }
}

.EditPracticeSeoLink {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 40px 40px;
  margin-bottom: 40px;
}
.EditPracticeSeoLink .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 40px;
}
.EditPracticeSeoLink .Select-control .arrow {
  top: 55px;
}
.EditPracticeSeoLink .Textarea,
.EditPracticeSeoLink .TagsInput .form-col,
.EditPracticeSeoLink .Input-control{
  scroll-margin-top: 80px;
}
.EditPracticeSeoLink .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  margin-top: 17px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 1px;
  border: 1px solid #013648;
}
.EditPracticeSeoLink .Textarea.form-group TEXTAREA {
  height: 160px;
}
.EditPracticeSeoLink .Textarea.form-group .form-control:not(:placeholder-shown) {
  padding-left: 25px;
}
.EditPracticeSeoLink .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeSeoLink .Input-control.form-group {
  margin-bottom: 36px;
}
.EditPracticeSeoLink .Textarea.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeSeoLink .Textarea.form-group {
  margin-bottom: 34px;
}
.EditPracticeSeoLink .Select-control .label {
  line-height: 1.25;
  margin-bottom: 14px;
  display: block;
}
.confirm-seolink p{
  display: grid;
  grid-template-columns: 160px 1fr;
  padding-left: 15px;
}
@media screen and (max-width: 1366px) {
  .EditPracticeSeoLink {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeSeoLink .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .EditPracticeSeoLink .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 413px) {
  .confirm-seolink p{
    display: inherit;
  }
}
.ProvidersTab {
  padding: 44px 40px 56px 37px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  max-width: 100%;
  width: 100%;
}
.ProvidersTab .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 30px;
  display: inline-block;
}
.ProvidersTab .provider-list {
  display: flex;
  flex-wrap: wrap;
}
.ProvidersTab .active-providers .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Regular';
  font-weight: normal;
  margin-bottom: 25px;
  border-bottom: 1px solid #f1f1f5;
  display: block;
  padding-bottom: 7px;
}
.ProvidersTab .active-providers .h3 .sub-heading {
  position: relative;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  padding-left: 29px;
  padding-right: 24px;
}
.ProvidersTab .active-providers .h3 .sub-heading:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 7px;
  width: 5px;
  height: 5px;
  background-color: rgba(114, 120, 125, 0.3);
  border-radius: 100%;
}
.ProvidersTab .active-providers .h3 .update-txt {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  color: #26acda;
  cursor: pointer;
}
.ProvidersTab .add-provider-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.ProvidersTab .add-provider-content .add-provider {
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
}
.ProvidersTab .add-provider-content .add-provider:last-child {
  margin-bottom: 0;
}
.ProvidersTab .add-provider-content .add-provider .add-provider-inner {
  height: 76px;
  border-radius: 8px;
  border: dashed 1px rgba(1, 54, 72, 0.5);
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #b2bcc4;
  padding: 5px 23px;
}
.ProvidersTab .add-provider-content .add-provider .plus-icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba(1, 54, 72, 0.2);
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProvidersTab .add-provider-content .add-provider .plus-icon img {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.ProvidersTab .providers-content {
  max-height: 626px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1366px) {
  .ProvidersTab {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ProvidersTab .active-providers .h3,
  .ProvidersTab .active-providers .h3 .sub-heading {
    font-size: 18px;
  }
  .ProvidersTab .providers-content {
    max-height: none;
  }
}

@media screen and (max-width: 413px) {
  .ProvidersTab {
    padding: 10px;
  }
}

.AddInsurancePlansModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
}
.AddInsurancePlansModal .box-title{
  padding: 22px 31px 5px;
  background-color: #013648;
  display: flex;
    justify-content: space-between;
}
.AddInsurancePlansModal .box-title .cross-icon{
  position: absolute;
    width: 19px;
    top: 27px;
    cursor: pointer;
    right: 38px;
}
.AddInsurancePlansModal .box-title .cross-icon img{
  width: 100%;
}
.AddInsurancePlansModal .box-title span.heading{
  font-size: 20px;
    line-height: 1.98;
    color: #fff;
}
.AddInsurancePlansModal .box {
  background-color: #ffffff;
  padding: 30px 60px 40px 77px;
  padding-bottom: 0;
  /* background-color: #013648; */
}
.AddInsurancePlansModal .box .title {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
}
.AddInsurancePlansModal .box .title .cross-icon {
  width: 19px;
  cursor: pointer;
}
.AddInsurancePlansModal .box .title .cross-icon IMG {
  width: 100%;
}
.AddInsurancePlansModal .search-input {
  margin-bottom: 10px;
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT {
  font-size: 16px;
  height: 38px;
  border-radius: 4px;
  background-color: #fafafb;
  border-color: transparent;
  padding-left: 40px;
  padding-top: 7px;
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT::placeholder {
  font-size: 16px;
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.5);
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT:-ms-input-placeholder {
  font-size: 16px;
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.5);
}
.AddInsurancePlansModal .search-input.Input-control.form-group INPUT::-webkit-input-placeholder {
  font-size: 16px;
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.5);
}
.AddInsurancePlansModal .search-input.Input-control.form-group .view-img {
  left: 11px;
  top: 19px;
  width: 16px;
}
.AddInsurancePlansModal .Input-control.form-group .form-control:focus,
.AddInsurancePlansModal .Input-control.form-group .form-control:not(:placeholder-shown) {
  padding: 7px 15px 0 40px;
  border: solid 1.5px rgba(70, 104, 116, 0.2);
}
.AddInsurancePlansModal .insuranceplans-item:not(:last-child) {
  margin-bottom: 32px;
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Medium';
  border-bottom: solid 1px #e2e2ea;
  padding-bottom: 6px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.AddInsurancePlansModal .insuranceplans-item:last-child .insuranceplans-title {
  border-bottom: 0;
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-title IMG {
  margin-right: 30px;
  cursor: pointer;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #72787d;
  padding-left: 30px;
  margin-bottom: 3px;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control .checkmark {
  border-radius: 1px;
  border: solid 1px #26acda;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #26acda;
}
.AddInsurancePlansModal .insuranceplans-content .Checkbox-control INPUT:checked ~SPAN:not(.checkmark) {
  color: #013648;
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-content {
  display: none;
}
.AddInsurancePlansModal .insuranceplans-item.active-item .insuranceplans-content {
  display: block;
  padding-left: 5px;
}
.AddInsurancePlansModal .insuranceplans-item.active-item .insuranceplans-title IMG{
  transform: rotate(180deg);
}
.AddInsurancePlansModal .insuranceplans-item .insuranceplans-title IMG {
  transform: rotate(0deg);
  transition: 0.3s all linear;
}
.AddInsurancePlansModal .insuranceplans-item .showmore {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #26acda;
  margin-top: 6px;
  display: inline-block;
  cursor: pointer;
}
.AddInsurancePlansModal .action-button-list {
  margin: 20px 0px;
  text-align: right;
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AddInsurancePlansModal .action-button-list .error-text {
  color: red;
  font-size: 16px;
}
.AddInsurancePlansModal .action-button-list .CircleButton.clear {
  background-color: #999;
  border-color: #999;
  width: 111px;
  height: 32px;
  line-height: 32px;
}
.AddInsurancePlansModal .action-button-list .CircleButton.save {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  line-height: 32px;
  border: 1px solid #26acda;
  color: #fff;
}
.selectall-btn-div{
  position: relative;
  height: 25px;
}
.insurance-selectall{
  background-color: #26acda;
  border: 1px solid #26acda;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  line-height: 20px;
  position: absolute;
  right: 0;
  
}
.AddInsurancePlansModal .insuranceplans-list {
  max-height: calc(100vh - 320px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  margin-top: 10px;
}
.AddInsurancePlansModal .no-records {
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
  height: 100px;
}

@media (hover: hover) {
  .AddInsurancePlansModal .action-button-list .CircleButton.clear:hover {
    color: #ffffff;
    background-color: #999;
  }
  .AddInsurancePlansModal .action-button-list .CircleButton.save:hover {
    background-color: #ffffff;
    color: #26acda;
  }
}

@media (max-width: 1200px) {
  .AddInsurancePlansModal .box {
    padding: 20px;
  }
}
@media (max-width: 767px) {
  .AddInsurancePlansModal .action-button-list {
    margin-bottom: 0;
  }
}

.AcceptedInsurancePlans {
  height: 100%;
}
.AcceptedInsurancePlans .box {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px;
  padding-bottom: 0;
  height: 100%;
}
.AcceptedInsurancePlans .box .title {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.AcceptedInsurancePlans .box .title .CircleButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  line-height: 32px;
  border: 1px solid #26acda;
  color:#fff;
}
.AcceptedInsurancePlans .insuranceplans-item {
  margin-bottom: 30px;
}
.AcceptedInsurancePlans .insuranceplans-item .insuranceplans-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Medium';
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 8px;
  margin-bottom: 13px;
}
.AcceptedInsurancePlans .insuranceplans-content {
  font-size: 16px;
  letter-spacing: -0.21px;
  color: #013648;
}
.AcceptedInsurancePlans .insuranceplans-content > div {
  line-height: 1.58;
}
.AcceptedInsurancePlans .insuranceplans-list {
  max-height: 730px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (hover: hover) {
  .AcceptedInsurancePlans .box .title .CircleButton:hover {
    border: 1px solid #26acda;
    background-color: #26acda;
    background-color: #ffffff;
    color: #26acda;
  }
}

@media screen and (max-width: 1200px) {
  .AcceptedInsurancePlans .box {
    padding: 20px;
  }
  .AcceptedInsurancePlans .box .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 414px) {
  .AcceptedInsurancePlans .box .title {
    flex-wrap: wrap;
  }
  .AcceptedInsurancePlans .box .title .CircleButton {
    margin-left: auto;
    margin-top: 10px;
  }
}
@supports (-webkit-touch-callout: none) {
  .AcceptedInsurancePlans .box .title .CircleButton {
    padding-top: 2px;
  }
}

.InsuranceTab {
  display: flex;
  margin: 0 -15px;
}
.InsuranceTab .rightpanle {
  padding: 0 15px;
  width: 53%;
}
@media screen and (max-width: 1200px) {
  .InsuranceTab .rightpanle {
    width: 100%;
  }
}

.StaffItem {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 8px;
  background-color: #f7f9f9;
}
.StaffItem .staff-inner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 76.4px;
  padding: 15px 88px 15px 83px;
  cursor: pointer;
}
.StaffItem .staff-image {
  min-width: 35px;
  width: 35px;
  display: flex;
  height: 54px;
  position: absolute;
  left: 20px;
  align-items: center;
  background: white;
  padding: 14px 4px;
  border-radius: 3px;
  border: solid 1px rgba(1, 54, 72, 0.2);
}
.StaffItem .staff-image img {
  width: 27px;
  height: 27px;
}
.StaffItem .name {
  position: relative;
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
}
.StaffItem .dot-icon {
  width: 20px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  right: 28px;
  top: 40%;
  background-color: #f7f9f9;
}
.StaffItem .dot-icon img {
  width: 100%;
}
.StaffItem .dot-icon .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
}
.StaffItem .dot-icon.show-menu .dropdown-menu {
  display: block;
}
.StaffItem .dot-icon .dropdown-menu span {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.StaffItem .dot-icon .dropdown-menu span.remove-user {
  color: #e85d72;
}
.StaffItem .status {
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #013648;
}
.StaffItem .status .status-text {
  display: inline-block;
  color: #3d93a6;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.StaffItem .status .active {
  color: #3d93a6;
}
.StaffItem .status .pending {
  color: #f2962d;
}
.StaffItem .status .inactive {
  color: #e85d72;
}
.StaffItem .email {
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #72787d;
}
.StaffItem .profile-progress {
  display: flex;
  align-items: center;
}
.StaffItem .profile-progress .name {
  font-size: 16px;
  line-height: 2.13;
  letter-spacing: -0.2px;
  color: #013648;
  width: 115px;
  margin-right: 12px;
  text-align: right;
}
.StaffItem .profile-progress .number {
  font-size: 19px;
  line-height: 0.71;
  letter-spacing: normal;
  color: #013648;
  margin-left: 22px;
  display: inline-block;
  position: relative;
  top: 2px;
}
.StaffItem .profile-progress .progressBar .progress-bar {
  background-color: #3d93a6;
  border-radius: 7px;
}
.StaffItem .profile-progress .progressbar {
  width: 105px;
  border-radius: 7px;
}
.StaffItem .profile-progress.active .progressbar .ProgressBar .progress-bar {
  background-color: #3d93a6;
}
.StaffItem .profile-progress.active .name {
  color: #013648;
}
.StaffItem .profile-progress.active .number {
  color: #013648;
}
.StaffItem .profile-progress.inactive .progressbar .ProgressBar .progress-bar {
  background-color: #e85d72;
}
.StaffItem .profile-progress.inactive .name {
  color: #e85d72;
}
.StaffItem .profile-progress.inactive .number {
  color: #e85d72;
}
.StaffItem .profile-progress.pending .progressbar .ProgressBar .progress-bar {
  background-color: #f2962d;
}
.StaffItem .profile-progress.pending .name {
  color: #f2962d;
}
.StaffItem .profile-progress.pending .number {
  color: #f2962d;
}
.StaffItem .member-detail {
  cursor: pointer;
  width: 55%;
}
.StaffItem .member-rating {
  display: flex;
  align-items: center;
  position: relative;
}
.StaffItem .overlay {
  z-index: 8;
}

@media (hover: hover) {
  .StaffItem .dot-icon .dropdown-menu span:hover {
    background-color: #f2f2f2;
  }
}

@media screen and (max-width: 1199px) {
  .StaffItem .member-detail {
    width: 100%;
  }
  .StaffItem .profile-progress .name {
    text-align: left;
  }
}

@media screen and (max-width: 767px) {
  .StaffItem .name {
    font-size: 16px;
  }
  .StaffItem .dot-icon {
    top: 14%;
  }
  .StaffItem .profile-progress .progressbar {
    width: 82px;
  }
  .StaffItem .status .pending {
    display: inline-block;
    width: 100%;
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 414px) {
  .StaffItem .profile-progress {
    flex-wrap: wrap;
  }
  .StaffItem .staff-inner-content {
    padding: 15px 32px 15px 50px;
  }
  .StaffItem .staff-image {
    left: 7px;
  }
  .StaffItem .dot-icon {
    right: 8px;
  }
  .StaffItem .profile-progress .name {
    display: inline-block;
    width: 100%;
  }
}

.AddStaffToPractice {
  padding: 42px 26px 0px 37px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  max-width: 100%;
  width: 100%;
  margin-top: 11px;
}
.AddStaffToPractice .h3 {
  font-family: 'Calibre Regular';
  font-weight: normal;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 5px;
  display: block;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.AddStaffToPractice .email-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  width: 416px;
}
.AddStaffToPractice .email-row .Input-control.email.form-group {
  width: 100%;
}
.AddStaffToPractice .email-row .Input-control.form-group {
  width: 50%;
  padding: 0px 8px;
}
.AddStaffToPractice .add-staff-form {
  display: flex;
  margin-left: -8px;
}
.AddStaffToPractice .add-staff-form .Input-control .error-text {
  left: 8px;
}
.AddStaffToPractice .action {
  padding-top: 9px;
}
.AddStaffToPractice .action .CircleButton.WhiteButton {
  width: 144px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  margin-left: 15px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.23px;
  color: #ffffff;
  font-family: 'Calibre Regular';
  font-weight: normal;
  border: 0;
  padding: 2px 0;
}
.AddStaffToPractice .para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #b2bcc4;
  width: 398px;
}

@media screen and (max-width: 1366px) {
  .AddStaffToPractice {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .AddStaffToPractice .add-staff-form {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .AddStaffToPractice .email-row {
    width: 100%;
  }
  .AddStaffToPractice .h3 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .AddStaffToPractice .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .AddStaffToPractice {
    padding: 10px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AddStaffToPractice .action .CircleButton.WhiteButton {
    padding-top: 2px;
  }
}

.StaffTab {
  padding: 44px 40px 56px 37px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  max-width: 100%;
  width: 100%;
}
.StaffTab .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 30px;
  display: inline-block;
}
.StaffTab .staff-list {
  display: flex;
  flex-wrap: wrap;
}
.StaffTab .active-staffs .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Regular';
  font-weight: normal;
  margin-bottom: 26px;
  border-bottom: 1px solid #f1f1f5;
  display: block;
  padding-bottom: 7px;
}
.StaffTab .active-staffs .h3 .sub-heading {
  position: relative;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  padding-left: 29px;
  padding-right: 24px;
}
.StaffTab .active-staffs .h3 .sub-heading:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 7px;
  width: 5px;
  height: 5px;
  background-color: rgba(114, 120, 125, 0.3);
  border-radius: 100%;
}
.StaffTab .active-staffs .h3 .update-txt {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  color: #26acda;
  cursor: pointer;
}
.StaffTab .add-staff-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.StaffTab .add-staff-content .add-staff {
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
}
.StaffTab .add-staff-content .add-staff:last-child {
  margin-bottom: 0;
}
.StaffTab .add-staff-content .add-staff .add-staff-inner {
  height: 76px;
  border-radius: 8px;
  border: dashed 1px rgba(1, 54, 72, 0.5);
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #b2bcc4;
  padding: 5px 23px;
}
.StaffTab .add-staff-content .add-staff .plus-icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba(1, 54, 72, 0.2);
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.StaffTab .add-staff-content .add-staff .plus-icon img {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.StaffTab .staffs-content {
  max-height: 626px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1366px) {
  .StaffTab {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .StaffTab .active-staffs .h3,
  .StaffTab .active-staffs .h3 .sub-heading {
    font-size: 18px;
  }
  .StaffTab .staffs-content {
    max-height: none;
  }
}

@media screen and (max-width: 413px) {
  .StaffTab {
    padding: 10px;
  }
}

.WidgetTab {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 27px 36px 40px 40px;
  margin-bottom: 40px;
  width: 53%;
  min-width: 620px;
}
.WidgetTab .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  letter-spacing: -0.2px;
  margin-bottom: 17px;
}
.WidgetTab .widget-textarea,
.WidgetTab .widget-textarea textarea {
  background-color: #f7f9f9;
  color: #72787d;
}
.WidgetTab .widget-textarea {
  padding: 18px 0px;
}
.WidgetTab .MyLink {
  color: #26acda;
}
.WidgetTab .heading {
  margin-bottom: 16px;
  margin-top: 23px;
}
.WidgetTab button {
  background-color: #013648;
  font-size: 20px;
  font-family: 'Calibre Medium';
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  text-align: center;
  color: #f5f5f2;
  padding: 14px 24px 10px 49px;
  position: relative;
}
.WidgetTab button img {
  position: absolute;
  width: 24px;
  left: 16px;
}
.WidgetTab .copy-link {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.WidgetTab .subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #013648;
  margin-bottom: 34px;
}
.WidgetTab .Textarea.form-group .label {
  display: none;
}
.WidgetTab .widget-textarea textarea {
  padding-left: 29px;
  padding-right: 34px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #72787d;
  height: 150px;
}
.WidgetTab .Textarea.form-group .form-control:focus,
.WidgetTab .Textarea.form-group .form-control:not(:placeholder-shown) {
  border: 0;
  outline: 0;
}
.WidgetTab .Textarea.form-group {
  margin-bottom: 13px;
}
.WidgetTab .booking-widget .label-text {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #72787d;
  margin-bottom: 10px;
}
.GoogleMyBusinessAppointment{
  color: #72787d;
}
.GoogleMyBusinessAppointment h4{
  font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #013648;
    letter-spacing: -0.2px;
    margin-bottom: 10px;
}
.GoogleMyBusinessAppointment p{
margin-top: 0;

}
@media (hover: hover) {
  .WidgetTab button:hover {
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .WidgetTab {
    min-width: 100%;
  }
}
@media (max-width: 413px) {
  .WidgetTab {
    padding: 20px 15px;
  }
}

.uploadFiles {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 27px 36px 40px 40px;
  margin-bottom: 40px;
  width: 98%;
  min-width: 620px;
}
.uploadFiles .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  letter-spacing: -0.2px;
  margin-bottom: 17px;
}
.uploadFiles .widget-textarea,
.uploadFiles .widget-textarea textarea {
  background-color: #f7f9f9;
  color: #72787d;
}
.uploadFiles .widget-textarea {
  padding: 18px 0px;
}
.uploadFiles .MyLink {
  color: #26acda;
}
.uploadFiles .heading {
  margin-bottom: 16px;
  margin-top: 23px;
}
.uploadFiles button {
  background-color: #013648;
  font-size: 20px;
  font-family: 'Calibre Medium';
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  text-align: center;
  color: #f5f5f2;
  padding: 14px 24px 10px 49px;
  position: relative;
}
.uploadFiles button img {
  position: absolute;
  width: 24px;
  left: 16px;
}
.uploadFiles .copy-link {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.uploadFiles .subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #013648;
  margin-bottom: 34px;
}
.uploadFiles .Textarea.form-group .label {
  display: none;
}
.uploadFiles .widget-textarea textarea {
  padding-left: 29px;
  padding-right: 34px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #72787d;
  height: 150px;
}
.uploadFiles .Textarea.form-group .form-control:focus,
.uploadFiles .Textarea.form-group .form-control:not(:placeholder-shown) {
  border: 0;
  outline: 0;
}
.uploadFiles .Textarea.form-group {
  margin-bottom: 13px;
}
.uploadFiles .booking-widget .label-text {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #72787d;
  margin-bottom: 10px;
}
.uploadFiles .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
/* .uploadFiles {
  width: calc(100% - 250px);
} */
.uploadFiles .search INPUT {
  padding: 3px 15px 0px 25px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.uploadFiles .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.uploadFiles .rtable {
  display: table;
  background-color: white;
  border: 0px;
  width: 100%;
}
.uploadFiles .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.uploadFiles .row {
  display: table-row;
  width: auto;
  clear: both;
}
.uploadFiles .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.uploadFiles .heading .cell {
  background-color: #013648;
  color: white;
}
.uploadFiles .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.uploadFiles .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.uploadFiles .cell.edit-cell {
  width: 20px;
  text-align: center;
  padding-bottom: 90px;
}
.uploadFiles .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.uploadFiles .table-overflow .heading div{
  padding: 10px !important;
}
.uploadFiles .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.uploadFiles .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.uploadFiles .pagination LI.active {
  background-color: #013648;
  color: white;
}
.uploadFiles .pagination LI.previous.disabled,
.uploadFiles .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.uploadFiles .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.uploadFiles .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.uploadFiles .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.uploadFiles .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.uploadFiles .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.uploadFiles .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.uploadFiles .pointer {
  cursor: pointer;
}
.uploadFiles IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 30%;
    top: 16px;
}
.uploadFiles IMG.delete-icon {
  width: 40px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 26%;
    top: 5px;
}
.uploadFiles .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .uploadFiles .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.uploadFiles .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.uploadFiles .search .Select-control SELECT {
  height: 40px;
}
/* .uploadFiles .input-control INPUT {
  height: 52px;
} */
.uploadFiles .Select-control.form-group:after {
  display: none;
}
.uploadFiles .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .uploadFiles .search BUTTON {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.uploadFiles .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.uploadFiles .search button.btn-clear IMG {
  width: 15px;
  left: 0;
  top: 10px;
}
.uploadFiles .search DIV {
  position: relative;
}
.uploadFiles .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.uploadFiles .dataCount {
  font-size: 16px;
}

@media (hover: hover) {
  .uploadFiles button:hover {
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .uploadFiles {
    min-width: 100%;
  }
}
@media (max-width: 700px) {
  .AfterAuthSettings .setting-tabs .tab-content{
    overflow: auto;
  }
  .uploadFiles {
    min-width: 700px;
  }
}
@media (max-width: 413px) {
  .uploadFiles {
    padding: 20px 15px;
  }
}

.Editfaq {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.faq-xl .modal-wrapper{
max-width: 1024px !important;
}

.dynamic-variables .variables{
border: 1px solid rgba(40, 84, 99, 0.2);
padding: 30px;
}

.main-label{
  font-family: 'Calibre Medium';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #013648;
    margin-bottom: 0;
    position: unset;
    top: unset;
    left: unset;
    transform: none;
}
.Editfaq ul{
  margin: 0;
    padding: 0;
    list-style: circle;
    font-size: 20px;
}
.Editfaq ul li{
  padding: 0 0 10px 0;
    
}
.validation-error{
  color: red;
    font-size: 13px;
    margin-top: 10px;
    display: block;
}
.Editfaq ul li img{
  width: 20px;
    height: 20px;
    background: #013648;
    position: relative;
    top: 6px;
    padding: 3px;
    border: 1px solid #8b8585;
    cursor: pointer;
}
.tox .tox-notification--warn, 
.tox .tox-notification--warning{
  display: none !important;
}
.Editfaq .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.Editfaq .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.Editfaq .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.Editfaq .cross-icon img {
  width: 100%;
}
.Editfaq .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.Editfaq .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.Editfaq .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.Editfaq .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.Editfaq .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.Editfaq .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.Editfaq .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.Editfaq .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.Editfaq .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.Editfaq .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.Editfaq .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.Editfaq .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.Editfaq .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.Editfaq .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.Editfaq .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Editfaq .input-close {
  position: relative;
}
.Editfaq .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.Editfaq .img-preview {
  max-width: 100%;
}
.Editfaq .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}

.addeditfile {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.faq-xl .modal-wrapper{
max-width: 1024px !important;
}

.dynamic-variables .variables{
border: 1px solid rgba(40, 84, 99, 0.2);
padding: 30px;
}

.main-label{
  font-family: 'Calibre Medium';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #013648;
    margin-bottom: 0;
    position: unset;
    top: unset;
    left: unset;
    transform: none;
}
.addeditfile ul{
  margin: 0;
    padding: 0;
    list-style: circle;
    font-size: 20px;
}
.addeditfile ul li{
  padding: 0 0 10px 0;
    
}
.validation-error{
  color: red;
    font-size: 13px;
    margin-top: 10px;
    display: block;
}
.addeditfile ul li img{
  width: 20px;
    height: 20px;
    background: #013648;
    position: relative;
    top: 6px;
    padding: 3px;
    border: 1px solid #8b8585;
    cursor: pointer;
}
.tox .tox-notification--warn, 
.tox .tox-notification--warning{
  display: none !important;
}
.addeditfile .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  /* border: solid 1.5px rgba(40, 84, 99, 0.2); */
  background-color: #ffffff;
}
.addeditfile .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.addeditfile .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.addeditfile .cross-icon img {
  width: 100%;
}
.addeditfile .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.addeditfile .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.addeditfile .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.addeditfile .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.addeditfile .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.addeditfile .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.addeditfile .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.addeditfile .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.addeditfile .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.addeditfile .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.addeditfile .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.addeditfile .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.addeditfile .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.addeditfile .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.addeditfile .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.addeditfile .input-close {
  position: relative;
}
.addeditfile .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.addeditfile .img-preview {
  max-width: 100%;
}
.addeditfile .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
.addeditfile .fileupload {
  min-height: 52px;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  padding: 5px 142px 5px 20px;
  word-break: break-all;
  border: 1.5px solid rgba(1,54,72,.2);
}
.addeditfile .Input-control.form-group .fileupload {
  width: 100%;
  height: 60px;
  background: #fff;
  /* border: 1px solid #013648!important; */
  box-sizing: border-box;
  /* border-radius: 10px; */
}
.addeditfile .fileupload .preview-img {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
.addeditfile .fileupload .preview-img span {
  position: relative;
  display: block;
  top: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.addeditfile .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}
.addeditfile .fileupload .preview-img img {
  max-width: 50px;
  max-height: 33px;
}
.addeditfile .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.addeditfile .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: Calibre Medium;
  font-weight: 500;
  padding-top: 4px;
}
.addeditfile .fileupload .fileupload-btn button {
  background-color: #809ba4;
}
.addeditfile .fileupload .preview-img-multy {
  grid-template-columns: 55px 1fr;
}
.AfterAuthSettings {
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px);
}
.AfterAuthSettings .no-permission-text {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: rgba(38, 172, 218, 0.1);
  padding: 15px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #26acda;
}
.AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
  padding-bottom: 41px;
  padding-right: 445px;
}
.AfterAuthSettings .setting-conettn {
  padding: 0px 20px 0 40px;
}
.AfterAuthSettings .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
}
.AfterAuthSettings .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
  letter-spacing: -0.2px;
}
.AfterAuthSettings .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.AfterAuthSettings .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
  font-family: 'Calibre Medium';
}
.AfterAuthSettings .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.AfterAuthSettings .setting-tabs .tab-content {
  /* background-color: #ffffff; */
  margin-bottom: 40px;
  margin-top: 51px;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  position: relative;
}
.AfterAuthSettings .setting-approvalcard {
  border-radius: 8px;
  background-color: #fff;
  padding: 11px 15px 18px;
  border: 1px solid #e2e2ea;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: -175px;
  width: 375px;
}
.AfterAuthSettings .setting-approvalcard .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.AfterAuthSettings .setting-approvalcard .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.AfterAuthSettings .setting-approvalcard .approval-action {
  display: block;
  margin-top: 10px;
}
.AfterAuthSettings .setting-approvalcard .approval-action SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #013648;
  display: inline-block;
  margin-right: 10px;
  width: 29%;
}
.AfterAuthSettings .setting-approvalcard .approval-action SPAN:last-child {
  margin-right: 0;
}
.AfterAuthSettings .setting-approvalcard .approval-action SPAN IMG {
  width: 20px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}

@media screen and (max-width: 1366px) {
  .AfterAuthSettings .setting-approvalcard {
    padding: 11px 10px 18px;
    width: 330px;
  }
  .AfterAuthSettings .setting-approvalcard .approval-action SPAN {
    margin-right: 33px;
  }
  .AfterAuthSettings .setting-approvalcard .approval-action SPAN IMG {
    margin-right: 5px;
  }
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 30px;
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-right: 365px;
  }
}

@media screen and (max-width: 1200px) {
  .AfterAuthSettings .setting-conettn {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 1200px) {
  .AfterAuthSettings {
    width: calc(100% - 200px);
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
  }
  .AfterAuthSettings .setting-approvalcard {
    top: -225px;
  }
}

@media screen and (max-width: 1024px) {
  .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .AfterAuthSubHeader .main-inner-content SPAN:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 80px;
  }
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 16px;
  }
}

@media screen and (max-width: 767px) {
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 24px;
  }
  .AfterAuthSettings {
    width: 100%;
  }
  .AfterAuthSettings .setting-tabs .tab-list-item:first-child {
    margin-bottom: 15px;
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 41px;
    padding-right: 15px;
  }
  .AfterAuthSettings .setting-approvalcard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .AfterAuthSettings .setting-approvalcard .approval-action SPAN {
    margin-right: 10px;
    width: auto;
  }
}

@media screen and (max-width: 414px) {
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 16px;
    font-size: 16px;
  }
  .AfterAuthSettings .setting-conettn {
    padding: 0px 15px;
  }
}

.BillingTab {
  position: relative;
}
.BillingTab .UILabel {
  z-index: unset;
  right: 0;
  top: -63px;
  position: absolute;
}
.BillingTab .row-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.BillingTab .inner-box-content {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 25px 23px 25px 37px;
}
.BillingTab .hlaf-box-content {
  width: 48%;
}
.BillingTab .full-box-content {
  width: 100%;
}
.BillingTab .provider-content .provider-title,
.BillingTab .payment-content .payment-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
  align-items: center;
}
.BillingTab .ChangeSubscriptionDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
grid-gap: 5px;
gap: 5px;
}
.BillingTab .ChangeSubscriptionDiv .cancel-sub{
  width: 160px;
}
.BillingTab .ChangeSubscriptionDiv .cancel-sub.change-Subscription{
  background-color: #198754;
  color: #fff;
}
.BillingTab .ChangeSubscriptionDiv .cancel-sub.change-Subscription:hover{
  background-color: #013648;
}
.BillingTab .payment-content .payment-title BUTTON {
  cursor: pointer;
}
.BillingTab .provider-content .totalprovider {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  color: #013648;
}
.BillingTab .provider-content .daysleft {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3d93a6;
  min-width: 152px;
  height: 30px;
  padding: 4px 6px;
  background-color: rgba(61, 147, 166, 0.1);
}
.BillingTab .provider-content .description,
.BillingTab .payment-content .description {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #72787d;
}
.BillingTab .provider-content .description SPAN {
  color: #013648;
  font-family: 'Calibre Medium';
}
.BillingTab .provider-content .reconcile SPAN.linkspan,
.BillingTab .provider-content .description SPAN.linkspan {
  font-family: 'Calibre Regular';
  color: #26acda;
  cursor: pointer;
}
.BillingTab .provider-content .reconcile {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #72787d;
  display: flex;
  padding-top: 20px;
}
.BillingTab .provider-content .reconcile p {
  margin: 0;
  padding-right: 5px;
}
.BillingTab .provider-content .SubscribeBtn,
.BillingTab .provider-content .reconcile .Reconcilebtn {
  float: right;
  padding: 1px 18px;
  border: 0;
  background-color: #013648;
  color: #fff;
  font-family: 'Calibre Medium';
  letter-spacing: -0.2px;
  text-align: center;
  padding-top: 6px;
  border-radius: 2px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  font-size: 16px;
  cursor: pointer;
    font-family: 'Calibre Medium';
}
.BillingTab .provider-content .SubscribeBtn:hover
.BillingTab .provider-content .reconcile .Reconcilebtn:hover{
  background-color: #fff;
  color: #013648;
  border: 1px solid #013648;
}
.BillingTab .payment-content .paymentheading {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #013648;
}
.BillingTab .payment-content .descriotion {
  line-height: 1.44;
}
.BillingTab .payment-content BUTTON {
  width: 115px;
  height: 32px;
  border-radius: 2px;
  background-color: #26acda;
  border: 0;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  outline: 0;
  padding: 8px 0px 9px;
}
.BillingTab .invoice-content .heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
  border-bottom: 1px solid #f1f1f5;
  display: flex;
  justify-content: space-between;
}

/* Table content */

.BillingTab .InvoiceDetail {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.BillingTab .InvoiceDetail .date-cell {
  width: 20%;
}
.BillingTab .InvoiceDetail .payment-cell {
  width: 15%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .description-cell {
  width: 20%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .price-cell {
  width: 15%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .status-cell {
  width: 20%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .action-cell {
  width: 10%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .Rtable {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  min-width: 600px;
}
.BillingTab .InvoiceDetail .Rtable-row {
  width: 100%;
  display: flex;
}
.BillingTab .InvoiceDetail .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.BillingTab .InvoiceDetail .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.BillingTab .InvoiceDetail .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.BillingTab .InvoiceDetail .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.BillingTab .InvoiceDetail .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.BillingTab .InvoiceDetail .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
}
.BillingTab .InvoiceDetail .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.BillingTab .InvoiceDetail .Rtable-cell BUTTON {
  width: 81px;
  height: 32px;
  border-radius: 2px;
  background-color: #f1f1f1;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
  outline: 0;
  border: 0;
  padding: 8px 0px 9px;
  cursor: pointer;
}
.BillingTab .cancel-sub {
  width: 144px;
  padding: 2px 10px;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  padding-top: 9px;
  background-color: orange;
  border: 0;
  color: #013648;
  box-shadow: none;
  outline: 0;
  border-radius: 2px;
  cursor: pointer;
}
.BillingTab .cancel-sub:hover {
  background-color: #013648;
  color: #fff;
}
@media (max-width: 1200px) {
  .BillingTab .provider-content .daysleft {
    min-width: 120px;
    width: auto;
  }
  .BillingTab .provider-content .totalprovider {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .BillingTab .InvoiceDetail .Rtable-cell.content-cell,
  .BillingTab .InvoiceDetail .Rtable-cell.column-heading {
    font-size: 16px;
  }
  .BillingTab .InvoiceDetail .status-cell {
    width: 10%;
  }
  .BillingTab .InvoiceDetail .description-cell {
    width: 30%;
  }
  .BillingTab .row-content {
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
  .BillingTab .hlaf-box-content {
    width: 100%;
    margin-bottom: 18px;
  }
}
@media (max-width: 1024px) {
  .BillingTab .inner-box-content {
    padding: 15px 10px;
  }
}

.CancelSubscription {
  width: 570px;
}
.CancelSubscription .modal-content-footer .CircleButton.WhiteButton {
  width: 190px;
}
.RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton{
  width: 175px;
}
.CancelSubscription .modal-content-footer {
  display: flex;
  flex-direction: row-reverse;
  grid-gap: 5px;
  gap: 5px;
}
.CancelSubscription .modal-content-footer{
  padding: 11px 15px 15px;
  border-top: .5px solid #01364824
}
@media screen and (max-width: 600px) {
  .RemoveStripeSubscription,
  .CancelSubscription {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .CancelSubscription .modal-content-footer{
    flex-direction: column-reverse;
  }
  .RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton,
  .CancelSubscription .modal-content-footer .CircleButton.WhiteButton {
    width: 100%;
  }
 
}
.SingleRating .name {
  font-size: 16px;
  line-height: 2.13;
  letter-spacing: -0.2px;
  color: #013648;
  /* width: 98px; */
  margin-right: 7px;
}
.SingleRating .number {
  width: 20px;
  height: 14px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 0.84;
  color: #013648;
  margin-left: 8px;
  display: inline-block;
  line-height: 1.3;
}
.SingleRating .progressbar {
  width: 94px;
  margin-left: auto;
}
.SingleRating {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1400px) {
  .SingleRating .name {
    font-size: 14px;
    width: 85px;
    margin-right: 5px;
  }
  .SingleRating .progressbar {
    width: 76px;
  }
  .SingleRating .name {
    width: 85px;
  }
  .SingleRating .number {
    font-size: 14px;
  }
  .KScore .all-score .rating-content {
    padding: 0px 6px;
  }
}
@media (max-width: 767px) {
  .SingleRating .progressbar {
    width: clac(100% - 78px);
  }
}


.KScore .k-score .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.05;
  letter-spacing: -0.32px;
  color: #013648;
  margin-bottom: 38px;
}
.KScore .k-score .star IMG {
  width: 49px;
}
.KScore .k-score .rating {
  font-family: 'Heldane Display';
  font-size: 86px;
  line-height: 0.77;
  color: #013648;
  padding: 0px 15px 0px 10px;
}
.KScore .k-score .Review {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
}
.KScore .k-score-content {
  padding-right: 40px;
}
.KScore .k-score {
  display: flex;
  justify-content: space-between;
}
.KScore .k-score .content-score {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.KScore .all-score {
  display: flex;
}
.KScore .all-score .rating-content .SingleRating .progressbar {
  width: 50px;
  margin-left: auto;
}
.KScore .all-score .rating-content .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  margin-bottom: 10px;
}
.KScore .all-score .rating-content {
  padding: 0px 30px;
  position: relative;
}
.KScore .all-score .rating-content:after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #efefef;
  top: 34px;
  bottom: 8px;
  left: 0px;
  color: #fff;
}
@media (max-width: 1400px) {
  .KScore .k-score .review-count {
    font-size: 18px;
  }
  .KScore .all-score .rating-content {
    padding: 0px 15px;
  }
  .KScore .all-score .rating-content:last-child {
    padding-right: 0;
  }
  .KScore .k-score .rating {
    font-size: 64px;
  }
  .KScore .k-score .star IMG{
    width: 35px;
  }
}
@media (max-width: 1200px) {
  .KScore .k-score {
    flex-wrap: wrap;
  }
  .KScore .k-score .h3{
    margin-bottom: 20px;
  }
  .KScore .k-score-content{
      padding-right: 0px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      width: 100%;
      border-bottom: 1px solid #efefef;
  }
  .KScore .all-score{
    width: 100%;
  }
  .KScore .all-score .rating-content{
    width: 33.33%;
  }
  .KScore .all-score .rating-content:first-child{
    padding-left: 0;
  }
  .KScore .all-score .rating-content:first-child:after{
  display: none;
  }

}
@media (max-width: 1024px) {
  .KScore .all-score .rating-content{
    padding: 0 6px;
  }
  .KScore .all-score .rating-content .SingleRating .progressbar{
    width: calc(100% - 125px);
  }
}
@media (max-width: 767px) {
  .KScore .all-score{
  flex-wrap: wrap;
  }
  .KScore .all-score .rating-content{
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  } 
  .KScore .all-score .rating-content:after{
    display: none;
  }
}

.ReadMore .review-pragraph {
  font-size: 18px;
  line-height: 1.28;
  color: #013648;
  margin-top: 18px;
}
.ReadMore .readmore-link {
  color: #26acda;
  cursor: pointer;
}

.ReviewDetail {
  padding-top: 47px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(143, 146, 161, 0.2);
}
.ReviewDetail .review-detail-innercontent {
  display: flex;
}
.ReviewDetail .review-detail-innercontent .Description {
  flex: 3 1;
  padding-right: 60px;
}
.ReviewDetail .review-detail-innercontent .rating {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.ReviewDetail .review-detail-innercontent .rating .rating-list .SingleRating .name {
  width: auto;
}
.ReviewDetail .review-detail-innercontent > DIV {
  flex: 1 1;
}
.ReviewDetail .review-detail-innercontent .name .person-name {
  min-width: 132px;
}
.ReviewDetail:first-child {
  padding-top: 7px;
}
.linka{
  color: rgba(1, 54, 72, 0.4);
  text-decoration: underline;
}
.ReviewDetail:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.ReviewDetail .star-rating .name {
  font-size: 18px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-right: 6px;
}
.ReviewDetail .star-rating .number {
  font-size: 20px;
  line-height: 0.68;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Heldane Display';
  font-weight: normal;
  position: relative;
}
.ReviewDetail .star-rating .rate-icon {
  width: 14px;
  margin-right: 4px;
  height: 13px;
  position: relative;
  left: -1px;
}
.ReviewDetail .review-detail-innercontent .name .person-name {
  width: 100%;
  display: inline-block;
  font-family: 'Calibre Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: #013648;
}
.ReviewDetail .rating-list {
  margin-left: auto;
}
.ReviewDetail .review-detail-innercontent .name .link-span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #26acda;
  margin-bottom: 30px;
  cursor: pointer;
}
.ReviewDetail .review-detail-innercontent .name .status-btn {
  border-radius: 1px;
  border: 0;
  color: #fff;
  margin-bottom: 22px;
  padding: 6px 13px 3px 13px;
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  line-height: 15px;
}
.ReviewDetail .review-detail-innercontent .name .recent-btn {
  background-color: #f37184;
}
.ReviewDetail .review-detail-innercontent .name .featured-btn {
  background-color: #013648;
}
.ReviewDetail .review-detail-innercontent .name .logo {
  width: 100%;
  display: inline-block;
}
.ReviewDetail .review-detail-innercontent .name .logo IMG {
  width: 29px;
  height: 29px;
  object-fit: contain;
}
.ReviewDetail .review-detail-innercontent .Description .datetime {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  margin-top: 7px;
  display: inline-block;
}
.ReviewDetail .review-detail-innercontent .Description .link {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  display: inline-block;
  margin-top: 30px;
  cursor: pointer;
}
.ReviewDetail .Confirmation {
  padding: 20px;
  border-radius: 2px;
  background-color: rgba(1, 54, 72, 0.03);
  margin-top: 20px;
}
.ReviewDetail .confirmation-content {
  border-radius: 2px;
  border: solid 1px rgba(1, 54, 72, 0.5);
  background-color: #ffffff;
  padding: 20px;
}
.ReviewDetail .confirmation-content .textarea-wrapper TEXTAREA {
  width: 100%;
  border: 0;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 36px;
  display: inline-block;
  width: 100%;
}
.ReviewDetail .confirmation-content .textarea-wrapper TEXTAREA:focus {
  outline: 0;
}
.ReviewDetail .CircleButton {
  display: inline-block;
  width: 111px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
}
.ReviewDetail .reply IMG {
  width: 14px;
  height: 11px;
  margin-right: 10px;
}
.ReviewDetail .replyBox {
  background-color: rgba(1, 54, 72, 0.03);
  padding: 20px;
  font-size: 16px;
}
.ReviewDetail .replyBox .reply-text {
  margin-top: 10px;
  word-break: break-word;
}
.ReviewDetail .popup-rating {
  position: absolute;
  top: -146px;
  left: -63px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  z-index: 999;
  padding: 20px;
}
.ReviewDetail .review-detail-innercontent .rating .featured-review {
  margin-top: auto;
  margin-left: auto;
  width: 100%;
  text-align: right;
}
.ReviewDetail .review-detail-innercontent .rating .featured-review .CircleButton {
  width: auto;
  font-size: 13px;
  margin-top: 15px;
  line-height: unset;
  padding: 5px 11px 2px;
}
.ReviewDetail .confirmation-content-action {
  display: flex;
  align-items: baseline;
  flex-flow: column;
}
.ReviewDetail .confirmation-content-action > SPAN {
  display: inline-block;
  padding-top: 15px;
  font-size: 16px;
  color: rgba(1, 54, 72, 0.4);
}
.ReviewDetail .replyaction .btn {
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.ReviewDetail .replyaction .btn SPAN.link {
  margin: auto 0;
  display: inline-block;
  position: relative;
  top: 3px;
}
.ReviewDetail .replyaction .btn IMG {
  width: 15px;
  margin-right: 5px;
}
.ReviewDetail .replyaction {
  margin-top: 15px;
}
@media (hover: hover) {
  .ReviewDetail .rating .rating-list > .SingleRating:hover .name,
  .ReviewDetail .rating .rating-list > .SingleRating:hover .number {
    color: #3d93a6;
  }
  .ReviewDetail .rating .rating-list > .SingleRating:hover .progress-bar {
    background-color: #3d93a6;
  }
}

@media (max-width: 1400px) {
  .ReviewDetail .review-detail-innercontent .Description {
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .ReviewDetail .confirmation-content-action {
    flex-wrap: wrap;
  }
  .ReviewDetail .confirmation-content-action > SPAN {
    padding-left: 0;
    /* margin-top: 10px; */
  }
}

@media (max-width: 767px) {
  .ReviewDetail .review-detail-innercontent {
    flex-wrap: wrap;
  }
  .ReviewDetail .review-detail-innercontent > DIV {
    flex: inherit;
    width: 100%;
  }
  .ReviewDetail .review-detail-innercontent .Description {
    padding-right: 0;
  }
  .ReviewDetail .review-detail-innercontent .rating {
    width: 100%;
    margin-top: 15px;
  }
  .ReviewDetail .review-detail-innercontent > DIV.name {
    position: relative;
    padding-left: 40px;
  }
  .ReviewDetail .rating-list {
    margin-left: 0;
    width: 100%;
  }
  .ReviewDetail .review-detail-innercontent .rating .featured-review {
    margin-top: 10px;
  }
  .ReviewDetail .review-detail-innercontent .name .logo {
    position: absolute;
    left: 0;
    top: 0;
  }
  .ReviewDetail .popup-rating {
    right: 15px;
    top: 100px;
    left: 20px;
  }
  .ReviewDetail {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .ReviewDetail .review-detail-innercontent .Description .link {
    margin-top: 15px;
  }
}

.ReviewTabs .tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin: 0;
}
.ReviewTabs .no-desingn {
  text-align: center;
  font-size: 25px;
}
.ReviewTabs .review-list {
  padding: 40px 0 0;
}
.ReviewTabs .tab-list-item:nth-child(4) {
  display: none;
}
.ReviewTabs .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  /* right: 17px; */
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.ReviewTabs .info-wrapper {
  position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}
.ReviewTabs .info-wrapper img{
  position: absolute;
  top: -20px;
  width: 14px;
}
.ReviewTabs .info-wrapper:hover .info-content{
 display: block;
}
.ReviewTabs .tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0px 15px;
  margin: 0px 60px 0 0;
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(1, 54, 72, 0.3);
  position: relative;
  cursor: pointer;
}
.ReviewTabs .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewTabs .tab-list-active {
  background-color: #fff;
  border: solid #013648;
  border-width: 0px 0px 4px 0px;
  color: rgb(1, 54, 72);
}
.ReviewTabs .tab-list-item .review-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  right: -24px;
  top: -9px;
}
.ReviewTabs .tab-list-active .review-count {
  color: #72787d;
}
.ReviewTabs .review-sort-wrapper {
  margin-bottom: -20px;
  margin-top: -20px;
  text-align: right;
}
.ReviewTabs .review-sort-wrapper SPAN {
  padding: 3px 11px 2px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #203a70;
  font-family: 'Calibre Medium';
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #013648;
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
}
.ReviewTabs .review-sort-wrapper SPAN.active {
  background-color: #013648;
  color: #fff;
}

@media (hover: hover) {
  .ReviewTabs .review-sort-wrapper SPAN:hover {
    background-color: #013648;
    color: #fff;
  }
}

@media (max-width: 1366px) {
  .ReviewTabs .tab-list-item {
    font-size: 20px;
    margin: 0px 50px 0 0;
  }
}

@media (max-width: 1200px) {
  .ReviewTabs .tab-list-item {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .ReviewTabs .tab-list {
    margin: 0px -10px;
  }
  .ReviewTabs .tab-list-item,
  .ReviewTabs .tab-list-item:first-child {
    margin-right: 50px;
    margin-left: 20px;
    margin-top: 10px;
  }
  .ReviewTabs .review-sort-wrapper{
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .ReviewTabs .tab-list {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .ReviewTabs .tab-list-item {
    white-space: nowrap;
  }
  .ReviewTabs .info-wrapper .info-content{
    white-space: break-spaces ;
    right: -4px;
    top: -11px;
  }

    .ReviewTabs .tab-list.min-h{
min-height: 120px;
    }
  
}

.AfterAuthReviews {
  width: 100%;
}
.AfterAuthReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.AfterAuthReviews .box-panle .Select-control {
  margin-bottom: 0;
  margin-right: 18px;
}
.AfterAuthReviews .box-panle .Select-control  SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 27px;
  padding-left: 22px;
  width: 200px;
  color: #44444f;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.11px;
}
.AfterAuthReviews .box-panle .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 16px;
}
.AfterAuthReviews .ReviewTabs .tab-list{
  padding-right: 520px;
}
.AfterAuthReviews .box-panle .search-review-list {
  position: absolute;
  right: 30px;
  top: 37px;
  display: flex;
  align-items: center;
}
.AfterAuthReviews .box-panle .search-review-list .Input-control.form-group .view-img {
  right: auto;
  left: 9px;
  width: 21px;
  height: 17px;
  top: 19px;
}
.AfterAuthReviews .search-review-list .Input-control.form-group INPUT::placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 36px;
  color: #013648;
}
.AfterAuthReviews .box-panle .search-review-list .Input-control {
  width: 300px;
  height: 38px;
  margin-bottom: 0;
}
.AfterAuthReviews .box-panle .search-review-list INPUT {
  border-radius: 10px;
  background-color: #fafafb;
  height: 38px;
  border: solid 1px #e2e2ea;
  padding-left: 37px;
}
.AfterAuthReviews .box-panle .search-review-list INPUT:focus,
.AfterAuthReviews .box-panle .search-review-list INPUT:not(:placeholder-shown) {
  outline: 0;
  padding-left: 37px;
}
.AfterAuthReviews .box-panle {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 42px 30px 38px 40px;
  margin-bottom: 40px;
  position: relative;
}

@media (max-width: 1400px) {
  .AfterAuthReviews .box-panle {
    padding: 40px 20px;
  }
  .AfterAuthReviews .box-panle .search-review-list {
    right: 20px;
  }
  .AfterAuthReviews .box-panle .search-review-list .Input-control {
    width: 230px;
  }
  .AfterAuthReviews .box-panle .Select-control {
    margin-right: 12px;
  }
  .AfterAuthReviews .ReviewTabs .tab-list {
    padding-right: 430px;
  }
  .AfterAuthReviews .box-panle .Select-control SELECT{
    padding-right: 20px;
    padding-left: 15px;
    width: 180px;
  }
  .AfterAuthReviews .box-panle .Select-control .arrow{
    right: 8px;
  }
}

@media (max-width: 1366px){
  .AfterAuthReviews .ReviewTabs .tab-list {
    padding-right: 390px;
  }
  .AfterAuthReviews .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .AfterAuthReviews .box-panle .Select-control SELECT {
    width: 170px;
  }
}

@media (max-width: 1199px) {
  .AfterAuthReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .AfterAuthReviews .main-section-content {
    padding: 0 15px 0;
  }
  .AfterAuthReviews .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
}

@media (max-width: 767px) {
  .AfterAuthReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .AfterAuthReviews .box-panle {
    padding: 20px 10px;
  }
  .AfterAuthReviews .box-panle .search-review-list {
    margin-top: 10px;
  }
}

@media (max-width: 480px){
  .AfterAuthReviews .box-panle .search-review-list{
    flex-wrap: wrap;
  }
  .AfterAuthReviews .box-panle .Select-control {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .AfterAuthReviews .box-panle .Select-control select {
    width: 100%;
  }
  .AfterAuthReviews .box-panle .search-review-list .Input-control {
    width: 100%;
  }
}

.AddAvailability {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
}
.AddAvailability .days-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.AddAvailability .days-checkbox-container .Select-days {
  width: 100%;
  height: 34px;
  border-radius: 9px;
  border: solid 0.9px #e2e2ea;
  display: flex;
  overflow: hidden;
}
.AddAvailability .days-checkbox-container .Checkbox-control {
  flex: 1 1;
  position: relative;
  padding-left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
}
.AddAvailability .days-checkbox-container .Checkbox-control INPUT {
  width: 100%;
  height: 100%;
}
.AddAvailability .days-checkbox-container .Checkbox-control .checkmark {
  width: 100%;
  height: 100%;
  border: none;
}
.AddAvailability .days-checkbox-container .Checkbox-control .checkmark:after {
  display: none;
}
.AddAvailability .days-checkbox-container .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(0, 17, 51, 0.2);
}
.AddAvailability .select-event-day-content {
  display: flex;
}
.AddAvailability .select-event-day-content .Select-control.form-group SELECT {
  padding-right: 38px;
}
.AddAvailability .select-event-day-content .Select-control.form-group SELECT OPTION {
  font-family: sans-serif;
  font-size: 14px;
}
.AddAvailability .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.AddAvailability .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.AddAvailability .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0;
}
.AddAvailability .dailog-close-btn IMG {
  height: 100%;
  width: 100%;
}
.AddAvailability .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.AddAvailability .dailog-body {
  padding: 20px 32px 15px;
}
.AddAvailability .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.AddAvailability .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.AddAvailability .virtual-field .Checkbox-control {
  font-size: 18px;
}
.AddAvailability .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.AddAvailability .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.AddAvailability .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.AddAvailability .action-btn .error {
  color: red;
  font-size: 16px;
  display: block;
  text-align: left;
  width: 100%;
}
.AddAvailability .action-btn {
  text-align: right;
}
.AddAvailability .dailog-body .Input-control .form-control {
  border: 0;
  padding-left: 50px;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.AddAvailability .select-date-content {
  padding-left: 42px;
  position: relative;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 34px;
  line-height: 1.8;
  z-index: 100;
  display: flex;
}
.AddAvailability .time-padding {
  padding: 8px 8px 5px 0;
  margin-right: 10px;
}
.AddAvailability span.small-label {
  display: block;
  line-height: 0.3;
  color: #a29d9dbf;
  font-size: 13px;
  font-weight: normal;
  margin-bottom: 5px;
}
.AddAvailability .gray-box {
  position: relative;
  z-index: 1;
}
.AddAvailability .gray-box .gray-back {
  background: #e9ebf2;
  padding: 8px 12px 5px 9px;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  position: absolute;
  left: -8px;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
  z-index: -1;
}
.AddAvailability .select-date-content .icon {
  position: absolute;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
}
.AddAvailability .select-date-content .icon IMG {
  width: 100%;
}
.AddAvailability .select-time .TimeManual .result {
  top: 33px;
}
.AddAvailability .select-date-content .select-time {
  padding-left: 0px;
  padding-right: 5px;
  font-weight: 700;
  display: inline-flex;
  width: 250px;
  position: relative;
  top: -2px;
}
.AddAvailability .time-devider {
  display: inline-block;
  padding: 0 5px;
  padding-top: 9px;
}
.AddAvailability .TimeManual .Input-control {
  margin-bottom: 0;
}
.AddAvailability .TimeManual .Input-control INPUT.form-control {
  display: inline-flex;
  padding-left: 10px;
  border: 1px solid black;
  height: 30px;
  padding-right: 10px;
}
.AddAvailability .end-date {
  /* color: rgba(1, 54, 72, 0.5);
  min-width: 140px; */
  margin-left: 15px;
}
.AddAvailability .select-picker-content {
  position: relative;
  display: inline-block;
  min-width: 135px;
}
.AddAvailability .select-picker-content .Datepicker-content {
  position: absolute;
  top: 35px;
  z-index: 99;
}
.AddAvailability .Datepicker-content.time.end-time {
  position: absolute;
}
.AddAvailability .datepicker-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.AddAvailability .location {
  position: relative;
  padding-left: 41px;
}
.AddAvailability .dailog-body .location .Select-control {
  width: 100%;
  background-color: #f1f2f2;
}
.AddAvailability .location .icon {
  position: absolute;
  left: 1px;
  top: 4px;
}
.AddAvailability .dailog-body .location .Select-control SELECT {
  padding-left: 18px;
  height: 37px;
}
.AddAvailability .dailog-body .location .Select-control SELECT OPTION {
  font-family: sans-serif;
  font-size: 14px;
}
.AddAvailability .dailog-body .location .Select-control:after {
  display: none;
}
.AddAvailability .dailog-body .location .Select-control .arrow {
  border: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 6px;
  right: 10px;
}
.AddAvailability .dropdown {
  position: relative;
}
.AddAvailability .dropdown .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all linear;
  z-index: 9;
  background-color: #ffffff;
  min-width: 300px;
}
.AddAvailability .dropdown .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.AddAvailability .dropdown .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
  min-width: 300px;
  z-index: 101;
}
.AddAvailability .virtual-field {
  cursor: pointer;
}
.AddAvailability .virtual-field .Checkbox-control {
  padding-left: 48px;
}
.AddAvailability .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.AddAvailability .disabled .Checkbox-control {
  color: rgba(1, 54, 72, 0.29);
}
.AddAvailability .disabled .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(1, 54, 72, 0.06);
}
.AddAvailability .select-event-day-content IMG {
  position: relative;
  top: 6px;
}
.AddAvailability .disabled .Select-control,
.AddAvailability .disabled IMG {
  opacity: 0.29;
}
.AddAvailability .cross-icon IMG {
  width: 100%;
}
@media (hover: hover) {
  .AddAvailability .dropdown .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
@media (max-width: 767px) {
  .AddAvailability .select-date-content,
  .AddAvailability .select-event-day-content {
    flex-wrap: wrap;
  }
  .AddAvailability .select-picker-content {
    margin-bottom: 15px;
  }
  .AddAvailability .dailog-body .Select-control {
    margin-bottom: 15px;
  }
  .AddAvailability .select-event-day-content {
    margin-bottom: 34px;
  }
  .AddAvailability .select-event-day-content .Select-control {
    width: 226px;
  }
  .AddAvailability .select-event-day-content .Select-control SELECT {
    padding-left: 20px;
  }
  .AddAvailability .select-picker-content.end-date {
    margin-left: 0;
    margin-top: 5px;
    margin-bottom: 0;
  }
  .AddAvailability {
    overflow: scroll;
  }
  .AddAvailability .select-picker-content .Datepicker-content{
    position: unset;
    top: 0px;
    left: 0 !important;
  }
  .MyDatetime .rdtPicker {
    position: relative;
  }
  
}

@media (max-width: 545px) {
  .AddAvailability .select-picker-content.end-date {
    margin-left: 0;
    margin-top: 20px;
    margin-bottom: 0;
  }
}
@media (max-width: 375px) {
  .AddAvailability .select-event-day-content .Select-control {
    width: 200px;
  }
  .AddAvailability .dailog-body {
    padding: 20px 15px 15px;
  }
  .AddAvailability .dailog-header {
    padding: 22px 15px 5px 15px;
  }
  .AddAvailability .dailog-close-btn{
    margin-left: 10px;
  }
  .AddAvailability .cross-icon {
    right: 15px;
  }
}

.AddAvailabilityBreak {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
}
.AddAvailabilityBreak .dailog-header {
  padding: 20px 65px 15px 30px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.AddAvailabilityBreak .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
}
.AddAvailabilityBreak .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.AddAvailabilityBreak .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.AddAvailabilityBreak .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.AddAvailabilityBreak .dailog-body {
  padding: 20px 32px 15px;
}
.AddAvailabilityBreak .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.AddAvailabilityBreak .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.AddAvailabilityBreak .virtual-field .Checkbox-control {
  font-size: 18px;
}
.AddAvailabilityBreak .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.AddAvailabilityBreak .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.AddAvailabilityBreak .action-btn .error {
  color: red;
  font-size: 16px;
}
.AddAvailabilityBreak .action-btn {
  text-align: right;
}
.AddAvailabilityBreak .select-date-content {
  padding-left: 42px;
  position: relative;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 34px;
  line-height: 1.8;
  z-index: 100;
  display: flex;
}
.AddAvailabilityBreak .gray-box {
  position: relative;
  z-index: 1;
}
.AddAvailabilityBreak .gray-box .gray-back {
  background: #e9ebf2;
  padding: 8px 12px 5px 9px;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  position: absolute;
  left: -8px;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
  z-index: -1;
}
.AddAvailabilityBreak .select-date-content .icon {
  position: absolute;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
}
.AddAvailabilityBreak .select-date-content .icon IMG {
  width: 100%;
}
.AddAvailabilityBreak .select-date-content .select-time {
  padding-left: 0px;
  padding-right: 25px;
  font-weight: 700;
  display: inline-flex;
  width: 250px;
  position: relative;
  top: -2px;
}
.AddAvailabilityBreak .time-devider {
  display: inline-block;
  padding: 0 5px;
}
.AddAvailabilityBreak .TimeManual .Input-control {
  margin-bottom: 0;
}
.AddAvailabilityBreak .TimeManual .Input-control INPUT.form-control {
  display: inline-flex;
  padding-left: 10px;
  border: 1px solid black;
  height: 30px;
  padding-right: 10px;
}
.AddAvailabilityBreak .end-date {
  /* color: rgba(1, 54, 72, 0.5);
  min-width: 140px; */
}
.AddAvailabilityBreak .select-picker-content {
  position: relative;
  display: inline-block;
  min-width: 144px;
}
.AddAvailabilityBreak .select-picker-content .Datepicker-content {
  position: absolute;
  top: 35px;
  z-index: 99;
}
.AddAvailabilityBreak .Datepicker-content.time.end-time {
  position: absolute;
}
.AddAvailabilityBreak .datepicker-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.AddAvailabilityBreak .location {
  position: relative;
  padding-left: 41px;
}
.AddAvailabilityBreak .dailog-body .location .Select-control {
  width: 100%;
  background-color: #f1f2f2;
}
.AddAvailabilityBreak .location .icon {
  position: absolute;
  left: 1px;
  top: 4px;
}
.AddAvailabilityBreak .dailog-body .location .Select-control SELECT {
  padding-left: 18px;
  height: 37px;
}
.AddAvailabilityBreak .dailog-body .location .Select-control:after {
  display: none;
}
.AddAvailabilityBreak .dailog-body .location .Select-control .arrow {
  border: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 6px;
  right: 10px;
}
.AddAvailabilityBreak .dropdown {
  position: relative;
}
.AddAvailabilityBreak .dropdown .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all linear;
  z-index: 9;
  background-color: #ffffff;
  min-width: 300px;
}
.AddAvailabilityBreak .dropdown .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.AddAvailabilityBreak .dropdown .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
  min-width: 300px;
  z-index: 101;
}
.AddAvailabilityBreak .virtual-field {
  cursor: pointer;
}
.AddAvailabilityBreak .virtual-field .Checkbox-control {
  padding-left: 48px;
}
.AddAvailabilityBreak .cross-icon {
  position: absolute;
  width: 19px;
  top: 20px;
  cursor: pointer;
  right: 30px;
}
.AddAvailabilityBreak .disabled .Checkbox-control {
  color: rgba(1, 54, 72, 0.29);
}
.AddAvailabilityBreak .disabled .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(1, 54, 72, 0.06);
}
.AddAvailabilityBreak .select-event-day-content IMG {
  position: relative;
  top: 6px;
}
.AddAvailabilityBreak .disabled .Select-control,
.AddAvailabilityBreak .disabled IMG {
  opacity: 0.29;
}
.AddAvailabilityBreak .cross-icon IMG {
  width: 100%;
}
.AddAvailabilityBreak .select-date-content .result {
  top: 31px;
}
@media (hover: hover) {
  .AddAvailabilityBreak .dropdown .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
@media (max-width: 767px) {
  .AddAvailabilityBreak .select-date-content,
  .AddAvailabilityBreak .select-event-day-content {
    flex-wrap: wrap;
  }
  .AddAvailabilityBreak .select-picker-content {
    margin-bottom: 15px;
  }
  .AddAvailabilityBreak .dailog-body .Select-control {
    margin-bottom: 15px;
  }
  .AddAvailabilityBreak .select-event-day-content {
    margin-bottom: 34px;
  }
  .AddAvailabilityBreak .select-event-day-content .Select-control {
    width: 226px;
  }
  .AddAvailabilityBreak .select-event-day-content .Select-control SELECT {
    padding-left: 20px;
  }
  .AddAvailabilityBreak .dailog-body {
    padding: 20px 15px 15px;
  }
  .AddAvailabilityBreak .dailog-header {
    padding-left: 15px;
  }
}

.CalendarToolbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 17px;
  align-items: center;
}
.CalendarToolbar .date-range {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.11px;
  color: #013648;
}
.CalendarToolbar .date-range span {
  font-weight: normal;
}
.CalendarToolbar .cal-btn {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  margin: 0px 15px;
  cursor: pointer;
  display: inline-flex;
}
.CalendarToolbar .cal-btn img {
  margin: auto;
}
.CalendarToolbar .cal-btn:focus {
  outline: 0;
}
.CalendarToolbar .calendar-datepicker {
  display: inline-block;
  position: relative;
}
.CalendarToolbar .datepicker {
  position: absolute;
  z-index: 5;
  top: 40px;
  right: 0px;
}
.CalendarToolbar .arrow-btn {
  margin: 0px 5px;
}

@media (max-width: 767px) {
  .CalendarToolbar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .CalendarToolbar > div {
    width: 100%;
  }
  .CalendarToolbar > div:last-child {
    text-align: right;
    margin-bottom: 10px;
  }
}

.CalendarEvent.rbc-event {
  background-color: #fff9f2;
  border: solid 1px #e2e2ea;
  border-left: 8px solid #fca43d;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 5px 0px 0px 7px;
  overflow: visible;
}
.CalendarEvent.rbc-event.video-visit {
  border-left: 8px solid #8d77ba;
  background-color: hsl(262, 100%, 98%);
}
.CalendarEvent.rbc-event.video-visit.in-person-visit {
  border-left: 8px solid #00cec9;
  background-color: #e8ffff;
}
.CalendarEvent .main-event {
  font-family: 'Calibre Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 0.88;
  letter-spacing: 0.11px;
  color: #013648;
  height: 100%;
}
.CalendarEvent .main-event .icon {
  margin-bottom: 5px;
  display: inline-block;
  right: 5px;
  width: 16px;
  position: absolute;
}
.CalendarEvent .main-event .icon IMG {
  width: 100%;
}
.CalendarEvent .main-event .time {
  line-height: 16px;
  padding-right: 25px;
  white-space: nowrap;
}
.CalendarEvent .main-event .hover-content.show {
  display: block;
}
.CalendarEvent .main-event .hover-content {
  display: none;
  width: 197px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  position: relative;
  z-index: 9;
  top: -90px;
  left: 73px;
  background-color: #fff9f2;
  border-left: 8px solid#fca43d;
}
.CalendarEvent .main-event .hover-content.video-visit {
  border-left: 8px solid #8d77ba;
  background-color: #f8f4ff;
}
.CalendarEvent .main-event .hover-content.video-visit.in-person-visit {
  border-left: 8px solid #00cec9;
  background-color: #e8ffff;
}
.CalendarEvent .main-event .hover-content.no-edit {
  top: -37px;
}
.CalendarEvent .main-event .hover-content .hover-content-date {
  padding: 11px 10px 2px 11px;
  margin-bottom: 4px;
  cursor: auto;
}
.CalendarEvent .main-event .hover-content .day {
  font-family: 'Calibre Medium';
  font-weight: 500;
  font-size: 15px;
  line-height: 1.33;
  letter-spacing: 0.09px;
  color: #013648;
  margin-bottom: -4px;
  display: inline-block;
}
.CalendarEvent .main-event .hover-content .icon {
  width: 12px;
  position: relative;
  left: 4px;
  top: 3px;
}
.CalendarEvent .main-event .hover-content .time {
  font-size: 15px;
  line-height: 1.33;
  color: #013648;
}
.CalendarEvent .main-event .hover-content .edit {
  font-size: 16px;
  color: #013648;
  background-color: #fff;
  padding: 19px 10px;
  border-bottom: 1px solid #fafafb;
}

@media (hover: hover) {
  .CalendarEvent .hover-content .edit:hover {
    background-color: #efefef;
  }
}

@media (max-width: 1400px) {
  .CalendarEvent .main-event .time {
    font-size: 14px;
  }
  .CalendarEvent .main-event .icon {
    width: 10px;
  }
}

.CalendarDayHeader {
  height: 56px;
  font-size: 16px;
  letter-spacing: 0.13px;
  text-align: center;
  color: #013648;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ActivityItem {
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #f7f9f9;
  margin-bottom: 9px;
  padding: 19px 17px 12px 28px;
  position: relative;
  overflow: hidden;
}
.ActivityItem:after {
  content: '';
  width: 8px;
  background-color: #199daf;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.ActivityItem.cancelled:after {
  background-color: #f37184;
}
.ActivityItem.cancelled {
  background-color: #f7f9f9;
}
.ActivityItem.pending:after {
  background-color: #f2962d;
}
.ActivityItem.cancelled .day,
.ActivityItem.cancelled .name,
.ActivityItem.cancelled .view-patient {
  text-decoration: line-through;
}
.ActivityItem.completed::after {
  background-color: #f1f1f1;
}
.ActivityItem .day {
  font-size: 20px;
  letter-spacing: 0.17px;
  color: #013648;
  position: relative;
  padding-right: 25px;
  margin-bottom: 5px;
}
.ActivityItem .day img {
  width: 20px;
  position: absolute;
  right: 0;
}
.ActivityItem .name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  line-height: 1.11;
  letter-spacing: 0.11px;
  color: #013648;
}
.ActivityItem A,
.ActivityItem .link-span {
  display: inline-block;
  width: 100%;
  line-height: 1.25;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #26acda;
  cursor: pointer;
}
.ActivityItem .view-patient {
  margin-bottom: 15px;
}
.ActivityItem .cancel-staus {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.11px;
  color: #798380;
}

.ScheduleActivity {
  width: 350px;
  min-width: 350px;
  border-radius: 20px;
  background-color: #ffffff;
}
.ScheduleActivity .title .h3 {
  font-family: 'Calibre Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.11px;
  color: #013648;
  display: inline-flex;
  align-items: center;
}
.ScheduleActivity .title {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 13px 28px;
  border-bottom: 1px solid #f0f0f1;
}
.ScheduleActivity .Select-control {
  margin-bottom: 0;
}
.ScheduleActivity .content .InfiniteScroll{
  max-height: 55vh;
}
.ScheduleActivity .Select-control .active-dropdown {
  width: 133px;
  height: 40px;
  transform: rotate(-360deg);
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-left: 15px;
  line-height: 41px;
  padding-right: 30px;
  padding-top: 1px;
  color: #44444f;
}
.ScheduleActivity .Select-control .arrow {
  position: absolute;
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 17px;
  right: 15px;
  z-index: 1;
}
.ScheduleActivity .content {
  display: flex;
  height: calc(100% - 74px);
  padding: 30px 10px;
}
.ScheduleActivity .content .acitive-bg-content .icon IMG {
  width: 74px;
}
.ScheduleActivity .content .acitive-bg-content .icon {
  margin-bottom: 34px;
  text-align: center;
}
.ScheduleActivity .content .acitive-bg-content {
  margin: auto;
  margin-top: 109px;
}
.ScheduleActivity .content .acitive-bg-content SPAN {
  width: 206px;
  height: 40px;
  display: inline-block;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #72787d;
}
.ScheduleActivity .activity-list {
  width: 100%;
}

@media (max-width: 1400px) {
  .ScheduleActivity {
    min-width: 225px;
    width: 225px;
  }
  .ScheduleActivity .Select-control .active-dropdown {
    font-size: 16px;
    width: 115px;
  }
}

@media (max-width: 1200px) {
  .ScheduleActivity {
    width: 100%;
    margin-bottom: 20px;
  }
}

.CalendarAppointmentEvent.rbc-event {
  background-color: #e8f5f7;
  border: solid 1px #199daf;
  border-left: 8px solid #199daf;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: visible;
  padding-top: 4px;
  z-index: 1;
}
.CalendarAppointmentEvent.rbc-event.confirmed {
  z-index: 3;
}
.CalendarAppointmentEvent.rbc-event.pending {
  border: solid 1px #f2962d;
  border-left: 8px solid #f2962d;
  background-color: #fff9f2;
  z-index: 2;
}
.CalendarAppointmentEvent.rbc-event.pending .rbc-event-content {
  position: relative;
}
.CalendarAppointmentEvent.rbc-event.pending .rbc-event-content .overlay {
  position: absolute;
  left: -6px;
  right: 0;
  top: -6px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}
.CalendarAppointmentEvent.rbc-event.pending .rbc-event-content .overlay > span {
  width: 8px;
  background-color: rgba(242, 150, 45, 0.15);
}
.CalendarAppointmentEvent .main-event {
  font-family: 'Calibre Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 0.88;
  letter-spacing: 0.11px;
  color: #013648;
  /* padding-top: 9px; */
  height: 100%;
  overflow: hidden;
}
.CalendarAppointmentEvent .main-event .icon {
  margin-bottom: 5px;
  display: inline-block;
  right: 7px;
  width: 16px;
  position: absolute;
}
.CalendarAppointmentEvent .main-event .icon img {
  width: 100%;
}
.CalendarAppointmentEvent .main-event .time {
  line-height: 1;
}

.CalendarHolidayEvent {
  text-align: center;
  /* padding: 5px; */
  /* padding-top: 7px; */
  /* background-color: rgb(121, 134, 203); */
  /* color: #ffffff; */
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
  display: table;
  width: 100%;
  
}
.CalendarHolidayEvent span{
  display: table-cell;
    vertical-align: middle;
    background-color: rgb(121, 134, 203);
    color: #ffffff;
    height: 100%;
    padding: 8px 5px;
    vertical-align: middle;
}
.AfterAuthSchedule {
  padding-bottom: 70px;
  width: 100%;
}
.AfterAuthSchedule .AfterAuthSchedule-innercontent {
  display: flex;
  padding-left: 12px;
  padding-right: 20px;
}
.AfterAuthSchedule .AfterAuthSchedule-inner {
  text-align: center;
  min-width: 1200px;
}
.AfterAuthSchedule .AfterAuthSchedule-innercontent .calender-box {
  width: 100%;
  margin-left: 12px;
}
.AfterAuthSchedule .AfterAuthSchedule-innercontent .activity-box {
  width: 350px;
}
.AfterAuthSchedule .box-content {
  border-radius: 20px;
  background-color: #ffffff;
}
.AfterAuthSchedule .all-day-text {
  margin: 0;
  display: block;
  width: 100%;
  text-align: center;
  background: rgba(252, 90, 90, 0.08);
  border-top: 1px solid #ddd;
  font-size: 12px;
  line-height: normal;
  padding: 5px 0;
  letter-spacing: 0.1px;
  color: rgba(243, 113, 132, 0.44);
  height: 25px;
}

.AfterAuthSchedule .rbc-header A {
  display: inline-block;
  width: 100%;
  cursor: initial;
}
.AfterAuthSchedule .rbc-header.rbc-today .CalendarDayHeader {
  color: #fff;
}
.AfterAuthSchedule .rbc-header.rbc-today {
  background-color: #013648;
}
.AfterAuthSchedule .rbc-header {
  min-height: 56px;
  background-color: #fcfcfc;
}
.AfterAuthSchedule .rbc-time-header-gutter {
  width: 82px;
  display: flex;
  align-items: flex-end;
  padding: 0px;
  background-color: #fcfcfc;
}
.AfterAuthSchedule .rbc-time-header {
  min-width: 700px;
}
.AfterAuthSchedule .rbc-time-view .rbc-row {
  min-height: 56px;
}
.AfterAuthSchedule .rbc-allday-cell {
  max-height: 24px;
  background-color: rgba(252, 90, 90, 0.08);
}
.rbc-time-header-content .rbc-allday-cell {
  max-height: 60px;
  background-color: rgba(252, 90, 90, 0.08);
}

.AfterAuthSchedule .rbc-allday-cell .rbc-row-bg {
  max-height: 24px;
}
.AfterAuthSchedule .rbc-allday-cell .rbc-row-content .rbc-row {
  max-height: 12px;
  min-height: 12px;
}
.AfterAuthSchedule .rbc-allday-cell .rbc-row-content {
  max-height: 24px;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row-content {
  max-height: 60px;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row-content .rbc-row {
  max-height: 24px;
  min-height: 24px;
}
.rbc-time-header-content .rbc-allday-cell .rbc-row-bg {
  max-height: 60px;
}
.AfterAuthSchedule
  .rbc-time-content
  .rbc-time-column.rbc-time-gutter
  .rbc-timeslot-group
  .rbc-label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  text-transform: lowercase;
  padding-left: 15px;
}
.AfterAuthSchedule .rbc-time-content {
  border-top: 1px solid #ddd;
  max-height: calc(100vh - 332px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  min-width: 678px;
}
.AfterAuthSchedule .rbc-time-view {
  border-left: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AfterAuthSchedule .rbc-time-content .rbc-time-column .rbc-timeslot-group {
  height: 44px;
  border-left: solid 1px #f6f6f6;
}
.AfterAuthSchedule .rbc-time-content .rbc-time-column.rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 0;
}
.AfterAuthSchedule .rbc-time-content > .rbc-day-slot .rbc-time-slot {
  border-top: solid 1px #f6f6f6;
}
.AfterAuthSchedule .rbc-time-content > .rbc-time-gutter {
  width: 82px;
}
.AfterAuthSchedule .rbc-day-slot .rbc-events-container {
  margin-right: unset;
}
.AfterAuthSchedule .rbc-current-time-indicator {
  height: 4px;
  border-style: solid;
  border-color: #6565b5;
  background-color: unset;
  z-index: 0;
}
.AfterAuthSchedule .monthly-activity-content {
  display: block !important;
  z-index: 9;
  bottom: 34px;
  right: 25px;
  position: absolute;
}
.AfterAuthSchedule .AppointmentDetail {
  box-shadow: -6px 7px 14px 0 rgba(55, 70, 95, 0.07);
}
.AfterAuthSchedule .monthly-activity-content .overlay {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .DateTime {
  font-size: 20px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .PatientDetails {
  padding: 17px 20px 18px 25px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .PatientDetails .Patient-name span {
  font-size: 16px;
}
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-address {
  padding: 18px 0 3px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-information {
  min-width: 240px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .link-span {
  font-size: 14px !important;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status {
  padding-left: 22px;
}
.AfterAuthSchedule
  .monthly-activity-content
  .AppointmentDetail
  .Patient-status
  .doctor-item
  .doctor-info
  .doctor-name {
  font-size: 16px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status .doctor-item > IMG {
  height: 37px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-location {
  padding: 25px 0;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-location IMG {
  width: 11px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-Appointment,
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-location {
  padding-left: 42px;
  font-size: 14px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .link-span {
  font-size: 16px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status-control {
  margin-top: 18px;
}
.AfterAuthSchedule
  .monthly-activity-content
  .AppointmentDetail
  .Patient-status-control
  .Select-control.form-group {
  margin-bottom: 0px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms .h3 {
  font-size: 18px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Questionnaire SPAN.h3 {
  font-size: 16px;
  font-family: 'Calibre Medium';
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Questionnaire SPAN {
  font-size: 14px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms .link-span {
  font-size: 14px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Questionnaire {
  margin-bottom: 17px;
}
.AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms {
  min-width: 179px;
  max-width: 179px;
}
.AfterAuthSchedule .rbc-time-header {
  min-width: 670px;
}
.AfterAuthSchedule .AppointmentDetail .Patient-status-control .Select-control.form-group select {
  width: 100%;
  line-height: 38px;
  font-size: 18px;
  padding-top: 0;
}
.AfterAuthSchedule .monthly-activity-content {
  width: 695px;
}
.AfterAuthSchedule
  .AppointmentDetail
  .PatientDetails-content
  .Patient-information
  .Patient-address
  SPAN,
.AfterAuthSchedule
  .AppointmentDetail
  .PatientDetails-content
  .Patient-information
  .Patient-data
  SPAN {
  font-size: 14.4px;
}
.AuthSchedulegoogleparent {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 0 0;
}
@media (hover: hover) {
  .InviteYourProviders .signup-bottom SPAN:hover {
    color: #013648;
  }
  .AfterAuthSchedule .rbc-time-content .rbc-day-slot:last-child .rbc-event .hover-content,
  .AfterAuthSchedule .rbc-time-content .rbc-day-slot:nth-last-child(2) .rbc-event .hover-content {
    left: -198px;
  }
}

@media (max-width: 1200px) {
  .AfterAuthSchedule .AfterAuthSchedule-innercontent {
    flex-wrap: wrap;
  }
  .AfterAuthSchedule .AfterAuthSchedule-innercontent .calender-box {
    margin-left: 0;
  }
  .AfterAuthSchedule .rbc-calendar {
    display: inline-grid;
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .AfterAuthSchedule .rTable {
    width: 1140px;
  }
  .AfterAuthSchedule .table-wrapper {
    overflow-x: auto;
  }
  .AfterAuthSchedule .AppointmentDetail .PatientDetails-content {
    flex-wrap: nowrap;
  }
}

@media (max-width: 767px) {
  .AfterAuthSchedule {
    padding: 0 0 50px;
  }
  .AfterAuthSchedule .heading2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .AfterAuthSchedule .rbc-calendar {
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .AfterAuthSchedule .rbc-time-view {
    overflow: unset;
  }
  .AfterAuthSchedule .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
  .AfterAuthSchedule .monthly-activity-content {
    width: auto;
    left: 30px;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-symptoms {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .PatientDetails {
    padding-left: 15px;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-information {
    min-width: auto;
  }
  @supports (-webkit-touch-callout: none) {
    .CalendarToolbar .arrow-btn,
    .CalendarToolbar .cal-btn {
      padding: 0 6px;
    }
  }
}
@media (max-width: 575px) {
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-information {
    width: 100%;
    padding-right: 0;
    border-right: 0;
  }
  .AfterAuthSchedule .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .AfterAuthSchedule .monthly-activity-content .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 15px;
  }
}

.ReviewSourceList {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ReviewSourceList .date-cell {
  width: 20%;
}
.ReviewSourceList .staus-cell {
  width: 5%;
  text-align: center;
}
.ReviewSourceList .access-link-cell {
  width: 52%;
  padding-left: 15px;
}
.ReviewSourceList .action-cell {
  width: 2%;
}
.ReviewSourceList .Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.ReviewSourceList .Rtable-row {
  width: 100%;
  display: flex;
}
.ReviewSourceList .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ReviewSourceList .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ReviewSourceList .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ReviewSourceList .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ReviewSourceList .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ReviewSourceList .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ReviewSourceList .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ReviewSourceList .Rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ReviewSourceList .Rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ReviewSourceList .action-menu {
  margin-left: auto;
  position: relative;
}
.ReviewSourceList .action-menu .action-btn {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}
.ReviewSourceList .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.ReviewSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.ReviewSourceList .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ReviewSourceList .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.ReviewSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.GoogleCalendar {
  text-align: center;
  padding: 5px;
}
.GoogleCalendar .logo img {
  width: 30px;
  margin-bottom: 5px;
}

.GoogleCalendar .name {
  margin-bottom: 20px;
}
.GoogleCalendar BUTTON {
  background-color: #26acda;
  font-size: 14px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 7px 14px 3px;
  border-radius: 2px;
}

.GoogleCalendar .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: -3px;
  right: -4px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.GoogleCalendar .info-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.GoogleCalendar .info-wrapper img {
  position: absolute;
  top: -11px;
  width: 14px;
}
.GoogleCalendar .info-wrapper:hover .info-content {
  display: block;
}
@media (max-width: 767px) {
  .ReviewSourceList .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}

.ApprovalCard {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 11px 25px 18px;
  border: solid 1px #e2e2ea;
  display: inline-block;
  position: absolute;
  right: 40px;
  top: -106px;
  width: 390px;
}
.ApprovalCard .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.ApprovalCard .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.ApprovalCard .approval-action {
  display: block;
  margin-top: 10px;
}
.ApprovalCard .approval-action SPAN IMG {
  width: 20px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}
.ApprovalCard .approval-action SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  margin-right: 14px;
  width: 29%;
}
.ApprovalCard .approval-action SPAN:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .ApprovalCard .approval-action SPAN {
    width: auto;
    margin-right: 15px;
  }
}

.ReviewSourceList {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ReviewSourceList .date-cell {
  width: 20%;
}
.ReviewSourceList .staus-cell {
  width: 5%;
  text-align: center;
}
.ReviewSourceList .access-link-cell {
  width: 52%;
  padding-left: 15px;
}
.ReviewSourceList .action-cell {
  width: 2%;
}
.ReviewSourceList .Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.ReviewSourceList .Rtable-row {
  width: 100%;
  display: flex;
}
.ReviewSourceList .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ReviewSourceList .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ReviewSourceList .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ReviewSourceList .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ReviewSourceList .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ReviewSourceList .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ReviewSourceList .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ReviewSourceList .Rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ReviewSourceList .Rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ReviewSourceList .action-menu {
  margin-left: auto;
  position: relative;
}
.ReviewSourceList .action-menu .action-btn {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}
.ReviewSourceList .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.ReviewSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.ReviewSourceList .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ReviewSourceList .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.ReviewSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}

@media (max-width: 767px) {
  .ReviewSourceList .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}

.ConnectReviews .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.ConnectReviews .addwebsite-btn {
  width: 149px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
}
.ConnectReviews .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ConnectReviews .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.ConnectReviews .no-review-text {
  text-align: center;
  width: 500px;
  margin: auto;
  max-width: 100%;
  margin-top: 66px;
}
.ConnectReviews .no-review-text .Reviewtitle {
  font-size: 20px;
  font-family: 'Calibre Medium';
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: #013648;
}
.ConnectReviews .no-review-text .review-logo {
  margin-top: 19px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ConnectReviews .no-review-text .review-logo > SPAN{
  display: inline-flex;
  padding: 0 10px;
  align-items: center;  
}
.ConnectReviews .no-review-text .desciption {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
}
.ConnectReviews .no-review-text .linkspan {
  font-size: 18px;
  line-height: 1.78;
  color: #26acda;
  display: inline-block;
  margin-top: 19px;
  cursor: pointer;
}
.ConnectReviews .add-website-form {
  padding-right: 138px;
  margin-top: 36px;
}
.ConnectReviews .add-website-example {
  font-size: 16px;
  line-height: 1.38;
  color: #b2bcc4;
  word-break: break-all;
  margin-bottom: 15px;
}
.ConnectReviews .add-website-form .Select-control {
  margin-bottom: 39px;
  width: 420px;
  max-width: 100%;
}
.ConnectReviews .add-website-form .add-site-actions {
  text-align: right;
}
.ConnectReviews .add-website-form .add-site-actions .CircleButton {
  margin-left: 26px;
  width: 111px;
}
.ConnectReviews .note {
  color: rgba(1, 54, 72, 0.8);
  font-size: 14px;
  margin-bottom: 10px;
}
.ConnectReviews .add-website-form .add-site-actions .CircleButton.cancel-btn {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #72787d;
}

@media (max-width: 1024px) {
  .ConnectReviews .title {
    font-size: 20px;
  }
  .ConnectReviews .refresh-btn {
    margin-right: 15px;
  }
  .ConnectReviews .addwebsite-btn {
    width: 100px;
  }
  .ConnectReviews .add-website-form {
    padding-right: 0;
  }
  .ConnectReviews .add-website-form .Select-control {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ConnectReviews .heading {
    flex-wrap: wrap;
  }
  .ConnectReviews .heading .title {
    width: 100%;
  }
  .ConnectReviews .heading > SPAN {
    margin-left: auto;
    margin-top: 15px;
  }
  .ConnectReviews .add-website-form .add-site-actions .CircleButton {
    margin-left: 15px;
  }
}

.ConnectSocial .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.ConnectSocial .refresh-btn {
  font-size: 18px;
  line-height: 1.78;
  color: #26acda;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ConnectSocial .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.ConnectSocial .list-item-inner-content {
  background-color: #f7f9f9;
  padding: 30px 15px;
  text-align: center;
  height: 100%;
}
.ConnectSocial .social-ac-info-text SPAN {
  display: inline-block;
  width: 100%;
}
.ConnectSocial .social-ac-info-text {
  display: inline-block;
  margin: 29px 0 36px;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #72787d;
}
.ConnectSocial .social-ac-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.ConnectSocial .social-ac-list-item {
  width: 25%;
  padding: 0 15px;
  margin-bottom: 20px;
}
.ConnectSocial .social-ac-list-item .logo {
  margin: 20px 0;
}
.ConnectSocial .social-ac-list-item .logo IMG {
  width: 42px;
}
.ConnectSocial .social-ac-list-item .name {
  font-family: 'Calibre Medium';
  color: #013648;
  margin: 15px 0 21px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: #013648;
}
.ConnectSocial .social-ac-list-item .status {
  font-size: 14px;
  color: #26acda;
  margin-bottom: 28px;
  font-size: 16px;
  letter-spacing: -0.2px;
}
.ConnectSocial .social-ac-list-item .status.not-connected {
  color: #e85d72;
}
.ConnectSocial .social-ac-list-item BUTTON {
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 7px 25px 3px;
  border-radius: 2px;
}
.ConnectSocial .social-ac-list-item BUTTON.disconnect {
  background-color: #e7ebeb;
  color: #868b90;
}
.ConnectSocial .send-review-request {
  border-radius: 2px;
  background-color: #26acda;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  padding: 5px 15px 5px;
  cursor: pointer;
  outline: 0;
  margin-right: 15px;
}
.mb-1 {
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .ConnectSocial .social-ac-list-item {
    width: 33%;
  }
}
@media (max-width: 1024px) {
  .ConnectSocial .social-ac-list-item .name {
    font-size: 14px;
  }
  .ConnectSocial .social-ac-list-item button {
    padding: 8px 10px 2px;
  }
  .ConnectSocial .social-ac-list-item {
    padding: 0 8px;
  }
  .ConnectSocial .social-ac-list {
    margin: 0 -8px;
  }
}
@media (max-width: 767px) {
  .ConnectSocial .social-ac-list-item {
    width: 50%;
  }
  .ConnectSocial .title {
    font-size: 20px;
  }
  .ConnectSocial .refresh-btn {
    font-size: 14px;
  }
  .ConnectSocial .heading {
    flex-wrap: wrap;
  }
  .ConnectSocial .heading SPAN {
    width: 100%;
    text-align: right;
    margin-top: 5px;
  }
}
@media (max-width: 414px) {
  .ConnectSocial .social-ac-list-item {
    width: 100%;
  }
  .ConnectSocial .social-ac-list {
    margin: 0 -8px;
  }
  .ConnectSocial .refresh-btn {
    font-size: 13px;
  }
}

.ManageLocation {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
}
.ManageLocation .dailog-header {
  padding: 22px 31px 5px;
  /* border-bottom: solid 1px #e9ebf2; */
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.ManageLocation .form-row{
  display: flex;
grid-column-gap: 10px;
column-gap: 10px;
}
.ManageLocation .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.ManageLocation .cross-icon IMG {
  width: 100%;
}
.ManageLocation .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.ManageLocation .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.ManageLocation .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.ManageLocation .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.ManageLocation .dailog-body {
  padding: 20px 32px 15px;
}
.ManageLocation .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.ManageLocation .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.ManageLocation .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.ManageLocation .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.ManageLocation .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.ManageLocation .action-btn {
  text-align: right;
}
.ManageLocation .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.ManageLocation .each-location{
  display: flex;
  margin-bottom: 10px;
border-bottom: 1px solid #9999993d;
padding-bottom: 12px;
}
.ManageLocation .each-location:first-child{
  font-size: 18px;
}
.ManageLocation .each-location .each-item{
 flex:0 0 auto;
 width: 80px;
}
.ManageLocation .each-location .item-name{
  width: 230px;
}
.ReviewAccounts {
  width: 100%;
}
.ReviewAccounts .main-section-content {
  padding: 0 20px 0 40px;
}
.ReviewAccounts .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.ReviewAccounts .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
}
.ReviewAccounts .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewAccounts .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
}
.ReviewAccounts .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.ReviewAccounts .ReviewAccounts-content {
  padding: 0 40px;
}
.ReviewAccounts .box-panel {
  padding: 40px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  margin-top: 31px;
  margin-bottom: 30px;
}
.ReviewAccounts .tab-content {
  position: relative;
}
.ReviewAccounts .AfterAuthSubHeader .main-inner-content {
  padding-right: 460px;
}
.ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
  min-width: 160px;
}
.ReviewAccounts .ReviewAccounts-content {
  position: relative;
}
@media (max-width: 1400px) {
  .ReviewAccounts .box-panle {
    padding: 40px 20px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    right: 20px;
  }
  .ReviewAccounts .box-panle .search-review-list .Input-control {
    width: 230px;
  }
}

@media (max-width: 1200px) {
  .ReviewAccounts .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    top: 36px;
  }
  .ReviewAccounts .main-section-content {
    padding: 0 20px 0 20px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
    min-width: 134px;
  }
  .ReviewAccounts .ApprovalCard {
    padding: 11px 10px 18px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span {
    margin-right: 15px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span:last-child {
    margin-right: 0;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-right: 440px;
  }
}

@media (max-width: 1024px) {
  .ReviewAccounts .main-section-content {
    padding: 0 15px 0;
  }
  .ReviewAccounts .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
  .ReviewAccounts .ReviewAccounts-content {
    padding: 0px 15px;
  }
  .ReviewAccounts .box-panel {
    padding: 15px;
  }
  .ReviewAccounts {
    width: calc(100% - 200px);
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
    padding-right: 420px;
  }
  .ReviewAccounts .ApprovalCard {
    top: -150px;
    right: 15px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .ReviewAccounts .box-panle {
    padding: 20px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    margin-top: 10px;
  }
  .ReviewAccounts {
    width: 100%;
  }
  .ReviewAccounts .setting-tabs .tab-list-item {
    margin-right: 20px;
    font-size: 18px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .ReviewAccounts .ApprovalCard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span {
    margin-right: 10px;
  }
}

.NotConnectMsg{
width: 100%;
}
.NotConnectMsg p{
  text-align: center;
  padding-top: 20%;
  font-size: 20px;
  }
.RequestSettings {
  margin-top: 15px;
}
.RequestSettings .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.RequestSettings .addwebsite-btn {
  width: 111px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestSettings .cancel-btn {
  background-color: #f1f1f1;
  color: #72787d;
}
.RequestSettings .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.RequestSettings .label-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
  font-family: 'Calibre Medium';
  margin-bottom: 15px;
  position: relative;
}
.RequestSettings .review-request .label-text IMG {
  position: absolute;
  left: -49px;
  top: -4px;
}
.RequestSettings .request-setting-inner {
  padding-top: 4px;
}
.RequestSettings .select-content {
  width: 333px;
  max-width: 100%;
}
.RequestSettings .select-content SELECT {
  height: 40px;
}
.RequestSettings .select-content .arrow {
  top: 18px;
  border-top-color: #92929d;
  border-width: 7px;
}
.RequestSettings .select-content.label-select .arrow {
  top: 42px;
}
.RequestSettings .review-request {
  border-bottom: solid 1px #e2e2ea;
  padding-bottom: 12px;
  padding-top: 30px;
}
.RequestSettings .review-request .preview-btn {
  font-family: 'Calibre Regular';
  width: 103px;
  height: 40px;
  padding: 10px 25px 8px 22px;
  border-radius: 4px;
  border: solid 1px #e2e2ea;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.13px;
  color: #44444f;
  margin: 0 16px;
  cursor: pointer;
}
.RequestSettings .review-request-content {
  display: flex;
  align-items: center;
}
.RequestSettings .frequently {
  padding-top: 30px;
}
.RequestSettings .review-request-content .label-select .label {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  margin-bottom: 8px;
  display: inline-block;
}
.RequestSettings .review-request-content .edit-url {
  font-size: 18px;
  font-family: 'Calibre Medium';
  line-height: 1.78;
  color: #26acda;
}
.RequestSettings .switch-content .label-text {
  font-family: 'Calibre Regular';
  color: #72787d;
  margin-bottom: 30px;
}
.RequestSettings .sent-out {
  margin-top: 44px;
}
.RequestSettings .important-info .description {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.15px;
  color: #013648;
  padding-bottom: 23px;
}
.RequestSettings .UILabel {
  position: fixed;
  right: 209px;
  top: 5px;
  margin-right: 30px;
}
.RequestSettings .review-request .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  padding-bottom: 23px;
}
.RequestReviews .review-request .sub-text A {
  color: #26acda;
}
.RequestReviews .max-email {
  height: 33px;
  background-color: #f1f1f1;
  color: #72787d;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
}
.RequestSettings .review-sources {
  padding: 27px;
  background-color: #f8f8f8;
  border-radius: 2px;
  width: 855px;
  max-width: 100%;
}
.RequestSettings .review-sources .heading-title {
  font-size: 21px;
  line-height: 0.83;
  letter-spacing: -0.3px;
  color: #72787d;
  margin-bottom: 23px;
}
.RequestSettings .review-sources .list-review-source {
  display: flex;
  flex-wrap: wrap;
}
.RequestSettings .review-sources .list-review-source > DIV {
  width: 23%;
  margin-bottom: 26px;
}
.RequestSettings .list-review-source > DIV .checkmark {
  border: solid 1px #26acda;
}
@media (max-width: 1400px) {
  .RequestSettings .review-request-content .edit-url {
    width: 120px;
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .RequestSettings .title {
    font-size: 20px;
  }
  .RequestSettings .review-sources .list-review-source > DIV {
    width: 31%;
  }
}
@media (max-width: 767px) {
  .RequestSettings .review-request-content {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .RequestSettings .select-content {
    width: 100%;
    margin-bottom: 15px;
  }
  .RequestSettings .review-request .preview-btn {
    margin-left: auto;
  }
  .RequestSettings .review-request-content .edit-url {
    width: auto;
  }
  .RequestSettings .heading {
    flex-wrap: wrap;
  }
  .RequestSettings .title {
    width: 100%;
  }
  .RequestSettings .right-panel {
    width: 100%;
    text-align: right;
  }
  .RequestSettings .review-request {
    padding-left: 25px;
  }
  .RequestSettings .review-request .label-text IMG {
    left: -30px;
  }
}
@media (max-width: 414px) {
  .RequestSettings .review-sources {
    padding: 15px 10px;
  }
  .RequestSettings .review-sources .list-review-source > DIV {
    width: 46%;
    margin-bottom: 15px;
  }
  .RequestSettings .review-sources .heading-title {
    font-size: 20px;
  }
}

.ContactSourceList {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ContactSourceList .name-cell {
  min-width: 25%;
}
.ContactSourceList .tempalte-cell {
  min-width: 15%;
  text-align: left;
  padding-left: 10px;
}
.ContactSourceList .type-cell {
  min-width: 20%;
  padding-left: 10px;
}

.ContactSourceList .dropdown-menu .close-btn{
  display: block !important;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0 !important;
    width: auto !important;
}
.ContactSourceList .usage-cell {
  min-width: 15%;
  padding-left: 10px;
}
.ContactSourceList .action-cell {
  min-width: 20%;
  padding-left: 10px;
}
.ContactSourceList .rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 30px;
}
.ContactSourceList .rtable-row {
  width: 100%;
  display: flex;
}
.ContactSourceList .rtable-row .rtable-cell.content-cell.name-cell {
  color: #26acda;
}
.ContactSourceList .rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ContactSourceList .rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ContactSourceList .rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ContactSourceList .rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ContactSourceList .rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ContactSourceList .rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ContactSourceList .rtable-row {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ContactSourceList .rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ContactSourceList .rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ContactSourceList .action-menu {
  margin-left: auto;
  position: relative;
}
.ContactSourceList .action-menu .action-btn {
  display: inline-block;
  width: 40px;
  cursor: pointer;
}
.ContactSourceList .action-menu .action-btn IMG {
  width: 16px;
}
.ContactSourceList .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.ContactSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
  right: 28px;
  top: -36px;
}
.ContactSourceList .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ContactSourceList .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.ContactSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.ContactSourceList .send-btn {
  max-width: 90px;
  height: 40px;
  padding: 14px 18px 10px 13px;
  border-radius: 2px;
  background-color: #013648;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  margin-left: 40px;
  font-family: 'Calibre Regular';
  cursor: pointer;
}
.ContactSourceList .send-btn IMG {
  margin-right: 3px;
}
.ContactSourceList .send-btn:focus {
  outline: 0;
}
.copy-span{
  display: inline-block;
  margin-bottom: 5px;
}
.ContactSourceList .nocontact{
  text-align: center;
  width: 100%;
  font-size: 20px;
  color: #333333a1;
}
.ContactSourceList img.copy-img{
  width: 20px;
    cursor: pointer;
    margin-left: 10px;
    top: 5px;
    display: inline-block;
    position: relative;
}
@media (max-width: 991px) {
  .ContactSourceList .tempalte-cell {
    min-width: 20%;
  }
  .ContactSourceList .usage-cell {
    min-width: 21%;
  }
}
@media (max-width: 767px) {
  .ContactSourceList .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}

.ContactSourceHistoryView {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
  position: relative;
}
.ContactSourceHistoryView .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.ContactSourceHistoryView .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ContactSourceHistoryView .modal-content-header .close IMG {
  width: 100%;
}
.ContactSourceHistoryView .list-history {
  padding: 33px;
}
ul.eachHistory {
  list-style: none;
  padding: 0;
}
li.eachHistory {
  display: grid;
    grid-template-columns: 20% 40% 40%;
  padding: 5px 10px;
}
li.eachHistory:nth-child(odd) {
  background-color: #eaeaea;
}
li.eachHistory:nth-child(even) {
  background-color: #f9f9f9;
}
li.eachHistory img {
  width: 20px;
  margin-right: 15px;
}
li.eachHistory div{
  flex: 0 0 1;
}
li.eachHistory p {
  margin-bottom: 5px;
}
.ContactSourceHistoryView .modal-content-body {
  overflow: auto;
  min-height: 350px;
}
.ContactSourceHistoryView .modal-content-body .rtable {
  overflow: auto;
  max-height: 350px;
}
.ContactSourceHistoryView .heading3 {
  font-size: 20px;
  text-transform: capitalize;
  color: #fff;
}
.mt-10 {
  margin-top: 10px;
}
.viewHistory {
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr;
}
.viewHistory .small-label {
  font-size: 17px !important;
  text-transform: capitalize;
}
.viewHistory .select-date-content {
  margin: 0;
  z-index: 5;
}
.viewHistory .select-date-content.end-date{
  z-index: 4;
}
.viewHistory .select-date-content .Datepicker-content {
  position: absolute;
  top: 50px;
}
.viewHistory .select-date-content.end-date .Datepicker-content {
  right: 67%;
}
.viewHistory  .MyDatetime .rdtPicker{
width: 300px;
padding-top: 40px;
}
.viewHistory .date-box{
  border: 1px solid #3333337d;
    border-radius: 5px;
    padding: 9px 28px;
    padding-bottom: 5px;
    padding-left: 10px;
}
.viewHistory .date-box img{
  position: relative;
  top: 7px;
  right: -12px;
}
.viewHistory .MyDatetime .rdtPicker .rdtDays table{
  border-spacing: 20px 0px;
}
.viewHistory .MyDatetime.days .rdtPicker .rdtSwitch{
  top: 17px;
  left: 20px;
}
.viewHistory .MyDatetime.days .rdtPicker th.rdtNext, 
.viewHistory .MyDatetime.days .rdtPicker th.rdtPrev{
  top: 10px;
}
.viewHistory .MyDatetime.days .rdtPicker .rdtSwitch:after{
  width: 38px;
  top: 4px;
}
.viewHistory .MyDatetime.days .rdtPicker td{
  line-height: 25px;
}
@media screen and (max-width: 850px) {
  .ContactSourceHistoryView {
    width: 750px;
  }
}
@media screen and (max-width: 766px) {
  .ContactSourceHistoryView {
    width: 95%;
  }
}
@media screen and (max-width: 767px) {
  .viewHistory{
    overflow: initial;
   
  }
  .viewHistory .select-date-content.end-date .Datepicker-content {
   left: -33%;
}
}
@media screen and (max-width: 766px) {
  .select-date-content.end-date {
   position: relative !important;
  }
}
@media screen and (max-width: 600px) {
  .viewHistory{
    display: block;
   
  }
  .viewHistory .select-date-content.end-date .Datepicker-content {
    left: 10%;
  }
}

  @media screen and (max-width: 420px) {
  .viewHistory .MyDatetime .rdtPicker {
width: 220px;
}
.viewHistory .MyDatetime .rdtPicker .rdtDays table {
  border-spacing: 10px 0px;
}
.rdtPicker td, .rdtPicker th{
  height: 27px;
}
.MyDatetime.days .rdtPicker .rdtSwitch{
  font-size: 15px;
}

.viewHistory .MyDatetime.days .rdtPicker .rdtSwitch:after{
display: none;
}
}
.RequestContact .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.RequestContact .addwebsite-btn {
  width: 124px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestContact .send-btn {
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #013648;
}
.RequestContact .send-btn IMG {
  position: relative;
  top: -3px;
  left: -7px;
}
.RequestContact .Select-control .arrow {
  top: 17px;
  right: 13px;
  border-width: 7px;
  border-top-color: #92929d;
}
.RequestContact .Select-control {
  margin-bottom: 0;
  height: 40px;
}
.RequestContact .Select-control SELECT {
  height: 40px;
}
.RequestContact .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.RequestContact .right-panel {
  display: flex;
}
.RequestContact .UILabel {
  position: fixed;
  right: 209px;
  top: 5px;
  margin-right: 30px;
}
.RequestContact .search-contact {
  margin-right: 14px;
  width: 300px;
  margin-bottom: 0;
}
.RequestContact .search-contact INPUT {
  height: 40px;
  padding-left: 37px;
  background-color: #fafafb;
  border-color: #fafafb;
}
.RequestContact .search-contact .view-img {
  left: 11px;
  top: 20px;
  width: 21px;
  height: 15px;
}
.RequestContact .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.RequestContact .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.RequestContact .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.RequestContact .pagination LI.active {
  background-color: #013648;
  color: white;
}
.RequestContact .pagination LI.previous.disabled,
.RequestContact .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.RequestContact .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
@media (max-width: 1200px) {
  .RequestContact .heading {
    flex-wrap: wrap;
  }
  .RequestContact .right-panel {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 14px;
  }
}
@media (max-width: 1024px) {
  .RequestContact .title {
    font-size: 20px;
  }
  .RequestContact .refresh-btn {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .RequestContact .heading {
    flex-wrap: wrap;
  }
  .RequestContact .heading .title {
    width: 100%;
  }
  .RequestContact .right-panel {
    flex-wrap: wrap;
  }
  .RequestContact .Select-control {
    margin-bottom: 0;
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
  }
  .RequestContact .search-contact {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
}
@media (max-width: 414px) {
  .RequestContact .send-btn,
  .RequestContact .addwebsite-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.SendReviewRequest {
  background-color: white;
  height: auto;
overflow: scroll;
  max-height: 80vh;
  width: 100%;
  padding: 0px;
}
.SendReviewRequest .dailog-header {
  padding: 22px 31px 5px;
  /* border-bottom: solid 1px #e9ebf2; */
  display: flex;
  justify-content: space-between;
  background-color: #013648;
  position: relative;
}
.SendReviewRequest .form-row{
  display: flex;
grid-column-gap: 10px;
column-gap: 10px;
}
.SendReviewRequest .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.SendReviewRequest .cross-icon IMG {
  width: 100%;
}
.SendReviewRequest .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.SendReviewRequest .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.SendReviewRequest .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.SendReviewRequest .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.SendReviewRequest .dailog-body {
  padding: 20px 32px 15px;
}
.SendReviewRequest .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
/* .SendReviewRequest .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.SendReviewRequest .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.SendReviewRequest .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
} */
.SendReviewRequest .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.SendReviewRequest .action-btn {
  text-align: right;
}
.SendReviewRequest .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}

.dailog-body .SearchPatients .active .Input-control.form-group {
  z-index: 1;
}
.dailog-body .SearchPatients .Input-control.form-group input {
  padding-left: 60px;
  height: 55px;
}
.dailog-body .SearchPatients .Input-control.form-group input:focus {
  border: solid 1.5px #013648;
}
.dailog-body .SearchPatients .Input-control .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
  top: calc(100% - 2rem);
}
.dailog-body .SearchPatients .search {
  position: relative;
}
.dailog-body .SearchPatients .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.dailog-body .SearchPatients .search .search-result {
  padding: 12px 0px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  position: absolute;
  min-width: 300px;
  width: 100%;
  overflow-x: auto;
  top: 77px;
  z-index: 99;
  display: none;
  max-height: 400px;
}
.dailog-body .SearchPatients .search.active .search-result {
  display: block;
}
.dailog-body .SearchPatients .search-result .search-category {
  margin-bottom: 20px;
}
.dailog-body .SearchPatients .search-result .search-category:last-child {
  margin-bottom: 0;
}
.dailog-body .SearchPatients .search-result .search-category .h3 {
  opacity: 0.5;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #18222a;
  padding: 0px 18px;
}
.dailog-body .SearchPatients .search-result .search-category span {
  display: inline-block;
  width: 100%;
}
.dailog-body .SearchPatients .search-result .search-category .search-item {
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #013648;
  padding: 0px 18px;
  cursor: pointer;
}
.dailog-body .SearchPatients .search-result .search-category .search-item p{
  margin: 0;
  display: flex;
}
.dailog-body .SearchPatients .search-result .search-category .search-item p.email-ptag{
 font-size: 13px;
 font-style: italic;
 color: #999;
}
.dailog-body .SearchPatients .search-result .search-category .search-item p span{
margin-right: 3px;
width: auto;
height: 25px;
}
.dailog-body .SearchPatients .search-result .search-category .doctor-item b,
.dailog-body .SearchPatients .search-result .search-category .search-item b {
  color: #46a6c7;
  font-weight: 500;
}
.dailog-body .SearchPatients .doctor-item {
  padding-left: 75px;
  position: relative;
  font-size: 20px;
  line-height: 1.05;
  color: #013648;
  display: inline-block;
  margin-bottom: 20px;
  min-height: 48px;
  cursor: pointer;
}
.dailog-body .SearchPatients .doctor-item img {
  width: 40px;
  min-width: 40px;
  height: 48px;
  border-radius: 2px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  position: absolute;
  left: 19px;
  object-fit: cover;
}
.dailog-body .SearchPatients .doctor-item span:last-child {
  font-size: 18px;
  color: #72787d;
}
.dailog-body .SearchPatients .search-category.doctor {
  position: relative;
  padding-top: 30px;
}
.dailog-body .SearchPatients .search-category.doctor:after {
  content: '';
  position: absolute;
  left: 19px;
  right: 19px;
  top: 0;
  height: 1px;
  background-color: rgba(151, 151, 151, 0.24);
}
.dailog-body .SearchPatients .Input-control.form-group input.form-control::placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
}
.dailog-body .SearchPatients .Input-control.form-group input.form-control:-moz-placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
}
.dailog-body .SearchPatients .search-result .loader {
  text-align: center;
}
.dailog-body .SearchPatients .search-result .loader img {
  margin-top: 15px;
  height: 30px;
  transform: translateZ(0);
  animation: circleanimation 2.5s infinite linear;
}
@media (hover: hover) {
  .dailog-body .SearchPatients .search-result .search-category .search-item p.email-ptag:hover,
  .dailog-body .SearchPatients .search-result .search-category .search-item:hover {
    /* background-color: #eaf4eb; */
    color: #46a6c7;
  }
}
@media (max-width: 767px) {
  .dailog-body .SearchPatients .search .search-result {
    min-width: auto;
    margin: 0;
    text-align: left;
  }
  .dailog-body .SearchPatients .Input-control.form-group input.form-control::placeholder {
    font-size: 18px;
  }
  .dailog-body .SearchPatients .Input-control.form-group input.form-control:-moz-placeholder {
    font-size: 18px;
  }
}

.RequestContactLocation .eachrow{
display: flex;
justify-content: space-between;
}
.RequestContactLocation .eachrow .item {
  flex: 0 0 50%;
  margin-bottom: 20px;
  }
  .RequestContactLocation .eachrow .item span{
    font-size: 18px;
    font-weight: bold;
  }
  .RequestContactLocation .eachrow .item p{
    font-size: 17px;
    margin: 0;
    margin-top: 5px;
    color: #3333339e;
  }
.RecipientSourceList {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.RecipientSourceList .name-cell {
  min-width: 30%;
}
.RecipientSourceList .tempalte-cell {
  min-width: 15%;
  text-align: left;
  padding-left: 10px;
}
.RecipientSourceList .type-cell {
  min-width: 20%;
  padding-left: 10px;
}
.RecipientSourceList .usage-cell {
  min-width: 15%;
  padding-left: 10px;
}
.RecipientSourceList .action-cell {
  min-width: 20%;
  padding-left: 10px;
}
.RecipientSourceList .rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.RecipientSourceList .rtable-row {
  width: 100%;
  display: flex;
}
.RecipientSourceList .rtable-row .rtable-cell.content-cell.name-cell {
  color: #26acda;
}
.RecipientSourceList .rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.RecipientSourceList .rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.RecipientSourceList .rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.RecipientSourceList .rtable-cell--content .webinar-date {
  font-weight: 700;
}
.RecipientSourceList .rtable-row.rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.RecipientSourceList .rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.RecipientSourceList .rtable-row {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.RecipientSourceList .rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.RecipientSourceList .rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.RecipientSourceList .action-menu {
  margin-left: auto;
  position: relative;
}
.RecipientSourceList .action-menu .action-btn {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}
.RecipientSourceList .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 3px;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.RecipientSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.RecipientSourceList .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.RecipientSourceList .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.RecipientSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.RecipientSourceList .send-btn {
  max-width: 90px;
  height: 40px;
  padding: 14px 18px 10px 13px;
  border-radius: 2px;
  background-color: #013648;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  margin-left: 40px;
  font-family: 'Calibre Regular';
  cursor: pointer;
}
.RecipientSourceList .send-btn IMG {
  margin-right: 3px;
}
.RecipientSourceList .send-btn:focus {
  outline: 0;
}
@media (max-width: 991px) {
  .RecipientSourceList .usage-cell {
    min-width: 20%;
  }
}
@media (max-width: 767px) {
  .RecipientSourceList .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}

.RequestRecipients .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.RequestRecipients .addwebsite-btn {
  width: 124px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestRecipients .send-btn {
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #013648;
}
.RequestRecipients .send-btn IMG {
  position: relative;
  top: -3px;
  left: -7px;
}
.RequestRecipients .select-content .arrow {
  top: 17px;
  right: 13px;
  border-width: 7px;
  border-top-color: #92929d;
}
.RequestRecipients .select-content {
  margin-bottom: 0;
  height: 40px;
}
.RequestRecipients .select-content SELECT {
  height: 40px;
}
.RequestRecipients .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.RequestRecipients .right-panel {
  display: flex;
}
.RequestRecipients .UILabel {
  position: fixed;
  right: 209px;
  top: 5px;
  margin-right: 30px;
}
.RequestRecipients .send-btn {
  width: 187px;
  height: 40px;
  padding: 13px 14px 10px 13px;
  border-radius: 2px;
  background-color: #013648;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  margin-left: 20px;
  font-family: 'Calibre Regular';
  cursor: pointer;
}
.RequestRecipients .send-btn:focus {
  outline: 0;
}
.RequestRecipients .send-btn IMG {
  margin-right: 5px;
}
.RequestRecipients .action-menu .action-btn IMG {
  width: 16px;
}
@media (max-width: 1200px) {
  .RequestRecipients .heading {
    flex-wrap: wrap;
  }
  .RequestRecipients .right-panel {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 14px;
  }
}
@media (max-width: 1024px) {
  .RequestRecipients .title {
    font-size: 20px;
  }
  .RequestRecipients .refresh-btn {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .RequestRecipients .heading {
    flex-wrap: wrap;
  }
  .RequestRecipients .heading .title {
    width: 100%;
  }
  .RequestRecipients .right-panel {
    flex-wrap: wrap;
  }
  .RequestRecipients .Select-control {
    margin-bottom: 0;
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
  }
}
@media (max-width: 414px) {
  .RequestRecipients .send-btn,
  .RequestRecipients .addwebsite-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.RequestReviews {
  width: 100%;
}
.RequestReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.RequestReviews .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.RequestReviews .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
}
.RequestReviews .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.RequestReviews .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
}
.RequestReviews .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.RequestReviews .ReviewAccounts-content {
  padding: 0 40px;
}
.RequestReviews .box-panel {
  padding: 33px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  margin-top: 31px;
  margin-bottom: 30px;
}
.RequestReviews .tab-content {
  position: relative;
}
.RequestReviews .AfterAuthSubHeader .main-inner-content {
  padding-right: 495px;
}
.RequestReviews .AfterAuthSubHeader .main-inner-content .title SPAN:first-child {
  min-width: 160px;
}
.RequestReviews .ReviewAccounts-content {
  position: relative;
}

.RequestReviews .SendingReviewRequest {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 11px 25px 18px;
  border: solid 1px #e2e2ea;
  display: inline-block;
  position: absolute;
  width: 418px;
  right: 40px;
  top: -90px;
}
.RequestReviews .SendingReviewRequest .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.RequestReviews .SendingReviewRequest .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.RequestReviews .SendingReviewRequest .sub-text A {
  color: #26acda;
}
.RequestReviews .addwebsite-btn {
  width: 124px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestReviews .googlenotconnectmsg{
  font-size: 30px;
  padding-top: 150px;
  text-align: center;
  color: #9EAEB6;
  font-style: italic;
}
.RequestReviews .send-request-btn {
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #26acda;
}
.RequestReviews .send-request-btn IMG {
  position: relative;
  top: -3px;
  left: -7px;
}

@media (max-width: 1400px) {
  .RequestReviews {
    width: calc(100% - 250px);
  }
  .RequestReviews .box-panle {
    padding: 40px 20px;
  }
  .RequestReviews .box-panle .search-review-list {
    right: 20px;
  }
  .RequestReviews .box-panle .search-review-list .Input-control {
    width: 230px;
  }
  .RequestReviews .SendingReviewRequest {
    width: 300px;
    top: -99px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 55px;
  }
}

@media (max-width: 1200px) {
  .RequestReviews .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .RequestReviews .box-panle .search-review-list {
    top: 36px;
  }
  .RequestReviews .main-section-content {
    padding: 0 20px 0 20px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .title SPAN:first-child {
    min-width: 134px;
  }
  .RequestReviews .SendingReviewRequest {
    padding: 11px 10px 18px;
  }
  .RequestReviews .SendingReviewRequest .approval-action SPAN {
    margin-right: 15px;
  }
  .RequestReviews .SendingReviewRequest .approval-action SPAN:last-child {
    margin-right: 0;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 450px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
  }
  .RequestReviews .SendingReviewRequest {
    top: -149px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 400px;
  }
}

@media (max-width: 1024px) {
  .RequestReviews .main-section-content {
    padding: 0 15px 0;
  }
  .RequestReviews .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
  .RequestReviews .ReviewAccounts-content {
    padding: 0px 15px;
  }
  .RequestReviews .box-panel {
    padding: 15px;
  }
  .RequestReviews {
    width: calc(100% - 200px);
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
    padding-right: 370px;
  }
  .RequestReviews .SendingReviewRequest {
    top: -150px;
    right: 15px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .title SPAN:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .RequestReviews .box-panle {
    padding: 20px;
  }
  .RequestReviews .box-panle .search-review-list {
    margin-top: 10px;
  }
  .RequestReviews {
    width: 100%;
  }
  .RequestReviews .setting-tabs .tab-list-item {
    margin-right: 20px;
    font-size: 18px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .RequestReviews .SendingReviewRequest {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .RequestReviews .SendingReviewRequest .approval-action SPAN {
    margin-right: 10px;
  }
  .RequestReviews .setting-tabs .tab-list-item:first-child {
    margin-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .RequestReviews .setting-tabs .tab-list-item:first-child {
    margin-bottom: 10px;
  }
  .RequestReviews .send-request-btn,
  .RequestReviews .addwebsite-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}

.ShareReviews {
  width: 100%;
}
.ShareReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.ShareReviews .ShareReviews-content {
  padding: 0 40px;
}
.ShareReviews .box-panel {
  padding: 40px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
}
.ShareReviews .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.ShareReviews .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.ShareReviews .Widgets-form .Select-control {
  display: flex;
}
.ShareReviews .Widgets-control .text-info {
  text-align: center;
  margin-bottom: 19px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  color: #a2a2a2;
}
.ShareReviews .Select-control .label {
  min-width: 80px;
  padding-top: 10px;
  font-size: 16px;
  color: #013648;
}
.ShareReviews .Widgets-control {
  width: 33%;
  padding: 0 15px;
}
.ShareReviews .Widgets-control .Select-control.form-group:after {
  display: none;
}
.ShareReviews .Widgets-control select {
  border-radius: 5px;
}
.ShareReviews .Widgets-control .Select-control .arrow {
  top: 18px;
  border-width: 6px;
  border-top-color: rgba(1, 54, 72, 0.4);
  right: 16px;
}
.ShareReviews .box-inner-content {
  padding-top: 30px;
}
.ShareReviews .Widgets-section {
  display: flex;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 30px;
  margin: 0 -15px;
}
.ShareReviews .widget-preview {
  width: 270px;
  height: 180px;
  background-color: #026ca2;
  margin: auto;
}
.ShareReviews .dimantion-text {
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  color: #474747;
}
.ShareReviews .widget-preview {
  padding: 15px 20px;
  position: relative;
}
.ShareReviews .widget-preview .preview-text {
  font-size: 28px;
  color: #fff;
  font-family: 'Calibre Light';
  padding-right: 60px;
}
.ShareReviews .widget-preview .preview-text SPAN {
  display: inline-block;
  width: 100%;
}
.ShareReviews .widget-preview .star {
  display: inline-flex;
  padding-right: 25px;
  position: relative;
}
.ShareReviews .widget-preview .star IMG {
  width: 12px;
}
.ShareReviews .widget-preview .star > SPAN {
  display: inline-block;
  margin-right: 7px;
}
.ShareReviews .widget-preview .star > .rating {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #ffffff;
}
.ShareReviews .widget-preview .certificate-img {
  width: 50px;
  position: absolute;
  right: 25px;
  top: 0;
}
.ShareReviews .Widgets-info {
  width: 600px;
  max-width: 100%;
}
.ShareReviews .Widgets-info .heading-title {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.18px;
  color: #013648;
}
.ShareReviews .Widgets-info {
  padding-top: 26px;
}
.ShareReviews .Widgets-info .copy-link {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.ShareReviews .Textarea.form-group .form-control:focus,
.ShareReviews .Textarea.form-group .form-control:not(:placeholder-shown) {
  border: 0;
  outline: 0;
}
.ShareReviews .widget-textarea {
  background-color: #f7f9f9;
  color: #72787d;
  padding: 10px 10px 22px 13px;
  margin-top: 10px;
  margin-bottom: 13px;
}
.ShareReviews .widget-textarea TEXTAREA {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #72787d;
  background-color: #f7f9f9;
  height: 78px;
}
.ShareReviews .Widgets-control .Select-control.form-group SELECT {
  height: 40px;
  padding: 8px 12px 8px 22px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
}
.ShareReviews .Widgets-control .Select-control {
  margin-bottom: 12px;
}
.ShareReviews .custom-photoshop-picker {
  margin: auto;
}
@media (max-width: 1400px) {
  .ShareReviews .box-panle {
    padding: 40px 20px;
  }
}

@media (max-width: 1200px) {
  .ShareReviews .main-section-content {
    padding: 0 20px 0 20px;
  }
  .ShareReviews .Widgets-section {
    flex-wrap: wrap;
  }
  .ShareReviews .Widgets-control {
    width: 50%;
    margin-bottom: 15px;
  }
  .ShareReviews .Widgets-control:last-child {
    padding-left: 80px;
  }
}

@media (max-width: 1024px) {
  .ShareReviews .main-section-content {
    padding: 0 15px 0;
  }
  .ShareReviews .ShareReviews-content {
    padding: 0px 15px;
  }
  .ShareReviews .box-panel {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .ShareReviews .box-panle {
    padding: 20px;
  }
  .ShareReviews {
    width: 100%;
  }
  .ShareReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .ShareReviews .Widgets-control {
    width: 100%;
  }
  .ShareReviews .Widgets-control:last-child {
    padding-left: 15px;
  }
  .ShareReviews .Select-control .label {
    width: 100%;
  }
  .ShareReviews .Widgets-form .Select-control {
    flex-wrap: wrap;
  }
  .ShareReviews .Widgets-control .Select-control .arrow {
    top: 52px;
  }
}

.AppointmentItem {
  display: flex;
  padding: 27px 0 17px;
  justify-content: space-between;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.AppointmentItem:first-child {
  padding-bottom: 44px;
}
.AppointmentItem:last-child {
  border-bottom: 0;
}
.AppointmentItem .ap-dc-info {
  display: flex;
}
.AppointmentItem .ap-dc-info .dc-img {
  min-height: 120px;
  min-width: 80px;
  height: 120px;
  width: 80px;
  padding-top: 7px;
  cursor: pointer;
}
.AppointmentItem .ap-dc-info .dc-img img {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AppointmentItem .dc-info {
  padding-left: 24px;
  width: calc(100% - 80px);
}
.AppointmentItem .dc-info .name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
  cursor: pointer;
}
.AppointmentItem .dc-info .dc-specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  cursor: pointer;
}
.AppointmentItem .dc-info .address,
.AppointmentItem .dc-info .dc-guardian {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  display: flex;
  align-items: flex-start;
}
.AppointmentItem .dc-info .address {
  padding-top: 17px;
}
.AppointmentItem .dc-info .dc-guardian {
  padding-top: 23px;
}
.AppointmentItem .dc-info .address .icon,
.AppointmentItem .dc-info .dc-guardian .icon {
  width: 17px;
  margin-right: 14px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.AppointmentItem .dc-info .dc-guardian .icon.insurance-icon {
  padding-top: 5px;
}
.AppointmentItem .ap-dc-timeinfo {
  width: 200px;
  min-width: 200px;
  text-align: right;
  padding-top: 5px;
}
.AppointmentItem .ap-dc-timeinfo .appointment-time {
  width: 110px;
  min-height: 110px;
  margin-left: auto;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  border: solid 0.8px #013648;
  background-color: #013648;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.38px;
  color: #f7fffc;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.AppointmentItem .ap-dc-timeinfo .appointment-time span {
  display: inline-block;
  width: 100%;
}
.AppointmentItem .ap-dc-timeinfo .appointment-time .day {
  font-family: 'Calibre Medium';
}
.AppointmentItem .ap-dc-timeinfo .distance {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
  margin-bottom: 2px;
}
.AppointmentItem .ap-dc-timeinfo .book-appointment > span {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
}

.AppointmentItem .appointment-notification {
  margin-top: 18px;
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: -0.23px;
  color: #013648;
  padding: 10px 38px 8px 19px;
  border-radius: 2px;
  background-color: rgba(1, 54, 72, 0.03);
  border-left: 6px solid #f2962d;
  position: relative;
}
.AppointmentItem .appointment-notification .notification-icon {
  width: 16px;
  position: absolute;
  right: 14px;
  top: 8px;
}
.AppointmentItem .appointment-notification span {
  display: inline-block;
  width: 100%;
}
.AppointmentItem .ins-info {
  color: #26acda;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.AppointmentItem .ap-dc-timeinfo .book-appointment {
  position: relative;
  margin-top: 9px;
}
.AppointmentItem .ap-dc-timeinfo .book-appointment .appointment-action {
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 0 10px 1px #eaeaea;
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  overflow: hidden;
}
.AppointmentItem .menu-item {
  font-size: 16px;
  color: #013648;
  display: inline-block;
  width: 100%;
  padding: 20px 20px 17px;
  border-bottom: 1px solid #f1f1f5;
  cursor: pointer;
}
.AppointmentItem .menu-item:last-child {
  border-bottom: 0;
}
.AppointmentItem .menu-item.cancel {
  color: #e85d72;
}
.AppointmentItem.confirmed .ap-dc-timeinfo .appointment-time,
.AppointmentItem.completed .ap-dc-timeinfo .appointment-time {
  background-color: #198754;
  border-color: #198754;
  color: #f1f1f1;
}
.AppointmentItem.cancelled .ap-dc-timeinfo .appointment-time {
  border-color: #e85d72;
  background-color: #e85d72;
  color: #f7fffc;
}
.AppointmentItem.pending .ap-dc-timeinfo .appointment-time {
  border: solid 1.5px #013648;
  background-color: transparent;
  color: #013648;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 11px;
  padding-right: 0;
  min-height: 110px;
  width: 110px;
  text-align: left;
}
.AppointmentItem.pending .dc-info .address,
.AppointmentItem.pending .dc-info .dc-guardian {
  color: #013648;
}
.AppointmentItem .show-appointment-details {
  display: block;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #26acda;
  margin-top: 8px;
  cursor: pointer;
}
.AppointmentItem .ap-dc-timeinfo .book-appointment:hover .appointment-action {
  display: block;
}
.AppointmentItem .person-visit,
.AppointmentItem .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 13.5px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 5px;
  padding-left: 35px;
  margin-top: 13px;
}
.AppointmentItem .person-visit img,
.AppointmentItem .video-visit img {
  width: 27px;
  border: 0;
  position: absolute;
  left: 10px;
  top: -1px;
}
.AppointmentItem .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.AppointmentItem .video-visit img {
  left: 7px;
}
.AppointmentItem .start-videolink {
  padding: 5px 10px 2px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  margin-bottom: 31px;
  margin-left: 10px;
}

@media (hover: hover) {
  .AppointmentItem .ap-dc-timeinfo .book-appointment > span:hover {
    color: rgba(38, 172, 218, 0.6);
  }
  .AppointmentItem .menu-item:hover {
    background-color: rgba(1, 54, 72, 0.1);
  }
  .AppointmentItem .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1200px) {
  .AppointmentItem .ap-dc-timeinfo {
    width: 160px;
    min-width: 160px;
  }
}

@media (max-width: 767px) {
  .AppointmentItem .ap-dc-info {
    width: 100%;
  }
  .AppointmentItem .ap-dc-info .dc-img {
    padding-top: 0;
  }
  .AppointmentItem .ap-dc-timeinfo {
    padding-top: 0;
  }
  .AppointmentItem .appointment-notification {
    width: calc(100% + 101px);
    margin-left: -101px;
    margin-top: 32px;
    padding-top: 12px;
    padding-bottom: 4px;
  }
  .AppointmentItem .ins-info {
    text-decoration: underline;
    color: #013648;
  }
  .AppointmentItem .appointment-notification .notification-icon {
    width: 18px;
    top: 10px;
  }
  .AppointmentItem .dc-info .name {
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 4px;
  }
  .AppointmentItem .dc-info .dc-specialist {
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -0.21px;
  }
  .AppointmentItem .dc-info .address .icon,
  .dc-info .dc-guardian .icon {
    margin-right: 12px;
  }
  .AppointmentItem .AppointmentItem {
    flex-wrap: wrap;
  }
  .AppointmentItem .ap-dc-timeinfo {
    width: 100%;
    margin-top: 21px;
    position: relative;
    min-height: 130px;
    padding-left: 140px;
    margin-left: 0;
  }
  .AppointmentItem .ap-dc-timeinfo .distance {
    margin-top: 18px;
    margin-bottom: 11px;
  }
  .AppointmentItem .ap-dc-timeinfo .appointment-time {
    position: absolute;
    left: 0;
    top: 0;
  }
  .AppointmentItem {
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 20px;
  }
  .AppointmentItem:first-child {
    padding-bottom: 20px;
  }
  .AppointmentItem .dc-info {
    padding-left: 21px;
    padding-top: 2px;
  }
  .AppointmentItem .start-videolink {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media (max-width: 375px) {
  .AppointmentItem .ap-dc-timeinfo {
    padding-left: 125px;
    min-height: 115px;
  }
}

.FeedItem {
  width: 100%;
  border-radius: 4px;
  background-color: #f5f5f2;
  display: flex;
  padding: 19px 15px 9px 35px;
  position: relative;
  margin-bottom: 18px;
  min-height: 95px;
}
.FeedItem.cancel .feed-icon {
  position: relative;
  top: 0px;
  width: 17px;
}
.FeedItem .feed-icon {
  width: 22px;
  margin-left: 30px;
}
.FeedItem.welcome-feed .feed-icon {
  width: 32px;
  margin-right: 16px;
}
.FeedItem .feed-details {
  width: 100%;
}
.FeedItem.welcome-feed {
  background-color: transparent;
  background-image: linear-gradient(
    215deg,
    rgba(61, 147, 166, 0.06) 0%,
    rgba(240, 231, 56, 0.06) 110%,
    rgba(141, 120, 186, 0.06) 51%
  );
}
.FeedItem .welcome-feed-title {
  font-size: 20px;
  line-height: 1.7;
  color: #013648;
  font-family: 'Heldane Display';
  margin-bottom: 1px;
}
.FeedItem .feed-icon img {
  width: 100%;
}
.FeedItem .feed-text {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.43px;
  color: #013648;
  margin-bottom: 6px;
}
.FeedItem .feed-status {
  display: flex;
  justify-content: space-between;
}
.FeedItem .feed-time {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  position: absolute;
  bottom: 4px;
  right: 15px;
  margin-left: auto;
}
.FeedItem .feed-status .feed-status-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.38px;
}
.FeedItem.cancel .feed-status .feed-status-text {
  color: #b2bcc4;
}
.FeedItem.confirmed .feed-status .feed-status-text {
  color: #26acda;
  cursor: pointer;
}
.FeedItem.request .feed-status .feed-status-text {
  color: #72787d;
}
.FeedItem.confirmed.doctor-profile {
  padding-left: 35px;
}
.FeedItem.confirmed.doctor-profile .feed-icon {
  min-width: 27px;
  width: 27px;
  height: 40px;
}
.FeedItem.confirmed.doctor-profile .feed-icon img {
  border-radius: 1.6px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.FeedItem.disable-item {
  opacity: 0.3;
}

@media (hover: hover) {
  .FeedItem.confirmed .feed-status .feed-status-text:hover {
    color: rgba(38, 172, 218, 0.6);
  }
}

@media (max-width: 767px) {
  .FeedItem .feed-status {
    flex-direction: column;
  }
  .FeedItem .feed-time {
    margin-left: 0;
  }
  .FeedItem .feed-text {
    font-size: 16px;
  }
}

.AppointmentConfirmedModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 678px;
  margin: auto;
}
.AppointmentConfirmedModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.AppointmentConfirmedModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.AppointmentConfirmedModal .modal-content-header .close img {
  width: 100%;
}
.AppointmentConfirmedModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.AppointmentConfirmedModal .modal-content-body {
  padding: 23px 82px 23px 90px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AppointmentConfirmedModal .modal-content-footer {
  padding: 23px 82px 33px 90px;
  display: flex;
}
.AppointmentConfirmedModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AppointmentConfirmedModal .DoctorAppointmentTime {
  display: flex;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .appointment-time {
  min-width: 120px;
  height: 140px;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  border: solid 0.8px #013648;
  background-color: #013648;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.45px;
  color: #f7fffc;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .appointment-time span {
  display: inline-block;
  width: 100%;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .appointment-time .day {
  font-family: 'Calibre Medium';
}
.AppointmentConfirmedModal .DoctorAppointmentTime .dc-info {
  padding-left: 32px;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name {
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Regular';
  margin-bottom: 35px;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name a {
  color: #26acda;
}
.AppointmentConfirmedModal .social-calender .social-calender-title {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.AppointmentConfirmedModal .social-calender .social-link {
  display: flex;
  margin: 0px -15px;
}
.AppointmentConfirmedModal .social-calender .social-link a {
  font-size: 18px;
  line-height: 1.11;
  color: #26acda;
  padding: 5px 15px 2px;
  display: inline-block;
  border-right: solid 1px #e0e0e0;
}
.AppointmentConfirmedModal .social-calender .social-link a:last-child {
  border-right: 0;
}
.AppointmentConfirmedModal .Divider {
  margin: 25px 0px;
}
.AppointmentConfirmedModal .Divider .container {
  width: auto;
}
.AppointmentConfirmedModal .doctor-details {
  display: flex;
}
.AppointmentConfirmedModal .doctor-details .dc-img {
  width: 120px;
  min-width: 120px;
  height: 179px;
}
.AppointmentConfirmedModal .doctor-details .dc-img img {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AppointmentConfirmedModal .doctor-details .doctor-info {
  padding-left: 32px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name {
  margin-bottom: 28px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .h3 {
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .speciality {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address,
.AppointmentConfirmedModal .doctor-details .doctor-info .insurance,
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent {
  display: flex;
  margin-bottom: 22px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent {
  margin-bottom: 0px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .text,
.AppointmentConfirmedModal .doctor-details .doctor-info .insurance .text,
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent .text {
  font-size: 18px;
  line-height: 1.17;
  letter-spacing: -0.23px;
  color: #72787d;
  padding-left: 15px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .icon img,
.AppointmentConfirmedModal .doctor-details .doctor-info .insurance .icon img,
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent .icon img {
  width: 17px;
  height: 20px;
  object-fit: contain;
  position: relative;
  top: -3px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .icon img {
  position: static;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .text > span {
  display: inline-block;
  width: 100%;
}
.AppointmentConfirmedModal .reschedule .que {
  font-size: 24px;
  line-height: 0.79;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 17px;
}
.AppointmentConfirmedModal .reschedule .decription {
  font-size: 18px;
  line-height: 1.11;
  color: #72787d;
}
.AppointmentConfirmedModal .modal-content-footer .CircleButton {
  width: 100%;
}
.AppointmentConfirmedModal .modal-content-footer .cancel {
  display: inline-block;
}
.AppointmentConfirmedModal .modal-content-footer .cancel .CircleButton.SecondaryButton {
  width: 136px;
  margin-left: 21px;
  color: #e85d72;
  border-color: #e85d72;
}

@media (hover: hover) {
  .AppointmentConfirmedModal .social-calender .social-link a:hover {
    opacity: 0.7;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name a:hover {
    opacity: 0.7;
  }
  .AppointmentConfirmedModal .modal-content-footer .cancel .CircleButton.SecondaryButton:hover {
    background-color: #e85d72;
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .DoctorAppointmentTime .dc-info .name {
    font-size: 18px;
  }
  .DoctorAppointmentTime .dc-info .dc-specialist {
    font-size: 16px;
  }
  .AppointmentConfirmedModal {
    width: 100%;
  }
  .AppointmentConfirmedModal .modal-content-body {
    padding: 23px 20px 23px 20px;
  }
  .AppointmentConfirmedModal .Divider {
    display: block;
  }
  .AppointmentConfirmedModal .modal-content-footer {
    padding: 10px 20px 10px 20px;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime {
    flex-wrap: wrap;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime .dc-info {
    padding-left: 0px;
    margin-top: 20px;
  }
  .AppointmentConfirmedModal .modal-content-header {
    padding-left: 20px;
  }
  .AppointmentConfirmedModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .AppointmentConfirmedModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .AppointmentConfirmedModal .modal-content-footer {
    flex-wrap: wrap;
  }
  .AppointmentConfirmedModal .modal-content-footer .CircleButton {
    width: 100%;
    margin-bottom: 10px;
  }
  .AppointmentConfirmedModal .modal-content-footer .cancel {
    width: 100%;
  }
  .AppointmentConfirmedModal .modal-content-footer .cancel .CircleButton.SecondaryButton {
    width: 100%;
    margin-left: 0;
  }
  .AppointmentConfirmedModal .modal-content-header {
    height: 65px;
  }
  .AppointmentConfirmedModal .doctor-details .dc-img {
    width: 70px;
    min-width: 70px;
    height: 115px;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info {
    padding-left: 15px;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .h3 {
    font-size: 18px;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .speciality {
    font-size: 16px;
  }
  .AppointmentConfirmedModal .Divider .container {
    padding: 0;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name {
    margin-bottom: 0;
  }
}
@media (max-width: 413px) {
  .AppointmentConfirmedModal .doctor-details {
    flex-wrap: wrap;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info {
    padding-left: 0px;
    margin-top: 15px;
  }
}
@supports (-webkit-touch-callout: none) {
  .AppointmentConfirmedModal .modal-content-footer .CircleButton {
    padding-top: 2px;
  }
}

.CancelAppointmentModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 586px;
  margin: auto;
}
.CancelAppointmentModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.CancelAppointmentModal .DoctorAppointmentTime .appointment-time {
  border: solid 0.8px #e85d72;
  background-color: #e85d72;
  color: #f7fffc;
}
.CancelAppointmentModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.CancelAppointmentModal .modal-content-header .close img {
  width: 100%;
}
.CancelAppointmentModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.CancelAppointmentModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.CancelAppointmentModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.CancelAppointmentModal .Divider {
  margin-top: 10px;
}

.CancelAppointmentModal .Divider .container {
  width: auto;
}
.CancelAppointmentModal .cancel-reason {
  margin-top: 18px;
  padding: 0px 50px;
}
.CancelAppointmentModal .cancel-reason .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  line-height: 1.61;
  letter-spacing: -0.18px;
  color: #013648;
  margin-bottom: 19px;
}
.CancelAppointmentModal .cancel-reason .cancel-reason-list > div {
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: -0.34px;
  color: #013648;
  min-height: 47px;
  border-radius: 21.6px;
  border: solid 1px #b2bcc4;
  margin-bottom: 12px;
  padding: 9px 24px 12px;
  border-top-left-radius: 0;
  cursor: pointer;
}
.CancelAppointmentModal .cancel-reason .cancel-reason-list > div.selected {
  background-color: #013648;
  color: #fff;
}
.CancelAppointmentModal .keep-appointment span {
  margin-top: 28px;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
}

@media (hover: hover) {
  .CancelAppointmentModal .cancel-reason .cancel-reason-list > div:hover {
    background-color: #013648;
    color: #fff;
  }
  .CancelAppointmentModal .keep-appointment span:hover {
    opacity: 0.7;
  }
}
@media (max-width: 767px) {
  .CancelAppointmentModal {
    width: 100%;
  }
  .CancelAppointmentModal .cancel-reason {
    padding: 0px 0px;
  }
  .CancelAppointmentModal .modal-content-body {
    padding: 29px 20px;
  }
  .CancelAppointmentModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .CancelAppointmentModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .CancelAppointmentModal .modal-content-header {
    padding-left: 20px;
  }
  .CancelAppointmentModal .modal-content-body {
    max-height: calc(100vh - 184px);
  }
}
@media (max-width: 413px) {
  .CancelAppointmentModal .DoctorAppointmentTime {
    flex-wrap: wrap;
  }
  .CancelAppointmentModal .DoctorAppointmentTime .dc-info {
    padding-left: 0;
    width: 100%;
    margin-top: 10px;
  }
}

.InsuranceInfoModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 586px;
  margin: auto;
}
.InsuranceInfoModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.InsuranceInfoModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.InsuranceInfoModal .modal-content-header .close img {
  width: 100%;
}
.InsuranceInfoModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.InsuranceInfoModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 220px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.InsuranceInfoModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.InsuranceInfoModal .modal-content-footer .CircleButton {
  width: 100%;
}
.InsuranceInfoModal .Divider {
  margin-top: 10px;
}
.InsuranceInfoModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.InsuranceInfoModal .form-group-2-colum {
  display: flex;
  margin: 0 -12px;
}
.InsuranceInfoModal .Input-control {
  margin-bottom: 38px;
}
.InsuranceInfoModal .form-group-2-colum .Select-control,
.InsuranceInfoModal .form-group-2-colum .Input-control,
.InsuranceInfoModal .form-group-2-colum .Upload {
  margin-left: 12px;
  margin-right: 12px;
}
.InsuranceInfoModal .Divider .container {
  width: auto;
}
.InsuranceInfoModal .medical-insurance .h3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  margin-bottom: 25px;
}
.InsuranceInfoModal .medical-insurance {
  margin-top: 15px;
}
.InsuranceInfoModal .medical-insurance .Upload,
.InsuranceInfoModal .medical-insurance .form-group-2-colum .Input-control,
.InsuranceInfoModal .medical-insurance .form-group-2-colum .Select-control {
  width: 50%;
}
.InsuranceInfoModal .medical-insurance .Checkbox-control {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  padding-left: 35px;
}

.InsuranceInfoModal .secondary-label {
  color: rgba(1, 54, 72, 0.4);
}
.InsuranceInfoModal .DoctorAppointmentTime .appointment-time {
  width: 120px;
  min-width: unset;
  height: 140px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InsuranceInfoModal .DoctorAppointmentTime .dc-info {
  width: calc(100% - 158px);
}

@media (max-width: 767px) {
  .InsuranceInfoModal {
    width: 100%;
  }
  .InsuranceInfoModal .modal-content-body {
    padding: 29px 15px;
  }
  .InsuranceInfoModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
  .InsuranceInfoModal .DoctorAppointmentTime .appointment-time {
    font-size: 20px;
  }
  .InsuranceInfoModal .DoctorAppointmentTime {
    flex-wrap: wrap;
  }
  .InsuranceInfoModal .DoctorAppointmentTime .dc-info {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
  .InsuranceInfoModal .form-group-2-colum {
    flex-wrap: wrap;
  }
  .InsuranceInfoModal .medical-insurance .Upload,
  .InsuranceInfoModal .medical-insurance .form-group-2-colum .Input-control,
  .InsuranceInfoModal .medical-insurance .form-group-2-colum .Select-control {
    width: 100%;
  }
  .InsuranceInfoModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .InsuranceInfoModal .modal-content-header .close {
    height: 18px;
    width: 18px;
  }
}

.PatientDashboard .filter .Input-control .view-img {
  left: 18px;
  height: 17px;
  top: 26px;
}
.PatientDashboard .filter .Input-control.zipcode .view-img {
  left: 13px;
}
.PatientDashboard .filter .Input-control INPUT {
  font-family: 'Calibre Regular';
}
.PatientDashboard .filter .Input-control.zipcode INPUT {
  padding-top: 8px;
}
.PatientDashboard .filter .Input-control.transform-input.zipcode .form-control:focus,
.PatientDashboard
  .filter
  .Input-control.transform-input.zipcode
  .form-control:not(:placeholder-shown),
.PatientDashboard .filter .Input-control.transform-input.filled-focus-input.zipcode .form-control {
  padding-top: 8px;
}
.PatientDashboard .filter .ElasticSearch {
  min-width: 426px;
  margin-right: 4px;
}
.PatientDashboard .filter .ElasticSearch .Input-control.form-group INPUT:focus {
  border: solid 1px #013648;
}
.PatientDashboard .filter {
  background-color: #013648;
  display: flex;
  padding: 5px 60px 13px;
}
.PatientDashboard .filter .Input-control INPUT {
  padding-left: 53px;
  border: solid 1px rgba(1, 54, 72, 0.1);
  border-radius: 4px;
}
.PatientDashboard .filter .Input-control .label {
  display: none;
}
.PatientDashboard .filter .Input-control {
  margin: 0 2px;
  flex: 1 1;
}
.PatientDashboard .filter .ElasticSearch .Input-control {
  margin-left: 0;
}
.PatientDashboard .filter .ElasticSearch .Input-control.form-group INPUT {
  height: 52px;
}
.PatientDashboard .filter .Input-control.transform-input .form-control:focus,
.PatientDashboard .filter .Input-control.transform-input .form-control:not(:placeholder-shown),
.PatientDashboard .filter .Input-control.transform-input.filled-focus-input .form-control {
  padding: 5px 0px 5px 53px;
}
.PatientDashboard .filter .SelectDate {
  margin-right: 4px;
  flex: 1 1;
  border: 0;
}
.PatientDashboard .filter .SelectDate .form-control {
  border: solid 1px rgba(1, 54, 72, 0.1);
}
.PatientDashboard .search-result {
  padding: 21px 60px 12px;
  display: flex;
  align-items: center;
}
.PatientDashboard .search-result .search-text {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 60%;
  padding-right: 15px;
}
.PatientDashboard .search-result .search-text .search-item {
  color: #b2bcc4;
}
.PatientDashboard .search-result .search-text .search-item > SPAN {
  padding-right: 5px;
}
.PatientDashboard .search-result .search-text .search-item > SPAN:not(:first-child) {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}
.PatientDashboard .search-result .search-text .search-item > SPAN:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  left: 5px;
  top: 11px;
  background-color: #b2bcc4;
}
.PatientDashboard .search-action .CircleButton {
  height: 30px;
  border-radius: 15.1px;
  border: solid 1px #013648;
  line-height: 32px;
  display: inline-block;
  width: auto;
  padding: 0px 18px;
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
  margin-right: 11px;
}
.PatientDashboard .patien-dashboard-wrapper {
  display: flex;
  background-color: #fbfbf9;
  padding-top: 41px;
  padding-bottom: 40px;
  min-height: calc(100vh - 211px);
}
.PatientDashboard .patien-dashboard-wrapper .left-panle {
  width: 60%;
  padding-left: 62px;
  padding-right: 102px;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.07;
  color: #013648;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
}
.capitalize {
  text-transform: capitalize;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title .app-list-detail {
  position: relative;
  padding-left: 39px;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .calendar-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -4px;
  left: 0;
  cursor: pointer;
}
.PatientDashboard
  .patien-dashboard-wrapper
  .left-panle
  .appointment-list
  .title
  .app-list-detail
  .app-calender {
  width: 100%;
  height: 100%;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1.45;
  color: #013648;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-bottom: 12px;
}
.PatientDashboard .appointment-list .title .past-appointment {
  cursor: pointer;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: -0.34px;
  color: #26acda;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list {
  margin-top: 53px;
  margin-bottom: 80px;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle .title {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.21;
  letter-spacing: -0.3px;
  color: #013648;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle {
  padding: 20px 64px 0 50px;
  border-left: 1px solid rgba(32, 58, 112, 0.08);
  width: 40%;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle .title .feed-count {
  width: 28px;
  height: 22px;
  border-radius: 12px;
  background-color: #e85d72;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 27px;
  display: inline-block;
  position: relative;
  top: -3px;
  margin-left: 12px;
}
.PatientDashboard .right-panle .title .clear-complete {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #72787d;
  cursor: pointer;
}
.PatientDashboard .right-panle .title .clear-complete.inactive {
  color: #dcdcdc;
  cursor: not-allowed;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle .feed-list {
  padding-top: 28px;
}
.PatientDashboard .CustomFooter {
  display: none;
}
.PatientDashboard .no-appointment {
  text-align: center;
  height: 500px;
  display: flex;
}
.PatientDashboard .no-appointment-content {
  margin: auto;
}
.PatientDashboard .no-appointment-content .no-appointment-img IMG {
  width: 74px;
  height: 71px;
  object-fit: contain;
}
.PatientDashboard .no-appointment-content .no-appointment-text {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #72787d;
  padding: 30px 0px 42px;
}
.PatientDashboard .no-appointment-content .no-appointment-text SPAN {
  display: inline-block;
  width: 100%;
}
.PatientDashboard .no-appointment-content .finddoctor SPAN {
  font-size: 20px;
  line-height: 1.13;
  letter-spacing: -0.38px;
  text-align: center;
  color: #26acda;
  display: inline-block;
  cursor: pointer;
}
.PatientDashboard .no-appointment-content .finddoctor SPAN IMG {
  position: relative;
  top: 3px;
  margin-right: 5px;
  height: 18px;
  width: 18px;
}
.PatientDashboard .search-btn {
  width: 93px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #3d93a6;
  cursor: pointer;
  margin-left: 6px;
  vertical-align: top;
  margin-top: 1px;
  background-color: #3d93a6;
}
.PatientDashboard .search-btn .mghover-icon {
  display: none;
}
.PatientDashboard .search-btn IMG:last-child {
  display: none;
}
.PatientDashboard .search-btn:focus {
  outline: 0;
}

/*Sticky Header*/
.PatientDashboard.sticky-header {
  padding-top: 82px;
}
.PatientDashboard.sticky-header .filter {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
.PatientDashboard .filter .logo {
  width: 160px;
  margin-right: 30px;
  padding-top: 3px;
  display: none;
}
.PatientDashboard .filter .logo IMG {
  width: 100%;
}
.PatientDashboard.sticky-header .filter .logo {
  display: block;
}
.PatientDashboard.sticky-header .filter .search-btn {
  width: 56px;
}
.PatientDashboard.sticky-header .filter .ElasticSearch {
  min-width: 380px;
}
.PatientDashboard.sticky-header .filter {
  padding: 30px 60px 19px;
}
.PatientDashboard .filter .CircleButton.WhiteButton {
  display: none;
}
.PatientDashboard .search-warp {
  display: none;
}
.PatientDashboard .search-btn SPAN {
  display: none;
}
.PatientDashboard .filter .Input-control.form-group INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control.form-group INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control.form-group INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control.form-group INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .search-btn[disabled] {
  opacity: 0.5;
  color: #d8d8d8;
  cursor: not-allowed;
}

@media (hover: hover) {
  .PatientDashboard .search-action .CircleButton:hover {
    color: #fff;
  }
  .PatientDashboard .filter.show-filter .CircleButton.WhiteButton:hover {
    border: 1px solid #72787d;
  }
  .PatientDashboard .search-btn:enabled:hover SPAN {
    color: #013648;
  }
  .PatientDashboard .search-btn:enabled:hover .mg-icon {
    display: none;
  }
  .PatientDashboard .no-appointment-content .finddoctor SPAN:hover {
    opacity: 0.7;
  }
  .PatientDashboard .search-btn:enabled:hover IMG {
    display: none;
  }
  .PatientDashboard .search-btn:enabled:hover .mghover-icon {
    display: inline-block;
  }
  .PatientDashboard .search-btn:enabled:hover {
    background-color: rgba(61, 147, 166, 0.8);
  }
}

@media (max-width: 1366px) {
  .PatientDashboard.sticky-header .filter .ElasticSearch {
    min-width: 280px;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: 322px;
  }
}
@media (max-width: 1200px) {
  .PatientDashboard .filter {
    padding: 15px 30px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 30px;
    padding-right: 30px;
  }
  .PatientDashboard .filter .Input-control INPUT,
  .PatientDashboard .filter .Input-control.transform-input.filled-focus-input .form-control {
    padding-left: 42px;
    padding-right: 15px;
  }
  .PatientDashboard .search-btn {
    width: 48px;
  }
  .PatientDashboard .filter .Input-control .view-img {
    left: 12px;
  }
  .PatientDashboard.sticky-header .filter .logo {
    display: flex;
    width: 120px;
    margin-right: 0;
  }
  .PatientDashboard.sticky-header .filter .logo .view-img {
    margin: auto;
  }
  .PatientDashboard.sticky-header .filter .search-btn {
    width: 44px;
  }
  .PatientDashboard.sticky-header .filter .ElasticSearch {
    min-width: 233px;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: 300px;
  }
}

@media (max-width: 1024px) {
  .PatientDashboard .filter {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
  .PatientDashboard .patien-dashboard-wrapper {
    flex-wrap: wrap;
  }
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    margin-top: 40px;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: initial;
    width: 49%;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control,
  .PatientDashboard .filter .SelectDate .Input-control,
  .PatientDashboard .filter .InsuranceFilterSelect .Input-control {
    width: 100%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .PatientDashboard .filter .chooseinsurance {
    width: calc(49% - 75px);
  }
  .PatientDashboard .filter .Input-control,
  .PatientDashboard .filter .SelectDate {
    width: 49%;
    flex: inherit;
    margin: 0;
  }
  .PatientDashboard .search-btn {
    margin: auto;
    margin: inherit;
    margin-left: auto;
  }
  .PatientDashboard .filter .SelectDate .Input-control {
    width: 100%;
  }
  .PatientDashboard .filter .f-search {
    min-width: auto;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: initial;
    width: 49%;
    margin: 0;
  }
  .PatientDashboard .filter .SelectDate {
    margin-right: auto;
  }
  .PatientDashboard .filter .chooseinsurance .Input-control {
    width: 100%;
  }
  .PatientDashboard.sticky-header .filter .logo {
    position: absolute;
    left: 16px;
    top: 20px;
  }
  .PatientDashboard.sticky-header .filter {
    padding-left: 150px;
  }
  .PatientDashboard.sticky-header {
    padding-top: 154px;
  }
  .PatientDashboard.sticky-header .filter .ElasticSearch {
    min-width: auto;
  }
  .PatientDashboard.sticky-header .filter .search-btn {
    width: 62px;
  }
  .PatientDashboard .search-btn {
    width: 58px;
  }
  .PatientDashboard .filter .InsuranceFilterSelect .Input-control.form-group INPUT.form-control {
    padding-left: 42px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list {
    margin-bottom: 30px;
  }
  /* .PatientDashboard .reviewed-notification-feed-list .InfiniteScroll{
    height: auto !important;
  } */
}

@media (max-width: 767px) {
  .PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
    font-size: 32px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list {
    margin-top: 36px;
    margin-bottom: 30px;
  }
  .PatientDashboard
    .patien-dashboard-wrapper
    .left-panle
    .appointment-list
    .title
    .app-list-detail {
    margin-bottom: 9px;
    width: 100%;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 30px;
    padding-right: 30px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title {
    flex-wrap: wrap;
  }

  .PatientDashboard.sticky-header .filter {
    position: static;
    padding-left: 30px;
  }
  .PatientDashboard.sticky-header .filter .logo {
    display: none;
  }
  .PatientDashboard .filter .ElasticSearch {
    width: 100%;
  }
  .PatientDashboard .filter .Input-control,
  .PatientDashboard .filter .SelectDate {
    width: 100%;
  }
  .PatientDashboard .patien-dashboard-wrapper {
    padding-top: 27px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
    font-size: 48px;
    line-height: 0.96;
    letter-spacing: normal;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title {
    letter-spacing: normal;
    line-height: 1.1;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
  .PatientDashboard
    .patien-dashboard-wrapper
    .left-panle
    .appointment-list
    .title
    .past-appointment {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    color: #26acda;
    padding-left: 40px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title SPAN {
    display: block;
  }
  .PatientDashboard .search-btn {
    width: 100%;
    border-radius: 24px;
  }
  .PatientDashboard.sticky-header {
    padding-top: 0;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control {
    margin-bottom: 10px;
  }
  .PatientDashboard .filter .Input-control,
  .PatientDashboard .filter .SelectDate .Input-control {
    margin-bottom: 10px;
  }
  .PatientDashboard.sticky-header .filter .search-btn {
    width: 100%;
  }
  .PatientDashboard .doctor-list-wrapper .doctor-list-content {
    width: 100%;
  }
  .PatientDashboard .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 100%;
  }
  .PatientDashboard .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 350px;
  }
  .PatientDashboard .MyDatetime.days .rdtPicker .rdtSwitch:after {
    width: 93px;
  }
  .PatientDashboard .MyDatetime .rdtPicker .rdtDays table {
    border-spacing: 25px 10px;
  }
  .PatientDashboard .search-result .search-text {
    display: none;
  }
  .PatientDashboard .filter .Input-control .view-img {
    top: 25px;
    left: 16px;
    height: 22px;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control .view-img {
    top: 28px;
  }
  .PatientDashboard.sticky-header .filter {
    padding: 11px 30px 19px;
  }
  .PatientDashboard .filter .InsuranceFilterSelect {
    width: 100%;
  }
  .PatientDashboard .PatientHeader .header-content .logo A IMG {
    width: 128px;
    cursor: pointer;
  }
  .PatientDashboard .PatientHeader .container {
    padding: 0 30px;
  }
  .PatientDashboard .filter {
    height: 83px;
    overflow: hidden;
    padding: 11px 30px 11px;
    position: relative;
  }
  .PatientDashboard .filter .zipcode {
    visibility: hidden;
  }
  .PatientDashboard .filter.show-filter {
    height: 100%;
    overflow: visible;
  }
  .PatientDashboard .filter.show-filter .ElasticSearch {
    width: 100%;
  }
  .PatientDashboard .filter.show-filter .CircleButton.WhiteButton {
    width: 100%;
    display: block;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 1.12;
    letter-spacing: -0.34px;
    text-align: center;
    color: #ffffff;
    padding-top: 5px;
  }
  .PatientDashboard .search-result {
    padding: 20px 0px 22px;
    margin: 0px 30px;
    border-bottom: 1px solid #e8eceb;
  }
  .PatientDashboard .search-btn {
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 5px;
    line-height: 50px;
    background-color: #3d93a6;
  }
  .PatientDashboard .search-btn SPAN {
    font-family: 'Calibre Medium';
    font-size: 18px;
    display: inline-block;
    text-align: center;
    display: block;
    color: #ffffff;
  }
  .PatientDashboard .search-btn:enabled:hover SPAN {
    color: #ffffff;
  }
  .PatientDashboard .search-btn IMG,
  .PatientDashboard .search-btn IMG:hover,
  .PatientDashboard .search-btn:enabled:hover .mghover-icon {
    display: none;
  }
  .PatientDashboard .search-warp {
    position: absolute;
    width: 100%;
    height: 56px;
    top: 11px;
    left: 0;
  }
  .PatientDashboard .search-warp {
    display: block;
    z-index: 3;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control.form-group INPUT {
    height: 57px;
  }
  .PatientDashboard .filter.show-filter .zipcode {
    visibility: visible;
  }
  .PatientDashboard .filter.show-filter .search-warp {
    display: none;
  }
  .PatientDashboard .filter .Input-control.transform-input .form-control:focus,
  .PatientDashboard .filter .Input-control.transform-input.filled-focus-input .form-control {
    padding-top: 2px;
  }
  .PatientDashboard .rating-content-show .dc-review {
    position: static;
    display: flex;
    margin-left: auto;
  }
  .PatientDashboard .rating-content-show .reviwe-content .rating-text {
    top: 0;
  }
  .PatientDashboard .rating-content-show .DoctorItem .dc-review .reviwe-content .star {
    top: 22px;
  }
  .PatientDashboard .rating-content-show .DoctorItem .dc-review .reviwe-content .rating {
    top: 2px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT {
    font-size: 18px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
    line-height: 57px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }
}
@media (max-width: 413px) {
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 15px;
    padding-right: 15px;
  }
  .PatientDashboard .filter {
    padding: 11px 15px 11px;
  }
  .PatientDashboard .PatientHeader .container {
    padding: 0px 15px;
  }
  .PatientDashboard .search-result {
    margin: 0px 15px;
  }
  .PatientDashboard .doctor-list-wrapper .doctor-list-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .DoctorItem .table-content {
    margin-right: -15px;
  }
  .PatientDashboard .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 289px;
  }
  .PatientDashboard .MyDatetime.days .rdtPicker .rdtSwitch:after {
    width: 54px;
  }
  .PatientDashboard .MyDatetime .rdtPicker .rdtDays TABLE {
    border-spacing: 10px 10px;
  }
  .PatientDashboard.sticky-header .filter {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 375px) {
  .PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
    font-size: 44px;
  }
}
@supports (-webkit-touch-callout: none) {
  .PatientDashboard .search-btn SPAN {
    display: inline-block;
    padding-top: 2px;
  }
  .PatientDashboard .filter .Input-control INPUT.form-control::-webkit-input-placeholder {
    opacity: 1;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.34px;
    font-family: 'Calibre Regular';
  }
  .PatientDashboard .filter .SelectDate .form-control {
    padding-top: 2px;
  }
  .PatientDashboard .filter .Input-control .view-img {
    top: 26px;
  }
}

.RescheduleAppointmentModal {
  background-color: #fff;
  /* border: solid 1px #e2e2ea; */
  border-radius: 4px;
  overflow: hidden;
  width: 586px;
  max-width: 100%;
  margin: auto;
}
.RescheduleAppointmentModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.RescheduleAppointmentModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.RescheduleAppointmentModal .modal-content-header .close img {
  width: 100%;
}
.RescheduleAppointmentModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.RescheduleAppointmentModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.RescheduleAppointmentModal span.msg{
  color: #013648;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
}
.RescheduleAppointmentModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.RescheduleAppointmentModal .Divider {
  margin-top: 10px;
}

.RescheduleAppointmentModal .Divider .container {
  width: auto;
}
.RescheduleAppointmentModal .dc-appointment-time {
  padding-top: 40px;
}
.RescheduleAppointmentModal .timing-table {
  width: 100%;
}
.RescheduleAppointmentModal .table-wrapper {
  position: relative;
  display: inline-block;
  padding: 0px 53px 0px 52px;
}
.RescheduleAppointmentModal .table-wrapper .prev-link {
  position: absolute;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
.RescheduleAppointmentModal .table-wrapper .right-link {
  position: absolute;
  right: 0;
  top: -4px;
  transform: rotate(180deg);
  cursor: pointer;
}
.RescheduleAppointmentModal .table-wrapper .prev-link img,
.RescheduleAppointmentModal .table-wrapper .right-link img {
  width: 24px;
}
.RescheduleAppointmentModal .table-wrapper .prev-link.disabled img,
.RescheduleAppointmentModal .table-wrapper .right-link.disabled img {
  opacity: 0.21;
  cursor: not-allowed;
}
.RescheduleAppointmentModal .timing-table .tr {
  display: flex;
}
.RescheduleAppointmentModal .timing-table .tr {
  margin: 0px -5px;
}
.RescheduleAppointmentModal .timing-table .th {
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: center;
  width: 83px;
}
.RescheduleAppointmentModal .timing-table .th .date {
  display: block;
  font-family: 'Calibre Regular';
  font-weight: normal;
  text-align: center;
}
.RescheduleAppointmentModal .timing-table .tr .th:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .timing-table .td {
  padding: 4px 5px 4px;
}
.RescheduleAppointmentModal .timing-table .tr .td:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .timing-table .tr:last-child .td {
  padding-bottom: 0;
}
.RescheduleAppointmentModal .timing-table .td .time {
  font-size: 14px;
  line-height: 1.31;
  letter-spacing: normal;
  border-radius: 13.8px;
  padding: 6px 3px 2px;
  text-align: center;
  vertical-align: middle;
  border: 0.7px solid rgba(1, 54, 72, 0.1);
  color: #013648;
  min-width: 73px;
}
.RescheduleAppointmentModal .timing-table .td.available .time {
  color: #fff;
  border: 1px solid #013648;
  cursor: pointer;
  background-color: #013648
}
.RescheduleAppointmentModal .appointment-video .timing-table .td.available .time {
  color: #fff;
  border: 1px solid #6a3acd;
  cursor: pointer;
  background-color: #6a3acd
}
.RescheduleAppointmentModal .timing-table .td.booked .time {
  background-color: rgba(1, 54, 72, 0.1);
  border: 0.7px solid #013648;
  color: #013648;
  cursor: not-allowed;
}
.RescheduleAppointmentModal .day-table {
  width: 100%;
  border-spacing: 0;
}
.RescheduleAppointmentModal .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RescheduleAppointmentModal .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.RescheduleAppointmentModal .day-table .tr .td:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .day-table .tr:last-child .td {
  padding-bottom: 0;
}
.RescheduleAppointmentModal .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.RescheduleAppointmentModal .appointment-inner-block {
  margin-bottom: 46px;
}
.RescheduleAppointmentModal .appointments-day-block {
  padding-top: 10px;
}
.RescheduleAppointmentModal .appointment-inner-block:last-child {
  margin-bottom: 0;
}
.RescheduleAppointmentModal .dc-appointment-time .more-time {
  margin-top: 37px;
  cursor: pointer;
}
.RescheduleAppointmentModal .dc-appointment-time .more-time span {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: right;
  color: #26acda;
}

@media (hover: hover) {
  .RescheduleAppointmentModal .timing-table .td.available .time:hover {
    background-color: rgba(1,54,72,.1);
    color: #013648;
    border: 1px solid #013648;
  }
  .RescheduleAppointmentModal .appointment-video .timing-table .td.available .time:hover{
    background-color: #fff !important;
    color: #013648;
    border: 1px solid #013648;
  }
}

@media (max-width: 767px) {
  .RescheduleAppointmentModal {
    width: 100%;
  }
  .RescheduleAppointmentModal .DoctorAppointmentTime {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .RescheduleAppointmentModal .DoctorAppointmentTime .dc-info {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
  .RescheduleAppointmentModal .appointment-inner-block {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .RescheduleAppointmentModal .modal-content-header {
    padding-left: 20px;
  }
  .RescheduleAppointmentModal .modal-content-body {
    padding: 29px 20px;
  }
  .RescheduleAppointmentModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .RescheduleAppointmentModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .RescheduleAppointmentModal .modal-content-body {
    height: calc(100vh - 200px);
  }
  /* .RescheduleAppointmentModal .timing-table .td.available .time {
    background-color: #013648;
    border: 0px;
    color: #fff;
  } */
}

.PatientAccount {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 39px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientAccount .form-row .error-text {
  left: 12px;
}
.PatientAccount .optional-text {
  /* font-size: 14px;
  line-height: 16px;
  font-family: 'Calibre Regular'; */
  color: rgba(1, 54, 72, 0.4);
}
.PatientAccount .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 17px;
}
.PatientAccount .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 23px;
}
.PatientAccount .Select-control .arrow {
  right: 33px;
}
.PatientAccount .PatientAccount-block {
  margin-bottom: 32px;
}
.PatientAccount .Input-control.form-group {
  margin-bottom: 31px;
}
.PatientAccount .DateManual {
  margin-top: 23px;
}
.PatientAccount .Input-control.form-group .label {
  line-height: 1.5;
  margin-bottom: 1px;
}
.PatientAccount .form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.PatientAccount .form-row .SelectDate .label {
  line-height: 1.5;
}
.PatientAccount .form-row .column6 {
  width: 50%;
  padding: 0 12px;
}
.PatientAccount .form-row .column9 {
  padding: 0 12px;
  width: calc(100% - 132px);
}
.PatientAccount .form-row .column3 {
  padding: 0 12px;
  width: 132px;
}
.PatientAccount .pateintaccount-top {
  border-radius: 2px;
  background-color: #f1f1f1;
  padding: 11px 25px 7px;
  margin-bottom: 25px;
}
.PatientAccount .pateintaccount-top .heading2 {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #013648;
  margin-bottom: 5px;
}

.PatientAccount .pateintaccount-top .para {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.PatientAccount .pateintaccount-top .para .spanlink {
  color: #26acda;
}
.PatientAccount .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}
.PatientAccount .patientAccount-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.PatientAccount .patientAccount-button .deactive-link {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #f37184;
  cursor: pointer;
}

@media (hover: hover) {
  .PatientAccount .patientAccount-button .deactive-link:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1200px) {
  .PatientAccount {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientAccount .pateintaccount-top {
    padding: 11px 15px;
  }
  .PatientAccount .form-row .column6 {
    width: 100%;
  }
  .PatientAccount .form-row .column9 {
    width: 100%;
  }
  .PatientAccount .form-row .column3 {
    width: 100%;
  }
  .PatientAccount .Select-control .arrow {
    right: 21px;
  }
  .PatientAccount .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .PatientAccount .patientAccount-button .deactive-link {
    display: inline-block;
    margin-top: 6px;
    font-size: 16px;
  }
  .PatientAccount .CircleButton.WhiteButton{
    width: 90px;
  }
}

@supports (-webkit-touch-callout: none) {
  .PatientAccount .CircleButton.WhiteButton {
    padding-top: 2px;
  }
}

.PatientPassword {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 37px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientPassword .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 20px;
}
.PatientPassword .password-form {
  max-width: 398px;
  width: 100%;
}
.PatientPassword .Input-control.form-group {
  margin-bottom: 27px;
}
.PatientPassword .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 4px;
}
.PatientPassword .patientPassword-button {
  margin-top: 6px;
}
.PatientPassword .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}

@media screen and (max-width: 1200px) {
  .PatientPassword {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .PatientPassword .h3 {
    font-size: 20px;
  }
}

.PatientNotification {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 39px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientNotification .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 38px;
}
.PatientNotification .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 25px;
}
.PatientNotification .notification-block {
  margin-bottom: 46px;
}
.PatientNotification .notification-block:last-child {
  margin-bottom: 175px;
}
.PatientNotification .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Regular';
}

@media screen and (max-width: 1200px) {
  .PatientNotification {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientNotification .notification-block:last-child {
    margin-bottom: 75px;
  }
  .PatientNotification .h3 {
    font-size: 20px;
  }
}


.select-search {
    width: 100%;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

/**
 * Value wrapper
 */
.select-search__value {
    position: relative;
    z-index: 1;
}

.select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
}

/**
 * Input
 */
.select-search__input {
    display: block;
    height: 52px;
    width: 100%;
    padding: 0 40px 0 16px;
    background: #fff;
    border: solid 1.5px rgba(1, 54, 72, 0.2);
    /* box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15); */
    border-radius: 3px;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
}

.select-search__input:not([readonly]):focus {
    cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
}

/**
 * Options
 */
.select-search__options {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
    display: block;
    min-height: 36px;
    width: 100%;
    padding: 10px 16px;
    background: #fff;
    border: none;
    outline: none;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
}

.select-search--multiple .select-search__option {
    height: 48px;
}

.select-search__option.is-selected {
    background: #b0b0b0;
    color: #fff;
}
.Select-control{
    position: relative;
}
.Select-control .error-text {
    position: absolute;
    left: 0;
    bottom: -20px;
    color: red;
    font-size: 13px;
}
.Select-control.error .select-search input {
    border: 1px solid rgba(255,0,0,.4)
}
.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
    background: rgb(153, 161, 158);
    color: #fff;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
    background: #b0b0b0;
    color: #fff;
}

/**
 * Group
 */
.select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
    opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
}

.select-search--multiple .select-search__input {
    cursor: initial;
}

.select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
    border-color: rgb(40, 84, 99, 0.2);
}
.Select-control.error .select-search:not(.select-search--multiple) .select-search__input:hover {
    border: 1px solid rgba(255,0,0,.4)
}

.select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 54px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
}

.select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
}

.select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}

.PatientInsurance {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 40px 37px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientInsurance .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 48px;
}
.PatientInsurance .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 25px;
}
.PatientInsurance .PatientInsurance-block {
  margin-bottom: 25px;
}
.PatientInsurance .form-group {
  margin-bottom: 37px;
  padding: 0 12px;
}
.PatientInsurance .form-group.Upload {
  padding-left: 5px;
  margin-bottom: 0;
}
.PatientInsurance .form-group .label {
  line-height: 1.25;
  margin-bottom: 2px;
  display: block;
}
.PatientInsurance .Select-control .arrow{
  right: 33px;
}
.PatientInsurance .form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.PatientInsurance .form-row .column6 {
  width: 50%;
}
.PatientInsurance .form-row .column9 {
  padding: 0 12px;
  width: calc(100% - 132px);
}
.PatientInsurance .form-row .column3 {
  padding: 0 12px;
  width: 132px;
}
.PatientInsurance .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}
.PatientInsurance .form-group .light_text {
  color: rgba(1, 54, 72, 0.4);
}
.PatientInsurance .Input-control .error-text,
.PatientInsurance .Select-control .error-text{
  left: 15px;
}
.PatientInsurance .clear-btn{
display: block;
float: right;
cursor: pointer;
color: #26acda;
font-size: 17px;
}
@media screen and (max-width: 1200px) {
  .PatientInsurance {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientInsurance .form-row .column6,
  .PatientInsurance .form-row .column9,
  .PatientInsurance .form-row .column3 {
    width: 100%;
  }
  .PatientInsurance .Select-control .arrow{
    right: 21px;
  }
  .PatientInsurance .h3{
    font-size: 20px;
    margin-bottom: 22px;
  }
  .PatientInsurance .heading2{
    margin-bottom: 15px;
  }
}

.PatientDemographic {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 39px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientDemographic .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 48px;
}
.PatientDemographic .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 21px;
}
.PatientDemographic .heading2 .sub-label {
  font-size: 16px;
  line-height: 2;
  letter-spacing: normal;
  color: #013648;
  opacity: 0.4;
}
.PatientDemographic .demographic-container {
  margin-bottom: 37px;
}
.PatientDemographic .demographic-block {
  margin-bottom: 58px;
}
.PatientDemographic .demographic-block .Checkbox-control {
  margin-bottom: 20px;
  padding-left: 33px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #013648;
}
.PatientDemographic .demographic-block .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #26acda;
  border-color: #26acda;
}
.PatientDemographic .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 4px;
}
.PatientDemographic .two-input .light-txt {
  color: rgba(1, 54, 72, 0.4);
}
.PatientDemographic .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}

@media screen and (max-width: 1200px) {
  .PatientDemographic {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientDemographic .h3 {
    font-size: 20px;
    margin-bottom: 22px;
  }
  .PatientDemographic .demographic-block .Checkbox-control {
    margin-bottom: 14px;
  }
  .PatientDemographic .demographic-block {
    margin-bottom: 35px;
  }
}

.PatientDataPrivacy {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 79px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientDataPrivacy .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 44px;
  font-family: 'Calibre Semibold';
  font-weight: 600;
}
.PatientDataPrivacy .dataprivacy-block {
  margin-bottom: 73px;
}
.PatientDataPrivacy .dataprivacy-block .para {
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 23px;
}
.PatientDataPrivacy .dataprivacy-block .para:last-child {
  margin-bottom: 0;
}
.PatientDataPrivacy .CircleButton.WhiteButton {
  min-width: 193px;
  width: auto;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  background-color: #f37184;
  border-color: #f37184;
  color: #fff;
}
.deletemyaccount{
  margin-left: 5px;
}
@media (hover: hover) {
  .PatientDataPrivacy .CircleButton.WhiteButton:hover {
    background-color: transparent;
    color: #f37184;
  }
}

@media screen and (max-width: 1200px) {
  .PatientDataPrivacy {
    padding: 20px;
  }
}

@media screen and (max-width: 492px) {
  .deletemyaccount{
    margin-left: 0;
    margin-top: 10px;
  }
  .PatientDataPrivacy .CircleButton.WhiteButton{
    width: 100%;
  }
}

.PatientProfile .PatientHeader {
  height: 108px;
}
.PatientProfile .main-container {
  width: 100%;
  min-height: calc(100vh - 181px);
}
.PatientProfile .no-permission-text {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: rgba(38, 172, 218, 0.1);
  padding: 15px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #26acda;
}
.PatientProfile .AfterAuthSubHeader .main-inner-content {
  padding: 45px 43px 40px;
  padding-bottom: 30px;
}
.PatientProfile .setting-conettn {
  padding: 0px 40px;
}
.PatientProfile .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
}
.PatientProfile .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 46px;
  cursor: pointer;
  padding-bottom: 12px;
}
.PatientProfile .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.PatientProfile .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #26acda;
  position: relative;
}
.PatientProfile .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #26acda;
  border-radius: 4px 4px 0px 0px;
}
.PatientProfile .setting-tabs .tab-content {
  margin-bottom: 40px;
  margin-top: 50px;
  border-radius: 8px;
}
.PatientProfile {
  background-color: #f7f9f9;
  padding: 0px;
}
.PatientProfile .main-container {
  padding: 0px 125px;
}

@media screen and (max-width: 1400px) {
  .PatientProfile .main-container {
    padding: 0px 60px;
  }
  .PatientProfile .AfterAuthSubHeader .main-inner-content {
    padding: 30px 0;
  }
  .PatientProfile .setting-conettn {
    padding: 0;
  }
}
@media screen and (max-width: 1200px) {
  .PatientProfile .setting-tabs .tab-list-item {
    margin-right: 34px;
  }
  .PatientProfile .setting-tabs .tab-list-item:last-child {
    margin-right: 0;
  }
}
@media screen and (max-width: 1024px) {
  .PatientProfile .main-container {
    padding: 0 30px;
  }
  .PatientProfile .setting-tabs .tab-list-item {
    padding-bottom: 4px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1024px) {
  .PatientProfile .setting-tabs .tab-list-item {
    margin-right: 25px;
    font-size: 16px;
  }
}
@media screen and (max-width: 414px) {
  .PatientProfile .main-container {
    padding: 0 15px;
  }
}

.AdminLayoutHeader {
  background-color: #ffffff;
  height: 70px;
  display: flex;
  z-index: 8;
  padding: 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.AdminLayoutHeader > DIV {
  width: 100%;
}
.AdminLayoutHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.AdminLayoutHeader .header-content .right-col A {
  color: #013648;
}
.AdminLayoutHeader .header-content .right-col SPAN {
  display: inline-block;
  padding: 0px 0px;
  position: relative;
}
.AdminLayoutHeader .header-content .right-col SPAN:last-child {
  padding-right: 0px;
}
.AdminLayoutHeader .header-content .logo {
  width: 111px;
}
.AdminLayoutHeader .header-content .logo A IMG {
  width: 100%;
  cursor: pointer;
}
.AdminLayoutHeader .header-content .logo A {
  position: relative;
  top: 5px;
  display: inline-block;
}
.AdminLayoutHeader .mobile-menu {
  display: none;
}
.AdminLayoutHeader .header-content .right-col .user .user-content > DIV {
  display: flex;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-pic {
  width: 27px;
  height: 41px;
  margin-right: 16px;
  display: inline-block;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-pic IMG {
  width: 100%;
  border-radius: 2.5px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .staff-default-img {
  width: 27px;
  height: 41px;
  margin-right: 16px;
  display: inline-block;
  border-radius: 2.5px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  padding: 8px 3px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .staff-default-img IMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-post {
  opacity: 0.4;
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #013648;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name {
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.4;
  color: #013648;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info SPAN {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info {
  position: relative;
  padding-right: 60px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info .arrowPointer {
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: 0.3s all linear;
  background-color: #ffffff;
  min-width: 300px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 16px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.email IMG {
  width: 20px;
  position: absolute;
  left: 20px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.email {
  color: #3dbce7;
  padding-left: 47px;
  position: relative;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.logout IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.logout {
  padding-left: 47px;
  position: relative;
}
.AdminLayoutHeader
  .header-content
  .right-col
  .user
  .user-content
  .dropdown-menu
  SPAN.change-pwd
  IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.change-pwd {
  padding-left: 47px;
  position: relative;
}
.AdminLayoutHeader .header-content .right-col .user .user-content {
  position: relative;
}
@media (hover: hover) {
  .AdminLayoutHeader .header-content .right-col A:hover {
    text-decoration: none;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
@media (max-width: 1024px) {
  .AdminLayoutHeader {
    padding: 0 10px;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content > DIV {
    display: flex;
  }
}
@media (max-width: 767px) {
  .AdminLayoutHeader .header-content {
    padding-left: 35px;
  }
  .AdminLayoutHeader .mobile-menu {
    width: 20px;
    height: 18px;
    position: absolute;
    top: 22px;
    cursor: pointer;
    display: block;
    left: 15px;
  }
  .AdminLayoutHeader .mobile-menu SPAN {
    width: 20px;
    display: inline-block;
    height: 2px;
    background-color: #013648;
    position: absolute;
    transition: 0.3s all linear;
  }
  .AdminLayoutHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .AdminLayoutHeader .mobile-menu SPAN:nth-child(2) {
    top: 7px;
  }
  .AdminLayoutHeader .mobile-menu SPAN:last-child {
    top: 14px;
    width: 10px;
  }
  .AdminLayoutHeader.menu-active .right-col {
    right: 0px;
  }
  .AdminLayoutHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .AdminLayoutHeader.menu-active .mobile-menu SPAN {
    background-color: #013648;
    transform: rotate(45deg);
  }
  .AdminLayoutHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
  }
  .AdminLayoutHeader.menu-active .mobile-menu {
    position: fixed;
    left: 15px;
    top: 30px;
    z-index: 2;
  }
  .AdminLayoutHeader .header-content .right-col SPAN,
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name {
    line-height: 1;
  }
  @media (hover: hover) {
    .AdminLayoutHeader .mobile-menu:hover SPAN:last-child {
      width: 20px;
    }
  }
}
@media (max-width: 414px) {
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name {
    font-size: 16px;
  }

  .AdminLayoutHeader .header-content .right-col .user .user-content .user-pic {
    margin-left: 8px;
    margin-right: 8px;
  }
  .AdminLayoutHeader .header-content .logo {
    min-width: 98px;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name,
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-post {
    width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info {
    padding-right: 25px;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .arrowPointer {
    right: 7px;
  }
}

.Sidebar {
  min-width: 250px;
  margin-top: 1px;
}
.Sidebar .Sidebar-inner-content {
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
  width: 250px;
  padding: 20px;
  overflow: auto;
  z-index: 3;
  background-color: #fff;
  border-top: 1px solid #f9f9f9;
  -webkit-overflow-scrolling: touch;
}
.Sidebar .medical-content {
  margin-top: 15px;
}
.Sidebar .medical-content .medical-text {
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 2px;
  color: #72787d;
  text-transform: uppercase;
  margin-bottom: 21px;
}
.Sidebar .medical-content .medical-name {
  position: relative;
  padding-left: 42px;
  font-family: 'Calibre Regular';
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 30px;
}
.Sidebar .medical-content .medical-name .medical-logo {
  width: 32px;
  height: 32px;
  position: absolute;
  left: 0;
  top: -3px;
  border: none;
  border-radius: 7px;
}
.Sidebar .medical-content .medical-name img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #c6c6c6;
}
.Sidebar .menu {
  margin-top: 20px;
  margin-bottom: 130px;
  margin-left: -20px;
  margin-right: -20px;
}
.Sidebar .viewprofile {
  opacity: 0.4;
  color: #013648;
  position: absolute;
  bottom: 11px;
  left: 42px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .Sidebar {
    min-width: 200px;
  }
  .Sidebar .Sidebar-inner-content {
    width: 200px;
    padding: 20px 10px;
  }
  .Sidebar .medical-content .medical-name {
    font-size: 18px;
  }
  .MenuItem a {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .Sidebar {
    position: fixed;
    top: 70px;
    bottom: 0;
    left: -200px;
    transition: 0.3s all linear;
    z-index: 8;
    margin-top: 0;
  }
  .Sidebar .Sidebar-inner-content {
    position: static;
    height: calc(100vh - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.AdminLayout .wrap-content-row {
  display: flex;
  padding-top: 70px;
  background-color: #fafafb;
  min-height: 100vh;
}
.AdminLayout .main-content .main-inner-content {
  padding: 34px 40px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.AdminLayout .search INPUT {
  padding: 3px 15px 0px 25px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.AdminLayout .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.AdminLayout .search BUTTON:focus {
  outline: 0;
}
.AdminLayout .table-overflow {
  overflow: auto;
  width: 100%;
  box-shadow: 0px 0px 4px 0px #cacaca;
}
.AdminLayout .admin-provider-list TD {
  padding: 15px 15px 12px;
}
.AdminLayout .admin-provider-list TR {
  border-bottom: 1px solid #d8d8d8;
}

@media screen and (max-width: 768px) {
  .AdminLayout .AdminPatients,
  .AdminLayout .AdminJoinUs,
  .AdminLayout .ImportProvider,
  .AdminLayout .Configuration,
  .AdminLayout .Languages,
  .AdminLayout .Suffix,
  .AdminLayout .InsuranceCompany,
  .AdminLayout .InsurancePlans,
  .AdminLayout .GhostProfiles,
  .AdminLayout .AdminProviders{
    width: 100%;
  }
  .AdminLayout .AdminPatients .dashboard-panle,
  .AdminLayout .AdminJoinUs .dashboard-panle,
  .AdminLayout .ImportProvider .dashboard-panle,
  .AdminLayout .Configuration .dashboard-panle,
  .AdminLayout .Languages .dashboard-panle,
  .AdminLayout .dashboard-panle .dashboard-panle,
  .AdminLayout .InsuranceCompany .dashboard-panle,
  .AdminLayout .InsurancePlans .dashboard-panle,
  .AdminLayout .GhostProfiles .dashboard-panle,
  .AdminLayout .AdminProviders .dashboard-panle{
    padding-left: 20px;
  }
  .AdminLayout .AdminPatients .main-content .main-inner-content,
  .AdminLayout .AdminJoinUs .main-content .main-inner-content,
  .AdminLayout .ImportProvider .main-content .main-inner-content,
  .AdminLayout .Configuration .main-content .main-inner-content,
  .AdminLayout .Languages .main-content .main-inner-content,
  .AdminLayout .Suffix .main-content .main-inner-content,
  .AdminLayout .InsuranceCompany .main-content .main-inner-content,
  .AdminLayout .InsurancePlans .main-content .main-inner-content,
  .AdminLayout .GhostProfiles .main-content .main-inner-content,
  .AdminLayout .AdminProviders .main-content .main-inner-content{
    padding: 34px 20px 12px;
  }
}
.ReviewStatus {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
}
.ReviewStatus .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.ReviewStatus .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.ReviewStatus .cross-icon IMG {
  width: 100%;
}
.ReviewStatus .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.ReviewStatus .total {
  font-size: 22px;
  line-height: 1.5;
  color: #013648;
  padding-bottom: 10px;
}
.ReviewStatus .heading2 {
  font-size: 18px;
  line-height: 1.2;
  color: #26acda;
  cursor: pointer;
}
.ReviewStatus .h3 {
  font-size: 16px;
  line-height: 1.2;
  color: #013648;
}
.ReviewStatus .review-source {
  padding: 10px 0px;
  border-bottom: solid 1px #e9ebf2;
  font-size: 16px;
}
.ReviewStatus .review-source:last-child {
  border-bottom: none;
}
.ReviewStatus .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.ReviewStatus .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.ReviewStatus .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.ReviewStatus .dailog-body {
  padding: 20px 32px 15px;
}
.ReviewStatus .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.ReviewStatus .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.ReviewStatus .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.ReviewStatus .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.ReviewStatus .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.ReviewStatus .action-btn {
  text-align: right;
}
.ReviewStatus .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.ReviewStatus .pending {
  font-size: 13px;
  color: #f2962d;
}
.ReviewStatus .approved {
  font-size: 13px;
  color: #3d93a6;
}
.ReviewStatus .rejected {
  font-size: 13px;
  color: #e85d72;
}
.ReviewStatus .btn {
  margin-right: 15px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ReviewStatus .btn.btn-approve {
  background-color: #3d93a6;
  border: none;
}
.ReviewStatus .btn.btn-reject {
  background-color: #e85d72;
  border: none;
}
.ReviewStatus .btn-refresh {
  margin-right: 43px;
  padding: 5px 10px 0;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  cursor: pointer;
  outline: 0;
  height: 30px;
}

.AdminProviders .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.AdminProviders .main-content .main-inner-content {
  padding: 34px 40px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.AdminProviders {
  width: calc(100% - 250px);
}

.AdminProviders .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
.AdminProviders .search INPUT {
  padding: 3px 15px 0px 25px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.AdminProviders .search DIV {
  position: relative;
}
.AdminProviders .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.AdminProviders .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.AdminProviders .search .Select-control SELECT {
  height: 40px;
}
.AdminProviders .Select-control.form-group:after {
  display: none;
}
.AdminProviders .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
.AdminProviders .search BUTTON:focus {
  outline: 0;
}
.AdminProviders .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.AdminProviders .search button.btn-clear IMG {
  width: 15px;
}
.AdminProviders .table-overflow {
  overflow: auto;
  width: 100%;
  box-shadow: 0px 0px 4px 0px #cacaca;
}
.AdminProviders .admin-provider-list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}
.AdminProviders .admin-provider-list TD,
.AdminProviders .admin-provider-list TH {
  border: 1px solid black;
}
.AdminProviders .admin-provider-list TD {
  padding: 15px 15px 12px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border: 0;
}
.AdminProviders .admin-provider-list TD:first-child,
.AdminProviders .admin-provider-list thead TD:nth-child(4) {
  min-width: 150px;
}
.AdminProviders .admin-provider-list TR {
  border-bottom: 1px solid #d8d8d8;
}
.AdminProviders .admin-provider-list thead TD {
  background-color: #013648;
  color: white;
  border: 0;
}

.AdminProviders .no-record {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}

.AdminProviders .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.AdminProviders .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.AdminProviders .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.AdminProviders .pagination LI.active {
  background-color: #013648;
  color: white;
}
.AdminProviders .pagination LI.previous.disabled,
.AdminProviders .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.AdminProviders .pagination LI:hover:not(.active) {
  background-color: #ddd;
}

.AdminProviders .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.AdminProviders .specialties-span{
display: flex;
align-items: flex-start;
}
.AdminProviders .specialties-span .custom-specialities{
  width:20px;
  margin-left:10px;
  cursor: pointer;

}
.AdminProviders .pointer {
  cursor: pointer;
}
.AdminProviders .admin-provider-list TD .sort-icon {
  margin-left: 10px;
  position: relative;
}
.AdminProviders .admin-provider-list TD .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.AdminProviders .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.AdminProviders .btn-deactivate {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.AdminProviders .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin-top: 15px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.AdminProviders .dataCount {
  font-size: 16px;
  margin-top: 12px;
  /* top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left; */
}

.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 1030;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  user-select: text;
  width: 232px;
  
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  box-shadow: 0 0 8px #00000026;
  -webkit-clip-path: polygon(0% 0%,95% 0%,95% 50%,100% 55%,95% 60%,95% 60%,95% 100%,0% 100%);
          clip-path: polygon(0% 0%,95% 0%,95% 50%,100% 55%,95% 60%,95% 60%,95% 100%,0% 100%);
  background-color: #f3f3fb;
 
}
.shadow{
  position: absolute;
  z-index: -1;
  content: "";
  background: rgb(0 0 0 / 12%);
  width: 102%;
  height: 104%;
  left: -1px;
  top: 0px;
  -webkit-clip-path: polygon(0% 0%,95% 0%,95% 50%,100% 55%,95% 60%,95% 60%,95% 100%,0% 100%);
          clip-path: polygon(0% 0%,95% 0%,95% 50%,100% 55%,95% 60%,95% 60%,95% 100%,0% 100%);
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: #000000d9;
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #000000d9;
  font-size: 14px;
}

.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
} 
.ant-popover-buttons button {
  margin-left: 8px !important;
  cursor: pointer;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn>span {
  display: inline-block;
}
.show{
  display: inline-block;
}
.hide{
  display: none;
}
.delete-confirm-img{
  position: absolute;
  width: 40px;
  top: 20px;
  cursor: pointer;
}
.ant-btn-primary {
  color: #fff;
  border-color: #3d93a6;
  background: #3d93a6;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px #0000000b;
}
.ant-btn-danger {
  border-color: #f37184;
  background: #f37184;
  color: #fff;
}
.pdf-viewer{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00000047;
  z-index: 5000;
  text-align: center;
}
.pdf-viewer .viewer{
  margin: 0 auto;
  width: 80vw;
  margin-top: 0px;
}
.pdf-viewer .viewer iframe{
  width: 100%;
  border: none;
  min-height: 80vh;
}
.pdf-viewer .top-control{
  width: 100%;
    height: 50px;
}
.pdf-viewer .top-control .close-btn{
  float: right;
  background: transparent;
  color: white;
  border: none;
  font-size: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.AdminPatients .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.AdminPatients {
  width: calc(100% - 250px);
}
.AdminPatients .main-content .main-inner-content {
  padding: 34px 40px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.AdminPatients .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
.AdminPatients .search INPUT {
  padding: 3px 28px 0px 20px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.AdminPatients .search DIV {
  position: relative;
}
.AdminPatients .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.AdminPatients .search BUTTON:focus {
  outline: 0;
}
.AdminPatients .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.AdminPatients .search button.btn-clear IMG {
  width: 15px;
}
.AdminPatients .admin-provider-list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}
.AdminPatients .admin-provider-list TD,
.AdminPatients .admin-provider-list TH {
  border: 1px solid black;
}
.AdminPatients .table-overflow {
  overflow: auto;
  width: 100%;
  box-shadow: 0px 0px 4px 0px #cacaca;
}
.AdminPatients .admin-provider-list TD {
  padding: 15px 15px 12px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border: 0;
}
.AdminPatients .admin-provider-list TR {
  border-bottom: 1px solid #d8d8d8;
}
.AdminPatients .admin-provider-list thead TD {
  background-color: #013648;
  color: white;
}
.AdminPatients .no-record {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}
.AdminPatients .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.AdminPatients .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.AdminPatients .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.AdminPatients .pagination LI.active {
  background-color: #013648;
  color: white;
}
.AdminPatients .pagination LI.previous.disabled,
.AdminPatients .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.AdminPatients .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.AdminPatients .pointer {
  cursor: pointer;
}
.AdminPatients .admin-provider-list TD .sort-icon {
  margin-left: 10px;
  position: relative;
}
.AdminPatients .admin-provider-list TD .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.AdminPatients .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.AdminJoinUs .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.AdminJoinUs {
  width: calc(100% - 250px);
}

.AdminJoinUs .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
.filenameSpan{
  display:block;
    width:100px;
    word-wrap:break-word;
}
.AdminJoinUs .photoid{
  width: 36px;
  height: 23px;
  top: 5px;
  position: relative;
  cursor: pointer;
}
.AdminJoinUs .actionbtndev{
  display: grid
}
.AdminJoinUs .actionbtndev button:first-child{
margin-left: 0;
}
.viewPhotoIdmodal{
width: 100% !important;
}
/* .AdminJoinUs .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.AdminJoinUs .search div {
  position: relative;
}
/* .AdminJoinUs .search button {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.AdminJoinUs .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.AdminJoinUs .search button.btn-clear IMG {
  width: 15px;
}
.AdminJoinUs .admin-provider-list thead td:nth-child(4) {
  min-width: 150px;
}

.AdminJoinUs .admin-provider-list thead td:last-child {
  min-width: 146px;
}
.AdminJoinUs.approvedJoinUs .admin-provider-list thead td:last-child {
  min-width: auto;
}
.AdminJoinUs.approvedJoinUs .admin-provider-list thead td:nth-last-child(2) {
  min-width: 146px;
}
.AdminJoinUs .admin-provider-list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}
/* .AdminJoinUs .admin-provider-list td,
.AdminJoinUs .admin-provider-list th {
  border: 1px solid black;
} */
.AdminJoinUs .admin-provider-list td {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
}
.AdminJoinUs .admin-provider-list thead td {
  background-color: #013648;
  color: white;
}

.AdminJoinUs .no-record {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}

.AdminJoinUs .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.AdminJoinUs .pagination li {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 8px 0px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.AdminJoinUs .pagination li a {
  padding: 8px 16px;
}
.AdminJoinUs .pagination li.active {
  background-color: #013648;
  color: white;
}
.AdminJoinUs .pagination li.previous.disabled,
.AdminJoinUs .pagination li.next.disabled {
  opacity: 0;
  cursor: unset;
}
.AdminJoinUs .pagination li:hover:not(.active) {
  background-color: #ddd;
}
.AdminJoinUs .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.AdminJoinUs .btn.btn-approve {
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.AdminJoinUs .btn.btn-reject {
  background-color: #f37184;
  border: none;
  margin: 0;
    margin-bottom: 5px;
}
.AdminJoinUs .btn.btn-waiting {
  background-color: #f5ab0c;
  border: none;
  margin: 0;
    margin-bottom: 5px;
}
.AdminJoinUs .pointer {
  cursor: pointer;
}
.AdminJoinUs .admin-provider-list td .sort-icon {
  margin-left: 10px;
  position: relative;
}
.AdminJoinUs .admin-provider-list td .sort-icon img {
  width: 15px;
  top: -1px;
  position: absolute;
}
.AdminJoinUs .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.Upload-newFile {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  float: right;
  margin-left: 10px;
  border: 0;
}
.Upload-newFile[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}
.Upload-newFile:hover {
  opacity: 0.8;
}

 .admin-uploadfile .Input-control.form-group .fileupload {
  width: 100%;
  height: 60px;
  background: #fff;
  box-sizing: border-box;
}
.admin-uploadfile .fileupload {
  min-height: 52px;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  padding: 5px 142px 5px 20px;
  word-break: break-all;
  border: 1.5px solid rgba(1,54,72,.2);
}
.admin-uploadfile .fileupload .preview-img {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
.admin-uploadfile .fileupload .preview-img-multy {
  grid-template-columns: 55px 1fr;
}
.admin-uploadfile .fileupload .preview-img img {
  max-width: 50px;
  max-height: 33px;
}
.admin-uploadfile .fileupload .preview-img span {
  position: relative;
  display: block;
  top: 2px;
  overflow: hidden;
}
.admin-uploadfile .fileupload .preview-img span {
  position: relative;
  display: block;
  top: 2px;
  overflow: hidden;
}
.admin-uploadfile .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}
.admin-uploadfile .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.admin-uploadfile .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: Calibre Medium;
  font-weight: 500;
  padding-top: 4px;
  background-color: #809ba4;
}
 .react-viewer-toolbar{
display: none;
}
.ImportProvider .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ImportProvider {
  width: calc(100% - 250px);
}
.ImportProvider .fileupload {
  position: relative;
  margin-right: 9px;
  margin-bottom: 5px;
  margin-top: 20px;
}
.ImportProvider .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.ImportProvider .fileupload .fileupload-btn button:focus {
  outline: 0;
}

.ImportProvider .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.ImportProvider .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
  width: 161px;
}
.ImportProvider .note {
  padding: 5px;
}
.ImportProvider .note a {
  color: #26acda;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.ImportProvider .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.ImportProvider .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.ImportProvider .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}

.Configuration .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Configuration {
  width: calc(100% - 250px);
}
.Configuration .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.Configuration .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Configuration .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Configuration .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.Configuration .row .cell:nth-child(2) {
  padding-right: 30px;
}
.Configuration .heading .cell {
  background-color: #013648;
  color: white;
}
.Configuration .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Configuration .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Configuration .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Configuration .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Configuration .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Configuration .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Configuration .pagination LI.previous.disabled,
.Configuration .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Configuration .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Configuration .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Configuration .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Configuration .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Configuration .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Configuration .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Configuration .pointer {
  cursor: pointer;
}
.Configuration .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Configuration .img-preview {
  max-width: 250px;
}
.Configuration .edit-span {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.Configuration .edit-span IMG {
  width: 18px;
}
.Configuration .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .Configuration .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Configuration .search DIV {
  position: relative;
}
/* .Configuration .search button {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Configuration .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Configuration .search button.btn-clear IMG {
  width: 15px;
}
.Configuration .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.UpdateConfigModal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.UpdateConfigModal .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.UpdateConfigModal .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.UpdateConfigModal .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.UpdateConfigModal .cross-icon IMG {
  width: 100%;
}
.UpdateConfigModal .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.UpdateConfigModal .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.UpdateConfigModal .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.UpdateConfigModal .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.UpdateConfigModal .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.UpdateConfigModal .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.UpdateConfigModal .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.UpdateConfigModal .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.UpdateConfigModal .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.UpdateConfigModal .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.UpdateConfigModal .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.UpdateConfigModal .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.UpdateConfigModal .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.UpdateConfigModal .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.UpdateConfigModal .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.UpdateConfigModal .input-close {
  position: relative;
}
.UpdateConfigModal .input-close IMG {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.UpdateConfigModal .img-preview {
  max-width: 100%;
}
.UpdateConfigModal .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
.UpdateConfigModal .fileupload {
  position: relative;
  margin-bottom: 5px;
}
.UpdateConfigModal .fileupload .fileupload-btn BUTTON {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.UpdateConfigModal .fileupload .fileupload-btn button:focus {
  outline: 0;
}
.UpdateConfigModal .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.UpdateConfigModal .fileupload .fileupload-btn INPUT {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}

@media (hover: hover) {
  .UpdateConfigModal .fileupload .fileupload-btn:hover BUTTON {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .UpdateConfigModal .fileupload .fileupload-btn BUTTON {
    width: 148px;
  }
}
@media screen and (max-width: 1200px) {
  .UpdateConfigModal .fileupload .fileupload-btn BUTTON {
    width: 140px;
  }
}

@supports (-webkit-touch-callout: none) {
  .UpdateConfigModal .fileupload .fileupload-btn BUTTON {
    padding-top: 2px;
  }
}

.EditLanguages {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.EditLanguages .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditLanguages .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditLanguages .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditLanguages .cross-icon img {
  width: 100%;
}
.EditLanguages .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditLanguages .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditLanguages .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditLanguages .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditLanguages .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditLanguages .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditLanguages .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditLanguages .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditLanguages .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.EditLanguages .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.EditLanguages .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.EditLanguages .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.EditLanguages .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.EditLanguages .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.EditLanguages .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.EditLanguages .input-close {
  position: relative;
}
.EditLanguages .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.EditLanguages .img-preview {
  max-width: 100%;
}
.EditLanguages .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
.EditLanguages .fileupload {
  position: relative;
  margin-bottom: 5px;
}
.EditLanguages .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.EditLanguages .fileupload .fileupload-btn button:focus {
  outline: 0;
}
.EditLanguages .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.EditLanguages .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}

@media (hover: hover) {
  .EditLanguages .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .EditLanguages .fileupload .fileupload-btn button {
    width: 148px;
  }
}
@media screen and (max-width: 1200px) {
  .EditLanguages .fileupload .fileupload-btn button {
    width: 140px;
  }
}

@supports (-webkit-touch-callout: none) {
  .EditLanguages .fileupload .fileupload-btn button {
    padding-top: 2px;
  }
}

.Languages .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Languages {
  width: calc(100% - 250px);
}
.Languages .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.Languages .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Languages .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Languages .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.Languages .heading .cell {
  background-color: #013648;
  color: white;
}
.Languages .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Languages .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Languages .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.Languages .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Languages .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Languages .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Languages .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Languages .pagination LI.previous.disabled,
.Languages .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Languages .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Languages .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Languages .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Languages .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Languages .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Languages .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Languages .pointer {
  cursor: pointer;
}
.Languages img.edit-icon {
  width: 18px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Languages .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .Languages .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Languages .search div {
  position: relative;
}
/* .Languages .search button {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Languages .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Languages .search button.btn-clear IMG {
  width: 15px;
}
.Languages .btn-add-new {
  padding: 9px 20px 6px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  border: 0;
  height: 40px;
}
.Languages .btn-add-new:focus {
  outline: 0;
}
.Languages .dataCount {
  font-size: 16px;
}

.Certification .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Certification {
  width: calc(100% - 250px);
}
.Certification .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0;
  width: 100%;
}
.Certification .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Certification .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Certification .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Certification .heading .cell {
  background-color: #013648;
  color: white;
}
.Certification .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Certification .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Certification .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Certification .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Certification .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Certification .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Certification .pagination LI.previous.disabled,
.Certification .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Certification .pagination li:hover:not(.active) {
  background-color: #ddd;
}
.Certification .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Certification .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Certification .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Certification .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Certification .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Certification .pointer {
  cursor: pointer;
}
.Certification .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.Certification .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .Certification .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Certification .search DIV {
  position: relative;
}
/* .Certification .search button {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Certification .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Certification .search button.btn-clear IMG {
  width: 15px;
}
.Certification .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.Certification IMG.edit-icon {
  width: 18px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Certification .dataCount {
  font-size: 16px;
}

.EditCertification .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditCertification .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditCertification .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditCertification .cross-icon img {
  width: 100%;
}
.EditCertification .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditCertification .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditCertification .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditCertification .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditCertification .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditCertification .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditCertification .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditCertification .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditCertification .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}

.Suffix .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Suffix {
  width: calc(100% - 250px);
}
.Suffix .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.Suffix .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Suffix .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Suffix .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Suffix .heading .cell {
  background-color: #013648;
  color: white;
}
.Suffix .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Suffix .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Suffix .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.Suffix .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Suffix .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Suffix .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Suffix .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Suffix .pagination LI.previous.disabled,
.Suffix .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Suffix .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Suffix .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Suffix .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Suffix .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Suffix .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Suffix .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Suffix .pointer {
  cursor: pointer;
}
.Suffix img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Suffix .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .Suffix .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Suffix .search div {
  position: relative;
}
/* .Suffix .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Suffix .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Suffix .search button.btn-clear IMG {
  width: 15px;
}
.Suffix .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.Suffix .btn-add-new:focus {
  outline: 0;
}
.Suffix .dataCount {
  font-size: 16px;
}

.UpdateSuffixModal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.UpdateSuffixModal .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.UpdateSuffixModal .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.UpdateSuffixModal .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.UpdateSuffixModal .cross-icon img {
  width: 100%;
}
.UpdateSuffixModal .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.UpdateSuffixModal .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.UpdateSuffixModal .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.UpdateSuffixModal .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.UpdateSuffixModal .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.UpdateSuffixModal .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.UpdateSuffixModal .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.UpdateSuffixModal .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.UpdateSuffixModal .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.UpdateSuffixModal .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.UpdateSuffixModal .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.UpdateSuffixModal .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.UpdateSuffixModal .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.UpdateSuffixModal .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.UpdateSuffixModal .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.UpdateSuffixModal .input-close {
  position: relative;
}
.UpdateSuffixModal .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.UpdateSuffixModal .img-preview {
  max-width: 100%;
}
.UpdateSuffixModal .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
.UpdateSuffixModal .fileupload {
  position: relative;
  margin-bottom: 5px;
}
.UpdateSuffixModal .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.UpdateSuffixModal .fileupload .fileupload-btn button:focus {
  outline: 0;
}
.UpdateSuffixModal .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.UpdateSuffixModal .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}

@media (hover: hover) {
  .UpdateSuffixModal .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .UpdateSuffixModal .fileupload .fileupload-btn button {
    width: 148px;
  }
}
@media screen and (max-width: 1200px) {
  .UpdateSuffixModal .fileupload .fileupload-btn button {
    width: 140px;
  }
}

@supports (-webkit-touch-callout: none) {
  .UpdateSuffixModal .fileupload .fileupload-btn button {
    padding-top: 2px;
  }
}

.InsuranceCompany .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.InsuranceCompany {
  width: calc(100% - 250px);
}
.InsuranceCompany .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.InsuranceCompany .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.InsuranceCompany .row {
  display: table-row;
  width: auto;
  clear: both;
}
.InsuranceCompany .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.InsuranceCompany .heading .cell {
  background-color: #013648;
  color: white;
}
.InsuranceCompany .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.InsuranceCompany .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.InsuranceCompany .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.InsuranceCompany .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.InsuranceCompany .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.InsuranceCompany .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.InsuranceCompany .pagination LI.active {
  background-color: #013648;
  color: white;
}
.InsuranceCompany .pagination LI.previous.disabled,
.InsuranceCompany .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.InsuranceCompany .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.InsuranceCompany .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.InsuranceCompany .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.InsuranceCompany .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.InsuranceCompany .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.InsuranceCompany .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.InsuranceCompany .pointer {
  cursor: pointer;
}
.InsuranceCompany IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.InsuranceCompany .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .InsuranceCompany .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.InsuranceCompany .search DIV {
  position: relative;
}
/* .InsuranceCompany .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.InsuranceCompany .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.InsuranceCompany .search button.btn-clear IMG {
  width: 15px;
}
.InsuranceCompany .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.InsuranceCompany .dataCount {
  font-size: 16px;
}

.EditCompany {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.EditCompany .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditCompany .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditCompany .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditCompany .cross-icon img {
  width: 100%;
}
.EditCompany .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditCompany .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditCompany .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditCompany .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditCompany .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditCompany .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditCompany .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditCompany .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditCompany .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.EditCompany .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.EditCompany .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.EditCompany .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.EditCompany .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.EditCompany .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.EditCompany .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.EditCompany .input-close {
  position: relative;
}
.EditCompany .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.EditCompany .img-preview {
  max-width: 100%;
}
.EditCompany .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}

.EditPlan {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.EditPlan .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditPlan .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditPlan .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditPlan .cross-icon img {
  width: 100%;
}
.EditPlan .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditPlan .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditPlan .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditPlan .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditPlan .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditPlan .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditPlan .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditPlan .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditPlan .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.EditPlan .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.EditPlan .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.EditPlan .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.EditPlan .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.EditPlan .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.EditPlan .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.EditPlan .input-close {
  position: relative;
}
.EditPlan .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.EditPlan .img-preview {
  max-width: 100%;
}
.EditPlan .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}

.InsurancePlans .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.InsurancePlans {
  width: calc(100% - 250px);
}
.InsurancePlans .rtable {
  display: table;
  background-color: white;
  border: 0px;
  width: 100%;
}
.InsurancePlans .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.InsurancePlans .row {
  display: table-row;
  width: auto;
  clear: both;
}
.InsurancePlans .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.InsurancePlans .heading .cell {
  background-color: #013648;
  color: white;
}
.InsurancePlans .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.InsurancePlans .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.InsurancePlans .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.InsurancePlans .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.InsurancePlans .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.InsurancePlans .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.InsurancePlans .pagination LI.active {
  background-color: #013648;
  color: white;
}
.InsurancePlans .pagination LI.previous.disabled,
.InsurancePlans .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.InsurancePlans .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.InsurancePlans .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.InsurancePlans .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.InsurancePlans .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.InsurancePlans .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.InsurancePlans .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.InsurancePlans .pointer {
  cursor: pointer;
}
.InsurancePlans IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.InsurancePlans .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .InsurancePlans .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.InsurancePlans .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.InsurancePlans .search .Select-control SELECT {
  height: 40px;
}
/* .InsurancePlans .input-control INPUT {
  height: 52px;
} */
.InsurancePlans .Select-control.form-group:after {
  display: none;
}
.InsurancePlans .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .InsurancePlans .search BUTTON {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.InsurancePlans .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.InsurancePlans .search button.btn-clear IMG {
  width: 15px;
}
.InsurancePlans .search DIV {
  position: relative;
}
.InsurancePlans .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.InsurancePlans .dataCount {
  font-size: 16px;
}

.GhostProfiles .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.GhostProfiles {
  width: calc(100% - 250px);
}
.GhostProfiles .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.GhostProfiles .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.GhostProfiles .row {
  display: table-row;
  width: auto;
  clear: both;
}
.GhostProfiles .deleted-row {
  display: table-row;
  width: auto;
  clear: both;
  background-color: #f8e0e0;
}
.GhostProfiles .ghostCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.GhostProfiles .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: transparent;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.GhostProfiles .heading .cell {
  background-color: #013648;
  color: white;
}
.GhostProfiles .heading .cell:first-child {
  min-width: 150px;
}
.GhostProfiles .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.GhostProfiles .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.GhostProfiles .cell.edit-cell {
  width: 20px;
}
.GhostProfiles .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.GhostProfiles .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.GhostProfiles .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.GhostProfiles .pagination LI.active {
  background-color: #013648;
  color: white;
}
.GhostProfiles .pagination LI.previous.disabled,
.GhostProfiles .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.GhostProfiles .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.GhostProfiles .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.GhostProfiles .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.GhostProfiles .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.GhostProfiles .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.GhostProfiles .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.GhostProfiles .pointer {
  cursor: pointer;
}
.GhostProfiles img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.GhostProfiles .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .GhostProfiles .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.GhostProfiles .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.GhostProfiles .search .Select-control SELECT {
  height: 40px;
}
/* .GhostProfiles .input-control INPUT {
  height: 52px;
} */
.GhostProfiles .Select-control.form-group:after {
  display: none;
}
.GhostProfiles .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .GhostProfiles .search div {
  position: relative;
} */
/* .GhostProfiles .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.GhostProfiles .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.GhostProfiles .search button.btn-clear IMG {
  width: 15px;
}
.GhostProfiles .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.GhostProfiles .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.GhostProfiles .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.GhostProfiles .search DIV {
  position: relative;
}
.GhostProfiles .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.GhostProfiles .btn-refresh {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2962d;
  border: none;
  margin-bottom: 5px;
}
.GhostProfiles .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.GhostProfiles .input-search {
  display: flex;
}
@media (max-width: 1024px) {
  .GhostProfiles .refresh-btn {
    margin-right: 15px;
  }
  .GhostProfiles .ghostCount {
    position: static;
    margin-top: 5px;
  }
}
@media (max-width: 991px) {
  .GhostProfiles .select-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .GhostProfiles .input-search {
    width: 100%;
    display: flex;
  }
  .GhostProfiles .search {
    float: none;
    flex-wrap: wrap;
  }
  .GhostProfiles .input-search .input-control {
    flex: 1 1;
  }
  .GhostProfiles .input-search .input-control INPUT {
    width: 100%;
  }
  .GhostProfiles .search .Select-control {
    margin-right: 0px;
  }
}

.AdminDashboard .dashboard-panle {
  display: flex;
  padding: 15px 20px 40px 40px;
  justify-content: space-between;
}
.AdminDashboard {
  width: calc(100% - 250px);
}
.AdminDashboard .panel-left {
  width: 100%;
}
.AdminDashboard .panel-right {
  padding-left: 40px;
  min-width: 274px;
  width: 274px;
}
.AdminDashboard .monthly-activity {
  display: block;
  width: 100%;
}
.AdminDashboard .monthly-activity .AppointmentDetail {
  margin-bottom: 15px;
}
.AdminDashboard
  .monthly-activity
  .AppointmentDetail
  .Patient-status-control
  .Select-control.form-group
  select {
  padding-top: 0px;
}
.AdminDashboard .monthly-activity .title-content .title {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
}
.AdminDashboard .monthly-activity .title-content .title:after {
  content: '';
  height: 1px;
  background-color: #f1f1f5;
  left: 0;
  right: 0;
  top: 8px;
  position: absolute;
}
.AdminDashboard .monthly-activity .title-content .title SPAN {
  display: inline-block;
  padding: 0px 17px;
  background-color: #fafafb;
  z-index: 1;
  position: relative;
}
.AdminDashboard .monthly-activity .title-content {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
}
.AdminDashboard .monthly-activity .title-content .Select-control {
  margin-bottom: 0;
  margin-left: 16px;
}
.AdminDashboard .monthly-activity .title-content .Select-control SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 22px;
  padding-left: 16px;
  width: 115px;
  color: #013648;
}
.AdminDashboard .monthly-activity .title-content .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 7px;
}
.AdminDashboard .monthly-activity .title-content .Select-control.form-group:after {
  display: none;
}
.AdminDashboard .dashboard-panle .monthly-activity .InfiniteScroll {
  margin-right: -10px;
}
.AdminDashboard .analysis-title {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-left: 2px;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .AdminDashboard .dashboard-panle {
    display: flex;
    padding: 0px 20px 40px 20px;
  }
  .AdminDashboard .panel-right {
    padding-left: 20px;
  }
  .AdminDashboard .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .AdminDashboard .AppointmentDetail .Patient-information {
    padding-right: 0;
    border-right: 0;
  }
  .AdminDashboard .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 30px;
    min-width: auto;
  }
}

@media (max-width: 1024px) {
  .AdminDashboard {
    width: calc(100% - 200px);
  }
}

@media (max-width: 767px) {
  .AdminDashboard .dashboard-panle {
    flex-wrap: wrap;
    padding: 0px 15px;
  }
  .AdminDashboard .panel-right {
    padding-left: 0px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .AdminDashboard .panel-right > DIV {
    width: 48%;
    display: inline-block;
    vertical-align: top;
  }
  .AdminDashboard .AppointmentDetail {
    margin-bottom: 30px;
  }
  .AdminDashboard {
    width: 100%;
  }
  .AdminDashboard .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
}

@media (max-width: 414px) {
  .AdminDashboard .panel-right > DIV {
    width: 100%;
  }
}

.UserCounts {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 32px;
}
.UserCounts .Counter-box {
  border-radius: 8px;
  background-color: #ffffff;
  text-align: center;
  padding: 22px 22px 17px;
  width: 30.5%;
  position: relative;
  border: 1px solid #f1f1f5;
}
.UserCounts .Counter-box .count-title {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.UserCounts .Counter-box .count {
  font-size: 48px;
  text-align: center;
  color: #013648;
}
.UserCounts .Counter-box .parcent IMG {
  width: 12px;
  margin-left: 9px;
}
.UserCounts .Counter-box .parcent {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #199daf;
  font-family: 'Calibre Medium';
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.UserCounts .Counter-box .decrement {
  color: #f37184;
}
.UserCounts .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.UserCounts .info-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 0;
}
.UserCounts .info-wrapper:hover .info-content {
  display: block;
}
@media (max-width: 1200px) {
  .UserCounts .Counter-box {
    width: 24%;
    padding: 22px 15px 17px;
  }
  .UserCounts .Counter-box .count {
    font-size: 24px;
    text-align: center;
    color: #013648;
  }
}
@media (max-width: 1024px) {
  .UserCounts {
    flex-wrap: wrap;
    padding-top: 0;
  }
  .UserCounts .Counter-box {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .UserCounts {
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .UserCounts .Counter-box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.ReviewRequest .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ReviewRequest {
  width: calc(100% - 250px);
}
.ReviewRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.ReviewRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.ReviewRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.ReviewRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ReviewRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.ReviewRequest .heading .cell:first-child {
  min-width: 150px;
}
.ReviewRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.ReviewRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.ReviewRequest .cell.edit-cell {
  width: 20px;
}
.ReviewRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.ReviewRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.ReviewRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.ReviewRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.ReviewRequest .pagination LI.previous.disabled,
.ReviewRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.ReviewRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.ReviewRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.ReviewRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.ReviewRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.ReviewRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.ReviewRequest .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.ReviewRequest .pointer {
  cursor: pointer;
}
.ReviewRequest img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.ReviewRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .ReviewRequest .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.ReviewRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.ReviewRequest .search .Select-control SELECT {
  height: 40px;
}
/* .ReviewRequest .input-control INPUT {
  height: 52px;
} */
.ReviewRequest .Select-control.form-group:after {
  display: none;
}
.ReviewRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .ReviewRequest .search div {
  position: relative;
} */
/* .ReviewRequest .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.ReviewRequest .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.ReviewRequest .search button.btn-clear IMG {
  width: 15px;
}
.ReviewRequest .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.ReviewRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.ReviewRequest .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ReviewRequest .search DIV {
  position: relative;
}
.ReviewRequest .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
  margin-top: 5px;
}
.ReviewRequest .btn-view {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.ReviewRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.ReviewRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

@media (max-width: 1024px) {
  .ReviewRequest .refresh-btn {
    margin-right: 15px;
  }
}

.SpecialtyRequest .dashboard-panle {
  justify-content: space-between;
}
.SpecialtyRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.SpecialtyRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.SpecialtyRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.SpecialtyRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.SpecialtyRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.SpecialtyRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.SpecialtyRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.SpecialtyRequest .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.SpecialtyRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.SpecialtyRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.SpecialtyRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.SpecialtyRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.SpecialtyRequest .pagination LI.previous.disabled,
.SpecialtyRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.SpecialtyRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.SpecialtyRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.SpecialtyRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.SpecialtyRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.SpecialtyRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.SpecialtyRequest .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.SpecialtyRequest .pointer {
  cursor: pointer;
}
.SpecialtyRequest IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.SpecialtyRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .SpecialtyRequest .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.SpecialtyRequest .search DIV {
  position: relative;
}
/* .SpecialtyRequest .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.SpecialtyRequest .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.SpecialtyRequest .search button.btn-clear IMG {
  width: 15px;
}
.SpecialtyRequest .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.SpecialtyRequest .btn-approve {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.SpecialtyRequest .btn-reject {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.SpecialtyRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.SpecialtyRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.SpecialtyRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.CertificationRequest .dashboard-panle {
  justify-content: space-between;
}
.CertificationRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.CertificationRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.CertificationRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.CertificationRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.CertificationRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.CertificationRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.CertificationRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.CertificationRequest .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.CertificationRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.CertificationRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.CertificationRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.CertificationRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.CertificationRequest .pagination LI.previous.disabled,
.CertificationRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.CertificationRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.CertificationRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.CertificationRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.CertificationRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.CertificationRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.CertificationRequest .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.CertificationRequest .pointer {
  cursor: pointer;
}
.CertificationRequest IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.CertificationRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .CertificationRequest .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.CertificationRequest .search DIV {
  position: relative;
}
/* .CertificationRequest .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.CertificationRequest .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.CertificationRequest .search button.btn-clear IMG {
  width: 15px;
}
.CertificationRequest .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.CertificationRequest .btn-approve {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.CertificationRequest .btn-reject {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.CertificationRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.CertificationRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.CertificationRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
.CertificationRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.CertificationRequest .search .Select-control SELECT {
  height: 40px;
}
.CertificationRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.SuffixRequest .dashboard-panle {
  justify-content: space-between;
}
.SuffixRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.SuffixRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.SuffixRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.SuffixRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.SuffixRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.SuffixRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.SuffixRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.SuffixRequest .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.SuffixRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.SuffixRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.SuffixRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.SuffixRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.SuffixRequest .pagination LI.previous.disabled,
.SuffixRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.SuffixRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.SuffixRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.SuffixRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.SuffixRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.SuffixRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.SuffixRequest .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.SuffixRequest .pointer {
  cursor: pointer;
}
.SuffixRequest IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.SuffixRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .SuffixRequest .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.SuffixRequest .search DIV {
  position: relative;
}
/* .SuffixRequest .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.SuffixRequest .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.SuffixRequest .search button.btn-clear IMG {
  width: 15px;
}
.SuffixRequest .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.SuffixRequest .btn-approve {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.SuffixRequest .btn-reject {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.SuffixRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.SuffixRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.SuffixRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.OtherInfoRequest .dashboard-panle {
  justify-content: space-between;
}
.OtherInfoRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.OtherInfoRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.OtherInfoRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.OtherInfoRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.OtherInfoRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.OtherInfoRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.OtherInfoRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.OtherInfoRequest .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.OtherInfoRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.OtherInfoRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.OtherInfoRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.OtherInfoRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.OtherInfoRequest .pagination LI.previous.disabled,
.OtherInfoRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.OtherInfoRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.OtherInfoRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.OtherInfoRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.OtherInfoRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.OtherInfoRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.OtherInfoRequest .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.OtherInfoRequest .pointer {
  cursor: pointer;
}
.OtherInfoRequest IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.OtherInfoRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .OtherInfoRequest .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.OtherInfoRequest .search DIV {
  position: relative;
}
/* .OtherInfoRequest .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.OtherInfoRequest .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.OtherInfoRequest .search button.btn-clear IMG {
  width: 15px;
}
.OtherInfoRequest .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.OtherInfoRequest .btn-approve {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.OtherInfoRequest .btn-reject {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.OtherInfoRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.OtherInfoRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.OtherInfoRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
.OtherInfoRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.OtherInfoRequest .search .Select-control SELECT {
  height: 40px;
}
.OtherInfoRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.UpdateRequest {
  width: 100%;
}
.UpdateRequest .main-section-content {
  padding: 0 20px 0 40px;
}
.UpdateRequest .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.UpdateRequest .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
}
.UpdateRequest .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.UpdateRequest .setting-tabs .tab-list-active {
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
}
.UpdateRequest .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.UpdateRequest .UpdateRequest-content {
  padding: 0 40px;
}
.UpdateRequest .box-panel {
  margin-left: 20px;
  margin-right: 20px;
  padding: 40px 10px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  margin-top: 31px;
  margin-bottom: 30px;
}
.UpdateRequest .tab-content {
  position: relative;
}
.UpdateRequest .AfterAuthSubHeader .main-inner-content {
  padding-right: 460px;
}
.UpdateRequest .AfterAuthSubHeader .main-inner-content .title span:first-child {
  min-width: 160px;
}
.UpdateRequest .UpdateRequest-content {
  position: relative;
}
.UpdateRequest .tab-list-item .review-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  top: -5px;
  padding-left: -5px;
}
.UpdateRequest .tab-list-active .review-count {
  color: #72787d;
}
@media (max-width: 1400px) {
  .UpdateRequest .box-panle {
    padding: 40px 20px;
  }
  .UpdateRequest .box-panle .search-review-list {
    right: 20px;
  }
  .UpdateRequest .box-panle .search-review-list .Input-control {
    width: 230px;
  }
}

@media (max-width: 1200px) {
  .UpdateRequest .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .UpdateRequest .box-panle .search-review-list {
    top: 36px;
  }
  .UpdateRequest .main-section-content {
    padding: 0 20px 0 20px;
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content .title span:first-child {
    min-width: 134px;
  }
  .UpdateRequest .ApprovalCard {
    padding: 11px 10px 18px;
  }
  .UpdateRequest .ApprovalCard .approval-action span {
    margin-right: 15px;
  }
  .UpdateRequest .ApprovalCard .approval-action span:last-child {
    margin-right: 0;
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content {
    padding-right: 440px;
  }
}

@media (max-width: 1024px) {
  .UpdateRequest .main-section-content {
    padding: 0 15px 0;
  }
  .UpdateRequest .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
  .UpdateRequest .UpdateRequest-content {
    padding: 0px 15px;
  }
  .UpdateRequest .box-panel {
    padding: 15px;
  }
  .UpdateRequest {
    width: calc(100% - 200px);
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
    padding-right: 420px;
  }
  .UpdateRequest .ApprovalCard {
    top: -150px;
    right: 15px;
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content .title span:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .UpdateRequest .box-panle {
    padding: 20px;
  }
  .UpdateRequest .box-panle .search-review-list {
    margin-top: 10px;
  }
  .UpdateRequest {
    width: 100%;
  }
  .UpdateRequest .setting-tabs .tab-list-item {
    margin-right: 20px;
    font-size: 18px;
  }
  .UpdateRequest .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .UpdateRequest .ApprovalCard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .UpdateRequest .ApprovalCard .approval-action span {
    margin-right: 10px;
  }
}

.OtherInfoRequest .dashboard-panle {
  justify-content: space-between;
}
.OtherInfoRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.OtherInfoRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.OtherInfoRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.OtherInfoRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.OtherInfoRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.OtherInfoRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.OtherInfoRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.OtherInfoRequest .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.OtherInfoRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.OtherInfoRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.OtherInfoRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.OtherInfoRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.OtherInfoRequest .pagination LI.previous.disabled,
.OtherInfoRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.OtherInfoRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.OtherInfoRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.OtherInfoRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.OtherInfoRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.OtherInfoRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.OtherInfoRequest .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.OtherInfoRequest .pointer {
  cursor: pointer;
}
.OtherInfoRequest IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.OtherInfoRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .OtherInfoRequest .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.OtherInfoRequest .search DIV {
  position: relative;
}
/* .OtherInfoRequest .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.OtherInfoRequest .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.OtherInfoRequest .search button.btn-clear IMG {
  width: 15px;
}
.OtherInfoRequest .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.OtherInfoRequest .btn-approve {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.OtherInfoRequest .btn-reject {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.OtherInfoRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.OtherInfoRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.OtherInfoRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
.OtherInfoRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.OtherInfoRequest .search .Select-control SELECT {
  height: 40px;
}
.OtherInfoRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.Practices .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Practices {
  width: calc(100% - 250px);
}
.Practices .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.Practices .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Practices .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Practices .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Practices .heading .cell {
  background-color: #013648;
  color: white;
}
.Practices .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Practices .cell-center {
  text-align: center;
}
.Practices .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Practices .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.Practices .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Practices .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Practices .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Practices .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Practices .pagination LI.previous.disabled,
.Practices .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Practices .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Practices .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Practices .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Practices .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Practices .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Practices .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Practices .pointer {
  cursor: pointer;
}
.Practices img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Practices .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .Practices .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Practices .search div {
  position: relative;
}
/* .Practices .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Practices .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Practices .search button.btn-clear IMG {
  width: 15px;
}
.Practices .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.Practices .btn-add-new:focus {
  outline: 0;
}
.Practices .practice-website {
  display: block;
  padding-top: 5px;
  color: #26acda;
}
.Practices .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Practices .btn-edit {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.Practices .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}

.ManageFaq .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ManageFaq {
  width: calc(100% - 250px);
}
.ManageFaq .rtable {
  display: table;
  background-color: white;
  border: 0px;
  width: 100%;
}
.ManageFaq .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.ManageFaq .row {
  display: table-row;
  width: auto;
  clear: both;
}
.ManageFaq .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ManageFaq .heading .cell {
  background-color: #013648;
  color: white;
}
.ManageFaq .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.ManageFaq .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.ManageFaq .cell.edit-cell {
  width: 20px;
  text-align: center;
  padding-bottom: 90px;
}
.ManageFaq .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.ManageFaq .table-overflow .heading div{
  padding: 10px !important;
}
.ManageFaq .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.ManageFaq .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.ManageFaq .pagination LI.active {
  background-color: #013648;
  color: white;
}
.ManageFaq .pagination LI.previous.disabled,
.ManageFaq .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.ManageFaq .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.ManageFaq .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.ManageFaq .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.ManageFaq .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.ManageFaq .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.ManageFaq .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.ManageFaq .pointer {
  cursor: pointer;
}
.ManageFaq IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 30%;
    top: 16px;
}
.ManageFaq IMG.delete-icon {
  width: 40px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 26%;
    top: 41px;
}
.ManageFaq .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .ManageFaq .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.ManageFaq .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.ManageFaq .search .Select-control SELECT {
  height: 40px;
}
/* .ManageFaq .input-control INPUT {
  height: 52px;
} */
.ManageFaq .Select-control.form-group:after {
  display: none;
}
.ManageFaq .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .ManageFaq .search BUTTON {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.ManageFaq .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.ManageFaq .search button.btn-clear IMG {
  width: 15px;
}
.ManageFaq .search DIV {
  position: relative;
}
.ManageFaq .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.ManageFaq .dataCount {
  font-size: 16px;
}

.Addstaff {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.faq-xl .modal-wrapper{
max-width: 1024px !important;
}
.Addstaff .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.Addstaff .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
.dynamic-variables .variables{
border: 1px solid rgba(40, 84, 99, 0.2);
padding: 30px;
}

.main-label{
  font-family: 'Calibre Medium';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #013648;
    margin-bottom: 0;
    position: unset;
    top: unset;
    left: unset;
    transform: none;
}
.Addstaff ul{
  margin: 0;
    padding: 0;
    list-style: circle;
    font-size: 20px;
}

.Addstaff ul li{
  padding: 0 0 10px 0;
    
}
.validation-error{
  color: red;
    font-size: 13px;
    margin-top: 10px;
    display: block;
}
.Addstaff ul li img{
  width: 20px;
    height: 20px;
    background: #013648;
    position: relative;
    top: 6px;
    padding: 3px;
    border: 1px solid #8b8585;
    cursor: pointer;
}
.tox .tox-notification--warn, 
.tox .tox-notification--warning{
  display: none !important;
}
.Addstaff .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.Addstaff .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.Addstaff .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.Addstaff .cross-icon img {
  width: 100%;
}
.Addstaff .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.Addstaff .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.Addstaff .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.Addstaff .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.Addstaff .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.Addstaff .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.Addstaff .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.Addstaff .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.Addstaff .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.Addstaff .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.Addstaff .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.Addstaff .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.Addstaff .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.Addstaff .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.Addstaff .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Addstaff .input-close {
  position: relative;
}
.Addstaff .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.Addstaff .img-preview {
  max-width: 100%;
}
.Addstaff .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}

.staff-list .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.staff-list {
  width: calc(100% - 250px);
}
.staff-list .rtable {
  display: table;
  background-color: white;
  border: 0px;
  width: 100%;
}
.staff-list .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.providerslist{
  display: flex;
  flex-flow: column;
}
.staff-list .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.staff-list .row {
  display: table-row;
  width: auto;
  clear: both;
}
.staff-list .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.staff-list .heading .cell {
  background-color: #013648;
  color: white;
}
.staff-list .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.staff-list .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.staff-list .cell.edit-cell {
  width: 20px;
  text-align: center;
  padding-bottom: 90px;
}
.staff-list .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.staff-list .table-overflow .heading div{
  padding: 10px !important;
}
.staff-list .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.staff-list .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.staff-list .pagination LI.active {
  background-color: #013648;
  color: white;
}
.staff-list .pagination LI.previous.disabled,
.staff-list .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.staff-list .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.staff-list .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.staff-list .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.staff-list .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.staff-list .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.staff-list .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.staff-list .pointer {
  cursor: pointer;
}
.staff-list IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 30%;
    top: 16px;
}
.staff-list IMG.delete-icon {
  width: 40px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 26%;
    top: 5px;
}
.staff-list .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.staff-list .btn-deactivate {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.staff-list .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .staff-list .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.staff-list .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.staff-list .search .Select-control SELECT {
  height: 40px;
}
/* .staff-list .input-control INPUT {
  height: 52px;
} */
.staff-list .Select-control.form-group:after {
  display: none;
}
.staff-list .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .staff-list .search BUTTON {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.staff-list .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.staff-list .search button.btn-clear IMG {
  width: 15px;
}
.staff-list .search DIV {
  position: relative;
}
.staff-list .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.staff-list .dataCount {
  font-size: 16px;
}

@media (max-width: 770px){
.staff-list {
  width: 100%
}

}
.ReviewRequest .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ReviewRequest {
  width: calc(100% - 250px);
}
.ReviewRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.ReviewRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.uploadFiles img.photoid{
width: 50px;
max-height: 100px;
position: absolute;
cursor: pointer;
top: 12px;
}
.ReviewRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.ReviewRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ReviewRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.ReviewRequest .heading .cell:first-child {
  min-width: 150px;
}
.ReviewRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.ReviewRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.ReviewRequest .cell.edit-cell {
  width: 20px;
}
.ReviewRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.ReviewRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.ReviewRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.ReviewRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.ReviewRequest .pagination LI.previous.disabled,
.ReviewRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.ReviewRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.ReviewRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.ReviewRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.ReviewRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.ReviewRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.ReviewRequest .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.ReviewRequest .pointer {
  cursor: pointer;
}
.ReviewRequest img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.ReviewRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .ReviewRequest .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.ReviewRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.ReviewRequest .actionbtndev {
  display: grid;
}
.ReviewRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ReviewRequest .search .Select-control SELECT {
  height: 40px;
}
/* .ReviewRequest .input-control INPUT {
  height: 52px;
} */
.ReviewRequest .Select-control.form-group:after {
  display: none;
}
.ReviewRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .ReviewRequest .search div {
  position: relative;
} */
/* .ReviewRequest .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.ReviewRequest .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.ReviewRequest .search button.btn-clear IMG {
  width: 15px;
}
.ReviewRequest .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.ReviewRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.ReviewRequest .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ReviewRequest .search DIV {
  position: relative;
}
.ReviewRequest .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
  margin-top: 5px;
}
.ReviewRequest .btn-view {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.ReviewRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.ReviewRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.photoid-div{
  display: grid;
  min-width: 38px;
}
.ReviewRequest .actionbtndev button {
  margin-left: 0;
}
.ReviewRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .ReviewRequest .refresh-btn {
    margin-right: 15px;
  }
}

.ProfileSpecialtyTitles {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
}
.ProfileSpecialtyTitles .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.ProfileSpecialtyTitles .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ProfileSpecialtyTitles .modal-content-header .close IMG {
  width: 100%;
}
.ProfileSpecialtyTitles .modal-content-header .heading3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.ProfileSpecialtyTitles .modal-content-body {
  padding: 30px 33px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ProfileSpecialtyTitles .modal-content-body .InfiniteScroll {
  max-height: calc(100vh - 244px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.ProfileSpecialtyTitles h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 5px;
  font-family: 'Calibre Medium';
 
}
.ProfileSpecialtyTitles .each-spec{
  font-size: 18px;
    line-height: 1.4;
    letter-spacing: -.24px;
    color: #013648;
    margin-bottom: 3px;
}
.ProfileSpecialtyTitles .sub-para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -.2px;
  color: #b2bcc4;
  margin-bottom: 2px;
}
.ProfileSpecialtyTitles .footer-div{
  margin-top: 20px;
   border-top: 1px solid #b2bcc4;
    padding-top: 20px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.ProfileSpecialtyTitles .footer-div button{
  width: 150px;
  height: 35px;
  line-height: 35px;
}
.ProfileSpecialtyTitles .error-span{
color: red;
}
.ProfileSpecialtyTitles .Input-control.column3.form-group.error{
  margin-bottom: 5px;
}

/* .ProfileSpecialtyTitles .patient-details {
  display: flex;
} */




@media (max-width: 1024px) {
 
  .ProfileSpecialtyTitles {
    width: 730px;
  }
  .ProfileSpecialtyTitles .modal-content-body {
    padding: 30px 17px;
  }
}
@media (max-width: 767px) {
  .ProfileSpecialtyTitles {
    width: 100%;
  }

  
}
@media (max-width: 575px) {
  .ProfileSpecialtyTitles {
    width: 100%;
  }
  .ProfileSpecialtyTitles .modal-content-body {
    padding: 20px;
  }
  .ProfileSpecialtyTitles .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .ProfileSpecialtyTitles .modal-content-header .heading3 {
    font-size: 24px;
  }
  .ProfileSpecialtyTitles .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  

 
}
@media (max-width: 414px) {
  .ProfileSpecialtyTitles .modal-content-body {
    padding: 15px;
  }
  
}

.GhostProfiles .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.GhostProfiles {
  width: calc(100% - 250px);
}
.GhostProfiles .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.GhostProfiles .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.GhostProfiles .row {
  display: table-row;
  width: auto;
  clear: both;
}
.GhostProfiles .deleted-row {
  display: table-row;
  width: auto;
  clear: both;
  background-color: #f8e0e0;
}
.GhostProfiles .ghostCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.GhostProfiles .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: transparent;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.GhostProfiles .heading .cell {
  background-color: #013648;
  color: white;
}
.GhostProfiles .heading .cell:first-child {
  min-width: 150px;
}
.GhostProfiles .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.GhostProfiles .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.GhostProfiles .cell.edit-cell {
  width: 20px;
}
.GhostProfiles .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.GhostProfiles .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.GhostProfiles .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.GhostProfiles .pagination LI.active {
  background-color: #013648;
  color: white;
}
.GhostProfiles .pagination LI.previous.disabled,
.GhostProfiles .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.GhostProfiles .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.GhostProfiles .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.GhostProfiles .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.GhostProfiles .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.GhostProfiles .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.GhostProfiles .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.GhostProfiles .pointer {
  cursor: pointer;
}
.GhostProfiles img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.GhostProfiles .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .GhostProfiles .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.GhostProfiles .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.GhostProfiles .search .Select-control SELECT {
  height: 40px;
}
/* .GhostProfiles .input-control INPUT {
  height: 52px;
} */
.GhostProfiles .Select-control.form-group:after {
  display: none;
}
.GhostProfiles .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .GhostProfiles .search div {
  position: relative;
} */
/* .GhostProfiles .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.GhostProfiles .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.GhostProfiles .search button.btn-clear IMG {
  width: 15px;
}
.GhostProfiles .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.GhostProfiles .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.GhostProfiles .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.GhostProfiles .search DIV {
  position: relative;
}
.GhostProfiles .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.GhostProfiles .btn-refresh {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2962d;
  border: none;
  margin-bottom: 5px;
}
.GhostProfiles .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.GhostProfiles .input-search {
  display: flex;
}
@media (max-width: 1024px) {
  .GhostProfiles .refresh-btn {
    margin-right: 15px;
  }
  .GhostProfiles .ghostCount {
    position: static;
    margin-top: 5px;
  }
}
@media (max-width: 991px) {
  .GhostProfiles .select-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .GhostProfiles .input-search {
    width: 100%;
    display: flex;
  }
  .GhostProfiles .search {
    float: none;
    flex-wrap: wrap;
  }
  .GhostProfiles .input-search .input-control {
    flex: 1 1;
  }
  .GhostProfiles .input-search .input-control INPUT {
    width: 100%;
  }
  .GhostProfiles .search .Select-control {
    margin-right: 0px;
  }
}

.Provideraccesslog .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Provideraccesslog {
  width: calc(100% - 250px);
}
.Provideraccesslog .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.Provideraccesslog .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Provideraccesslog .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Provideraccesslog .deleted-row {
  display: table-row;
  width: auto;
  clear: both;
  background-color: #f8e0e0;
}
.Provideraccesslog .ghostCount {
  top: auto;
  /* position: absolute; */
  margin-top: 35px;
  font-size: large;
  float: left;
}
.Provideraccesslog .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: transparent;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Provideraccesslog .heading .cell {
  background-color: #013648;
  color: white;
}
.Provideraccesslog .heading .cell:first-child {
  min-width: 150px;
}
.Provideraccesslog .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Provideraccesslog .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Provideraccesslog .cell.edit-cell {
  width: 20px;
}
.Provideraccesslog .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Provideraccesslog .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Provideraccesslog .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Provideraccesslog .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Provideraccesslog .pagination LI.previous.disabled,
.Provideraccesslog .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Provideraccesslog .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Provideraccesslog .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Provideraccesslog .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Provideraccesslog .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Provideraccesslog .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Provideraccesslog .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Provideraccesslog .pointer {
  cursor: pointer;
}
.Provideraccesslog img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Provideraccesslog .search {
  margin: 15px 0px;
  /* float: right; */
  display: flex;
  align-items: flex-end;
}
/* .Provideraccesslog .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Provideraccesslog .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.Provideraccesslog .search .Select-control SELECT {
  height: 40px;
}
/* .Provideraccesslog .input-control INPUT {
  height: 52px;
} */
.Provideraccesslog .Select-control.form-group:after {
  display: none;
}
.Provideraccesslog .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .Provideraccesslog .search div {
  position: relative;
} */
/* .Provideraccesslog .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Provideraccesslog .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Provideraccesslog .search button.btn-clear IMG {
  width: 15px;
}
.Provideraccesslog .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.Provideraccesslog .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Provideraccesslog .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.Provideraccesslog .search DIV {
  position: relative;
}
.Provideraccesslog .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.Provideraccesslog .btn-refresh {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2962d;
  border: none;
  margin-bottom: 5px;
}
.Provideraccesslog .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.Provideraccesslog .input-search {
  display: flex;
}
.Provideraccesslog .search BUTTON{
  background-color: inherit;
  color: #013648;
}
.Provideraccesslog .search BUTTON.searchBtn{
  background-color: #013648;
  color: #fff;
}

.DateRangePicker-firstdiv{
  position: relative;
}
.DateRangePicker-seconddiv{
  position: absolute !important;
  z-index: 2;
  margin: 0 auto 50px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
@media (max-width: 1024px) {
  .Provideraccesslog .refresh-btn {
    margin-right: 15px;
  }
  .Provideraccesslog .ghostCount {
    position: static;
    margin-top: 5px;
  }
}
@media (max-width: 991px) {
  .Provideraccesslog .select-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .Provideraccesslog .input-search {
    width: 100%;
    display: flex;
  }
  .Provideraccesslog .search {
    float: none;
    flex-wrap: wrap;
  }
  .Provideraccesslog .input-search .input-control {
    flex: 1 1;
  }
  .Provideraccesslog .input-search .input-control INPUT {
    width: 100%;
  }
  .Provideraccesslog .search .Select-control {
    margin-right: 0px;
  }
}

.Provideraccesslog .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Provideraccesslog {
  width: calc(100% - 250px);
}
.Provideraccesslog .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.Provideraccesslog .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Provideraccesslog .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Provideraccesslog .deleted-row {
  display: table-row;
  width: auto;
  clear: both;
  background-color: #f8e0e0;
}
.Provideraccesslog .ghostCount {
  top: auto;
  /* position: absolute; */
  margin-top: 35px;
  font-size: large;
  float: left;
}
.Provideraccesslog .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: transparent;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Provideraccesslog .heading .cell {
  background-color: #013648;
  color: white;
}
.Provideraccesslog .heading .cell:first-child {
  min-width: 150px;
}
.Provideraccesslog .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Provideraccesslog .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Provideraccesslog .cell.edit-cell {
  width: 20px;
}
.Provideraccesslog .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Provideraccesslog .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Provideraccesslog .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Provideraccesslog .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Provideraccesslog .pagination LI.previous.disabled,
.Provideraccesslog .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Provideraccesslog .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Provideraccesslog .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Provideraccesslog .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Provideraccesslog .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Provideraccesslog .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Provideraccesslog .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Provideraccesslog .pointer {
  cursor: pointer;
}
.Provideraccesslog img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Provideraccesslog .search {
  margin: 15px 0px;
  /* float: right; */
  display: flex;
  align-items: flex-end;
}
/* .Provideraccesslog .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Provideraccesslog .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.Provideraccesslog .search .Select-control SELECT {
  height: 40px;
}
/* .Provideraccesslog .input-control INPUT {
  height: 52px;
} */
.Provideraccesslog .Select-control.form-group:after {
  display: none;
}
.Provideraccesslog .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .Provideraccesslog .search div {
  position: relative;
} */
/* .Provideraccesslog .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Provideraccesslog .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Provideraccesslog .search button.btn-clear IMG {
  width: 15px;
}
.Provideraccesslog .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.Provideraccesslog .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Provideraccesslog .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.Provideraccesslog .search DIV {
  position: relative;
}
.Provideraccesslog .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.Provideraccesslog .btn-refresh {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2962d;
  border: none;
  margin-bottom: 5px;
}
.Provideraccesslog .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.Provideraccesslog .input-search {
  display: flex;
}
.Provideraccesslog .search BUTTON{
  background-color: inherit;
  color: #013648;
}
.Provideraccesslog .search BUTTON.searchBtn{
  background-color: #013648;
  color: #fff;
}

.DateRangePicker-firstdiv{
  position: relative;
}
.DateRangePicker-seconddiv{
  position: absolute !important;
  z-index: 2;
  margin: 0 auto 50px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}
@media (max-width: 1024px) {
  .Provideraccesslog .refresh-btn {
    margin-right: 15px;
  }
  .Provideraccesslog .ghostCount {
    position: static;
    margin-top: 5px;
  }
}
@media (max-width: 991px) {
  .Provideraccesslog .select-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .Provideraccesslog .input-search {
    width: 100%;
    display: flex;
  }
  .Provideraccesslog .search {
    float: none;
    flex-wrap: wrap;
  }
  .Provideraccesslog .input-search .input-control {
    flex: 1 1;
  }
  .Provideraccesslog .input-search .input-control INPUT {
    width: 100%;
  }
  .Provideraccesslog .search .Select-control {
    margin-right: 0px;
  }
}

.Visualizations{
  background: white;
  min-width: 90vw;
  overflow: auto;
}
.visualizations-xl .modal-wrapper{
  max-width: 90vw !important;
}
.Visualizations .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  background-color: #013648;
  /* border-bottom: 1px solid #f1f1f5; */
}
.Visualizations .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.Visualizations .modal-content-header .close IMG {
  width: 100%;
}
.Visualizations .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.Visualizations .chartBody{
padding: 20px;
padding-top: 0;
/* padding-right: 30px; */
}
@media (max-width: 767px) {

  .Visualizations .modal-content-body,
  .Visualizations .modal-content-header {
    padding: 23px 20px 13px 20px;
  }
 
}
@media (max-width: 575px) {
 
  .Visualizations .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .Visualizations .modal-content-header .h3 {
    font-size: 24px;
  }
  .Visualizations .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}

.Visualizations{
  background: white;
  min-width: 90vw;
  overflow: auto;
}
.visualizations-xl .modal-wrapper{
  max-width: 90vw !important;
}
.Visualizations .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  background-color: #013648;
  /* border-bottom: 1px solid #f1f1f5; */
}
.Visualizations .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.Visualizations .modal-content-header .close IMG {
  width: 100%;
}
.Visualizations .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.Visualizations .chartBody{
padding: 20px;
padding-top: 0;
/* padding-right: 30px; */
}
@media (max-width: 767px) {

  .Visualizations .modal-content-body,
  .Visualizations .modal-content-header {
    padding: 23px 20px 13px 20px;
  }
 
}
@media (max-width: 575px) {
 
  .Visualizations .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .Visualizations .modal-content-header .h3 {
    font-size: 24px;
  }
  .Visualizations .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}

.EachFeedback {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 49%;
  padding: 10px;
  grid-gap: 5px;
  /* gap: 10px; */
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 40% 58%; */
  border: 1px solid #9993;
  border-radius: 5px;
}

.EachFeedback .col-left {
  display: flex;
  flex-direction: column;
  
    color: white;
   

}
.EachFeedback .col-left .content {
  padding: 10px;
  display: flex;
  /* grid-template-columns: 50% 50%; */
  flex-wrap: wrap;
  border-radius: 5px;
}
.EachFeedback .col-left .content .each-q{
  width: 50%;
}
.EachFeedback .col-left .content.completed{
  background-color: #013648;
 
}
.EachFeedback .col-left .content.pending{
background-color: #999;
}
.EachFeedback .col-left .each-q {
  display: flex;
  flex-direction: column;
}
.EachFeedback .col-left .each-q label.title {
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.EachFeedback .col-left .each-q label.ans {
  margin-bottom: 10px;
  font-size: 13px;
  opacity: .7;
}

.EachFeedback .col-right .each-block {
  padding: 10px;
  display: flex;
    flex-direction: column;
}
.EachFeedback .col-right .each-block label.title {
  font-size: 16px;
  font-weight: bold;
}
.EachFeedback .col-right .each-block  label.labeltext {
  font-size: 13px;
  color: #999;
  margin-top: 10px;
}
.EachFeedback .col-right .each-block  .slider-maindiv{
  padding: 5px;
  margin-bottom: 20px;
}
.EachFeedback .col-right .each-block textarea.textarea-comments:focus-visible{
border-color: #999 !important;
outline: none;
}
.EachFeedback .col-right .each-block .savebtn{
margin-left:0 !important;
}
.comment-label{
  font-weight: bold;
  font-size: 18px;
  color: #666;
  text-decoration: underline;
  margin: 15px 0;
}
.comment-text{
font-size: 16px;
color: #666;
}
.EachFeedback .auto-save{
  position: absolute;
    right: 0;
    bottom: 0px;
    font-size: 17px;
    color: #24ACD9;
}
@media screen and (max-width: 561px) {
  .EachFeedback {
    width: 100%;
  }
}

.rc-slider {
    position: relative;
    width: 100%;
    height: 14px;
    padding: 5px 0;
    border-radius: 6px;
    touch-action: none;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .rc-slider * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  .rc-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #e9e9e9;
    border-radius: 6px;
  }
  .rc-slider-track {
    position: absolute;
    height: 4px;
    background-color:#abe2fb;
    border-radius: 6px;
  }
  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 2px #96dbfa;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    opacity: 0.8;
    touch-action: pan-x;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: #57c5f7;
    box-shadow: 0 0 0 5px #96dbfa;
  }
  .rc-slider-handle:focus {
    outline: none;
    box-shadow: none;
  }
  .rc-slider-handle:focus-visible {
    border-color: #2db7f5;
    box-shadow: 0 0 0 3px #96dbfa;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #96dbfa;
    box-shadow: unset;
  }
  .rc-slider-handle:hover {
    border-color: #57c5f7;
  }
  .rc-slider-handle:active {
    border-color: #57c5f7;
    box-shadow: 0 0 5px #57c5f7;
    cursor: grabbing;
  }
  .rc-slider-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 12px;
  }
  .rc-slider-mark-text {
    position: absolute;
    display: inline-block;
    color: #999;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
  }
  .rc-slider-mark-text-active {
    color: #666;
  }
  .rc-slider-step {
    position: absolute;
    width: 100%;
    height: 4px;
    background: transparent;
    pointer-events: none;
  }
  .rc-slider-dot {
    position: absolute;
    bottom: -2px;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    background-color: #fff;
    border: 2px solid #e9e9e9;
    border-radius: 50%;
    cursor: pointer;
  }
  .rc-slider-dot-active {
    border-color: #96dbfa;
  }
  .rc-slider-dot-reverse {
    margin-right: -4px;
  }
  /* .rc-slider-disabled {
    background-color: #e9e9e9;
  } */
  /* .rc-slider-disabled .rc-slider-track {
    background-color: #ccc;
  } */
  /* .rc-slider-disabled .rc-slider-handle, .rc-slider-disabled .rc-slider-dot {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
    cursor: not-allowed;
  } */
  .rc-slider-disabled .rc-slider-mark-text, .rc-slider-disabled .rc-slider-dot {
    cursor: not-allowed !important;
  }
  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    width: 4px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-track {
    bottom: 0;
    left: 5px;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-top: 0;
    margin-left: -5px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    width: 4px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-dot {
    margin-left: -2px;
  }
  
.like_unlike{
  display: flex;
}
.like_unlike div{
  height: 60px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.like_unlike  .likediv{
  margin-right: 10px;
}
.like_unlike .unlike{
 padding-top: 10px;
 
}
.like_unlike img{
  width: 50px;
}
.like_unlike img.likeimg{
  margin-top: 4px;
}
.like_unlike div.noallow{
  
  cursor: auto;
  
}
.like_unlike div.active.activegreen{
  background-color: #198754;
}
.like_unlike div.active.activered{
  background-color: #e85d72;
}
.like_unlike div.novalue img{
  filter: invert(55%) sepia(27%) saturate(0%) hue-rotate(218deg) brightness(106%) contrast(86%);
}
.feedback-maindiv .feedback-content{
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.feedback-maindiv .main-title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.feedback-maindiv .loadmorediv{
  
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.feedback-maindiv .loadmorediv button{
  text-transform: capitalize;
}
.feedback-maindiv .nofeedbacks{
  font-size: 20px;
  color: #999;
}
.ReviewAccounts {
  width: 100%;
}
.ReviewAccounts .main-section-content {
  padding: 0 20px 0 40px;
}
.ReviewAccounts .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.ReviewAccounts .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
}
.ReviewAccounts .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewAccounts .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
}
.ReviewAccounts .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.ReviewAccounts .ReviewAccounts-content {
  padding: 0 40px;
}
.ReviewAccounts .box-panel {
  padding: 40px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  margin-top: 31px;
  margin-bottom: 30px;
}
.ReviewAccounts .tab-content {
  position: relative;
}
.ReviewAccounts .AfterAuthSubHeader .main-inner-content {
  padding-right: 460px;
}
.ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
  min-width: 160px;
}
.ReviewAccounts .ReviewAccounts-content {
  position: relative;
}
@media (max-width: 1400px) {
  .ReviewAccounts .box-panle {
    padding: 40px 20px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    right: 20px;
  }
  .ReviewAccounts .box-panle .search-review-list .Input-control {
    width: 230px;
  }
}

@media (max-width: 1200px) {
  .ReviewAccounts .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    top: 36px;
  }
  .ReviewAccounts .main-section-content {
    padding: 0 20px 0 20px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
    min-width: 134px;
  }
  .ReviewAccounts .ApprovalCard {
    padding: 11px 10px 18px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span {
    margin-right: 15px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span:last-child {
    margin-right: 0;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-right: 440px;
  }
}

@media (max-width: 1024px) {
  .ReviewAccounts .main-section-content {
    padding: 0 15px 0;
  }
  .ReviewAccounts .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
  .ReviewAccounts .ReviewAccounts-content {
    padding: 0px 15px;
  }
  .ReviewAccounts .box-panel {
    padding: 15px;
  }
  .ReviewAccounts {
    width: calc(100% - 200px);
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
    padding-right: 420px;
  }
  .ReviewAccounts .ApprovalCard {
    top: -150px;
    right: 15px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content .title span:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .ReviewAccounts .box-panle {
    padding: 20px;
  }
  .ReviewAccounts .box-panle .search-review-list {
    margin-top: 10px;
  }
  .ReviewAccounts {
    width: 100%;
  }
  .ReviewAccounts .setting-tabs .tab-list-item {
    margin-right: 20px;
    font-size: 18px;
  }
  .ReviewAccounts .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .ReviewAccounts .ApprovalCard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .ReviewAccounts .ApprovalCard .approval-action span {
    margin-right: 10px;
  }
}

.singleFeedback .EachFeedback{
width: 100%;
margin-top: 10px;
}
.Reviewsources .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.Reviewsources {
  width: calc(100% - 250px);
}
.Reviewsources .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.Reviewsources .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.Reviewsources .row {
  display: table-row;
  width: auto;
  clear: both;
}
.Reviewsources .deleted-row {
  display: table-row;
  width: auto;
  clear: both;
  background-color: #f8e0e0;
}
.Reviewsources .ghostCount {
  top: auto;
  /* position: absolute; */
  margin-top: 15px;
  font-size: large;
  float: left;
}
.Reviewsources .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: transparent;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.Reviewsources .heading .cell {
  background-color: #013648;
  color: white;
}
.Reviewsources .heading .cell:first-child {
  min-width: 150px;
}
.Reviewsources .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.Reviewsources .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.Reviewsources .cell.edit-cell {
  width: 20px;
}
.Reviewsources .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.Reviewsources .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.Reviewsources .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.Reviewsources .pagination LI.active {
  background-color: #013648;
  color: white;
}
.Reviewsources .pagination LI.previous.disabled,
.Reviewsources .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.Reviewsources .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.Reviewsources .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.Reviewsources .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.Reviewsources .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.Reviewsources .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.Reviewsources .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.Reviewsources .pointer {
  cursor: pointer;
}
.Reviewsources img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.Reviewsources .search {
  margin: 15px 0px;
  /* float: right; */
  display: flex;
  justify-content: space-between;
}
.Reviewsources .url-cell{
width: 200px;
display: block;
word-break: break-all;
}
.Reviewsources  .no-record{
  padding: 10px;
  font-size: 19px;
}
/* .Reviewsources .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.Reviewsources .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.Reviewsources .search .Select-control SELECT {
  height: 40px;
}
/* .Reviewsources .input-control INPUT {
  height: 52px;
} */
.Reviewsources .Select-control.form-group:after {
  display: none;
}
.Reviewsources .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .Reviewsources .search div {
  position: relative;
} */
/* .Reviewsources .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.Reviewsources .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.Reviewsources .search button.btn-clear IMG {
  width: 15px;
}
.Reviewsources .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.Reviewsources .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.Reviewsources .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.Reviewsources .search DIV {
  position: relative;
}
.Reviewsources .btn-edit {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.Reviewsources .btn-refresh {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2962d;
  border: none;
  margin-bottom: 5px;
}
.Reviewsources .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.Reviewsources .input-search {
  display: flex;
}
@media (max-width: 1024px) {
  .Reviewsources .refresh-btn {
    margin-right: 15px;
  }
  .Reviewsources .ghostCount {
    position: static;
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
.Reviewsources {
  width: calc(90%);
}
}
@media (max-width: 991px) {
  .Reviewsources .select-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .Reviewsources .input-search {
    width: 100%;
    display: flex;
  }
  .Reviewsources .search {
    float: none;
    flex-wrap: wrap;
  }
  .Reviewsources .input-search .input-control {
    flex: 1 1;
  }
  .Reviewsources .input-search .input-control INPUT {
    width: 100%;
  }
  .Reviewsources .search .Select-control {
    margin-right: 0px;
  }
}

.ConsultRequest .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ConsultRequest {
  width: calc(100% - 250px);
}
.ConsultRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.ConsultRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.uploadFiles img.photoid{
width: 50px;
max-height: 100px;
position: absolute;
cursor: pointer;
top: 12px;
}
.ConsultRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.ConsultRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ConsultRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.ConsultRequest .heading .cell:first-child {
  min-width: 150px;
}
.ConsultRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.ConsultRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.ConsultRequest .cell.edit-cell {
  width: 20px;
}
.ConsultRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.ConsultRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.ConsultRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.ConsultRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.ConsultRequest .pagination LI.previous.disabled,
.ConsultRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.ConsultRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.ConsultRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.ConsultRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.ConsultRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.ConsultRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.ConsultRequest .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.ConsultRequest .pointer {
  cursor: pointer;
}
.ConsultRequest img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.ConsultRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .ConsultRequest .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.ConsultRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.ConsultRequest .actionbtndev {
  display: grid;
}
.ConsultRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ConsultRequest .search .Select-control SELECT {
  height: 40px;
}
/* .ConsultRequest .input-control INPUT {
  height: 52px;
} */
.ConsultRequest .Select-control.form-group:after {
  display: none;
}
.ConsultRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .ConsultRequest .search div {
  position: relative;
} */
/* .ConsultRequest .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.ConsultRequest .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.ConsultRequest .search button.btn-clear IMG {
  width: 15px;
}
.ConsultRequest .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.ConsultRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.ConsultRequest .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ConsultRequest .search DIV {
  position: relative;
}
.ConsultRequest .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
  margin-top: 5px;
}
.ConsultRequest .btn-view {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.ConsultRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.ConsultRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.ConsultRequest .sub-header{
  color: #013648;
  font-size: 36px;
  margin-bottom: 0;
}
.ConsultRequest .imgtextdisplay{
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.ConsultRequest .imgtextdisplay span{
margin-top: 5px;
}
.ConsultRequest .imgtextdisplay img{
width: 20px;
}
.edit-btn{
  border: none;
  background: none;
  margin-left: 5px;
  cursor: pointer;
}
.ConsultRequest .imgtextdisplay.success{
  background: green;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  color: white;
  justify-content: center;
  }
  .ConsultRequest .imgtextdisplay.error{
    background: red;
    border-radius: 5px;
    padding: 5px;
    font-size: 16px;
    color: white;
    justify-content: center;
    }
  .ConsultRequest .imgtextdisplay.success img{
    width: 15px;
  }
  .ConsultRequest .imgtextdisplay.error img{
    width: 15px;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(106%) contrast(101%);
  }
.photoid-div{
  display: grid;
  min-width: 38px;
}
.ConsultRequest .actionbtndev button {
  margin-left: 0;
}
.ConsultRequest .search INPUT{
  padding: 3px 15px 0 25px;
    height: 40px;
    border-radius: 2px;
    border: 1.5px solid rgba(40,84,99,.2);
    background-color: #fff;
    font-size: 18px;
    font-family: Calibre Regular;
    line-height: 40px;
    letter-spacing: -.34px;
    color: #013648;
    -webkit-appearance: none
}
.ConsultRequest .search BUTTON {
  padding: 5px 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  height: 40px;
  font-weight: 400!important;
  line-height: 33px;
  background-color: #013648;
  color: #fff;
  cursor: pointer;
  border: 0;
}
.ConsultRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .ConsultRequest .refresh-btn {
    margin-right: 15px;
  }
}

.ConsultrequestEdit .EachFeedback{
width: 100%;
margin-top: 10px;
}
.ConsultrequestEdit .h3{
color:white;
}
.ConsultrequestEdit .kalymdrequestform{
    padding-top: 20px;
}
.ConsultrequestEdit .kalymdrequestform .kalymdrequestform-container{
    max-width: 90%;
    padding: 0;
}
.viewrequest{
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 20px 36px;
  background-color: #999;
  margin-top: 10px;
  margin-bottom: 15px;
}
.viewmaindiv .viewrequest{
  background-color: white;
}
/* .viewmaindiv .viewrequest .rightpanel,
.viewmaindiv .viewrequest .leftpanel {
  flex: 1
} */
.viewmaindiv  .viewrequest .leftpanel{
  width: 40%;
}
.viewmaindiv  .viewrequest .rightpanel{
  width: 50%;
}
.viewrequest .leftpanel{
  display: flex;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
padding-right: 30px;
/* width: 40%; */
}

.viewrequest .rightpanel{
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  border-left: 1px solid #fff;
  grid-gap: 15px;
  gap: 15px;
}
.viewmaindiv .viewrequest .rightpanel{
  border-left: 1px solid #000;
}
.viewrequest .eachitem {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.viewmaindiv .viewrequest .eachitem{
  color: #000;
}
.viewrequest .eachitem .title{
  font-weight: bold;
  font-size: 20px;
  
}
.viewrequest .imgtextdisplay{
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
}
.viewrequest .imgtextdisplay span{
margin-top: 5px;
}
.viewrequest .imgtextdisplay img{
width: 20px;
filter: invert(100%) sepia(9%) saturate(1783%) hue-rotate(294deg) brightness(109%) contrast(108%);
}
.viewmaindiv .viewrequest .imgtextdisplay img{
  width: 20px;
  filter: invert(0%) sepia(4%) saturate(16%) hue-rotate(68deg) brightness(105%) contrast(103%);
  }
  .PracticeNote{
    white-space: pre-line;
   margin-top: 0;
  }
.ChangePassword{
  width: 100%;
}
.ChangePassword .dashboard-panle {

  padding: 35px 30px 138px 40px;
  width: 100%;
  max-width: 100%;
}
.ChangePassword .password-form {
  width: 612px;
  background-color: #ffffff;
  border: solid 1px #e2e2ea;
  padding: 35px 30px 138px 40px;
  width: 620px;
  max-width: 100%;
}
.ChangePassword .password-form .h3 {
  margin-bottom: 24px;
}
.ChangePassword .email-form .h3 {
  margin: 0 0 24px;
}
.ChangePassword .password-form .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 8px;
}
.ChangePassword .password-form .Input-control.form-group {
  margin-bottom: 22px;
}
.ChangePassword .password-form .save-btn  button{
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  letter-spacing: normal;
  margin-right: 34px;
  line-height: 32px;
}

.EditProvider {
  width: 100%;
}
.EditProvider .dashboard-panle {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
}
.EditProvider .setting-approvalcard {
  border-radius: 8px;
  background-color: #fff;
  padding: 11px 15px 18px;
  border: 1px solid #e2e2ea;
  display: block;
  right: 0px;
  top: -175px;
  width: 375px;
  margin-left: auto;
  margin-bottom: 10px;
}
.EditProvider .setting-approvalcard .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.EditProvider .setting-approvalcard .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.EditProvider .setting-approvalcard .approval-action {
  display: block;
  margin-top: 10px;
}
.EditProvider .setting-approvalcard .approval-action SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #013648;
  display: inline-block;
  margin-right: 10px;
  width: 29%;
}
.EditProvider .setting-approvalcard .approval-action SPAN:last-child {
  margin-right: 0;
}
.EditProvider .setting-approvalcard .approval-action SPAN IMG {
  width: 20px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}

@media screen and (max-width: 1366px) {
  .EditProvider .setting-approvalcard {
    padding: 11px 10px 18px;
    width: 330px;
  }
  .EditProvider .setting-approvalcard .approval-action SPAN {
    margin-right: 33px;
  }
  .EditProvider .setting-approvalcard .approval-action SPAN IMG {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .EditProvider .setting-approvalcard {
    top: -225px;
  }
}

@media screen and (max-width: 767px) {
  .EditProvider .setting-approvalcard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .EditProvider .setting-approvalcard .approval-action SPAN {
    margin-right: 10px;
    width: auto;
  }
}

.ReviewData {
  padding-top: 47px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(143, 146, 161, 0.2);
}
.ReviewData .review-detail-innercontent {
  display: flex;
}
.ReviewData .review-detail-innercontent .Description {
  flex: 3 1;
  padding-right: 60px;
}
.ReviewData .review-detail-innercontent .rating {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.linka{
  color: rgba(1, 54, 72, 0.4);
  text-decoration: underline;
}
.ReviewData .review-detail-innercontent .rating .rating-list .SingleRating .name {
  width: auto;
}
.ReviewData .review-detail-innercontent > DIV {
  flex: 1 1;
}
.ReviewData .review-detail-innercontent .name .person-name {
  min-width: 132px;
}
.ReviewData:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.ReviewData .star-rating .name {
  font-size: 18px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-right: 6px;
}
.ReviewData .star-rating .number {
  font-size: 20px;
  line-height: 0.68;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Heldane Display';
  font-weight: normal;
  position: relative;
}
.ReviewData .star-rating .rate-icon {
  width: 14px;
  margin-right: 4px;
  height: 13px;
  position: relative;
  left: -1px;
}
.ReviewData .review-detail-innercontent .name .person-name {
  width: 100%;
  display: inline-block;
  font-family: 'Calibre Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: #013648;
}
.ReviewData .rating-list {
  margin-left: auto;
}
.ReviewData .review-detail-innercontent .name .link-span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #26acda;
  margin-bottom: 30px;
  cursor: pointer;
}
.ReviewData .review-detail-innercontent .name .status-btn {
  border-radius: 1px;
  border: 0;
  color: #fff;
  margin-bottom: 22px;
  padding: 6px 13px 3px 13px;
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  line-height: 15px;
}
.ReviewData .review-detail-innercontent .name .recent-btn {
  background-color: #f37184;
}
.ReviewData .review-detail-innercontent .name .featured-btn {
  background-color: #013648;
}
.ReviewData .review-detail-innercontent .name .logo {
  width: 100%;
  display: inline-block;
}
.ReviewData .review-detail-innercontent .name .logo IMG {
  width: 29px;
  height: 29px;
  object-fit: contain;
}
.ReviewData .review-detail-innercontent .Description .datetime {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  margin-top: 7px;
  display: inline-block;
}
.ReviewData .review-detail-innercontent .Description .link {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  display: inline-block;
  margin-top: 30px;
  cursor: pointer;
}
.ReviewData .Confirmation {
  padding: 20px;
  border-radius: 2px;
  background-color: rgba(1, 54, 72, 0.03);
  margin-top: 20px;
}
.ReviewData .confirmation-content {
  border-radius: 2px;
  border: solid 1px rgba(1, 54, 72, 0.5);
  background-color: #ffffff;
  padding: 20px;
}
.ReviewData .confirmation-content .textarea-wrapper TEXTAREA {
  width: 100%;
  border: 0;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 36px;
  display: inline-block;
  width: 100%;
}
.ReviewData .confirmation-content .textarea-wrapper TEXTAREA:focus {
  outline: 0;
}
.ReviewData .CircleButton {
  display: inline-block;
  width: 111px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
}
.ReviewData .reply IMG {
  width: 14px;
  height: 11px;
  margin-right: 10px;
}
.ReviewData .replyBox {
  background-color: rgba(1, 54, 72, 0.03);
  padding: 20px;
  font-size: 16px;
}
.ReviewData .replyBox .reply-text {
  margin-top: 10px;
  word-break: break-word;
}
.ReviewData .popup-rating {
  position: absolute;
  top: -146px;
  left: -63px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  z-index: 999;
  padding: 20px;
}
.ReviewData:first-child .popup-rating {
  top: 80px;
}
.ReviewData .review-detail-innercontent .rating .featured-review {
  margin-top: auto;
  margin-left: auto;
  width: 100%;
  text-align: right;
}
.ReviewData .review-detail-innercontent .rating .featured-review .CircleButton {
  width: auto;
  font-size: 13px;
  margin-top: 10px;
  line-height: unset;
  padding: 5px 5px 2px;
  margin-right: 5px;
}
.ReviewData .confirmation-content-action {
  display: flex;
  align-items: center;
}
.ReviewData .confirmation-content-action > SPAN {
  display: inline-block;
  padding-left: 15px;
  font-size: 16px;
  color: rgba(1, 54, 72, 0.4);
}
.ReviewData .replyaction .btn {
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.ReviewData .replyaction .btn SPAN.link {
  margin: auto 0;
  display: inline-block;
  position: relative;
  top: 3px;
}
.ReviewData .replyaction .btn IMG {
  width: 15px;
  margin-right: 5px;
}
.ReviewData .replyaction {
  margin-top: 15px;
}
.ReviewData .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
}
.ReviewData .delete-request {
  padding-top: 20px;
  padding-right: 0px;
  width: 100%;
  text-align: right;
}

@media (hover: hover) {
  .ReviewData .rating .rating-list > .SingleRating:hover .name,
  .ReviewData .rating .rating-list > .SingleRating:hover .number {
    color: #3d93a6;
  }
  .ReviewData .rating .rating-list > .SingleRating:hover .progress-bar {
    background-color: #3d93a6;
  }
}

@media (max-width: 1400px) {
  .ReviewData .review-detail-innercontent .Description {
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .ReviewData .confirmation-content-action {
    flex-wrap: wrap;
  }
  .ReviewData .confirmation-content-action > SPAN {
    padding-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .ReviewData .review-detail-innercontent {
    flex-wrap: wrap;
  }
  .ReviewData .review-detail-innercontent > DIV {
    flex: inherit;
    width: 100%;
  }
  .ReviewData .review-detail-innercontent .Description {
    padding-right: 0;
  }
  .ReviewData .review-detail-innercontent .rating {
    width: 100%;
    margin-top: 15px;
  }
  .ReviewData .review-detail-innercontent > DIV.name {
    position: relative;
    padding-left: 40px;
  }
  .ReviewData .rating-list {
    margin-left: 0;
    width: 100%;
  }
  .ReviewData .review-detail-innercontent .rating .featured-review {
    margin-top: 10px;
  }
  .ReviewData .review-detail-innercontent .name .logo {
    position: absolute;
    left: 0;
    top: 0;
  }
  .ReviewData .popup-rating {
    right: 15px;
    top: 100px;
    left: 20px;
  }
  .ReviewData {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .ReviewData .review-detail-innercontent .Description .link {
    margin-top: 15px;
  }
}

.ReviewTab .tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin: 0;
}
.ReviewTab .no-desingn {
  text-align: center;
  font-size: 25px;
}
.ReviewTab .review-list {
  padding: 40px 0 0;
}
.ReviewTab .tab-list-item:nth-child(4) {
  display: none;
}
.ReviewTab .tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0px 15px;
  margin: 0px 60px 0 0;
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(1, 54, 72, 0.3);
  position: relative;
  cursor: pointer;
}
.ReviewTab .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewTab .tab-list-active {
  background-color: #fff;
  border: solid #013648;
  border-width: 0px 0px 4px 0px;
  color: rgb(1, 54, 72);
}
.ReviewTab .tab-list-item .review-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  right: -24px;
  top: 0;
}
.ReviewTab .tab-list-active .review-count {
  color: #72787d;
}
.ReviewTab .review-sort-wrapper {
  margin-bottom: -20px;
  margin-top: -20px;
  text-align: right;
}
.ReviewTab .review-sort-wrapper SPAN {
  padding: 3px 11px 2px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #203a70;
  font-family: 'Calibre Medium';
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #013648;
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
}
.ReviewTab .review-sort-wrapper SPAN.active {
  background-color: #013648;
  color: #fff;
}
.ReviewTab .Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.ReviewTab .Rtable-row {
  width: 100%;
  display: flex;
}
.ReviewTab .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ReviewTab .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ReviewTab .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ReviewTab .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ReviewTab .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ReviewTab .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ReviewTab .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ReviewTab .Rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ReviewTab .Rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ReviewTab .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ReviewTab .btn.btn-refresh {
  width: auto;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 5px 2px;
  cursor: pointer;
  outline: 0;
}
.ReviewTab .refesh {
  text-align: right;
  padding-top: 10px;
}
.mr-1{
  margin-right: 5px;
}
@media (hover: hover) {
  .ReviewTab .review-sort-wrapper SPAN:hover {
    background-color: #013648;
    color: #fff;
  }
}

@media (max-width: 1366px) {
  .ReviewTab .tab-list-item {
    font-size: 20px;
    margin: 0px 50px 0 0;
  }
}

@media (max-width: 1200px) {
  .ReviewTab .tab-list-item {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .ReviewTab .tab-list {
    margin: 0px -10px;
  }
  .ReviewTab .tab-list-item,
  .ReviewTab .tab-list-item:first-child {
    margin-right: 50px;
    margin-left: 20px;
  }
  .ReviewTab .review-sort-wrapper {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .ReviewTab .tab-list {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .ReviewTab .tab-list-item {
    white-space: nowrap;
  }
}

.ReviewConnect {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ReviewConnect .date-cell {
  width: 20%;
}
.ReviewConnect .staus-cell {
  width: 10%;
}
.ReviewConnect .access-link-cell {
  width: 50%;
  padding-left: 3px;
  padding-right: 15px;
}
.ReviewConnect .action-cell {
  width: 20%;
  text-align: center;
}
.ReviewConnect .Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.ReviewConnect .Rtable-row {
  width: 100%;
  display: flex;
}
.ReviewConnect .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ReviewConnect .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ReviewConnect .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ReviewConnect .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ReviewConnect .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ReviewConnect .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ReviewConnect .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ReviewConnect .Rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ReviewConnect .Rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ReviewConnect .action-menu {
  margin-left: auto;
  position: relative;
}
.ReviewConnect .action-menu .action-btn {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}
.ReviewConnect .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.ReviewConnect .action-menu.show-menu .dropdown-menu {
  display: block;
}
.ReviewConnect .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ReviewConnect .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.ReviewConnect .action-menu.show-menu .dropdown-menu {
  display: block;
}
.ReviewConnect .btn.btn-approve {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
}
.ReviewConnect .btn.btn-reject {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #e85d72;
  border: none;
}
@media (max-width: 767px) {
  .ReviewConnect .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}

.ReviewAddComponent .body{
  padding: 20px;
}
.review-addactionbtn{
  grid-gap: 5px;
  gap: 5px;
  display: flex;
  /* justify-content: center; */
}
.ProviderReviews {
  width: 100%;
}
.ProviderReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.ProviderReviews .box-panle .Select-control {
  margin-bottom: 0;
  margin-right: 18px;
}
.ProviderReviews .box-panle .Select-control SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 27px;
  padding-left: 22px;
  width: 200px;
  color: #44444f;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.11px;
}
.ProviderReviews .box-panle .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 16px;
}
.ProviderReviews .ReviewTabs .tab-list {
  padding-right: 520px;
}
.ProviderReviews .box-panle .search-review-list {
  position: absolute;
  right: 30px;
  top: 37px;
  display: flex;
  align-items: center;
}
.ProviderReviews .box-panle .search-review-list .Input-control.form-group .view-img {
  right: auto;
  left: 9px;
  width: 21px;
  height: 17px;
  top: 19px;
}
.ProviderReviews .search-review-list .Input-control.form-group INPUT::placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 36px;
  color: #013648;
}
.ProviderReviews .box-panle .search-review-list .Input-control {
  width: 300px;
  height: 38px;
  margin-bottom: 0;
}
.ProviderReviews .box-panle .search-review-list INPUT {
  border-radius: 10px;
  background-color: #fafafb;
  height: 38px;
  border: solid 1px #e2e2ea;
  padding-left: 37px;
}
.ProviderReviews .box-panle .search-review-list INPUT:focus,
.ProviderReviews .box-panle .search-review-list INPUT:not(:placeholder-shown) {
  outline: 0;
  padding-left: 37px;
}
.ProviderReviews .box-panle {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 42px 30px 38px 40px;
  margin-bottom: 40px;
  position: relative;
}
.ProviderReviews .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.ProviderReviews .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.ProviderReviews .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}

@media (max-width: 1400px) {
  .ProviderReviews .box-panle {
    padding: 40px 20px;
  }
  .ProviderReviews .box-panle .search-review-list {
    right: 20px;
  }
  .ProviderReviews .box-panle .search-review-list .Input-control {
    width: 230px;
  }
  .ProviderReviews .box-panle .Select-control {
    margin-right: 12px;
  }
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 430px;
  }
  .ProviderReviews .box-panle .Select-control SELECT {
    padding-right: 20px;
    padding-left: 15px;
    width: 180px;
  }
  .ProviderReviews .box-panle .Select-control .arrow {
    right: 8px;
  }
}

@media (max-width: 1366px) {
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 390px;
  }
  .ProviderReviews .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .ProviderReviews .box-panle .Select-control SELECT {
    width: 170px;
  }
}

@media (max-width: 1199px) {
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .ProviderReviews .main-section-content {
    padding: 0 15px 0;
  }
  .ProviderReviews .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
}

@media (max-width: 767px) {
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .ProviderReviews .box-panle {
    padding: 20px 10px;
  }
  .ProviderReviews .box-panle .search-review-list {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .ProviderReviews .box-panle .search-review-list {
    flex-wrap: wrap;
  }
  .ProviderReviews .box-panle .Select-control {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .ProviderReviews .box-panle .Select-control select {
    width: 100%;
  }
  .ProviderReviews .box-panle .search-review-list .Input-control {
    width: 100%;
  }
}

.EditPracticeLogo .PracticeLogo {
  display: flex;
}
.EditPracticeLogo {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 22px 70px 40px;
  margin-bottom: 20px;
}
.EditPracticeLogo .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #192022;
  margin-bottom: 44px;
}
.EditPracticeLogo .logo-img {
  min-width: 120px;
  width: 120px;
  height: 120px;
  border-radius: 5.6px;
  border: solid 0.8px #e2e2ea;
  display: inline-block;
  display: flex;
}
.EditPracticeLogo .logo-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.EditPracticeLogo .logo-inner-content .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.EditPracticeLogo .logo-inner-content {
  padding-left: 20px;
  width: calc(100% - 120px);
  padding-top: 10px;
}
.EditPracticeLogo .logo-inner-content .image-size {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 14px;
}
.EditPracticeLogo .circle-btn {
  height: 48px;
  border-radius: 24px;
  background-color: rgba(143, 146, 161, 0.2);
  border: 0;
  width: 117px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  text-align: center;
  color: #72787d;
  padding: 7px 5px 5px;
  cursor: pointer;
}
.EditPracticeLogo .circle-btn:focus {
  outline: 0;
}
.EditPracticeLogo .circle-btn.blue-btn {
  background-color: #26acda;
  color: #fff;
  margin-right: 10px;
}
.EditPracticeLogo .fileupload {
  position: relative;
  margin-right: 9px;
  margin-bottom: 5px;
}
.EditPracticeLogo .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.EditPracticeLogo .fileupload .fileupload-btn button:focus {
  outline: 0;
}

.EditPracticeLogo .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.EditPracticeLogo .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}
.EditPracticeLogo .logo-content-action {
  display: flex;
  flex-wrap: wrap;
}

@media (hover: hover) {
  .EditPracticeLogo .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .EditPracticeLogo {
    padding: 20px;
  }
  .EditPracticeLogo .logo-inner-content {
    padding-left: 15px;
  }
  .EditPracticeLogo .fileupload .fileupload-btn button {
    width: 148px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeLogo .fileupload .fileupload-btn button {
    width: 140px;
  }
  .EditPracticeLogo .circle-btn {
    width: 100px;
  }
  .EditPracticeLogo .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .EditPracticeLogo {
    padding: 10px;
  }
}

@media screen and (max-width: 375px) {
  .EditPracticeLogo .logo-img {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
}

@supports (-webkit-touch-callout: none) {
  .EditPracticeLogo .fileupload .fileupload-btn button {
    padding-top: 2px;
  }
}

.EditPracticeOverview {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 40px 40px;
  margin-bottom: 40px;
}
.EditPracticeOverview .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 40px;
}
.EditPracticeOverview .Select-control .arrow {
  top: 55px;
}
.EditPracticeOverview .Textarea,
.EditPracticeOverview .TagsInput .form-col,
.EditPracticeOverview .Input-control{
  scroll-margin-top: 80px;
}
.EditPracticeOverview .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  margin-top: 17px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 1px;
  border: 1px solid #013648;
}
.EditPracticeOverview .Textarea.form-group TEXTAREA {
  height: 160px;
}
.EditPracticeOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
  padding-left: 25px;
}
.EditPracticeOverview .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeOverview .Input-control.form-group {
  margin-bottom: 36px;
}
.EditPracticeOverview .Textarea.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeOverview .Textarea.form-group {
  margin-bottom: 34px;
}
.EditPracticeOverview .Select-control .label {
  line-height: 1.25;
  margin-bottom: 14px;
  display: block;
}

@media screen and (max-width: 1366px) {
  .EditPracticeOverview {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeOverview .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .EditPracticeOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px !important;
  }
}

.EditLocation {
  border-radius: 2px;
  background-color: #f7f9f9;
  margin-bottom: 30px;
}
.EditLocation input:disabled {
  background-color: #f1f1f5 !important;
}
.EditLocation:last-child {
  margin-bottom: 0;
}
.EditLocation .location-content {
  display: none;
  padding: 9px 29px 40px;
}
.EditLocation.show .location-content {
  display: block;
}
.EditLocation .Textarea,
.EditLocation .TagsInput .form-col,
.EditLocation .Input-control{
  scroll-margin-top: 80px;
}
.EditLocation .location-header {
  position: relative;
  font-size: 24px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #013648;
  padding: 31px 43px 18px 29px;
  border-radius: 2px;
  min-height: 82px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.EditLocation .location-header:after {
  content: '';
  position: absolute;
  right: 31px;
  top: 50%;
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #013648;
}
.EditLocation.show .location-header:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #013648;
  border-top: 0;
}
.EditLocation .toggle-icon {
  margin-right: 29px;
  position: relative;
  width: 19px;
  height: 19px;
}
.EditLocation .toggle-icon SPAN {
  height: 2px;
  border-radius: 0.9px;
  background-color: #013648;
  opacity: 0.2;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
}
.EditLocation .toggle-icon SPAN:first-child {
  top: 0;
}
.EditLocation .toggle-icon SPAN:nth-child(2) {
  top: 4px;
}
.EditLocation .toggle-icon SPAN:nth-child(3) {
  top: 9px;
}
.EditLocation .website-label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 14px;
}
.EditLocation .website-label .inner-label {
  color: rgba(1, 54, 72, 0.4);
}
.EditLocation .Input-control.form-group .label .inner-label {
  color: rgba(1, 54, 72, 0.4);
}
.EditLocation .add-email SPAN {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #3d93a6;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 40px;
}
.EditLocation .add-email SPAN IMG {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
}
.EditLocation .form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.EditLocation .form-row .column-9 {
  width: calc(100% - 98px);
  padding-right: 11px;
}
.EditLocation .form-row .column-3 {
  width: 98px;
}
.EditLocation .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
}
.EditLocation .save-delete-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EditLocation .save-delete-wrapper .delete-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #f37184;
  margin-left: 15px;
  cursor: pointer;
}
.EditLocation .Input-control.form-group.firstEmail {
  margin-bottom: 20px;
}
.EditLocation .Input-control .correct-icon {
  width: 16px;
  height: 16px;
  background-color: #3d93a6;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 6px;
}
.EditLocation .Input-control .correct-icon IMG {
  width: 9px;
}
.EditLocation .Input-control .close-icon {
  width: 16px;
  height: 16px;
  background-color: #e85d72;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 6px;
}
.EditLocation .Input-control .close-icon IMG {
  width: 8px;
}
.EditLocation .Select-control.form-group SELECT.active-dropdown {
  border-radius: 4px;
  height: 38px;
  border-width: 1px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #3d93a6;
}
.EditLocation .Select-control.form-group SELECT.active-dropdown ~ .arrow {
  border: 0;
  border-left: solid 1px #e2e2ea;
  top: 24px;
  right: 0px;
  width: 36px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EditLocation .Select-control.form-group SELECT.active-dropdown ~ .arrow:after {
  content: '';
  border: 8px solid rgba(143, 146, 161, 0.8);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: relative;
  top: 4px;
}
.EditLocation .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 6px;
}
.EditLocation .Input-control.form-group {
  margin-bottom: 36px;
}
.EditLocation .location-form .Input-control.form-group {
  margin-bottom: 14px;
}
.EditLocation .location-form .input-list-label {
  margin-bottom: 9px;
}
.EditLocation .location-form .Input-control.form-group.location-name {
  margin-bottom: 36px;
}
.EditLocation .location-form .Select-control.form-group {
  margin-top: 25px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1366px) {
  .EditLocation .location-header {
    padding: 18px 43px 18px 20px;
  }
  .EditLocation .location-content {
    padding: 6px 20px 40px;
  }
}

@media screen and (max-width: 1200px) {
  .EditLocation .location-header {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .EditLocation .save-delete-wrapper {
    flex-wrap: wrap;
  }
  .EditLocation .save-delete-wrapper .delete-link {
    margin-top: 15px;
    margin-left: 0;
  }
}

.EditPracticeLocations {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 36px 40px 40px;
  margin-bottom: 40px;
}
.EditPracticeLocations .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 44px;
}
.EditPracticeLocations .add-location {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  width: 100%;
  height: 92px;
  border-radius: 2px;
  border: dashed 1px #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.EditPracticeLocations .add-location .plus-icon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: rgba(1, 54, 72, 0.2);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -3px;
}
.EditPracticeLocations .add-location .plus-icon img {
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
}

@media screen and (max-width: 1366px) {
  .EditPracticeLocations {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeLocations .h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) {
  .EditPracticeLocations {
    padding: 10px;
  }
}

.EditPracticeRegistration {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 20px 40px 40px;
  margin-bottom: 40px;
}
.EditPracticeRegistration .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 39px;
}
.EditPracticeRegistration .label-content {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
}
.EditPracticeRegistration .label-content span {
  margin-right: 10px;
}
.EditPracticeRegistration .label-content .uploadfile-text {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.EditPracticeRegistration .fileupload {
  min-height: 52px;
  border-radius: 2px;
  background-color: #f7f9f9;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  margin-top: 14px;
  padding-right: 142px;
  word-break: break-all;
}
.EditPracticeRegistration .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 4px;
}
.EditPracticeRegistration .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}
.EditPracticeRegistration .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 1366px) {
  .EditPracticeRegistration {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeRegistration .h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) {
  .EditPracticeRegistration {
    padding: 10px;
  }
}

.EditPracticeSeoLink {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 40px 40px;
  margin-bottom: 40px;
}
.EditPracticeSeoLink .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 40px;
}
.EditPracticeSeoLink .Select-control .arrow {
  top: 55px;
}
.EditPracticeSeoLink .Textarea,
.EditPracticeSeoLink .TagsInput .form-col,
.EditPracticeSeoLink .Input-control{
  scroll-margin-top: 80px;
}
.EditPracticeSeoLink .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  margin-top: 17px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 1px;
  border: 1px solid #013648;
}
.EditPracticeSeoLink .Textarea.form-group TEXTAREA {
  height: 160px;
}
.EditPracticeSeoLink .Textarea.form-group .form-control:not(:placeholder-shown) {
  padding-left: 25px;
}
.EditPracticeSeoLink .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeSeoLink .Input-control.form-group {
  margin-bottom: 36px;
}
.EditPracticeSeoLink .Textarea.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.EditPracticeSeoLink .Textarea.form-group {
  margin-bottom: 34px;
}
.EditPracticeSeoLink .Select-control .label {
  line-height: 1.25;
  margin-bottom: 14px;
  display: block;
}
 .confirm-seolink.practice p {
  display: grid;
  grid-template-columns: 180px 1fr;
  padding-left: 15px;
}

@media screen and (max-width: 1366px) {
  .EditPracticeSeoLink {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .EditPracticeSeoLink .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .EditPracticeSeoLink .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px !important;
  }
}
@media screen and (max-width: 413px) {
  .confirm-seolink.practice p{
    display: inherit;
  }
}
.EditPractice {
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
}
.EditPractice .dashboard-panel {
  display: flex;
  margin: 10px -15px;
}
.EditPractice .leftpanel {
  padding: 0 15px;
  width: 45.5%;
}
.EditPractice .rightpanel {
  padding: 0 15px;
  width: 54.5%;
}

.EditPractice .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.EditPractice .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.EditPractice .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}

@media (max-width: 1280px) {
  .EditPractice .leftpanel,
  .EditPractice .rightpanel {
    padding: 0 5px;
  }
  .EditPractice .dashboard-panel {
    margin: 0 -5px;
  }
}

@media (max-width: 1024px) {
  .EditPractice .leftpanel,
  .EditPractice .rightpanel {
    width: 100%;
    padding: 0;
  }
  .EditPractice .dashboard-panel {
    flex-wrap: wrap;
    margin: 0;
  }
}

.GhostProviderReviews {
  width: 100%;
}
.GhostProviderReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.GhostProviderReviews .box-panle .Select-control {
  margin-bottom: 0;
  margin-right: 18px;
}
.GhostProviderReviews .box-panle .Select-control SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 27px;
  padding-left: 22px;
  width: 200px;
  color: #44444f;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.11px;
}
.GhostProviderReviews .box-panle .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 16px;
}
.GhostProviderReviews .ReviewTabs .tab-list {
  padding-right: 520px;
}
.GhostProviderReviews .box-panle .search-review-list {
  position: absolute;
  right: 30px;
  top: 37px;
  display: flex;
  align-items: center;
}
.GhostProviderReviews .box-panle .search-review-list .Input-control.form-group .view-img {
  right: auto;
  left: 9px;
  width: 21px;
  height: 17px;
  top: 19px;
}
.GhostProviderReviews .search-review-list .Input-control.form-group INPUT::placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 36px;
  color: #013648;
}
.GhostProviderReviews .box-panle .search-review-list .Input-control {
  width: 300px;
  height: 38px;
  margin-bottom: 0;
}
.GhostProviderReviews .box-panle .search-review-list INPUT {
  border-radius: 10px;
  background-color: #fafafb;
  height: 38px;
  border: solid 1px #e2e2ea;
  padding-left: 37px;
}
.GhostProviderReviews .box-panle .search-review-list INPUT:focus,
.GhostProviderReviews .box-panle .search-review-list INPUT:not(:placeholder-shown) {
  outline: 0;
  padding-left: 37px;
}
.GhostProviderReviews .box-panle {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 42px 30px 38px 40px;
  margin-bottom: 40px;
  position: relative;
}
.GhostProviderReviews .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.GhostProviderReviews .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.GhostProviderReviews .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}

@media (max-width: 1400px) {
  .GhostProviderReviews .box-panle {
    padding: 40px 20px;
  }
  .GhostProviderReviews .box-panle .search-review-list {
    right: 20px;
  }
  .GhostProviderReviews .box-panle .search-review-list .Input-control {
    width: 230px;
  }
  .GhostProviderReviews .box-panle .Select-control {
    margin-right: 12px;
  }
  .GhostProviderReviews .ReviewTabs .tab-list {
    padding-right: 430px;
  }
  .GhostProviderReviews .box-panle .Select-control SELECT {
    padding-right: 20px;
    padding-left: 15px;
    width: 180px;
  }
  .GhostProviderReviews .box-panle .Select-control .arrow {
    right: 8px;
  }
}

@media (max-width: 1366px) {
  .GhostProviderReviews .ReviewTabs .tab-list {
    padding-right: 390px;
  }
  .GhostProviderReviews .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .GhostProviderReviews .box-panle .Select-control SELECT {
    width: 170px;
  }
}

@media (max-width: 1199px) {
  .GhostProviderReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .GhostProviderReviews .main-section-content {
    padding: 0 15px 0;
  }
  .GhostProviderReviews .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
}

@media (max-width: 767px) {
  .GhostProviderReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .GhostProviderReviews .box-panle {
    padding: 20px 10px;
  }
  .GhostProviderReviews .box-panle .search-review-list {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .GhostProviderReviews .box-panle .search-review-list {
    flex-wrap: wrap;
  }
  .GhostProviderReviews .box-panle .Select-control {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .GhostProviderReviews .box-panle .Select-control select {
    width: 100%;
  }
  .GhostProviderReviews .box-panle .search-review-list .Input-control {
    width: 100%;
  }
}

.Modal .error .modal-header {
  color: #fff;
  background-color: #d53834;
  border-color: #d53834;
}
.Modal .info .modal-header {
  color: #fff;
  background-color: #83bdff;
  border-color: #83bdff;
  min-height: 42px;
}
.Modal .success .modal-header {
  color: #fff;
  background-color: #013648;
  border-color: #013648;
}
.Modal .warning .modal-header {
  color: #fff;
  background-color: #f5b041;
  border-color: #f5b041;
}
.Modal .agreement .modal-header {
  color: #fff;
  background-color: #f5b041;
  border-color: #f5b041;
}

.ConfirmTemplate {
  background-color: #fff;
  /* border: solid 1px #e2e2ea; */
  border-radius: 4px;
  overflow: hidden;
  width: 586px;
  max-width: 100%;
  margin: auto;
}
.ConfirmTemplate .modal-content-body {
  padding: 29px 35px;
}
.ConfirmTemplate .modal-content-footer {
  padding: 23px 45px 33px;
}
.ConfirmTemplate .Divider {
  margin-top: 10px;
}
.ConfirmTemplate .confirm-msg {
  font-size: 20px;
}
.ConfirmTemplate .Divider .container {
  width: auto;
}
.ConfirmTemplate .modal-content-body {
  padding-bottom: 0;
}
.ConfirmTemplate .modal-content-footer {
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: right;
}
.ConfirmTemplate .modal-content-footer .CircleButton {
  width: 100px;
  margin-left: 15px;
}
.ConfirmTemplate .modal-content-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 32px;
}
.ConfirmTemplate .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}

.Modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.Modal .modal-wrapper {
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
}
.Modal .modal-body {
  padding: 20px;
  min-height: 150px;
  font-size: 15px;
  line-height: 22px;
  background: #fefefe;
}
.Modal .modal-header,
.Modal .modal-footer {
  padding: 10px 20px;
  border-radius: 0 0 4px 4px;
}
.Modal .modal-header {
  background: #174c99;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.Modal .modal-header .title {
  font-size: 20px;
}
.Modal .modal-footer {
  border-top: #eee solid 1px;
  text-align: right;
  background: #fefefe;
}
.Modal .btn-close {
  color: #f5f5f5;
  font-size: 30px;
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 3px;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.Modal .cancel-btn {
  height: 35px;
  border-radius: 4px;
  padding: 10px 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  background: #e5e5e5;
  color: #222;
  border: 0;
  margin-right: 15px;
  cursor: pointer;
}
.Modal .modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.Modal.mega-modal .modal-wrapper {
  max-width: inherit;
  width: inherit;
}

@media (hover: hover) {
  .Modal .btn-close:hover {
    color: #fff;
    opacity: 0.8;
  }
}

@media (max-width: 767px) {
  .Modal.mega-modal .modal-wrapper {
    max-width: 100%;
    width: 90%;
  }
  .Modal.fullpage-height .modal-wrapper{
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0,0);
  }
  .Modal .mob-screen{
    width: 100%;
    margin-bottom: 10px;
  }
}
.Login {
  padding: 119px 0;
}
.Login .login-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.Login .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 18px;
  margin-bottom: 48px;
  font-family: 'Heldane Display';
  font-weight: normal;
}
.Login .checkbox-block {
  margin-top: 22px;
  margin-bottom: 24px;
}
.Login .Checkbox-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.Login .login-btn {
  margin-bottom: 74px;
}
.Login .login-btn > * {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.Login .login-bottom {
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
}
.Login .login-bottom A {
  color: #3dbce7;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.Login .checkbox-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Login .checkbox-block .reset-link {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
  font-family: 'Calibre Medium';
}
.Login .password-group .form-control {
  padding-right: 35px;
}
.Login .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 21px;
  height: 13px;
  top: 50%;
  transform: translateY(-50%);
}
.Login .doctor-loginbtn > * {
  height: 48px;
  line-height: 48px;
  font-family: 'Calibre Regular';
}
.otp-maindiv div.otp-subdiv{
  width: 320px;
  margin: 0 auto;
}
.otp-maindiv div.otp-subdiv div div input {
  width: 63.5% !important;
  margin-right: 8px;
  height: 42px;
  font-size: 18px;
}
@media (hover: hover) {
  .Login .login-bottom A:hover {
    color: #013648;
  }
  .Login .checkbox-block .reset-link:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .Login {
    padding: 90px 0;
  }
  .Login .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
  .Login .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .Login .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .Login {
    padding: 70px 0;
  }
  .Login .h3 {
    font-size: 28px;
    margin-bottom: 35px;
    padding-bottom: 12px;
  }
  .Login .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .Login .login-btn {
    margin-bottom: 30px;
  }
}
@supports (-webkit-touch-callout: none) {
  .Login .doctor-loginbtn .CircleButton {
    padding-top: 3px;
  }
}

.ResetPassword {
  padding: 120px 0;
}
.ResetPassword .reset-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.ResetPassword .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 18px;
  margin-bottom: 22px;
  font-family: 'Heldane Display';
}
.ResetPassword .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 37px;
}
.ResetPassword .reset-btn {
  margin-top: 48px;
}
.ResetPassword .reset-btn > button {
  width: 100%;
  height: 48px;
  font-family: 'Calibre Regular';
  line-height: 50px;
}

@media (max-width: 1024px) {
  .ResetPassword {
    padding: 90px 0;
  }
  .ResetPassword .h3 {
    font-size: 30px;
    margin-bottom: 15px;
    padding-bottom: 14px;
  }
  .ResetPassword .reset-btn {
    margin-top: 42px;
  }
  .ResetPassword .paragraph {
    margin-bottom: 33px;
  }
}
@media (max-width: 767px) {
  .ResetPassword {
    padding: 70px 0;
  }
  .ResetPassword .h3 {
    font-size: 28px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  .ResetPassword .paragraph {
    font-size: 14px;
    margin-bottom: 25px;
  }
  .ResetPassword .reset-btn {
    margin-top: 30px;
  }
}

.NewPassword {
  padding: 120px 0;
}
.NewPassword .newpassword-container {
  max-width: 414px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.NewPassword .password-instruction-text {
  font-size: 13px;
  line-height: 16px;
  font-family: 'Calibre Regular';
}
.NewPassword .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 17px;
  font-family: 'Heldane Display';
}
.NewPassword .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #013648;
  margin-bottom: 34px;
}
.NewPassword .confirm-btn {
  margin-top: 15px;
}
.NewPassword .confirm-btn > BUTTON {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.NewPassword .Input-control.form-group {
  margin-bottom: 18px;
}
.NewPassword .Input-control.form-group .view-img {
  height: 18px;
  top: calc(100% - 1.85rem);
}

@media (max-width: 1024px) {
  .NewPassword {
    padding: 90px 0;
  }
  .NewPassword .h3 {
    font-size: 30px;
    margin-bottom: 15px;
    padding-bottom: 14px;
  }
  .NewPassword .confirm-btn {
    margin-top: 10px;
  }
  .NewPassword .paragraph {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .NewPassword {
    padding: 70px 0;
  }
  .NewPassword .h3 {
    font-size: 28px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  .NewPassword .paragraph {
    font-size: 14px;
    margin-bottom: 25px;
  }
}

.FinalizeAccount .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.FinalizeAccount .password-instruction-text {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Calibre Regular';
}
.FinalizeAccount .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(12, 59, 75, 1);
  margin-bottom: 23px;
}
.FinalizeAccount .paragraph A {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(12, 59, 75, 0.7);
}
.FinalizeAccount .checkbox-block {
  margin-top: 23px;
  margin-bottom: 39px;
}
.FinalizeAccount .checkbox-block .Checkbox-control:not(:last-child) {
  margin-bottom: 18px;
}
.FinalizeAccount .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.FinalizeAccount .form-row .two-input {
  width: 48%;
}
.FinalizeAccount .signup-form .Input-control {
  margin-bottom: 18px;
}
.FinalizeAccount .signup-btn > BUTTON {
  width: 100%;
  height: 45px;
  margin-bottom: 55px;
}
.FinalizeAccount .signup-bottom {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
}
.FinalizeAccount .signup-bottom A {
  color: #3dbce7;
  font-weight: 500;
}
.FinalizeAccount .Input-control .form-control {
  border-width: 1px;
}
.FinalizeAccount .Input-control.form-group .form-control:focus,
.FinalizeAccount .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}

@media (hover: hover) {
  .FinalizeAccount .signup-bottom A:hover {
    color: #013648;
  }
  .FinalizeAccount .paragraph A:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .FinalizeAccount .h3 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .FinalizeAccount .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .FinalizeAccount .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 40px;
    letter-spacing: normal;
  }
  .FinalizeAccount .checkbox-block {
    margin-top: 15px;
    margin-bottom: 22px;
  }
  .FinalizeAccount .checkbox-block .Checkbox-control:not(:last-child) {
    margin-bottom: 23px;
  }
  .FinalizeAccount .signup-btn > BUTTON {
    margin-bottom: 40px;
  }
  .FinalizeAccount .signup-form .Input {
    margin-bottom: 20px;
  }
}

.FileUpload {
  margin-bottom: 18px;
}
.FileUpload .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.FileUpload .Fileuploadinput {
  width: 150px;
  height: 149px;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  background-color: #f7f9f9;
  position: relative;
  display: flex;
}
.FileUpload .Fileuploadinput input {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 99;
}
.FileUpload .upload-img .Fileuploadinput img {
  height: auto;
  width: 102px;
  max-height: 100%;
}
.FileUpload .Fileuploadinput img {
  width: 61px;
  height: 39px;
  margin: auto;
}
.FileUpload .fileupload-wrapper {
  display: flex;
  align-items: flex-end;
}
.FileUpload .upload-link a {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
}
.FileUpload .upload-link .upload-txt {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
}
.FileUpload .upload-link {
  padding-left: 19px;
}
.FileUpload .RemoveImage {
  display: none;
}
.FileUpload .upload-img .RemoveImage {
  position: ABSOLUTE;
  right: 6px;
  top: 7px;
  width: 15px;
  display: inline-block;
  cursor: pointer;
  display: block;
  z-index: 100;
}
.FileUpload .upload-link .Fileuploadinput {
  background-color: transparent;
  border: 0;
  width: auto;
  height: auto;
  cursor: pointer;
}
.FileUpload .upload-img .RemoveImage img {
  width: 100%;
}

@media (hover: hover) {
  .FileUpload .upload-link a:hover {
    color: #013648;
  }
}

.AboutYourPracticeStepOne .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYourPracticeStepOne .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 27px;
}
.AboutYourPracticeStepOne .paragraph a {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
}
.AboutYourPracticeStepOne .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYourPracticeStepOne .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
  margin-top: 16px;
}
.AboutYourPracticeStepOne .Input-control .form-control {
  border-width: 1px;
}
.AboutYourPracticeStepOne .Input-control.form-group .form-control:focus,
.AboutYourPracticeStepOne .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYourPracticeStepOne .Select-control.form-group SELECT {
  border-width: 1px;
}

@media (hover: hover) {
  .AboutYourPracticeStepOne .paragraph A:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .AboutYourPracticeStepOne .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYourPracticeStepOne .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepOne .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 40px;
    letter-spacing: normal;
  }
  .AboutYourPracticeStepOne .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepOne .signup-btn .CircleButton {
    margin-top: 11px;
  }
}

.AboutYourPracticeStepTwo .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYourPracticeStepTwo .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 26px;
}
.AboutYourPracticeStepTwo .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.AboutYourPracticeStepTwo .form-row .column-9 {
  width: 71.3%;
}
.AboutYourPracticeStepTwo .form-row .column-3 {
  width: 25.5%;
}
.AboutYourPracticeStepTwo .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYourPracticeStepTwo .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
  margin-top: 22px;
}
.AboutYourPracticeStepTwo .two-input .inner-label {
  color: rgba(1, 54, 72, 0.5);
}
.AboutYourPracticeStepTwo .Input-control .form-control {
  border-width: 1px;
}
.AboutYourPracticeStepTwo .Input-control.form-group .form-control:focus,
.AboutYourPracticeStepTwo .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYourPracticeStepTwo .stripe-input {
  border-width: 1px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  padding: 13px 15px 0px 15px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}

@media (max-width: 1024px) {
  .AboutYourPracticeStepTwo .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYourPracticeStepTwo .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepTwo .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 40px;
    letter-spacing: normal;
  }
  .AboutYourPracticeStepTwo .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepTwo .signup-btn .CircleButton {
    margin-top: 9px;
  }
}

.AboutYouAsProviderStepOne .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYouAsProviderStepOne .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 28px;
}
.AboutYouAsProviderStepOne .paragraph A {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(12, 59, 75, 0.7);
}
.AboutYouAsProviderStepOne .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYouAsProviderStepOne .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.AboutYouAsProviderStepOne .form-row .column-6 {
  width: 48%;
}
.AboutYouAsProviderStepOne .signup-btn {
  margin-top: 38px;
}
.AboutYouAsProviderStepOne .signup-btn > BUTTON {
  width: 100%;
  height: 45px;
}
.AboutYouAsProviderStepOne .Select-control {
  margin-bottom: 20px;
}
.AboutYouAsProviderStepOne .Input-control .form-control {
  border-width: 1px;
}
.AboutYouAsProviderStepOne .Input-control.form-group .form-control:focus,
.AboutYouAsProviderStepOne .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYouAsProviderStepOne .Select-control.form-group select {
  border-width: 1px;
}

@media (hover: hover) {
  .AboutYouAsProviderStepOne .paragraph A:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .AboutYouAsProviderStepOne .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYouAsProviderStepOne .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYouAsProviderStepOne .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 20px;
    letter-spacing: normal;
  }
  .AboutYouAsProviderStepOne .signup-btn {
    margin-top: 40px;
  }
  .AboutYouAsProviderStepOne .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYouAsProviderStepOne .Select-control {
    margin-bottom: 18px;
  }
}

.AboutYouAsProviderStepTwo .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYouAsProviderStepTwo .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #013648;
  margin-bottom: 28px;
}
.AboutYouAsProviderStepTwo .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYouAsProviderStepTwo .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
  margin-top: 18px;
}
.AboutYouAsProviderStepTwo .TagsInput .multiselectsearch-block {
  min-height: 147px;
}
.AboutYouAsProviderStepTwo .TagsInput.suffix-tag-input .outer-suggetion-box {
  flex: 1 1;
}
.AboutYouAsProviderStepTwo .TagsInput.suffix-tag-input .multiselectsearch-block {
  min-height: 52px;
  padding: 11px 15px;
}
.AboutYouAsProviderStepTwo .Input-control .form-control {
  border-width: 1px;
}
.AboutYouAsProviderStepTwo .Input-control.form-group .form-control:focus,
.AboutYouAsProviderStepTwo .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYouAsProviderStepTwo .Select-control.form-group SELECT {
  border-width: 1px;
}

@media (max-width: 1024px) {
  .AboutYouAsProviderStepTwo .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYouAsProviderStepTwo .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYouAsProviderStepTwo .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 30px;
    letter-spacing: normal;
  }
  .AboutYouAsProviderStepTwo .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYouAsProviderStepTwo .signup-btn .CircleButton {
    margin-top: 30px;
  }
  .AboutYouAsProviderStepTwo .TagsInput {
    margin-bottom: 20px;
  }
}

.AboutYouAsProvider {
}

.InviteYourProviders .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.InviteYourProviders .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #013648;
  margin-bottom: 28px;
}
.InviteYourProviders .bottom-paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  text-align: center;
  color: rgba(1, 54, 72, 0.5);
  margin-bottom: 38px;
}
.InviteYourProviders .signup-btn {
  margin-bottom: 27px;
}
.InviteYourProviders .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
}
.InviteYourProviders .signup-bottom {
  text-align: center;
}
.InviteYourProviders .signup-bottom SPAN {
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
  text-align: center;
  cursor: pointer;
}
.InviteYourProviders .providers-input {
  position: relative;
}
.InviteYourProviders .providers-instruction-text {
  position: absolute;
  left: 117px;
  top: 7px;
}
.InviteYourProviders .TagsInput .multiselectsearch-block {
  height: 322px;
}
.InviteYourProviders .Input-control .form-control {
  border-width: 1px;
}
.InviteYourProviders .Input-control.form-group .form-control:focus,
.InviteYourProviders .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.InviteYourProviders .Select-control.form-group SELECT {
  border-width: 1px;
}
@media (hover: hover) {
  .InviteYourProviders .signup-bottom SPAN:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .InviteYourProviders .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .InviteYourProviders .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .InviteYourProviders .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 30px;
    letter-spacing: normal;
  }
  .InviteYourProviders .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .InviteYourProviders .signup-btn .CircleButton {
    margin-top: 30px;
  }
  .InviteYourProviders .TagsInput {
    margin-bottom: 20px;
  }
}

.InviteMultipleProvider-maindiv {
  display: grid;
  grid-template-columns:1fr;
  border-bottom: 1px solid #c0bdbd;
  margin-bottom: 20px;
}
.InviteMultipleProvider-maindiv:last-child{
  border-bottom: none;
}
.InviteMultipleProvider-maindiv .input-div {
  padding-right: 5px;
}
.InviteMultipleProvider-maindiv .action-div {
  position: relative;
}
.InviteMultipleProvider-maindiv .action-btn {
  position: absolute;
  bottom: -10px;
  color: #d8d8d8;
  background-color: #013648;
  border: none;
  cursor: pointer;
  right: 0;
  border-radius: 5px;
  padding: 5px 10px;
}

.InviteMultipleProvider-maindiv .action-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
span.count-Indicater {
  border: 1px solid #999;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  text-align: center;
  padding-top: 3px;
  background-color: #013648;
  color: #fff;
}
.InviteMultipleProvider-maindiv-parent{
  position: relative;
}
.InviteMultipleProvider-maindiv-parent .dailog-close-btn{
  position: absolute;
  right: 0;
  top: -3px;
  border: none;
  width: 38px;
  height: 38px;
  background: none;
  cursor: pointer;
}
@media (max-width: 400px) {
  .InviteYourProviders .Input-control .form-control{
    font-size: 14px;
  }
}
.SignUp {
  padding: 76px 0;
}
.SignUp .container {
  padding: 0 60px;
  width: 100%;
}
.SignUp .signup-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
}
.SignUp .signup-row .step-ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 307px;
}
.SignUp .signup-row .step-li {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: rgba(1, 54, 72, 0.5);
  font-family: HeldaneDisplay-Regular;
}
.SignUp .signup-row .step-li.current {
  color: #013648;
}
.SignUp .middle-section {
  max-width: 418px;
  width: 100%;
  padding: 0 10px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .SignUp .container {
    padding: 0 15px;
  }
  .SignUp .signup-row .step-ul {
    width: 270px;
  }
  .SignUp .signup-row .step-li {
    font-size: 18px;
    line-height: 1.5;
  }
}
@media (max-width: 1024px) {
  .SignUp .middle-section {
    padding-left: 75px;
  }
}
@media (max-width: 767px) {
  .SignUp {
    padding: 39px 0;
  }
  .SignUp .signup-row .step-ul {
    display: none;
  }
  .SignUp .middle-section {
    padding: 0;
  }
}

.PatientLogin {
  padding: 120px 0;
  background-color: #fbfbf9;
  min-height: calc(100vh - 281px);
}
.PatientLogin .login-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.PatientLogin .h3 {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 26px;
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  font-weight: normal;
}
.PatientLogin .checkbox-block {
  margin-top: 20px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PatientLogin .Checkbox-control {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.PatientLogin .login-btn {
  margin-bottom: 47px;
  margin-top: 28px;
}
.PatientLogin .login-btn .CircleButton.WhiteButton {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.PatientLogin .login-bottom {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #013648;
}
.PatientLogin .login-bottom a {
  color: #3dbce7;
  font-weight: 500;
}
.PatientLogin .checkbox-block .reset-link {
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: center;
  color: #3dbce7;
}
.PatientLogin .password-group .form-control {
  padding-right: 35px;
}
.PatientLogin .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.PatientLogin .ca-social .social-icon {
  padding-top: 6px;
  display: inline-block;
  max-width: 100%;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  background-color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #013648;
  cursor: pointer;
  font-family: 'Calibre Medium';
}
.PatientLogin .ca-social {
  margin-bottom: 21px;
}
.PatientLogin .ca-social .social-icon img {
  margin-right: 12px;
  position: relative;
  top: -3px;
}
.PatientLogin .divider-content {
  position: relative;
  margin-bottom: 21px;
  margin-top: 20px;
}
.PatientLogin .resentdiv {
  text-align: right;
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.34px;
  cursor: pointer;
  color: #3dbce7;
}
.PatientLogin .divider-content .Divider .container {
  width: 100%;
}
.PatientLogin .divider-content > span {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #72787d;
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fbfbf9;
  padding: 9px;
  display: inline-block;
}
.PatientLogin .Input-control.transform-input input {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.PatientLogin .Input-control.transform-input input:focus {
  border: solid 1px #013648;
}
.PatientLogin .password-label {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  padding-left: 24px;
  width: 322px;
  max-width: 100%;
}

@media (hover: hover) {
  .PatientLogin .login-bottom a:hover {
    color: #013648;
  }
  .Login .checkbox-block .reset-link:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .PatientLogin {
    padding: 90px 0;
  }
  .PatientLogin .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
  .PatientLogin .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .PatientLogin .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .PatientLogin .divider-content .Divider {
    display: block;
  }
  .PatientLogin {
    padding: 32px 0;
    min-height: 100vh;
  }
  .PatientLogin .login-container {
    padding: 0 0px;
    width: 100%;
    max-width: 100%;
  }
  .PatientLogin .h3 {
    font-size: 28px;
    margin-bottom: 15px;
    padding-bottom: 12px;
    padding: 0 30px 15px;
    letter-spacing: 0;
    line-height: 1.21;
  }
  .PatientLogin .checkbox-block {
    margin-top: 25px;
    margin-bottom: 15px;
    padding-left: 11px;
  }
  .PatientLogin .login-btn {
    margin-bottom: 30px;
    margin-top: 23px;
  }
  .PatientLogin .ca-social {
    padding: 0 30px;
  }
  .PatientLogin .ca-social .social-icon {
    width: 100%;
    padding-top: 4px;
  }
  .PatientLogin .login-form {
    padding: 0px 30px;
  }
  .PatientLogin .login-form .Input-control.transform-input {
    margin-bottom: 20px;
  }
  .PatientLogin .ca-social .social-icon {
    font-size: 18px;
    border-radius: 2px;
    border: solid 1px rgba(1, 54, 72, 0.2);
    margin-bottom: 15px;
  }
  .PatientLogin .login-form .Input-control .form-control {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    color: #013648;
  }
  .PatientLogin .Checkbox-control {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    font-family: 'Calibre Medium';
  }
  .PatientLogin .checkbox-block .reset-link {
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -0.3px;
    font-family: 'Calibre Medium';
  }
  .PatientLogin .login-btn > * {
    width: 100%;
    height: 48px;
    line-height: 48px;
  }
  /* .PatientLogin ~ .CustomFooter {
    display: none;
  } */
}
@media (max-width: 413px) {
  .PatientLogin .login-form,
  .PatientLogin .ca-social {
    padding: 0px 15px;
  }
  .PatientLogin .h3 {
    padding: 0 15px 17px;
  }
  .PatientLogin .divider-content .Divider .container {
    padding: 0 15px;
  }
}

.PatientSignUpStepOne .login-btn {
  margin-bottom: 47px;
  margin-top: 28px;
}
.PatientSignUpStepOne .login-btn > * {
  width: 100%;
  height: 45px;
}
.PatientSignUpStepOne .login-btn > button {
  line-height: 45px;
}
.PatientSignUpStepOne .login-bottom {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #013648;
}
.PatientSignUpStepOne .login-bottom A {
  color: #3dbce7;
  font-weight: 500;
}
.PatientSignUpStepOne .password-group .form-control {
  padding-right: 35px;
}
.PatientSignUpStepOne .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.PatientSignUpStepOne .ca-social .social-icon {
  display: inline-block;
  max-width: 100%;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  background-color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  cursor: pointer;
}
.PatientSignUpStepOne .ca-social {
  margin-bottom: 21px;
}
.PatientSignUpStepOne .ca-social .social-icon IMG {
  margin-right: 11px;
}
.PatientSignUpStepOne .divider-content {
  position: relative;
  margin-bottom: 21px;
}
.PatientSignUpStepOne .divider-content .Divider .container {
  width: 100%;
}
.PatientSignUpStepOne .divider-content > SPAN {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #72787d;
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fbfbf9;
  padding: 9px;
  display: inline-block;
}
.PatientSignUpStepOne .Input-control.transform-input INPUT {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.PatientSignUpStepOne .Input-control.transform-input INPUT:focus {
  border: solid 1px #013648;
  box-shadow: none;
}
.PatientSignUpStepOne .password-label {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  padding-left: 24px;
  width: 322px;
  max-width: 100%;
}
.termsofuse .checkbox-block {
  display: flex;
  margin-bottom: 10px;
}
.termsofuse .checkbox-block .Checkbox-control {
  margin-right: 4px;
  top: -4px;
}
.termsofuse .checkbox-block .p-4{
  margin: 0 4px;
}
.termsofuse .modal-content-header .close img {
  width: 70%;
}
@media (hover: hover) {
  .PatientSignUpStepOne .login-bottom A:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .PatientSignUpStepOne .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .PatientSignUpStepOne .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .PatientSignUpStepOne .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .PatientSignUpStepOne .login-btn {
    margin-bottom: 30px;
  }
}

.PatientSignUpStepTwo .select-appointment-date {
  position: relative;
}
.PatientSignUpStepTwo .select-appointment-date .datepicker {
  position: absolute;
  z-index: 9;
  top: 80px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime .rdtPicker {
  height: 350px;
  overflow: hidden;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch {
  top: 34px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch:after {
  top: 10px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker .dow {
  text-transform: uppercase;
  font-family: 'Calibre Semibold';
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker TD {
  line-height: 1.7;
  padding-top: 5px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker TH.rdtNext,
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker TH.rdtPrev {
  top: 30px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime .rdtPicker .rdtDays TABLE THEAD TR .dow {
  border-spacing: 20px 5px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime .rdtPicker {
  padding-left: 0;
  padding-right: 0;
}
.PatientSignUpStepTwo .login-btn {
  margin-bottom: 47px;
  margin-top: 49px;
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.PatientSignUpStepTwo .login-btn .CircleButton.WhiteButton {
  width: 100%;
}
.PatientSignUpStepTwo .login-bottom {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #013648;
}
.PatientSignUpStepTwo .login-bottom A {
  color: #3dbce7;
  font-weight: 500;
}
.PatientSignUpStepTwo .password-group .form-control {
  padding-right: 35px;
}
.PatientSignUpStepTwo .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}
.PatientSignUpStepTwo .ca-social .social-icon {
  display: inline-block;
  max-width: 100%;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  background-color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}
.PatientSignUpStepTwo .ca-social {
  margin-bottom: 21px;
}
.PatientSignUpStepTwo .ca-social .social-icon IMG {
  margin-right: 11px;
}
.PatientSignUpStepTwo .divider-content {
  position: relative;
  margin-bottom: 21px;
}
.PatientSignUpStepTwo .divider-content .Divider .container {
  width: 100%;
}
.PatientSignUpStepTwo .divider-content > SPAN {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #72787d;
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fbfbf9;
  padding: 9px;
  display: inline-block;
}
.PatientSignUpStepTwo .Input-control.transform-input INPUT {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.PatientSignUpStepTwo .Input-control.transform-input INPUT:focus {
  border: solid 1px #013648;
  box-shadow: none;
}
.PatientSignUpStepTwo .password-label {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  padding-left: 24px;
  width: 322px;
  max-width: 100%;
}
.PatientSignUpStepTwo .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.PatientSignUpStepTwo .form-group-2-colum .Input {
  margin-left: 6px;
  margin-right: 6px;
}
.PatientSignUpStepTwo .checkbox-block.privacy {
  margin-top: 42px;
}
.PatientSignUpStepTwo .checkbox-block {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #013648;
  padding-left: 45px;
  position: relative;
  margin-bottom: 18px;
}
.PatientSignUpStepTwo .checkbox-block A {
  color: #3dbce7;
}
.PatientSignUpStepTwo .checkbox-block .Checkbox-control {
  position: absolute;
  left: 17px;
  top: -1px;
}

@media (hover: hover) {
  .PatientSignUpStepTwo .login-bottom A:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .PatientSignUpStepTwo .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .PatientSignUpStepTwo .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .PatientSignUpStepTwo .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .PatientSignUpStepTwo .login-btn {
    margin-bottom: 30px;
  }
  .PatientSignUpStepTwo .checkbox-block {
    padding-left: 30px;
  }
  .PatientSignUpStepTwo .checkbox-block .Checkbox-control{
    left: 0;
  }
}

.PatientSignUp {
  padding: 120px 0;
  background-color: #fbfbf9;
}
.PatientSignUp .ca-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.PatientSignUp .h3 {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 26px;
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  font-weight: normal;
}

@media (max-width: 1023px) {
  .PatientSignUp {
    padding: 90px 0;
  }
  .PatientSignUp .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .PatientSignUp {
    padding: 70px 0;
  }
  .PatientSignUp .h3 {
    font-size: 28px;
    margin-bottom: 35px;
    padding-bottom: 12px;
  }
}

.AppointmentConnecting {
  background-color: #013648;
  display: flex;
  position: relative;
}
.AppointmentConnecting .Ac-left .heading1 {
  font-family: 'Heldane Display';
  font-size: 64px;
  line-height: 0.94;
  text-align: center;
  min-height: 68px;
  letter-spacing: -0.2px;
  color: #fbfbf9;
  margin-bottom: 13px;
  margin-top: 0;
  font-weight: 400;
}
.AppointmentConnecting .sub-text {
  font-size: 24px;
  line-height: 1.17;
  text-align: center;
  letter-spacing: -0.27px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Calibre Light';
  font-weight: 300;
}
.AppointmentConnecting .Ac-left {
  width: 100%;
  padding-top: 176px;
  padding-left: 137px;
  padding-right: 137px;
  position: relative;
}
.AppointmentConnecting .Appointment-form {
  width: 887px;
  margin: auto;
  margin-top: 35px;
}
.AppointmentConnecting .Appointment-form FORM {
  display: flex;
  height: 68px;
  border-radius: 34px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  background-color: #ffffff;
  align-items: center;
  padding: 3px 7px 0px 36px;
}
.AppointmentConnecting .Appointment-form form > .Appointment-filed-control {
  padding-left: 0;
  min-width: 283px;
}
.AppointmentConnecting .Appointment-form .Input-control.form-group INPUT {
  height: auto;
  font-size: 17px;
  padding: 0;
}
.AppointmentConnecting .Appointment-form .Input-control .view-img {
  display: none;
}
.AppointmentConnecting .Appointment-form .SearchInput.form-group INPUT {
  height: 55px;
}
.AppointmentConnecting .Appointment-form .SearchInput .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
}
.AppointmentConnecting .Appointment-filed {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-left: auto;
}
.AppointmentConnecting .Appointment-filed > .Appointment-filed-control:last-child:after {
  display: none;
}
.AppointmentConnecting .CircleButton > SPAN > IMG {
  width: 25px;
  margin-left: 20px;
  position: relative;
  top: -2px;
}
.AppointmentConnecting .CircleButton > SPAN > IMG:nth-child(2) {
  display: none;
}
.AppointmentConnecting .CircleButton > SPAN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AppointmentConnecting .CircleButton {
  font-size: 16px;
  width: 148px;
  height: 54px;
  font-family: 'Calibre Regular';
  line-height: 55px;
  border-radius: 27px;
  position: relative;
  top: -2px;
  background: #3D93A6;
  border: 1px solid #3D93A6;
  color: #ffffff;
}
.AppointmentConnecting .CircleButton .search-icon {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
}
.AppointmentConnecting .CircleButton .search-icon > IMG {
  width: 100%;
  margin-left: 0;
  top: 2px;
}
.AppointmentConnecting .Ac-right {
  width: 44.5%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.AppointmentConnecting .Ac-right .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(24, 34, 42, 0.2);
}
.AppointmentConnecting .scroll-down {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  bottom: -52px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  opacity: 0.3;
}
.AppointmentConnecting .SelectDate INPUT {
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.AppointmentConnecting .Appointment-form .Input-control.form-group INPUT:focus {
  border: 0;
}
.AppointmentConnecting .Input-control.form-group INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: #72787d;
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: #72787d;
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: #72787d;
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group {
  margin-bottom: 0;
}
.AppointmentConnecting .Input-control.form-group INPUT,
.AppointmentConnecting .Input-control.form-group .form-control:not(:placeholder-shown) {
  border: 0;
}
.AppointmentConnecting .ElasticSearch {
  padding-right: 0px;
}
.AppointmentConnecting .Appointment-filed-control {
  position: relative;
  padding-left: 20px;
  padding-right: 15px;
}
.AppointmentConnecting .Appointment-filed-control:after {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  bottom: 11px;
  background-color: #b2bcc4;
  width: 1px;
}
.AppointmentConnecting .Appointment-filed .zip-code:before {
  right: auto;
  left: 0px;
}
.AppointmentConnecting .doctor-specialist-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 45px auto 36px;
  width: 1055px;
  max-width: 100%;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item SPAN {
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item SPAN:first-child {
  height: 112px;
  display: flex;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item {
  height: 100%;
  align-items: stretch;
  width: 181px;
  position: relative;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item IMG {
  margin: auto;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item SPAN.dsc-name {  
  font-size: 16px;  
  line-height: 1.4;
  letter-spacing: -0.3px;
  text-align: center;
  color: #fbfbf9;
  margin-top: 14px;
}
.AppointmentConnecting .Appointment-filed-control .label-text {
  font-family: 'Calibre Medium';
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.AppointmentConnecting .Appointment-filed-control.zip-code-control {
  width: 140px;
}
.AppointmentConnecting .Appointment-filed-control.select-date-contorl {
  width: 160px;
}
.AppointmentConnecting .ElasticSearch .Input-control.form-group INPUT.form-control::placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .InsuranceFilterSelect .Input-control.form-group INPUT.form-control::placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting
  .InsuranceFilterSelect
  .Input-control.form-group
  INPUT.form-control:-moz-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .ElasticSearch .search .search-result {
  top: 31px;
  min-width: 255px;
}
.AppointmentConnecting .SelectDate .datepicker {
  top: 31px;
  left: -23px;
}
.AppointmentConnecting .InsuranceFilterSelect .result {
  left: -21px;
  top: 31px;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item IMG {
  transform: scale(1);
  transition: 0.3s all linear;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item:hover IMG {
  transform: scale(1.2);
}

@media (hover: hover) {
  .AppointmentConnecting .CircleButton:hover:enabled > SPAN > IMG:nth-child(2) {
    display: block;
  }
  .AppointmentConnecting .CircleButton:hover:enabled > SPAN > IMG:nth-child(1) {
    display: none;
  }
  .AppointmentConnecting .CircleButton:hover:enabled > .search-icon > IMG:nth-child(1) {
    display: inline-block;
  }
}

@media (max-width: 1366px) {
  .AppointmentConnecting .Ac-left {
    padding-right: 70px;
    padding-left: 70px;
  }
}

@media (max-width: 1200px) {
  .AppointmentConnecting .Ac-left {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 1024px) {
  .AppointmentConnecting .Ac-right {
    background-position: -104px;
  }
  .AppointmentConnecting .Ac-left .heading1 {
    font-size: 48px;
  }
  .AppointmentConnecting .Appointment-form FORM {
    flex-wrap: wrap;
    height: auto;
    padding: 0px 15px 0px 15px;
  }
  .AppointmentConnecting .Appointment-filed .zip-code {
    padding-left: 0;
  }
  .AppointmentConnecting .CircleButton {
    width: 100%;
  }
  .AppointmentConnecting .ElasticSearch {
    min-width: 100%;
  }
  .AppointmentConnecting .ElasticSearch .Input-control.form-group INPUT,
  .AppointmentConnecting .SelectDate,
  .AppointmentConnecting .InsuranceFilterSelect {
    padding-left: 0;
  }
  .AppointmentConnecting .Appointment-filed {
    margin-left: 0;
    width: 100%;
  }
  .AppointmentConnecting .InsuranceFilterSelect :after,
  .AppointmentConnecting .Appointment-filed .zip-code:after,
  .AppointmentConnecting .Appointment-filed .zip-code:before {
    left: 0;
    display: none;
  }
  .AppointmentConnecting .Appointment-form FORM {
    background-color: transparent;
    border-radius: 0;
    border: 0;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed-control {
    background-color: #fff;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 15px;
    min-height: 57px;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed .Appointment-filed-control {
    width: 32%;
  }
  .AppointmentConnecting .Appointment-filed-control:after {
    display: none;
  }
  .AppointmentConnecting .Appointment-form {
    width: 100%;
  }
  .AppointmentConnecting .InsuranceFilterSelect .result {
    min-width: 254px;
  }
  .AppointmentConnecting .doctor-specialist-content .dsc-item {
    width: 33.33%;
    margin-bottom: 30px;
  }
  .AppointmentConnecting .doctor-specialist-content {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .AppointmentConnecting .Ac-right {
    display: none;
  }
  .AppointmentConnecting .Ac-left {
    width: 100%;
    padding-top: 10px;
  }
  .AppointmentConnecting .sub-text {
    /* display: none; */
  }
  .AppointmentConnecting .Appointment-form {
    margin-top: 40px;
    text-align: center;
  }
  .AppointmentConnecting .Appointment-filed {
    width: 100%;
    flex-wrap: wrap;
  }
  .AppointmentConnecting {
    height: auto;
    margin-top: -1px;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed-control {
    min-height: 59px;
  }
  .AppointmentConnecting .scroll-down {
    display: none;
  }
  .AppointmentConnecting .CircleButton {
    width: 100%;
  }
  .AppointmentConnecting .ElasticSearch,
  .AppointmentConnecting .Ac-left .heading1 {
    max-width: 100%;
  }
  .AppointmentConnecting .Appointment-filed .zip-code,
  .AppointmentConnecting .SelectDate,
  .AppointmentConnecting .InsuranceFilterSelect {
    min-width: 100%;
    width: 100%;
  }
  .AppointmentConnecting .InsuranceFilterSelect {
    min-width: 100%;
    width: 100%;
  }  
  .AppointmentConnecting .Appointment-form FORM {
    padding: 0;
  }
  .AppointmentConnecting .Appointment-filed-control .label-text {
    text-align: left;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed .Appointment-filed-control {
    width: 100%;
  }
  .AppointmentConnecting .Ac-left .heading1 {
    font-size: 40px;
    line-height: 1.2;
  }
  .PatientLandingHeader .mobile-menu {
    display: block;
  }
  .AppointmentConnecting .InsuranceFilterSelect .result {
    left: 0;
  }
}

@media (max-width: 575px) {
  .AppointmentConnecting .doctor-specialist-content .dsc-item {
    width: 50%;
  }
  .AppointmentConnecting .doctor-specialist-content .dsc-item {
    width: 145px;
  }
}

@media (max-width: 413px) {
  .AppointmentConnecting .Ac-left {
    padding-left: 15px;
    padding-right: 15px;
  }
  .AppointmentConnecting .Ac-left .heading1 {
    font-size: 36px;
  }
  .AppointmentConnecting .Appointment-form .Input-control .view-img {
    left: 15px;
  }
  .AppointmentConnecting .SelectDate .datepicker{
    left: -22px;
    width: auto;
  }
}

@supports (-webkit-touch-callout: none) {
  .AppointmentConnecting INPUT::placeholder {
    padding-top: 2px;
  }
}

.DoctorToday {
  padding: 125px 0 67px;
  background-color: rgba(234,244,235,.2);
}
.DoctorToday .heading2 {
  font-family: 'Heldane Display';
    font-size: 54px;
    line-height: 1;
    letter-spacing: -.9px;
    text-align: center;
    color: #013648;
    width: 64%;
    margin: 0 auto;
    margin-bottom: 48px;

}
.DoctorToday .today-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}
.DoctorToday .today-col {
  width: 33.33%;
  padding: 0 15px;
  text-align: center;
}
.DoctorToday .today-col .today-box {
  max-width: 314px;
  width: 100%;
  margin: 0 auto 34px;
  background: #ffffff;
  padding: 26px 18px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: solid 1px rgba(162, 168, 174, 0.55);
  min-height: 260px;
}
.DoctorToday .today-col.symptoms-today-col .today-box  .today-box-inner {
  max-width: 243px;
  width: 100%;
  margin: 0 auto;
}
.DoctorToday .today-col .heading3.fst {  
  /* width: 197px; */
  margin: 0 auto;
  margin-bottom: 36px;
}
.DoctorToday .today-col .heading3 {  
  font-family: 'Heldane Display';
  font-size: 25.6px;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 30px;
}
.DoctorToday .today-col.appoinment-today-col .heading3 {
  margin-bottom: 16px;
}
.DoctorToday .today-col .sf-description-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 13px;
}
.DoctorToday .today-col .sf-description-item:last-child {
  margin-bottom: 0;
}
.DoctorToday .today-col .sf-description-item.active SPAN {
  background-color: #013648;
  color: #fff;
}
.DoctorToday .today-col .sf-description-item.second SPAN{
  background-color: #e7ebeb;
  color: #013648;
}
.DoctorToday .today-col .sf-description-item SPAN {
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  background-color: #e7ebeb;
  width: 100%;
  color: #fff;  
text-align: center;
   font-family: Calibre Medium;
font-style: normal;
font-weight: normal;
  display: inline-block;  
  padding: 5px 15px 5px;  
  font-size: 17px;
  line-height: 157.5%;
  letter-spacing: -0.26px;
}
.DoctorToday .today-desc {
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: -0.4px;
  text-align: center;
  color: #013648;
  max-width: 335px;
  margin: 0 auto;
  width: 100%;
}
.DoctorToday .symptoms-today-col .today-desc {
  max-width: 270px;
}
.DoctorToday .appoinment-today-col .today-desc {
  max-width: 260px;
}
.DoctorToday .appoinment-timing .time {
  text-align: left;
  width: 96px;
  min-width: 96px;
  height: 102px;
  border-radius: 4.8px;
  background-color: #013648;    
  margin: 0 auto;
  display: flex;
  padding: 18px 0 18px 11px;
  font-size: 22px;
  line-height: 1.09;
  letter-spacing: -0.41px;
  color: #f7fffc;
}
.DoctorToday .appoinment-timing .time > SPAN {
  margin: auto;
}
.DoctorToday .appoinment-timing .time .date, 
.DoctorToday .appoinment-timing .time .day {
  display: inline-block;
  width: 100%;
}
.DoctorToday .appoinment-timing .time .day {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.DoctorToday .doctor-profile-wrapper {
  display: flex;
  text-align: left;
  margin-bottom: 18px;
}
.DoctorToday .doctor-profile-wrapper .profile-photo {
  width: 61px;
  height: 79px;
  min-width: 61px;
}
.DoctorToday .doctor-profile-wrapper .profile-photo IMG {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: #d8d8d8;
  object-fit: cover;
  margin-top: 1px;
}
.DoctorToday .doctor-profile-wrapper .profile-info {
  flex: 1 1;
  padding-left: 11px;
}
.DoctorToday .doctor-profile-wrapper .profile-row {
  display: flex;
}
.DoctorToday .doctor-profile-wrapper .profile-name {
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: -0.18px;
  color: #013648;
  font-family: 'Heldane Display';
  text-align: left;
  margin-bottom: 7px;
}
.DoctorToday .doctor-profile-wrapper .profile-specialist {
  font-size: 14px;
  line-height: 0.97;
  letter-spacing: normal;
  color: #013648;
  text-align: left;
}
.DoctorToday .doctor-profile-wrapper .profile-row .profile-left {
  flex: 1 1;
  padding-right: 10px;
}
.DoctorToday .doctor-profile-wrapper .rating-image {
  text-align: right;
  position: relative;
  display: block;
}
.DoctorToday .doctor-profile-wrapper .rating-image .star-icon {
  font-size: 35.2px;
  line-height: 1.42;
  color: #013648;
  position: relative;
  top: 8px;
  z-index: 1;
  left: 3px;
  display: inline-block;
  width: 22px;
  height: 21px;
}
.DoctorToday .doctor-profile-wrapper .rating-image .review-count {  
  position: relative;
  top: 0;
  display: inline-block;
  font-family: 'Heldane Display';
  font-weight: normal;
  font-size: 25.9px;
  line-height: 0.71;
  letter-spacing: -0.52px;
  color: #013648;
}
.DoctorToday .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorToday .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(38, 172, 218, 0.2);  
}
.DoctorToday .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(1n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(2n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(3n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(4n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(38, 172, 218, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(5n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(6n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorToday .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 11px;
}
.DoctorToday .dc-symptoms-result {
  min-height: 28px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.DoctorToday .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.DoctorToday .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorToday .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorToday .pt-category-box .box:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.DoctorToday .progress-bar .dc-symptoms {  
  font-size: 14px;
  line-height: 1;
  letter-spacing: normal;
  color: #a2a8ae;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.DoctorToday .progress-bar .dc-symptoms .symptoms-txt {
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.DoctorToday .rating-block {
  text-align: left;
  padding-top: 16px;
  border-top: 1px solid rgba(151,151,151,0.23);
}
.DoctorToday .rating-block .star-rating {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.DoctorToday .rating-block .star-rating .rating-inner {
  display: flex;
  align-items: center;
  margin-right: 23px;
}
.DoctorToday .rating-block .star-rating .rating-inner:last-child {
  margin-right: 0;
}
.DoctorToday .rating-block .star-rating .name {
  margin-right: 4px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.58;
  letter-spacing: -0.19px;
  color: #013648;
}
.DoctorToday .rating-block .star-rating .rate-icon {
  width: 12px;
  margin-right: 4px;
  height: 11px;
  position: relative;
  top: -4px;
  left: 0;
}
.DoctorToday .rating-block .star-rating .number { 
  font-family: 'Heldane Display';
  font-weight: normal;
  position: relative;
  top: -2px;
  font-size: 17px;
  line-height: 0.7;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
}
.DoctorToday .review-content {
  font-size: 16px;
  line-height: 1.13;
  letter-spacing: -0.2px;
  color: #013648;
}
.DoctorToday .video-btn {
  width: 140px;
  color: #8d77ba;
  height: 34px;
  border-radius: 14.1px;
  background-color: #eeebf5;
  display: inline-block;
  text-align: left;
  position: relative;
  padding-left: 31px;
  font-family: 'Calibre Medium';
  font-size: 16.6px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.31px;
  margin: 0 auto 21px;
}
.DoctorToday .video-btn span{
  position: relative;
    top: 4px;
    left: 17px;
}
.DoctorToday .video-btn IMG {
  position: absolute;
  left: 0;
  width: 34px;
  height: 34px;
}
.DoctorToday .rating-parent-div{
  display: flex;
}
.DoctorToday .rating-parent-div span{
  font-size: 13px;
    margin-left: 4px;
    top: 3px;
    position: relative;
}
@media (hover: hover) {
  /* .DoctorToday .today-col .sf-description-item.active:hover SPAN {
    background-color: #e7ebeb;
    color: #013648;
  } */
  .DoctorToday .today-col .sf-description-item.second:hover SPAN {
    background-color: #013648;
color: #fff;
  }
}

@media (max-width: 1024px) {
  .DoctorToday {
    padding: 80px 0 67px;
  }
  .DoctorToday .heading2 {
    font-size: 30px;
    margin-bottom: 45px;
  }
  .DoctorToday .today-col .heading3 {
    font-size: 22px;
  }
  .DoctorToday .today-desc {
    font-size: 21px;
  }
}

@media (max-width: 991px) {
  .DoctorToday .today-col {
    width: 100%;
    margin-bottom: 15px;
  }
  .DoctorToday .today-col:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .DoctorToday {
    padding: 70px 0 50px;
  }
  .DoctorToday .today-row{
    margin-bottom: 0;
  }
  .DoctorToday .heading2 {
    font-size: 28px;
  }
  .DoctorToday .dc-symptoms-result {
    left: auto;
    right: 0;
  }
  .DoctorToday .heading2 {
    width: 100%;
  }
}
.DoctorArticle {
  padding: 51px 0 30px;
  background-color: #fbfbf9;
}
.DoctorArticle .da-deserve-title .heading2 {
  font-family: 'Heldane Display';
  font-size: 74px;
  line-height: 0.78;
  color: #013648;
  margin-bottom: 32px;
  margin-top: 178px;
  padding-right: 100px;
  letter-spacing: -1.23px;
}
.DoctorArticle .da-deserve-title .paragaph {
  width: 544px;
  height: 188px;
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.DoctorArticle .container .da-arical-row {
  display: flex;
}

.DoctorArticle .da-arical-colum {
  width: 50%;
  position: relative;
  overflow: hidden;
}
.DoctorArticle .da-arical-colum.makingtheright {
  width: 100%;
}
.DoctorArticle .da-arical-colum .hiddenlogodiv {
  position: absolute;
  left: -35%;
  width: 513px;
  top: -104%;
  height: 643px;
  overflow: hidden;
}
.DoctorArticle .da-arical-colum .hiddenlogo {
  width: 359px;
}
.DoctorArticle .dc-artical .heading2 {
  font-family: 'Heldane Display';
  /* font-style: italic; */
  font-weight: normal;
  font-size: 28px;
  line-height: 119%;
color: #013648;
}
.DoctorArticle .dc-artical .description {
  font-family: Calibre Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 163.5%;
  color: #013648;
  width: 403px;
  margin-top: 10px;
}
.scrolling-div {
  height: 300px;
}

element.style {
}

.DoctorArticle .dc-artical .img IMG {
  width: 85%;
}
.DoctorArticle .dc-artical .img IMG.mainImg {
  width: 366px;
}
.DoctorArticle .dc-artical .img IMG.design1 {
  position: relative;
  top: -30px;
  left: 10px;
}
.DoctorArticle .dc-artical .img IMG.design2 {
  position: relative;
  top: -130px;
  left: 10px;
}
.DoctorArticle .dc-artical .img IMG.designyellow {
  position: relative;
  top: 60px;
  left: 66px;
}
.DoctorArticle .Losingyourvision {
  /* width: 325px; */
  font-family: Heldane Display;
  /* font-style: italic; */
  font-weight: normal;
  font-size: 37px;
  line-height: 119%;
  margin-left: 23px;
  margin-top: 106px;
  /* max-height: 60px; */
  margin-bottom: 35px;
  /* overflow: hidden; */
  color: #013648;
}
.DoctorArticle .para {
  /* width: 382px; */
  font-family: Calibre Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 119%;
  color: #013648;
  margin-left: 22px;
  margin-top: 0;
  overflow: hidden;
  max-height: 60px;
}
.DoctorArticle .dc-artical .img {
  position: relative;
  left: 40px;
  margin-bottom: 30px;
}
.DoctorArticle .dc-artical1 .description,
.DoctorArticle .dc-artical1 .heading2 {
  margin-left:40px;
}
.DoctorArticle .dc-artical2 {
  /* margin-top: -209px; */
}
.DoctorArticle .dc-artical1 .description {
  margin-top: 14px;
}
.DoctorArticle .dc-artical2 .img IMG {
  width: 543px;
}
.DoctorArticle .dc-artical2 .img {
  position: relative;
  left: 0;
}
.DoctorArticle .dc-artical2 .description {
  margin-top: 14px;
}
.DoctorArticle .da-arical-Powered {
  padding-top: 0;
}
.DoctorArticle .dc-artical.dc-artical3 {
  margin-top: -334px;
}
.DoctorArticle .da-arical-Powered .container {
  display: flex;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
  background-color: #fff;
  padding: 30px;
  display: flex;
  position: relative;
  left: 0px;
  top: 5px;
}
.DoctorArticle .da-arical-Powered .da-arical-left {
  width: 50%;
  /* padding-left: 20%; */
}
.DoctorArticle .da-arical-Powered .da-arical-left img.logo {
  width: 183px;
  height: 183px;
  margin-left: 57px;
  margin-top: -37px;
}
.DoctorArticle .da-arical-Powered .da-arical-left img.doctor5 {
  margin-left: 10px;
  margin-top: 89px;
}

.DoctorArticle .da-arical-Powered .da-arical-left .makerightchoice {
  font-family: Heldane Display;
  font-weight: normal;
  font-size: 22px;
  line-height: 119%;
  margin: 0;
  margin-top: 31px;
  margin-left: 10px;
  color: #013648;
}
.DoctorArticle .da-arical-Powered .da-arical-left .paraputtingyourhealth {
  font-family: Calibre Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 163.5%;
  color: #013648;
  margin-left: 10px;
  margin-top: 14px;
}
.DoctorArticle .da-arical-Powered .da-arical-right {
  width: 50%;
  padding-left: 15px;
}
.DoctorArticle .da-arical-Powered .da-arical-right img {
  width: 250px;
}
.DoctorArticle .da-arical-Powered .da-arical-right.logoimgdiv {
  padding-top: 150px;
  padding-left: 50px;

}
.DoctorArticle .da-arical-Powered .da-arical-right .img {
  left: 0;
  margin-bottom: 35px;
  width: 423px;
  height: 247px;
  background: #ffffff;
  border: 1px solid rgba(162,168,174,.55);
  box-sizing: border-box;
  border-radius: 8px;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left,
.da-arical-Powered .da-arical-right .img .patients-result .right {
  width: 50%;
}
.DoctorArticle .patients-result .right {
  padding-top: 0px;
}
.DoctorArticle .da-arical-Powered .review .star {
  width: 61px;
  height: 58px;
  position: relative;
  top: 39px;
  left: 4px;
}
.DoctorArticle .patients-result .left .google-logo {
  width: 110px;
  margin-top: 62px;
  margin-left: 22px;
}
.DoctorArticle .da-arical-Powered .review .review-rate {
  font-family: 'Heldane Display';
  font-size: 70px;
  line-height: 0.77;
  letter-spacing: -1.92px;
  color: #013648;
  position: relative;
  top: 35px;
  left: -11px;
}
.DoctorArticle .patients-result .patients-treated .h3 {
  font-size: 14px;
  line-height: 0.95;
  color: #013648;
  margin-bottom: 11px;
}
.DoctorArticle .patients-result .patients-treated-btn {
  display: inline-block;
  margin-bottom: 18px;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN {
  display: inline-block;
  margin-right: 3px;
  width: 51px;
  height: 13px;
  border-radius: 2px;
  background-color: #3d93a6;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN.sciatica {
  background-color: #e85d72;
  width: 45px;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN.herniated {
  background-color: #8d77ba;
  width: 31px;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN.scoliosis {
  background-color: #f2962d;
  width: 38px;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: -0.38px;
  color: #1aa2b4;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN.spansciatica {
  color: #e85d72;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN.herniated {
  color: #8d77ba;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN.scoliosis {
  color: #f2962d;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
  width: 314px;
  position: absolute;
  right: -49px;
  top: -36px;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
  width: 277px;
  left: -99px;
  position: absolute;
  top: -12px;
  z-index: 1;
}

.DoctorArticle .dc-artical2 .description {
  padding-right: 30px;
}

.Losingyourvision.looping-animation-Q span.animation,
.para.looping-animation-A span.animation {
  animation: looping 6s ease-in-out;
}
.Losingyourvision.looping-animation-Q span.hide,
.para.looping-animation-A span.hide {
  opacity: 0;
  animation: hidding 1s ease-in-out;
}
.DoctorArticle .Losingyourvision {
  /* min-height: 80px; */
  margin-bottom: 15px;
}
.DoctorArticle .para {
  min-height: 60px;
}
@keyframes looping {
  20%,
  100% {
    opacity: 1;
    line-height: 26px;
    transform: translateY(0px);
  }

  0% {
    opacity: 0;
    line-height: 150px;
    transform: translateY(550px);
  }
}
@keyframes hidding {
  20%,
  100% {
    opacity: 0;
    transform: translateY(-10);
  }

  0% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1400px) {
  .DoctorArticle .dc-artical .img {
    right: 0;
  }
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 40px;
  }
  .DoctorArticle .dc-artical2 .img IMG {
    width: 100%;
  }
  .DoctorArticle .dc-artical .description {
    width: 100%;
  }
  .DoctorArticle .dc-artical1 .description {
    width: calc(100% - 65px);
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 0;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    padding-right: 0;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding: 30px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    right: 0;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left {
    width: 45%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .right {
    width: 55%;
  }
}

@media (max-width: 1366px) {
  .DoctorArticle .da-deserve-title .heading2 {
    font-size: 60px;
  }

  .DoctorArticle .dc-artical2 .description {
    padding-right: 0;
  }
}

@media (max-width: 1200px) {
  .DoctorArticle .dc-artical .img .design1,
  .DoctorArticle .dc-artical .img .design2,
  .DoctorArticle .dc-artical .img .designyellow {
    display: none;
  }
  .DoctorArticle .da-arical-Powered {
    padding-top: 50px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    font-size: 48px;
    margin-top: 100px;
  }

  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 40px;
  }
  .DoctorArticle .da-deserve-title .paragaph {
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
    width: 200px;
    left: -50px;
    top: 0px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    width: 245px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 54px;
    top: 32px;
    left: -16px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 70px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    width: 90px;
    margin-left: 0;
  }
  .DoctorArticle.da-arical-Powered .patients-treated .h3 {
    font-size: 16px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN {
    width: 58px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.sciatica {
    width: 40px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.herniated {
    width: 30px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.scoliosis {
    width: 30px;
  }

  .DoctorArticle .dc-artical .img IMG.mainImg {
    width: 65%;
    float: right;
  }
  /* .DoctorArticle .da-arical-colum .hiddenlogo{
    left: -74%;
    width: 134%;
  } */
}

@media (max-width: 1024px) {
  .DoctorArticle {
    padding: 70px 0 50px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    padding-right: 0;
  }
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 40px;
  }
  .DoctorArticle .dc-artical .heading2 {
    font-size: 24px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 18px;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 45px;
        text-align: left;
        width: 91%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left {
    padding-left: 0px;
    text-align: center;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left IMG {
    width: 50%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left .makingtheright IMG{
    width: 80%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
    width: 150px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    width: 150px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    padding-right: 10px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    top: -14px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 48px;
    top: 29px;
    left: -7px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left {
    width: 40%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .right {
    width: 60%;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 50px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding: 30px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN {
    width: 49px;
  }
  .DoctorArticle .Losingyourvision {
    max-height: 80px;
  }
}
@media (max-width: 997px){
.DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 36px;
        text-align: left;
        width: 91%;
  }
}
@media (max-width: 991px){
.DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv{
width: 342px;
}
}
@media (max-width: 767px) {
  .DoctorArticle .container .da-arical-row {
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin: 0px -30px;
  }
  .scrolling-div{
    margin-top: 50px;
    height: 150px;
  }
 
.DoctorArticle .dc-artical.dc-artical3{
  margin: 0;
}
.DoctorArticle .da-arical-Powered .da-arical-right.logoimgdiv {
  text-align: center;
  padding: 0;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv {
  width: 420px;
}
  .DoctorArticle .dc-artical .img IMG.mainImg {
    width: 42%;
    float: none;
  }
  .DoctorArticle .container .da-arical-row:last-child {
    margin-bottom: 0;
  }
  .DoctorArticle .da-arical-colum,
  .DoctorArticle .da-arical-colum {
    width: 100%;
  }
  .DoctorArticle {
    padding-top: 77px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 0;
  }
  .DoctorArticle .dc-artical2 {
    margin-top: 65px;
  }
  .DoctorArticle .dc-artical .img {
    text-align: center;
  }
  .DoctorArticle .Losingyourvision {
    /* max-height: 60px;
    min-height: 60px; */
    width: 100%;
    font-size: 30px;
    text-align: center;
    margin: 0;
    margin-bottom: 15px;
  }
  .DoctorArticle .para {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .DoctorArticle .da-deserve-title .da-arical-colum {
    text-align: center;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 0;
  }
  .DoctorArticle .dc-artical .heading2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 20px;
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left .logo {
    display: none;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left {
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right {
    width: 100%;
    padding-left: 0;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 66px;
  }
 
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding: 30px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    position: absolute;
    left: 47px;
    bottom: 22px;
    width: 108px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    width: 65%;
    top: 58px;
    right: -40px;
  }
  .DoctorArticle .da-arical-Powered {
    overflow: hidden;
    padding-top: 27px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
    left: -22px;
    top: 81px;
  }
  .DoctorArticle .patients-result .patients-treated .h3 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .DoctorArticle .da-deserve-title,
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    padding: 0px 30px;
    text-align: center;
  }
  .DoctorArticle .dc-artical .img {
    overflow: hidden;
    padding: 0;
    left: unset;
    right: unset;
  }
  .DoctorArticle .dc-artical .img IMG {
    width: 100%;
  }
  /* .DoctorArticle .dc-artical1 .img IMG {
    width: 127%;
    margin: 0 -12%;
  } */
  .DoctorArticle {
    padding-top: 0;
  }
  .DoctorArticle .da-arical-colum .hiddenlogo {
    display: none;
  }
  .DoctorArticle .dc-artical2 .img IMG {
    width: 122%;
    margin: 0px -11%;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    width: 100%;
  }
  .DoctorArticle .dc-artical.dc-artical1 {
    margin-top: 0;
    padding: 30px;
  }
  .DoctorArticle .da-arical-Powered .container {
    display: block;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    margin: 0 auto;
  }
  .DoctorArticle .dc-artical3 .description,
  .DoctorArticle .dc-artical3 .heading2 {
    text-align: center;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left {
    width: 55%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .right {
    width: 59%;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    top: 28px;
    left: -1px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    top: 21px;
    left: -19px;
    font-size: 58px;
}
  .DoctorArticle .patients-result .patients-treated-percent SPAN {
    font-size: 13.8px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN {
    height: 12px;
    width: 48px;
    font-size: 13.8px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.sciatica {
    width: 41px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.herniated {
    width: 28px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.scoliosis {
    width: 28px;
  }
  .DoctorArticle .patients-result .patients-treated-btn {
    margin-bottom: 9px;
    display: flex;
  }
  .DoctorArticle .patients-result .patients-treated {
    text-align: left;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left IMG {
    width: 80%;
  }
}

@media (max-width:575px){
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding-bottom: 0;
  }
  .DoctorArticle .patients-result .left .google-logo {
    
    bottom: -4;
    
  }
}
@media (max-width: 474px) 
{
  .DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv {
    width: 95%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img{
    height: 200px;
  }
}

@media (max-width: 474px) {
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    width: 80%;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 50px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv {
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 54.1px;
    top: 21px;
    left: -15px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    width: 86px;
    left: 37px;
    top: 71px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left IMG {
    width: 98%;
  }
  /* .DoctorArticle .Losingyourvision {
    max-height: 100px;
    min-height: 100px;
  } */
}
@media (max-width: 500px) {
  .DoctorArticle .dc-artical .heading2 ,
  .DoctorArticle .dc-artical2 .heading2{
    font-size: 24px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 17px;
  }
}
@media (max-width: 413px) {
  .DoctorArticle .da-deserve-title,
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    padding: 0px 15px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    font-size: 42px;
    margin-bottom: 20px;
  }
  .DoctorArticle .da-deserve-title .paragaph {
    font-size: 18px;
  }
  .DoctorArticle .dc-artical .heading2 {
    font-size: 26px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 18px;
  }
  .DoctorArticle .dc-artical2 .img IMG {
    width: 100%;
    margin: 0px -9%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right.logoimgdiv img {
   width: 80%;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 15px;
    padding-right: 15px;
  }
  .DoctorArticle .dc-artical .heading2 ,
  .DoctorArticle .dc-artical2 .heading2{
    font-size: 20px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 16px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 49.1px;
    left: -28px;
    top: 1px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 52px;
    left: -15px;
    top: 10px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    width: 27%;
    left: 6%;
    top: 51px;
  }
  /* .DoctorArticle .da-arical-Powered .dc-artical3 .heading2,
  .DoctorArticle .da-arical-Powered .dc-artical3 .description {
    margin: 0px -15px 30px;
  } */
  
  
  
}
@media (max-width: 400px) {
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 43.1px;
    left: -28px;
    top: 1px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 45px;
    left: -15px;
    top: 10px;
  }
}
@media (max-width: 370px) {
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 37.1px;
    left: -24px;
    top: -5px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 33px;
    left: -15px;
    top: 10px;
  }
  .DoctorArticle .dc-artical .heading2 ,
  .DoctorArticle .dc-artical2 .heading2{
    font-size: 18px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 15px;
  }
}
.AmazingDoctor {
  text-align: center;
  padding-top: 93px;
  padding-bottom: 106px;
}
.AmazingDoctor .title {
  width: 585px;
  max-width: 100%;
  font-family: 'Heldane Display';
  font-size: 64px;
  line-height: 1.03;
  text-align: center;
  color: #013648;
  margin: auto;
  margin-bottom: 29px;
}
.AmazingDoctor .AmazingDoctor-content .description {
  width: 544px;
  max-width: 100%;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #013648;
  margin: auto;
  margin-bottom: 60px;
}
.AmazingDoctor .AmazingDoctor-content .CircleButton.WhiteButton {
  width: 288px;
  height: 54px;
  line-height: 54px;
  font-family: 'Calibre Regular';
}

@media (max-width: 1024px) {
  .AmazingDoctor {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .AmazingDoctor .title {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .AmazingDoctor .title {
    margin-bottom: 30px;
  }
  .AmazingDoctor .AmazingDoctor-content .CircleButton.WhiteButton {
    width: 241px;
    height: 48px;
    line-height: 48px;
  }
  .AmazingDoctor .AmazingDoctor-content .description {
    margin-bottom: 30px;
  }
  .AmazingDoctor {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .AmazingDoctor .title {
    font-size:39px;
  }
}

@media (max-width: 413px) {
  .AmazingDoctor .title {
    font-size: 29px;
  }
  .AmazingDoctor .AmazingDoctor-content .description {
    font-size: 18px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AmazingDoctor .AmazingDoctor-content .CircleButton.WhiteButton {
    padding-top: 4px;
  }
}

.BuiltDoctors {
  padding-top: 70px;
  background-color: #fbfbf9;
  padding-bottom: 108px;
}
.BuiltDoctors .title {
  font-family: 'Heldane Display';
  font-size: 48px;
  line-height: 1.38;
  text-align: center;
  color: #013648;
  margin-bottom: 46px;
}
.BuiltDoctors .BuiltDoctors-testimonial {
  /* display: flex;
  border-radius: 9.5px;
  overflow: hidden; */
}

.BuiltDoctors .BuiltDoctors-testimonial  .slick-track{ margin-left: 0; display: flex }

.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
  width: 285px;
  /* min-width: 285px; */
  padding: 58px 49px 275px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 728px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-date {
  margin-top: auto;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
  width: 77%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 280px; */
  margin: auto;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name span {
  display: inline-block;
  width: 100%;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.harmonis {
  background-color: #E2DEDD;
}

.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.pipi {
  background-color: #F7B1AF;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.Onahau {
  background-color: #ECECEC;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem.Sinbad {
  background-color: #D8E1F0;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name {
  opacity: 0.9;
  font-size: 16px;
  line-height: 1.22;
  color: #013648;
  margin-bottom: 27px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name .name {
  font-family: 'Heldane Display';
  font-size: 18px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-description {
  opacity: 0.8;
  font-size: 18px;
  line-height: 1.28;
  color: #013648;
  margin-bottom: 35px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-date span {
  display: inline-block;
  width: 100%;
  opacity: 0.79;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}

@media (max-width: 1200px) {
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    padding: 58px 20px 275px;
    width: 320px;
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
    width: 160px;
  } */
}

@media (max-width: 1024px) {
  .BuiltDoctors .title {
    font-size: 36px;
  }
  .BuiltDoctors {
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    padding-bottom: 200px;
   
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-name {
    font-size: 16px;
  }
}
@media (max-width: 990px) {
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    
    width: 360px;
  }
}
@media (max-width: 767px) {
  .BuiltDoctors .title {
    font-size: 48px;
    text-align: center;
    line-height: 1.08;
    margin-bottom: 70px;

  }
  .BuiltDoctors {
    padding-top: 0;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem {
    /* min-width: 400px; */
    width: calc(100vw - 65px);
    margin-right: 37px;
    padding-left: 50px;
    padding-right: 30px;
    padding-bottom: 300px;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem .dc-date{
    margin-top: 30px;
  }
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img{
    width: 65%;
  }
  .BuiltDoctors .BuiltDoctors-testimonial {
    width: calc(100% + 30px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 15px;
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
    width: 226px;
  } */
}
@media (max-width: 670px) {
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img{
  width: 73%;
}
}
@media (max-width: 550px) {
.BuiltDoctors .title {
  font-size: 39px;
    margin-bottom: 30px;
}
.BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img{
  width: 77%;
}
}
@media (max-width: 470px) {
  .BuiltDoctors .title {
    font-size: 35px;
    
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem{
    height: 560px;
  } */
  }
@media (max-width: 413px) {
  .BuiltDoctors .title {
    font-size: 29px;
    margin-bottom: 30px;
  }
  /* .BuiltDoctors .BuiltDoctors-testimonial {
    margin: 0px -15px;
    width: calc(100% + 30px);
  } */
  
  .BuiltDoctors .BuiltDoctors-testimonial .BuiltDoctorsitem img {
    width: 100%;
}
}
@media (max-width: 350px) {
  .BuiltDoctors .title {
    font-size: 25px;
    
  }
  }
.doctorNearMe{
 /* background-color: #fbfbf9;  */
 padding: 30px 0 60px 0;
 min-height: 210px;

}
.doctorNearMe .doctor-locations-div{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.doctorNearMe .doctor-locations-div .parent-label{
  width: 95%;
  display: block;
  padding: 10px 0 10px 0;
  font-size: 20px;
  position: relative;
  /* border-bottom: 1px solid #9999993b; */
  cursor: pointer;
  color: #013648;
}
/* .doctorNearMe .doctor-locations-div .parent-label:hover{
  background-color: black;
} */
.doctorNearMe .doctor-locations-div label.parent-label:last-of-type{
  /* width: 100%; */
}
.doctorNearMe .doctor-locations-div .parent-label .iconlabel{
  display: block;
  right: 10px;
  position: absolute;
  top: 12px;
}

.doctorNearMe .doctor-locations-div ul.each-spacility {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  opacity: 1;
  height: 100%;
  /* height: 0%;*/
  transition: all .5s ease; 
}
.doctorNearMe .doctor-locations-div .parent-doctorme.active ul.each-spacility{
  display: block;
  opacity: 1;
  /* transition: all 0.5s ease;
 
  height: 100%; */
}
.doctorNearMe .doctor-locations-div .parent-doctorme.active .parent-label{
  border: none;
  
}
.doctorNearMe .doctor-locations-div .parent-doctorme.active .parent-label .iconlabel img{
  transform: rotate(180deg);
}
.doctorNearMe .doctor-locations-div ul.each-spacility li {
  padding: 7px 0;
    font-size: 17px;
    cursor: pointer;
    text-decoration: underline;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-style: dotted;
            text-decoration-style: dotted;
}
.doctorNearMe .doctor-locations-div ul.each-spacility li a {
color: #013648;
}
.doctorNearMe .doctor-locations-div ul.each-spacility li:hover {
  color:  rgb(43, 191, 237);
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
}
.doctornearme-title{
  font-size: 30px;
}
@media (max-width: 1024px) {
  .doctorNearMe .doctor-locations-div{
  grid-template-columns:  1fr 1fr 1fr !important;
  }
}
@media (max-width: 767px) {
  .doctorNearMe .doctor-locations-div{
  grid-template-columns: 1fr 1fr !important;
  }
}
@media (max-width: 500px) {
  .doctorNearMe .doctor-locations-div{
  grid-template-columns: 1fr !important ;
  }
}
.PatientLanding .gradient {
    background-image: linear-gradient(-90deg, rgba(242, 150, 45, 0.08), rgb(61, 147, 166, 0.08));
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    /* background: #F3F6F6; */
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .PatientLanding IMG{
    border-radius: 5px;
  }
  .divider{
    background-color: #fbfbf9;
  }  
  .divider .horizontal-line{
      width: 1140px;
      margin: auto;
      max-width: 100%;
      background-color: #eaf4eb;
  }
  
  .PatientLanding .divider .container{
    text-align: center;
    
  }  
  .PatientLanding .divider .horizontal-line{
      width: 887px;
      margin: auto;
      max-width: 100%;
      background-color: #eaf4eb;
  }
.AdminLogin {
  padding: 119px 0;
}
.AdminLogin .login-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.AdminLogin .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 18px;
  margin-bottom: 48px;
  font-family: 'Heldane Display';
}
.AdminLogin .checkbox-block {
  margin-top: 22px;
  margin-bottom: 24px;
}
.AdminLogin .Checkbox-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.AdminLogin .login-btn {
  margin-bottom: 74px;
}
.AdminLogin .login-btn > * {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.AdminLogin .login-bottom {
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
}
.AdminLogin .login-bottom A {
  color: #3dbce7;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.AdminLogin .checkbox-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.AdminLogin .checkbox-block .reset-link {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
  font-family: 'Calibre Medium';
}
.AdminLogin .password-group .form-control {
  padding-right: 35px;
}
.AdminLogin .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 21px;
  height: 13px;
  top: 50%;
  transform: translateY(-50%);
}
.AdminLogin .doctor-loginbtn > * {
  height: 48px;
  line-height: 48px;
  font-family: 'Calibre Regular';
}

@media (hover: hover) {
  .AdminLogin .login-bottom A:hover {
    color: #013648;
  }
  .AdminLogin .checkbox-block .reset-link:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .AdminLogin {
    padding: 90px 0;
  }
  .AdminLogin .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
  .AdminLogin .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .AdminLogin .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .AdminLogin {
    padding: 70px 0;
  }
  .AdminLogin .h3 {
    font-size: 28px;
    margin-bottom: 35px;
    padding-bottom: 12px;
  }
  .AdminLogin .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .AdminLogin .login-btn {
    margin-bottom: 30px;
  }
}
@supports (-webkit-touch-callout: none) {
  .AdminLogin .doctor-loginbtn .CircleButton {
    padding-top: 3px;
  }
}

.AccessChecker {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.AccessChecker .modal-body {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.AccessChecker .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
}
.AccessChecker .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.AccessChecker .cross-icon IMG {
  width: 100%;
}
.AccessChecker .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #013648;
  padding-left: 35px;
}
.AccessChecker .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.AccessChecker .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.AccessChecker .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.AccessChecker .dailog-body {
  padding: 20px 32px 15px;
}
.AccessChecker .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.AccessChecker .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.AccessChecker .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.AccessChecker .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.AccessChecker .action-btn {
  text-align: right;
}
.AccessChecker .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.AccessChecker .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.AccessChecker .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  transform: translateY(-50%);
}

.ActiveWatcher .modal-content-header {
  height: 60px;
}
.ActiveWatcher .modal-content-body {
  padding-bottom: 0;
}
.ActiveWatcher p {
  color: #013648;
}
.ActiveWatcher p.text {
  font-size: 16px;
  margin-top: 0;
}

.ActiveWatcher p.text #timer {
  font-size: 18px;
  font-weight: bold;
}

/* .App .Header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.App .Header + div {
  margin-top: 119px;
}

@media (max-width: 767px) {
  .App .Header + div {
    margin-top: 108px;
  }
} */

@font-face {
  font-family: 'HK Grotesk SemiBold';
  src: url(/app/static/media/HKGrotesk-SemiBold.902e0c15.eot);
  src: url(/app/static/media/HKGrotesk-SemiBold.902e0c15.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/HKGrotesk-SemiBold.4d18fa0d.woff2) format('woff2'),
    url(/app/static/media/HKGrotesk-SemiBold.a64443b2.woff) format('woff'),
    url(/app/static/media/HKGrotesk-SemiBold.987f5814.ttf) format('truetype'),
    url(/app/static/media/HKGrotesk-SemiBold.2561ff7e.svg#HKGrotesk-SemiBold) format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Medium';
  src: url(/app/static/media/Calibre-Medium.d28eba23.eot);
  src: url(/app/static/media/Calibre-Medium.d28eba23.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/Calibre-Medium.46b36969.woff2) format('woff2'),
    url(/app/static/media/Calibre-Medium.115d3718.woff) format('woff'),
    url(/app/static/media/Calibre-Medium.88178c3f.ttf) format('truetype'),
    url(/app/static/media/Calibre-Medium.bc542b1a.svg#Calibre-Medium) format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Light';
  src: url(/app/static/media/Calibre-Light.314f80b4.eot);
  src: url(/app/static/media/Calibre-Light.314f80b4.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/Calibre-Light.183de736.woff2) format('woff2'),
    url(/app/static/media/Calibre-Light.62b8f0fd.woff) format('woff'),
    url(/app/static/media/Calibre-Light.73b7f438.ttf) format('truetype'),
    url(/app/static/media/Calibre-Light.2f6a316e.svg#Calibre-Light) format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre Bold';
  src: url(/app/static/media/Calibre-Bold.bf6feef0.eot);
  src: url(/app/static/media/Calibre-Bold.bf6feef0.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/Calibre-Bold.87cc6590.woff2) format('woff2'),
    url(/app/static/media/Calibre-Bold.c76f2c3d.woff) format('woff'),
    url(/app/static/media/Calibre-Bold.ccc8b8a8.ttf) format('truetype'),
    url(/app/static/media/Calibre-Bold.f1e0c0dd.svg#Calibre-Bold) format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre Semibold';
  src: url(/app/static/media/Calibre-Semibold.8f120a9c.eot);
  src: url(/app/static/media/Calibre-Semibold.8f120a9c.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/Calibre-Semibold.d8b85647.woff2) format('woff2'),
    url(/app/static/media/Calibre-Semibold.34780840.woff) format('woff'),
    url(/app/static/media/Calibre-Semibold.8325d174.ttf) format('truetype'),
    url(/app/static/media/Calibre-Semibold.9afef547.svg#Calibre-Semibold) format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre Regular';
  src: url(/app/static/media/Calibre-Regular.7b08540b.eot);
  src: url(/app/static/media/Calibre-Regular.7b08540b.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/Calibre-Regular.b63c62e5.woff2) format('woff2'),
    url(/app/static/media/Calibre-Regular.e0823214.woff) format('woff'),
    url(/app/static/media/Calibre-Regular.96e0a7c4.ttf) format('truetype'),
    url(/app/static/media/Calibre-Regular.5f287987.svg#Calibre-Regular) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Heldane Display';
  src: url(/app/static/media/HeldaneDisplay-Regular.d21efec5.eot);
  src: url(/app/static/media/HeldaneDisplay-Regular.d21efec5.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/HeldaneDisplay-Regular.52dae98b.woff2) format('woff2'),
    url(/app/static/media/HeldaneDisplay-Regular.3b74d474.woff) format('woff'),
    url(/app/static/media/HeldaneDisplay-Regular.fe0b6265.ttf) format('truetype'),
    url(/app/static/media/HeldaneDisplay-Regular.5b5e0013.svg#HeldaneDisplay-Regular) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TT Commons';
  src: url(/app/static/media/TTCommons-Regular.e026266e.eot);
  src: url(/app/static/media/TTCommons-Regular.e026266e.eot?#iefix) format('embedded-opentype'),
    url(/app/static/media/TTCommons-Regular.be476b7a.woff2) format('woff2'),
    url(/app/static/media/TTCommons-Regular.0ee53eef.woff) format('woff'),
    url(/app/static/media/TTCommons-Regular.fc6fbc1a.ttf) format('truetype'),
    url(/app/static/media/TTCommons-Regular.8b10b2ca.svg#TTCommons-Regular) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  height: 100%;
  margin: 0;
  border: 0;
  font-family: 'Calibre Regular', sans-serif;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}
/* thin scrollbar */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  position: relative;
  right: 15px;
  padding-right: 15px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0);
}
img{
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a6a6a6e0;
  max-height: 100px;
}
a,
a:focus {
  outline: none;
  text-decoration: none;
}
/*css Clearfix */
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-cap {
  text-transform: capitalize;
}
.container {
  margin: auto;
}
.no-select {
  /*  disable text selection */
  /*  URL : https://stackoverflow.com/a/4407335/7160641 */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
/* For edge,remove X icon */
input::-ms-clear {
  display: none;
}
input[type='password']::-ms-reveal,
 input[type='password']::-ms-clear  {
  display: none;
}
/* cursor pointer for input type file */
input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}
.no-scroll {
  overflow: hidden;
}

@media (hover: hover) {
  a:hover {
    outline: none;
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0 30px;
  }
}

