.KScore .k-score .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.05;
  letter-spacing: -0.32px;
  color: #013648;
  margin-bottom: 38px;
}
.KScore .k-score .star IMG {
  width: 49px;
}
.KScore .k-score .rating {
  font-family: 'Heldane Display';
  font-size: 86px;
  line-height: 0.77;
  color: #013648;
  padding: 0px 15px 0px 10px;
}
.KScore .k-score .Review {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
}
.KScore .k-score-content {
  padding-right: 40px;
}
.KScore .k-score {
  display: flex;
  justify-content: space-between;
}
.KScore .k-score .content-score {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.KScore .all-score {
  display: flex;
}
.KScore .all-score .rating-content .SingleRating .progressbar {
  width: 50px;
  margin-left: auto;
}
.KScore .all-score .rating-content .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  margin-bottom: 10px;
}
.KScore .all-score .rating-content {
  padding: 0px 30px;
  position: relative;
}
.KScore .all-score .rating-content:after {
  content: '';
  position: absolute;
  width: 1px;
  background-color: #efefef;
  top: 34px;
  bottom: 8px;
  left: 0px;
  color: #fff;
}
@media (max-width: 1400px) {
  .KScore .k-score .review-count {
    font-size: 18px;
  }
  .KScore .all-score .rating-content {
    padding: 0px 15px;
  }
  .KScore .all-score .rating-content:last-child {
    padding-right: 0;
  }
  .KScore .k-score .rating {
    font-size: 64px;
  }
  .KScore .k-score .star IMG{
    width: 35px;
  }
}
@media (max-width: 1200px) {
  .KScore .k-score {
    flex-wrap: wrap;
  }
  .KScore .k-score .h3{
    margin-bottom: 20px;
  }
  .KScore .k-score-content{
      padding-right: 0px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      width: 100%;
      border-bottom: 1px solid #efefef;
  }
  .KScore .all-score{
    width: 100%;
  }
  .KScore .all-score .rating-content{
    width: 33.33%;
  }
  .KScore .all-score .rating-content:first-child{
    padding-left: 0;
  }
  .KScore .all-score .rating-content:first-child:after{
  display: none;
  }

}
@media (max-width: 1024px) {
  .KScore .all-score .rating-content{
    padding: 0 6px;
  }
  .KScore .all-score .rating-content .SingleRating .progressbar{
    width: calc(100% - 125px);
  }
}
@media (max-width: 767px) {
  .KScore .all-score{
  flex-wrap: wrap;
  }
  .KScore .all-score .rating-content{
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  } 
  .KScore .all-score .rating-content:after{
    display: none;
  }
}
