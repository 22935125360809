.PatientDataPrivacy {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 79px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientDataPrivacy .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 44px;
  font-family: 'Calibre Semibold';
  font-weight: 600;
}
.PatientDataPrivacy .dataprivacy-block {
  margin-bottom: 73px;
}
.PatientDataPrivacy .dataprivacy-block .para {
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 23px;
}
.PatientDataPrivacy .dataprivacy-block .para:last-child {
  margin-bottom: 0;
}
.PatientDataPrivacy .CircleButton.WhiteButton {
  min-width: 193px;
  width: auto;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  background-color: #f37184;
  border-color: #f37184;
  color: #fff;
}
.deletemyaccount{
  margin-left: 5px;
}
@media (hover: hover) {
  .PatientDataPrivacy .CircleButton.WhiteButton:hover {
    background-color: transparent;
    color: #f37184;
  }
}

@media screen and (max-width: 1200px) {
  .PatientDataPrivacy {
    padding: 20px;
  }
}

@media screen and (max-width: 492px) {
  .deletemyaccount{
    margin-left: 0;
    margin-top: 10px;
  }
  .PatientDataPrivacy .CircleButton.WhiteButton{
    width: 100%;
  }
}
