.FinishProfile .box-panel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.FinishProfile .box-header .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: normal;
  margin-bottom: 0px;
}
.FinishProfile .box-header {
  padding: 10px 20px;
  border-bottom: 1px solid #f1f1f5;
}
.FinishProfile .box-body {
  padding: 20px;
}
.FinishProfile .box-body SPAN A {
  font-size: 20px;
  line-height: 1.2;
  color: #26acda;
  margin-bottom: 28px;
  display: inline-block;
}
.FinishProfile .box-body SPAN:last-child A {
  margin-bottom: 0px;
}
.FinishProfile .box-body span.completed SPAN {
  color: #013648;
  text-decoration: line-through;
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 28px;
  display: inline-block;
  width: 100%;
}
.FinishProfile .ProgressBar {
  height: 4px;
  border-radius: 2.5px;
  background-color: #e2e2ea;
  width: 149px;
  max-width: 100%;
}
.FinishProfile .ProgressBar .progress-bar {
  background-color: #26acda;
  border-radius: 2.5px;
}

@media (hover: hover) {
  .FinishProfile .box-body SPAN A:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .FinishProfile .box-body SPAN A {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .FinishProfile .box-header .heading3 {
    font-size: 18px;
  }
  .FinishProfile .box-body SPAN ,
  .FinishProfile .box-body SPAN.completed SPAN {
    font-size: 16px;
    margin-bottom: 14px;
  }
}
