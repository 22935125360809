.AppointmentDetail {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  border-left: 8px solid #199daf;
  /* width: 695px; */
  z-index: 1;
  position: relative;
}
.AppointmentDetail.pending {
  border-left-color: #B2BCC4;
}
.AppointmentDetail.confirmed {
  border-left-color: #199daf;
}
.AppointmentDetail.cancelled {
  border-left-color: #f37184;
}
.AppointmentDetail.completed {
  border-left-color: #f1f1f1;
}
.AppointmentDetail.cancelled .PatientDetails .DateTime {
  text-decoration: line-through;
}
.AppointmentDetail .DateTime {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 20px;
}
.AppointmentDetail .PatientDetails {
  padding: 17px 20px 18px 30px;
  width: 100%;
}
.AppointmentDetail .Patientinner-Details {
  display: flex;
  margin-top: 8px;
}
.AppointmentDetail .PatientDetails .Patient-name SPAN {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Medium';
}
.AppointmentDetail .PatientDetails-content {
  display: flex;
  justify-content: space-between;
}
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-address {
  padding: 20px 0;
}
.AppointmentDetail .Patient-data {
  padding: 14px 0 3px;
}
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-address SPAN,
.AppointmentDetail .PatientDetails-content .Patient-information .Patient-data SPAN {
  font-size: 16px;
  line-height: 1.25;
  color: #72787d;
}
.AppointmentDetail .Patient-information {
  border-right: solid 1px #ededed;
  padding-right: 22px;
  width: 51%;
  min-width: 260px;
}
.AppointmentDetail .Patient-information SPAN {
  display: inline-block;
  width: 100%;
}
.AppointmentDetail .PatientDetails .Patient-name .link-span,
.AppointmentDetail .PatientDetails .Patient-address .link-span,
.AppointmentDetail .PatientDetails .Patient-data .link-span,
.AppointmentDetail .Patient-symptoms .link-span,
.AppointmentDetail .Patient-status .notes .link-span {
  font-size: 16px;
  line-height: 1.25;
  color: #26acda !important;
  cursor: pointer;
}
.AppointmentDetail .Patient-status .doctor-item {
  display: flex;
}
.AppointmentDetail .Patient-status .doctor-item > IMG {
  width: 27px;
  height: 41px;
  border-radius: 2.5px;
  border: solid 0.6px rgba(1, 54, 72, 0.2);
  margin-right: 15px;
  object-fit: cover;
}
.AppointmentDetail .Patient-status .doctor-item .doctor-info .doctor-name,
.AppointmentDetail .Patient-status .doctor-item .doctor-info .doctor-name SPAN,
.AppointmentDetail .Patient-status .doctor-item .doctor-info > SPAN {
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}
.AppointmentDetail .Patient-status .doctor-item .doctor-info > SPAN {
  width: 100%;
  display: inline-block;
}
.AppointmentDetail .Patient-status .doctor-item .doctor-info .doctor-name + SPAN {
  vertical-align: top;
}
.AppointmentDetail .Patient-status {
  /* width: 47%; */
  padding-left: 30px;
}
.AppointmentDetail .Patient-location {
  padding: 28px 0 30px;
}
.AppointmentDetail .Patient-location,
.AppointmentDetail .Patient-Appointment {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  padding-left: 45px;
  position: relative;
}
.AppointmentDetail .Patient-location IMG,
.AppointmentDetail .Patient-Appointment IMG {
  position: absolute;
  left: 7px;
}
.AppointmentDetail .Patient-status-control {
  margin-top: 28px;
}
.AppointmentDetail .cancelled-time {
  font-size: 16px;
  line-height: 1.25;
  color: #b2bcc4;
  margin-bottom: 18px;
}
.AppointmentDetail .Patient-status-control .cancelled-by {
  border-radius: 4px;
  background-color: #f7f9f9;
  font-size: 18px;
  line-height: 38px;
  color: #013648;
  padding: 2px 15px 0px 15px;
  white-space: nowrap;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group {
  height: 38px;
  background-color: #f7f9f9;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group SELECT {
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  background-color: transparent;
  padding-left: 15px;
  font-size: 20px;
  line-height: 2.2;
  letter-spacing: normal;
  height: 38px;
  width: 100%;
  padding-right: 40px;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group .arrow:after {
  content: '';
  width: 1px;
  height: 38px;
  background-color: rgba(1, 54, 72, 0.2);
  position: absolute;
  left: -17px;
  top: -23px;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group:after {
  display: none;
}
.AppointmentDetail .Patient-status-control .Select-control.form-group .arrow {
  top: 16px;
  right: 8px;
  border-top-color: #8f92a1;
  opacity: 0.8;
  border-top-width: 7px;
}
.AppointmentDetail .Patient-symptoms {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #f7f9f9;
  padding: 25px;
  max-width: 232px;
  min-width: 232px;
}
.AppointmentDetail .Patient-symptoms .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 14px;
  display: inline-block;
  width: 100%;
}
.AppointmentDetail .Questionnaire {
  margin-bottom: 35px;
}
.AppointmentDetail .Questionnaire SPAN {
  font-size: 16px;
  line-height: 1.5;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.AppointmentDetail .Questionnaire SPAN.h3 {
  font-size: 18px;
  margin-bottom: 0;
  font-family: 'Calibre Medium';
  line-height: 1.44;
}
.AppointmentDetail .Patient-symptoms A {
  font-size: 16px;
  line-height: 1.25;
  color: #26acda;
}
.AppointmentDetail .Patient-status .notes A {
  width: 166px;
  height: 20px;
  font-size: 16px;
  line-height: 1.25;
  color: #26acda;
}
.AppointmentDetail .Patient-status-control SELECT {
  font-size: 18px;
  color: #013648;
}
.AppointmentDetail .appointment-btn {
  margin-bottom: 16px;
}
.AppointmentDetail .appointment-btn SPAN {
  width: 97px;
  height: 25px;
  border-radius: 12.3px;
  background-color: #cce3eac9;
  display: inline-block;
  text-align: left;
  position: relative;
  padding-left: 31px;
  font-family: 'Calibre Medium';
  font-size: 14.4px;
  line-height: 30px;
  color: #013648;
  letter-spacing: -0.27px;
}
.AppointmentDetail .appointment-btn SPAN.video {
  width: 107px;
  background-color: #eeebf5;
  color: #8d77ba;
}
.AppointmentDetail .appointment-btn SPAN IMG {
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
}
.AppointmentDetail .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  margin-bottom: 31px;
  display: inline-block;
}
@media (hover: hover) {
  .AppointmentDetail .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1366px) {
  .AppointmentDetail .Patient-information {
    min-width: auto;
  }
  .AppointmentDetail .Patient-status {
    padding-left: 20px;
  }
  .AppointmentDetail .Patient-symptoms {
    min-width: 178px;
    width: 178px;
  }
  .AfterAuthDashboard .panel-right {
    width: 250px;
    min-width: auto;
  }
}
@media (max-width: 1200px) {
  .AppointmentDetail .Patient-symptoms {
    max-width: 200px;
    width: 200px;
    padding: 25px 10px;
  }
  .AppointmentDetail .PatientDetails-content .Patient-information .Patient-address SPAN,
  .AppointmentDetail .PatientDetails-content .Patient-information .Patient-data SPAN {
    font-size: 14px;
  }
  .AppointmentDetail .Questionnaire SPAN {
    font-size: 14px;
  }
  .AppointmentDetail .Questionnaire SPAN.h3 {
    font-size: 16px;
  }
  .AppointmentDetail .Patient-symptoms {
    min-width: auto;
  }
}

@media (max-width: 1024px) {
  .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
  .AppointmentDetail .Patient-symptoms {
    max-width: 100%;
    min-width: 100%;
    border-top-right-radius: 0px;
  }
}

@media (max-width: 767px) {
  .AppointmentDetail .PatientDetails,
  .AppointmentDetail .Patient-symptoms {
    padding-left: 15px;
  }
}
