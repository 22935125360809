.PaymentModal-PlanSelection {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
  position: relative;
}
.PaymentModal-PlanSelection .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.PaymentModal-PlanSelection .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.PaymentModal-PlanSelection .modal-content-header .close IMG {
  width: 100%;
}
.PaymentModal-PlanSelection .modal-content-header .close.logout{
  width: 70px;
  display: flex;
  align-items: center;
 }
 .PaymentModal-PlanSelection .modal-content-header .close.logout span{
  display: inline-block;
  margin-top: 5px;
  padding-left: 5px;
  color: #fff;
 }
.PaymentModal-PlanSelection .modal-content-header .close.logout img{
  width: 20px;
    filter:invert(100%) sepia(0%) saturate(7490%) hue-rotate(344deg) brightness(153%) contrast(104%)
}
.PaymentModal-PlanSelection .list-history {
  padding: 33px;
}
ul.eachHistory {
  list-style: none;
  padding: 0;
}
li.eachHistory {
  display: flex;
  padding: 5px 10px;
  gap: 10px;
}
li.eachHistory div{
  flex: 0 0 1;
}
li.eachHistory:nth-child(odd) {
  background-color: #eaeaea;
}
li.eachHistory:nth-child(even) {
  background-color: #f9f9f9;
}
li.eachHistory img {
  width: 20px;
  margin-right: 15px;
}
li.eachHistory p {
  margin-bottom: 5px;
}
.PaymentModal-PlanSelection .modal-content-body {
  overflow: scroll;
  min-height: 350px;
  max-height: 70vh;
  scroll-behavior: smooth;
}
.PaymentModal-PlanSelection .modal-content-body .rtable {
  overflow: scroll;
  max-height: 350px;
}
.PaymentModal-PlanSelection .heading3 {
  font-size: 36px;
  text-transform: capitalize;
  color: #fff;
}
.mt-10 {
  margin-top: 10px;
}
.PaymentSession-ParentDiv{
  padding: 30px;
}
.PaymentSession-ParentDiv .paymentSession .product-list{
  width: auto;
  justify-content: center;
}
.PaymentSession-ParentDiv .stripe-ccinput,
.PaymentSession-ParentDiv .paymentSession .promocodediv{
  max-width: 400px;
}
#PaymentSession-ParentDiv-id .paymentSession .stripe-input{
  overflow: hidden;
}
@media screen and (max-width: 850px) {
  .PaymentModal-PlanSelection {
    width: 750px;
  }
}
@media screen and (max-width: 766px) {
  .PaymentModal-PlanSelection {
    width: 95%;
  }
}
@media screen and (max-width: 767px) {

}
@media screen and (max-width: 766px) {
  .select-date-content.end-date {
   position: relative !important;
  }
}

