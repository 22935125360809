.Textarea.form-group {
  margin-bottom: 23px;
  position: relative;
}
.Textarea.form-group,
.Textarea.form-group .label,
.Textarea.form-group TEXTAREA {
  width: 100%;
  display: inline-block;
}
.Textarea.form-group TEXTAREA {
  padding: 9px 15px;
  height: 116px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  resize: none;
}
.Textarea.form-group .form-control[disabled] {
  background-color: #f8f8f8;
}
.Textarea.form-group .form-control:focus,
.Textarea.form-group .form-control:not(:placeholder-shown) {
  padding: 9px 15px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.Textarea.form-group .form-control:focus {
  border: solid 1.5px #013648;
}
.Textarea.form-group .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.Textarea.error .form-control {
  border-color: rgba(255, 0, 0, 0.4);
}
.Textarea .error-text {
  color: red;
  font-size: 13px;
}
.Textarea .charcount-text {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  line-height: 1.38;
  color: #b2bcc4;
}
