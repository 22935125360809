.VideoLayout .wrap-content-row {
  display: flex;
  background-color: #fafafb;
  padding-top: 70px;
  min-height: 100vh;
}
.VideoLayout .wrap-content-row-full {
  display: flex;
  background-color: #fafafb;
  min-height: 100vh;
}
@media (max-width: 767px) {
  .VideoLayout .wrap-content-row {
    padding-top: 108px;
  }
}
