.AcceptedInsurancePlans {
  height: 100%;
}
.AcceptedInsurancePlans .box {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px;
  padding-bottom: 0;
  height: 100%;
}
.AcceptedInsurancePlans .box .title {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.AcceptedInsurancePlans .box .title .CircleButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  line-height: 32px;
  border: 1px solid #26acda;
  color:#fff;
}
.AcceptedInsurancePlans .insuranceplans-item {
  margin-bottom: 30px;
}
.AcceptedInsurancePlans .insuranceplans-item .insuranceplans-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Medium';
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 8px;
  margin-bottom: 13px;
}
.AcceptedInsurancePlans .insuranceplans-content {
  font-size: 16px;
  letter-spacing: -0.21px;
  color: #013648;
}
.AcceptedInsurancePlans .insuranceplans-content > div {
  line-height: 1.58;
}
.AcceptedInsurancePlans .insuranceplans-list {
  max-height: 730px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
@media (hover: hover) {
  .AcceptedInsurancePlans .box .title .CircleButton:hover {
    border: 1px solid #26acda;
    background-color: #26acda;
    background-color: #ffffff;
    color: #26acda;
  }
}

@media screen and (max-width: 1200px) {
  .AcceptedInsurancePlans .box {
    padding: 20px;
  }
  .AcceptedInsurancePlans .box .title {
    font-size: 20px;
  }
}

@media screen and (max-width: 414px) {
  .AcceptedInsurancePlans .box .title {
    flex-wrap: wrap;
  }
  .AcceptedInsurancePlans .box .title .CircleButton {
    margin-left: auto;
    margin-top: 10px;
  }
}
@supports (-webkit-touch-callout: none) {
  .AcceptedInsurancePlans .box .title .CircleButton {
    padding-top: 2px;
  }
}
