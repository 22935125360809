.InsuranceFilterSelect {
  position: relative;
}
.InsuranceFilterSelect.active .Input-control.form-group {
  z-index: 1;
}
.InsuranceFilterSelect .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.InsuranceFilterSelect .selected-search-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.InsuranceFilterSelect .Input-control.form-group .form-control:focus {
  border: solid 1px #013648;
}
.InsuranceFilterSelect .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.InsuranceFilterSelect .result {
  width: 100%;
  min-width: 290px;
  max-height: 402px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 52px;
  padding: 18px 32px;
  z-index: 8;
}
.InsuranceFilterSelect .result .option.company {
  opacity: 0.5;
}
.InsuranceFilterSelect .result .option {
  font-size: 20px;
  line-height: 1.26;
  color: #013648;
  margin-bottom: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.InsuranceFilterSelect .result .option.plan {
  cursor: pointer;
}
.InsuranceFilterSelect .Input-control.form-group .view-img {
  position: absolute;
  left: 18px;
  top: calc(100% - 1.9rem);
  width: 20px;
  height: 20px;
}
.InsuranceFilterSelect input {
  padding-left: 52px;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control {
  opacity: 1;
  font-size: 20px;
  line-height: 1.64;
  letter-spacing: -0.38px;
  color: #013648;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control {
  padding-left: 52px;
  color: #013648;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control::-webkit-input-placeholder {
  font-size: 20px;
  color: rgba(24, 34, 42, 0.6);
  font-family: 'Calibre Regular';
  letter-spacing: -0.38px;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control:-ms-input-placeholder {
  font-size: 20px;
  color: rgba(24, 34, 42, 0.6);
  font-family: 'Calibre Regular';
  letter-spacing: -0.38px;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control::placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
}
.InsuranceFilterSelect .Input-control.form-group input.form-control:-moz-placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
}
.InsuranceFilterSelect .result .loader {
  text-align: center;
}
.InsuranceFilterSelect .result .loader img {
  margin-top: 15px;
  height: 30px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circleanimation 2.5s infinite linear;
  animation: circleanimation 2.5s infinite linear;
}
.InsuranceFilterSelect .backdivclass{
/* background-color: #023648;
position: relative;
padding: 5px;
 */
 margin-bottom: 10px;
}

.InsuranceFilterSelect .backdivclass span{
  color: #0000ED;
  cursor: pointer;
/* color: #ffffff;
    margin-top: 5px;
    position: absolute;
    margin-left: 5px;
    font-size: 17px; */
}
.InsuranceFilterSelect .selectedCompany-text {
  display: block;
  /* grid-template-columns: 124px 1fr; */
  margin-bottom: 10px;
  margin-top: 5px;
  color:#023648
}
.InsuranceFilterSelect .selectedCompany-text span{
  font-size: 16px;
  color:#023648;

}
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT{
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  padding-left: 5px !important;
  margin-bottom: 20px;
  max-height: 35px;
  padding-top: 3px !important;
}
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT, 
.InsuranceFilterSelect .Input-control.form-group.plansearch .form-control:not(:placeholder-shown),
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT:focus,
.InsuranceFilterSelect .Input-control.form-group.plansearch INPUT:hover{
  border: solid 1.5px rgba(40, 84, 99, 0.2) !important;
  padding-left: 5px !important;
  padding-top: 3px !important;
}
@media (hover: hover) {
  .InsuranceFilterSelect .result .option.plan:hover {
    color: #3dbce7;
  }
}

@media (max-width: 767px) {
  .InsuranceFilterSelect .Input-control.form-group input.form-control::-webkit-input-placeholder {
    font-size: 18px;
  }
  .InsuranceFilterSelect .Input-control.form-group input.form-control:-ms-input-placeholder {
    font-size: 18px;
  }
  .InsuranceFilterSelect .Input-control.form-group input.form-control::placeholder {
    font-size: 18px;
  }
  .InsuranceFilterSelect .Input-control.form-group input.form-control:-moz-placeholder {
    font-size: 18px;
  }
}
