.Divider .horizontal-line {
  width: 100%;
  height: 1px;
  background-color: #e3e7ed;
  display: inline-block;
}
@media (max-width: 767px) {
  .Divider {
    display: none;
  }
}
