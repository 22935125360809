.AnswerRating .rating {
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  display: inline-flex;
  overflow: hidden;
  margin-bottom: 30px;
}
.AnswerRating .rating > span {
  width: 37px;
  height: 37px;
  background-color: #ffffff;
  display: inline-block;
  display: flex;
  border-right: solid 1px #e2e2ea;
}
.AnswerRating .rating > span:last-child {
  border-right: 0;
}
.AnswerRating .rating > span img {
  width: 20px;
  height: 19px;
  margin: auto;
}
.AnswerRating .rating > span {
  cursor: pointer;
}
.AnswerRating .rating > span.disable img {
  opacity: 0.2;
}
.AnswerRating .answer-text {
  font-size: 18px;
  line-height: 2;
  letter-spacing: -0.23px;
  color: #013648;
  margin-bottom: 4px;
}
.AnswerRating .answer-text.no-select {
  color: #e85d72;
}
