.DoctorViewAvailabilityModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 1003px;
  margin: 0 auto;
  max-width: 95%;
}
.DoctorViewAvailabilityModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 57px 0 47px;
}
.DoctorViewAvailabilityModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.DoctorViewAvailabilityModal .modal-content-header .close IMG {
  width: 100%;
}
.DoctorViewAvailabilityModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.DoctorViewAvailabilityModal .modal-content-body {
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorViewAvailabilityModal:last-child {
  border-bottom: solid 0px rgba(1, 54, 72, 0.07);
}
.DoctorViewAvailabilityModal .dc-information {
  margin-left: 40px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.DoctorViewAvailabilityModal .dc-information .dc-content .dc-name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.DoctorViewAvailabilityModal .dc-information .dc-content .dc-specialist {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: normal;
  color: #013648;
}
.DoctorViewAvailabilityModal .dc-information .dc-content .dc-address {
  font-size: 16px;
  line-height: 1.78;
  color: #72787d;
}
.DoctorViewAvailabilityModal .progress-bar-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}
.DoctorViewAvailabilityModal .progress-bar-wrapper .progress-bar {
  width: 50%;
  padding: 0 9px;
}
.DoctorViewAvailabilityModal .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorViewAvailabilityModal .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 11px;
}
.DoctorViewAvailabilityModal .progress-bar .dc-symptoms {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.4px;
  color: #72787d;
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(1n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(2n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(3n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(4n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(5n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(240, 230, 58, 1);
}
.DoctorViewAvailabilityModal .pt-category-box .box.active:nth-of-type(6n),
.DoctorViewAvailabilityModal .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorViewAvailabilityModal .progress-bar .dc-symptoms-text {
  font-size: 16px;
  line-height: 0.84;
  color: #b2bcc4;
  display: none;
}
.DoctorViewAvailabilityModal .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: none;
  position: absolute;
  padding: 0;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorViewAvailabilityModal .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorViewAvailabilityModal .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorViewAvailabilityModal .pt-category-box .box:hover .dc-symptoms-result {
  display: flex;
  z-index: 3;
}
.DoctorViewAvailabilityModal .reviwe-content {
  position: relative;
}
.DoctorViewAvailabilityModal .dc-review {
  min-width: 175px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .star {
  width: 41px;
  position: relative;
  top: 21px;
  left: 5px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .rating {
  font-family: 'Heldane Display';
  font-size: 44px;
  line-height: 0.71;
  letter-spacing: -0.96px;
  color: #013648;
  position: relative;
  top: 4px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .info {
  width: 19px;
  padding-left: 5px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content .rating-text {
  width: 34px;
  height: 26px;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #013648;
  position: relative;
  top: 22px;
  right: 16px;
}
.DoctorViewAvailabilityModal .dc-info-content {
  width: 100%;
  display: flex;
  padding: 10px 132px 10px 124px;
}
.DoctorViewAvailabilityModal .dc-review .reviwe-content > SPAN {
  display: inline-block;
  width: 100%;
  position: relative;
  text-align: right;
}
.DoctorViewAvailabilityModal .DC-image {
  width: 101px;
  display: inline-block;
  margin-top: 5px;
}
.DoctorViewAvailabilityModal .DC-image IMG {
  width: 100%;
  min-width: 100px;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
}
.DoctorViewAvailabilityModal .DC-image SPAN,
.DoctorViewAvailabilityModal .DC-image A {
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
  margin-bottom: 8px;
}
.DoctorViewAvailabilityModal .DC-image A {
  color: #26acda;
}
.DoctorViewAvailabilityModal .DC-image > SPAN {
  flex-wrap: wrap;
  display: flex;
}
.DoctorViewAvailabilityModal .dc-time-info {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  /* background-color: #fbfbf9; */
  padding: 27px 47px 0;
  display: flex;
}
.DoctorViewAvailabilityModal .dc-time {
  padding-left: 70px;
  width: 100%;
}
.DoctorViewAvailabilityModal .dc-time .dc-address {
  font-size: 16px;
  line-height: 1.22;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-bottom: 12px;
  margin-bottom: 21px;
}
.DoctorViewAvailabilityModal .dc-time .dc-address > div:first-child {
  font-family: 'Calibre Medium';
}
.DoctorViewAvailabilityModal .dc-time-list {
  height: 500px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .day {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 0.9;
  color: #013648;
  margin-bottom: 15px;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time {
  margin-left: -6px;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box {
  width: 72px;
  height: 28px;
  border-radius: 13.8px;
  font-size: 15px;
  line-height: 1.22;
  text-align: center;
  color: #fff;
  display: inline-block;
  line-height: 28px;
  cursor: pointer;
  margin: 0px 6px 10px;
  padding-top: 1px;
  background-color: #013648;
  border: 1px solid #013648;
}
.DoctorViewAvailabilityModal .videomode .dc-time-list .time-list-item .time .time-box {
  background-color: #6a3acd;
  color: #fff;
  border: 1px solid #6a3acd;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box.booked {
  cursor: not-allowed;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box.booked {
  background-color: #013648;
  color: #ffffff;
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item {
  border-top: solid 1px rgba(32, 58, 112, 0.08);
  padding-top: 15px;
  /* cursor: pointer; */
}
.DoctorViewAvailabilityModal .dc-time-list .time-list-item:first-child {
  border: 0;
}
.DoctorViewAvailabilityModal .appointments-day-block {
  padding-top: 10px;
  width: 407px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 22px;
}
.DoctorViewAvailabilityModal .prev-link,
.DoctorViewAvailabilityModal .right-link {
  cursor: pointer;
}
.DoctorViewAvailabilityModal .prev-link.disabled,
.DoctorViewAvailabilityModal .right-link.disabled {
  cursor: not-allowed;
  opacity: 0.21;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-heading {
  margin-bottom: 27px;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-bottom: 16px;
}
.DoctorViewAvailabilityModal .appointment-block .heading-txt {
  font-size: 20px;
  line-height: 0.79;
  color: #114236;
  font-family: 'Calibre Medium';
  width: calc(100% - 80px);
  padding-right: 10px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 80px;
  position: relative;
  top: 8px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link A {
  display: block;
  line-height: 0;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link .prev-link {
  margin-right: 20px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link IMG {
  width: 30px;
  height: 25px;
}
.DoctorViewAvailabilityModal .appointment-block .appointment-link .prev-link {
  margin-right: 6px;
}
.DoctorViewAvailabilityModal .day-table .tr {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0px -2px;
}
.DoctorViewAvailabilityModal .day-table {
  width: 100%;
  border-spacing: 0;
}
.DoctorViewAvailabilityModal .day-table .td {
  padding: 2px;
  width: 14.28%;
}
.DoctorViewAvailabilityModal .day-table .day.selected {
  background-color: #013648;
  color: #fbfbf9;
}
.DoctorViewAvailabilityModal .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.DoctorViewAvailabilityModal .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.DoctorViewAvailabilityModal .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
  cursor: not-allowed;
}
.DoctorViewAvailabilityModal .dc-review .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DoctorViewAvailabilityModal .dc-review .info-wrapper {
  display: inline-block;
  position: relative;
  top: -9px;
  left: 5px;
  line-height: 0;
}
.DoctorViewAvailabilityModal .DC-image .person-visit,
.DoctorViewAvailabilityModal .DC-image .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 4px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 4px;
  padding-left: 36px;
  margin-bottom: 13px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.DoctorViewAvailabilityModal .DC-image .person-visit IMG,
.DoctorViewAvailabilityModal .DC-image .video-visit IMG {
  max-width: 27px;
  min-width: 27px;
  width: 27px;
  min-height: auto;
  height: auto;
  border: 0;
  position: absolute;
  left: 10px;
  top: 0;
}
.DoctorViewAvailabilityModal .DC-image .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.DoctorViewAvailabilityModal .dc-review .info-wrapper:hover .info-content {
  display: block;
}
.DoctorViewAvailabilityModal .week-time {
  display: none;
}

@media (hover: hover) {
  .DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box:hover {
    background-color: #fff;
    color: #013648;
  }
  .DoctorViewAvailabilityModal .videomode .dc-time-list .time-list-item .time .time-box:hover{
    background-color: #fff;
    color: #000;
    border: 1px solid #013648;
  }
}

@media (max-width: 1024px) {
  .DoctorViewAvailabilityModal .modal-content-header {
    padding: 0 20px 0 30px;
  }
  .DoctorViewAvailabilityModal {
    width: 100%;
  }
  .DoctorViewAvailabilityModal .dc-info-content {
    padding: 10px 30px;
  }
  .DoctorViewAvailabilityModal .dc-time-info {
    padding: 27px 30px 0;
    flex-wrap: wrap;
  }
  .DoctorViewAvailabilityModal .dc-time {
    padding-left: 0;
    margin-top: 50px;
  }
  .DoctorViewAvailabilityModal .dc-time-list {
    height: auto;
  }
  .DoctorViewAvailabilityModal .dc-review {
    position: absolute;
    right: 26px;
    min-width: auto;
    top: 7px;
  }
  .DoctorViewAvailabilityModal {
    min-width: 700px;
  }
  .DoctorViewAvailabilityModal .modal-content-body {
    position: relative;
  }
  .DoctorViewAvailabilityModal .dc-information {
    padding-right: 105px;
  }
}

@media (max-width: 767px) {
  .DoctorViewAvailabilityModal {
    min-width: 90%;
  }
  .DoctorViewAvailabilityModal .dc-information {
    margin-left: 0px;
    padding-right: 0px;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-name {
    font-size: 18px;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .rating {
    font-size: 26px;
    top: 2px;
    left: -3px;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .star {
    width: 24px;
    top: 8px;
    left: 0px;
  }
  .DoctorViewAvailabilityModal .DC-image IMG {
    width: 71px;
    min-width: 71px;
    height: 104px;
  }
  .DoctorViewAvailabilityModal .DC-image {
    width: 71px;
    position: absolute;
  }
  .DoctorViewAvailabilityModal .appointments-day-block {
    width: 100%;
    padding-right: 0;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content {
    width: 100%;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-name,
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-specialist,
  .DoctorViewAvailabilityModal .dc-information .dc-content .dc-address {
    margin-left: 90px;
    padding-right: 0;
  }
  .DoctorViewAvailabilityModal .dc-information .dc-content .progress-bar-wrapper {
    margin-top: 30px;
  }
  .DoctorViewAvailabilityModal .progress-bar-wrapper .progress-bar {
    width: 100%;
  }
  .DoctorViewAvailabilityModal .dc-information {
    position: relative;
    display: block;
  }
  .DoctorViewAvailabilityModal .reviwe-content {
    display: flex;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .rating-text {
    position: static;
    font-size: 12px;
    color: #3dbce7;
    padding-left: 13px;
    padding-top: 14px;
    display: inline-block;
  }
  .DoctorViewAvailabilityModal .dc-review {
    position: static;
    padding-left: 90px;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content > SPAN {
    text-align: left;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content .info {
    display: none;
  }
  .DoctorViewAvailabilityModal .dc-review .reviwe-content > SPAN {
    width: auto;
  }
  .DoctorViewAvailabilityModal .modal-content-header {
    padding: 15px 16px;
    font-size: 16px;
    height: auto;
  }
  .DoctorViewAvailabilityModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .DoctorViewAvailabilityModal .dc-time-info {
    padding: 31px 15px 0;
    border-top: solid 1px rgba(1, 54, 72, 0.1);
  }
  .DoctorViewAvailabilityModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit,
  .DoctorViewAvailabilityModal .DC-image .video-visit {
    font-size: 12px;
    padding-left: 18px;
    width: 70px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit IMG,
  .DoctorViewAvailabilityModal .DC-image .video-visit IMG {
    left: 0px;
    width: 21px;
    min-width: 14px;
    top: 3px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit {
    padding-left: 20px;
  }
  .DoctorViewAvailabilityModal .DC-image .person-visit IMG {
    width: 24px;
    top: 2px;
    left: -2px;
  }
  .DoctorViewAvailabilityModal {
    min-width: 100%;
    border-radius: 0px;
    border: 0;
  }
  /* .DoctorViewAvailabilityModal .dc-time-list .time-list-item .time .time-box {
    background-color: #013648;
    color:#fff;
  } */
  .DoctorViewAvailabilityModal .modal-content-body {
    max-height: calc(100vh - 0px);
    background-color: #fbfbf9;
  }
  .DoctorViewAvailabilityModal .modal-content-header {
    padding-top: 37px;
    background-color: #fbfbf9;
  }
  .DoctorViewAvailabilityModal .dc-info-content {
    padding: 15px 15px 10px;
    min-height: 162px;
  }
  .DoctorViewAvailabilityModal .DC-image {
    margin-top: 0;
  }
  .DoctorViewAvailabilityModal .progress-bar-wrapper {
    display: none;
  }
  /* .DoctorViewAvailabilityModal .dc-time-info .dc-calender {
    display: none;
  } */
  .DoctorViewAvailabilityModal .dc-time {
    margin-top: 0;
    margin-bottom: 140px;
  }
  .DoctorViewAvailabilityModal .dc-time .dc-address {
    margin-bottom: 0;
  }
  /* .DoctorViewAvailabilityModal .week-time {
    display: flex;
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action {
    display: flex;
    align-items: center;
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action IMG {
    width: 25px;
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action .right-link {
    transform: rotate(180deg);
    margin-left: 30px;
    position: relative;
    top: -7px;
  }
  .DoctorViewAvailabilityModal .week-time .date-title {
    margin: 23px 46px 20px 0;
    font-size: 24px;
    line-height: 0.75;
    color: #013648;
    font-family: 'Calibre Medium';
  }
  .DoctorViewAvailabilityModal .week-time .next-week-action .disable IMG {
    opacity: 0.5;
  } */
}

@media (max-width: 550px) {
  .DoctorViewAvailabilityModal .day-table .day{
    height: 40px;
  }
}

