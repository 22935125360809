.AfterAuthSettings {
  width: calc(100% - 250px);
  min-height: calc(100vh - 70px);
}
.AfterAuthSettings .no-permission-text {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: rgba(38, 172, 218, 0.1);
  padding: 15px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #26acda;
}
.AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
  padding-bottom: 41px;
  padding-right: 445px;
}
.AfterAuthSettings .setting-conettn {
  padding: 0px 20px 0 40px;
}
.AfterAuthSettings .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
}
.AfterAuthSettings .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
  letter-spacing: -0.2px;
}
.AfterAuthSettings .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.AfterAuthSettings .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
  font-family: 'Calibre Medium';
}
.AfterAuthSettings .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.AfterAuthSettings .setting-tabs .tab-content {
  /* background-color: #ffffff; */
  margin-bottom: 40px;
  margin-top: 51px;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  position: relative;
}
.AfterAuthSettings .setting-approvalcard {
  border-radius: 8px;
  background-color: #fff;
  padding: 11px 15px 18px;
  border: 1px solid #e2e2ea;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: -175px;
  width: 375px;
}
.AfterAuthSettings .setting-approvalcard .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.AfterAuthSettings .setting-approvalcard .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.AfterAuthSettings .setting-approvalcard .approval-action {
  display: block;
  margin-top: 10px;
}
.AfterAuthSettings .setting-approvalcard .approval-action SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #013648;
  display: inline-block;
  margin-right: 10px;
  width: 29%;
}
.AfterAuthSettings .setting-approvalcard .approval-action SPAN:last-child {
  margin-right: 0;
}
.AfterAuthSettings .setting-approvalcard .approval-action SPAN IMG {
  width: 20px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}

@media screen and (max-width: 1366px) {
  .AfterAuthSettings .setting-approvalcard {
    padding: 11px 10px 18px;
    width: 330px;
  }
  .AfterAuthSettings .setting-approvalcard .approval-action SPAN {
    margin-right: 33px;
  }
  .AfterAuthSettings .setting-approvalcard .approval-action SPAN IMG {
    margin-right: 5px;
  }
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 30px;
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-right: 365px;
  }
}

@media screen and (max-width: 1200px) {
  .AfterAuthSettings .setting-conettn {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 1200px) {
  .AfterAuthSettings {
    width: calc(100% - 200px);
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
  }
  .AfterAuthSettings .setting-approvalcard {
    top: -225px;
  }
}

@media screen and (max-width: 1024px) {
  .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .AfterAuthSubHeader .main-inner-content SPAN:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 80px;
  }
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 16px;
  }
}

@media screen and (max-width: 767px) {
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 24px;
  }
  .AfterAuthSettings {
    width: 100%;
  }
  .AfterAuthSettings .setting-tabs .tab-list-item:first-child {
    margin-bottom: 15px;
  }
  .AfterAuthSettings .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 41px;
    padding-right: 15px;
  }
  .AfterAuthSettings .setting-approvalcard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .AfterAuthSettings .setting-approvalcard .approval-action SPAN {
    margin-right: 10px;
    width: auto;
  }
}

@media screen and (max-width: 414px) {
  .AfterAuthSettings .setting-tabs .tab-list-item {
    margin-right: 16px;
    font-size: 16px;
  }
  .AfterAuthSettings .setting-conettn {
    padding: 0px 15px;
  }
}
