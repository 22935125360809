.PatientVideoExit {
  width: 100%;
  padding: 214px 20px 120px;
  text-align: center;
}
.PatientVideoExit .sub-heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 9px;
}
.PatientVideoExit .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 29px;
}
.PatientVideoExit .doctor-img {
  width: 100px;
  height: 150px;
  display: block;
  margin: 0 auto 28px;
}
.PatientVideoExit .doctor-img IMG {  
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.PatientVideoExit .rejoin-videolink {
  padding: 5px 10px 0px;
  border-radius: 4px;
  background-color: #3d93a6;
  border: solid 1px #3d93a6;
  max-width: 158px;
  width: 100%;
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.24px;
  color: #ffffff; 
  display: inline-block;
  text-transform: capitalize;
}
@media (hover: hover) {
  .PatientVideoExit .rejoin-videolink:hover {
    background: transparent;
    color: #3d93a6;
  }
}

@media (max-width: 1199px) {
  .PatientVideoExit {
    padding: 170px 20px 120px;
  }
}

@media (max-width: 767px) {
  .PatientVideoExit {
    padding: 165px 30px 120px;
  }
  .PatientVideoExit .heading2 {
    margin-bottom: 69px;
  }
}

@media (max-width: 413px) {
  .PatientVideoExit {
    padding: 165px 15px 120px;
  }
  .PatientVideoExit .heading2 {
    font-size: 32px;
  }
  .PatientVideoExit .para {
    font-size: 16px;
  }
}