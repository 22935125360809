.ReviewWidget .widget-preview {
  width: 270px;
  height: 180px;
  background-color: #026ca2;
  margin: auto;
  cursor: pointer;
}
.ReviewWidget .widget-preview.rounded {
  width: 180px;
  height: 180px;
  border-radius: 90px;
  text-align: center;
}
.ReviewWidget .widget-preview.square {
  width: 165px;
  height: 165px;
}
.ReviewWidget .widget-preview.mini-rectangle {
  width: 145px;
  height: 75px;
}
.ReviewWidget .dimantion-text {
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  color: #474747;
}
.ReviewWidget .widget-preview {
  padding: 15px 20px;
  position: relative;
}
.ReviewWidget .widget-preview.mini-rectangle {
  padding: 11px 8px 9px 11px;
}
.ReviewWidget .widget-preview.mini-rectangle .preview-text {
  font-size: 13px;
  line-height: 1.1;
}
.ReviewWidget .widget-preview .preview-text {
  font-size: 28px;
  color: #fff;
  font-family: 'Calibre Light';
  padding-right: 60px;
}
.ReviewWidget .widget-preview.square .preview-text {
  padding-top: 10px;
}
.ReviewWidget .widget-preview.rounded .preview-text {
  text-align: center;
  padding-right: 0px;
}
.ReviewWidget .widget-preview .preview-text span {
  display: inline-block;
  width: 100%;
}
.ReviewWidget .widget-preview .star {
  display: inline-flex;
  padding-right: 25px;
  position: relative;
}
.ReviewWidget .widget-preview .star img {
  width: 12px;
}
.ReviewWidget .widget-preview .star > span {
  display: inline-block;
  margin-right: 7px;
}
.ReviewWidget .widget-preview .star > .rating {
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 14px;
  color: #ffffff;
}
.ReviewWidget .widget-preview .certificate-img {
  width: 50px;
  position: absolute;
  right: 25px;
  top: 0;
}
.ReviewWidget .widget-preview.mini-rectangle .certificate-img {
  width: 43px;
  right: 3px;
  top: 2px;
}
.ReviewWidget .widget-preview.rounded .certificate-img {
  display: none;
}
