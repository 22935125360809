.EditPractice {
  width: 100%;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
}
.EditPractice .dashboard-panel {
  display: flex;
  margin: 10px -15px;
}
.EditPractice .leftpanel {
  padding: 0 15px;
  width: 45.5%;
}
.EditPractice .rightpanel {
  padding: 0 15px;
  width: 54.5%;
}

.EditPractice .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.EditPractice .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.EditPractice .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}

@media (max-width: 1280px) {
  .EditPractice .leftpanel,
  .EditPractice .rightpanel {
    padding: 0 5px;
  }
  .EditPractice .dashboard-panel {
    margin: 0 -5px;
  }
}

@media (max-width: 1024px) {
  .EditPractice .leftpanel,
  .EditPractice .rightpanel {
    width: 100%;
    padding: 0;
  }
  .EditPractice .dashboard-panel {
    flex-wrap: wrap;
    margin: 0;
  }
}
