.uploadFiles {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 27px 36px 40px 40px;
  margin-bottom: 40px;
  width: 98%;
  min-width: 620px;
}
.uploadFiles .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  letter-spacing: -0.2px;
  margin-bottom: 17px;
}
.uploadFiles .widget-textarea,
.uploadFiles .widget-textarea textarea {
  background-color: #f7f9f9;
  color: #72787d;
}
.uploadFiles .widget-textarea {
  padding: 18px 0px;
}
.uploadFiles .MyLink {
  color: #26acda;
}
.uploadFiles .heading {
  margin-bottom: 16px;
  margin-top: 23px;
}
.uploadFiles button {
  background-color: #013648;
  font-size: 20px;
  font-family: 'Calibre Medium';
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  text-align: center;
  color: #f5f5f2;
  padding: 14px 24px 10px 49px;
  position: relative;
}
.uploadFiles button img {
  position: absolute;
  width: 24px;
  left: 16px;
}
.uploadFiles .copy-link {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.uploadFiles .subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #013648;
  margin-bottom: 34px;
}
.uploadFiles .Textarea.form-group .label {
  display: none;
}
.uploadFiles .widget-textarea textarea {
  padding-left: 29px;
  padding-right: 34px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #72787d;
  height: 150px;
}
.uploadFiles .Textarea.form-group .form-control:focus,
.uploadFiles .Textarea.form-group .form-control:not(:placeholder-shown) {
  border: 0;
  outline: 0;
}
.uploadFiles .Textarea.form-group {
  margin-bottom: 13px;
}
.uploadFiles .booking-widget .label-text {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #72787d;
  margin-bottom: 10px;
}
.uploadFiles .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
/* .uploadFiles {
  width: calc(100% - 250px);
} */
.uploadFiles .search INPUT {
  padding: 3px 15px 0px 25px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.uploadFiles .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.uploadFiles .rtable {
  display: table;
  background-color: white;
  border: 0px;
  width: 100%;
}
.uploadFiles .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.uploadFiles .row {
  display: table-row;
  width: auto;
  clear: both;
}
.uploadFiles .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.uploadFiles .heading .cell {
  background-color: #013648;
  color: white;
}
.uploadFiles .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.uploadFiles .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.uploadFiles .cell.edit-cell {
  width: 20px;
  text-align: center;
  padding-bottom: 90px;
}
.uploadFiles .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.uploadFiles .table-overflow .heading div{
  padding: 10px !important;
}
.uploadFiles .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.uploadFiles .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.uploadFiles .pagination LI.active {
  background-color: #013648;
  color: white;
}
.uploadFiles .pagination LI.previous.disabled,
.uploadFiles .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.uploadFiles .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.uploadFiles .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.uploadFiles .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.uploadFiles .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.uploadFiles .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.uploadFiles .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.uploadFiles .pointer {
  cursor: pointer;
}
.uploadFiles IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 30%;
    top: 16px;
}
.uploadFiles IMG.delete-icon {
  width: 40px;
  margin: auto;
  display: block;
  cursor: pointer;
  position: absolute;
  left: 26%;
    top: 5px;
}
.uploadFiles .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .uploadFiles .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.uploadFiles .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.uploadFiles .search .Select-control SELECT {
  height: 40px;
}
/* .uploadFiles .input-control INPUT {
  height: 52px;
} */
.uploadFiles .Select-control.form-group:after {
  display: none;
}
.uploadFiles .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .uploadFiles .search BUTTON {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.uploadFiles .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.uploadFiles .search button.btn-clear IMG {
  width: 15px;
  left: 0;
  top: 10px;
}
.uploadFiles .search DIV {
  position: relative;
}
.uploadFiles .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.uploadFiles .dataCount {
  font-size: 16px;
}

@media (hover: hover) {
  .uploadFiles button:hover {
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .uploadFiles {
    min-width: 100%;
  }
}
@media (max-width: 700px) {
  .AfterAuthSettings .setting-tabs .tab-content{
    overflow: auto;
  }
  .uploadFiles {
    min-width: 700px;
  }
}
@media (max-width: 413px) {
  .uploadFiles {
    padding: 20px 15px;
  }
}
