.TimeManual {
  position: relative;
}
.TimeManual .result {
  width: 100%;
  min-width: 120px;
  max-height: 191px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 62px;
  padding: 2px 10px;
  z-index: 8;
}
.TimeManual .result .option b {
  color: #3dbce7;
}
.TimeManual .result .option {
  font-size: 18px;
  line-height: 1.95;
  color: #013648;
  cursor: pointer;
}
.TimeManual .Overlay {
  z-index: 1;
}
@media (hover: hover) {
  .TimeManual .result .option:hover {
    color: #3dbce7;
  }
}