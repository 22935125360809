.InsuranceInfoModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 586px;
  margin: auto;
}
.InsuranceInfoModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.InsuranceInfoModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.InsuranceInfoModal .modal-content-header .close img {
  width: 100%;
}
.InsuranceInfoModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.InsuranceInfoModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 220px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.InsuranceInfoModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.InsuranceInfoModal .modal-content-footer .CircleButton {
  width: 100%;
}
.InsuranceInfoModal .Divider {
  margin-top: 10px;
}
.InsuranceInfoModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.InsuranceInfoModal .form-group-2-colum {
  display: flex;
  margin: 0 -12px;
}
.InsuranceInfoModal .Input-control {
  margin-bottom: 38px;
}
.InsuranceInfoModal .form-group-2-colum .Select-control,
.InsuranceInfoModal .form-group-2-colum .Input-control,
.InsuranceInfoModal .form-group-2-colum .Upload {
  margin-left: 12px;
  margin-right: 12px;
}
.InsuranceInfoModal .Divider .container {
  width: auto;
}
.InsuranceInfoModal .medical-insurance .h3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  margin-bottom: 25px;
}
.InsuranceInfoModal .medical-insurance {
  margin-top: 15px;
}
.InsuranceInfoModal .medical-insurance .Upload,
.InsuranceInfoModal .medical-insurance .form-group-2-colum .Input-control,
.InsuranceInfoModal .medical-insurance .form-group-2-colum .Select-control {
  width: 50%;
}
.InsuranceInfoModal .medical-insurance .Checkbox-control {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  padding-left: 35px;
}

.InsuranceInfoModal .secondary-label {
  color: rgba(1, 54, 72, 0.4);
}
.InsuranceInfoModal .DoctorAppointmentTime .appointment-time {
  width: 120px;
  min-width: unset;
  height: 140px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InsuranceInfoModal .DoctorAppointmentTime .dc-info {
  width: calc(100% - 158px);
}

@media (max-width: 767px) {
  .InsuranceInfoModal {
    width: 100%;
  }
  .InsuranceInfoModal .modal-content-body {
    padding: 29px 15px;
  }
  .InsuranceInfoModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
  .InsuranceInfoModal .DoctorAppointmentTime .appointment-time {
    font-size: 20px;
  }
  .InsuranceInfoModal .DoctorAppointmentTime {
    flex-wrap: wrap;
  }
  .InsuranceInfoModal .DoctorAppointmentTime .dc-info {
    width: 100%;
    padding-left: 0;
    margin-top: 10px;
  }
  .InsuranceInfoModal .form-group-2-colum {
    flex-wrap: wrap;
  }
  .InsuranceInfoModal .medical-insurance .Upload,
  .InsuranceInfoModal .medical-insurance .form-group-2-colum .Input-control,
  .InsuranceInfoModal .medical-insurance .form-group-2-colum .Select-control {
    width: 100%;
  }
  .InsuranceInfoModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .InsuranceInfoModal .modal-content-header .close {
    height: 18px;
    width: 18px;
  }
}
