.ConsultrequestEdit .EachFeedback{
width: 100%;
margin-top: 10px;
}
.ConsultrequestEdit .h3{
color:white;
}
.ConsultrequestEdit .kalymdrequestform{
    padding-top: 20px;
}
.ConsultrequestEdit .kalymdrequestform .kalymdrequestform-container{
    max-width: 90%;
    padding: 0;
}