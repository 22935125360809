.DoctorAppointmentTime {
  display: flex;
}
.DoctorAppointmentTime .appointment-time {
  min-width: 120px;
  width: 120px;
  min-height: 140px;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  border: solid 0.8px #013648;
  background-color: #013648;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.45px;
  color: #f7fffc;
  margin-bottom: 9px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.DoctorAppointmentTime .appointment-time span {
  display: inline-block;
  width: 100%;
}
.DoctorAppointmentTime .appointment-time .day {
  font-family: 'Calibre Medium';
}
.DoctorAppointmentTime .dc-info {
  padding-left: 23px;
}
.DoctorAppointmentTime .dc-info .name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.DoctorAppointmentTime .dc-info .dc-specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.DoctorAppointmentTime .dc-info .address {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  display: flex;
  align-items: flex-start;
}
.DoctorAppointmentTime .dc-info .address {
  padding-top: 17px;
}
.DoctorAppointmentTime .dc-info .address .icon {
  width: 14px;
  margin-right: 14px;
  display: inline-block;
}
