.SelectDate {
  position: relative;
}
.SelectDate .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 8;
}
.SelectDate .datepicker .overlay-month-change {
  position: absolute;
  left: 34px;
  top: 31px;
  width: 131px;
  height: 35px;
  z-index: 10;
  opacity: 0.6;
}
.SelectDate .datepicker {
  position: absolute;
  z-index: 9;
  top: 80px;
}
.SelectDate .MyDatetime .rdtPicker {
  overflow: hidden;
}
.SelectDate .MyDatetime.days .rdtPicker .rdtSwitch {
  top: 34px;
}
.SelectDate .MyDatetime.days .rdtPicker .rdtSwitch:after {
  top: 10px;
}
.SelectDate .MyDatetime.days .rdtPicker .dow {
  text-transform: uppercase;
  font-family: 'Calibre Semibold';
}
.SelectDate .MyDatetime.days .rdtPicker td {
  line-height: 1.7;
  padding-top: 5px;
}
.SelectDate .MyDatetime.days .rdtPicker th.rdtNext,
.SelectDate .MyDatetime.days .rdtPicker th.rdtPrev {
  top: 30px;
}
.SelectDate .MyDatetime .rdtPicker .rdtDays table thead tr .dow {
  border-spacing: 20px 5px;
}
.SelectDate .MyDatetime .rdtPicker {
  padding-left: 0;
  padding-right: 0;
}
.SelectDate .Input-control.form-group .view-img {
  left: 18px;
  width: 17px;
  height: 17px;
}
.SelectDate .Input-control.form-group input {
  padding-left: 53px;
}
@media (max-width: 768px) {
  .MyDatetime .rdtPicker ,
  .SelectDate .MyDatetime .rdtPicker {
    width: 360px;
  }
  
  .SelectDate .MyDatetime .rdtPicker .rdtSwitch:after {
    width: 82px;
  }
}
@media (max-width: 413px) {
  .SelectDate .MyDatetime .rdtPicker {
    width: 290px;
  }
  .SelectDate .MyDatetime .rdtPicker .rdtSwitch:after {
    width: 81px;
    left: 128px;
  }
  .SelectDate .MyDatetime .rdtPicker .rdtDays table {
    border-spacing: 18px 10px;
  }
}
