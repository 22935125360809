.MyImageCrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.MyImageCrop .modal-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 20px 20px 20px 42px;
}
.MyImageCrop .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.MyImageCrop .modal-content-header .close img {
  width: 100%;
}
.MyImageCrop .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
  margin-bottom: 0;
}
.MyImageCrop .modal-content-body {
  text-align: center;
}
.MyImageCrop .modal-content-body{
  padding: 10px;
  /* overflow: scroll; */
}

.MyImageCrop .modal-content-body,
.MyImageCrop .ReactCrop {
  max-height: calc(100vh - 200px);
}
.cropinfotext{
  width: 100%;
  display: block;
}
.MyImageCrop .modal-content-body{
  height: auto;
}

.MyImageCrop .modal-content-footer {
  padding: 10px 15px 10px;
  text-align: right;
  margin-top: 10px;
}
.MyImageCrop .modal-content-footer .CircleButton {
  height: auto;
  line-height: normal;
  margin-left: 15px;
  min-width: 111px;
  height: 39px;
  padding-top: 0;
  line-height: 41px;
  margin-bottom: 10px;
}
.MyImageCrop .content {
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  background-color: white;
  overflow: hidden;
}
.MyImageCrop .ReactCrop .ReactCrop__image,
.MyImageCrop .ReactCrop > div {
  /* height: 100%; */
  max-height: 55vh;
}

@media (max-width: 767px) {
  .MyImageCrop .content{
    width: 90%;
  }
  .MyImageCrop .modal-content-footer{
    text-align: center;
  }
}
@media (max-width: 550px){
  .MyImageCrop .ReactCrop .ReactCrop__image,
.MyImageCrop .ReactCrop > div {
  max-height: 55vh;
}
}