.ViewPatientOverlayModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
}
.ViewPatientOverlayModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.ViewPatientOverlayModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ViewPatientOverlayModal .modal-content-header .close IMG {
  width: 100%;
}
.ViewPatientOverlayModal .modal-content-header .heading3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.ViewPatientOverlayModal .modal-content-body {
  padding: 30px 33px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ViewPatientOverlayModal .modal-content-body .InfiniteScroll {
  max-height: calc(100vh - 244px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.ViewPatientOverlayModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.ViewPatientOverlayModal .patient-info .link {
  color: #26acda;
  cursor: pointer;
  margin-top: 16px;
}
.ViewPatientOverlayModal .insurance-info .link-main {
  margin-top: 16px;
}
.ViewPatientOverlayModal .insurance-info .link {
  color: #26acda;
}
.ViewPatientOverlayModal .patient-info {
  margin-right: 60px;
}
.ViewPatientOverlayModal .patient-info,
.ViewPatientOverlayModal .insurance-info {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  min-width: 230px;
  width: 230px;
}
.ViewPatientOverlayModal .patient-details {
  display: flex;
}
.ViewPatientOverlayModal .patient-counts-details {
  display: flex;
  justify-content: center;
  margin-top: 33px;
  flex-wrap: wrap;
  gap: 10px;
}
.ViewPatientOverlayModal .patient-counts-details .box {
  border-radius: 4px;
  background-color: #f5f5f2;
  padding: 8px 3px;
  text-align: center;
  width: 133px;
}
.ViewPatientOverlayModal .patient-counts-details .box .number {
  font-family: 'Calibre Medium';
  font-size: 24px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #013648;
  display: inline-block;
  margin-bottom: 9px;
  margin-top: 12px;
}
.ViewPatientOverlayModal .patient-counts-details .box .text,
.ViewPatientOverlayModal .patient-counts-details .box .subtext {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #72787d;
  line-height: 1.25;
}
.ViewPatientOverlayModal .patient-appointment-head {
  justify-content: space-between;
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2ea;
  margin-top: 30px;
}
.ViewPatientOverlayModal .patient-appointment-head .title {
  font-size: 20px;
  letter-spacing: 0.17px;
  color: #013648;
  display: inline-block;
  margin: auto 0;
}
.ViewPatientOverlayModal .patient-appointment-head .title SPAN {
  display: inline-block;
  color: #b2bcc4;
}
.ViewPatientOverlayModal .patient-appointment-head .Select-control {
  margin-bottom: 0;
  display: inline-block;
}
.ViewPatientOverlayModal .patient-appointment-head .Select-control .active-dropdown {
  width: 133px;
  height: 40px;
  transform: rotate(-360deg);
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-left: 20px;
  line-height: 41px;
  padding-right: 30px;
  padding-top: 1px;
  color: #44444f;
}
.ViewPatientOverlayModal .patient-appointment-head .Select-control .arrow {
  position: absolute;
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 17px;
  right: 15px;
  z-index: 1;
}
.ViewPatientOverlayModal .patient-appointment-dtails {
  padding-top: 16px;
}
.ViewPatientOverlayModal .AppointmentDetail {
  margin-bottom: 15px;
}
.ViewPatientOverlayModal .AppointmentDetail .PatientDetails {
  padding-top: 17px;
  padding-left: 25px;
}
.ViewPatientOverlayModal .AppointmentDetail .DateTime {
  font-size: 20px;
  margin-bottom: 13px;
}
.ViewPatientOverlayModal .AppointmentDetail .PatientDetails .Patient-name SPAN {
  font-size: 16px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-name A,
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-data A {
  display: none;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-address {
  padding-bottom: 10px;
  padding-top: 8px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-address SPAN,
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-data SPAN,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name SPAN,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info > SPAN,
.ViewPatientOverlayModal .AppointmentDetail .Patient-location {
  line-height: 1.13;
}
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info .doctor-name SPAN,
.ViewPatientOverlayModal .AppointmentDetail .doctor-item .doctor-info > SPAN {
  font-size: 16px;
}

.ViewPatientOverlayModal .AppointmentDetail .Patient-information {
  width: 225px;
  padding-right: 10px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status {
  padding-left: 10px;
  min-width: 225px;
  width: 225px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-Appointment {
  display: none;
}
.ViewPatientOverlayModal .AppointmentDetail .doctor-item > IMG {
  width: 24px;
  height: 37px;
  margin-right: 16px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-location IMG {
  height: 14px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-location SPAN {
  padding-left: 24px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms {
  width: 185px;
  min-width: 185px;
  padding-right: 5px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms > .heading3 {
  font-size: 18px;
  margin-bottom: 14px;
  display: inline-block;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms .Questionnaire .heading3 {
  line-height: 1.54;
  margin-bottom: 0;
  font-size: 16px;
  font-family: 'Calibre Medium';
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-information .Patient-address > SPAN {
  display: inline-block;
  margin-bottom: 8px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group SELECT,
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group .arrow:after,
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group {
  height: 34px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group SELECT {
  padding-right: 34px;
  font-size: 18px;
  color: #013648;
  line-height: 1.7;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group .arrow {
  border-width: 6px;
  border-top-color: #8f92a1;
  opacity: 0.8;
  border-top-width: 7px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-status-control .Select-control.form-group {
  width: 207px;
  margin-bottom: 0;
}
.ViewPatientOverlayModal .AppointmentDetail .Questionnaire {
  margin-bottom: 21px;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-location {
  align-items: flex-start;
  padding-bottom: 51px;
}
.ViewPatientOverlayModal .AppointmentDetail .Questionnaire SPAN {
  line-height: 1.31;
}
.ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms A {
  font-size: 15px;
}

@media (hover: hover) {
  .ViewPatientOverlayModal .patient-info .link:hover,
  .ViewPatientOverlayModal .insurance-info .link:hover {
    opacity: 0.8;
  }
}

@media (max-width: 1200px) {
  .ViewPatientOverlayModal .AppointmentDetail .Patientinner-Details {
    flex-wrap: nowrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information {
    border-right: solid 1px #ededed;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    margin-top: 0;
  }
}
@media (max-width: 1024px) {
  .ViewPatientOverlayModal .AppointmentDetail .Patientinner-Details {
    flex-wrap: nowrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information {
    border-right: solid 1px #ededed;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    margin-top: 0;
  }
  .ViewPatientOverlayModal .AppointmentDetail .PatientDetails-content {
    flex-wrap: nowrap;
  }
  .ViewPatientOverlayModal {
    width: 730px;
  }
  .ViewPatientOverlayModal .modal-content-body {
    padding: 30px 17px;
  }
}
@media (max-width: 767px) {
  .ViewPatientOverlayModal {
    width: 100%;
  }
  .ViewPatientOverlayModal .patient-details {
    flex-wrap: wrap;
  }
  .ViewPatientOverlayModal .patient-counts-details {
    flex-wrap: wrap;
    justify-content: unset;
  }
  .ViewPatientOverlayModal .patient-info {
    margin-bottom: 15px;
  }
  .ViewPatientOverlayModal .patient-counts-details .box {
    margin-bottom: 15px;
    width: 32%;
    margin: 0 3px 10px;
  }
  .ViewPatientOverlayModal .patient-info,
  .ViewPatientOverlayModal .insurance-info {
    width: 50%;
    margin-right: 0;
    padding-right: 10px;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-location {
    padding-bottom: 10px;
  }
  .ViewPatientOverlayModal .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-symptoms {
    width: 100%;
    padding-left: 15px;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information,
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    min-width: 50%;
    width: 50%;
  }
  .ViewPatientOverlayModal .AppointmentDetail .PatientDetails {
    padding-left: 15px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .ViewPatientOverlayModal {
    width: 100%;
  }
  .ViewPatientOverlayModal .modal-content-body {
    padding: 20px;
  }
  .ViewPatientOverlayModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .ViewPatientOverlayModal .modal-content-header .heading3 {
    font-size: 24px;
  }
  .ViewPatientOverlayModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .ViewPatientOverlayModal .patient-counts-details .box {
    width: 48%;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-information {
    border-right: 0;
  }
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 20px;
  }

  .ViewPatientOverlayModal .AppointmentDetail .Patient-information,
  .ViewPatientOverlayModal .AppointmentDetail .Patient-status {
    min-width: 100%;
    width: 100%;
  }
}
@media (max-width: 414px) {
  .ViewPatientOverlayModal .modal-content-body {
    padding: 15px;
  }
  .ViewPatientOverlayModal .patient-counts-details .box {
    width: 100%;
  }
  .ViewPatientOverlayModal .patient-appointment-head {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .ViewPatientOverlayModal .patient-appointment-head .title {
    width: 100%;
    margin-top: 5px;
  }
  .ViewPatientOverlayModal .patient-appointment-head .Select-control {
    margin-left: auto;
  }
}
