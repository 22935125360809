.Appointments-item {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
  padding-right: 25px;
}
.Appointments-item .appoinment-icon {
  position: absolute;
  right: 0;
  top: 10px;
  width: 20px;
  height: 20px;
}
.Appointments-item .appoinment-icon IMG {
  width: 100%;
}
.Appointments-item:last-child {
  margin-bottom: 0;
}
.Appointments-item .Patient-name {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
}
.Appointments-item .appointment-time {
  width: 133px;
  height: 20px;
  opacity: 0.6;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.Appointments-item .icon IMG {
  width: 18px;
  height: 14px;
  opacity: 0.8;
}
.Appointments-item .icon {
  position: absolute;
  right: 0;
  top: 15px;
}
