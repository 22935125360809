.AddPatientModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.AddPatientModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.AddPatientModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.AddPatientModal .modal-content-header .close img {
  width: 100%;
}
.AddPatientModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.AddPatientModal .modal-content-body {
  padding: 23px 40px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AddPatientModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.AddPatientModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.AddPatientModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AddPatientModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.AddPatientModal .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.AddPatientModal .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
@media (max-width: 767px) {
  .AddPatientModal {
    width: 100%;
  }
  .AddPatientModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
  .AddPatientModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .AddPatientModal .modal-content-footer {
    padding: 15px;
  }
  .AddPatientModal .modal-content-body {
    padding: 20px 15px;
  }
}
@media (max-width: 439px) {
  .AddPatientModal .confirm-patient-select.Select-control.form-group:after {
    top: 49px;
  }
  .AddPatientModal .confirm-patient-select.Select-control .arrow {
    top: 69px;
  }
}
