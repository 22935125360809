.Introduction {
  padding-bottom: 30px;
}
.Introduction .intro-info .paragraph {
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
  margin-bottom: 36px;
}
.Introduction .intro-info .heading1 {
  font-size: 64px;
  line-height: 1.03;
  color: #013648;
  margin: 36px 0 43px;
  font-family: 'Heldane Display';
}
.Introduction .intro-info .CircleButton{
  font-family: 'Calibre Regular';
  font-size: 18px;
}
.Introduction .intro-info SPAN:before {
  content: '';
  position: absolute;
  height: 2px;
  width: 14px;
  background-color: #344563;
  left: 0;
  top: 10px;
}
.Introduction .intro-info SPAN {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  font-family: 'Calibre Semibold';
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
}
.Introduction .intro-info {
  margin-top: 111px;
}
.Introduction .intro-banner {
  background-repeat: no-repeat;
  background-position: 100px 47px;
  width: calc(100% + 28px);
  padding-right: 28px;
  position: relative;
}

.Introduction .intro-banner IMG {
  margin-top: 40px;
  margin-left: 100px;
}
.Introduction .intro-inner-content {
  display: flex;
  flex-wrap: wrap;
}
.Introduction .intro-inner-content .col-left {
  width: 45%;
}
.Introduction .intro-inner-content .col-right {
  width: 55%;
}
/* .Introduction .shapemask {
  position: absolute;
  right: 0;
  bottom: -23px;
} */

@media (max-width: 1200px) {
  .Introduction .intro-banner {
    background-position: 0px 47px;
  }
  .Introduction .intro-banner IMG {
    margin-left: 89px;
    width: 84%;
  }
  .Introduction .intro-info .heading1 {
    font-size: 54px;
  }
  .Introduction .intro-banner {
    width: calc(98% + 28px);
  }
}
@media (max-width: 991px) {
  .Introduction .intro-info .heading1 {
    font-size: 42px;
  }
  .Introduction .intro-banner,
  .Introduction .intro-banner IMG {
    width: 100%;
  }
  .Introduction .intro-banner IMG {
    margin-left: 40px;
  }
  .Introduction .intro-banner {
    background-position: 34px 52px;
    background-size: 92%;
  }
}
@media (max-width: 767px) {
  .Introduction .intro-info SPAN {
    font-size: 18px;
    line-height: 1.22;
  }
  .Introduction .intro-inner-content .col-left {
    width: 100%;
  }
  .Introduction .intro-inner-content .col-right {
    width: 100%;
  }
  .Introduction .intro-banner IMG {
    width: 100%;
    margin-top: 63px;
    margin-left: 28px;
  }
  .Introduction .intro-banner {
    max-width: 406px;
    margin: auto;
    background-position: -8px 36px;
    background-size: 100%;
  }
  .Introduction .intro-info {
    margin-top: 40px;
  }
  .Introduction .intro-info .heading1 {
    margin: 40px 0;
    font-size: 48px;
    line-height: 1.17;
  }
  .Introduction .intro-info .paragraph {
    font-size: 20px;
    margin-bottom: 28px;
    line-height: 1.6;
  }
  .Introduction button.withus-btn {
    width: 244px;
  }
}
