.ProfileSpecialtyTitles {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
}
.ProfileSpecialtyTitles .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.ProfileSpecialtyTitles .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ProfileSpecialtyTitles .modal-content-header .close IMG {
  width: 100%;
}
.ProfileSpecialtyTitles .modal-content-header .heading3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.ProfileSpecialtyTitles .modal-content-body {
  padding: 30px 33px;
  max-height: calc(100vh - 130px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ProfileSpecialtyTitles .modal-content-body .InfiniteScroll {
  max-height: calc(100vh - 244px);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.ProfileSpecialtyTitles h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 5px;
  font-family: 'Calibre Medium';
 
}
.ProfileSpecialtyTitles .each-spec{
  font-size: 18px;
    line-height: 1.4;
    letter-spacing: -.24px;
    color: #013648;
    margin-bottom: 3px;
}
.ProfileSpecialtyTitles .sub-para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -.2px;
  color: #b2bcc4;
  margin-bottom: 2px;
}
.ProfileSpecialtyTitles .footer-div{
  margin-top: 20px;
   border-top: 1px solid #b2bcc4;
    padding-top: 20px;
    display: flex;
    gap: 10px;
}
.ProfileSpecialtyTitles .footer-div button{
  width: 150px;
  height: 35px;
  line-height: 35px;
}
.ProfileSpecialtyTitles .error-span{
color: red;
}
.ProfileSpecialtyTitles .Input-control.column3.form-group.error{
  margin-bottom: 5px;
}

/* .ProfileSpecialtyTitles .patient-details {
  display: flex;
} */




@media (max-width: 1024px) {
 
  .ProfileSpecialtyTitles {
    width: 730px;
  }
  .ProfileSpecialtyTitles .modal-content-body {
    padding: 30px 17px;
  }
}
@media (max-width: 767px) {
  .ProfileSpecialtyTitles {
    width: 100%;
  }

  
}
@media (max-width: 575px) {
  .ProfileSpecialtyTitles {
    width: 100%;
  }
  .ProfileSpecialtyTitles .modal-content-body {
    padding: 20px;
  }
  .ProfileSpecialtyTitles .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .ProfileSpecialtyTitles .modal-content-header .heading3 {
    font-size: 24px;
  }
  .ProfileSpecialtyTitles .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  

 
}
@media (max-width: 414px) {
  .ProfileSpecialtyTitles .modal-content-body {
    padding: 15px;
  }
  
}
