.UpcomingAppointments .box-panel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 40px;
}
.UpcomingAppointments .box-header .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  letter-spacing: normal;
}
.UpcomingAppointments .box-header {
  padding: 14px 20px 0;
  border-bottom: 1px solid #f1f1f5;
}
.UpcomingAppointments .box-footer {
  background-color: rgba(38, 172, 218, 0.1);
  text-align: center;
  padding: 14px;
  cursor: pointer;
}
.UpcomingAppointments .box-footer SPAN {
  color: #26acda;
  font-size: 16px;
  line-height: 1.25;
}
.UpcomingAppointments .box-body {
  padding: 20px;
}
.UpcomingAppointments .box-body.no-records {
  text-align: center;
  font-size: 18px;
  opacity: 0.5;
}

@media (hover: hover) {
  .UpcomingAppointments .box-footer SPAN:hover {
    opacity: 0.7;
  }
}

@media (max-width: 1200px) {
  .UpcomingAppointments .box-header {
    padding: 14px 15px 0;
  }
  .UpcomingAppointments .box-body {
    padding: 14px 15px 0;
  }
}
@media (max-width: 767px) {
  .UpcomingAppointments .box-header {
    padding: 14px 10px 0;
  }
  .UpcomingAppointments .box-header .heading3 {
    font-size: 18px;
  }
  .UpcomingAppointments .box-body {
    padding: 20px 10px 0;
  }
  .Appointments-item .Patient-name {
    font-size: 16px;
  }
}
