.Taking {
  padding: 65px 0 90px;
}
.Taking .traking-points {
  padding: 0px 132px 0 189px;
  display: flex;
  flex-wrap: wrap;
}
.Taking .heading2 {
  font-size: 64px;
  line-height: 1.03;
  text-align: center;
  color: #013648;
  font-family: 'Heldane Display';
  max-width: 670px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.Taking .traking-action {
  margin-top: 89px;
}
.Taking .traking-action .CircleButton.WhiteButton {
  width: 245px;
  font-family: 'Calibre Regular';
}
.Taking .Patient-Treat {
  margin-top: 125px;
  z-index: 1;
  position: relative;
}
.Taking .TakingPoint .Patient-Treat .h3 {
  font-family: 'Calibre Regular';
  font-size: 18px;  
  line-height: 0.85;
  letter-spacing: -0.23px;  
}
.Taking .Patient-Treat .pt-category-box span {
  width: 69px;
  height: 19px;
  border-radius: 2px;
  background-color: #3d93a6;
  display: inline-block;
  margin-right: 5px;
}
.Taking .Patient-Treat .pt-category-box .Herniated-box {
  width: 60px;
  background-color: #e85d72;
}
.Taking .Patient-Treat .pt-category-box .Sciatica-box {
  width: 40px;
  background-color: #8d77ba;
}
.Taking .Patient-Treat .pt-category-box .blue-box {
  width: 40px;
  background-color: #26acda;
}
.Taking .Patient-Treat .pt-category-box .Scoliosis-box {
  width: 40px;
  background-color: #f2962d;
}
.Taking .Patient-Treat .pt-category-name {
  width: 245px;
  margin-top: 8px;
}
.Taking .Patient-Treat .pt-category-name span {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.79;
  letter-spacing: -0.3px;
  color: #1aa2b4;
  display: inline-block;
  width: 50%;
  height: 29px;
  margin-bottom: 5px;
}
.Taking .Patient-Treat .pt-category-name .Herniated {
  color: #8874c9;
}
.Taking .Patient-Treat .pt-category-name .Sciatica {
  color: #f37184;
}
.Taking .Patient-Treat .pt-category-name .Scoliosis {
  color: #fca43d;
}

.Taking .TakingPoint {
  position: relative;
  width: 50%;
}
.Taking .TakingPoint .paragraph {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.Taking .TakingPoint .h3 {
  font-family: 'Calibre Medium';
  font-size: 20px;
  line-height: 1.1;
  letter-spacing: normal;
  margin-bottom: 21px;
  margin-top: 230px;
  color: #013648;
}
.Taking .TakingPoint.patient .h3 {
  margin-top: 289px;
  z-index: 2;
}
.Taking .TakingPoint.fair .h3 {
  margin-top: 262px;
}
.Taking .TakingPoint.data .h3 {
  margin-top: 33px;
}
.Taking .TakingPoint.professional .h3 {
  margin-top: 262px;
}
.Taking .TakingPoint .images {
  position: absolute;
  top: 119px;
  left: 182px;
}
.Taking .TakingPoint.fair .images {
  position: absolute;
  top: 75px;
  left: 25px;
}
.Taking .TakingPoint.data .images {
  top: 80px;
  left: 20px;
}
.Taking .TakingPoint.professional .images {
  top: 49px;
  left: 75px;
}
.Taking .TakingPoint.professional .images img{
  width: 225px;
}
.Taking .TakingPoint.patient .images {
  left: 51px;
  top: 78px;
}
.Taking .TakingPoint .images .h3 {
  font-size: 103.7px;
  line-height: 0.76;
  letter-spacing: -2.07px;
  color: #013648;
  position: absolute;
  top: -250px;
  left: 80px;
  font-family: 'Heldane Display';
}
.Taking .TakingPoint .images .text {
  font-size: 35.2px;
  line-height: 1.42;
  color: #013648;
  position: absolute;
  top: 48px;
  font-family: 'Heldane Display';
  z-index: 1;
  left: 7px;
}
.Taking .TakingPoint.patient,
.Taking .TakingPoint.professional {
  padding-left: 59px;
}

.Taking .TakingPoint .rating-img {
  position: absolute;
  top: 22px;
  width: 178px;
  right: 40px;
}
.Taking .TakingPoint .rating-img img {
  max-width: 100%;
}

.Taking .fair .social-media-fair {
  position: absolute;
  left: -28px;
  top: 55px;
}
.Taking .TakingPoint .Patient-Treat .h3 {
  margin-bottom: 15px;
}
.Taking .TakingPoint.data .paragraph,
.Taking .TakingPoint.fair .paragraph {
  padding-right: 50px;
}
.Taking .TakingPoint.patient .social-media-mobile {
  display: none;
}

@media (max-width: 1200px) {
  .Taking .heading2 {
    font-size: 54px;
  }
  .Taking .TakingPoint.data .paragraph,
  .Taking .TakingPoint.fair .paragraph {
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .Taking {
    padding: 50px 0;
  }
  .Taking .heading2 {
    font-size: 42px;
  }

  .Taking .traking-points {
    padding: 0;
  }
  .Taking .traking-action {
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .Taking .Patient-Treat {
    margin-top: 52px;
  }
  .Taking .TakingPoint.fair {
    order: 1;
  }
  .Taking .TakingPoint.patient {
    order: 2;
  }
  .Taking .TakingPoint.data {
    order: 3;
  }
  .Taking .TakingPoint.professional {
    order: 4;
  }
  .Taking {
    padding: 95px 0 60px;
    border-top: solid 1px #e3e7ed;
  }
  .Taking .heading2 {
    text-align: left;
    font-size: 48px;
    line-height: 1.17;
  }
  .Taking .traking-action {
    /* margin-top: 35px; */
    text-align: left;
    margin-top: 36px;
    padding-top: 40px;
    border-top: 1px solid #e3e7ed;
  }
  .Taking .TakingPoint .paragraph {
    line-height: 1.25;
  }
  .Taking .TakingPoint .h3 {
    margin-top: 238px;
    font-size: 18px;
    margin-bottom: 17px;
  }

  .Taking .TakingPoint {
    width: 100%;
  }

  .Taking .TakingPoint.patient,
  .Taking .TakingPoint.professional {
    padding-left: 0;
  }
  .Taking .TakingPoint.data .h3 {
    margin-top: 26px;
  }
  .Taking .TakingPoint.professional .h3 {
    margin-top: 190px;
  }
  .Taking .TakingPoint.professional .images {
    top: 14px;
    left: 59px;
  }
  .Taking .TakingPoint.professional .images img {
    width: 195px;
  }
  .Taking .TakingPoint.patient .images {
    left: 87px;
    top: 12px;
  }
  .Taking .TakingPoint.data .images {
    top: 57px;
    left: 100px;
  }
  .Taking .TakingPoint.data .images img {
    width: 127px;
    height: 104px;
  }
  .Taking .TakingPoint .images {
    position: absolute;
    top: 63px;
    left: 182px;
  }
  .Taking .TakingPoint .images .h3 {
    top: -249px;
    font-size: 86.4px;
    line-height: 0.76;
    letter-spacing: -1.73px;
  }
  .Taking .TakingPoint .images .text {
    width: 78px;
    height: 75px;
    left: 14px;
    top: 37px;
  }
  .Taking .TakingPoint .images .text img {
    width: 100%;
  }
  .Taking .TakingPoint.fair .images {
    position: absolute;
    top: 73px;
    left: 30px;
  }
  .Taking .TakingPoint.patient .h3 {
    margin-top: 220px;
    line-height: 1.22;
    margin-bottom: 19px;
    left: 73px;
    top: -215px;
  }
  .Taking .TakingPoint.fair .h3 {
    margin-top: 250px;
  }
  .Taking .TakingPoint.patient .social-media-mobile {
    display: block;
    width: 110px;
    position: absolute;
    bottom: 7px;
    left: 14px;
  }
  .Taking .traking-action .CircleButton.WhiteButton {
    font-family: 'Calibre Medium';
  }
  .Taking .TakingPoint.patient .social-media-mobile{
    display: none;
  }
}
@media (max-width: 375px) {
  .Taking .TakingPoint.fair .images {
    left: 115px;
  }
  .Taking .TakingPoint .images {
    left: 160px;
  }
  .Taking .TakingPoint.data .images {
    left: 80px;
  }
  .Taking .TakingPoint.professional .images {
    left: 61px;
  }
  .Taking .TakingPoint.patient .images {
    left: 66px;
  }
}
@media (max-width: 360px) {
  .Taking .TakingPoint.fair .images {
    left: 30px;
  }
  .Taking .TakingPoint .images {
    left: 138px;
  }
  .Taking .TakingPoint.data .images {
    left: 60px;
  }
  .Taking .TakingPoint.professional .images {
    left: 36px;
  }
  .Taking .TakingPoint.patient .images {
    left: 55px;
  }
}
