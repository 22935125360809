.PracticeLocations {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 36px 40px 40px;
  margin-bottom: 40px;
}
.PracticeLocations .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 44px;
}
.PracticeLocations .add-location {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  width: 100%;
  height: 92px;
  border-radius: 2px;
  border: dashed 1px #bfbfbf;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}
.PracticeLocations .add-location .plus-icon {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  background-color: rgba(1, 54, 72, 0.2);
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -3px;
}
.PracticeLocations .add-location .plus-icon img {
  width: 11px;
  height: 11px;
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 1366px) {
  .PracticeLocations {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeLocations .h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) {
  .PracticeLocations {
    padding: 10px;
  }
}
