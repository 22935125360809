.AfterAuthSubHeader .main-inner-content .title {
  display: inline-block;
  display: flex;
  align-items: center;
}
.AfterAuthSubHeader .main-inner-content span {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.AfterAuthSubHeader .main-inner-content {
  padding: 34px 40px 44px;
}
.AfterAuthSubHeader .main-inner-content span {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  display: inline-block;
}
.AfterAuthSubHeader .main-inner-content .name {
  font-size: 36px;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  padding-left: 30px;
  position: relative;
  font-family: 'Heldane Display';
  top: 2px;
}
.AfterAuthSubHeader .main-inner-content .name:after {
  content: '';
  position: absolute;
  left: 15px;
  top: -7px;
  bottom: 0;
  width: 1px;
  background-color: rgba(1, 54, 72, 0.2);
}

@media (max-width: 1200px) {
  .AfterAuthSubHeader .main-inner-content {
    padding: 36px 20px 60px;
  }
  .AfterAuthSubHeader .main-inner-content .name {
    font-size: 24px;
  }
  .AfterAuthSubHeader .main-inner-content span {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .AfterAuthSubHeader .main-inner-content {
    padding: 36px 15px 60px;
  }
}
@media (max-width: 767px) {
  .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
    display: inline-block;
    width: 100%;
    margin-top: 5px;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
  .AfterAuthSubHeader .main-inner-content {
    padding: 20px 15px;
  }
}
