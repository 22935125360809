.AddProviderToPractice {
  padding: 42px 26px 0px 37px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  max-width: 100%;
  width: 100%;
  margin-top: 11px;
}
.AddProviderToPractice .h3 {
  font-family: 'Calibre Regular';
  font-weight: normal;
  margin-bottom: 30px;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 5px;
  display: block;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
}
.AddProviderToPractice .email-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  width: 416px;
}
.AddProviderToPractice .email-row .Input-control.email.form-group {
  width: 100%;
}
.AddProviderToPractice .email-row .Input-control.form-group {
  width: 50%;
  padding: 0px 8px;
}
.AddProviderToPractice .add-provider-form {
  display: flex;
  margin-left: -8px;
}
.AddProviderToPractice .add-provider-form .Input-control .error-text {
  left: 8px;
}
.AddProviderToPractice .action {
  padding-top: 9px;
}
.AddProviderToPractice .action .CircleButton.WhiteButton {
  width: 144px;
  height: 32px;
  border-radius: 16px;
  background-color: #26acda;
  margin-left: 15px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: -0.23px;
  color: #ffffff;
  font-family: 'Calibre Regular';
  font-weight: normal;
  border: 0;
  padding: 2px 0;
}
.AddProviderToPractice .para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #b2bcc4;
  width: 398px;
}

@media screen and (max-width: 1366px) {
  .AddProviderToPractice {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .AddProviderToPractice .add-provider-form {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }
  .AddProviderToPractice .email-row {
    width: 100%;
  }
  .AddProviderToPractice .h3 {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .AddProviderToPractice .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 413px) {
  .AddProviderToPractice {
    padding: 10px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AddProviderToPractice .action .CircleButton.WhiteButton {
    padding-top: 2px;
  }
}
