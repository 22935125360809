.AutoSuggestionSelect {
  position: relative;
}
.AutoSuggestionSelect .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.AutoSuggestionSelect .result {
  width: 100%;
  min-width: 300px;
  max-height: 402px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(167, 167, 167, 0.5);
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 62px;
  padding: 18px 32px;
  z-index: 8;
}
.AutoSuggestionSelect .result .option {
  font-size: 16px;
  line-height: 1.95;
  color: #013648;
  cursor: pointer;
  width: 100%;
  display: block;

}
.AutoSuggestionSelect .result  button.option {
  border: none;
  background: none;
  text-align: left;
  padding: 0;
}
.AutoSuggestionSelect .Input-control.form-group .view-img {
  position: absolute;
  left: 18px;
  top: calc(100% - 1.9rem);
  width: 20px;
  height: 20px;
}
.AutoSuggestionSelect INPUT {
  padding-left: 52px;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control {
  font-size: 20px;
  line-height: 1.64;
  letter-spacing: -0.38px;
  color: #b2bcc4;
  padding-top: 5px;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 1.64;
  letter-spacing: -0.38px;
  color: #b2bcc4;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control:-ms-input-placeholder {
  font-size: 20px;
  line-height: 1.64;
  letter-spacing: -0.38px;
  color: #b2bcc4;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control::placeholder {
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #b2bcc4;
  opacity: 1;
}
.AutoSuggestionSelect .Input-control.form-group INPUT.form-control {
  padding-left: 52px;
  color: #013648;
}
@media (hover: hover) {
  .AutoSuggestionSelect .result .option:hover {
    color: #3dbce7;
  }
}
@media (max-width: 767px) {
  .AutoSuggestionSelect .result {
    max-height: 200px;
  }
}
