.LoadingPage {
  background-color: #fbfbf9;
  padding-top: 119px;
  min-height: calc(100vh - 62px);
  display: flex;
}
.LoadingPage .loading-content {
  width: 531px;
  margin: auto;
  text-align: center;
  padding: 50px 15px;
}
.LoadingPage .loading-content img {
  width: 140px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circleanimation 2.5s infinite linear;
  animation: circleanimation 2.5s infinite linear;
}
.LoadingPage .loading-content .h3 {
  font-family: 'Heldane Display';
  font-size: 36px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 70px;
}
.LoadingPage ~ .CustomFooter {
  display: none;
}

/* define animation function for above */
@-webkit-keyframes circleanimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circleanimation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .LoadingPage .loading-content .h3 {
    font-size: 28px;
    margin-bottom: 50px;
  }
  .LoadingPage .loading-content {
    margin-top: 0;
    padding: 0 30px;
  }
  .LoadingPage {
    padding-top: 69px;
  }
  .LoadingPage .loading-content img {
    width: 90px;
  }
}
