.ConnectReviews .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.ConnectReviews .addwebsite-btn {
  width: 149px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
}
.ConnectReviews .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ConnectReviews .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.ConnectReviews .no-review-text {
  text-align: center;
  width: 500px;
  margin: auto;
  max-width: 100%;
  margin-top: 66px;
}
.ConnectReviews .no-review-text .Reviewtitle {
  font-size: 20px;
  font-family: 'Calibre Medium';
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: #013648;
}
.ConnectReviews .no-review-text .review-logo {
  margin-top: 19px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ConnectReviews .no-review-text .review-logo > SPAN{
  display: inline-flex;
  padding: 0 10px;
  align-items: center;  
}
.ConnectReviews .no-review-text .desciption {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
}
.ConnectReviews .no-review-text .linkspan {
  font-size: 18px;
  line-height: 1.78;
  color: #26acda;
  display: inline-block;
  margin-top: 19px;
  cursor: pointer;
}
.ConnectReviews .add-website-form {
  padding-right: 138px;
  margin-top: 36px;
}
.ConnectReviews .add-website-example {
  font-size: 16px;
  line-height: 1.38;
  color: #b2bcc4;
  word-break: break-all;
  margin-bottom: 15px;
}
.ConnectReviews .add-website-form .Select-control {
  margin-bottom: 39px;
  width: 420px;
  max-width: 100%;
}
.ConnectReviews .add-website-form .add-site-actions {
  text-align: right;
}
.ConnectReviews .add-website-form .add-site-actions .CircleButton {
  margin-left: 26px;
  width: 111px;
}
.ConnectReviews .note {
  color: rgba(1, 54, 72, 0.8);
  font-size: 14px;
  margin-bottom: 10px;
}
.ConnectReviews .add-website-form .add-site-actions .CircleButton.cancel-btn {
  background-color: #f1f1f1;
  border-color: #f1f1f1;
  color: #72787d;
}

@media (max-width: 1024px) {
  .ConnectReviews .title {
    font-size: 20px;
  }
  .ConnectReviews .refresh-btn {
    margin-right: 15px;
  }
  .ConnectReviews .addwebsite-btn {
    width: 100px;
  }
  .ConnectReviews .add-website-form {
    padding-right: 0;
  }
  .ConnectReviews .add-website-form .Select-control {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ConnectReviews .heading {
    flex-wrap: wrap;
  }
  .ConnectReviews .heading .title {
    width: 100%;
  }
  .ConnectReviews .heading > SPAN {
    margin-left: auto;
    margin-top: 15px;
  }
  .ConnectReviews .add-website-form .add-site-actions .CircleButton {
    margin-left: 15px;
  }
}
