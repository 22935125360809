.ReviewStatus {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
}
.ReviewStatus .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.ReviewStatus .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.ReviewStatus .cross-icon IMG {
  width: 100%;
}
.ReviewStatus .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.ReviewStatus .total {
  font-size: 22px;
  line-height: 1.5;
  color: #013648;
  padding-bottom: 10px;
}
.ReviewStatus .heading2 {
  font-size: 18px;
  line-height: 1.2;
  color: #26acda;
  cursor: pointer;
}
.ReviewStatus .h3 {
  font-size: 16px;
  line-height: 1.2;
  color: #013648;
}
.ReviewStatus .review-source {
  padding: 10px 0px;
  border-bottom: solid 1px #e9ebf2;
  font-size: 16px;
}
.ReviewStatus .review-source:last-child {
  border-bottom: none;
}
.ReviewStatus .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.ReviewStatus .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.ReviewStatus .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.ReviewStatus .dailog-body {
  padding: 20px 32px 15px;
}
.ReviewStatus .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.ReviewStatus .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.ReviewStatus .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.ReviewStatus .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.ReviewStatus .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.ReviewStatus .action-btn {
  text-align: right;
}
.ReviewStatus .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.ReviewStatus .pending {
  font-size: 13px;
  color: #f2962d;
}
.ReviewStatus .approved {
  font-size: 13px;
  color: #3d93a6;
}
.ReviewStatus .rejected {
  font-size: 13px;
  color: #e85d72;
}
.ReviewStatus .btn {
  margin-right: 15px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ReviewStatus .btn.btn-approve {
  background-color: #3d93a6;
  border: none;
}
.ReviewStatus .btn.btn-reject {
  background-color: #e85d72;
  border: none;
}
.ReviewStatus .btn-refresh {
  margin-right: 43px;
  padding: 5px 10px 0;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  cursor: pointer;
  outline: 0;
  height: 30px;
}
