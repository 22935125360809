.RequestRecipients .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.RequestRecipients .addwebsite-btn {
  width: 124px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestRecipients .send-btn {
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #013648;
}
.RequestRecipients .send-btn IMG {
  position: relative;
  top: -3px;
  left: -7px;
}
.RequestRecipients .select-content .arrow {
  top: 17px;
  right: 13px;
  border-width: 7px;
  border-top-color: #92929d;
}
.RequestRecipients .select-content {
  margin-bottom: 0;
  height: 40px;
}
.RequestRecipients .select-content SELECT {
  height: 40px;
}
.RequestRecipients .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.RequestRecipients .right-panel {
  display: flex;
}
.RequestRecipients .UILabel {
  position: fixed;
  right: 209px;
  top: 5px;
  margin-right: 30px;
}
.RequestRecipients .send-btn {
  width: 187px;
  height: 40px;
  padding: 13px 14px 10px 13px;
  border-radius: 2px;
  background-color: #013648;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  margin-left: 20px;
  font-family: 'Calibre Regular';
  cursor: pointer;
}
.RequestRecipients .send-btn:focus {
  outline: 0;
}
.RequestRecipients .send-btn IMG {
  margin-right: 5px;
}
.RequestRecipients .action-menu .action-btn IMG {
  width: 16px;
}
@media (max-width: 1200px) {
  .RequestRecipients .heading {
    flex-wrap: wrap;
  }
  .RequestRecipients .right-panel {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 14px;
  }
}
@media (max-width: 1024px) {
  .RequestRecipients .title {
    font-size: 20px;
  }
  .RequestRecipients .refresh-btn {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .RequestRecipients .heading {
    flex-wrap: wrap;
  }
  .RequestRecipients .heading .title {
    width: 100%;
  }
  .RequestRecipients .right-panel {
    flex-wrap: wrap;
  }
  .RequestRecipients .Select-control {
    margin-bottom: 0;
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
  }
}
@media (max-width: 414px) {
  .RequestRecipients .send-btn,
  .RequestRecipients .addwebsite-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}
