.RequestContactLocation .eachrow{
display: flex;
justify-content: space-between;
}
.RequestContactLocation .eachrow .item {
  flex: 0 0 50%;
  margin-bottom: 20px;
  }
  .RequestContactLocation .eachrow .item span{
    font-size: 18px;
    font-weight: bold;
  }
  .RequestContactLocation .eachrow .item p{
    font-size: 17px;
    margin: 0;
    margin-top: 5px;
    color: #3333339e;
  }