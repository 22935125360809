.ProgressBar {
  display: flex;
  height: 8px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: rgba(1, 54, 72, 0.2);
  border-radius: 0.25rem;
}
.ProgressBar .progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #013648;
  transition: width 0.6s ease;
}
