.InfiniteScroll {
  height: 100vh;
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}
.InfiniteScroll .infinite-scroll-loader {
  text-align: center;
}
.InfiniteScroll .infinite-scroll-loader img {
  margin: 15px;
  height: 45px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circleanimation 2.5s infinite linear;
  animation: circleanimation 2.5s infinite linear;
}
