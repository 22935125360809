.ConfirmAppointmentThanks .ConfirmAppointmentThanks-content {
  background-color: #fbfbf9;

  padding: 7% 15%;
  padding-bottom: 0%;
}

.ConfirmAppointmentThanks .ConfirmAppointmentThanks-content {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.ConfirmAppointmentThanks .reserve-appointment {
  height: 57.6px;
  font-size: 14px;
  line-height: 1.37;
  letter-spacing: -0.19px;
  color: rgba(1, 54, 72, 0.5);
  width: 100%;
  margin-top: 56px;
  margin-bottom: 50px;
}
.ConfirmAppointmentThanks .reserve-appointment a {
  color: #3dbce7;
}
.ConfirmAppointmentThanks .CustomFooter {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-top: 62px;
}
.ConfirmAppointmentThanks .footer-block:after {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
  width: 52.6%;
  background-color: #fbfbf9;
}
.ConfirmAppointmentThanks .CustomFooter .container {
  width: 100%;
}
.ConfirmAppointmentThanks .CustomFooter .footer-menu-ul {
  z-index: 1;
}
.ConfirmAppointmentThanks .title-heading {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.ConfirmAppointmentThanks .title-heading h1 {
  color: #023648;
  font-family: 'Heldane Display';
  font-size: 45px;
  line-height: 0.94;
  text-align: center;
  /* min-height: 68px; */
  letter-spacing: -0.2px;
  margin-top: 20px;
  margin-bottom: 16px;
  font-weight: normal;
}
.ConfirmAppointmentThanks .title-heading h3 {
  color: #023648;
  /* font-family: 'Heldane Display'; */
  font-size: 30px;
  line-height: 0.94;
  text-align: center;
  /* min-height: 68px; */
  letter-spacing: -0.2px;
  margin-top: 0;
  margin-bottom: 13px;
  font-weight: normal;
}
.ConfirmAppointmentThanks .ReviewConfirm .reserve-appointment {
  display: none;
  margin-top: 24px;
}
.AppointmentRequest .appointment-map {
  height: 350px;
}
.goback-div {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.goback-div a {
  font-size: 18px;
  color: #023648;
  cursor: pointer;
}
.goback-div a:hover {
  color: #0479a2;
}
.PatientHeader.small {
  height: 60px;
}
@media (max-width: 1400px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    padding-left: 100px;
    padding-right: 60px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content {
    padding-right: 60px;
  }
  .ConfirmAppointmentThanks .AppointmentRequest .back-arrow {
    left: -50px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    padding-left: 70px;
    padding-right: 30px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content {
    padding-right: 30px;
    padding-left: 30px;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    width: 100%;
  }
  .ConfirmAppointmentThanks .footer-block:after {
    width: 50.6%;
  }
}
@media (max-width: 1200px) {
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    width: 55%;
  }
  .AppointmentRequest .appointment-map {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ConfirmAppointmentThanks {
    padding-bottom: 118px;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    padding-bottom: 41px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    width: 100%;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    padding-top: 41px;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content {
    background-color: #fbfbf9;
  }
  .ConfirmAppointmentThanks .CustomFooter {
    background-color: #fbfbf9;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content {
    flex-wrap: wrap;
  }
  .ConfirmAppointmentThanks .footer-block:after {
    display: none;
  }
  .ReserveAppointment .divider-content .Divider {
    display: block;
  }
  .ConfirmAppointmentThanks .reserve-appointment {
    display: none;
  }
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    background-color: #eaf4eb;
    padding-top: 10px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .title-heading {
    font-family: 'Heldane Display';
    font-size: 28px;
    line-height: 1.21;
    color: #fff;
    padding-top: 36px;
    position: relative;
    border-bottom: 1px solid #e0e4e7;
    padding-bottom: 18px;
    padding-right: 100px;
    margin-bottom: 18px;
    display: block;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .address {
    font-size: 16px;
    line-height: 1.25;
    color: #013648;
    opacity: 1;
    margin-top: 43px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .title-heading img {
    width: 18px;
    position: absolute;
    top: -20px;
    cursor: pointer;
    right: 0;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .reserve-appointment {
    display: block;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .AppointmentRequest .questionnaire {
    display: flex;
    flex-wrap: nowrap;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 11;
    overflow: auto;
    padding-top: 50px;
    padding-bottom: 85px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-overflow-scrolling: touch;
  }
  .ConfirmAppointmentThanks.showappointment-mobile
    .AppointmentRequest
    .appointment-doctor
    .doctor-img {
    height: 105px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .appointment-request-detail {
    border-bottom: solid 1px rgba(32, 58, 112, 0.08);
    padding-bottom: 20px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .reserve-appointment {
    display: none;
  }
  .ConfirmAppointmentThanks .ReviewConfirm .reserve-appointment {
    display: block;
  }
  .CustomFooter .menu-item-list {
    width: auto;
  }
  .ConfirmAppointmentThanks .title-heading h1 {
    font-size: 35px;
  }
  .ConfirmAppointmentThanks .title-heading h3 {
    font-size: 22px;
  }
}

@media (max-width: 413px) {
  .ConfirmAppointmentThanks {
    padding-bottom: 148px;
  }
  .ConfirmAppointmentThanks .CustomFooter,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .right-content,
  .ConfirmAppointmentThanks .ConfirmAppointmentThanks-content .left-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
  .ConfirmAppointmentThanks.showappointment-mobile .left-content .title-heading {
    padding-right: 0;
  }
}
