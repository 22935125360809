.AppointmentConnecting {
  background-color: #013648;
  display: flex;
  position: relative;
}
.AppointmentConnecting .Ac-left .heading1 {
  font-family: 'Heldane Display';
  font-size: 64px;
  line-height: 0.94;
  text-align: center;
  min-height: 68px;
  letter-spacing: -0.2px;
  color: #fbfbf9;
  margin-bottom: 13px;
  margin-top: 0;
  font-weight: 400;
}
.AppointmentConnecting .sub-text {
  font-size: 24px;
  line-height: 1.17;
  text-align: center;
  letter-spacing: -0.27px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Calibre Light';
  font-weight: 300;
}
.AppointmentConnecting .Ac-left {
  width: 100%;
  padding-top: 176px;
  padding-left: 137px;
  padding-right: 137px;
  position: relative;
}
.AppointmentConnecting .Appointment-form {
  width: 887px;
  margin: auto;
  margin-top: 35px;
}
.AppointmentConnecting .Appointment-form FORM {
  display: flex;
  height: 68px;
  border-radius: 34px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  background-color: #ffffff;
  align-items: center;
  padding: 3px 7px 0px 36px;
}
.AppointmentConnecting .Appointment-form form > .Appointment-filed-control {
  padding-left: 0;
  min-width: 283px;
}
.AppointmentConnecting .Appointment-form .Input-control.form-group INPUT {
  height: auto;
  font-size: 17px;
  padding: 0;
}
.AppointmentConnecting .Appointment-form .Input-control .view-img {
  display: none;
}
.AppointmentConnecting .Appointment-form .SearchInput.form-group INPUT {
  height: 55px;
}
.AppointmentConnecting .Appointment-form .SearchInput .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
}
.AppointmentConnecting .Appointment-filed {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-left: auto;
}
.AppointmentConnecting .Appointment-filed > .Appointment-filed-control:last-child:after {
  display: none;
}
.AppointmentConnecting .CircleButton > SPAN > IMG {
  width: 25px;
  margin-left: 20px;
  position: relative;
  top: -2px;
}
.AppointmentConnecting .CircleButton > SPAN > IMG:nth-child(2) {
  display: none;
}
.AppointmentConnecting .CircleButton > SPAN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.AppointmentConnecting .CircleButton {
  font-size: 16px;
  width: 148px;
  height: 54px;
  font-family: 'Calibre Regular';
  line-height: 55px;
  border-radius: 27px;
  position: relative;
  top: -2px;
  background: #3D93A6;
  border: 1px solid #3D93A6;
  color: #ffffff;
}
.AppointmentConnecting .CircleButton .search-icon {
  display: inline-block;
  width: 15px;
  margin-right: 5px;
}
.AppointmentConnecting .CircleButton .search-icon > IMG {
  width: 100%;
  margin-left: 0;
  top: 2px;
}
.AppointmentConnecting .Ac-right {
  width: 44.5%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
}
.AppointmentConnecting .Ac-right .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(24, 34, 42, 0.2);
}
.AppointmentConnecting .scroll-down {
  position: absolute;
  right: 0;
  left: 0;
  text-align: center;
  bottom: -52px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  opacity: 0.3;
}
.AppointmentConnecting .SelectDate INPUT {
  font-size: 20px;
  letter-spacing: -0.38px;
  color: #013648;
  opacity: 1;
}
.AppointmentConnecting .Appointment-form .Input-control.form-group INPUT:focus {
  border: 0;
}
.AppointmentConnecting .Input-control.form-group INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: #72787d;
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: #72787d;
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: #72787d;
  letter-spacing: -0.34px;
}
.AppointmentConnecting .Input-control.form-group {
  margin-bottom: 0;
}
.AppointmentConnecting .Input-control.form-group INPUT,
.AppointmentConnecting .Input-control.form-group .form-control:not(:placeholder-shown) {
  border: 0;
}
.AppointmentConnecting .ElasticSearch {
  padding-right: 0px;
}
.AppointmentConnecting .Appointment-filed-control {
  position: relative;
  padding-left: 20px;
  padding-right: 15px;
}
.AppointmentConnecting .Appointment-filed-control:after {
  content: '';
  position: absolute;
  right: 0;
  top: 8px;
  bottom: 11px;
  background-color: #b2bcc4;
  width: 1px;
}
.AppointmentConnecting .Appointment-filed .zip-code:before {
  right: auto;
  left: 0px;
}
.AppointmentConnecting .doctor-specialist-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 45px auto 36px;
  width: 1055px;
  max-width: 100%;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item SPAN {
  display: inline-block;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item SPAN:first-child {
  height: 112px;
  display: flex;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item {
  height: 100%;
  align-items: stretch;
  width: 181px;
  position: relative;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item IMG {
  margin: auto;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item SPAN.dsc-name {  
  font-size: 16px;  
  line-height: 1.4;
  letter-spacing: -0.3px;
  text-align: center;
  color: #fbfbf9;
  margin-top: 14px;
}
.AppointmentConnecting .Appointment-filed-control .label-text {
  font-family: 'Calibre Medium';
  font-size: 17px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.AppointmentConnecting .Appointment-filed-control.zip-code-control {
  width: 140px;
}
.AppointmentConnecting .Appointment-filed-control.select-date-contorl {
  width: 160px;
}
.AppointmentConnecting
  .ElasticSearch
  .Input-control.form-group
  INPUT.form-control::-webkit-input-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .ElasticSearch .Input-control.form-group INPUT.form-control:-ms-input-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .ElasticSearch .Input-control.form-group INPUT.form-control::placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .ElasticSearch .Input-control.form-group INPUT.form-control:-moz-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting
  .InsuranceFilterSelect
  .Input-control.form-group
  INPUT.form-control::-webkit-input-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting
  .InsuranceFilterSelect
  .Input-control.form-group
  INPUT.form-control:-ms-input-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .InsuranceFilterSelect .Input-control.form-group INPUT.form-control::placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting
  .InsuranceFilterSelect
  .Input-control.form-group
  INPUT.form-control:-moz-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: #72787d;
}
.AppointmentConnecting .ElasticSearch .search .search-result {
  top: 31px;
  min-width: 255px;
}
.AppointmentConnecting .SelectDate .datepicker {
  top: 31px;
  left: -23px;
}
.AppointmentConnecting .InsuranceFilterSelect .result {
  left: -21px;
  top: 31px;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item IMG {
  transform: scale(1);
  transition: 0.3s all linear;
}
.AppointmentConnecting .doctor-specialist-content .dsc-item:hover IMG {
  transform: scale(1.2);
}

@media (hover: hover) {
  .AppointmentConnecting .CircleButton:hover:enabled > SPAN > IMG:nth-child(2) {
    display: block;
  }
  .AppointmentConnecting .CircleButton:hover:enabled > SPAN > IMG:nth-child(1) {
    display: none;
  }
  .AppointmentConnecting .CircleButton:hover:enabled > .search-icon > IMG:nth-child(1) {
    display: inline-block;
  }
}

@media (max-width: 1366px) {
  .AppointmentConnecting .Ac-left {
    padding-right: 70px;
    padding-left: 70px;
  }
}

@media (max-width: 1200px) {
  .AppointmentConnecting .Ac-left {
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media (max-width: 1024px) {
  .AppointmentConnecting .Ac-right {
    background-position: -104px;
  }
  .AppointmentConnecting .Ac-left .heading1 {
    font-size: 48px;
  }
  .AppointmentConnecting .Appointment-form FORM {
    flex-wrap: wrap;
    height: auto;
    padding: 0px 15px 0px 15px;
  }
  .AppointmentConnecting .Appointment-filed .zip-code {
    padding-left: 0;
  }
  .AppointmentConnecting .CircleButton {
    width: 100%;
  }
  .AppointmentConnecting .ElasticSearch {
    min-width: 100%;
  }
  .AppointmentConnecting .ElasticSearch .Input-control.form-group INPUT,
  .AppointmentConnecting .SelectDate,
  .AppointmentConnecting .InsuranceFilterSelect {
    padding-left: 0;
  }
  .AppointmentConnecting .Appointment-filed {
    margin-left: 0;
    width: 100%;
  }
  .AppointmentConnecting .InsuranceFilterSelect :after,
  .AppointmentConnecting .Appointment-filed .zip-code:after,
  .AppointmentConnecting .Appointment-filed .zip-code:before {
    left: 0;
    display: none;
  }
  .AppointmentConnecting .Appointment-form FORM {
    background-color: transparent;
    border-radius: 0;
    border: 0;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed-control {
    background-color: #fff;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 15px;
    min-height: 57px;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed .Appointment-filed-control {
    width: 32%;
  }
  .AppointmentConnecting .Appointment-filed-control:after {
    display: none;
  }
  .AppointmentConnecting .Appointment-form {
    width: 100%;
  }
  .AppointmentConnecting .InsuranceFilterSelect .result {
    min-width: 254px;
  }
  .AppointmentConnecting .doctor-specialist-content .dsc-item {
    width: 33.33%;
    margin-bottom: 30px;
  }
  .AppointmentConnecting .doctor-specialist-content {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .AppointmentConnecting .Ac-right {
    display: none;
  }
  .AppointmentConnecting .Ac-left {
    width: 100%;
    padding-top: 10px;
  }
  .AppointmentConnecting .sub-text {
    /* display: none; */
  }
  .AppointmentConnecting .Appointment-form {
    margin-top: 40px;
    text-align: center;
  }
  .AppointmentConnecting .Appointment-filed {
    width: 100%;
    flex-wrap: wrap;
  }
  .AppointmentConnecting {
    height: auto;
    margin-top: -1px;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed-control {
    min-height: 59px;
  }
  .AppointmentConnecting .scroll-down {
    display: none;
  }
  .AppointmentConnecting .CircleButton {
    width: 100%;
  }
  .AppointmentConnecting .ElasticSearch,
  .AppointmentConnecting .Ac-left .heading1 {
    max-width: 100%;
  }
  .AppointmentConnecting .Appointment-filed .zip-code,
  .AppointmentConnecting .SelectDate,
  .AppointmentConnecting .InsuranceFilterSelect {
    min-width: 100%;
    width: 100%;
  }
  .AppointmentConnecting .InsuranceFilterSelect {
    min-width: 100%;
    width: 100%;
  }  
  .AppointmentConnecting .Appointment-form FORM {
    padding: 0;
  }
  .AppointmentConnecting .Appointment-filed-control .label-text {
    text-align: left;
  }
  .AppointmentConnecting .Appointment-form FORM .Appointment-filed .Appointment-filed-control {
    width: 100%;
  }
  .AppointmentConnecting .Ac-left .heading1 {
    font-size: 40px;
    line-height: 1.2;
  }
  .PatientLandingHeader .mobile-menu {
    display: block;
  }
  .AppointmentConnecting .InsuranceFilterSelect .result {
    left: 0;
  }
}

@media (max-width: 575px) {
  .AppointmentConnecting .doctor-specialist-content .dsc-item {
    width: 50%;
  }
  .AppointmentConnecting .doctor-specialist-content .dsc-item {
    width: 145px;
  }
}

@media (max-width: 413px) {
  .AppointmentConnecting .Ac-left {
    padding-left: 15px;
    padding-right: 15px;
  }
  .AppointmentConnecting .Ac-left .heading1 {
    font-size: 36px;
  }
  .AppointmentConnecting .Appointment-form .Input-control .view-img {
    left: 15px;
  }
  .AppointmentConnecting .SelectDate .datepicker{
    left: -22px;
    width: auto;
  }
}

@supports (-webkit-touch-callout: none) {
  .AppointmentConnecting INPUT::placeholder {
    padding-top: 2px;
  }
}
