.ApprovalCard {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 11px 25px 18px;
  border: solid 1px #e2e2ea;
  display: inline-block;
  position: absolute;
  right: 40px;
  top: -106px;
  width: 390px;
}
.ApprovalCard .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.ApprovalCard .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.ApprovalCard .approval-action {
  display: block;
  margin-top: 10px;
}
.ApprovalCard .approval-action SPAN IMG {
  width: 20px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}
.ApprovalCard .approval-action SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  margin-right: 14px;
  width: 29%;
}
.ApprovalCard .approval-action SPAN:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .ApprovalCard .approval-action SPAN {
    width: auto;
    margin-right: 15px;
  }
}
