.AboutYouAsProviderStepOne .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYouAsProviderStepOne .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 28px;
}
.AboutYouAsProviderStepOne .paragraph A {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(12, 59, 75, 0.7);
}
.AboutYouAsProviderStepOne .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYouAsProviderStepOne .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.AboutYouAsProviderStepOne .form-row .column-6 {
  width: 48%;
}
.AboutYouAsProviderStepOne .signup-btn {
  margin-top: 38px;
}
.AboutYouAsProviderStepOne .signup-btn > BUTTON {
  width: 100%;
  height: 45px;
}
.AboutYouAsProviderStepOne .Select-control {
  margin-bottom: 20px;
}
.AboutYouAsProviderStepOne .Input-control .form-control {
  border-width: 1px;
}
.AboutYouAsProviderStepOne .Input-control.form-group .form-control:focus,
.AboutYouAsProviderStepOne .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYouAsProviderStepOne .Select-control.form-group select {
  border-width: 1px;
}

@media (hover: hover) {
  .AboutYouAsProviderStepOne .paragraph A:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .AboutYouAsProviderStepOne .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYouAsProviderStepOne .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYouAsProviderStepOne .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 20px;
    letter-spacing: normal;
  }
  .AboutYouAsProviderStepOne .signup-btn {
    margin-top: 40px;
  }
  .AboutYouAsProviderStepOne .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYouAsProviderStepOne .Select-control {
    margin-bottom: 18px;
  }
}
