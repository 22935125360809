.AboutYourPracticeStepOne .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYourPracticeStepOne .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 27px;
}
.AboutYourPracticeStepOne .paragraph a {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
}
.AboutYourPracticeStepOne .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYourPracticeStepOne .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
  margin-top: 16px;
}
.AboutYourPracticeStepOne .Input-control .form-control {
  border-width: 1px;
}
.AboutYourPracticeStepOne .Input-control.form-group .form-control:focus,
.AboutYourPracticeStepOne .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYourPracticeStepOne .Select-control.form-group SELECT {
  border-width: 1px;
}

@media (hover: hover) {
  .AboutYourPracticeStepOne .paragraph A:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .AboutYourPracticeStepOne .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYourPracticeStepOne .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepOne .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 40px;
    letter-spacing: normal;
  }
  .AboutYourPracticeStepOne .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepOne .signup-btn .CircleButton {
    margin-top: 11px;
  }
}
