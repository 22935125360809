.PatientHeader {
  background-color: #013648;
  height: 119px;
  display: flex;
}
.PatientHeader > DIV {
  width: 100%;
}
.PatientHeader .container {
  display: flex;
  width: 100%;
  padding: 0px 60px;
}
.PatientHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.PatientHeader .header-content .right-col {
  display: flex;
  align-items: center;
}
.PatientHeader .header-content .right-col .menu-content SPAN {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #ffffff;
}
.PatientHeader .header-content .right-col .menu-content A {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #55bfdb;
}
.PatientHeader .header-content .right-col .menu-content SPAN:last-child {
  padding-right: 0px;
}
.PatientHeader .header-content .logo {
  width: 158px;
}
.PatientHeader .header-content .logo A {
  position: relative;
  top: 5px;
}
.PatientHeader .header-content .logo A IMG {
  width: 169px;
  cursor: pointer;
}
.PatientHeader .right-col.after-login .menu-content {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}
.PatientHeader .right-col.after-login .menu-content > SPAN {
  display: inline-block;
  padding: 0px 40px;
}
.PatientHeader .right-col.after-login .menu-content .submenu {
  position: relative;
}
.PatientHeader .right-col.after-login .menu-content .submenu .username {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #ffffff;
  position: relative;
  padding-right: 25px !important;
  cursor: pointer;
  display: inline-block;
}
.PatientHeader .right-col.after-login .menu-content .submenu .title {
  display: none;
}
.PatientHeader .right-col.after-login .menu-content .submenu .username > IMG {
  position: absolute;
  right: 0;
  top: 7px;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu {
  position: absolute;
  top: 80px;
  width: 279px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: 0.3s all linear;
  background-color: #ffffff;
  min-width: 264px;
  z-index: 9;
  overflow: hidden;
  display: none;
}
.PatientHeader .header-content .right-col .menu-content .submenu.show-menu .dropdown-menu {
  display: block;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu > SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.email IMG {
  width: 20px;
  position: absolute;
  left: 20px;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.email {
  color: #3dbce7;
  padding-left: 47px;
  position: relative;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout {
  padding-left: 47px;
  position: relative;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG.logout-sm {
  display: none;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.settings-web {
  padding-left: 47px;
  position: relative;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.settings-web IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.PatientHeader .header-content .right-col .menu-content A.home-link {
  text-decoration: none;
  color: #ffffff;
  margin-right: 39px;
}
.PatientHeader .overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.PatientHeader .mobile-menu {
  display: none;
}
.PatientHeader .logoblue {
  display: none;
}
.PatientHeader .logowhite {
  display: block;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu > SPAN.contact-us {
  display: none;
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu .submenu-mobile-list {
  display: none;
}
.deletegraycolor {
  filter: invert(19%) sepia(10%) saturate(40%) hue-rotate(315deg) brightness(86%) contrast(89%);
}
.deletewhitecolor {
  filter: brightness(4.5);
}
.PatientHeader .header-content .right-col .menu-content .dropdown-menu .settings-mobile {
  display: none;
}
@media (hover: hover) {
  .PatientHeader .mobile-menu:hover SPAN:last-child {
    width: 23px;
  }
  .PatientHeader .header-content .right-col A:hover {
    text-decoration: none;
    color: rgba(85, 191, 219, 0.9);
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}

@media (max-width: 1200px) {
  .PatientHeader .container {
    padding: 0 30px;
  }
}
@media (max-width: 1024px) {
  .PatientHeader .header-content .right-col .menu-content .submenu.show-menu .dropdown-menu {
    top: 40px;
  }
}
@media (max-width: 767px) {
  .PatientHeader {
    height: 108px;
  }
  .PatientHeader .header-content .logo {
    width: 128px;
  }
  .PatientHeader .header-content .logo A IMG {
    width: 100%;
  }
  .PatientHeader .right-col {
    transition: 0.3s all linear;
    position: absolute;
    right: 0;
    top: -69px;
    left: 0;
    transition: 0.3s all linear;
    padding: 0 30px 20px;
    background-color: #013648;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    flex-wrap: wrap;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN {
    width: 100%;
    text-align: center;
    padding: 0px 0px;
    margin: 10px 0px;
    font-family: 'Calibre Regular';
    font-size: 24px;
    line-height: 1.83;
    letter-spacing: -0.24px;
    color: #fbfbf9;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.83;
    letter-spacing: -0.24px;
    color: #fbfbf9;
  }
  .PatientHeader .container {
    padding: 0 15px 0 15px;
  }
  .PatientHeader .mobile-menu SPAN {
    width: 23px;
    display: inline-block;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    transition: 0.3s all linear;
  }
  .PatientHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .PatientHeader .mobile-menu SPAN:nth-child(2) {
    top: 8px;
  }
  .PatientHeader .mobile-menu SPAN:last-child {
    top: 16px;
    width: 10px;
    left: 0;
  }
  .PatientHeader .mobile-menu {
    width: 23px;
    height: 15px;
    position: relative;
    cursor: pointer;
    display: block;
    margin: auto 0;
    top: -2px;
  }
  .PatientHeader.menu-active .right-col {
    right: 0px;
  }
  .PatientHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .PatientHeader.menu-active .mobile-menu SPAN {
    background-color: #013648;
    transform: rotate(45deg);
  }
  .PatientHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
  }
  .PatientHeader.menu-active .mobile-menu {
    top: 7px;
  }
  .PatientHeader.menu-active {
    background-color: #013648;
  }
  .PatientHeader .header-content .right-col {
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    top: -100%;
  }
  .PatientHeader.menu-active .header-content .right-col {
    top: 108px;
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }
  .PatientHeader.menu-active .mobile-menu SPAN {
    background-color: #ffffff;
  }
  .PatientHeader.menu-active .logo IMG {
    display: none;
  }
  .PatientHeader.menu-active .logo .logowhite IMG,
  .PatientHeader.menu-active .logo .logowhite {
    display: block;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN {
    font-size: 18px;
  }
  .PatientHeader .header-content .right-col .menu-content SPAN A {
    font-size: 16px;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu {
    position: relative;
    top: 0;
    box-shadow: none;
    width: 100%;
    display: block;
    background-color: transparent;
    margin-top: 27px;
    margin-bottom: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu {
    margin-bottom: 0;
    padding-right: 0 !important;
    margin-top: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu .title {
    text-align: left;
    font-family: 'Heldane Display';
    font-size: 36px;
    color: #fbfbf9;
    display: block;
    line-height: 1.28;
    word-break: break-all;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu > SPAN {
    font-family: 'Calibre Medium';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: #fbfbf9;
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 8px;
  }
  .PatientHeader .right-col.after-login .dropdown-menu > SPAN:last-child {
    font-size: 24px;
    line-height: 1.17;
    letter-spacing: -0.24px;
    color: #fbfbf9;
    font-family: 'Calibre Regular';
    border-bottom: 0;
    border-top: solid 1.5px rgba(255, 255, 255, 0.08);
    display: inline-block;
    padding-top: 20px;
    padding-bottom: 0;
  }
  .PatientHeader .right-col .settings-mobile > SPAN:first-child {
    font-family: 'Calibre Medium';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: #fbfbf9;
    text-align: left;
    padding: 0;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 8px;
  }
  .PatientHeader .right-col .settings-mobile > SPAN.setting-sm {
    position: relative;
    padding-left: 35px;
  }
  .PatientHeader .right-col .settings-mobile > SPAN.setting-sm IMG {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu > SPAN.contact-us A {
    opacity: 0.5;
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.38px;
    color: #fbfbf9;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu > SPAN.contact-us {
    position: absolute;
    right: 0;
    bottom: 0px;
    display: inline-block;
    width: AUTO;
    margin-top: 0;
    z-index: 2;
  }
  .PatientHeader .right-col .menu-content {
    width: 100%;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu > IMG {
    display: none;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu .settings-web {
    display: none;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu .submenu-mobile-list {
    display: inline-block;
    padding: 0;
    margin: 0;
    display: none;
    width: 100%;
    border-top: solid 1.5px rgba(255, 255, 255, 0.08);
    padding-top: 15px;
    margin-top: 13px;
  }
  .PatientHeader .right-col.after-login .menu-content .dropdown-menu .submenu-mobile-list > A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 2.3;
    letter-spacing: -0.2px;
    color: #fbfbf9;
    text-align: left;
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu-mobile-list SPAN:last-child {
    border-bottom: 0 !important;
  }
  .PatientHeader
    .right-col.after-login
    .menu-content
    .settings-mobile.show-menu
    .submenu-mobile-list {
    display: block;
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu .settings-mobile {
    display: block;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu .title {
    display: block;
  }
  .PatientHeader .right-col.after-login .menu-content .submenu .username {
    display: none;
  }
  .PatientHeader .container {
    padding: 0 30px 0 30px;
  }
  .PatientHeader .header-content .right-col.after-login .menu-content SPAN.logout {
    padding-top: 20px;
    font-size: 24px;
    line-height: 28px;
    padding-left: 35px;
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG {
    left: 0;
    display: none;
  }
  .PatientHeader .header-content .right-col .menu-content .dropdown-menu SPAN.logout IMG.logout-sm {
    display: block;
  }
  .PatientHeader .header-content .right-col .menu-content .submenu.show-menu .dropdown-menu {
    top: 0px;
  }
  @media (hover: hover) {
    .PatientHeader .header-content .right-col.after-login .menu-content .dropdown-menu SPAN:hover {
      background-color: transparent;
    }
  }
}

@media (max-width: 413px) {
  .PatientHeader .container {
    padding: 0 15px;
  }
}
