.Checkbox-control {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 5px;
  margin-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
}
.Checkbox-control A {
  color: #3dbce7;
}
.Checkbox-control INPUT:disabled {
  cursor: initial;
}
.Checkbox-control INPUT {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 18px;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1;
}
.Checkbox-control .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 18px;
  border-radius: 1.1px;
  border: solid 1px #013648;
}
.Checkbox-control INPUT:checked ~ .checkmark {
  background-color: #013648;
}
.Checkbox-control .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.Checkbox-control INPUT:checked ~ .checkmark:after {
  display: block;
}
.Checkbox-control .checkmark:after {
  left: 6px;
  top: 1px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.Checkbox-control-label{
  cursor: pointer;
}
@media (hover: hover) {
  .Checkbox-control A:hover {
    color: #013648;
  }
}
@media (max-width: 767px) {
  .Checkbox-control .checkbox-container:not(:last-child) {
    margin-bottom: 23px;
  }
}
.is-disabled{
  border-color: #dcdcdc !important;
  cursor: not-allowed !important;
}
