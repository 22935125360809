.kalymdrequestform {
  padding: 60px 0;
  background-color: #fbfbf9;
  min-height: calc(100vh - 281px);
}
.kalymdrequestform .kalymdrequestform-container {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.kalymdrequestform .h3 {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 26px;
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  font-weight: normal;
}
.kalymdrequestform .checkbox-block {
  margin-top: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.kalymdrequestform .checkbox-block.readandacceptclass{
  justify-content: flex-start;

}
.kalymdrequestform .checkbox-block.readandacceptclass .Checkbox-control {
  margin-right: 0;
}
.kalymdrequestform .checkbox-block.readandacceptclass .labeldiv{
  letter-spacing: -0.24px;
  color: #013648;
  font-size: 18px;
}

.kalymdrequestform .Checkbox-control {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.kalymdrequestform .login-btn {
  margin-bottom: 47px;
  margin-top: 28px;
}
.kalymdrequestform .login-btn .CircleButton.WhiteButton {
  width: 100%;
  height: 45px;
  line-height: 45px;
}

.kalymdrequestform .checkbox-block .reset-link {
  font-size: 18px;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: center;
  color: #3dbce7;
  cursor: pointer;
}

.kalymdrequestform .Input-control.transform-input input {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.kalymdrequestform .Input-control.transform-input input:focus {
  border: solid 1px #013648;
}

.kalymdrequestform .form-row {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (hover: hover) {
  .kalymdrequestform .login-bottom a:hover {
    color: #013648;
  }
  .Login .checkbox-block .reset-link:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .kalymdrequestform {
    padding: 90px 0;
  }
  .kalymdrequestform .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
  .kalymdrequestform .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .kalymdrequestform .login-btn {
    margin-bottom: 37px;
  }
}
@media (max-width: 1199px) {
  .kalymdrequestform .form-row{
    display: block;
  }
}

@media (max-width: 767px) {
  .kalymdrequestform .divider-content .Divider {
    display: block;
  }
  .kalymdrequestform {
    padding: 32px 0;
    min-height: 100vh;
  }
  .kalymdrequestform .login-container {
    padding: 0 0px;
    width: 100%;
    max-width: 100%;
  }
  .kalymdrequestform .h3 {
    font-size: 28px;
    margin-bottom: 15px;
    padding-bottom: 12px;
    padding: 0 30px 15px;
    letter-spacing: 0;
    line-height: 1.21;
  }
  .kalymdrequestform .checkbox-block {
    margin-top: 25px;
    margin-bottom: 15px;
    padding-left: 11px;
  }
  .kalymdrequestform .login-btn {
    margin-bottom: 30px;
    margin-top: 23px;
  }
  .kalymdrequestform .ca-social {
    padding: 0 30px;
  }
  .kalymdrequestform .ca-social .social-icon {
    width: 100%;
    padding-top: 4px;
  }
  .kalymdrequestform .login-form {
    padding: 0px 30px;
  }
  .kalymdrequestform .login-form .Input-control.transform-input {
    margin-bottom: 20px;
  }
  .kalymdrequestform .ca-social .social-icon {
    font-size: 18px;
    border-radius: 2px;
    border: solid 1px rgba(1, 54, 72, 0.2);
    margin-bottom: 15px;
  }
  .kalymdrequestform .login-form .Input-control .form-control {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    color: #013648;
  }
  .kalymdrequestform .Checkbox-control {
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    font-family: 'Calibre Medium';
  }
  .kalymdrequestform .checkbox-block .reset-link {
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -0.3px;
    font-family: 'Calibre Medium';
  }
  .kalymdrequestform .login-btn > * {
    width: 100%;
    height: 48px;
    line-height: 48px;
  }
  /* .kalymdrequestform ~ .CustomFooter {
    display: none;
  } */
}
@media (max-width: 413px) {
  .kalymdrequestform .login-form,
  .kalymdrequestform .ca-social {
    padding: 0px 15px;
  }
  .kalymdrequestform .h3 {
    padding: 0 15px 17px;
  }
  .kalymdrequestform .divider-content .Divider .container {
    padding: 0 15px;
  }
}
