.AdminJoinUs .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.AdminJoinUs {
  width: calc(100% - 250px);
}

.AdminJoinUs .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
.filenameSpan{
  display:block;
    width:100px;
    word-wrap:break-word;
}
.AdminJoinUs .photoid{
  width: 36px;
  height: 23px;
  top: 5px;
  position: relative;
  cursor: pointer;
}
.AdminJoinUs .actionbtndev{
  display: grid
}
.AdminJoinUs .actionbtndev button:first-child{
margin-left: 0;
}
.viewPhotoIdmodal{
width: 100% !important;
}
/* .AdminJoinUs .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.AdminJoinUs .search div {
  position: relative;
}
/* .AdminJoinUs .search button {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.AdminJoinUs .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.AdminJoinUs .search button.btn-clear IMG {
  width: 15px;
}
.AdminJoinUs .admin-provider-list thead td:nth-child(4) {
  min-width: 150px;
}

.AdminJoinUs .admin-provider-list thead td:last-child {
  min-width: 146px;
}
.AdminJoinUs.approvedJoinUs .admin-provider-list thead td:last-child {
  min-width: auto;
}
.AdminJoinUs.approvedJoinUs .admin-provider-list thead td:nth-last-child(2) {
  min-width: 146px;
}
.AdminJoinUs .admin-provider-list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}
/* .AdminJoinUs .admin-provider-list td,
.AdminJoinUs .admin-provider-list th {
  border: 1px solid black;
} */
.AdminJoinUs .admin-provider-list td {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
}
.AdminJoinUs .admin-provider-list thead td {
  background-color: #013648;
  color: white;
}

.AdminJoinUs .no-record {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}

.AdminJoinUs .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.AdminJoinUs .pagination li {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 8px 0px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.AdminJoinUs .pagination li a {
  padding: 8px 16px;
}
.AdminJoinUs .pagination li.active {
  background-color: #013648;
  color: white;
}
.AdminJoinUs .pagination li.previous.disabled,
.AdminJoinUs .pagination li.next.disabled {
  opacity: 0;
  cursor: unset;
}
.AdminJoinUs .pagination li:hover:not(.active) {
  background-color: #ddd;
}
.AdminJoinUs .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.AdminJoinUs .btn.btn-approve {
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.AdminJoinUs .btn.btn-reject {
  background-color: #f37184;
  border: none;
  margin: 0;
    margin-bottom: 5px;
}
.AdminJoinUs .btn.btn-waiting {
  background-color: #f5ab0c;
  border: none;
  margin: 0;
    margin-bottom: 5px;
}
.AdminJoinUs .pointer {
  cursor: pointer;
}
.AdminJoinUs .admin-provider-list td .sort-icon {
  margin-left: 10px;
  position: relative;
}
.AdminJoinUs .admin-provider-list td .sort-icon img {
  width: 15px;
  top: -1px;
  position: absolute;
}
.AdminJoinUs .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.Upload-newFile {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  float: right;
  margin-left: 10px;
  border: 0;
}
.Upload-newFile[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}
.Upload-newFile:hover {
  opacity: 0.8;
}

 .admin-uploadfile .Input-control.form-group .fileupload {
  width: 100%;
  height: 60px;
  background: #fff;
  box-sizing: border-box;
}
.admin-uploadfile .fileupload {
  min-height: 52px;
  border-radius: 2px;
  background-color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  padding: 5px 142px 5px 20px;
  word-break: break-all;
  border: 1.5px solid rgba(1,54,72,.2);
}
.admin-uploadfile .fileupload .preview-img {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}
.admin-uploadfile .fileupload .preview-img-multy {
  grid-template-columns: 55px 1fr;
}
.admin-uploadfile .fileupload .preview-img img {
  max-width: 50px;
  max-height: 33px;
}
.admin-uploadfile .fileupload .preview-img span {
  position: relative;
  display: block;
  top: 2px;
  overflow: hidden;
}
.admin-uploadfile .fileupload .preview-img span {
  position: relative;
  display: block;
  top: 2px;
  overflow: hidden;
}
.admin-uploadfile .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}
.admin-uploadfile .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.admin-uploadfile .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: Calibre Medium;
  font-weight: 500;
  padding-top: 4px;
  background-color: #809ba4;
}
 .react-viewer-toolbar{
display: none;
}