.Marker {
  position: relative;
}
.Marker .marker-image {
  height: 46px;
}
.Marker .doctor-detail-map {
  position: absolute;
  bottom: 46px;
  left: -139px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  border-radius: 8px;
  width: 317px;
  z-index: 9;
}
.Marker .doctor-card:before {
  content: '';
  position: absolute;
  height: 0;
  width: 0;
  bottom: -11px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid #fff;
}
.Marker .doctor-container {
  width: 317px;
  overflow: hidden;
}
.Marker .doctor-child-container {
  display: flex;
  position: relative;
  left: 0;
}
.Marker .doctor-slider {
  position: absolute;
  top: -48px;
  right: 0;
  background: white;
  justify-content: space-between;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  cursor: auto;
}
.Marker .pagination-div {
  font-size: 20px;
  padding: 0 5px;
  cursor: pointer;
}
.Marker .pagination-div.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.Marker .doctor-card {
  min-width: 317px;
  min-height: 119px;
  padding: 12px 11px;
  display: flex;
  border-bottom: solid 1px rgba(1, 54, 72, 0.1);
}
.Marker .doctor-card:last-child {
  border-bottom: unset;
}
.Marker .doctor-detail-map .dc-img {
  width: 60px;
  min-width: 60px;
  height: 90px;
}
.Marker .doctor-detail-map .dc-img img {
  border-radius: 2.4px;
  border: solid 0.6px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Marker .doctor-detail-map .dc-info {
  padding-left: 11px;
  width: 100%;
}
.Marker .doctor-detail-map .dc-details .dc-name {
  font-family: 'Heldane Display';
  font-size: 14px;
  line-height: 1.46;
  color: #013648;
  word-break: break-all;
}
.Marker .doctor-detail-map .dc-details .dc-specialist {
  font-family: 'Calibre Regular';
  font-size: 12px;
  line-height: 1.26;
  letter-spacing: normal;
  color: #013648;
  word-break: break-all;
}
.Marker .doctor-detail-map .dc-details .dc-address {
  font-family: 'Calibre Regular';
  font-size: 12px;
  line-height: 1.17;
  color: #72787d;
  word-break: break-all;
}
.Marker .doctor-detail-map .dc-content {
  display: flex;
  justify-content: space-between;
}
.Marker .doctor-detail-map .dc-rating img {
  width: 24px;
  height: 23px;
  object-fit: cover;
  position: relative;
  top: 4px;
  left: 5px;
}
.Marker .doctor-detail-map .dc-rating {
  position: relative;
  display: flex;
}
.Marker .doctor-detail-map .dc-rating .rating-text {
  font-family: 'Heldane Display';
  font-size: 28.8px;
  line-height: 0.71;
  letter-spacing: -0.58px;
  color: #013648;
}
.Marker .doctor-detail-map .dc-action {
  display: flex;
}
.Marker .doctor-detail-map .dc-action a,
.Marker .doctor-detail-map .dc-action span {
  font-family: 'Calibre Regular';
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: -0.26px;
  color: #26acda;
  display: inline-block;
  margin-top: 10px;
  cursor: pointer;
}
.Marker .doctor-detail-map .dc-action span {
  margin-left: 18px;
}

@media (hover: hover) {
  .Marker .doctor-detail-map .dc-action a:hover,
  .Marker .doctor-detail-map .dc-action span:hover {
    opacity: 0.7;
  }
}

@media (max-width: 767px) {
  /* .Marker {
    position: static;
  }
  .Marker .doctor-detail-map {
    position: fixed;
    left: -48%;
    right: 30px;
    bottom: -212px;
  }
  .Marker .doctor-card:before {
    display: none;
  } */
}
