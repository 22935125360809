.ScheduleActivity {
  width: 350px;
  min-width: 350px;
  border-radius: 20px;
  background-color: #ffffff;
}
.ScheduleActivity .title .h3 {
  font-family: 'Calibre Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.11px;
  color: #013648;
  display: inline-flex;
  align-items: center;
}
.ScheduleActivity .title {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 13px 28px;
  border-bottom: 1px solid #f0f0f1;
}
.ScheduleActivity .Select-control {
  margin-bottom: 0;
}
.ScheduleActivity .content .InfiniteScroll{
  max-height: 55vh;
}
.ScheduleActivity .Select-control .active-dropdown {
  width: 133px;
  height: 40px;
  transform: rotate(-360deg);
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-left: 15px;
  line-height: 41px;
  padding-right: 30px;
  padding-top: 1px;
  color: #44444f;
}
.ScheduleActivity .Select-control .arrow {
  position: absolute;
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 17px;
  right: 15px;
  z-index: 1;
}
.ScheduleActivity .content {
  display: flex;
  height: calc(100% - 74px);
  padding: 30px 10px;
}
.ScheduleActivity .content .acitive-bg-content .icon IMG {
  width: 74px;
}
.ScheduleActivity .content .acitive-bg-content .icon {
  margin-bottom: 34px;
  text-align: center;
}
.ScheduleActivity .content .acitive-bg-content {
  margin: auto;
  margin-top: 109px;
}
.ScheduleActivity .content .acitive-bg-content SPAN {
  width: 206px;
  height: 40px;
  display: inline-block;
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #72787d;
}
.ScheduleActivity .activity-list {
  width: 100%;
}

@media (max-width: 1400px) {
  .ScheduleActivity {
    min-width: 225px;
    width: 225px;
  }
  .ScheduleActivity .Select-control .active-dropdown {
    font-size: 16px;
    width: 115px;
  }
}

@media (max-width: 1200px) {
  .ScheduleActivity {
    width: 100%;
    margin-bottom: 20px;
  }
}
