.ContactSourceHistoryView {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 810px;
  margin: auto;
  position: relative;
}
.ContactSourceHistoryView .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 33px 0 33px;
}
.ContactSourceHistoryView .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ContactSourceHistoryView .modal-content-header .close IMG {
  width: 100%;
}
.ContactSourceHistoryView .list-history {
  padding: 33px;
}
ul.eachHistory {
  list-style: none;
  padding: 0;
}
li.eachHistory {
  display: grid;
    grid-template-columns: 20% 40% 40%;
  padding: 5px 10px;
}
li.eachHistory:nth-child(odd) {
  background-color: #eaeaea;
}
li.eachHistory:nth-child(even) {
  background-color: #f9f9f9;
}
li.eachHistory img {
  width: 20px;
  margin-right: 15px;
}
li.eachHistory div{
  flex: 0 0 1;
}
li.eachHistory p {
  margin-bottom: 5px;
}
.ContactSourceHistoryView .modal-content-body {
  overflow: auto;
  min-height: 350px;
}
.ContactSourceHistoryView .modal-content-body .rtable {
  overflow: auto;
  max-height: 350px;
}
.ContactSourceHistoryView .heading3 {
  font-size: 20px;
  text-transform: capitalize;
  color: #fff;
}
.mt-10 {
  margin-top: 10px;
}
.viewHistory {
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr 1fr;
}
.viewHistory .small-label {
  font-size: 17px !important;
  text-transform: capitalize;
}
.viewHistory .select-date-content {
  margin: 0;
  z-index: 5;
}
.viewHistory .select-date-content.end-date{
  z-index: 4;
}
.viewHistory .select-date-content .Datepicker-content {
  position: absolute;
  top: 50px;
}
.viewHistory .select-date-content.end-date .Datepicker-content {
  right: 67%;
}
.viewHistory  .MyDatetime .rdtPicker{
width: 300px;
padding-top: 40px;
}
.viewHistory .date-box{
  border: 1px solid #3333337d;
    border-radius: 5px;
    padding: 9px 28px;
    padding-bottom: 5px;
    padding-left: 10px;
}
.viewHistory .date-box img{
  position: relative;
  top: 7px;
  right: -12px;
}
.viewHistory .MyDatetime .rdtPicker .rdtDays table{
  border-spacing: 20px 0px;
}
.viewHistory .MyDatetime.days .rdtPicker .rdtSwitch{
  top: 17px;
  left: 20px;
}
.viewHistory .MyDatetime.days .rdtPicker th.rdtNext, 
.viewHistory .MyDatetime.days .rdtPicker th.rdtPrev{
  top: 10px;
}
.viewHistory .MyDatetime.days .rdtPicker .rdtSwitch:after{
  width: 38px;
  top: 4px;
}
.viewHistory .MyDatetime.days .rdtPicker td{
  line-height: 25px;
}
@media screen and (max-width: 850px) {
  .ContactSourceHistoryView {
    width: 750px;
  }
}
@media screen and (max-width: 766px) {
  .ContactSourceHistoryView {
    width: 95%;
  }
}
@media screen and (max-width: 767px) {
  .viewHistory{
    overflow: initial;
   
  }
  .viewHistory .select-date-content.end-date .Datepicker-content {
   left: -33%;
}
}
@media screen and (max-width: 766px) {
  .select-date-content.end-date {
   position: relative !important;
  }
}
@media screen and (max-width: 600px) {
  .viewHistory{
    display: block;
   
  }
  .viewHistory .select-date-content.end-date .Datepicker-content {
    left: 10%;
  }
}

  @media screen and (max-width: 420px) {
  .viewHistory .MyDatetime .rdtPicker {
width: 220px;
}
.viewHistory .MyDatetime .rdtPicker .rdtDays table {
  border-spacing: 10px 0px;
}
.rdtPicker td, .rdtPicker th{
  height: 27px;
}
.MyDatetime.days .rdtPicker .rdtSwitch{
  font-size: 15px;
}

.viewHistory .MyDatetime.days .rdtPicker .rdtSwitch:after{
display: none;
}
}