.EditLocation {
  border-radius: 2px;
  background-color: #f7f9f9;
  margin-bottom: 30px;
}
.EditLocation input:disabled {
  background-color: #f1f1f5 !important;
}
.EditLocation:last-child {
  margin-bottom: 0;
}
.EditLocation .location-content {
  display: none;
  padding: 9px 29px 40px;
}
.EditLocation.show .location-content {
  display: block;
}
.EditLocation .Textarea,
.EditLocation .TagsInput .form-col,
.EditLocation .Input-control{
  scroll-margin-top: 80px;
}
.EditLocation .location-header {
  position: relative;
  font-size: 24px;
  line-height: 0.92;
  letter-spacing: normal;
  color: #013648;
  padding: 31px 43px 18px 29px;
  border-radius: 2px;
  min-height: 82px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.EditLocation .location-header:after {
  content: '';
  position: absolute;
  right: 31px;
  top: 50%;
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #013648;
}
.EditLocation.show .location-header:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #013648;
  border-top: 0;
}
.EditLocation .toggle-icon {
  margin-right: 29px;
  position: relative;
  width: 19px;
  height: 19px;
}
.EditLocation .toggle-icon SPAN {
  height: 2px;
  border-radius: 0.9px;
  background-color: #013648;
  opacity: 0.2;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
}
.EditLocation .toggle-icon SPAN:first-child {
  top: 0;
}
.EditLocation .toggle-icon SPAN:nth-child(2) {
  top: 4px;
}
.EditLocation .toggle-icon SPAN:nth-child(3) {
  top: 9px;
}
.EditLocation .website-label {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 14px;
}
.EditLocation .website-label .inner-label {
  color: rgba(1, 54, 72, 0.4);
}
.EditLocation .Input-control.form-group .label .inner-label {
  color: rgba(1, 54, 72, 0.4);
}
.EditLocation .add-email SPAN {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #3d93a6;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 40px;
}
.EditLocation .add-email SPAN IMG {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
}
.EditLocation .form-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.EditLocation .form-row .column-9 {
  width: calc(100% - 98px);
  padding-right: 11px;
}
.EditLocation .form-row .column-3 {
  width: 98px;
}
.EditLocation .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
}
.EditLocation .save-delete-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.EditLocation .save-delete-wrapper .delete-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #f37184;
  margin-left: 15px;
  cursor: pointer;
}
.EditLocation .Input-control.form-group.firstEmail {
  margin-bottom: 20px;
}
.EditLocation .Input-control .correct-icon {
  width: 16px;
  height: 16px;
  background-color: #3d93a6;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 6px;
}
.EditLocation .Input-control .correct-icon IMG {
  width: 9px;
}
.EditLocation .Input-control .close-icon {
  width: 16px;
  height: 16px;
  background-color: #e85d72;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 6px;
}
.EditLocation .Input-control .close-icon IMG {
  width: 8px;
}
.EditLocation .Select-control.form-group SELECT.active-dropdown {
  border-radius: 4px;
  height: 38px;
  border-width: 1px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #3d93a6;
}
.EditLocation .Select-control.form-group SELECT.active-dropdown ~ .arrow {
  border: 0;
  border-left: solid 1px #e2e2ea;
  top: 24px;
  right: 0px;
  width: 36px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EditLocation .Select-control.form-group SELECT.active-dropdown ~ .arrow:after {
  content: '';
  border: 8px solid rgba(143, 146, 161, 0.8);
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  position: relative;
  top: 4px;
}
.EditLocation .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 6px;
}
.EditLocation .Input-control.form-group {
  margin-bottom: 36px;
}
.EditLocation .location-form .Input-control.form-group {
  margin-bottom: 14px;
}
.EditLocation .location-form .input-list-label {
  margin-bottom: 9px;
}
.EditLocation .location-form .Input-control.form-group.location-name {
  margin-bottom: 36px;
}
.EditLocation .location-form .Select-control.form-group {
  margin-top: 25px;
  margin-bottom: 40px;
}

@media screen and (max-width: 1366px) {
  .EditLocation .location-header {
    padding: 18px 43px 18px 20px;
  }
  .EditLocation .location-content {
    padding: 6px 20px 40px;
  }
}

@media screen and (max-width: 1200px) {
  .EditLocation .location-header {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .EditLocation .save-delete-wrapper {
    flex-wrap: wrap;
  }
  .EditLocation .save-delete-wrapper .delete-link {
    margin-top: 15px;
    margin-left: 0;
  }
}
