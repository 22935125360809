.PatientAccount {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 39px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientAccount .form-row .error-text {
  left: 12px;
}
.PatientAccount .optional-text {
  /* font-size: 14px;
  line-height: 16px;
  font-family: 'Calibre Regular'; */
  color: rgba(1, 54, 72, 0.4);
}
.PatientAccount .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 17px;
}
.PatientAccount .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 23px;
}
.PatientAccount .Select-control .arrow {
  right: 33px;
}
.PatientAccount .PatientAccount-block {
  margin-bottom: 32px;
}
.PatientAccount .Input-control.form-group {
  margin-bottom: 31px;
}
.PatientAccount .DateManual {
  margin-top: 23px;
}
.PatientAccount .Input-control.form-group .label {
  line-height: 1.5;
  margin-bottom: 1px;
}
.PatientAccount .form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.PatientAccount .form-row .SelectDate .label {
  line-height: 1.5;
}
.PatientAccount .form-row .column6 {
  width: 50%;
  padding: 0 12px;
}
.PatientAccount .form-row .column9 {
  padding: 0 12px;
  width: calc(100% - 132px);
}
.PatientAccount .form-row .column3 {
  padding: 0 12px;
  width: 132px;
}
.PatientAccount .pateintaccount-top {
  border-radius: 2px;
  background-color: #f1f1f1;
  padding: 11px 25px 7px;
  margin-bottom: 25px;
}
.PatientAccount .pateintaccount-top .heading2 {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #013648;
  margin-bottom: 5px;
}

.PatientAccount .pateintaccount-top .para {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.PatientAccount .pateintaccount-top .para .spanlink {
  color: #26acda;
}
.PatientAccount .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}
.PatientAccount .patientAccount-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.PatientAccount .patientAccount-button .deactive-link {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #f37184;
  cursor: pointer;
}

@media (hover: hover) {
  .PatientAccount .patientAccount-button .deactive-link:hover {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1200px) {
  .PatientAccount {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientAccount .pateintaccount-top {
    padding: 11px 15px;
  }
  .PatientAccount .form-row .column6 {
    width: 100%;
  }
  .PatientAccount .form-row .column9 {
    width: 100%;
  }
  .PatientAccount .form-row .column3 {
    width: 100%;
  }
  .PatientAccount .Select-control .arrow {
    right: 21px;
  }
  .PatientAccount .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .PatientAccount .patientAccount-button .deactive-link {
    display: inline-block;
    margin-top: 6px;
    font-size: 16px;
  }
  .PatientAccount .CircleButton.WhiteButton{
    width: 90px;
  }
}

@supports (-webkit-touch-callout: none) {
  .PatientAccount .CircleButton.WhiteButton {
    padding-top: 2px;
  }
}
