.pdf-viewer{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #00000047;
  z-index: 5000;
  text-align: center;
}
.pdf-viewer .viewer{
  margin: 0 auto;
  width: 80vw;
  margin-top: 0px;
}
.pdf-viewer .viewer iframe{
  width: 100%;
  border: none;
  min-height: 80vh;
}
.pdf-viewer .top-control{
  width: 100%;
    height: 50px;
}
.pdf-viewer .top-control .close-btn{
  float: right;
  background: transparent;
  color: white;
  border: none;
  font-size: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}