.Modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.Modal .modal-wrapper {
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.Modal .modal-body {
  padding: 20px;
  min-height: 150px;
  font-size: 15px;
  line-height: 22px;
  background: #fefefe;
}
.Modal .modal-header,
.Modal .modal-footer {
  padding: 10px 20px;
  border-radius: 0 0 4px 4px;
}
.Modal .modal-header {
  background: #174c99;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.Modal .modal-header .title {
  font-size: 20px;
}
.Modal .modal-footer {
  border-top: #eee solid 1px;
  text-align: right;
  background: #fefefe;
}
.Modal .btn-close {
  color: #f5f5f5;
  font-size: 30px;
  text-decoration: none;
  position: absolute;
  right: 5px;
  top: 3px;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.Modal .cancel-btn {
  height: 35px;
  border-radius: 4px;
  padding: 10px 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  background: #e5e5e5;
  color: #222;
  border: 0;
  margin-right: 15px;
  cursor: pointer;
}
.Modal .modal-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.Modal.mega-modal .modal-wrapper {
  max-width: inherit;
  width: inherit;
}

@media (hover: hover) {
  .Modal .btn-close:hover {
    color: #fff;
    opacity: 0.8;
  }
}

@media (max-width: 767px) {
  .Modal.mega-modal .modal-wrapper {
    max-width: 100%;
    width: 90%;
  }
  .Modal.fullpage-height .modal-wrapper{
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(0,0);
  }
  .Modal .mob-screen{
    width: 100%;
    margin-bottom: 10px;
  }
}