.PracticeOverview {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 36px 40px 40px;
  margin-bottom: 40px;
}
.PracticeOverview .h3 {
  font-size: 24px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 40px;
}
.PracticeLocation .Input-control,
.practiceOverview-inner .Input-control{
  scroll-margin-top: 80px;
}
.practiceOverview-inner .Textarea{
  scroll-margin-top: 80px;
}

.PracticeOverview .Select-control .arrow {
  top: 55px;
}
.PracticeOverview .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  margin-top: 17px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 1px;
  border: 1px solid #013648;
}
.PracticeOverview .Textarea.form-group TEXTAREA {
  height: 160px;
}
.PracticeOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
  padding-left: 25px;
}
.PracticeOverview .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.PracticeOverview .Input-control.form-group {
  margin-bottom: 36px;
}
.PracticeOverview .Textarea.form-group .label {
  line-height: 1.25;
  margin-bottom: 9px;
}
.PracticeOverview .Textarea.form-group {
  margin-bottom: 34px;
}
.PracticeOverview .Select-control .label {
  line-height: 1.25;
  margin-bottom: 14px;
  display: block;
}

@media screen and (max-width: 1366px) {
  .PracticeOverview {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .PracticeOverview .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PracticeOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px !important;
  }
}
