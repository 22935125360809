.Upload {
  position: relative;
  height: 52px;
  border-radius: 2.2px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  background-color: #ffffff;
  padding: 5px;
  padding-left: 52px;
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 23px;
  width: 100%;
}
.Upload input {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
}
.Upload .error-text {
  position: absolute;
  top: 53px;
  left: 0px;
  color: red;
}
.Upload img {
  width: 42px;
  height: 42px;
  opacity: 0.51;
  border-radius: 1.8px;
  background-color: #f7f9f9;
  position: absolute;
  left: 5px;
  top: 3px;
}
.Upload .filename {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #26acda;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 57px;
  right: 10px;
  top: 13px;
}
.Upload .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  position: absolute;
  top: -24px;
  left: 0;
}
@media (max-width: 414px) {
  .Upload input {
    width: 100%;
  }
}
