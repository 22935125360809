.CCPAPolicy {
  padding: 131px 0;
}
.CCPAPolicy .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.CCPAPolicy .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.CCPAPolicy .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.CCPAPolicy .title-txt {
  margin-bottom: 37px;
}
.CCPAPolicy .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.CCPAPolicy .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.CCPAPolicy .back-yellow {
  background-color: yellow;
}
.CCPAPolicy ol {
  list-style-position: outside;
}
.CCPAPolicy .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}
.CCPAPolicy table.tbl-with-style {
  border-collapse: collapse;
  margin: 15px 0px;
}
.CCPAPolicy table.tbl-with-style,
.CCPAPolicy table.tbl-with-style td,
.CCPAPolicy table.tbl-with-style th {
  border: 1px solid black;
  padding: 5px;
}
.CCPAPolicy table.tbl-with-style td:nth-child(3) {
  text-align: center;
  font-weight: bold;
}
.CCPAPolicy .address-cell {
  vertical-align: top;
}

@media screen and (max-width: 1199px) {
  .CCPAPolicy {
    padding: 90px 0;
  }
  .CCPAPolicy .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .CCPAPolicy {
    padding: 70px 0;
  }
  .CCPAPolicy .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .CCPAPolicy .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .CCPAPolicy .title-txt {
    margin-bottom: 27px;
  }
  .CCPAPolicy .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .CCPAPolicy {
    padding: 50px 0;
  }
  .CCPAPolicy .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .CCPAPolicy .sub-header-title {
    margin-bottom: 15px;
  }
  .CCPAPolicy .title-txt {
    margin-bottom: 20px;
  }
  .CCPAPolicy .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
