.SendReviewRequest {
  background-color: white;
  height: auto;
overflow: scroll;
  max-height: 80vh;
  width: 100%;
  padding: 0px;
}
.SendReviewRequest .dailog-header {
  padding: 22px 31px 5px;
  /* border-bottom: solid 1px #e9ebf2; */
  display: flex;
  justify-content: space-between;
  background-color: #013648;
  position: relative;
}
.SendReviewRequest .form-row{
  display: flex;
column-gap: 10px;
}
.SendReviewRequest .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.SendReviewRequest .cross-icon IMG {
  width: 100%;
}
.SendReviewRequest .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.SendReviewRequest .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.SendReviewRequest .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.SendReviewRequest .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.SendReviewRequest .dailog-body {
  padding: 20px 32px 15px;
}
.SendReviewRequest .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
/* .SendReviewRequest .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.SendReviewRequest .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.SendReviewRequest .dailog-body .Select-control .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
} */
.SendReviewRequest .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.SendReviewRequest .action-btn {
  text-align: right;
}
.SendReviewRequest .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
