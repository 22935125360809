.ProviderPercentage {
  padding-bottom: 34px;
  margin-bottom: 17px;
  border-bottom: 1px solid #f1f1f5;
}
.ProviderPercentage:last-child {
  border-bottom: 0;
}
.ProviderPercentage .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 2px;
  font-family: 'Calibre Medium';
}
.ProviderPercentage .h3 .per-txt {
  color: #b2bcc4;
}
.ProviderPercentage .sub-para {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  margin-bottom: 28px;
}
.ProviderPercentage .add-input {
  margin-top: 16px;
  margin-bottom: 20px;
}
.ProviderPercentage .add-input SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderPercentage .add-input SPAN IMG {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
  margin-right: 10px;
  position: relative;
  top: 2px;
}
.ProviderPercentage .action-block {
  display: flex;
  align-items: center;
}
.ProviderPercentage .action-block .error-txt {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #f37184;
  margin-left: 21px;
  margin-top: 5px;
}
.ProviderPercentage .pt-category-box {
  width: 250px;
  margin: 0 -3px 30px;
  display: flex;
}
.ProviderPercentage .pt-category-box .box-wrapper {
  padding: 0 2px;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(1) .box {
  background-color: #3d93a6;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(2) .box {
  background-color: #e85d72;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(3) .box {
  background-color: #8d77ba;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(4) .box {
  background-color: #f2962d;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(5) .box {
  background-color: #ec95a2;
}
.ProviderPercentage .pt-category-box .box-wrapper:nth-child(6) .box {
  background-color: #f0e63a;
}
.ProviderPercentage .pt-category-box .box {
  width: 100%;
  height: 19px;
  border-radius: 2px;
  background-color: #3d93a6;
  display: inline-block;
}
.ProviderPercentage .pt-category-box .sciatica-box {
  width: 250px;
  background-color: #3d93a6;
}
.ProviderPercentage .remove-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
}
.ProviderPercentage .remove-link .close-icon IMG {
  width: 12px;
  height: 12px;
  margin-left: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner {
  display: flex;
  align-items: center;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .column9 {
  width: calc(100% - 190px);
  position: relative;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .column3 {
  width: 100px;
}
.ProviderPercentage .percentage-wrapper .percentage-label {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 14px;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderPercentage .percentage-wrapper .percentage-label .column9 {
  width: calc(100% - 190px);
  position: relative;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .AutoSuggestionSelect {
  margin-right: 10px;
}
.ProviderPercentage .AutoSuggestionSelect .Input-control.form-group input.form-control {
  padding-left: 25px;
}
.ProviderPercentage .percentage-wrapper .percentage-label .column3 {
  width: 100px;
  margin-left: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .Input-control.form-group {
  margin-bottom: 10px;
  margin-right: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .Input-control.form-group INPUT {
  padding-left: 25px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner .column9:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 10px;
  left: 0;
  width: 6px;
  background-color: #3d93a6;
  z-index: 1;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(1) .column9::before {
  background-color: #3d93a6;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(2) .column9::before {
  background-color: #e85d72;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(3) .column9::before {
  background-color: #8d77ba;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(4) .column9::before {
  background-color: #f2962d;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(5) .column9::before {
  background-color: #ec95a2;
}
.ProviderPercentage .percentage-wrapper .percentage-inner:nth-child(6) .column9::before {
  background-color: #f0e63a;
}
.ProviderPercentage .other-input {
  padding: 3px 15px 0px 25px;
  height: 52px;
  border-radius: 2px;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  display: flex;
  align-items: center;
  border: solid 1px #f1f1f1;
  background-color: #fbfcfc;
}
.ProviderPercentage .other-control SPAN:first-child {
  color: #013648;
}
.ProviderPercentage .other-control SPAN {
  font-size: 18px;
  line-height: 1.22;
  color: #72787d;
  white-space: nowrap;
}
.ProviderPercentage .percentage-inner.other .Input-control .form-control[disabled] {
  height: 52px;
  border-radius: 2px;
  border: solid 1px #f1f1f1;
  background-color: #fbfcfc;
}
.ProviderPercentage .other-info-icon {
  position: absolute;
  right: 53px;
  top: 17px;
}
.ProviderPercentage .percentage-inner.other .other-control.column9:before {
  bottom: 0;
}
.ProviderPercentage .other-control {
  margin-right: 10px;
}
.ProviderPercentage .percentage-wrapper .percentage-inner.other {
  position: relative;
  align-items: flex-start;
}
.ProviderPercentage .Input-control.error INPUT.form-control {
  border-color: #f37184;
}
.ProviderPercentage .titleheading {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .ProviderPercentage .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ProviderPercentage .percentage-wrapper .percentage-inner .column9 {
    width: calc(100% - 140px);
  }
  .ProviderPercentage .percentage-wrapper .percentage-label .column9 {
    width: calc(100% - 140px);
  }
  .ProviderPercentage .percentage-wrapper .percentage-label .column3 {
    width: 80px;
    min-width: 80px;
  }
  .ProviderPercentage .percentage-wrapper .percentage-inner .column3 {
    width: 80px;
    min-width: 80px;
  }
  .ProviderPercentage .AutoSuggestionSelect .Input-control.form-group input.form-control {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 16px;
  }
  .ProviderPercentage .remove-link .remove-text {
    display: none;
  }
  .ProviderPercentage .action-block .error-txt {
    margin-left: 12px;
  }
  .ProviderPercentage .other-input{
    flex-wrap: wrap;
    overflow: hidden;
  }
  .ProviderPercentage .other-input SPAN{
    font-size: 16px;
  }
  .ProviderPercentage .other-info-icon{
    right:15px;
  }
}
@media screen and (max-width: 375px) {
  .ProviderPercentage .action-block {
    flex-wrap: wrap;
  }
  .ProviderPercentage .action-block .error-txt {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
  }
}
