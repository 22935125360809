.ChatTab .chat-details {
  padding: 13px 23px 30px 24px;
  height: calc(100vh - 349px);
  overflow-y: auto;
}
.ChatTab .message-box {
  margin-bottom: 38px;
}
.ChatTab .message-box .time-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: -0.2px;
  color: #013648;
  display: flex;
}
.ChatTab .message-box .time-name .time {
  font-size: 16px;
  line-height: normal;
  font-family: 'Calibre Regular';
  font-weight: normal;
  letter-spacing: normal;
  color: #a2a2a2;
}
.ChatTab .btn-send-msg {
  background: transparent;
  outline: 0;
  border: 0;
  box-shadow: none;
  position: absolute;
  height: 20px;
  width: 20px;
  right: 0;
  top: 0;
  padding: 0px;
  cursor: pointer;
}
.ChatTab .btn-send-msg IMG {
  width: 100%;
  height: 100%;
}
.ChatTab .outgoing-msg .time-name .time {
  margin-left: 10px;
  order: 2;
}
.ChatTab .outgoing-msg .time-name .name {
  order: 1;
}
.ChatTab .incoming-msg {
  text-align: right;
}
.ChatTab .incoming-msg .file-box {
  margin-left: auto;
}
.ChatTab .incoming-msg .time-name {
  justify-content: flex-end;
}
.ChatTab .incoming-msg .time-name .time {
  margin-right: 10px;
  order: 1;
}
.ChatTab .incoming-msg .time-name .name {
  order: 2;
}
.ChatTab .message-box .msg {
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #013648;
}
.ChatTab .upcoming-appoinment {
  padding: 16px 34px 32px 24px;
  border-top: 1px solid #f1f1f5;
}
.ChatTab .appoinment-heading {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: -0.2px;
  color: #013648;
  padding-left: 31px;
  padding-right: 20px;
  position: relative;
}
.ChatTab .appoinment-heading SPAN {
  display: block;
}
.ChatTab .appoinment-heading .schedule-icon {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  top: 4px;
}
.ChatTab .appoinment-heading .down-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  height: 9px;
  top: 10px;
}
.ChatTab .appoinment-detail {
  padding-top: 15px;
  display: none;
}
.ChatTab .appoinment-detail.show-appoinment {
  display: block;
}
.ChatTab .appname-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
.ChatTab .appname-block .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 1.25;
  color: #013648;
  width: 100%;
  display: block;
}
.ChatTab .appname-block .patient-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #26acda;
}
.ChatTab .appname-block .appname-left {
  flex: 1;
}
.ChatTab .appname-block .appoinment-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #013648;
}
.ChatTab .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  display: inline-block;
}
.ChatTab .noroom-txt {
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: #a2a2a2;
}
.ChatTab .link-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ChatTab .file-box {
  width: 262px;
  max-width: 100%;
  padding: 14px 17px 10px;
  display: flex;
  border-radius: 4px;
  background-color: #fbfbf9;
}
.ChatTab .file-box .file-img {
  width: 47px;
  height: 57px;
}
.ChatTab .file-box .file-img IMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ChatTab .file-box .file-detail {
  padding-left: 15px;
  flex: 1;
  text-align: left;
}
.ChatTab .file-box .file-name {
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.16;
  letter-spacing: -0.2px;
  color: #282a2a;
}
.ChatTab .file-box .file-size {
  font-size: 16px;
  line-height: 1.06;
  letter-spacing: -0.2px;
  color: #282a2a;
}
.ChatTab .file-box .file-link {
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.18px;
  color: #26acda;
  display: inline-block;
  margin-top: 3px;
}
.ChatTab .typemsg-box {
  padding: 20px 23px 25px 24px;
  background-color: #fbfbf9;
}
.ChatTab .typemsg-box .typemsg-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 21px;
}
.ChatTab .typemsg-box .receiver-name {
  font-size: 16px;
  line-height: 2;
  letter-spacing: -0.18px;
  color: #013648;
}
.ChatTab .typemsg-box .msg-input {
  margin-bottom: 0;
  padding-right: 33px;
}
.ChatTab .typemsg-box .msg-input INPUT {
  border: 0;
  height: auto;
  padding: 0;
  border-radius: 0;
  background: transparent;
  font-size: 16px;
  letter-spacing: -0.18px;
}
.ChatTab .typemsg-box .msg-input INPUT.form-control::-webkit-input-placeholder {
  color: #72787d;
}
.ChatTab .typemsg-box .msg-input INPUT.form-control:-ms-input-placeholder {
  color: #72787d;
}
.ChatTab .typemsg-box .msg-input INPUT.form-control::placeholder {
  color: #72787d;
}
.ChatTab .typemsg-box .msg-input.form-group .form-control:focus {
  border: 0;
}
.ChatTab .typemsg-box .msg-input INPUT:not(:placeholder-shown) {
  border: 0;
}
.ChatTab .typemsg-box .fileupload-input {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: solid 1px #b2bcc4;
  background-color: #ffffff;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #72787d;
  width: 80px;
  height: 28px;
  padding-top: 5px;
}
.ChatTab .typemsg-box .fileupload-input IMG {
  width: 17px;
  height: 15px;
  margin-left: 6px;
  top: -3px;
  position: relative;
}
.ChatTab .typemsg-box .fileupload-input INPUT {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
.ChatTab .patient-schedule {
  border-top: 1px solid #f1f1f5;
  padding: 21px 30px 32px;
  margin-top: 17px;
}
.ChatTab .patient-schedule-inner {
  position: relative;
  padding-left: 35px;
}
.ChatTab .patient-schedule .date-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: #013648;
}
.ChatTab .patient-schedule .time-duration {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.17px;
  color: #013648;
}
.ChatTab .patient-schedule .schedule-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}
.ChatTab .patient-sm-schedule {
  display: none;
}
.ChatTab .participant-video {
  display: none;
}
.ChatTab .message-info {
  padding: 5px 5px;
  margin-bottom: 7px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  background-color: rgba(234, 244, 235, 0.3);
  color: #696969;
  font-size: 12px;
  text-align: center;
}
.ChatTab .msg-area {
  position: relative;
}
@media (hover: hover) {
  .ChatTab .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .ChatTab .appoinment-heading {
    font-size: 16px;
    padding-left: 28px;
  }
  .ChatTab .appoinment-heading .schedule-icon {
    top: 2px;
  }
  .ChatTab .appoinment-heading .down-arrow {
    top: 8px;
  }
  .ChatTab .noroom-txt {
    font-size: 16px;
  }
  .ChatTab .chat-details {
    padding: 15px 23px 15px 24px;
  }
  .ChatTab .upcoming-appoinment {
    padding: 10px 34px 20px 24px;
  }
  .ChatTab .file-box {
    padding: 14px 10px 10px;
  }
}

@media (max-width: 767px) {
  .ChatTab .typemsg-box .msg-input {
    margin-top: 6px;
  }
  .ChatTab .btn-send-msg {
    top: 4px;
  }
  .ChatTab .chat-details {
    padding: 18px 30px 15px;
  }
  .ChatTab .appoinment-heading {
    font-size: 18px;
    padding-left: 31px;
  }
  .ChatTab .message-box {
    margin-bottom: 65px;
  }
  .ChatTab .patient-schedule {
    margin-top: 0px;
    padding: 20px 25px 20px;
  }
  .ChatTab .appoinment-heading .schedule-icon {
    top: 4px;
  }
  .ChatTab .appoinment-heading .down-arrow {
    top: 10px;
  }
  .ChatTab .noroom-txt {
    font-size: 18px;
  }
  .ChatTab .upcoming-appoinment {
    padding: 13px 30px 20px;
    display: none;
  }
  .ChatTab .typemsg-box .receiver-name {
    display: none;
  }
  .ChatTab .message-box .time-name {
    font-size: 21.6px;
    letter-spacing: -0.24px;
  }
  .ChatTab .message-box .time-name .time {
    font-size: 19.2px;
  }
  .ChatTab .message-box .msg {
    font-size: 21.6px;
    letter-spacing: -0.24px;
  }
  .ChatTab .file-box {
    width: 314px;
    padding: 18px 20px 12px;
  }
  .ChatTab .upload-doc {
    display: none;
  }
  .ChatTab .msg-area {
    width: 100%;
  }
  .ChatTab .file-box .file-img {
    width: 56px;
    height: 68px;
  }
  .ChatTab .file-box .file-detail {
    padding-left: 18px;
  }
  .ChatTab .file-box .file-name {
    font-size: 19.2px;
    letter-spacing: -0.24px;
  }
  .ChatTab .file-box .file-size {
    font-size: 19.2px;
    letter-spacing: -0.24px;
  }
  .ChatTab .file-box .file-link {
    font-size: 19.2px;
    letter-spacing: -0.21px;
  }
  .ChatTab .typemsg-box {
    display: flex;
    padding: 31px 20px;
  }
  .ChatTab .typemsg-box .fileupload-input {
    height: 28px;
    width: 42px;
  }
  .ChatTab .typemsg-box .fileupload-input IMG {
    width: 19px;
    height: 16px;
    margin-left: 0px;
  }
  .ChatTab .typemsg-box .typemsg-top {
    order: 2;
    margin-left: 15px;
  }
  .ChatTab .sidebar-bottom .patient-schedule {
    display: none;
  }
  .ChatTab .patient-sm-schedule {
    display: block;
    background-color: rgba(234, 244, 235, 0.3);
  }
  .ChatTab .patient-sm-schedule .date-time {
    font-size: 20px;
    line-height: normal;
    letter-spacing: normal;
    color: #013648;
  }
  .ChatTab .patient-sm-schedule .time-duration {
    font-size: 20px;
    line-height: normal;
    letter-spacing: normal;
    color: #26acda;
  }
  .ChatTab .patient-sm-schedule .schedule-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .ChatTab .participant-video {
    width: 99px;
    height: 176px;
    border-radius: 4.8px;
    position: fixed;
    bottom: 189px;
    right: 24px;
    border: solid 0.5px #979797;
    background-color: #1d1b1b;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ChatTab .participant-video VIDEO {
    border-radius: 4.8px;
    width: 100%;
    height: 100%;
    background-color: #1d1b1b;
  }
}

@media (max-width: 413px) {
  .ChatTab .chat-details {
    padding: 20px 15px;
  }
  .ChatTab .upcoming-appoinment {
    padding: 13px 15px 20px;
  }
  .ChatTab .message-box {
    margin-bottom: 30px;
  }
  .ChatTab .patient-sm-schedule .date-time {
    display: block;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .ChatTab .typemsg-box {
    padding: 10px 23px 10px 24px;
  }
  .ChatTab .typemsg-box .typemsg-top {
    margin-bottom: 8px;
  }
  .ChatTab .chat-details {
    padding: 12px 23px 12px 24px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .ChatTab .chat-details {
    padding: 12px 30px;
  }
  .ChatTab .typemsg-box {
    padding: 10px 20px;
  }
  .ChatTab .participant-video {
    bottom: 100px;
  }
}
