.PatientDashboard .filter .Input-control .view-img {
  left: 18px;
  height: 17px;
  top: 26px;
}
.PatientDashboard .filter .Input-control.zipcode .view-img {
  left: 13px;
}
.PatientDashboard .filter .Input-control INPUT {
  font-family: 'Calibre Regular';
}
.PatientDashboard .filter .Input-control.zipcode INPUT {
  padding-top: 8px;
}
.PatientDashboard .filter .Input-control.transform-input.zipcode .form-control:focus,
.PatientDashboard
  .filter
  .Input-control.transform-input.zipcode
  .form-control:not(:placeholder-shown),
.PatientDashboard .filter .Input-control.transform-input.filled-focus-input.zipcode .form-control {
  padding-top: 8px;
}
.PatientDashboard .filter .ElasticSearch {
  min-width: 426px;
  margin-right: 4px;
}
.PatientDashboard .filter .ElasticSearch .Input-control.form-group INPUT:focus {
  border: solid 1px #013648;
}
.PatientDashboard .filter {
  background-color: #013648;
  display: flex;
  padding: 5px 60px 13px;
}
.PatientDashboard .filter .Input-control INPUT {
  padding-left: 53px;
  border: solid 1px rgba(1, 54, 72, 0.1);
  border-radius: 4px;
}
.PatientDashboard .filter .Input-control .label {
  display: none;
}
.PatientDashboard .filter .Input-control {
  margin: 0 2px;
  flex: 1;
}
.PatientDashboard .filter .ElasticSearch .Input-control {
  margin-left: 0;
}
.PatientDashboard .filter .ElasticSearch .Input-control.form-group INPUT {
  height: 52px;
}
.PatientDashboard .filter .Input-control.transform-input .form-control:focus,
.PatientDashboard .filter .Input-control.transform-input .form-control:not(:placeholder-shown),
.PatientDashboard .filter .Input-control.transform-input.filled-focus-input .form-control {
  padding: 5px 0px 5px 53px;
}
.PatientDashboard .filter .SelectDate {
  margin-right: 4px;
  flex: 1;
  border: 0;
}
.PatientDashboard .filter .SelectDate .form-control {
  border: solid 1px rgba(1, 54, 72, 0.1);
}
.PatientDashboard .search-result {
  padding: 21px 60px 12px;
  display: flex;
  align-items: center;
}
.PatientDashboard .search-result .search-text {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #013648;
  width: 60%;
  padding-right: 15px;
}
.PatientDashboard .search-result .search-text .search-item {
  color: #b2bcc4;
}
.PatientDashboard .search-result .search-text .search-item > SPAN {
  padding-right: 5px;
}
.PatientDashboard .search-result .search-text .search-item > SPAN:not(:first-child) {
  position: relative;
  display: inline-block;
  padding-left: 5px;
}
.PatientDashboard .search-result .search-text .search-item > SPAN:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 2px;
  border-radius: 50%;
  left: 5px;
  top: 11px;
  background-color: #b2bcc4;
}
.PatientDashboard .search-action .CircleButton {
  height: 30px;
  border-radius: 15.1px;
  border: solid 1px #013648;
  line-height: 32px;
  display: inline-block;
  width: auto;
  padding: 0px 18px;
  font-size: 16px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
  margin-right: 11px;
}
.PatientDashboard .patien-dashboard-wrapper {
  display: flex;
  background-color: #fbfbf9;
  padding-top: 41px;
  padding-bottom: 40px;
  min-height: calc(100vh - 211px);
}
.PatientDashboard .patien-dashboard-wrapper .left-panle {
  width: 60%;
  padding-left: 62px;
  padding-right: 102px;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.07;
  color: #013648;
  display: flex;
  gap: 5px;
}
.capitalize {
  text-transform: capitalize;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title .app-list-detail {
  position: relative;
  padding-left: 39px;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .calendar-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -4px;
  left: 0;
  cursor: pointer;
}
.PatientDashboard
  .patien-dashboard-wrapper
  .left-panle
  .appointment-list
  .title
  .app-list-detail
  .app-calender {
  width: 100%;
  height: 100%;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 1.45;
  color: #013648;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-bottom: 12px;
}
.PatientDashboard .appointment-list .title .past-appointment {
  cursor: pointer;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: -0.34px;
  color: #26acda;
}
.PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list {
  margin-top: 53px;
  margin-bottom: 80px;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle .title {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 1.21;
  letter-spacing: -0.3px;
  color: #013648;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle {
  padding: 20px 64px 0 50px;
  border-left: 1px solid rgba(32, 58, 112, 0.08);
  width: 40%;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle .title .feed-count {
  width: 28px;
  height: 22px;
  border-radius: 12px;
  background-color: #e85d72;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 27px;
  display: inline-block;
  position: relative;
  top: -3px;
  margin-left: 12px;
}
.PatientDashboard .right-panle .title .clear-complete {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #72787d;
  cursor: pointer;
}
.PatientDashboard .right-panle .title .clear-complete.inactive {
  color: #dcdcdc;
  cursor: not-allowed;
}
.PatientDashboard .patien-dashboard-wrapper .right-panle .feed-list {
  padding-top: 28px;
}
.PatientDashboard .CustomFooter {
  display: none;
}
.PatientDashboard .no-appointment {
  text-align: center;
  height: 500px;
  display: flex;
}
.PatientDashboard .no-appointment-content {
  margin: auto;
}
.PatientDashboard .no-appointment-content .no-appointment-img IMG {
  width: 74px;
  height: 71px;
  object-fit: contain;
}
.PatientDashboard .no-appointment-content .no-appointment-text {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #72787d;
  padding: 30px 0px 42px;
}
.PatientDashboard .no-appointment-content .no-appointment-text SPAN {
  display: inline-block;
  width: 100%;
}
.PatientDashboard .no-appointment-content .finddoctor SPAN {
  font-size: 20px;
  line-height: 1.13;
  letter-spacing: -0.38px;
  text-align: center;
  color: #26acda;
  display: inline-block;
  cursor: pointer;
}
.PatientDashboard .no-appointment-content .finddoctor SPAN IMG {
  position: relative;
  top: 3px;
  margin-right: 5px;
  height: 18px;
  width: 18px;
}
.PatientDashboard .search-btn {
  width: 93px;
  height: 50px;
  border-radius: 4px;
  border: 1px solid #3d93a6;
  cursor: pointer;
  margin-left: 6px;
  vertical-align: top;
  margin-top: 1px;
  background-color: #3d93a6;
}
.PatientDashboard .search-btn .mghover-icon {
  display: none;
}
.PatientDashboard .search-btn IMG:last-child {
  display: none;
}
.PatientDashboard .search-btn:focus {
  outline: 0;
}

/*Sticky Header*/
.PatientDashboard.sticky-header {
  padding-top: 82px;
}
.PatientDashboard.sticky-header .filter {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
.PatientDashboard .filter .logo {
  width: 160px;
  margin-right: 30px;
  padding-top: 3px;
  display: none;
}
.PatientDashboard .filter .logo IMG {
  width: 100%;
}
.PatientDashboard.sticky-header .filter .logo {
  display: block;
}
.PatientDashboard.sticky-header .filter .search-btn {
  width: 56px;
}
.PatientDashboard.sticky-header .filter .ElasticSearch {
  min-width: 380px;
}
.PatientDashboard.sticky-header .filter {
  padding: 30px 60px 19px;
}
.PatientDashboard .filter .CircleButton.WhiteButton {
  display: none;
}
.PatientDashboard .search-warp {
  display: none;
}
.PatientDashboard .search-btn SPAN {
  display: none;
}
.PatientDashboard .filter .Input-control.form-group INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control.form-group INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control.form-group INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control.form-group INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .filter .Input-control INPUT:-moz-placeholder {
  /* Firefox 18- */
  font-size: 18px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
  letter-spacing: -0.38px;
  opacity: 1;
}
.PatientDashboard .search-btn[disabled] {
  opacity: 0.5;
  color: #d8d8d8;
  cursor: not-allowed;
}

@media (hover: hover) {
  .PatientDashboard .search-action .CircleButton:hover {
    color: #fff;
  }
  .PatientDashboard .filter.show-filter .CircleButton.WhiteButton:hover {
    border: 1px solid #72787d;
  }
  .PatientDashboard .search-btn:enabled:hover SPAN {
    color: #013648;
  }
  .PatientDashboard .search-btn:enabled:hover .mg-icon {
    display: none;
  }
  .PatientDashboard .no-appointment-content .finddoctor SPAN:hover {
    opacity: 0.7;
  }
  .PatientDashboard .search-btn:enabled:hover IMG {
    display: none;
  }
  .PatientDashboard .search-btn:enabled:hover .mghover-icon {
    display: inline-block;
  }
  .PatientDashboard .search-btn:enabled:hover {
    background-color: rgba(61, 147, 166, 0.8);
  }
}

@media (max-width: 1366px) {
  .PatientDashboard.sticky-header .filter .ElasticSearch {
    min-width: 280px;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: 322px;
  }
}
@media (max-width: 1200px) {
  .PatientDashboard .filter {
    padding: 15px 30px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 30px;
    padding-right: 30px;
  }
  .PatientDashboard .filter .Input-control INPUT,
  .PatientDashboard .filter .Input-control.transform-input.filled-focus-input .form-control {
    padding-left: 42px;
    padding-right: 15px;
  }
  .PatientDashboard .search-btn {
    width: 48px;
  }
  .PatientDashboard .filter .Input-control .view-img {
    left: 12px;
  }
  .PatientDashboard.sticky-header .filter .logo {
    display: flex;
    width: 120px;
    margin-right: 0;
  }
  .PatientDashboard.sticky-header .filter .logo .view-img {
    margin: auto;
  }
  .PatientDashboard.sticky-header .filter .search-btn {
    width: 44px;
  }
  .PatientDashboard.sticky-header .filter .ElasticSearch {
    min-width: 233px;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: 300px;
  }
}

@media (max-width: 1024px) {
  .PatientDashboard .filter {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
  }
  .PatientDashboard .patien-dashboard-wrapper {
    flex-wrap: wrap;
  }
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    margin-top: 40px;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: initial;
    width: 49%;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control,
  .PatientDashboard .filter .SelectDate .Input-control,
  .PatientDashboard .filter .InsuranceFilterSelect .Input-control {
    width: 100%;
    flex: inherit;
    margin-bottom: 10px;
  }
  .PatientDashboard .filter .chooseinsurance {
    width: calc(49% - 75px);
  }
  .PatientDashboard .filter .Input-control,
  .PatientDashboard .filter .SelectDate {
    width: 49%;
    flex: inherit;
    margin: 0;
  }
  .PatientDashboard .search-btn {
    margin: auto;
    margin: inherit;
    margin-left: auto;
  }
  .PatientDashboard .filter .SelectDate .Input-control {
    width: 100%;
  }
  .PatientDashboard .filter .f-search {
    min-width: auto;
  }
  .PatientDashboard .filter .ElasticSearch {
    min-width: initial;
    width: 49%;
    margin: 0;
  }
  .PatientDashboard .filter .SelectDate {
    margin-right: auto;
  }
  .PatientDashboard .filter .chooseinsurance .Input-control {
    width: 100%;
  }
  .PatientDashboard.sticky-header .filter .logo {
    position: absolute;
    left: 16px;
    top: 20px;
  }
  .PatientDashboard.sticky-header .filter {
    padding-left: 150px;
  }
  .PatientDashboard.sticky-header {
    padding-top: 154px;
  }
  .PatientDashboard.sticky-header .filter .ElasticSearch {
    min-width: auto;
  }
  .PatientDashboard.sticky-header .filter .search-btn {
    width: 62px;
  }
  .PatientDashboard .search-btn {
    width: 58px;
  }
  .PatientDashboard .filter .InsuranceFilterSelect .Input-control.form-group INPUT.form-control {
    padding-left: 42px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list {
    margin-bottom: 30px;
  }
  /* .PatientDashboard .reviewed-notification-feed-list .InfiniteScroll{
    height: auto !important;
  } */
}

@media (max-width: 767px) {
  .PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
    font-size: 32px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list {
    margin-top: 36px;
    margin-bottom: 30px;
  }
  .PatientDashboard
    .patien-dashboard-wrapper
    .left-panle
    .appointment-list
    .title
    .app-list-detail {
    margin-bottom: 9px;
    width: 100%;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 30px;
    padding-right: 30px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title {
    flex-wrap: wrap;
  }

  .PatientDashboard.sticky-header .filter {
    position: static;
    padding-left: 30px;
  }
  .PatientDashboard.sticky-header .filter .logo {
    display: none;
  }
  .PatientDashboard .filter .ElasticSearch {
    width: 100%;
  }
  .PatientDashboard .filter .Input-control,
  .PatientDashboard .filter .SelectDate {
    width: 100%;
  }
  .PatientDashboard .patien-dashboard-wrapper {
    padding-top: 27px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
    font-size: 48px;
    line-height: 0.96;
    letter-spacing: normal;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title {
    letter-spacing: normal;
    line-height: 1.1;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }
  .PatientDashboard
    .patien-dashboard-wrapper
    .left-panle
    .appointment-list
    .title
    .past-appointment {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: normal;
    color: #26acda;
    padding-left: 40px;
  }
  .PatientDashboard .patien-dashboard-wrapper .left-panle .appointment-list .title SPAN {
    display: block;
  }
  .PatientDashboard .search-btn {
    width: 100%;
    border-radius: 24px;
  }
  .PatientDashboard.sticky-header {
    padding-top: 0;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control {
    margin-bottom: 10px;
  }
  .PatientDashboard .filter .Input-control,
  .PatientDashboard .filter .SelectDate .Input-control {
    margin-bottom: 10px;
  }
  .PatientDashboard.sticky-header .filter .search-btn {
    width: 100%;
  }
  .PatientDashboard .doctor-list-wrapper .doctor-list-content {
    width: 100%;
  }
  .PatientDashboard .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 100%;
  }
  .PatientDashboard .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 350px;
  }
  .PatientDashboard .MyDatetime.days .rdtPicker .rdtSwitch:after {
    width: 93px;
  }
  .PatientDashboard .MyDatetime .rdtPicker .rdtDays table {
    border-spacing: 25px 10px;
  }
  .PatientDashboard .search-result .search-text {
    display: none;
  }
  .PatientDashboard .filter .Input-control .view-img {
    top: 25px;
    left: 16px;
    height: 22px;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control .view-img {
    top: 28px;
  }
  .PatientDashboard.sticky-header .filter {
    padding: 11px 30px 19px;
  }
  .PatientDashboard .filter .InsuranceFilterSelect {
    width: 100%;
  }
  .PatientDashboard .PatientHeader .header-content .logo A IMG {
    width: 128px;
    cursor: pointer;
  }
  .PatientDashboard .PatientHeader .container {
    padding: 0 30px;
  }
  .PatientDashboard .filter {
    height: 83px;
    overflow: hidden;
    padding: 11px 30px 11px;
    position: relative;
  }
  .PatientDashboard .filter .zipcode {
    visibility: hidden;
  }
  .PatientDashboard .filter.show-filter {
    height: 100%;
    overflow: visible;
  }
  .PatientDashboard .filter.show-filter .ElasticSearch {
    width: 100%;
  }
  .PatientDashboard .filter.show-filter .CircleButton.WhiteButton {
    width: 100%;
    display: block;
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 18px;
    line-height: 1.12;
    letter-spacing: -0.34px;
    text-align: center;
    color: #ffffff;
    padding-top: 5px;
  }
  .PatientDashboard .search-result {
    padding: 20px 0px 22px;
    margin: 0px 30px;
    border-bottom: 1px solid #e8eceb;
  }
  .PatientDashboard .search-btn {
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 5px;
    line-height: 50px;
    background-color: #3d93a6;
  }
  .PatientDashboard .search-btn SPAN {
    font-family: 'Calibre Medium';
    font-size: 18px;
    display: inline-block;
    text-align: center;
    display: block;
    color: #ffffff;
  }
  .PatientDashboard .search-btn:enabled:hover SPAN {
    color: #ffffff;
  }
  .PatientDashboard .search-btn IMG,
  .PatientDashboard .search-btn IMG:hover,
  .PatientDashboard .search-btn:enabled:hover .mghover-icon {
    display: none;
  }
  .PatientDashboard .search-warp {
    position: absolute;
    width: 100%;
    height: 56px;
    top: 11px;
    left: 0;
  }
  .PatientDashboard .search-warp {
    display: block;
    z-index: 3;
  }
  .PatientDashboard .filter .ElasticSearch .Input-control.form-group INPUT {
    height: 57px;
  }
  .PatientDashboard .filter.show-filter .zipcode {
    visibility: visible;
  }
  .PatientDashboard .filter.show-filter .search-warp {
    display: none;
  }
  .PatientDashboard .filter .Input-control.transform-input .form-control:focus,
  .PatientDashboard .filter .Input-control.transform-input.filled-focus-input .form-control {
    padding-top: 2px;
  }
  .PatientDashboard .rating-content-show .dc-review {
    position: static;
    display: flex;
    margin-left: auto;
  }
  .PatientDashboard .rating-content-show .reviwe-content .rating-text {
    top: 0;
  }
  .PatientDashboard .rating-content-show .DoctorItem .dc-review .reviwe-content .star {
    top: 22px;
  }
  .PatientDashboard .rating-content-show .DoctorItem .dc-review .reviwe-content .rating {
    top: 2px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT {
    font-size: 18px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
    line-height: 57px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control.form-group INPUT:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT:-ms-input-placeholder {
    /* IE 10+ */
    font-size: 20px;
  }
  .PatientDashboard .filter .Input-control INPUT:-moz-placeholder {
    /* Firefox 18- */
    font-size: 20px;
  }
}
@media (max-width: 413px) {
  .PatientDashboard .patien-dashboard-wrapper .left-panle,
  .PatientDashboard .patien-dashboard-wrapper .right-panle {
    padding-left: 15px;
    padding-right: 15px;
  }
  .PatientDashboard .filter {
    padding: 11px 15px 11px;
  }
  .PatientDashboard .PatientHeader .container {
    padding: 0px 15px;
  }
  .PatientDashboard .search-result {
    margin: 0px 15px;
  }
  .PatientDashboard .doctor-list-wrapper .doctor-list-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .DoctorItem .table-content {
    margin-right: -15px;
  }
  .PatientDashboard .SelectDate .datepicker .MyDatetime .rdtPicker {
    width: 289px;
  }
  .PatientDashboard .MyDatetime.days .rdtPicker .rdtSwitch:after {
    width: 54px;
  }
  .PatientDashboard .MyDatetime .rdtPicker .rdtDays TABLE {
    border-spacing: 10px 10px;
  }
  .PatientDashboard.sticky-header .filter {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 375px) {
  .PatientDashboard .patien-dashboard-wrapper .left-panle .user-name {
    font-size: 44px;
  }
}
@supports (-webkit-touch-callout: none) {
  .PatientDashboard .search-btn SPAN {
    display: inline-block;
    padding-top: 2px;
  }
  .PatientDashboard .filter .Input-control INPUT.form-control::-webkit-input-placeholder {
    opacity: 1;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.34px;
    font-family: 'Calibre Regular';
  }
  .PatientDashboard .filter .SelectDate .form-control {
    padding-top: 2px;
  }
  .PatientDashboard .filter .Input-control .view-img {
    top: 26px;
  }
}
