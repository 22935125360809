.DoctorList .doctor-item IMG {
  width: 27px;
  height: 41px;
  border-radius: 2.5px;
  border: solid 0.6px rgba(1, 54, 72, 0.2);
  margin-right: 9px;
  object-fit: cover;
}
.DoctorList .doctor-item .doctor-info .doctor-name {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 20px;
}
.DoctorList .doctor-item .doctor-info > SPAN {
  width: 100%;
  display: inline-block;
}
.DoctorList .doctor-item {
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 15px;
}
.DoctorList .viewprofile.provider-list {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.35;
  color: #013648;
  position: absolute;
  bottom: 0px;
  left: 45px;
}

.DoctorList .doctor-detail {
  display: inline-flex;
  padding: 8px 9px 8px 9px;
  width: 100%;
  cursor: pointer;
}
.DoctorList .doctor-item.active {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fafafb;
}
.DoctorList .doctor-item.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 4px;
  background-color: #013648;
}
.DoctorList .search-input .view-img {
  width: 16px;
  height: 16px;
  left: 11px;
  right: auto;
  top: 45%;
}
.DoctorList .search-input INPUT.form-control {
  padding-left: 37px;
  height: 38px;
  border-radius: 10px;
  background-color: #fafafb;
  border: 0;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
}
.DoctorList .search-input INPUT.form-control::-webkit-input-placeholder {
  /* Edge */
  opacity: 0.5;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.DoctorList .search-input INPUT.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.5;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.DoctorList .search-input INPUT.form-control::placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 2.25;
  color: #013648;
}
.DoctorList .search-input.Input-control.form-group INPUT.form-control:focus,
.DoctorList .search-input.Input-control.form-group .form-control:not(:placeholder-shown) {
  padding-left: 37px;
  border: 0;
}
.DoctorList .h3 {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 2px;
  color: #72787d;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 18px;
  cursor: pointer;
  margin-bottom: 15px;
  padding-right: 15px;
}
.DoctorList .toggle-icon IMG {
  position: absolute;
  right: 0;
  top: 2px;
  transform: rotate(0deg);
  transition: 0.3s all linear;
}
.DoctorList .search-input {
  margin-bottom: 20px;
}
.DoctorList.open-accodian .toggle-icon .h3 IMG {
  transform: rotate(180deg);
}
.DoctorList {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.DoctorList.open-accodian {
  height: auto;
}
.DoctorList .h3 .toggle-icon {
  position: absolute;
  right: 0;
  top: 2px;
  transform: rotate(180deg);
  transition: 0.3s all linear;
  height: 14px;
  width: 14px;
}
.DoctorList.open-accodian .h3 .toggle-icon {
  transform: rotate(0deg);
}
@media (hover: hover) {
  .DoctorList .doctor-detail:hover {
    position: relative;
    background-color: rgba(1, 54, 72, 0.1);
    border-radius: 4px;
    overflow: hidden;
  }
  .DoctorList .viewprofile.provider-list:hover {
    font-weight: bold;
  }
}
