.AmazingDoctor {
  text-align: center;
  padding-top: 93px;
  padding-bottom: 106px;
}
.AmazingDoctor .title {
  width: 585px;
  max-width: 100%;
  font-family: 'Heldane Display';
  font-size: 64px;
  line-height: 1.03;
  text-align: center;
  color: #013648;
  margin: auto;
  margin-bottom: 29px;
}
.AmazingDoctor .AmazingDoctor-content .description {
  width: 544px;
  max-width: 100%;
  font-size: 20px;
  line-height: 1.5;
  text-align: center;
  color: #013648;
  margin: auto;
  margin-bottom: 60px;
}
.AmazingDoctor .AmazingDoctor-content .CircleButton.WhiteButton {
  width: 288px;
  height: 54px;
  line-height: 54px;
  font-family: 'Calibre Regular';
}

@media (max-width: 1024px) {
  .AmazingDoctor {
    padding-top: 80px;
    padding-bottom: 50px;
  }
  .AmazingDoctor .title {
    font-size: 48px;
  }
}

@media (max-width: 767px) {
  .AmazingDoctor .title {
    margin-bottom: 30px;
  }
  .AmazingDoctor .AmazingDoctor-content .CircleButton.WhiteButton {
    width: 241px;
    height: 48px;
    line-height: 48px;
  }
  .AmazingDoctor .AmazingDoctor-content .description {
    margin-bottom: 30px;
  }
  .AmazingDoctor {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .AmazingDoctor .title {
    font-size:39px;
  }
}

@media (max-width: 413px) {
  .AmazingDoctor .title {
    font-size: 29px;
  }
  .AmazingDoctor .AmazingDoctor-content .description {
    font-size: 18px;
  }
}

@supports (-webkit-touch-callout: none) {
  .AmazingDoctor .AmazingDoctor-content .CircleButton.WhiteButton {
    padding-top: 4px;
  }
}
