.PatientNotification {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 39px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientNotification .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 38px;
}
.PatientNotification .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 25px;
}
.PatientNotification .notification-block {
  margin-bottom: 46px;
}
.PatientNotification .notification-block:last-child {
  margin-bottom: 175px;
}
.PatientNotification .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Regular';
}

@media screen and (max-width: 1200px) {
  .PatientNotification {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientNotification .notification-block:last-child {
    margin-bottom: 75px;
  }
  .PatientNotification .h3 {
    font-size: 20px;
  }
}
