.RecipientSourceList {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.RecipientSourceList .name-cell {
  min-width: 30%;
}
.RecipientSourceList .tempalte-cell {
  min-width: 15%;
  text-align: left;
  padding-left: 10px;
}
.RecipientSourceList .type-cell {
  min-width: 20%;
  padding-left: 10px;
}
.RecipientSourceList .usage-cell {
  min-width: 15%;
  padding-left: 10px;
}
.RecipientSourceList .action-cell {
  min-width: 20%;
  padding-left: 10px;
}
.RecipientSourceList .rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.RecipientSourceList .rtable-row {
  width: 100%;
  display: flex;
}
.RecipientSourceList .rtable-row .rtable-cell.content-cell.name-cell {
  color: #26acda;
}
.RecipientSourceList .rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.RecipientSourceList .rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.RecipientSourceList .rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.RecipientSourceList .rtable-cell--content .webinar-date {
  font-weight: 700;
}
.RecipientSourceList .rtable-row.rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.RecipientSourceList .rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.RecipientSourceList .rtable-row {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.RecipientSourceList .rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.RecipientSourceList .rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.RecipientSourceList .action-menu {
  margin-left: auto;
  position: relative;
}
.RecipientSourceList .action-menu .action-btn {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}
.RecipientSourceList .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 3px;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.RecipientSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.RecipientSourceList .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.RecipientSourceList .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.RecipientSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.RecipientSourceList .send-btn {
  max-width: 90px;
  height: 40px;
  padding: 14px 18px 10px 13px;
  border-radius: 2px;
  background-color: #013648;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  margin-left: 40px;
  font-family: 'Calibre Regular';
  cursor: pointer;
}
.RecipientSourceList .send-btn IMG {
  margin-right: 3px;
}
.RecipientSourceList .send-btn:focus {
  outline: 0;
}
@media (max-width: 991px) {
  .RecipientSourceList .usage-cell {
    min-width: 20%;
  }
}
@media (max-width: 767px) {
  .RecipientSourceList .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}
