.TagsInput {
  margin-bottom: 18px;
}
.TagsInput .form-label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: -0.2px;
  color: #013648;
}
.TagsInput .form-col {
  position: relative;
}
.TagsInput .multiselectsearch-block {
  position: relative;
  display: grid;
  flex-wrap: wrap;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 2px;
  padding: 25px 16px;
  margin-bottom: 21px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  align-content: flex-start;
}
.TagsInput .multiselectsearch-block .multiselect-result {
  display: flex;
  flex-wrap: wrap;
}
.TagsInput .multiselectsearch-block .form-input {
  width: auto;
  height: 32px;
  border: 0;
  background: transparent;
  border-radius: 0;
  padding: 5px;
  margin: 0;
}
.TagsInput .multiselectsearch-block .form-input:focus {
  border: 0;
}
.TagsInput .multiselectsearch-block .multiselect-result div {
  background-color: rgba(1, 54, 72, 0.1);
  padding: 1px 12px 1px 14px;
  margin-right: 17px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}
.TagsInput .multiselectsearch-block .multiselect-result div img {
  margin-left: 8px;
}
.TagsInput .instruction-block {
  font-size: 14px;
  line-height: 18px;
  color: #979797;
  max-width: 330px;
  width: 100%;
  position: relative;
  padding-left: 35px;
}
.TagsInput .only-Toggle{
display: contents !important;
}
.TagsInput .only-Toggle .SwitchToggle {
  display: contents !important;
}
.TagsInput .only-Toggle .SwitchToggle .switchContainer{
margin: 0;
margin-top: 3px;
background: transparent;
}
.TagsInput .instruction-block img {
  position: absolute;
  left: 0;
  top: 5px;
}
.TagsInput .instruction-block a {
  color: #979797;
  text-decoration: underline;
}
.TagsInput .form-input.custom-input-box {
  width: 100%;
  border: 1px solid #99999938;
  border-radius: 2px
}
.TagsInput .outer-suggetion-box {
  display: flex;
  flex: 1 0 auto;
  position: relative;
  
}
.TagsInput .autocomplete-box {
  background: #fff;
  padding: 7px 0;
  position: absolute;
  top: 35px;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 300px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* max-width: 150px; */
  width: 100%;
}
.TagsInput .autocomplete-box div {
  padding: 7px 10px;
  cursor: pointer;
}
.TagsInput .multiselectsearch-block .multiselect-result div {
  display: inline-block;
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  padding-top: 3px;
}
.TagsInput .multiselectsearch-block .multiselect-result div img {
  cursor: pointer;
  width: 13px;
  height: 13px;
  position: relative;
  top: 3px;
}
.TagsInput .multiselectsearch-disabled {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 4px 4px 0px 0px;
  border: 0;
  padding: 12px 13px;
  margin-bottom: 16px;
}
.TagsInput .multiselect search-disabled .multiselect-result {
  display: flex;
  flex-wrap: wrap;
}
.TagsInput .multiselectsearch-disabled .multiselect-result div {
  background: rgba(0, 0, 0, 0.1);
  color: #484848;
  padding: 1px 12px 1px 14px;
  margin-right: 17px;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.34px;
}
.TagsInput .input-detail {
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  text-align: right;
  color: #484848;
  position: absolute;
  right: 0;
  bottom: -30px;
}
.TagsInput .error-text {
  position: absolute;
  left: 0;
  bottom: -20px;
  color: red;
  font-size: 13px;
}
.TagsInput .multiselectsearch-block .multiselect-result .rejected-approval {
  background-color: rgba(232, 93, 114, 0.1);
  color: #e85d72;
}
.TagsInput .multiselectsearch-block .multiselect-result .pending-approval {
  background-color: rgba(242, 150, 45, 0.1);
  color: #f2962d;
}
.TagsInput .required-info {
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #f37184;
}
.TagsInput .SwitchToggle .switchContainer input:checked + .slider{
  background-color: #35b255;
}
@media (hover: hover) {
  .TagsInput .instruction-block a:hover {
    color: #174c99;
  }
  .TagsInput .autocomplete-box div:hover {
    background: #e8e8e8;
  }
}

@media (max-width: 767px) {
  .TagsInput .multiselectsearch-block {
    padding: 16px 17px;
    padding-right: 0;
  }
  .TagsInput .multiselectsearch-block .multiselect-result div {
    font-size: 16px;
  }
  .TagsInput .multiselectsearch-block .multiselect-result div img {
    width: 10px;
  }
}
