.ReserveAppointment {
  padding: 32px 0 0 66px;
}
.ReserveAppointment .h3 {
  width: 100%;
  height: 29px;
  font-size: 24px;
  line-height: 1.21;
  color: #013648;
}
.ReserveAppointment .h3 span {
  font-family: 'Calibre Medium';
}
.ReserveAppointment .already-login-text {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #013648;
  margin-top: 23px;
}
.ReserveAppointment .already-login-text A {
  font-family: 'Calibre Medium';
  color: #26acda;
}
.ReserveAppointment .ca-social .social-icon {
  padding-top: 5px;
  font-size: 18px;
  display: inline-block;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  border: solid 1px #013648;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  color: #000;
  cursor: pointer;
  letter-spacing: -0.34px;
}
.ReserveAppointment .ca-social {
  margin: 15px 0 20px;
}
.ReserveAppointment .ca-social .social-icon IMG {
  margin-right: 11px;
  position: relative;
  top: -2px;
}
.ReserveAppointment .divider-content {
  position: relative;
  margin-bottom: 21px;
  width: 399px;
}
.ReserveAppointment .divider-content .Divider .container {
  width: 100%;
}
.ReserveAppointment .divider-content > SPAN {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.5);
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fff;
  padding: 9px;
  display: inline-block;
}
.ReserveAppointment FORM {
  width: 398px;
}
.ReserveAppointment .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.ReserveAppointment .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
  width: 100%;
}
.ReserveAppointment .Select-control .arrow {
  top: 22px;
  right: 20px;
}
.ReserveAppointment .Input-control .form-control {
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #18222a;
}
.ReserveAppointment .checkbox-block.privacy {
  margin-top: 32px;
}
.ReserveAppointment .checkbox-block {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #013648;
  padding-left: 30px;
  position: relative;
  margin-bottom: 18px;
}
.ReserveAppointment .checkbox-block A {
  color: #3dbce7;
}
.ReserveAppointment .checkbox-block .Checkbox-control {
  position: absolute;
  left: 0px;
}
.ReserveAppointment .action-btn .CircleButton.WhiteButton {
  width: 100%;
  font-family: 'Calibre Regular';
  height: 45px;
  line-height: 45px;
}
.ReserveAppointment .action-btn {
  margin-top: 35px;
}
.ReserveAppointment .appoitment-smtime {
  display: none;
}
.ReserveAppointment .appoitment-smtime .title {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  display: block;
}
.ReserveAppointment .appoitment-smtime .time-detail {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: block;
}
.ReserveAppointment .appoitment-smtime .view-details {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #3dbce7;
  font-family: 'Calibre Medium';
  cursor: pointer;
}
.ReserveAppointment .DateManual {
  margin-top: 20px;
}
.ReserveAppointment .Input-control.form-group,
.ReserveAppointment .DateManual .Input-control {
  margin-bottom: 20px;
}
.ReserveAppointment .DateManual .Input-control .form-control::placeholder {
  font-size: 15px;
  font-weight: normal;
}

.reserveappointmentnew .Input-control .form-control {
  height: 26px;
  font-size: 16px;
}
.reserveappointmentnew .Input-control.transform-input .label {
  top: 3px;
  font-size: 16px;
  /* display: none; */
}
.reserveappointmentnew .Input-control.transform-input.filled-focus-input .label {
  display: none;
}
.reserveappointmentnew .Input-control .form-control:placeholder-shown {
  color: rgba(1, 54, 72, 0.5);
  font-size: 16px;
}
.reserveappointmentnew .Input-control.transform-input.filled-focus-input .form-control {
  top: 3px;
  font-size: 16px;
  padding: 5px 25px 5px;
}
.reserveappointmentnew .CircleButton.WhiteButton {
  background-color: #00a806;
}
.reserveappointmentnew .action-btn {
  margin-top: 20px;
}
.reserveappointmentnew .checkbox-block.privacy,
.reserveappointmentnew .already-login-text {
  margin-top: 5px;
}
.reserveappointmentnew .DateManual {
  margin-top: 0;
}
.reserveappointmentnew .Select-control.form-group SELECT {
  height: 28px;
  font-size: 16px;
}
.reserveappointmentnew .Select-control .arrow {
  top: 10px;
}
.AppointmentRequest .appointment-request-detail .h3 {
  margin-bottom: 20px;
  font-size: 20px;
}
@media (max-width: 1400px) {
  .ReserveAppointment .divider-content,
  .ReserveAppointment FORM,
  .ReserveAppointment .ca-social .social-icon {
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .ReserveAppointment {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 767px) {
  .ReserveAppointment {
    padding-top: 32px;
  }
  .ReserveAppointment .already-login-text {
    margin-top: 18px;
  }
  .ReserveAppointment .ca-social {
    margin: 18px 0 24px;
  }
  .ReserveAppointment .ca-social .social-icon IMG {
    margin-right: 17px;
  }
  .ReserveAppointment .form-group-2-colum {
    margin: 0 -7px;
  }
  .ReserveAppointment .checkbox-block.privacy {
    margin-top: 29px;
    margin-bottom: 5px;
  }
  .ReserveAppointment .checkbox-block {
    line-height: 1.58;
    margin-bottom: 0px;
  }
  .ReserveAppointment .divider-content {
    margin-bottom: 18px;
  }
  .ReserveAppointment .form-group-2-colum .Input-control {
    width: 50%;
    margin-left: 7px;
    margin-right: 7px;
  }
  .ReserveAppointment .h3 {
    font-family: 'Calibre Medium';
    line-height: 1.45;
  }
  .ReserveAppointment .already-login-text A {
    color: #3dbce7;
  }
  .ReserveAppointment .ca-social .social-icon {
    border: solid 1px rgba(1, 54, 72, 0.2);
    font-family: 'Calibre Medium';
    letter-spacing: normal;
    padding-top: 3px;
  }
  .ReserveAppointment .divider-content > SPAN {
    color: rgba(1, 54, 72, 0.5);
    background-color: #fbfbf9;
  }
  .ReserveAppointment .Select-control.form-group:after {
    top: 0;
  }
  .ReserveAppointment .Select-control.form-group SELECT {
    padding: 3px 30px 0 25px;
    font-size: 18px;
  }
  .ReserveAppointment .appoitment-smtime {
    display: block;
    width: calc(100% - 181px);
  }
  .ReserveAppointment .action-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
    margin: 0;
    padding: 19px 30px 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e0e4e7;
  }
  .ReserveAppointment .action-bottom .CircleButton.WhiteButton {
    letter-spacing: normal;
    min-width: 171px;
    width: auto;
    margin-left: 10px;
    height: 48px;
    line-height: 48px;
  }
  .ReserveAppointment .divider-content .Divider .container {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .ReserveAppointment .h3 {
    width: 100%;

    font-size: 20px;
  }
}
@media (max-width: 413px) {
  .ReserveAppointment .action-bottom {
    padding: 19px 15px 15px;
  }
  .ReserveAppointment .action-bottom {
    flex-direction: column;
    justify-content: center;
  }
  .ReserveAppointment .appoitment-smtime {
    width: 100%;
    text-align: center;
  }
  .ReserveAppointment .action-bottom .CircleButton.WhiteButton {
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
}
@media (max-width: 320px) {
  .ReserveAppointment .h3 {
    margin-bottom: 5px;
  }
  .ReserveAppointment .already-login-text {
    margin-top: 8px;
  }
}
@supports (-webkit-touch-callout: none) {
  .ReserveAppointment .action-bottom .CircleButton.WhiteButton {
    padding-top: 1px;
  }
}
