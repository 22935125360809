.ReviewSourceList {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ReviewSourceList .date-cell {
  width: 20%;
}
.ReviewSourceList .staus-cell {
  width: 5%;
  text-align: center;
}
.ReviewSourceList .access-link-cell {
  width: 52%;
  padding-left: 15px;
}
.ReviewSourceList .action-cell {
  width: 2%;
}
.ReviewSourceList .Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.ReviewSourceList .Rtable-row {
  width: 100%;
  display: flex;
}
.ReviewSourceList .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ReviewSourceList .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ReviewSourceList .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ReviewSourceList .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ReviewSourceList .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ReviewSourceList .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ReviewSourceList .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ReviewSourceList .Rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ReviewSourceList .Rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ReviewSourceList .action-menu {
  margin-left: auto;
  position: relative;
}
.ReviewSourceList .action-menu .action-btn {
  display: inline-block;
  width: 20px;
  cursor: pointer;
}
.ReviewSourceList .action-menu .dropdown-menu {
  position: absolute;
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  right: 0;
  top: 30px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  z-index: 8;
  display: none;
  padding-top: 7px;
}
.ReviewSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.ReviewSourceList .action-menu .dropdown-menu SPAN {
  display: inline-block;
  padding: 15px 20px;
  width: 100%;
  font-size: 16px;
  color: #013648;
  cursor: pointer;
  border-bottom: 1px solid #fafafb;
}
.ReviewSourceList .action-menu .dropdown-menu SPAN.remove-user {
  color: #e85d72;
}
.ReviewSourceList .action-menu.show-menu .dropdown-menu {
  display: block;
}
.GoogleCalendar {
  text-align: center;
  padding: 5px;
}
.GoogleCalendar .logo img {
  width: 30px;
  margin-bottom: 5px;
}

.GoogleCalendar .name {
  margin-bottom: 20px;
}
.GoogleCalendar BUTTON {
  background-color: #26acda;
  font-size: 14px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 7px 14px 3px;
  border-radius: 2px;
}

.GoogleCalendar .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: -3px;
  right: -4px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.GoogleCalendar .info-wrapper {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}
.GoogleCalendar .info-wrapper img {
  position: absolute;
  top: -11px;
  width: 14px;
}
.GoogleCalendar .info-wrapper:hover .info-content {
  display: block;
}
@media (max-width: 767px) {
  .ReviewSourceList .action-menu .dropdown-menu {
    right: 32px;
    top: -10px;
  }
}
