.AdminDashboard .dashboard-panle {
  display: flex;
  padding: 15px 20px 40px 40px;
  justify-content: space-between;
}
.AdminDashboard {
  width: calc(100% - 250px);
}
.AdminDashboard .panel-left {
  width: 100%;
}
.AdminDashboard .panel-right {
  padding-left: 40px;
  min-width: 274px;
  width: 274px;
}
.AdminDashboard .monthly-activity {
  display: block;
  width: 100%;
}
.AdminDashboard .monthly-activity .AppointmentDetail {
  margin-bottom: 15px;
}
.AdminDashboard
  .monthly-activity
  .AppointmentDetail
  .Patient-status-control
  .Select-control.form-group
  select {
  padding-top: 0px;
}
.AdminDashboard .monthly-activity .title-content .title {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
}
.AdminDashboard .monthly-activity .title-content .title:after {
  content: '';
  height: 1px;
  background-color: #f1f1f5;
  left: 0;
  right: 0;
  top: 8px;
  position: absolute;
}
.AdminDashboard .monthly-activity .title-content .title SPAN {
  display: inline-block;
  padding: 0px 17px;
  background-color: #fafafb;
  z-index: 1;
  position: relative;
}
.AdminDashboard .monthly-activity .title-content {
  display: flex;
  align-items: center;
  margin-bottom: 29px;
}
.AdminDashboard .monthly-activity .title-content .Select-control {
  margin-bottom: 0;
  margin-left: 16px;
}
.AdminDashboard .monthly-activity .title-content .Select-control SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 22px;
  padding-left: 16px;
  width: 115px;
  color: #013648;
}
.AdminDashboard .monthly-activity .title-content .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 7px;
}
.AdminDashboard .monthly-activity .title-content .Select-control.form-group:after {
  display: none;
}
.AdminDashboard .dashboard-panle .monthly-activity .InfiniteScroll {
  margin-right: -10px;
}
.AdminDashboard .analysis-title {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-left: 2px;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .AdminDashboard .dashboard-panle {
    display: flex;
    padding: 0px 20px 40px 20px;
  }
  .AdminDashboard .panel-right {
    padding-left: 20px;
  }
  .AdminDashboard .AppointmentDetail .Patientinner-Details {
    flex-wrap: wrap;
  }
  .AdminDashboard .AppointmentDetail .Patient-information {
    padding-right: 0;
    border-right: 0;
  }
  .AdminDashboard .AppointmentDetail .Patient-status {
    padding-left: 0;
    margin-top: 30px;
    min-width: auto;
  }
}

@media (max-width: 1024px) {
  .AdminDashboard {
    width: calc(100% - 200px);
  }
}

@media (max-width: 767px) {
  .AdminDashboard .dashboard-panle {
    flex-wrap: wrap;
    padding: 0px 15px;
  }
  .AdminDashboard .panel-right {
    padding-left: 0px;
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .AdminDashboard .panel-right > DIV {
    width: 48%;
    display: inline-block;
    vertical-align: top;
  }
  .AdminDashboard .AppointmentDetail {
    margin-bottom: 30px;
  }
  .AdminDashboard {
    width: 100%;
  }
  .AdminDashboard .AppointmentDetail .PatientDetails-content {
    flex-wrap: wrap;
  }
}

@media (max-width: 414px) {
  .AdminDashboard .panel-right > DIV {
    width: 100%;
  }
}
