.PatientForm {
  height: calc(100vh - 150px);
}
.PatientForm .UILabel {
  top: 30px;
}
.PatientForm form {
  width: 350px;
  margin: auto;
  padding-top: 50px;
}
.PatientForm .select-appointment-date {
  position: relative;
}
.PatientForm .select-appointment-date .datepicker {
  position: absolute;
  z-index: 9;
  position: absolute;
  top: 55px;
}
.PatientForm .select-appointment-date .MyDatetime .rdtPicker {
  height: 350px;
  overflow: hidden;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch {
  top: 34px;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch:after {
  top: 10px;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker .dow {
  text-transform: uppercase;
  font-family: 'Calibre Semibold';
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker td {
  line-height: 1.7;
  padding-top: 5px;
}
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker th.rdtNext,
.PatientForm .select-appointment-date .MyDatetime.days .rdtPicker th.rdtPrev {
  top: 30px;
}
.PatientForm .select-appointment-date .MyDatetime .rdtPicker .rdtDays table thead tr .dow {
  border-spacing: 20px 5px;
}
.PatientForm .select-appointment-date .MyDatetime .rdtPicker {
  padding-left: 0;
  padding-right: 0;
}
.PatientForm .Input-control.form-group input {
  padding-left: 60px;
  height: 55px;
}
.PatientForm .Input-control .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
}
.PatientForm .search {
  position: relative;
}
.PatientForm .search .search-result {
  padding: 12px 0px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  position: absolute;
  width: 500px;
  height: 400px;
  max-width: 100%;
  overflow-x: auto;
  top: 54px;
  z-index: 99;
  display: none;
}
.PatientForm .search.active .search-result {
  display: block;
}
.PatientForm .search-result .search-category {
  margin-bottom: 20px;
}
.PatientForm .search-result .search-category:last-child {
  margin-bottom: 0;
}
.PatientForm .search-result .search-category .h3 {
  opacity: 0.5;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #18222a;
  padding: 0px 18px;
}
.PatientForm .search-result .search-category span {
  display: inline-block;
  width: 100%;
}
.PatientForm .search-result .search-category .search-item {
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #013648;
  padding: 0px 18px;
  cursor: pointer;
}
.PatientForm .doctor-item {
  padding-left: 75px;
  position: relative;
  font-size: 20px;
  line-height: 1.05;
  color: #013648;
  display: inline-block;
  margin-bottom: 20px;
  min-height: 48px;
}
.PatientForm .doctor-item img {
  width: 40px;
  height: 48px;
  border-radius: 2px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  position: absolute;
  left: 19px;
}
.PatientForm .doctor-item span:last-child {
  font-size: 18px;
  color: #72787d;
}
.PatientForm .search-category.doctor {
  position: relative;
  padding-top: 30px;
}
.PatientForm .search-category.doctor:after {
  content: '';
  position: absolute;
  left: 19px;
  right: 19px;
  top: 0;
  height: 1px;
  background-color: rgba(151, 151, 151, 0.24);
}
@media (hover: hover) {
  .PatientForm .search-result .search-category .search-item:hover {
    background-color: #eaf4eb;
  }
}
