.BetterTools {
  padding: 85px 0 75px;
}
.BetterTools .tools-inner-content .title span {
  width: 390px;
  font-size: 20px;
  line-height: 1.3;
  color: #013648;
  display: inline-block;
  margin-left: 86px;
  vertical-align: top;
}
.BetterTools .tools-inner-content {
  padding: 0 30px;
}
.BetterTools .tools-inner-content .title .heading2 {
  display: inline-block;
  width: 500px;
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Heldane Display';
}
.BetterTools .tools-inner-content .title {
  margin-bottom: 48px;
  padding: 0px 35px;
}

@media (max-width: 1200px) {
  .BetterTools .tools-inner-content .title .heading2 {
    font-size: 54px;
    width: 450px;
  }
  .BetterTools .tools-inner-content .title SPAN {
    width: 350px;
    margin-left: 25px;
  }
  .dashboard-content IMG {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .BetterTools {
    padding: 50px 0;
  }
  .BetterTools .tools-inner-content .title .heading2 {
    width: auto;
  }
  .BetterTools .tools-inner-content .title .heading2 {
    font-size: 42px;
    width: 335px;
  }
  .BetterTools .tools-inner-content .title {
    padding: 0px 0px;
  }
  .BetterTools .tools-inner-content .title SPAN {
    width: 300px;
    margin-top: 0px;
  }
}
@media (max-width: 767px) {
  .BetterTools .tools-inner-content .title .heading2 {
    width: 100%;
    font-size: 48px;
    line-height: 1.17;
    margin-bottom: 30px;
  }
  .BetterTools .tools-inner-content .title SPAN {
    width: 100%;
    margin-left: 0;
    font-size: 20px;
    line-height: 1.6;
    color: #013648;
  }
  .BetterTools .tools-inner-content {
    padding: 0;
  }
  .BetterTools {
    padding: 90px 0 59px;
  }
  .BetterTools .dashboard-content {
    margin-bottom: 28px;
  }
}
