.DoctorToday {
  padding: 125px 0 67px;
  background-color: rgba(234,244,235,.2);
}
.DoctorToday .heading2 {
  font-family: 'Heldane Display';
    font-size: 54px;
    line-height: 1;
    letter-spacing: -.9px;
    text-align: center;
    color: #013648;
    width: 64%;
    margin: 0 auto;
    margin-bottom: 48px;

}
.DoctorToday .today-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}
.DoctorToday .today-col {
  width: 33.33%;
  padding: 0 15px;
  text-align: center;
}
.DoctorToday .today-col .today-box {
  max-width: 314px;
  width: 100%;
  margin: 0 auto 34px;
  background: #ffffff;
  padding: 26px 18px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: solid 1px rgba(162, 168, 174, 0.55);
  min-height: 260px;
}
.DoctorToday .today-col.symptoms-today-col .today-box  .today-box-inner {
  max-width: 243px;
  width: 100%;
  margin: 0 auto;
}
.DoctorToday .today-col .heading3.fst {  
  /* width: 197px; */
  margin: 0 auto;
  margin-bottom: 36px;
}
.DoctorToday .today-col .heading3 {  
  font-family: 'Heldane Display';
  font-size: 25.6px;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
  margin-bottom: 30px;
}
.DoctorToday .today-col.appoinment-today-col .heading3 {
  margin-bottom: 16px;
}
.DoctorToday .today-col .sf-description-item {
  display: inline-block;
  width: 100%;
  margin-bottom: 13px;
}
.DoctorToday .today-col .sf-description-item:last-child {
  margin-bottom: 0;
}
.DoctorToday .today-col .sf-description-item.active SPAN {
  background-color: #013648;
  color: #fff;
}
.DoctorToday .today-col .sf-description-item.second SPAN{
  background-color: #e7ebeb;
  color: #013648;
}
.DoctorToday .today-col .sf-description-item SPAN {
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  background-color: #e7ebeb;
  width: 100%;
  color: #fff;  
text-align: center;
   font-family: Calibre Medium;
font-style: normal;
font-weight: normal;
  display: inline-block;  
  padding: 5px 15px 5px;  
  font-size: 17px;
  line-height: 157.5%;
  letter-spacing: -0.26px;
}
.DoctorToday .today-desc {
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: -0.4px;
  text-align: center;
  color: #013648;
  max-width: 335px;
  margin: 0 auto;
  width: 100%;
}
.DoctorToday .symptoms-today-col .today-desc {
  max-width: 270px;
}
.DoctorToday .appoinment-today-col .today-desc {
  max-width: 260px;
}
.DoctorToday .appoinment-timing .time {
  text-align: left;
  width: 96px;
  min-width: 96px;
  height: 102px;
  border-radius: 4.8px;
  background-color: #013648;    
  margin: 0 auto;
  display: flex;
  padding: 18px 0 18px 11px;
  font-size: 22px;
  line-height: 1.09;
  letter-spacing: -0.41px;
  color: #f7fffc;
}
.DoctorToday .appoinment-timing .time > SPAN {
  margin: auto;
}
.DoctorToday .appoinment-timing .time .date, 
.DoctorToday .appoinment-timing .time .day {
  display: inline-block;
  width: 100%;
}
.DoctorToday .appoinment-timing .time .day {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.DoctorToday .doctor-profile-wrapper {
  display: flex;
  text-align: left;
  margin-bottom: 18px;
}
.DoctorToday .doctor-profile-wrapper .profile-photo {
  width: 61px;
  height: 79px;
  min-width: 61px;
}
.DoctorToday .doctor-profile-wrapper .profile-photo IMG {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  background-color: #d8d8d8;
  object-fit: cover;
  margin-top: 1px;
}
.DoctorToday .doctor-profile-wrapper .profile-info {
  flex: 1;
  padding-left: 11px;
}
.DoctorToday .doctor-profile-wrapper .profile-row {
  display: flex;
}
.DoctorToday .doctor-profile-wrapper .profile-name {
  font-size: 16px;
  line-height: 1.15;
  letter-spacing: -0.18px;
  color: #013648;
  font-family: 'Heldane Display';
  text-align: left;
  margin-bottom: 7px;
}
.DoctorToday .doctor-profile-wrapper .profile-specialist {
  font-size: 14px;
  line-height: 0.97;
  letter-spacing: normal;
  color: #013648;
  text-align: left;
}
.DoctorToday .doctor-profile-wrapper .profile-row .profile-left {
  flex: 1;
  padding-right: 10px;
}
.DoctorToday .doctor-profile-wrapper .rating-image {
  text-align: right;
  position: relative;
  display: block;
}
.DoctorToday .doctor-profile-wrapper .rating-image .star-icon {
  font-size: 35.2px;
  line-height: 1.42;
  color: #013648;
  position: relative;
  top: 8px;
  z-index: 1;
  left: 3px;
  display: inline-block;
  width: 22px;
  height: 21px;
}
.DoctorToday .doctor-profile-wrapper .rating-image .review-count {  
  position: relative;
  top: 0;
  display: inline-block;
  font-family: 'Heldane Display';
  font-weight: normal;
  font-size: 25.9px;
  line-height: 0.71;
  letter-spacing: -0.52px;
  color: #013648;
}
.DoctorToday .pt-category-box .box {
  width: 77px;
  height: 9px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
}
.DoctorToday .pt-category-box .box:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(4n) {
  background-color: rgba(38, 172, 218, 0.2);  
}
.DoctorToday .pt-category-box .box:nth-of-type(5n) {
  background-color: rgba(242, 150, 45, 0.2);
}
.DoctorToday .pt-category-box .box:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 0.2);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(1n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(1n) {
  background-color: rgba(61, 147, 166, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(2n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(2n) {
  background-color: rgba(232, 93, 114, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(3n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(3n) {
  background-color: rgba(141, 119, 186, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(4n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(4n) {
  background-color: rgba(38, 172, 218, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(5n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(5n) {
  background-color: rgba(242, 150, 45, 1);
}
.DoctorToday .pt-category-box .box.active:nth-of-type(6n),
.DoctorToday .pt-category-box .box:hover:nth-of-type(6n) {
  background-color: rgba(162, 168, 174, 1);
}
.DoctorToday .pt-category-box {
  display: flex;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 11px;
}
.DoctorToday .dc-symptoms-result {
  min-height: 28px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.DoctorToday .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.DoctorToday .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.DoctorToday .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.DoctorToday .pt-category-box .box:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.DoctorToday .progress-bar .dc-symptoms {  
  font-size: 14px;
  line-height: 1;
  letter-spacing: normal;
  color: #a2a8ae;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.DoctorToday .progress-bar .dc-symptoms .symptoms-txt {
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.DoctorToday .rating-block {
  text-align: left;
  padding-top: 16px;
  border-top: 1px solid rgba(151,151,151,0.23);
}
.DoctorToday .rating-block .star-rating {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.DoctorToday .rating-block .star-rating .rating-inner {
  display: flex;
  align-items: center;
  margin-right: 23px;
}
.DoctorToday .rating-block .star-rating .rating-inner:last-child {
  margin-right: 0;
}
.DoctorToday .rating-block .star-rating .name {
  margin-right: 4px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.58;
  letter-spacing: -0.19px;
  color: #013648;
}
.DoctorToday .rating-block .star-rating .rate-icon {
  width: 12px;
  margin-right: 4px;
  height: 11px;
  position: relative;
  top: -4px;
  left: 0;
}
.DoctorToday .rating-block .star-rating .number { 
  font-family: 'Heldane Display';
  font-weight: normal;
  position: relative;
  top: -2px;
  font-size: 17px;
  line-height: 0.7;
  letter-spacing: normal;
  text-align: center;
  color: #013648;
}
.DoctorToday .review-content {
  font-size: 16px;
  line-height: 1.13;
  letter-spacing: -0.2px;
  color: #013648;
}
.DoctorToday .video-btn {
  width: 140px;
  color: #8d77ba;
  height: 34px;
  border-radius: 14.1px;
  background-color: #eeebf5;
  display: inline-block;
  text-align: left;
  position: relative;
  padding-left: 31px;
  font-family: 'Calibre Medium';
  font-size: 16.6px;
  font-weight: 500;
  line-height: 35px;
  letter-spacing: -0.31px;
  margin: 0 auto 21px;
}
.DoctorToday .video-btn span{
  position: relative;
    top: 4px;
    left: 17px;
}
.DoctorToday .video-btn IMG {
  position: absolute;
  left: 0;
  width: 34px;
  height: 34px;
}
.DoctorToday .rating-parent-div{
  display: flex;
}
.DoctorToday .rating-parent-div span{
  font-size: 13px;
    margin-left: 4px;
    top: 3px;
    position: relative;
}
@media (hover: hover) {
  /* .DoctorToday .today-col .sf-description-item.active:hover SPAN {
    background-color: #e7ebeb;
    color: #013648;
  } */
  .DoctorToday .today-col .sf-description-item.second:hover SPAN {
    background-color: #013648;
color: #fff;
  }
}

@media (max-width: 1024px) {
  .DoctorToday {
    padding: 80px 0 67px;
  }
  .DoctorToday .heading2 {
    font-size: 30px;
    margin-bottom: 45px;
  }
  .DoctorToday .today-col .heading3 {
    font-size: 22px;
  }
  .DoctorToday .today-desc {
    font-size: 21px;
  }
}

@media (max-width: 991px) {
  .DoctorToday .today-col {
    width: 100%;
    margin-bottom: 15px;
  }
  .DoctorToday .today-col:last-child {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .DoctorToday {
    padding: 70px 0 50px;
  }
  .DoctorToday .today-row{
    margin-bottom: 0;
  }
  .DoctorToday .heading2 {
    font-size: 28px;
  }
  .DoctorToday .dc-symptoms-result {
    left: auto;
    right: 0;
  }
  .DoctorToday .heading2 {
    width: 100%;
  }
}