.AppointmentBookedModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 678px;
  margin: auto;
}
.AppointmentBookedModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.AppointmentBookedModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.AppointmentBookedModal .modal-content-header .close img {
  width: 100%;
}
.AppointmentBookedModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.AppointmentBookedModal .modal-content-body {
  padding: 36px 100px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AppointmentBookedModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.AppointmentBookedModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.AppointmentBookedModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AppointmentBookedModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.AppointmentBookedModal .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.AppointmentBookedModal .form-group-2-colum .Input-control {
  margin-left: 6px;
  margin-right: 6px;
}
.AppointmentBookedModal .AppointmentRequest .qu-right .appointment-time .time {
  margin-bottom: 0;
}
.AppointmentBookedModal .AppointmentRequest .appointment-doctor .doctor-img {
  width: 120px;
  min-width: 120px;
  height: 179px;
}
@media (max-width: 767px) {
  .AppointmentBookedModal {
    width: 100%;
  }
  .AppointmentBookedModal .modal-content-body {
    height: calc(100vh - 180px);
  }
  .AppointmentBookedModal .AppointmentRequest .appointment-map {
    display: block;
    margin-top: 15px;
    width: 100%;
  }
  .AppointmentBookedModal .AppointmentRequest .questionnaire {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  .AppointmentBookedModal .modal-content-body {
    padding: 36px 30px;
  }
  .AppointmentBookedModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .AppointmentBookedModal .modal-content-header {
    padding-left: 20px;
  }
  .AppointmentBookedModal .AppointmentRequest .appointment-doctor .doctor-img {
    width: 70px;
    min-width: 70px;
    height: 115px;
  }
  .AppointmentBookedModal .modal-content-header {
    height: auto;
    padding: 10px 20px;
  }
}
@media (max-width: 575px) {
  .AppointmentBookedModal .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
}
