.AdminLayoutHeader {
  background-color: #ffffff;
  height: 70px;
  display: flex;
  z-index: 8;
  padding: 0 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.AdminLayoutHeader > DIV {
  width: 100%;
}
.AdminLayoutHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}
.AdminLayoutHeader .header-content .right-col A {
  color: #013648;
}
.AdminLayoutHeader .header-content .right-col SPAN {
  display: inline-block;
  padding: 0px 0px;
  position: relative;
}
.AdminLayoutHeader .header-content .right-col SPAN:last-child {
  padding-right: 0px;
}
.AdminLayoutHeader .header-content .logo {
  width: 111px;
}
.AdminLayoutHeader .header-content .logo A IMG {
  width: 100%;
  cursor: pointer;
}
.AdminLayoutHeader .header-content .logo A {
  position: relative;
  top: 5px;
  display: inline-block;
}
.AdminLayoutHeader .mobile-menu {
  display: none;
}
.AdminLayoutHeader .header-content .right-col .user .user-content > DIV {
  display: flex;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-pic {
  width: 27px;
  height: 41px;
  margin-right: 16px;
  display: inline-block;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-pic IMG {
  width: 100%;
  border-radius: 2.5px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .staff-default-img {
  width: 27px;
  height: 41px;
  margin-right: 16px;
  display: inline-block;
  border-radius: 2.5px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  padding: 8px 3px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .staff-default-img IMG {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-post {
  opacity: 0.4;
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: normal;
  color: #013648;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name {
  font-family: 'Calibre Regular';
  font-size: 16px;
  line-height: 1.4;
  color: #013648;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info SPAN {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info {
  position: relative;
  padding-right: 60px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .user-info .arrowPointer {
  position: absolute;
  right: 19px;
  top: 15px;
  cursor: pointer;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: 0.3s all linear;
  background-color: #ffffff;
  min-width: 300px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 16px 20px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.email IMG {
  width: 20px;
  position: absolute;
  left: 20px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.email {
  color: #3dbce7;
  padding-left: 47px;
  position: relative;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.logout IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.logout {
  padding-left: 47px;
  position: relative;
}
.AdminLayoutHeader
  .header-content
  .right-col
  .user
  .user-content
  .dropdown-menu
  SPAN.change-pwd
  IMG {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 20px;
}
.AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN.change-pwd {
  padding-left: 47px;
  position: relative;
}
.AdminLayoutHeader .header-content .right-col .user .user-content {
  position: relative;
}
@media (hover: hover) {
  .AdminLayoutHeader .header-content .right-col A:hover {
    text-decoration: none;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
@media (max-width: 1024px) {
  .AdminLayoutHeader {
    padding: 0 10px;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content > DIV {
    display: flex;
  }
}
@media (max-width: 767px) {
  .AdminLayoutHeader .header-content {
    padding-left: 35px;
  }
  .AdminLayoutHeader .mobile-menu {
    width: 20px;
    height: 18px;
    position: absolute;
    top: 22px;
    cursor: pointer;
    display: block;
    left: 15px;
  }
  .AdminLayoutHeader .mobile-menu SPAN {
    width: 20px;
    display: inline-block;
    height: 2px;
    background-color: #013648;
    position: absolute;
    transition: 0.3s all linear;
  }
  .AdminLayoutHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .AdminLayoutHeader .mobile-menu SPAN:nth-child(2) {
    top: 7px;
  }
  .AdminLayoutHeader .mobile-menu SPAN:last-child {
    top: 14px;
    width: 10px;
  }
  .AdminLayoutHeader.menu-active .right-col {
    right: 0px;
  }
  .AdminLayoutHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .AdminLayoutHeader.menu-active .mobile-menu SPAN {
    background-color: #013648;
    transform: rotate(45deg);
  }
  .AdminLayoutHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 0;
  }
  .AdminLayoutHeader.menu-active .mobile-menu {
    position: fixed;
    left: 15px;
    top: 30px;
    z-index: 2;
  }
  .AdminLayoutHeader .header-content .right-col SPAN,
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name {
    line-height: 1;
  }
  @media (hover: hover) {
    .AdminLayoutHeader .mobile-menu:hover SPAN:last-child {
      width: 20px;
    }
  }
}
@media (max-width: 414px) {
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name {
    font-size: 16px;
  }

  .AdminLayoutHeader .header-content .right-col .user .user-content .user-pic {
    margin-left: 8px;
    margin-right: 8px;
  }
  .AdminLayoutHeader .header-content .logo {
    min-width: 98px;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-name,
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .user-post {
    width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info {
    padding-right: 25px;
  }
  .AdminLayoutHeader .header-content .right-col .user .user-content .user-info .arrowPointer {
    right: 7px;
  }
}
