.like_unlike{
  display: flex;
}
.like_unlike div{
  height: 60px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}
.like_unlike  .likediv{
  margin-right: 10px;
}
.like_unlike .unlike{
 padding-top: 10px;
 
}
.like_unlike img{
  width: 50px;
}
.like_unlike img.likeimg{
  margin-top: 4px;
}
.like_unlike div.noallow{
  
  cursor: auto;
  
}
.like_unlike div.active.activegreen{
  background-color: #198754;
}
.like_unlike div.active.activered{
  background-color: #e85d72;
}
.like_unlike div.novalue img{
  filter: invert(55%) sepia(27%) saturate(0%) hue-rotate(218deg) brightness(106%) contrast(86%);
}