.CalendarAppointmentEvent.rbc-event {
  background-color: #e8f5f7;
  border: solid 1px #199daf;
  border-left: 8px solid #199daf;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  overflow: visible;
  padding-top: 4px;
  z-index: 1;
}
.CalendarAppointmentEvent.rbc-event.confirmed {
  z-index: 3;
}
.CalendarAppointmentEvent.rbc-event.pending {
  border: solid 1px #f2962d;
  border-left: 8px solid #f2962d;
  background-color: #fff9f2;
  z-index: 2;
}
.CalendarAppointmentEvent.rbc-event.pending .rbc-event-content {
  position: relative;
}
.CalendarAppointmentEvent.rbc-event.pending .rbc-event-content .overlay {
  position: absolute;
  left: -6px;
  right: 0;
  top: -6px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}
.CalendarAppointmentEvent.rbc-event.pending .rbc-event-content .overlay > span {
  width: 8px;
  background-color: rgba(242, 150, 45, 0.15);
}
.CalendarAppointmentEvent .main-event {
  font-family: 'Calibre Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 0.88;
  letter-spacing: 0.11px;
  color: #013648;
  /* padding-top: 9px; */
  height: 100%;
  overflow: hidden;
}
.CalendarAppointmentEvent .main-event .icon {
  margin-bottom: 5px;
  display: inline-block;
  right: 7px;
  width: 16px;
  position: absolute;
}
.CalendarAppointmentEvent .main-event .icon img {
  width: 100%;
}
.CalendarAppointmentEvent .main-event .time {
  line-height: 1;
}
