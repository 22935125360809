.PracticeTab {
  display: flex;
  margin: 0 -15px;
}
.PracticeTab .leftpanle {
  padding: 0 15px;
  width: 45.5%;
}
.PracticeTab .rightpanle {
  padding: 0 15px;
  width: 54.5%;
}

@media (max-width: 1280px) {
  .PracticeTab {
    margin: 0 -5px;
  }
  .PracticeTab .leftpanle,
  .PracticeTab .rightpanle {
    padding: 0 5px;
  }
}

@media (max-width: 1024px) {
  .PracticeTab {
    flex-wrap: wrap;
    margin: 0;
  }
  .PracticeTab .leftpanle,
  .PracticeTab .rightpanle {
    width: 100%;
    padding: 0;
  }
}
