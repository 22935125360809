.ListyourpracticeIntrobodyText{
margin-top: 50px;
}
.ListyourpracticeIntrobodyText .maintext{
  width: 660px;
  height: 126px;
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 35px;
line-height: 122.5%;
text-align: center;
margin: 0 auto;
color: #013648;
margin-bottom: 30px;
}
.ListyourpracticeIntrobodyText .box{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  height: 207px;
}
.ListyourpracticeIntrobodyText .box .fst{
  background: rgba(170, 149, 215, 0.15);
  border: 1px solid #AA95D7;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 25px;
  padding-bottom: 20px;
}
.ListyourpracticeIntrobodyText .box .sec{
  background: #E5F3F6;
  border: 1px solid #77CAE5;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 25px;
  padding-bottom: 20px;
}
.ListyourpracticeIntrobodyText .box .thr{
  background: rgba(244, 161, 71, 0.15);
border: 1px solid #F4A147;
box-sizing: border-box;
border-radius: 6px;
padding: 0 25px;
padding-bottom: 20px;
}
.ListyourpracticeIntrobodyText .box .headding{
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 118.18%;
  color: #013648;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 23px;
}
.ListyourpracticeIntrobodyText .box .paragraph{
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 143.5%;
text-align: center;
color: #013648;
margin-bottom: 0;
}