.SwitchToggle {
  margin-bottom: 25px;
  padding-left: 55px;
  position: relative;
}
.SwitchToggle .switch-label {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
}
.SwitchToggle .switchContainer {
  position: absolute;
  left: 0;
  top: -4px;
  display: inline-block;
  width: 40px;
  height: 20px;
}
.SwitchToggle .switchContainer input {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
.SwitchToggle .switchContainer .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b2c2c8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.SwitchToggle .switchContainer .slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.SwitchToggle .switchContainer input:checked + .slider {
  background-color: #26acda;
}
.SwitchToggle .switchContainer input:focus + .slider {
  box-shadow: 0 0 1px #26acda;
}
.SwitchToggle .switchContainer input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -3px;
}
.SwitchToggle .switchContainer .slider.round {
  border-radius: 13px;
}
.SwitchToggle .switchContainer .slider.round:before {
  border-radius: 13px;
}
.SwitchToggle.switchImage {
  padding-left: 75px;
}
.SwitchToggle.switchImage .switchContainer {
  height: 31px;
  width: 67px;
}
.SwitchToggle.switchImage .switch-label {
  font-size: 16px;
  line-height: 1.26;
  letter-spacing: -0.3px;
}
.SwitchToggle.switchImage .switchContainer .slider img {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transition: all 0.3s ease;
}
.SwitchToggle.switchImage .switchContainer .slider img:last-child {
  display: none;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider {
  background-color: #8d77ba;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider img {
  left: auto;
  right: 0;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider img:first-child {
  display: none;
}
.SwitchToggle.switchImage .switchContainer input:checked + .slider img:last-child {
  display: block;
}
.SwitchToggle.switchImage .switchContainer .slider.round {
  border-radius: 15.5px;
}
.SwitchToggle.switchImage .switchContainer .slider {
  border: solid 1px #8d77ba;
  background-color: rgba(141, 119, 186, 0.15);
}
.SwitchToggle.switchImage .switchContainer .slider:before {
  display: none;
}
.SwitchToggle.switch-type-2 {
  display: inline-block;
}
.SwitchToggle.switch-type-2 .switchContainer input:checked + .slider:before {
  transform: translateX(8px);
}
.SwitchToggle.switch-type-2 .switchContainer .slider.round:before {
  transform: translateX(39px);
}
.SwitchToggle.switch-type-2 .switchContainer .slider:before {
  height: 27px;
  width: 27px;
}
.SwitchToggle.switch-type-2 .switchContainer {
  width: 74px;
  height: 33px;
}
.SwitchToggle.switch-type-2 .switchContainer .slider.round {
  border-radius: 16px;
}
.SwitchToggle.switch-type-2 .switchContainer input:checked + .slider:after {
  content: 'On';
  font-family: 'Calibre Medium';
  font-size: 18px;
  line-height: 1.78;
  text-align: right;
  color: #ffffff;
  position: absolute;
  right: 14px;
  top: 3px;
}
