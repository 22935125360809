.PatientLanding .gradient {
    background-image: linear-gradient(-90deg, rgba(242, 150, 45, 0.08), rgb(61, 147, 166, 0.08));
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    /* background: #F3F6F6; */
  }
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  .PatientLanding IMG{
    border-radius: 5px;
  }
  .divider{
    background-color: #fbfbf9;
  }  
  .divider .horizontal-line{
      width: 1140px;
      margin: auto;
      max-width: 100%;
      background-color: #eaf4eb;
  }
  
  .PatientLanding .divider .container{
    text-align: center;
    
  }  
  .PatientLanding .divider .horizontal-line{
      width: 887px;
      margin: auto;
      max-width: 100%;
      background-color: #eaf4eb;
  }