.VisitReasons {
  margin-bottom: 28px;
  border-bottom: 1px solid #f1f1f5;
  position: relative;
}
.blockEdit{
  position: absolute;
  width: 100%;
  height: 101%;
  top: -5%;
  background-color:#ffffff66;
  cursor: not-allowed;
}
.VisitReasons .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  letter-spacing: -0.33px;
  font-family: 'Calibre Medium';
}
.VisitReasons .heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.VisitReasons .heading .required-info {
  height: 26px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #f37184;
  margin-right: auto;
  margin-left: 20px;
  margin-top: 4px;
}
.VisitReasons .heading .CircleButton.WhiteButton {
  width: 173px;
  height: 32px;
  background-color: #26acda;
  border: 1px solid #26acda;
  font-size: 16px;
  line-height: 34px;
  color: #ffffff;
  font-family: 'Calibre Regular';
  margin-top: -5px;
  border-radius: 2px;
}
.VisitReasons .visit-tab-content {
  margin-top: 30px;
  margin-bottom: 57px;
  border-radius: 8px;
  max-height: 245px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.VisitReasons .tab-list {
  display: flex;
  white-space: pre;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  padding-left: 0px;
}
.VisitReasons .visit-tab-list-item {
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: normal;
  color: #b2bcc4;
  font-family: 'Calibre Regular';
  margin-right: 28px;
  padding-bottom: 3px;
  display: inline-block;
  cursor: pointer;
}
.VisitReasons .visit-tab-list-active {
  background-color: #ffffff;
  border: solid #013648;
  border-width: 0px 0px 1px 0px;
  opacity: 1;
  color: #013648;
}
.VisitReasons .visit-reason-tab .visit-tab-content .visit-list {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #72787d;
  margin-bottom: 3px;
}
.VisitReasons .visit-reason-tab .visit-tab-content .visit-list:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .VisitReasons .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 375px) {
  .VisitReasons .heading .CircleButton.WhiteButton {
    margin-top: 0;
  }
}
