.AdminPatients .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.AdminPatients {
  width: calc(100% - 250px);
}
.AdminPatients .main-content .main-inner-content {
  padding: 34px 40px 12px;
  border-bottom: 1px solid #e8e8e8;
}
.AdminPatients .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
.AdminPatients .search INPUT {
  padding: 3px 28px 0px 20px;
  height: 40px;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 40px;
  letter-spacing: -0.34px;
  color: #013648;
  -webkit-appearance: none;
}
.AdminPatients .search DIV {
  position: relative;
}
.AdminPatients .search BUTTON {
  padding: 5px 15px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  height: 40px;
  font-weight: 400 !important;
  line-height: 33px;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  border: 0;
}
.AdminPatients .search BUTTON:focus {
  outline: 0;
}
.AdminPatients .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.AdminPatients .search button.btn-clear IMG {
  width: 15px;
}
.AdminPatients .admin-provider-list {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  overflow: hidden;
}
.AdminPatients .admin-provider-list TD,
.AdminPatients .admin-provider-list TH {
  border: 1px solid black;
}
.AdminPatients .table-overflow {
  overflow: auto;
  width: 100%;
  box-shadow: 0px 0px 4px 0px #cacaca;
}
.AdminPatients .admin-provider-list TD {
  padding: 15px 15px 12px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border: 0;
}
.AdminPatients .admin-provider-list TR {
  border-bottom: 1px solid #d8d8d8;
}
.AdminPatients .admin-provider-list thead TD {
  background-color: #013648;
  color: white;
}
.AdminPatients .no-record {
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
}
.AdminPatients .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.AdminPatients .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 6px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.AdminPatients .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.AdminPatients .pagination LI.active {
  background-color: #013648;
  color: white;
}
.AdminPatients .pagination LI.previous.disabled,
.AdminPatients .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.AdminPatients .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.AdminPatients .pointer {
  cursor: pointer;
}
.AdminPatients .admin-provider-list TD .sort-icon {
  margin-left: 10px;
  position: relative;
}
.AdminPatients .admin-provider-list TD .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.AdminPatients .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
