.MenuItem A {
  font-family: 'Calibre Regular';
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
}
.MenuItem.active A:after {
  content: '';
  width: 8px;
  height: 32px;
  border-radius: 100px;
  background-color: #013648;
  position: absolute;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  left: 0px;
  top: 10px;
}
.MenuItem {
  display: inline-block;
  width: 100%;
  padding: 10px 20px;
  position: relative;
}
.MenuItem A IMG {
  width: 20px;
  margin-right: 20px;
  vertical-align: middle;
}
.MenuItem img.arrow {
  position: absolute;
  right: 18px;
  top: 16px;
  cursor: pointer;
}
.MenuItem.submenu {
  padding-top: 15px;
}
.MenuItem.submenu A {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  padding-left: 40px;
}
.MenuItem.submenu.sub-menu-active {
  background-color: #fafafb;
}
.pending-count.main{
  margin-left: 5px;
  background-color: #023648;
    color: #fff;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 50%;
    font-size: 16px;

}
.MenuItem.submenu .pending-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  top: 6px;
  padding: 0px 5px;
  border-radius: 4px;
  background-color: rgba(232, 93, 114, 0.1);
  height: 27px;
  color: #f2962d;
  margin-left: 4px;
}
.MenuItem.submenu.sub-menu-active .pending-count {
  color: #72787d;
}
