.FeedItem {
  width: 100%;
  border-radius: 4px;
  background-color: #f5f5f2;
  display: flex;
  padding: 19px 15px 9px 35px;
  position: relative;
  margin-bottom: 18px;
  min-height: 95px;
}
.FeedItem.cancel .feed-icon {
  position: relative;
  top: 0px;
  width: 17px;
}
.FeedItem .feed-icon {
  width: 22px;
  margin-left: 30px;
}
.FeedItem.welcome-feed .feed-icon {
  width: 32px;
  margin-right: 16px;
}
.FeedItem .feed-details {
  width: 100%;
}
.FeedItem.welcome-feed {
  background-color: transparent;
  background-image: linear-gradient(
    215deg,
    rgba(61, 147, 166, 0.06) 0%,
    rgba(240, 231, 56, 0.06) 110%,
    rgba(141, 120, 186, 0.06) 51%
  );
}
.FeedItem .welcome-feed-title {
  font-size: 20px;
  line-height: 1.7;
  color: #013648;
  font-family: 'Heldane Display';
  margin-bottom: 1px;
}
.FeedItem .feed-icon img {
  width: 100%;
}
.FeedItem .feed-text {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.43px;
  color: #013648;
  margin-bottom: 6px;
}
.FeedItem .feed-status {
  display: flex;
  justify-content: space-between;
}
.FeedItem .feed-time {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  position: absolute;
  bottom: 4px;
  right: 15px;
  margin-left: auto;
}
.FeedItem .feed-status .feed-status-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.38px;
}
.FeedItem.cancel .feed-status .feed-status-text {
  color: #b2bcc4;
}
.FeedItem.confirmed .feed-status .feed-status-text {
  color: #26acda;
  cursor: pointer;
}
.FeedItem.request .feed-status .feed-status-text {
  color: #72787d;
}
.FeedItem.confirmed.doctor-profile {
  padding-left: 35px;
}
.FeedItem.confirmed.doctor-profile .feed-icon {
  min-width: 27px;
  width: 27px;
  height: 40px;
}
.FeedItem.confirmed.doctor-profile .feed-icon img {
  border-radius: 1.6px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.FeedItem.disable-item {
  opacity: 0.3;
}

@media (hover: hover) {
  .FeedItem.confirmed .feed-status .feed-status-text:hover {
    color: rgba(38, 172, 218, 0.6);
  }
}

@media (max-width: 767px) {
  .FeedItem .feed-status {
    flex-direction: column;
  }
  .FeedItem .feed-time {
    margin-left: 0;
  }
  .FeedItem .feed-text {
    font-size: 16px;
  }
}
