.CalendarDayHeader {
  height: 56px;
  font-size: 16px;
  letter-spacing: 0.13px;
  text-align: center;
  color: #013648;
  display: flex;
  align-items: center;
  justify-content: center;
}
