.Visualizations{
  background: white;
  min-width: 90vw;
  overflow: auto;
}
.visualizations-xl .modal-wrapper{
  max-width: 90vw !important;
}
.Visualizations .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  background-color: #013648;
  /* border-bottom: 1px solid #f1f1f5; */
}
.Visualizations .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.Visualizations .modal-content-header .close IMG {
  width: 100%;
}
.Visualizations .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.Visualizations .chartBody{
padding: 20px;
padding-top: 0;
/* padding-right: 30px; */
}
@media (max-width: 767px) {

  .Visualizations .modal-content-body,
  .Visualizations .modal-content-header {
    padding: 23px 20px 13px 20px;
  }
 
}
@media (max-width: 575px) {
 
  .Visualizations .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .Visualizations .modal-content-header .h3 {
    font-size: 24px;
  }
  .Visualizations .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}
