.kalymdpopup .heading2{
  color: #013648;
  font-family: 'Heldane Display';
}
.kalymdpopup p{
  color: #013648;
  
}
iframe{
  width: 100%;
  border: none;
  min-height: 30vh;
}
.mega-modal-kalymd.Modal .modal-wrapper{
  width: 75%;
  max-width: 75%;
}
.mega-modal-kalymd.Modal .modal-wrapper .kalymdpopup{
width: auto !important;
height: 80vh;
}
.mega-modal-kalymd.Modal .modal-wrapper .kalymdpopup .modal-content-body{
  
  height: 80vh;
  }
  .mega-modal-kalymd.Modal .modal-wrapper .kalymdpopup .modal-content-body iframe{
    height: 100%;
  }