.ElasticSearch .active .Input-control.form-group {
  z-index: 1;
}
.ElasticSearch .Input-control.form-group input {
  padding-left: 60px;
  height: 55px;
}
.ElasticSearch .Input-control.form-group input:focus {
  border: solid 1.5px #013648;
}
.ElasticSearch .Input-control .view-img {
  width: 20px;
  height: 20px;
  left: 20px;
  right: auto;
}
.ElasticSearch .search {
  position: relative;
}
.ElasticSearch .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.ElasticSearch .search .search-result {
  padding: 12px 0px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  background-color: #ffffff;
  position: absolute;
  min-width: 300px;
  width: 100%;
  overflow-x: auto;
  top: 54px;
  z-index: 99;
  display: none;
  max-height: 400px;
}
.ElasticSearch .search.active .search-result {
  display: block;
}
.ElasticSearch .search-result .search-category {
  margin-bottom: 20px;
}
.ElasticSearch .search-result .search-category:last-child {
  margin-bottom: 0;
}
.ElasticSearch .search-result .search-category .h3 {
  opacity: 0.5;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #18222a;
  padding: 0px 18px;
}
.ElasticSearch .search-result .search-category span {
  display: inline-block;
  width: 97%;
}
.ElasticSearch .search-result .search-category .search-item {
  font-size: 20px;
  line-height: 1.8;
  letter-spacing: -0.2px;
  color: #013648;
  padding: 0px 18px;
  cursor: pointer;
}
.ElasticSearch .search-result .search-category .doctor-item b,
.ElasticSearch .search-result .search-category .search-item b {
  color: #46a6c7;
  font-weight: 500;
}
.ElasticSearch .doctor-item {
  padding-left: 75px;
  position: relative;
  font-size: 20px;
  line-height: 1.05;
  color: #013648;
  display: inline-block;
  margin-bottom: 20px;
  min-height: 48px;
  cursor: pointer;
}
.ElasticSearch .doctor-item img {
  width: 40px;
  min-width: 40px;
  height: 48px;
  border-radius: 2px;
  border: solid 0.5px rgba(1, 54, 72, 0.2);
  position: absolute;
  left: 19px;
  object-fit: cover;
}
.ElasticSearch .doctor-item span:last-child {
  font-size: 18px;
  color: #72787d;
}
.ElasticSearch .search-category.doctor {
  position: relative;
  padding-top: 30px;
}
.ElasticSearch .search-category.doctor:after {
  content: '';
  position: absolute;
  left: 19px;
  right: 19px;
  top: 0;
  height: 1px;
  background-color: rgba(151, 151, 151, 0.24);
}
.ElasticSearch .Input-control.form-group input.form-control::-webkit-input-placeholder {
  font-size: 20px;
  color: rgba(24, 34, 42, 0.6);
  font-family: 'Calibre Regular';
}
.ElasticSearch .Input-control.form-group input.form-control:-ms-input-placeholder {
  font-size: 20px;
  color: rgba(24, 34, 42, 0.6);
  font-family: 'Calibre Regular';
}
.ElasticSearch .Input-control.form-group input.form-control::placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
}
.ElasticSearch .Input-control.form-group input.form-control:-moz-placeholder {
  font-size: 20px;
  font-family: 'Calibre Regular';
  color: rgba(24, 34, 42, 0.6);
}
.ElasticSearch .search-result .loader {
  text-align: center;
}
.ElasticSearch .search-result .loader img {
  margin-top: 15px;
  height: 30px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circleanimation 2.5s infinite linear;
  animation: circleanimation 2.5s infinite linear;
}
@media (hover: hover) {
  .ElasticSearch .search-result .search-category .search-item:hover {
    /* background-color: #eaf4eb; */
    color: #46a6c7;
  }
}
@media (max-width: 767px) {
  .ElasticSearch .search .search-result {
    min-width: auto;
    margin: 0;
    text-align: left;
  }
  .ElasticSearch .Input-control.form-group input.form-control::-webkit-input-placeholder {
    font-size: 18px;
  }
  .ElasticSearch .Input-control.form-group input.form-control:-ms-input-placeholder {
    font-size: 18px;
  }
  .ElasticSearch .Input-control.form-group input.form-control::placeholder {
    font-size: 18px;
  }
  .ElasticSearch .Input-control.form-group input.form-control:-moz-placeholder {
    font-size: 18px;
  }
}
