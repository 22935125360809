.Login {
  padding: 119px 0;
}
.Login .login-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.Login .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 18px;
  margin-bottom: 48px;
  font-family: 'Heldane Display';
  font-weight: normal;
}
.Login .checkbox-block {
  margin-top: 22px;
  margin-bottom: 24px;
}
.Login .Checkbox-control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  margin-right: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.Login .login-btn {
  margin-bottom: 74px;
}
.Login .login-btn > * {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.Login .login-bottom {
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
}
.Login .login-bottom A {
  color: #3dbce7;
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.Login .checkbox-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Login .checkbox-block .reset-link {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
  font-family: 'Calibre Medium';
}
.Login .password-group .form-control {
  padding-right: 35px;
}
.Login .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 21px;
  height: 13px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.Login .doctor-loginbtn > * {
  height: 48px;
  line-height: 48px;
  font-family: 'Calibre Regular';
}
.otp-maindiv div.otp-subdiv{
  width: 320px;
  margin: 0 auto;
}
.otp-maindiv div.otp-subdiv div div input {
  width: 63.5% !important;
  margin-right: 8px;
  height: 42px;
  font-size: 18px;
}
@media (hover: hover) {
  .Login .login-bottom A:hover {
    color: #013648;
  }
  .Login .checkbox-block .reset-link:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .Login {
    padding: 90px 0;
  }
  .Login .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
  .Login .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .Login .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .Login {
    padding: 70px 0;
  }
  .Login .h3 {
    font-size: 28px;
    margin-bottom: 35px;
    padding-bottom: 12px;
  }
  .Login .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .Login .login-btn {
    margin-bottom: 30px;
  }
}
@supports (-webkit-touch-callout: none) {
  .Login .doctor-loginbtn .CircleButton {
    padding-top: 3px;
  }
}
