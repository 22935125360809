.ReviewRequest .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.ReviewRequest {
  width: calc(100% - 250px);
}
.ReviewRequest .rtable {
  display: table;
  background-color: white;
  width: auto;
  width: 100%;
}
.ReviewRequest .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.uploadFiles img.photoid{
width: 50px;
max-height: 100px;
position: absolute;
cursor: pointer;
top: 12px;
}
.ReviewRequest .row {
  display: table-row;
  width: auto;
  clear: both;
}
.ReviewRequest .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #e8e8e8;
}
.ReviewRequest .heading .cell {
  background-color: #013648;
  color: white;
}
.ReviewRequest .heading .cell:first-child {
  min-width: 150px;
}
.ReviewRequest .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.ReviewRequest .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.ReviewRequest .cell.edit-cell {
  width: 20px;
}
.ReviewRequest .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.ReviewRequest .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.ReviewRequest .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.ReviewRequest .pagination LI.active {
  background-color: #013648;
  color: white;
}
.ReviewRequest .pagination LI.previous.disabled,
.ReviewRequest .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.ReviewRequest .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.ReviewRequest .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.ReviewRequest .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.ReviewRequest .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.ReviewRequest .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.ReviewRequest .cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.ReviewRequest .pointer {
  cursor: pointer;
}
.ReviewRequest img.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.ReviewRequest .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .ReviewRequest .search input {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.ReviewRequest .search .Select-control {
  margin-bottom: 0px;
  margin-right: 15px;
}
.ReviewRequest .actionbtndev {
  display: grid;
}
.ReviewRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}
.ReviewRequest .search .Select-control SELECT {
  height: 40px;
}
/* .ReviewRequest .input-control INPUT {
  height: 52px;
} */
.ReviewRequest .Select-control.form-group:after {
  display: none;
}
.ReviewRequest .arrow {
  top: 19px;
  right: 14px;
  border-width: 6px;
  border-top-color: #a5a8b4;
}
/* .ReviewRequest .search div {
  position: relative;
} */
/* .ReviewRequest .search button {
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.ReviewRequest .search button.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.ReviewRequest .search button.btn-clear IMG {
  width: 15px;
}
.ReviewRequest .btn-add-new {
  padding: 9px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
}
.ReviewRequest .link-span {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.ReviewRequest .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ReviewRequest .search DIV {
  position: relative;
}
.ReviewRequest .btn-delete {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f37184;
  border: none;
  margin-top: 5px;
}
.ReviewRequest .btn-view {
  margin-left: 2px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  background-color: #3d93a6;
  border: none;
  margin-bottom: 5px;
}
.ReviewRequest .no-record {
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  padding-top: 10px;
  padding-left: 5px;
  padding-bottom: 7px;
}
.ReviewRequest .dataCount {
  top: auto;
  position: absolute;
  margin-top: 35px;
  font-size: large;
  float: left;
}
.photoid-div{
  display: grid;
  min-width: 38px;
}
.ReviewRequest .actionbtndev button {
  margin-left: 0;
}
.ReviewRequest .btn {
  margin-left: 10px;
  padding: 5px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .ReviewRequest .refresh-btn {
    margin-right: 15px;
  }
}
