.ChangeCardModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 575px;
  margin: auto;
}
.ChangeCardModal .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  background-color: #013648;
  /* border-bottom: 1px solid #f1f1f5; */
}
.ChangeCardModal .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.ChangeCardModal .modal-content-header .close IMG {
  width: 100%;
}
.ChangeCardModal .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.ChangeCardModal .modal-content-body {
  padding: 23px 41px 13px 47px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ChangeCardModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.ChangeCardModal .modal-content-footer {
  padding: 23px 47px 33px;
}
.ChangeCardModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.ChangeCardModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.ChangeCardModal .card-description {
  margin-top: 13px;
}
.ChangeCardModal .card-description .description {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #72787d;
}
.ChangeCardModal .stripe-input {
  margin-top: 20px;
  -webkit-box-align: center;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 0.2em;
  padding: 8px 6px;
  outline: 0;
  margin-bottom: 25px;
}
.ChangeCardModal .card-input {
  margin-top: 20px;
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid rgb(189, 189, 189);
  border-radius: 0.2em;
  display: flex;
  padding: 8px 6px;
  outline: 0;
  margin-bottom: 25px;
}
.ChangeCardModal .card-input .Input-control.form-group {
  position: relative;
  top: 2px;
}
.ChangeCardModal .card-input .Input-control.form-group INPUT {
  border: unset;
  margin: unset;
  padding: unset;
  outline: unset;
  height: auto;
}
.ChangeCardModal .card-input .Input-control.form-group INPUT:focus {
  outline: 0;
  border: 0;
}
.ChangeCardModal .card-input IMG {
  margin-right: 5px;
}
.ChangeCardModal .card-input .Input-control.form-group {
  margin: unset;
}
.ChangeCardModal .card-input .CVC {
  width: 10%;
}
.ChangeCardModal .card-input .expiryDate {
  width: 15%;
}
.ChangeCardModal .card-input .cardnumber {
  width: 70%;
}
.ChangeCardModal .modal-content-footer BUTTON {
  height: 32px;
  border-radius: 2px;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
  background-color: #f1f1f1;
  border: 0;
  margin-right: 10px;
  padding: 8px 15px 9px;
  cursor: pointer;
  outline: 0;
}
.ChangeCardModal .modal-content-footer .save {
  background-color: #26acda;
  color: #ffffff;
}
.ChangeCardModal .modal-content-footer .save:disabled {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .ChangeCardModal .modal-content-body .Input-control .view-img {
    top: calc(100% - 1.8rem);
  }
  .ChangeCardModal .modal-content-footer {
    padding: 23px 20px 33px;
  }
  .ChangeCardModal .modal-content-body,
  .ChangeCardModal .modal-content-header {
    padding: 23px 20px 13px 20px;
  }
  @supports (-webkit-touch-callout: none) {
    .ChangeCardModal .modal-content-footer .CircleButton.WhiteButton {
      padding-top: 2px;
    }
  }
}
@media (max-width: 575px) {
  .ChangeCardModal {
    width: 100%;
  }
  .ChangeCardModal .modal-content-body {
    padding: 20px;
  }
  .ChangeCardModal .modal-content-body .password-information {
    width: 100%;
  }
  .ChangeCardModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .ChangeCardModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .ChangeCardModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}
