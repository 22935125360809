.ConnectSocial .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.ConnectSocial .refresh-btn {
  font-size: 18px;
  line-height: 1.78;
  color: #26acda;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ConnectSocial .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.ConnectSocial .list-item-inner-content {
  background-color: #f7f9f9;
  padding: 30px 15px;
  text-align: center;
  height: 100%;
}
.ConnectSocial .social-ac-info-text SPAN {
  display: inline-block;
  width: 100%;
}
.ConnectSocial .social-ac-info-text {
  display: inline-block;
  margin: 29px 0 36px;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #72787d;
}
.ConnectSocial .social-ac-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.ConnectSocial .social-ac-list-item {
  width: 25%;
  padding: 0 15px;
  margin-bottom: 20px;
}
.ConnectSocial .social-ac-list-item .logo {
  margin: 20px 0;
}
.ConnectSocial .social-ac-list-item .logo IMG {
  width: 42px;
}
.ConnectSocial .social-ac-list-item .name {
  font-family: 'Calibre Medium';
  color: #013648;
  margin: 15px 0 21px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  text-align: center;
  color: #013648;
}
.ConnectSocial .social-ac-list-item .status {
  font-size: 14px;
  color: #26acda;
  margin-bottom: 28px;
  font-size: 16px;
  letter-spacing: -0.2px;
}
.ConnectSocial .social-ac-list-item .status.not-connected {
  color: #e85d72;
}
.ConnectSocial .social-ac-list-item BUTTON {
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 7px 25px 3px;
  border-radius: 2px;
}
.ConnectSocial .social-ac-list-item BUTTON.disconnect {
  background-color: #e7ebeb;
  color: #868b90;
}
.ConnectSocial .send-review-request {
  border-radius: 2px;
  background-color: #26acda;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  border: 0;
  padding: 5px 15px 5px;
  cursor: pointer;
  outline: 0;
  margin-right: 15px;
}
.mb-1 {
  margin-bottom: 10px;
}
@media (max-width: 1200px) {
  .ConnectSocial .social-ac-list-item {
    width: 33%;
  }
}
@media (max-width: 1024px) {
  .ConnectSocial .social-ac-list-item .name {
    font-size: 14px;
  }
  .ConnectSocial .social-ac-list-item button {
    padding: 8px 10px 2px;
  }
  .ConnectSocial .social-ac-list-item {
    padding: 0 8px;
  }
  .ConnectSocial .social-ac-list {
    margin: 0 -8px;
  }
}
@media (max-width: 767px) {
  .ConnectSocial .social-ac-list-item {
    width: 50%;
  }
  .ConnectSocial .title {
    font-size: 20px;
  }
  .ConnectSocial .refresh-btn {
    font-size: 14px;
  }
  .ConnectSocial .heading {
    flex-wrap: wrap;
  }
  .ConnectSocial .heading SPAN {
    width: 100%;
    text-align: right;
    margin-top: 5px;
  }
}
@media (max-width: 414px) {
  .ConnectSocial .social-ac-list-item {
    width: 100%;
  }
  .ConnectSocial .social-ac-list {
    margin: 0 -8px;
  }
  .ConnectSocial .refresh-btn {
    font-size: 13px;
  }
}
