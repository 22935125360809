.NextVideoAppointment .upcoming-appoinment {
  padding: 16px 25px 42px 30px;
  border-top: 1px solid #f1f1f5;
}
.NextVideoAppointment .no-room-txt {
  float: right;
}

@media (max-width: 1199px) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 10px 34px 20px 24px;
  }
}

@media (max-width: 767px) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 13px 30px 20px;
  }
}

@media (max-width: 413px) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 13px 15px 20px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 10px 34px 10px 24px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .NextVideoAppointment .upcoming-appoinment {
    padding: 10px 30px;
  }
}
