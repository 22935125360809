.Terms {
  padding: 131px 0;
}
.Terms .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.Terms .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.Terms .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.Terms .title-txt {
  margin-bottom: 37px;
}
.Terms .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 14px;
}
.Terms .text-bold {
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-top: 28px;
}
.Terms .back-yellow {
  background-color: yellow;
}
.Terms ol {
  list-style-position: outside;
}
.Terms .new-section {
  margin-bottom: 25px;
  margin-top: 75px;
}

@media screen and (max-width: 1199px) {
  .Terms {
    padding: 90px 0;
  }
  .Terms .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .Terms {
    padding: 70px 0;
  }
  .Terms .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .Terms .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .Terms .title-txt {
    margin-bottom: 27px;
  }
  .Terms .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .Terms {
    padding: 50px 0;
  }
  .Terms .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .Terms .sub-header-title {
    margin-bottom: 15px;
  }
  .Terms .title-txt {
    margin-bottom: 20px;
  }
  .Terms .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
