.ActiveWatcher .modal-content-header {
  height: 60px;
}
.ActiveWatcher .modal-content-body {
  padding-bottom: 0;
}
.ActiveWatcher p {
  color: #013648;
}
.ActiveWatcher p.text {
  font-size: 16px;
  margin-top: 0;
}

.ActiveWatcher p.text #timer {
  font-size: 18px;
  font-weight: bold;
}
