.ReviewTab .tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin: 0;
}
.ReviewTab .no-desingn {
  text-align: center;
  font-size: 25px;
}
.ReviewTab .review-list {
  padding: 40px 0 0;
}
.ReviewTab .tab-list-item:nth-child(4) {
  display: none;
}
.ReviewTab .tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0px 15px;
  margin: 0px 60px 0 0;
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(1, 54, 72, 0.3);
  position: relative;
  cursor: pointer;
}
.ReviewTab .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewTab .tab-list-active {
  background-color: #fff;
  border: solid #013648;
  border-width: 0px 0px 4px 0px;
  color: rgb(1, 54, 72);
}
.ReviewTab .tab-list-item .review-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  right: -24px;
  top: 0;
}
.ReviewTab .tab-list-active .review-count {
  color: #72787d;
}
.ReviewTab .review-sort-wrapper {
  margin-bottom: -20px;
  margin-top: -20px;
  text-align: right;
}
.ReviewTab .review-sort-wrapper SPAN {
  padding: 3px 11px 2px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #203a70;
  font-family: 'Calibre Medium';
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #013648;
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
}
.ReviewTab .review-sort-wrapper SPAN.active {
  background-color: #013648;
  color: #fff;
}
.ReviewTab .Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 3em 0;
  padding: 0;
  min-width: 600px;
  padding-bottom: 100px;
}
.ReviewTab .Rtable-row {
  width: 100%;
  display: flex;
}
.ReviewTab .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.ReviewTab .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.ReviewTab .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.ReviewTab .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.ReviewTab .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.ReviewTab .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
  word-break: break-all;
}
.ReviewTab .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}

.ReviewTab .Rtable-cell.content-cell A {
  font-size: 16px;
  line-height: 1.38;
  color: #26acda;
}
.ReviewTab .Rtable-cell.staus-cell IMG {
  width: 20px;
  height: 20px;
  margin: auto;
}
.ReviewTab .refresh-btn {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: normal;
  color: #26acda;
  margin-right: 45px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: relative;
  top: 2px;
  outline: 0;
}
.ReviewTab .btn.btn-refresh {
  width: auto;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 5px 2px;
  cursor: pointer;
  outline: 0;
}
.ReviewTab .refesh {
  text-align: right;
  padding-top: 10px;
}
.mr-1{
  margin-right: 5px;
}
@media (hover: hover) {
  .ReviewTab .review-sort-wrapper SPAN:hover {
    background-color: #013648;
    color: #fff;
  }
}

@media (max-width: 1366px) {
  .ReviewTab .tab-list-item {
    font-size: 20px;
    margin: 0px 50px 0 0;
  }
}

@media (max-width: 1200px) {
  .ReviewTab .tab-list-item {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .ReviewTab .tab-list {
    margin: 0px -10px;
  }
  .ReviewTab .tab-list-item,
  .ReviewTab .tab-list-item:first-child {
    margin-right: 50px;
    margin-left: 20px;
  }
  .ReviewTab .review-sort-wrapper {
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .ReviewTab .tab-list {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .ReviewTab .tab-list-item {
    white-space: nowrap;
  }
}
