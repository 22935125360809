.Gender {
  position: relative;
  display: inline-block;
}
.Gender .dropdown-menu {
  position: absolute;
  top: 50px;
  box-shadow: 0 0 14px 0 #d8d3d3;
  left: 0;
  border-radius: 8px;
  visibility: hidden;
  transition: all 0.3s linear;
  background-color: #fff;
  min-width: 230px;
  z-index: 20;
  padding: 10px 0 10px;
}
.Gender .dropdown-menu.show-menu {
  visibility: visible;
  opacity: 1;
}
.Gender .dropdown-menu .dropdown-option {
  display: inline-block;
  width: 100%;
  padding: 8px 20px;
  cursor: pointer;
  font-size: 18px;
  color: #013648;
  font-family: 'Calibre Medium';
}
.Gender .dropdown-menu .dropdown-option .count {
  font-family: 'Calibre Regular';
  color: rgba(1, 54, 72, 0.6);
}
.Gender .dropdown-menu .Checkbox-control {
  margin: 0;
}
.Gender .dropdown-menu .dropdown-bottom {
  padding: 8px 20px;
  border-top: 1px solid #dbdbdb;
  text-align: right;
  padding: 8px 20px 0;
}
.Gender .dropdown-menu .CircleButton.WhiteButton {
  margin-right: 0;
  color: #013648;
  line-height: 32px;
}
.Gender.selected > .CircleButton.SecondaryButton {
  background-color: #013648;
  color: #ffffff;
}
.Gender .overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

@media (max-width: 767px) {
  .Gender .dropdown-menu {
    min-width: 190px;
  }
  .Gender .dropdown-menu .CircleButton.WhiteButton {
    color: #fff;
  }
}

@media (hover: hover) {
  .Gender .dropdown-menu .CircleButton.WhiteButton:hover {
    color: #fff;
  }
}