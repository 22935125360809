.PatientPassword {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 37px 40px 40px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientPassword .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 20px;
}
.PatientPassword .password-form {
  max-width: 398px;
  width: 100%;
}
.PatientPassword .Input-control.form-group {
  margin-bottom: 27px;
}
.PatientPassword .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 4px;
}
.PatientPassword .patientPassword-button {
  margin-top: 6px;
}
.PatientPassword .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}

@media screen and (max-width: 1200px) {
  .PatientPassword {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .PatientPassword .h3 {
    font-size: 20px;
  }
}
