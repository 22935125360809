.RemoveStripeProvider {
  width: 570px;
}
.RemoveStripeProvider .modal-content-footer .CircleButton.WhiteButton {
  width: 150px;
}
.RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton{
  width: 175px;
}
.RemoveStripeProvider .modal-content-footer {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}
.RemoveStripeProvider .modal-content-footer{
  padding: 11px 15px 15px;
  border-top: .5px solid #01364824
}

@media screen and (max-width: 600px) {
  .RemoveStripeProvider {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .RemoveStripeProvider .modal-content-footer{
    flex-direction: column-reverse;
  }
  .RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton,
  .RemoveStripeProvider .modal-content-footer .CircleButton.WhiteButton {
    width: 100%;
  }
 
}