.DashboardCount {
  display: flex;
  justify-content: space-between;
  padding: 0px 0 32px;
  flex-wrap: wrap;
}
.DashboardCount .Counter-box {
  border-radius: 8px;
  background-color: #ffffff;
  text-align: center;
  padding: 22px 22px 17px;
  width: 23.5%;
  position: relative;
  margin-bottom: 10px;
}
.DashboardCount .Counter-box .count-title {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  display: inline-block;
  width: 100%;
}
.DashboardCount .Counter-box .count {
  font-size: 48px;
  text-align: center;
  color: #013648;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DashboardCount .Counter-box .count .indicater {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
  gap: 7px;
  line-height: 20px;
}
.DashboardCount .Counter-box .count .indicater.positive{
  color: #22B04C;
}
.DashboardCount .Counter-box .count .indicater.positive .shape{
  border-color: transparent transparent #22B04C;
  border-style: dashed dashed solid;
  border-width: 0 8px 8px;
  display: inline-block;
  font-size: 0;
  height: 0;
  line-height: 0;
  padding-top: 1px;
  position: relative;
  top: -1px;
  width: 0;
}
.DashboardCount .Counter-box .count .indicater.negative{
  color: #ED1C24;
  flex-direction: column-reverse;
  gap: 0;
}
.DashboardCount .Counter-box .count .indicater.negative .shape{
  border-color: #ED1C24 transparent transparent;
  border-style: solid dashed dashed;
  border-width: 8px 8px 0;
  display: inline-block;
  font-size: 0;
  height: 0;
  line-height: 0;
  padding-top: 1px;
  position: relative;
  top: -1px;
  width: 0;
}
.DashboardCount .Counter-box .count .indicater.normal{
  color: #7F7F7F;
  flex-direction: row-reverse;
  align-items: center;
  gap: 5px;
}
.DashboardCount .Counter-box .count .indicater.normal .shape{
  width: 20px;
  height: 6px;
  background: #7F7F7F;
  margin-bottom: 7px;
}
.DashboardCount .Counter-box .parcent IMG {
  width: 12px;
  margin-left: 9px;
}
.DashboardCount .Counter-box .parcent {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #199daf;
  font-family: 'Calibre Medium';
  display: inline-block;
  width: 100%;
  margin-top: 10px;
}
.DashboardCount .Counter-box .decrement {
  color: #f37184;
}
.DashboardCount .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  right: 17px;
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.DashboardCount .info-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
  line-height: 0;
}
.DashboardCount .info-wrapper:hover .info-content{
 display: block;
}
@media (max-width: 1200px) {
  .DashboardCount .Counter-box {
      width: 24%;
      padding: 22px 15px 17px;
  } 
}
@media (max-width: 1024px) {
  .DashboardCount {
    flex-wrap: wrap;
    padding-top: 0;
  }
  .DashboardCount .Counter-box {
    width: 48%;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .DashboardCount {
    flex-wrap: wrap;
    padding-top: 0;
    padding-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .DashboardCount .Counter-box {
    width: 100%;
    margin-bottom: 20px;
  }
}
