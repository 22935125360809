.RescheduleAppointmentModal {
  background-color: #fff;
  /* border: solid 1px #e2e2ea; */
  border-radius: 4px;
  overflow: hidden;
  width: 586px;
  max-width: 100%;
  margin: auto;
}
.RescheduleAppointmentModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.RescheduleAppointmentModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.RescheduleAppointmentModal .modal-content-header .close img {
  width: 100%;
}
.RescheduleAppointmentModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.RescheduleAppointmentModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 144px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.RescheduleAppointmentModal span.msg{
  color: #013648;
  font-size: 20px;
  display: block;
  margin-bottom: 5px;
  margin-top: 20px;
}
.RescheduleAppointmentModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.RescheduleAppointmentModal .Divider {
  margin-top: 10px;
}

.RescheduleAppointmentModal .Divider .container {
  width: auto;
}
.RescheduleAppointmentModal .dc-appointment-time {
  padding-top: 40px;
}
.RescheduleAppointmentModal .timing-table {
  width: 100%;
}
.RescheduleAppointmentModal .table-wrapper {
  position: relative;
  display: inline-block;
  padding: 0px 53px 0px 52px;
}
.RescheduleAppointmentModal .table-wrapper .prev-link {
  position: absolute;
  left: 0px;
  top: 3px;
  cursor: pointer;
}
.RescheduleAppointmentModal .table-wrapper .right-link {
  position: absolute;
  right: 0;
  top: -4px;
  transform: rotate(180deg);
  cursor: pointer;
}
.RescheduleAppointmentModal .table-wrapper .prev-link img,
.RescheduleAppointmentModal .table-wrapper .right-link img {
  width: 24px;
}
.RescheduleAppointmentModal .table-wrapper .prev-link.disabled img,
.RescheduleAppointmentModal .table-wrapper .right-link.disabled img {
  opacity: 0.21;
  cursor: not-allowed;
}
.RescheduleAppointmentModal .timing-table .tr {
  display: flex;
}
.RescheduleAppointmentModal .timing-table .tr {
  margin: 0px -5px;
}
.RescheduleAppointmentModal .timing-table .th {
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-bottom: 12px;
  vertical-align: top;
  text-align: center;
  width: 83px;
}
.RescheduleAppointmentModal .timing-table .th .date {
  display: block;
  font-family: 'Calibre Regular';
  font-weight: normal;
  text-align: center;
}
.RescheduleAppointmentModal .timing-table .tr .th:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .timing-table .td {
  padding: 4px 5px 4px;
}
.RescheduleAppointmentModal .timing-table .tr .td:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .timing-table .tr:last-child .td {
  padding-bottom: 0;
}
.RescheduleAppointmentModal .timing-table .td .time {
  font-size: 14px;
  line-height: 1.31;
  letter-spacing: normal;
  border-radius: 13.8px;
  padding: 6px 3px 2px;
  text-align: center;
  vertical-align: middle;
  border: 0.7px solid rgba(1, 54, 72, 0.1);
  color: #013648;
  min-width: 73px;
}
.RescheduleAppointmentModal .timing-table .td.available .time {
  color: #fff;
  border: 1px solid #013648;
  cursor: pointer;
  background-color: #013648
}
.RescheduleAppointmentModal .appointment-video .timing-table .td.available .time {
  color: #fff;
  border: 1px solid #6a3acd;
  cursor: pointer;
  background-color: #6a3acd
}
.RescheduleAppointmentModal .timing-table .td.booked .time {
  background-color: rgba(1, 54, 72, 0.1);
  border: 0.7px solid #013648;
  color: #013648;
  cursor: not-allowed;
}
.RescheduleAppointmentModal .day-table {
  width: 100%;
  border-spacing: 0;
}
.RescheduleAppointmentModal .day-table .day {
  font-size: 16.8px;
  line-height: 1.68;
  letter-spacing: -0.32px;
  text-align: center;
  color: #013648;
  width: 100%;
  height: 55px;
  background-color: rgba(1, 54, 72, 0.15);
  font-family: 'Calibre Medium';
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RescheduleAppointmentModal .day-table .td {
  padding-right: 3px;
  padding-bottom: 3px;
  width: 14.28%;
}
.RescheduleAppointmentModal .day-table .tr .td:last-child {
  padding-right: 0;
}
.RescheduleAppointmentModal .day-table .tr:last-child .td {
  padding-bottom: 0;
}
.RescheduleAppointmentModal .day-table .prev .day {
  background-color: rgba(192, 192, 192, 0.17);
  color: rgba(24, 34, 42, 0.3);
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.RescheduleAppointmentModal .appointment-inner-block {
  margin-bottom: 46px;
}
.RescheduleAppointmentModal .appointments-day-block {
  padding-top: 10px;
}
.RescheduleAppointmentModal .appointment-inner-block:last-child {
  margin-bottom: 0;
}
.RescheduleAppointmentModal .dc-appointment-time .more-time {
  margin-top: 37px;
  cursor: pointer;
}
.RescheduleAppointmentModal .dc-appointment-time .more-time span {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: right;
  color: #26acda;
}

@media (hover: hover) {
  .RescheduleAppointmentModal .timing-table .td.available .time:hover {
    background-color: rgba(1,54,72,.1);
    color: #013648;
    border: 1px solid #013648;
  }
  .RescheduleAppointmentModal .appointment-video .timing-table .td.available .time:hover{
    background-color: #fff !important;
    color: #013648;
    border: 1px solid #013648;
  }
}

@media (max-width: 767px) {
  .RescheduleAppointmentModal {
    width: 100%;
  }
  .RescheduleAppointmentModal .DoctorAppointmentTime {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .RescheduleAppointmentModal .DoctorAppointmentTime .dc-info {
    width: 100%;
    padding-left: 0;
    margin-bottom: 20px;
  }
  .RescheduleAppointmentModal .appointment-inner-block {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .RescheduleAppointmentModal .modal-content-header {
    padding-left: 20px;
  }
  .RescheduleAppointmentModal .modal-content-body {
    padding: 29px 20px;
  }
  .RescheduleAppointmentModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .RescheduleAppointmentModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .RescheduleAppointmentModal .modal-content-body {
    height: calc(100vh - 200px);
  }
  /* .RescheduleAppointmentModal .timing-table .td.available .time {
    background-color: #013648;
    border: 0px;
    color: #fff;
  } */
}
