.PatientSignUp {
  padding: 120px 0;
  background-color: #fbfbf9;
}
.PatientSignUp .ca-container {
  max-width: 428px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.PatientSignUp .h3 {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 26px;
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  font-weight: normal;
}

@media (max-width: 1023px) {
  .PatientSignUp {
    padding: 90px 0;
  }
  .PatientSignUp .h3 {
    font-size: 30px;
    margin-bottom: 40px;
    padding-bottom: 14px;
  }
}

@media (max-width: 767px) {
  .PatientSignUp {
    padding: 70px 0;
  }
  .PatientSignUp .h3 {
    font-size: 28px;
    margin-bottom: 35px;
    padding-bottom: 12px;
  }
}
