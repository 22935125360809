.BillingTab {
  position: relative;
}
.BillingTab .UILabel {
  z-index: unset;
  right: 0;
  top: -63px;
  position: absolute;
}
.BillingTab .row-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}
.BillingTab .inner-box-content {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 25px 23px 25px 37px;
}
.BillingTab .hlaf-box-content {
  width: 48%;
}
.BillingTab .full-box-content {
  width: 100%;
}
.BillingTab .provider-content .provider-title,
.BillingTab .payment-content .payment-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 11px;
  align-items: center;
}
.BillingTab .ChangeSubscriptionDiv{
  display: flex;
  flex-direction: column;
  justify-content: center;
gap: 5px;
}
.BillingTab .ChangeSubscriptionDiv .cancel-sub{
  width: 160px;
}
.BillingTab .ChangeSubscriptionDiv .cancel-sub.change-Subscription{
  background-color: #198754;
  color: #fff;
}
.BillingTab .ChangeSubscriptionDiv .cancel-sub.change-Subscription:hover{
  background-color: #013648;
}
.BillingTab .payment-content .payment-title BUTTON {
  cursor: pointer;
}
.BillingTab .provider-content .totalprovider {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  color: #013648;
}
.BillingTab .provider-content .daysleft {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3d93a6;
  min-width: 152px;
  height: 30px;
  padding: 4px 6px;
  background-color: rgba(61, 147, 166, 0.1);
}
.BillingTab .provider-content .description,
.BillingTab .payment-content .description {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #72787d;
}
.BillingTab .provider-content .description SPAN {
  color: #013648;
  font-family: 'Calibre Medium';
}
.BillingTab .provider-content .reconcile SPAN.linkspan,
.BillingTab .provider-content .description SPAN.linkspan {
  font-family: 'Calibre Regular';
  color: #26acda;
  cursor: pointer;
}
.BillingTab .provider-content .reconcile {
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: #72787d;
  display: flex;
  padding-top: 20px;
}
.BillingTab .provider-content .reconcile p {
  margin: 0;
  padding-right: 5px;
}
.BillingTab .provider-content .SubscribeBtn,
.BillingTab .provider-content .reconcile .Reconcilebtn {
  float: right;
  padding: 1px 18px;
  border: 0;
  background-color: #013648;
  color: #fff;
  font-family: 'Calibre Medium';
  letter-spacing: -0.2px;
  text-align: center;
  padding-top: 6px;
  border-radius: 2px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  font-size: 16px;
  cursor: pointer;
    font-family: 'Calibre Medium';
}
.BillingTab .provider-content .SubscribeBtn:hover
.BillingTab .provider-content .reconcile .Reconcilebtn:hover{
  background-color: #fff;
  color: #013648;
  border: 1px solid #013648;
}
.BillingTab .payment-content .paymentheading {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #013648;
}
.BillingTab .payment-content .descriotion {
  line-height: 1.44;
}
.BillingTab .payment-content BUTTON {
  width: 115px;
  height: 32px;
  border-radius: 2px;
  background-color: #26acda;
  border: 0;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #ffffff;
  outline: 0;
  padding: 8px 0px 9px;
}
.BillingTab .invoice-content .heading {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
  border-bottom: 1px solid #f1f1f5;
  display: flex;
  justify-content: space-between;
}

/* Table content */

.BillingTab .InvoiceDetail {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.BillingTab .InvoiceDetail .date-cell {
  width: 20%;
}
.BillingTab .InvoiceDetail .payment-cell {
  width: 15%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .description-cell {
  width: 20%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .price-cell {
  width: 15%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .status-cell {
  width: 20%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .action-cell {
  width: 10%;
  padding-left: 10px;
}
.BillingTab .InvoiceDetail .Rtable {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  min-width: 600px;
}
.BillingTab .InvoiceDetail .Rtable-row {
  width: 100%;
  display: flex;
}
.BillingTab .InvoiceDetail .Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  list-style: none;
}
.BillingTab .InvoiceDetail .Rtable-cell.column-heading {
  font-size: 18px;
  line-height: 1.78;
  color: #72787d;
  padding-bottom: 10px;
}
.BillingTab .InvoiceDetail .Rtable-cell--content A {
  font-size: 2em;
  color: #333;
}
.BillingTab .InvoiceDetail .Rtable-cell--content .webinar-date {
  font-weight: 700;
}
.BillingTab .InvoiceDetail .Rtable-row.Rtable-row--head {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.BillingTab .InvoiceDetail .Rtable-cell.content-cell {
  font-size: 18px;
  line-height: 0.8;
  letter-spacing: -0.23px;
  color: #013648;
  min-height: 68px;
  display: flex;
  align-items: center;
}
.BillingTab .InvoiceDetail .Rtable-row:not(:last-child) {
  border-bottom: 2px solid rgba(143, 146, 161, 0.2);
}
.BillingTab .InvoiceDetail .Rtable-cell BUTTON {
  width: 81px;
  height: 32px;
  border-radius: 2px;
  background-color: #f1f1f1;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
  outline: 0;
  border: 0;
  padding: 8px 0px 9px;
  cursor: pointer;
}
.BillingTab .cancel-sub {
  width: 144px;
  padding: 2px 10px;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  padding-top: 9px;
  background-color: orange;
  border: 0;
  color: #013648;
  box-shadow: none;
  outline: 0;
  border-radius: 2px;
  cursor: pointer;
}
.BillingTab .cancel-sub:hover {
  background-color: #013648;
  color: #fff;
}
@media (max-width: 1200px) {
  .BillingTab .provider-content .daysleft {
    min-width: 120px;
    width: auto;
  }
  .BillingTab .provider-content .totalprovider {
    font-size: 24px;
  }
}
@media (max-width: 1024px) {
  .BillingTab .InvoiceDetail .Rtable-cell.content-cell,
  .BillingTab .InvoiceDetail .Rtable-cell.column-heading {
    font-size: 16px;
  }
  .BillingTab .InvoiceDetail .status-cell {
    width: 10%;
  }
  .BillingTab .InvoiceDetail .description-cell {
    width: 30%;
  }
  .BillingTab .row-content {
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
  .BillingTab .hlaf-box-content {
    width: 100%;
    margin-bottom: 18px;
  }
}
@media (max-width: 1024px) {
  .BillingTab .inner-box-content {
    padding: 15px 10px;
  }
}
