.EditLanguages {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.EditLanguages .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditLanguages .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditLanguages .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditLanguages .cross-icon img {
  width: 100%;
}
.EditLanguages .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditLanguages .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditLanguages .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditLanguages .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditLanguages .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditLanguages .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditLanguages .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditLanguages .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditLanguages .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.EditLanguages .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.EditLanguages .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.EditLanguages .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.EditLanguages .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.EditLanguages .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.EditLanguages .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.EditLanguages .input-close {
  position: relative;
}
.EditLanguages .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.EditLanguages .img-preview {
  max-width: 100%;
}
.EditLanguages .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
.EditLanguages .fileupload {
  position: relative;
  margin-bottom: 5px;
}
.EditLanguages .fileupload .fileupload-btn button {
  height: 48px;
  border-radius: 24px;
  border: 0;
  width: 161px;
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  background-color: #26acda;
  color: #fff;
}
.EditLanguages .fileupload .fileupload-btn button:focus {
  outline: 0;
}
.EditLanguages .fileupload .fileupload-btn {
  display: block;
  cursor: pointer;
}
.EditLanguages .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  cursor: pointer;
  z-index: 1;
}

@media (hover: hover) {
  .EditLanguages .fileupload .fileupload-btn:hover button {
    opacity: 0.7;
  }
}

@media screen and (max-width: 1366px) {
  .EditLanguages .fileupload .fileupload-btn button {
    width: 148px;
  }
}
@media screen and (max-width: 1200px) {
  .EditLanguages .fileupload .fileupload-btn button {
    width: 140px;
  }
}

@supports (-webkit-touch-callout: none) {
  .EditLanguages .fileupload .fileupload-btn button {
    padding-top: 2px;
  }
}
