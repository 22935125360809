.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #000000d9;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: absolute;
  top: 0;
  right: 100%;
  z-index: 1030;
  font-weight: 400;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 232px;
  
}

.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  box-shadow: 0 0 8px #00000026;
  clip-path: polygon(0% 0%,95% 0%,95% 50%,100% 55%,95% 60%,95% 60%,95% 100%,0% 100%);
  background-color: #f3f3fb;
 
}
.shadow{
  position: absolute;
  z-index: -1;
  content: "";
  background: rgb(0 0 0 / 12%);
  width: 102%;
  height: 104%;
  left: -1px;
  top: 0px;
  clip-path: polygon(0% 0%,95% 0%,95% 50%,100% 55%,95% 60%,95% 60%,95% 100%,0% 100%);
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: #000000d9;
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #000000d9;
  font-size: 14px;
}

.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
} 
.ant-popover-buttons button {
  margin-left: 8px !important;
  cursor: pointer;
}
.ant-btn-sm {
  height: 24px;
  padding: 0 7px;
  font-size: 14px;
  border-radius: 2px;
}
.ant-btn>span {
  display: inline-block;
}
.show{
  display: inline-block;
}
.hide{
  display: none;
}
.delete-confirm-img{
  position: absolute;
  width: 40px;
  top: 20px;
  cursor: pointer;
}
.ant-btn-primary {
  color: #fff;
  border-color: #3d93a6;
  background: #3d93a6;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px #0000000b;
}
.ant-btn-danger {
  border-color: #f37184;
  background: #f37184;
  color: #fff;
}