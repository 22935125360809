.PatientRegistration {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 20px 40px 40px;
  margin-bottom: 40px;
}
.PatientRegistration .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 39px;
}
.PatientRegistration .label-content {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
}
.PatientRegistration .label-content span {
  margin-right: 10px;
}
.PatientRegistration .label-content .uploadfile-text {
  opacity: 0.4;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  font-family: 'Calibre Regular';
  font-weight: normal;
}
.PatientRegistration .fileupload {
  min-height: 52px;
  border-radius: 2px;
  background-color: #f7f9f9;
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  margin-top: 14px;
  padding-right: 142px;
  word-break: break-all;
}
.PatientRegistration .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 4px;
}
.PatientRegistration .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}
.PatientRegistration .fileupload .fileupload-btn input {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  z-index: 1;
}
.PatientRegistration-inner .removebtn {
  float: right;
  display: inline-block;
  color: #f37184;
  cursor: pointer;
}
@media screen and (max-width: 1366px) {
  .PatientRegistration {
    padding: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .PatientRegistration .h3 {
    font-size: 20px;
  }
}

@media screen and (max-width: 413px) {
  .PatientRegistration {
    padding: 10px;
  }
}
