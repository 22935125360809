.InviteMultipleProvider-maindiv {
  display: grid;
  grid-template-columns:1fr;
  border-bottom: 1px solid #c0bdbd;
  margin-bottom: 20px;
}
.InviteMultipleProvider-maindiv:last-child{
  border-bottom: none;
}
.InviteMultipleProvider-maindiv .input-div {
  padding-right: 5px;
}
.InviteMultipleProvider-maindiv .action-div {
  position: relative;
}
.InviteMultipleProvider-maindiv .action-btn {
  position: absolute;
  bottom: -10px;
  color: #d8d8d8;
  background-color: #013648;
  border: none;
  cursor: pointer;
  right: 0;
  border-radius: 5px;
  padding: 5px 10px;
}

.InviteMultipleProvider-maindiv .action-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
span.count-Indicater {
  border: 1px solid #999;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: block;
  text-align: center;
  padding-top: 3px;
  background-color: #013648;
  color: #fff;
}
.InviteMultipleProvider-maindiv-parent{
  position: relative;
}
.InviteMultipleProvider-maindiv-parent .dailog-close-btn{
  position: absolute;
  right: 0;
  top: -3px;
  border: none;
  width: 38px;
  height: 38px;
  background: none;
  cursor: pointer;
}
@media (max-width: 400px) {
  .InviteYourProviders .Input-control .form-control{
    font-size: 14px;
  }
}