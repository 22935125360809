@font-face {
  font-family: 'HK Grotesk SemiBold';
  src: url('../src/static/fonts/HKGrotesk-SemiBold.eot');
  src: url('../src/static/fonts/HKGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
    url('../src/static/fonts/HKGrotesk-SemiBold.woff') format('woff'),
    url('../src/static/fonts/HKGrotesk-SemiBold.ttf') format('truetype'),
    url('../src/static/fonts/HKGrotesk-SemiBold.svg#HKGrotesk-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Medium';
  src: url('../src/static/fonts/Calibre-Medium.eot');
  src: url('../src/static/fonts/Calibre-Medium.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/Calibre-Medium.woff2') format('woff2'),
    url('../src/static/fonts/Calibre-Medium.woff') format('woff'),
    url('../src/static/fonts/Calibre-Medium.ttf') format('truetype'),
    url('../src/static/fonts/Calibre-Medium.svg#Calibre-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre Light';
  src: url('../src/static/fonts/Calibre-Light.eot');
  src: url('../src/static/fonts/Calibre-Light.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/Calibre-Light.woff2') format('woff2'),
    url('../src/static/fonts/Calibre-Light.woff') format('woff'),
    url('../src/static/fonts/Calibre-Light.ttf') format('truetype'),
    url('../src/static/fonts/Calibre-Light.svg#Calibre-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre Bold';
  src: url('../src/static/fonts/Calibre-Bold.eot');
  src: url('../src/static/fonts/Calibre-Bold.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/Calibre-Bold.woff2') format('woff2'),
    url('../src/static/fonts/Calibre-Bold.woff') format('woff'),
    url('../src/static/fonts/Calibre-Bold.ttf') format('truetype'),
    url('../src/static/fonts/Calibre-Bold.svg#Calibre-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre Semibold';
  src: url('../src/static/fonts/Calibre-Semibold.eot');
  src: url('../src/static/fonts/Calibre-Semibold.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/Calibre-Semibold.woff2') format('woff2'),
    url('../src/static/fonts/Calibre-Semibold.woff') format('woff'),
    url('../src/static/fonts/Calibre-Semibold.ttf') format('truetype'),
    url('../src/static/fonts/Calibre-Semibold.svg#Calibre-Semibold') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Calibre Regular';
  src: url('../src/static/fonts/Calibre-Regular.eot');
  src: url('../src/static/fonts/Calibre-Regular.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/Calibre-Regular.woff2') format('woff2'),
    url('../src/static/fonts/Calibre-Regular.woff') format('woff'),
    url('../src/static/fonts/Calibre-Regular.ttf') format('truetype'),
    url('../src/static/fonts/Calibre-Regular.svg#Calibre-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Heldane Display';
  src: url('../src/static/fonts/HeldaneDisplay-Regular.eot');
  src: url('../src/static/fonts/HeldaneDisplay-Regular.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/HeldaneDisplay-Regular.woff2') format('woff2'),
    url('../src/static/fonts/HeldaneDisplay-Regular.woff') format('woff'),
    url('../src/static/fonts/HeldaneDisplay-Regular.ttf') format('truetype'),
    url('../src/static/fonts/HeldaneDisplay-Regular.svg#HeldaneDisplay-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'TT Commons';
  src: url('../src/static/fonts/TTCommons-Regular.eot');
  src: url('../src/static/fonts/TTCommons-Regular.eot?#iefix') format('embedded-opentype'),
    url('../src/static/fonts/TTCommons-Regular.woff2') format('woff2'),
    url('../src/static/fonts/TTCommons-Regular.woff') format('woff'),
    url('../src/static/fonts/TTCommons-Regular.ttf') format('truetype'),
    url('../src/static/fonts/TTCommons-Regular.svg#TTCommons-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html,
body {
  height: 100%;
  margin: 0;
  border: 0;
  font-family: 'Calibre Regular', sans-serif;
  font-size: 14px;
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}
/* thin scrollbar */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  position: relative;
  right: 15px;
  padding-right: 15px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0);
}
img{
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a6a6a6e0;
  max-height: 100px;
}
a,
a:focus {
  outline: none;
  text-decoration: none;
}
/*css Clearfix */
.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  *zoom: 1;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-cap {
  text-transform: capitalize;
}
.container {
  margin: auto;
}
.no-select {
  /*  disable text selection */
  /*  URL : https://stackoverflow.com/a/4407335/7160641 */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
/* For edge,remove X icon */
input::-ms-clear {
  display: none;
}
input[type='password']::-ms-reveal,
 input[type='password']::-ms-clear  {
  display: none;
}
/* cursor pointer for input type file */
input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}
.no-scroll {
  overflow: hidden;
}

@media (hover: hover) {
  a:hover {
    outline: none;
    text-decoration: none;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0 30px;
  }
}
