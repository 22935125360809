.ShareReviews {
  width: 100%;
}
.ShareReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.ShareReviews .ShareReviews-content {
  padding: 0 40px;
}
.ShareReviews .box-panel {
  padding: 40px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
}
.ShareReviews .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.ShareReviews .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.ShareReviews .Widgets-form .Select-control {
  display: flex;
}
.ShareReviews .Widgets-control .text-info {
  text-align: center;
  margin-bottom: 19px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  color: #a2a2a2;
}
.ShareReviews .Select-control .label {
  min-width: 80px;
  padding-top: 10px;
  font-size: 16px;
  color: #013648;
}
.ShareReviews .Widgets-control {
  width: 33%;
  padding: 0 15px;
}
.ShareReviews .Widgets-control .Select-control.form-group:after {
  display: none;
}
.ShareReviews .Widgets-control select {
  border-radius: 5px;
}
.ShareReviews .Widgets-control .Select-control .arrow {
  top: 18px;
  border-width: 6px;
  border-top-color: rgba(1, 54, 72, 0.4);
  right: 16px;
}
.ShareReviews .box-inner-content {
  padding-top: 30px;
}
.ShareReviews .Widgets-section {
  display: flex;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 30px;
  margin: 0 -15px;
}
.ShareReviews .widget-preview {
  width: 270px;
  height: 180px;
  background-color: #026ca2;
  margin: auto;
}
.ShareReviews .dimantion-text {
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
  color: #474747;
}
.ShareReviews .widget-preview {
  padding: 15px 20px;
  position: relative;
}
.ShareReviews .widget-preview .preview-text {
  font-size: 28px;
  color: #fff;
  font-family: 'Calibre Light';
  padding-right: 60px;
}
.ShareReviews .widget-preview .preview-text SPAN {
  display: inline-block;
  width: 100%;
}
.ShareReviews .widget-preview .star {
  display: inline-flex;
  padding-right: 25px;
  position: relative;
}
.ShareReviews .widget-preview .star IMG {
  width: 12px;
}
.ShareReviews .widget-preview .star > SPAN {
  display: inline-block;
  margin-right: 7px;
}
.ShareReviews .widget-preview .star > .rating {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #ffffff;
}
.ShareReviews .widget-preview .certificate-img {
  width: 50px;
  position: absolute;
  right: 25px;
  top: 0;
}
.ShareReviews .Widgets-info {
  width: 600px;
  max-width: 100%;
}
.ShareReviews .Widgets-info .heading-title {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.18px;
  color: #013648;
}
.ShareReviews .Widgets-info {
  padding-top: 26px;
}
.ShareReviews .Widgets-info .copy-link {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.ShareReviews .Textarea.form-group .form-control:focus,
.ShareReviews .Textarea.form-group .form-control:not(:placeholder-shown) {
  border: 0;
  outline: 0;
}
.ShareReviews .widget-textarea {
  background-color: #f7f9f9;
  color: #72787d;
  padding: 10px 10px 22px 13px;
  margin-top: 10px;
  margin-bottom: 13px;
}
.ShareReviews .widget-textarea TEXTAREA {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #72787d;
  background-color: #f7f9f9;
  height: 78px;
}
.ShareReviews .Widgets-control .Select-control.form-group SELECT {
  height: 40px;
  padding: 8px 12px 8px 22px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
}
.ShareReviews .Widgets-control .Select-control {
  margin-bottom: 12px;
}
.ShareReviews .custom-photoshop-picker {
  margin: auto;
}
@media (max-width: 1400px) {
  .ShareReviews .box-panle {
    padding: 40px 20px;
  }
}

@media (max-width: 1200px) {
  .ShareReviews .main-section-content {
    padding: 0 20px 0 20px;
  }
  .ShareReviews .Widgets-section {
    flex-wrap: wrap;
  }
  .ShareReviews .Widgets-control {
    width: 50%;
    margin-bottom: 15px;
  }
  .ShareReviews .Widgets-control:last-child {
    padding-left: 80px;
  }
}

@media (max-width: 1024px) {
  .ShareReviews .main-section-content {
    padding: 0 15px 0;
  }
  .ShareReviews .ShareReviews-content {
    padding: 0px 15px;
  }
  .ShareReviews .box-panel {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .ShareReviews .box-panle {
    padding: 20px;
  }
  .ShareReviews {
    width: 100%;
  }
  .ShareReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .ShareReviews .Widgets-control {
    width: 100%;
  }
  .ShareReviews .Widgets-control:last-child {
    padding-left: 15px;
  }
  .ShareReviews .Select-control .label {
    width: 100%;
  }
  .ShareReviews .Widgets-form .Select-control {
    flex-wrap: wrap;
  }
  .ShareReviews .Widgets-control .Select-control .arrow {
    top: 52px;
  }
}
