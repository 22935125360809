.ProvidersTab {
  padding: 44px 40px 56px 37px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  max-width: 100%;
  width: 100%;
}
.ProvidersTab .h3 {
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 30px;
  display: inline-block;
}
.ProvidersTab .provider-list {
  display: flex;
  flex-wrap: wrap;
}
.ProvidersTab .active-providers .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Calibre Regular';
  font-weight: normal;
  margin-bottom: 25px;
  border-bottom: 1px solid #f1f1f5;
  display: block;
  padding-bottom: 7px;
}
.ProvidersTab .active-providers .h3 .sub-heading {
  position: relative;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #b2bcc4;
  padding-left: 29px;
  padding-right: 24px;
}
.ProvidersTab .active-providers .h3 .sub-heading:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 7px;
  width: 5px;
  height: 5px;
  background-color: rgba(114, 120, 125, 0.3);
  border-radius: 100%;
}
.ProvidersTab .active-providers .h3 .update-txt {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  color: #26acda;
  cursor: pointer;
}
.ProvidersTab .add-provider-content {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.ProvidersTab .add-provider-content .add-provider {
  width: 100%;
  margin-bottom: 12px;
  cursor: pointer;
}
.ProvidersTab .add-provider-content .add-provider:last-child {
  margin-bottom: 0;
}
.ProvidersTab .add-provider-content .add-provider .add-provider-inner {
  height: 76px;
  border-radius: 8px;
  border: dashed 1px rgba(1, 54, 72, 0.5);
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #b2bcc4;
  padding: 5px 23px;
}
.ProvidersTab .add-provider-content .add-provider .plus-icon {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: rgba(1, 54, 72, 0.2);
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ProvidersTab .add-provider-content .add-provider .plus-icon img {
  width: 12px;
  height: 12px;
  transform: rotate(45deg);
}
.ProvidersTab .providers-content {
  max-height: 626px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

@media screen and (max-width: 1366px) {
  .ProvidersTab {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) {
  .ProvidersTab .active-providers .h3,
  .ProvidersTab .active-providers .h3 .sub-heading {
    font-size: 18px;
  }
  .ProvidersTab .providers-content {
    max-height: none;
  }
}

@media screen and (max-width: 413px) {
  .ProvidersTab {
    padding: 10px;
  }
}
