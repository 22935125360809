.ChangePassword{
  width: 100%;
}
.ChangePassword .dashboard-panle {

  padding: 35px 30px 138px 40px;
  width: 100%;
  max-width: 100%;
}
.ChangePassword .password-form {
  width: 612px;
  background-color: #ffffff;
  border: solid 1px #e2e2ea;
  padding: 35px 30px 138px 40px;
  width: 620px;
  max-width: 100%;
}
.ChangePassword .password-form .h3 {
  margin-bottom: 24px;
}
.ChangePassword .email-form .h3 {
  margin: 0 0 24px;
}
.ChangePassword .password-form .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 8px;
}
.ChangePassword .password-form .Input-control.form-group {
  margin-bottom: 22px;
}
.ChangePassword .password-form .save-btn  button{
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  letter-spacing: normal;
  margin-right: 34px;
  line-height: 32px;
}
