.viewrequest{
  display: flex;
  gap: 10px;
  padding: 20px 36px;
  background-color: #999;
  margin-top: 10px;
  margin-bottom: 15px;
}
.viewmaindiv .viewrequest{
  background-color: white;
}
/* .viewmaindiv .viewrequest .rightpanel,
.viewmaindiv .viewrequest .leftpanel {
  flex: 1
} */
.viewmaindiv  .viewrequest .leftpanel{
  width: 40%;
}
.viewmaindiv  .viewrequest .rightpanel{
  width: 50%;
}
.viewrequest .leftpanel{
  display: flex;
  flex-direction: column;
  gap: 15px;
padding-right: 30px;
/* width: 40%; */
}

.viewrequest .rightpanel{
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  border-left: 1px solid #fff;
  gap: 15px;
}
.viewmaindiv .viewrequest .rightpanel{
  border-left: 1px solid #000;
}
.viewrequest .eachitem {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.viewmaindiv .viewrequest .eachitem{
  color: #000;
}
.viewrequest .eachitem .title{
  font-weight: bold;
  font-size: 20px;
  
}
.viewrequest .imgtextdisplay{
  display: flex;
  gap: 5px;
  align-items: center;
}
.viewrequest .imgtextdisplay span{
margin-top: 5px;
}
.viewrequest .imgtextdisplay img{
width: 20px;
filter: invert(100%) sepia(9%) saturate(1783%) hue-rotate(294deg) brightness(109%) contrast(108%);
}
.viewmaindiv .viewrequest .imgtextdisplay img{
  width: 20px;
  filter: invert(0%) sepia(4%) saturate(16%) hue-rotate(68deg) brightness(105%) contrast(103%);
  }
  .PracticeNote{
    white-space: pre-line;
   margin-top: 0;
  }