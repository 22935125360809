.ProviderReviews {
  width: 100%;
}
.ProviderReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.ProviderReviews .box-panle .Select-control {
  margin-bottom: 0;
  margin-right: 18px;
}
.ProviderReviews .box-panle .Select-control SELECT {
  height: 40px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  padding-right: 27px;
  padding-left: 22px;
  width: 200px;
  color: #44444f;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.11px;
}
.ProviderReviews .box-panle .Select-control .arrow {
  border: 7px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-left-width: 5px;
  border-right-width: 5px;
  top: 17px;
  right: 16px;
}
.ProviderReviews .ReviewTabs .tab-list {
  padding-right: 520px;
}
.ProviderReviews .box-panle .search-review-list {
  position: absolute;
  right: 30px;
  top: 37px;
  display: flex;
  align-items: center;
}
.ProviderReviews .box-panle .search-review-list .Input-control.form-group .view-img {
  right: auto;
  left: 9px;
  width: 21px;
  height: 17px;
  top: 19px;
}
.ProviderReviews .search-review-list .Input-control.form-group INPUT::-webkit-input-placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.ProviderReviews .search-review-list .Input-control.form-group INPUT:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0.5;
  font-size: 16px;
  line-height: 38px;
  color: #013648;
}
.ProviderReviews .search-review-list .Input-control.form-group INPUT::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0.5;
  font-size: 16px;
  line-height: 38px;
  color: #013648;
}
.ProviderReviews .search-review-list .Input-control.form-group INPUT:-ms-input-placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
}
.ProviderReviews .search-review-list .Input-control.form-group INPUT::placeholder {
  opacity: 0.5;
  font-size: 16px;
  line-height: 36px;
  color: #013648;
}
.ProviderReviews .box-panle .search-review-list .Input-control {
  width: 300px;
  height: 38px;
  margin-bottom: 0;
}
.ProviderReviews .box-panle .search-review-list INPUT {
  border-radius: 10px;
  background-color: #fafafb;
  height: 38px;
  border: solid 1px #e2e2ea;
  padding-left: 37px;
}
.ProviderReviews .box-panle .search-review-list INPUT:focus,
.ProviderReviews .box-panle .search-review-list INPUT:not(:placeholder-shown) {
  outline: 0;
  padding-left: 37px;
}
.ProviderReviews .box-panle {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 42px 30px 38px 40px;
  margin-bottom: 40px;
  position: relative;
}
.ProviderReviews .back-provider {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
  margin-top: 20px;
}
.ProviderReviews .back-provider .left-arrow {
  width: 33px;
  margin-right: 5px;
  display: block;
}
.ProviderReviews .back-provider .left-arrow img {
  width: 100%;
  height: 20px;
}

@media (max-width: 1400px) {
  .ProviderReviews .box-panle {
    padding: 40px 20px;
  }
  .ProviderReviews .box-panle .search-review-list {
    right: 20px;
  }
  .ProviderReviews .box-panle .search-review-list .Input-control {
    width: 230px;
  }
  .ProviderReviews .box-panle .Select-control {
    margin-right: 12px;
  }
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 430px;
  }
  .ProviderReviews .box-panle .Select-control SELECT {
    padding-right: 20px;
    padding-left: 15px;
    width: 180px;
  }
  .ProviderReviews .box-panle .Select-control .arrow {
    right: 8px;
  }
}

@media (max-width: 1366px) {
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 390px;
  }
  .ProviderReviews .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .ProviderReviews .box-panle .Select-control SELECT {
    width: 170px;
  }
}

@media (max-width: 1199px) {
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .ProviderReviews .main-section-content {
    padding: 0 15px 0;
  }
  .ProviderReviews .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
}

@media (max-width: 767px) {
  .ProviderReviews .ReviewTabs .tab-list {
    padding-right: 0;
  }
  .ProviderReviews .box-panle {
    padding: 20px 10px;
  }
  .ProviderReviews .box-panle .search-review-list {
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .ProviderReviews .box-panle .search-review-list {
    flex-wrap: wrap;
  }
  .ProviderReviews .box-panle .Select-control {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
  .ProviderReviews .box-panle .Select-control select {
    width: 100%;
  }
  .ProviderReviews .box-panle .search-review-list .Input-control {
    width: 100%;
  }
}
