.DoctorArticle {
  padding: 51px 0 30px;
  background-color: #fbfbf9;
}
.DoctorArticle .da-deserve-title .heading2 {
  font-family: 'Heldane Display';
  font-size: 74px;
  line-height: 0.78;
  color: #013648;
  margin-bottom: 32px;
  margin-top: 178px;
  padding-right: 100px;
  letter-spacing: -1.23px;
}
.DoctorArticle .da-deserve-title .paragaph {
  width: 544px;
  height: 188px;
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.DoctorArticle .container .da-arical-row {
  display: flex;
}

.DoctorArticle .da-arical-colum {
  width: 50%;
  position: relative;
  overflow: hidden;
}
.DoctorArticle .da-arical-colum.makingtheright {
  width: 100%;
}
.DoctorArticle .da-arical-colum .hiddenlogodiv {
  position: absolute;
  left: -35%;
  width: 513px;
  top: -104%;
  height: 643px;
  overflow: hidden;
}
.DoctorArticle .da-arical-colum .hiddenlogo {
  width: 359px;
}
.DoctorArticle .dc-artical .heading2 {
  font-family: 'Heldane Display';
  /* font-style: italic; */
  font-weight: normal;
  font-size: 28px;
  line-height: 119%;
color: #013648;
}
.DoctorArticle .dc-artical .description {
  font-family: Calibre Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 163.5%;
  color: #013648;
  width: 403px;
  margin-top: 10px;
}
.scrolling-div {
  height: 300px;
}

element.style {
}

.DoctorArticle .dc-artical .img IMG {
  width: 85%;
}
.DoctorArticle .dc-artical .img IMG.mainImg {
  width: 366px;
}
.DoctorArticle .dc-artical .img IMG.design1 {
  position: relative;
  top: -30px;
  left: 10px;
}
.DoctorArticle .dc-artical .img IMG.design2 {
  position: relative;
  top: -130px;
  left: 10px;
}
.DoctorArticle .dc-artical .img IMG.designyellow {
  position: relative;
  top: 60px;
  left: 66px;
}
.DoctorArticle .Losingyourvision {
  /* width: 325px; */
  font-family: Heldane Display;
  /* font-style: italic; */
  font-weight: normal;
  font-size: 37px;
  line-height: 119%;
  margin-left: 23px;
  margin-top: 106px;
  /* max-height: 60px; */
  margin-bottom: 35px;
  /* overflow: hidden; */
  color: #013648;
}
.DoctorArticle .para {
  /* width: 382px; */
  font-family: Calibre Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 119%;
  color: #013648;
  margin-left: 22px;
  margin-top: 0;
  overflow: hidden;
  max-height: 60px;
}
.DoctorArticle .dc-artical .img {
  position: relative;
  left: 40px;
  margin-bottom: 30px;
}
.DoctorArticle .dc-artical1 .description,
.DoctorArticle .dc-artical1 .heading2 {
  margin-left:40px;
}
.DoctorArticle .dc-artical2 {
  /* margin-top: -209px; */
}
.DoctorArticle .dc-artical1 .description {
  margin-top: 14px;
}
.DoctorArticle .dc-artical2 .img IMG {
  width: 543px;
}
.DoctorArticle .dc-artical2 .img {
  position: relative;
  left: 0;
}
.DoctorArticle .dc-artical2 .description {
  margin-top: 14px;
}
.DoctorArticle .da-arical-Powered {
  padding-top: 0;
}
.DoctorArticle .dc-artical.dc-artical3 {
  margin-top: -334px;
}
.DoctorArticle .da-arical-Powered .container {
  display: flex;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
  background-color: #fff;
  padding: 30px;
  display: flex;
  position: relative;
  left: 0px;
  top: 5px;
}
.DoctorArticle .da-arical-Powered .da-arical-left {
  width: 50%;
  /* padding-left: 20%; */
}
.DoctorArticle .da-arical-Powered .da-arical-left img.logo {
  width: 183px;
  height: 183px;
  margin-left: 57px;
  margin-top: -37px;
}
.DoctorArticle .da-arical-Powered .da-arical-left img.doctor5 {
  margin-left: 10px;
  margin-top: 89px;
}

.DoctorArticle .da-arical-Powered .da-arical-left .makerightchoice {
  font-family: Heldane Display;
  font-weight: normal;
  font-size: 22px;
  line-height: 119%;
  margin: 0;
  margin-top: 31px;
  margin-left: 10px;
  color: #013648;
}
.DoctorArticle .da-arical-Powered .da-arical-left .paraputtingyourhealth {
  font-family: Calibre Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 163.5%;
  color: #013648;
  margin-left: 10px;
  margin-top: 14px;
}
.DoctorArticle .da-arical-Powered .da-arical-right {
  width: 50%;
  padding-left: 15px;
}
.DoctorArticle .da-arical-Powered .da-arical-right img {
  width: 250px;
}
.DoctorArticle .da-arical-Powered .da-arical-right.logoimgdiv {
  padding-top: 150px;
  padding-left: 50px;

}
.DoctorArticle .da-arical-Powered .da-arical-right .img {
  left: 0;
  margin-bottom: 35px;
  width: 423px;
  height: 247px;
  background: #ffffff;
  border: 1px solid rgba(162,168,174,.55);
  box-sizing: border-box;
  border-radius: 8px;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left,
.da-arical-Powered .da-arical-right .img .patients-result .right {
  width: 50%;
}
.DoctorArticle .patients-result .right {
  padding-top: 0px;
}
.DoctorArticle .da-arical-Powered .review .star {
  width: 61px;
  height: 58px;
  position: relative;
  top: 39px;
  left: 4px;
}
.DoctorArticle .patients-result .left .google-logo {
  width: 110px;
  margin-top: 62px;
  margin-left: 22px;
}
.DoctorArticle .da-arical-Powered .review .review-rate {
  font-family: 'Heldane Display';
  font-size: 70px;
  line-height: 0.77;
  letter-spacing: -1.92px;
  color: #013648;
  position: relative;
  top: 35px;
  left: -11px;
}
.DoctorArticle .patients-result .patients-treated .h3 {
  font-size: 14px;
  line-height: 0.95;
  color: #013648;
  margin-bottom: 11px;
}
.DoctorArticle .patients-result .patients-treated-btn {
  display: inline-block;
  margin-bottom: 18px;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN {
  display: inline-block;
  margin-right: 3px;
  width: 51px;
  height: 13px;
  border-radius: 2px;
  background-color: #3d93a6;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN.sciatica {
  background-color: #e85d72;
  width: 45px;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN.herniated {
  background-color: #8d77ba;
  width: 31px;
}
.DoctorArticle .patients-result .patients-treated-btn SPAN.scoliosis {
  background-color: #f2962d;
  width: 38px;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 1.7;
  letter-spacing: -0.38px;
  color: #1aa2b4;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN.spansciatica {
  color: #e85d72;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN.herniated {
  color: #8d77ba;
}
.DoctorArticle .patients-result .patients-treated-percent SPAN.scoliosis {
  color: #f2962d;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
  width: 314px;
  position: absolute;
  right: -49px;
  top: -36px;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
  width: 277px;
  left: -99px;
  position: absolute;
  top: -12px;
  z-index: 1;
}

.DoctorArticle .dc-artical2 .description {
  padding-right: 30px;
}

.Losingyourvision.looping-animation-Q span.animation,
.para.looping-animation-A span.animation {
  animation: looping 6s ease-in-out;
}
.Losingyourvision.looping-animation-Q span.hide,
.para.looping-animation-A span.hide {
  opacity: 0;
  animation: hidding 1s ease-in-out;
}
.DoctorArticle .Losingyourvision {
  /* min-height: 80px; */
  margin-bottom: 15px;
}
.DoctorArticle .para {
  min-height: 60px;
}
@keyframes looping {
  20%,
  100% {
    opacity: 1;
    line-height: 26px;
    transform: translateY(0px);
  }

  0% {
    opacity: 0;
    line-height: 150px;
    transform: translateY(550px);
  }
}
@keyframes hidding {
  20%,
  100% {
    opacity: 0;
    transform: translateY(-10);
  }

  0% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1400px) {
  .DoctorArticle .dc-artical .img {
    right: 0;
  }
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 40px;
  }
  .DoctorArticle .dc-artical2 .img IMG {
    width: 100%;
  }
  .DoctorArticle .dc-artical .description {
    width: 100%;
  }
  .DoctorArticle .dc-artical1 .description {
    width: calc(100% - 65px);
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 0;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    padding-right: 0;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding: 30px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    right: 0;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left {
    width: 45%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .right {
    width: 55%;
  }
}

@media (max-width: 1366px) {
  .DoctorArticle .da-deserve-title .heading2 {
    font-size: 60px;
  }

  .DoctorArticle .dc-artical2 .description {
    padding-right: 0;
  }
}

@media (max-width: 1200px) {
  .DoctorArticle .dc-artical .img .design1,
  .DoctorArticle .dc-artical .img .design2,
  .DoctorArticle .dc-artical .img .designyellow {
    display: none;
  }
  .DoctorArticle .da-arical-Powered {
    padding-top: 50px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    font-size: 48px;
    margin-top: 100px;
  }

  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 40px;
  }
  .DoctorArticle .da-deserve-title .paragaph {
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
    width: 200px;
    left: -50px;
    top: 0px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    width: 245px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 54px;
    top: 32px;
    left: -16px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 70px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    width: 90px;
    margin-left: 0;
  }
  .DoctorArticle.da-arical-Powered .patients-treated .h3 {
    font-size: 16px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN {
    width: 58px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.sciatica {
    width: 40px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.herniated {
    width: 30px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.scoliosis {
    width: 30px;
  }

  .DoctorArticle .dc-artical .img IMG.mainImg {
    width: 65%;
    float: right;
  }
  /* .DoctorArticle .da-arical-colum .hiddenlogo{
    left: -74%;
    width: 134%;
  } */
}

@media (max-width: 1024px) {
  .DoctorArticle {
    padding: 70px 0 50px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    padding-right: 0;
  }
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 40px;
  }
  .DoctorArticle .dc-artical .heading2 {
    font-size: 24px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 18px;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 45px;
        text-align: left;
        width: 91%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left {
    padding-left: 0px;
    text-align: center;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left IMG {
    width: 50%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left .makingtheright IMG{
    width: 80%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
    width: 150px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    width: 150px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    padding-right: 10px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    top: -14px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 48px;
    top: 29px;
    left: -7px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left {
    width: 40%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .right {
    width: 60%;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 50px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding: 30px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN {
    width: 49px;
  }
  .DoctorArticle .Losingyourvision {
    max-height: 80px;
  }
}
@media (max-width: 997px){
.DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 36px;
        text-align: left;
        width: 91%;
  }
}
@media (max-width: 991px){
.DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv{
width: 342px;
}
}
@media (max-width: 767px) {
  .DoctorArticle .container .da-arical-row {
    flex-wrap: wrap;
    margin-bottom: 50px;
    margin: 0px -30px;
  }
  .scrolling-div{
    margin-top: 50px;
    height: 150px;
  }
 
.DoctorArticle .dc-artical.dc-artical3{
  margin: 0;
}
.DoctorArticle .da-arical-Powered .da-arical-right.logoimgdiv {
  text-align: center;
  padding: 0;
}
.DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv {
  width: 420px;
}
  .DoctorArticle .dc-artical .img IMG.mainImg {
    width: 42%;
    float: none;
  }
  .DoctorArticle .container .da-arical-row:last-child {
    margin-bottom: 0;
  }
  .DoctorArticle .da-arical-colum,
  .DoctorArticle .da-arical-colum {
    width: 100%;
  }
  .DoctorArticle {
    padding-top: 77px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    margin-left: 0;
  }
  .DoctorArticle .dc-artical2 {
    margin-top: 65px;
  }
  .DoctorArticle .dc-artical .img {
    text-align: center;
  }
  .DoctorArticle .Losingyourvision {
    /* max-height: 60px;
    min-height: 60px; */
    width: 100%;
    font-size: 30px;
    text-align: center;
    margin: 0;
    margin-bottom: 15px;
  }
  .DoctorArticle .para {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .DoctorArticle .da-deserve-title .da-arical-colum {
    text-align: center;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 0;
  }
  .DoctorArticle .dc-artical .heading2 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 20px;
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left .logo {
    display: none;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left {
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right {
    width: 100%;
    padding-left: 0;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 66px;
  }
 
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding: 30px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    position: absolute;
    left: 47px;
    bottom: 22px;
    width: 108px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .owlshape {
    width: 65%;
    top: 58px;
    right: -40px;
  }
  .DoctorArticle .da-arical-Powered {
    overflow: hidden;
    padding-top: 27px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .dotshapemark {
    left: -22px;
    top: 81px;
  }
  .DoctorArticle .patients-result .patients-treated .h3 {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .DoctorArticle .da-deserve-title,
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    padding: 0px 30px;
    text-align: center;
  }
  .DoctorArticle .dc-artical .img {
    overflow: hidden;
    padding: 0;
    left: unset;
    right: unset;
  }
  .DoctorArticle .dc-artical .img IMG {
    width: 100%;
  }
  /* .DoctorArticle .dc-artical1 .img IMG {
    width: 127%;
    margin: 0 -12%;
  } */
  .DoctorArticle {
    padding-top: 0;
  }
  .DoctorArticle .da-arical-colum .hiddenlogo {
    display: none;
  }
  .DoctorArticle .dc-artical2 .img IMG {
    width: 122%;
    margin: 0px -11%;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
    width: 100%;
  }
  .DoctorArticle .dc-artical.dc-artical1 {
    margin-top: 0;
    padding: 30px;
  }
  .DoctorArticle .da-arical-Powered .container {
    display: block;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    margin: 0 auto;
  }
  .DoctorArticle .dc-artical3 .description,
  .DoctorArticle .dc-artical3 .heading2 {
    text-align: center;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .left {
    width: 55%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result .right {
    width: 59%;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    top: 28px;
    left: -1px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    top: 21px;
    left: -19px;
    font-size: 58px;
}
  .DoctorArticle .patients-result .patients-treated-percent SPAN {
    font-size: 13.8px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN {
    height: 12px;
    width: 48px;
    font-size: 13.8px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.sciatica {
    width: 41px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.herniated {
    width: 28px;
  }
  .DoctorArticle .patients-result .patients-treated-btn SPAN.scoliosis {
    width: 28px;
  }
  .DoctorArticle .patients-result .patients-treated-btn {
    margin-bottom: 9px;
    display: flex;
  }
  .DoctorArticle .patients-result .patients-treated {
    text-align: left;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left IMG {
    width: 80%;
  }
}

@media (max-width:575px){
  .DoctorArticle .da-arical-Powered .da-arical-right .img .patients-result {
    padding-bottom: 0;
  }
  .DoctorArticle .patients-result .left .google-logo {
    
    bottom: -4;
    
  }
}
@media (max-width: 474px) 
{
  .DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv {
    width: 95%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img{
    height: 200px;
  }
}

@media (max-width: 474px) {
  .DoctorArticle .da-arical-Powered .da-arical-right .img {
    width: 80%;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 50px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right .img.raingdiv {
    width: 100%;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 54.1px;
    top: 21px;
    left: -15px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    width: 86px;
    left: 37px;
    top: 71px;
  }
  .DoctorArticle .da-arical-Powered .da-arical-left IMG {
    width: 98%;
  }
  /* .DoctorArticle .Losingyourvision {
    max-height: 100px;
    min-height: 100px;
  } */
}
@media (max-width: 500px) {
  .DoctorArticle .dc-artical .heading2 ,
  .DoctorArticle .dc-artical2 .heading2{
    font-size: 24px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 17px;
  }
}
@media (max-width: 413px) {
  .DoctorArticle .da-deserve-title,
  .DoctorArticle .dc-artical1 .description,
  .DoctorArticle .dc-artical1 .heading2 {
    padding: 0px 15px;
  }
  .DoctorArticle .da-deserve-title .heading2 {
    font-size: 42px;
    margin-bottom: 20px;
  }
  .DoctorArticle .da-deserve-title .paragaph {
    font-size: 18px;
  }
  .DoctorArticle .dc-artical .heading2 {
    font-size: 26px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 18px;
  }
  .DoctorArticle .dc-artical2 .img IMG {
    width: 100%;
    margin: 0px -9%;
  }
  .DoctorArticle .da-arical-Powered .da-arical-right.logoimgdiv img {
   width: 80%;
  }
  .DoctorArticle .dc-artical2 .heading2,
  .DoctorArticle .dc-artical2 .description {
    padding-left: 15px;
    padding-right: 15px;
  }
  .DoctorArticle .dc-artical .heading2 ,
  .DoctorArticle .dc-artical2 .heading2{
    font-size: 20px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 16px;
  }
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 49.1px;
    left: -28px;
    top: 1px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 52px;
    left: -15px;
    top: 10px;
  }
  .DoctorArticle .patients-result .left .google-logo {
    width: 27%;
    left: 6%;
    top: 51px;
  }
  /* .DoctorArticle .da-arical-Powered .dc-artical3 .heading2,
  .DoctorArticle .da-arical-Powered .dc-artical3 .description {
    margin: 0px -15px 30px;
  } */
  
  
  
}
@media (max-width: 400px) {
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 43.1px;
    left: -28px;
    top: 1px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 45px;
    left: -15px;
    top: 10px;
  }
}
@media (max-width: 370px) {
  .DoctorArticle .da-arical-Powered .review .review-rate {
    font-size: 37.1px;
    left: -24px;
    top: -5px;
  }
  .DoctorArticle .da-arical-Powered .review .star {
    width: 33px;
    left: -15px;
    top: 10px;
  }
  .DoctorArticle .dc-artical .heading2 ,
  .DoctorArticle .dc-artical2 .heading2{
    font-size: 18px;
  }
  .DoctorArticle .dc-artical .description {
    font-size: 15px;
  }
}