.Joinkalypsys {
  padding: 64px 0;
  background-repeat: no-repeat;
  background-position: 15px, right;
}
.Joinkalypsys .title .heading2 {
  font-size: 48px;
  line-height: 1.17;
  color: #013648;
  font-family: 'Heldane Display';
  margin-bottom: 15px;
}
.Joinkalypsys .title SPAN {
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.24px;
  color: #013648;
  display: inline-block;
}
.Joinkalypsys .join-form {
  width: 400px;
  margin: auto;
  margin-top: 43px;
}
.Joinkalypsys .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.Joinkalypsys .form-row > .two-input {
  width: 49%;
}
.Joinkalypsys .join-form .joinform-action {
  margin-top: 25px;
}
.Joinkalypsys .join-form .joinform-action button.withus-btn {
  width: 201px;
}
.Joinkalypsys .join-form .Select-control SELECT{
  font-family: 'Calibre Regular';
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
}
.Joinkalypsys .fileupload{
  min-height: 52px;
    border-radius: 2px;
    background-color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    font-size: 18px;
    line-height: 1.22;
    color: #013648;
    /* margin-top: 14px; */
    padding-right: 142px;
    word-break: break-all;
    border: solid 1.5px rgba(1, 54, 72, 0.2);
}
.Joinkalypsys .fileupload .fileupload-btn {
  position: absolute;
  right: 20px;
  overflow: hidden;
}

.Joinkalypsys .fileupload .fileupload-btn input {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: 1;
}
.Joinkalypsys .fileupload .fileupload-btn button {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  background-color: #3dbce7;
  border: 0;
  color: #fff;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  font-family: 'Calibre Medium';
  font-weight: 500;
  padding-top: 4px;
}
.Joinkalypsys .fileupload .preview-img {
  width: 100%;
  display: grid;
  grid-template-columns:  1fr;
}
.Joinkalypsys .fileupload .preview-img-multy{
  grid-template-columns: 55px 1fr;
}
.Joinkalypsys .fileupload .preview-img img{
  max-width: 50px;
  max-height: 33px;
}
.Joinkalypsys .fileupload .preview-img span{
  position: relative;
    display: block;
    top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.split-screeninput .Input-control.form-group .fileupload,
.split-screeninput .Input-control.form-group input{
  width: 100%;
height: 60px;
background: #FFFFFF;
border: 1px solid #013648 !important;
box-sizing: border-box;
border-radius: 10px
}
.split-screeninput  .fileupload .fileupload-btn button{
 background-color: #809BA4;
}
.split-screeninput .successmessage{
background-repeat: no-repeat;
width: 91px;
height: 91px;
position: relative;
margin: 0 auto;
}
.split-screeninput .successmessage img{
  position: absolute;
  top: 35%;
  left: 35%;
}
.split-screeninput .great-text{
  width: 198px;
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 118.18%;
margin: 0 auto;
text-align: center;
margin-top: 20px;
color: #013648;

}
.split-screeninput .infotext{
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 137%;
  text-align: center;  
  color: #013648;
}
.Joinkalypsys .join-form .split-screeninput .Select-control SELECT{
  background: #FFFFFF;
border: 1px solid #013648;
box-sizing: border-box;
border-radius: 10px;
z-index: 2;
}
.Joinkalypsys .join-form .CircleButton.WhiteButton{
  padding-top: 2px;
}
.Joinkalypsys .join-form .split-screeninput .Select-control .arrow{
  z-index: 3;
}
.Joinkalypsys .join-form .split-screeninput .Select-control.form-group:after,
.Joinkalypsys .join-form .split-screeninput .Select-control SELECT::after{
  background: transparent;
}
.info-providers-photoid{
  color: #809ba4;
  font-size: 14px;
}
@media (max-width: 1350px){
  .Joinkalypsys {
    background-size: 270px !important;
    background-position: 6% center, 94% center !important;
  }
}
@media (max-width: 1060px){
  .Joinkalypsys {
    background-size: 197px !important;
    background-position: 3% center, 97% center !important;
  }
}
@media (max-width: 991px) {
  .Joinkalypsys .title .heading2 {
    font-size: 42px;
  }
  .Joinkalypsys {
    padding: 50px 0;
  }
}
@media (max-width: 840px){
  .Joinkalypsys {
    background-size: 171px !important;
    background-position: 1% center, 99% center !important;
  }
}
@media (max-width: 767px) {
  .Joinkalypsys {
    padding: 65px 0 40px;
  }
  .Joinkalypsys {
    background-image: none !important;
  }
  .Joinkalypsys .join-form {
    width: 100%;
  }
  .Joinkalypsys .title .heading2 {
    font-size: 48px;
    line-height: 1.17;
    margin-bottom: 25px;
  }
}
