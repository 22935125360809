.NewPassword {
  padding: 120px 0;
}
.NewPassword .newpassword-container {
  max-width: 414px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
.NewPassword .password-instruction-text {
  font-size: 13px;
  line-height: 16px;
  font-family: 'Calibre Regular';
}
.NewPassword .h3 {
  font-size: 28px;
  font-weight: normal;
  line-height: 1.21;
  color: #013648;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding-bottom: 16px;
  margin-bottom: 17px;
  font-family: 'Heldane Display';
}
.NewPassword .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #013648;
  margin-bottom: 34px;
}
.NewPassword .confirm-btn {
  margin-top: 15px;
}
.NewPassword .confirm-btn > BUTTON {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.NewPassword .Input-control.form-group {
  margin-bottom: 18px;
}
.NewPassword .Input-control.form-group .view-img {
  height: 18px;
  top: calc(100% - 1.85rem);
}

@media (max-width: 1024px) {
  .NewPassword {
    padding: 90px 0;
  }
  .NewPassword .h3 {
    font-size: 30px;
    margin-bottom: 15px;
    padding-bottom: 14px;
  }
  .NewPassword .confirm-btn {
    margin-top: 10px;
  }
  .NewPassword .paragraph {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .NewPassword {
    padding: 70px 0;
  }
  .NewPassword .h3 {
    font-size: 28px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  .NewPassword .paragraph {
    font-size: 14px;
    margin-bottom: 25px;
  }
}
