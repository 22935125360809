.DateManual {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 28px;
}
.DateManual .date-manual-label {
  position: absolute;
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  top: -23px;
}
.DateManual .error-text {
  position: absolute;
  font-size: 13px;
  color: red;
  bottom: 0;
}
.DateManual .Input-control {
  display: inline-block;
}
.DateManual .Input-control.form-group INPUT {
  padding-left: 25px;
}
.DateManual .Input-control.form-group:nth-child(2) INPUT {
  border-right: 1.5px solid transparent;
  border-left: 1.5px solid transparent;
  border-radius: 0;
}
.DateManual .Input-control.form-group:nth-child(2) {
  border-radius: 0;
}
.DateManual .Input-control.form-group:nth-child(2) INPUT:focus {
  border-color: #013648;
}
.DateManual INPUT::-webkit-input-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
}
.DateManual INPUT:-ms-input-placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
}
.DateManual INPUT::placeholder {
  font-size: 18px;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
}
