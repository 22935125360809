.RequestSettings {
  margin-top: 15px;
}
.RequestSettings .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.RequestSettings .addwebsite-btn {
  width: 111px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestSettings .cancel-btn {
  background-color: #f1f1f1;
  color: #72787d;
}
.RequestSettings .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.RequestSettings .label-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
  font-family: 'Calibre Medium';
  margin-bottom: 15px;
  position: relative;
}
.RequestSettings .review-request .label-text IMG {
  position: absolute;
  left: -49px;
  top: -4px;
}
.RequestSettings .request-setting-inner {
  padding-top: 4px;
}
.RequestSettings .select-content {
  width: 333px;
  max-width: 100%;
}
.RequestSettings .select-content SELECT {
  height: 40px;
}
.RequestSettings .select-content .arrow {
  top: 18px;
  border-top-color: #92929d;
  border-width: 7px;
}
.RequestSettings .select-content.label-select .arrow {
  top: 42px;
}
.RequestSettings .review-request {
  border-bottom: solid 1px #e2e2ea;
  padding-bottom: 12px;
  padding-top: 30px;
}
.RequestSettings .review-request .preview-btn {
  font-family: 'Calibre Regular';
  width: 103px;
  height: 40px;
  padding: 10px 25px 8px 22px;
  border-radius: 4px;
  border: solid 1px #e2e2ea;
  background-color: transparent;
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: 0.13px;
  color: #44444f;
  margin: 0 16px;
  cursor: pointer;
}
.RequestSettings .review-request-content {
  display: flex;
  align-items: center;
}
.RequestSettings .frequently {
  padding-top: 30px;
}
.RequestSettings .review-request-content .label-select .label {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #72787d;
  margin-bottom: 8px;
  display: inline-block;
}
.RequestSettings .review-request-content .edit-url {
  font-size: 18px;
  font-family: 'Calibre Medium';
  line-height: 1.78;
  color: #26acda;
}
.RequestSettings .switch-content .label-text {
  font-family: 'Calibre Regular';
  color: #72787d;
  margin-bottom: 30px;
}
.RequestSettings .sent-out {
  margin-top: 44px;
}
.RequestSettings .important-info .description {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.15px;
  color: #013648;
  padding-bottom: 23px;
}
.RequestSettings .UILabel {
  position: fixed;
  right: 209px;
  top: 5px;
  margin-right: 30px;
}
.RequestSettings .review-request .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  padding-bottom: 23px;
}
.RequestReviews .review-request .sub-text A {
  color: #26acda;
}
.RequestReviews .max-email {
  height: 33px;
  background-color: #f1f1f1;
  color: #72787d;
  width: fit-content;
  padding: 10px;
  border-radius: 6px;
}
.RequestSettings .review-sources {
  padding: 27px;
  background-color: #f8f8f8;
  border-radius: 2px;
  width: 855px;
  max-width: 100%;
}
.RequestSettings .review-sources .heading-title {
  font-size: 21px;
  line-height: 0.83;
  letter-spacing: -0.3px;
  color: #72787d;
  margin-bottom: 23px;
}
.RequestSettings .review-sources .list-review-source {
  display: flex;
  flex-wrap: wrap;
}
.RequestSettings .review-sources .list-review-source > DIV {
  width: 23%;
  margin-bottom: 26px;
}
.RequestSettings .list-review-source > DIV .checkmark {
  border: solid 1px #26acda;
}
@media (max-width: 1400px) {
  .RequestSettings .review-request-content .edit-url {
    width: 120px;
    font-size: 16px;
  }
}
@media (max-width: 1024px) {
  .RequestSettings .title {
    font-size: 20px;
  }
  .RequestSettings .review-sources .list-review-source > DIV {
    width: 31%;
  }
}
@media (max-width: 767px) {
  .RequestSettings .review-request-content {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .RequestSettings .select-content {
    width: 100%;
    margin-bottom: 15px;
  }
  .RequestSettings .review-request .preview-btn {
    margin-left: auto;
  }
  .RequestSettings .review-request-content .edit-url {
    width: auto;
  }
  .RequestSettings .heading {
    flex-wrap: wrap;
  }
  .RequestSettings .title {
    width: 100%;
  }
  .RequestSettings .right-panel {
    width: 100%;
    text-align: right;
  }
  .RequestSettings .review-request {
    padding-left: 25px;
  }
  .RequestSettings .review-request .label-text IMG {
    left: -30px;
  }
}
@media (max-width: 414px) {
  .RequestSettings .review-sources {
    padding: 15px 10px;
  }
  .RequestSettings .review-sources .list-review-source > DIV {
    width: 46%;
    margin-bottom: 15px;
  }
  .RequestSettings .review-sources .heading-title {
    font-size: 20px;
  }
}
