.EmailInputModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.EmailInputModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.EmailInputModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.EmailInputModal .modal-content-header .close IMG {
  width: 100%;
}
.EmailInputModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.EmailInputModal .modal-content-body {
  padding: 23px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.EmailInputModal .modal-content-body .resendcode-text {
  display: inline-block;
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
}
.EmailInputModal .modal-content-body .resendcode-text SPAN {
  color: #26acda;
  cursor: pointer;
}
.EmailInputModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.EmailInputModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.EmailInputModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
.EmailInputModal .modal-content-body .text-info {
  width: 318px;
  height: 20px;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.23px;
  color: #013648;
  margin-bottom: 43px;
}
.EmailInputModal .modal-content-body .text-info SPAN {
  font-family: 'Calibre Medium';
}
.EmailInputModal .modal-content-footer .contact-us-text {
  font-size: 18px;
  line-height: 1.68;
  letter-spacing: -0.34px;
  color: #013648;
  text-align: center;
  margin-top: 85px;
}
.EmailInputModal .modal-content-footer {
  padding-top: 0;
}
.EmailInputModal .modal-content-footer .contact-us-text A {
  color: #26acda;
}
@media screen and (max-width: 767px) {
  .EmailInputModal {
    width: 100%;
  }
  .EmailInputModal .modal-content-body {
    padding: 20px 15px;
  }
  .EmailInputModal .modal-content-header {
    height: auto;
    padding: 15px;
  }
}
