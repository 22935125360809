.Team .title .heading2 {
  font-size: 48px;
  line-height: 1.04;
  color: #013648;
  font-family: 'Heldane Display';
}
.Team .title .heading2.text-bold{
  font-weight: bold;
}
.Team {
  padding: 30px 0px;
}

.Team .slick-slider.team-slider {
  padding: 50px 55px 0 55px;
}
.Team .slick-slider.team-slider .slick-track{ margin-left: 0;display: flex; }

/* .Team .team-slider {
  margin-top: 82px;
}
.Team .team-slider .team-slider-content {
  padding: 0 0px;
}
.Team .slick-slider.team-slider .slick-track {
  margin-left: -45px;
}
@media (max-width: 1024px) {
  .Team .slick-slider.team-slider .slick-track {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .Team {
    padding: 50px 0;
  }
  .Team .title .heading2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .Team {
    padding: 40px 0;
    overflow: hidden;
  }
  .Team .title .heading2 {
    font-size: 48px;
    line-height: 1.17;
  }
  .Team .title {
    text-align: left;
  }
  .Team .team-slider {
    width: 800px;
  }
  .Team .team-slider .team-slider-content {
    padding: 0 15px;
  }
  .Team .team-slider {
    margin-top: 40px;
  }
  .Team .slick-slider.team-slider .slick-track {
    margin-left: 155px;
  }
}*/
@media (max-width: 665px) {
  .Team .slick-slider.team-slider {
    padding: 0;
    padding-top: 30px;
  }
  .Team{
    padding-top: 0;
  }
} 
@media (max-width: 350px) {
.Team .title .heading2.text-bold{
  font-size: 24px;
}
}