.RequestContact .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f1f1f5;
  padding-bottom: 10px;
}
.RequestContact .addwebsite-btn {
  width: 124px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestContact .send-btn {
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #013648;
}
.RequestContact .send-btn IMG {
  position: relative;
  top: -3px;
  left: -7px;
}
.RequestContact .Select-control .arrow {
  top: 17px;
  right: 13px;
  border-width: 7px;
  border-top-color: #92929d;
}
.RequestContact .Select-control {
  margin-bottom: 0;
  height: 40px;
}
.RequestContact .Select-control SELECT {
  height: 40px;
}
.RequestContact .title {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: #013648;
}
.RequestContact .right-panel {
  display: flex;
}
.RequestContact .UILabel {
  position: fixed;
  right: 209px;
  top: 5px;
  margin-right: 30px;
}
.RequestContact .search-contact {
  margin-right: 14px;
  width: 300px;
  margin-bottom: 0;
}
.RequestContact .search-contact INPUT {
  height: 40px;
  padding-left: 37px;
  background-color: #fafafb;
  border-color: #fafafb;
}
.RequestContact .search-contact .view-img {
  left: 11px;
  top: 20px;
  width: 21px;
  height: 15px;
}
.RequestContact .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.RequestContact .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.RequestContact .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.RequestContact .pagination LI.active {
  background-color: #013648;
  color: white;
}
.RequestContact .pagination LI.previous.disabled,
.RequestContact .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.RequestContact .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
@media (max-width: 1200px) {
  .RequestContact .heading {
    flex-wrap: wrap;
  }
  .RequestContact .right-panel {
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-top: 14px;
  }
}
@media (max-width: 1024px) {
  .RequestContact .title {
    font-size: 20px;
  }
  .RequestContact .refresh-btn {
    margin-right: 15px;
  }
}
@media (max-width: 767px) {
  .RequestContact .heading {
    flex-wrap: wrap;
  }
  .RequestContact .heading .title {
    width: 100%;
  }
  .RequestContact .right-panel {
    flex-wrap: wrap;
  }
  .RequestContact .Select-control {
    margin-bottom: 0;
    height: 40px;
    width: 100%;
    margin-bottom: 15px;
  }
  .RequestContact .search-contact {
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
}
@media (max-width: 414px) {
  .RequestContact .send-btn,
  .RequestContact .addwebsite-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}
