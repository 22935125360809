.EditCertification .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditCertification .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditCertification .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditCertification .cross-icon img {
  width: 100%;
}
.EditCertification .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditCertification .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditCertification .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditCertification .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditCertification .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditCertification .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditCertification .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditCertification .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditCertification .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
