.ReviewDetail {
  padding-top: 47px;
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(143, 146, 161, 0.2);
}
.ReviewDetail .review-detail-innercontent {
  display: flex;
}
.ReviewDetail .review-detail-innercontent .Description {
  flex: 3;
  padding-right: 60px;
}
.ReviewDetail .review-detail-innercontent .rating {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.ReviewDetail .review-detail-innercontent .rating .rating-list .SingleRating .name {
  width: auto;
}
.ReviewDetail .review-detail-innercontent > DIV {
  flex: 1;
}
.ReviewDetail .review-detail-innercontent .name .person-name {
  min-width: 132px;
}
.ReviewDetail:first-child {
  padding-top: 7px;
}
.linka{
  color: rgba(1, 54, 72, 0.4);
  text-decoration: underline;
}
.ReviewDetail:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}
.ReviewDetail .star-rating .name {
  font-size: 18px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #013648;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-right: 6px;
}
.ReviewDetail .star-rating .number {
  font-size: 20px;
  line-height: 0.68;
  letter-spacing: normal;
  color: #013648;
  font-family: 'Heldane Display';
  font-weight: normal;
  position: relative;
}
.ReviewDetail .star-rating .rate-icon {
  width: 14px;
  margin-right: 4px;
  height: 13px;
  position: relative;
  left: -1px;
}
.ReviewDetail .review-detail-innercontent .name .person-name {
  width: 100%;
  display: inline-block;
  font-family: 'Calibre Medium';
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: #013648;
}
.ReviewDetail .rating-list {
  margin-left: auto;
}
.ReviewDetail .review-detail-innercontent .name .link-span {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  line-height: 0.9;
  letter-spacing: 0.13px;
  color: #26acda;
  margin-bottom: 30px;
  cursor: pointer;
}
.ReviewDetail .review-detail-innercontent .name .status-btn {
  border-radius: 1px;
  border: 0;
  color: #fff;
  margin-bottom: 22px;
  padding: 6px 13px 3px 13px;
  display: inline-block;
  margin-right: 10px;
  font-size: 13px;
  line-height: 15px;
}
.ReviewDetail .review-detail-innercontent .name .recent-btn {
  background-color: #f37184;
}
.ReviewDetail .review-detail-innercontent .name .featured-btn {
  background-color: #013648;
}
.ReviewDetail .review-detail-innercontent .name .logo {
  width: 100%;
  display: inline-block;
}
.ReviewDetail .review-detail-innercontent .name .logo IMG {
  width: 29px;
  height: 29px;
  object-fit: contain;
}
.ReviewDetail .review-detail-innercontent .Description .datetime {
  font-size: 16px;
  line-height: 1.25;
  color: #013648;
  margin-top: 7px;
  display: inline-block;
}
.ReviewDetail .review-detail-innercontent .Description .link {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #26acda;
  display: inline-block;
  margin-top: 30px;
  cursor: pointer;
}
.ReviewDetail .Confirmation {
  padding: 20px;
  border-radius: 2px;
  background-color: rgba(1, 54, 72, 0.03);
  margin-top: 20px;
}
.ReviewDetail .confirmation-content {
  border-radius: 2px;
  border: solid 1px rgba(1, 54, 72, 0.5);
  background-color: #ffffff;
  padding: 20px;
}
.ReviewDetail .confirmation-content .textarea-wrapper TEXTAREA {
  width: 100%;
  border: 0;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 36px;
  display: inline-block;
  width: 100%;
}
.ReviewDetail .confirmation-content .textarea-wrapper TEXTAREA:focus {
  outline: 0;
}
.ReviewDetail .CircleButton {
  display: inline-block;
  width: 111px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
}
.ReviewDetail .reply IMG {
  width: 14px;
  height: 11px;
  margin-right: 10px;
}
.ReviewDetail .replyBox {
  background-color: rgba(1, 54, 72, 0.03);
  padding: 20px;
  font-size: 16px;
}
.ReviewDetail .replyBox .reply-text {
  margin-top: 10px;
  word-break: break-word;
}
.ReviewDetail .popup-rating {
  position: absolute;
  top: -146px;
  left: -63px;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(25, 42, 70, 0.13);
  background-color: #ffffff;
  z-index: 999;
  padding: 20px;
}
.ReviewDetail .review-detail-innercontent .rating .featured-review {
  margin-top: auto;
  margin-left: auto;
  width: 100%;
  text-align: right;
}
.ReviewDetail .review-detail-innercontent .rating .featured-review .CircleButton {
  width: auto;
  font-size: 13px;
  margin-top: 15px;
  line-height: unset;
  padding: 5px 11px 2px;
}
.ReviewDetail .confirmation-content-action {
  display: flex;
  align-items: baseline;
  flex-flow: column;
}
.ReviewDetail .confirmation-content-action > SPAN {
  display: inline-block;
  padding-top: 15px;
  font-size: 16px;
  color: rgba(1, 54, 72, 0.4);
}
.ReviewDetail .replyaction .btn {
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
.ReviewDetail .replyaction .btn SPAN.link {
  margin: auto 0;
  display: inline-block;
  position: relative;
  top: 3px;
}
.ReviewDetail .replyaction .btn IMG {
  width: 15px;
  margin-right: 5px;
}
.ReviewDetail .replyaction {
  margin-top: 15px;
}
@media (hover: hover) {
  .ReviewDetail .rating .rating-list > .SingleRating:hover .name,
  .ReviewDetail .rating .rating-list > .SingleRating:hover .number {
    color: #3d93a6;
  }
  .ReviewDetail .rating .rating-list > .SingleRating:hover .progress-bar {
    background-color: #3d93a6;
  }
}

@media (max-width: 1400px) {
  .ReviewDetail .review-detail-innercontent .Description {
    padding-right: 30px;
  }
}

@media (max-width: 991px) {
  .ReviewDetail .confirmation-content-action {
    flex-wrap: wrap;
  }
  .ReviewDetail .confirmation-content-action > SPAN {
    padding-left: 0;
    /* margin-top: 10px; */
  }
}

@media (max-width: 767px) {
  .ReviewDetail .review-detail-innercontent {
    flex-wrap: wrap;
  }
  .ReviewDetail .review-detail-innercontent > DIV {
    flex: inherit;
    width: 100%;
  }
  .ReviewDetail .review-detail-innercontent .Description {
    padding-right: 0;
  }
  .ReviewDetail .review-detail-innercontent .rating {
    width: 100%;
    margin-top: 15px;
  }
  .ReviewDetail .review-detail-innercontent > DIV.name {
    position: relative;
    padding-left: 40px;
  }
  .ReviewDetail .rating-list {
    margin-left: 0;
    width: 100%;
  }
  .ReviewDetail .review-detail-innercontent .rating .featured-review {
    margin-top: 10px;
  }
  .ReviewDetail .review-detail-innercontent .name .logo {
    position: absolute;
    left: 0;
    top: 0;
  }
  .ReviewDetail .popup-rating {
    right: 15px;
    top: 100px;
    left: 20px;
  }
  .ReviewDetail {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .ReviewDetail .review-detail-innercontent .Description .link {
    margin-top: 15px;
  }
}
