.AppointmentRequest .appointment-map {
  height: 107px;
  width: 100%;
}
.AppointmentRequest .appointment-request-detail {
  padding-bottom: 25px;
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
}
.AppointmentRequest .appointment-request-detail .h3 {
  font-size: 24px;
  line-height: 0.79;
  color: #013648;
  margin-bottom: 46px;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .title {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .Specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .address {
  margin-top: 25px;
  margin-bottom: 24px;
  line-height: 1.25;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #013648;
  display: flex;
}
.AppointmentRequest .appointment-request-detail .appointment-doctor-info .address .icon {
  width: 17px;
  margin-right: 14px;
  display: inline-block;
  position: relative;
  top: -3px;
}
.AppointmentRequest .appointment-doctor {
  display: flex;
}
.AppointmentRequest .appointment-doctor .doctor-img {
  min-width: 100px;
  width: 100px;
  display: inline-block;
  height: 150px;
}
.AppointmentRequest .appointment-doctor .doctor-img img {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AppointmentRequest .appointment-doctor .appointment-doctor-info {
  padding-left: 33px;
  width: calc(100% - 100px);
}
.AppointmentRequest .questionnaire {
  display: flex;
  padding-top: 28px;
  padding-bottom: 30px;
}
.AppointmentRequestthanks .questionnaire {
  justify-content: space-between;
  padding-bottom: 0;
}
.AppointmentRequest .qu-right {
  width: 55%;
}
.AppointmentRequestthanks .qu-left,
.AppointmentRequestthanks .qu-right {
  width: 49% !important;
}
.AppointmentRequest .qu-left {
  width: 45%;
  padding-right: 5px;
}
.AppointmentRequest .qu-left .logo-icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
  display: inline-block;
}
.AppointmentRequest .qu-left .logo-icon img {
  width: 100%;
}
.AppointmentRequest .qu-left .title {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
  margin-top: 6px;
}
.AppointmentRequest .qu-left .questionnaire-list span {
  display: inline-block;
  width: 100%;
}
.AppointmentRequest .qu-left .questionnaire-list {
  font-size: 18px;
  line-height: 1.67;
  color: #013648;
  margin-top: 14px;
}
.AppointmentRequest .qu-right {
  border-radius: 4px;
  background-color: #f1f1f1;
  display: flex;
}
.AppointmentRequest .qu-right .appointment-time {
  margin: auto;
  padding: 30px 0 30px;
  text-align: center;
}
.AppointmentRequest .qu-right .appointment-time .time {
  text-align: left;
  width: 132px;
  min-width: 132px;
  min-height: 132px;
  border-radius: 4.8px;
  background-color: #013648;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.45px;
  color: #f7fffc;
  margin: auto;
  margin-bottom: 29px;
  display: flex;
  padding: 18px 11px;
  /* padding-right: 0; */
}
.AppointmentRequest .qu-right .appointment-time .time > span {
  margin: auto;
}
.AppointmentRequest .qu-right .appointment-time .edit-appointment {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: center;
  color: #26acda;
  cursor: pointer;
  text-decoration: underline;
}
.AppointmentRequest .qu-right .appointment-time .time .day,
.AppointmentRequest .qu-right .appointment-time .time .date {
  display: inline-block;
  width: 100%;
}
.AppointmentRequest .qu-right .appointment-time .time .day {
  font-family: 'Calibre Medium';
}
.AppointmentRequest .h3 {
  position: relative;
}
.AppointmentRequest .back-arrow {
  position: absolute;
  left: -130px;
  top: -5px;
  width: 25px;
  cursor: pointer;
}
.AppointmentRequest .person-visit,
.AppointmentRequest .video-visit {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  font-family: 'Calibre Medium';
  font-size: 24px;
  line-height: 1.19;
  letter-spacing: -0.34px;
  color: #013648;
  position: relative;
  padding-left: 24px;
  margin-bottom: 25px;
}
.AppointmentRequest .video-visit img {
  width: 46px;
  position: absolute;
  left: -15px;
  top: -13px;
}
.AppointmentRequest .person-visit img {
  width: 45px;
  position: absolute;
  left: -21px;
  top: -12px;
}
.AppointmentRequest .qu-right.inPerson {
  background-color: #cce3eac9;
}
.AppointmentRequest .qu-right.inVideo {
  background-color: #eeebf5;
}
.AppointmentRequest .video-visit {
  color: #8d77ba;
}
.appointmentRequestnewview .appointment-request-detail .h3 {
  margin-bottom: 20px;
  font-size: 20px;
}
.appointmentRequestnewview .appointment-doctor .doctor-img {
  height: 100px;
}
.appointmentRequestnewview .appointment-request-detail .appointment-doctor-info .title {
  font-size: 20px;
}
.appointmentRequestnewview .appointment-request-detail .appointment-doctor-info .address {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #e85d72;
}
.appointmentRequestnewview .appointment-request-detail .appointment-doctor-info .address img {
  filter: invert(47%) sepia(69%) saturate(1210%) hue-rotate(316deg) brightness(96%) contrast(90%);
}
.appointmentRequestnewview .qu-right .appointment-time {
  padding: 10px 0 10px;
}
.appointmentRequestnewview .person-visit,
.appointmentRequestnewview .video-visit {
  margin-bottom: 10px;
  font-size: 18px;
}
.appointmentRequestnewview .person-visit img {
  width: 38px;
  left: -16px;
}
.appointmentRequestnewview .qu-right .appointment-time .time {
  text-align: center;
  font-size: 20px;
  min-height: 115px;
  margin-bottom: 15px;
}
.appointmentRequestnewview .qu-right .appointment-time .edit-appointment {
  font-size: 16px;
}

@media (max-width: 991px) {
  .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
  .AppointmentRequest .qu-left {
    width: 100%;
  }
  .AppointmentRequest .qu-right {
    width: 100%;
  }
  .AppointmentRequest .qu-left .questionnaire-list {
    margin-bottom: 15px;
  }
  .AppointmentRequest .appointment-doctor {
    flex-wrap: wrap;
  }
  .AppointmentRequest .appointment-doctor .appointment-doctor-info {
    padding-left: 0;
    margin-top: 15px;
    width: 100%;
  }
}
@media (max-width: 767px) {
  .AppointmentRequest .appointment-request-detail .h3 {
    display: none;
  }
  .AppointmentRequest .appointment-doctor .doctor-img {
    width: 70px;
    height: 99px;
    min-width: 70px;
  }
  .AppointmentRequest .appointment-doctor .appointment-doctor-info {
    width: calc(100% - 70px);
    padding-left: 17px;
  }
  .AppointmentRequest .questionnaire {
    display: none;
  }
  .AppointmentRequestthanks .questionnaire {
    display: block;
  }
  .AppointmentRequestthanks .qu-left,
  .AppointmentRequestthanks .qu-right {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .AppointmentRequest .appointment-request-detail {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .address {
    margin-bottom: 0;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .title {
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 12px;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .Specialist {
    font-size: 16px;
    line-height: 1.25;
    letter-spacing: normal;
    margin-bottom: 3px;
  }
  .AppointmentRequest .appointment-request-detail .appointment-doctor-info .address {
    margin-top: 0;
    letter-spacing: normal;
    opacity: 0.4;
  }
  .AddPatientModal .form-group-2-colum {
    flex-wrap: wrap;
  }
  .AddPatientModal .form-group-2-colum .Input-control {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .AppointmentRequest .qu-right,
  .AppointmentRequest .qu-left {
    width: 100%;
  }
}
