.FinalizeAccount .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.FinalizeAccount .password-instruction-text {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Calibre Regular';
}
.FinalizeAccount .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(12, 59, 75, 1);
  margin-bottom: 23px;
}
.FinalizeAccount .paragraph A {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(12, 59, 75, 0.7);
}
.FinalizeAccount .checkbox-block {
  margin-top: 23px;
  margin-bottom: 39px;
}
.FinalizeAccount .checkbox-block .Checkbox-control:not(:last-child) {
  margin-bottom: 18px;
}
.FinalizeAccount .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.FinalizeAccount .form-row .two-input {
  width: 48%;
}
.FinalizeAccount .signup-form .Input-control {
  margin-bottom: 18px;
}
.FinalizeAccount .signup-btn > BUTTON {
  width: 100%;
  height: 45px;
  margin-bottom: 55px;
}
.FinalizeAccount .signup-bottom {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  text-align: center;
  color: #013648;
}
.FinalizeAccount .signup-bottom A {
  color: #3dbce7;
  font-weight: 500;
}
.FinalizeAccount .Input-control .form-control {
  border-width: 1px;
}
.FinalizeAccount .Input-control.form-group .form-control:focus,
.FinalizeAccount .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}

@media (hover: hover) {
  .FinalizeAccount .signup-bottom A:hover {
    color: #013648;
  }
  .FinalizeAccount .paragraph A:hover {
    color: #013648;
  }
}

@media (max-width: 1024px) {
  .FinalizeAccount .h3 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .FinalizeAccount .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .FinalizeAccount .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 40px;
    letter-spacing: normal;
  }
  .FinalizeAccount .checkbox-block {
    margin-top: 15px;
    margin-bottom: 22px;
  }
  .FinalizeAccount .checkbox-block .Checkbox-control:not(:last-child) {
    margin-bottom: 23px;
  }
  .FinalizeAccount .signup-btn > BUTTON {
    margin-bottom: 40px;
  }
  .FinalizeAccount .signup-form .Input {
    margin-bottom: 20px;
  }
}
