.RouteNotFound {
  padding: 60px 0px;
}
.RouteNotFound .RouteNotFound-inner {
  text-align: center;
}
.RouteNotFound .heading1 {
  font-size: 100px;
  font-weight: normal;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #013648;
  margin-bottom: 30px;
  font-family: HeldaneDisplay;
  text-shadow: 2px 2px #74bdc7;
}
.RouteNotFound .paragraph {
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
  margin-bottom: 28px;
}

@media (max-width: 767px) {
  .RouteNotFound {
    padding: 40px 0px;
  }
  .RouteNotFound .heading1 {
    font-size: 70px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .RouteNotFound .paragraph {
    font-size: 18px;
    line-height: 1.06;
    margin-bottom: 15px;
  }
}
