.paymentSession {
  min-height: 400px;
}
.h3 {
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.paymentSession .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 26px;
}
.paymentSession .product-list{
display: flex;
flex-direction: row;
width: 700px;
margin-bottom: 15px;

gap: 5px;
}
.paymentSession .plan-card{
padding: 20px;
background-color: #02364805;
width: 280px;
cursor: pointer;
display: flex;
flex-direction: column;
}
.paymentSession .plan-card:hover{
background-color: #859ba36b;
}
.paymentSession .plan-card.active{
  background-color: #859ba31f;
}
.paymentSession .plan-card .logo img{
width: 150px;
}
.paymentSession .plan-card .tilte h3{
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}

.paymentSession .plan-card .discription p {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.3px;
  color: #859BA3;
  margin-bottom: 26px;
  /* min-height: 80px; */
}
.paymentSession .plan-card .pricing h3 {
  font-size: 32px;
  font-weight: bold;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  margin-top: 13px;
  font-family: HeldaneDisplay;
  display: flex;
}
.paymentSession .plan-card .pricing .delelement h3,
.paymentSession .plan-card .pricing .delelement{
 color: red;
 font-size: 20px;
}

.paymentSession .plan-card .subscribe{
  height: 50px !important;
  margin-right: 0!important;
  width: 100% !important;
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  /* margin: 0px 15px 15px; */
  cursor: pointer;
  text-align: center;
  width: 27.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border: 0px;
  font-family: "Heldane Display";
  background-color: rgb(1, 54, 72);
}
.paymentSession .plan-card .pricing h3 span.price-label{
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color:  #859BA3;
  margin-bottom: 13px;
  
  font-family: HeldaneDisplay;
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.paymentSession .plan-card .pricing h3.main-price{
  margin-bottom: 0;
}
.paymentSession .plan-card .pricing  .delelement h3{
  margin: 0;
}
.paymentSession .plan-card .pricing  .delelement h3 span.price-label{
  color: red;
  font-size: 10px;
}
.paymentSession .plan-card .subtitle h4{
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color:  #859BA3;
  margin-bottom: 5px;
  font-family: HeldaneDisplay;
}
.paymentSession .stripe-input {
  border-width: 1px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  padding: 13px 15px 0px 15px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  overflow-y: hidden;
}
.paymentSession .product-info ul{
  padding-left: 5px;
}
.paymentSession .product-info ul li{
  list-style-type: none;
  display: flex;line-height: 2;

}
.paymentSession .product-info ul li img.bullet{
 width: 20px;
 margin-right: 5px;
}
.paymentSession .product-info ul li span.text{
  padding-top: 5px;
  color:  #859BA3;
  
 }
 .paymentSession .promobaner{
  padding: 20px;
  border: 10px solid #55BFDB;
  color: #55BFDB;
  margin-bottom: 15px;
 }
 .paymentSession .promobaner h4{
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 10px;
  
  font-family: HeldaneDisplay;
 }
 .paymentSession .promobaner h6{
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  margin: 0;
  margin-bottom: 5px;
  
  font-family: HeldaneDisplay;
 }
 .paymentSession .promobaner p{
  margin: 0;
 }
 .paymentSession .selectedPlanParent{
width: 700px;
padding-top: 20px;
padding-bottom: 20px;
border-top: 1px solid #859ba354;
border-bottom: 1px solid #859ba354;
margin-bottom: 15px;
 }
.paymentSession .selectedPlanParent .selectedPlan{
  /* padding-left: 30px; */
  display: flex;
  flex-direction: column;
  width: 400px;
}
.paymentSession .selectedPlanParent .selectedPlan div{
  display: grid;
  grid-template-columns: 40% 60%;
  margin-bottom: 7px;
}
.paymentSession .selectedPlanParent .selectedPlan div label{
  font-size: 20px;
  color: #013648;
}
.paymentSession .promocodediv{
  display: flex;
  margin-bottom: 30px;
  align-items: baseline;
  justify-content: space-between;
}
.paymentSession .promocodediv button{
  height: 50px !important;
  margin-right: 0!important;
  width: 110px;
  border-radius: 4px;
  padding: 0px 20px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  font-weight: 600;
  margin: 0px 15px 15px;
  cursor: pointer;
  text-align: center;
  width: 27.33%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 255, 255);
  border: 0px;
  font-family: "Heldane Display";
  background-color: rgb(1, 54, 72);
}
.paymentSession .promocodediv button:disabled{
  opacity: 0.5;
color: #d8d8d8;
background-color: #013648;
}
.paymentSession .promocodediv p.error{
color: red;
margin: 0;
margin-top: 5px;
}
.paymentSession .promocodediv  p.success{
  color: #55BFDB;
  margin: 0;
  margin-top: 5px;
  }
.paymentSession .elementlabel {
  font-family: 'Calibre Medium';
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #013648;
}
.paymentSession .promocodediv input{
  border-width: 1px;
    border: solid 1.5px rgba(1, 54, 72, 0.2);
    padding: 5px 15px 0px 15px;
    height: 52px;
    border-radius: 2px;
    background-color: #ffffff;
    font-size: 18px;
    font-family: 'Calibre Regular';
    line-height: 1.56;
    letter-spacing: -0.34px;
    color: #013648;
    width: 275px;
}
@media (max-width: 991px) {
  .paymentSession .plan-card {
    width: 240px;
  }
}
@media (max-width: 700px) {
  .paymentSession .selectedPlanParent,
  .paymentSession .product-list{
    width: 100%;
  }
 
  
}
@media (max-width: 640px) {
  .paymentSession .product-list{
   flex-direction: column;

  }
  .paymentSession .promocodediv{
    display: grid;
    grid-template-columns: 1fr;
  }
  .paymentSession .promocodediv button {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .paymentSession .promocodediv input{
    width: 100%;
  }
  .paymentSession .plan-card {
    width: 100%;
    margin-bottom: 20px;
  }
  .paymentSession .selectedPlanParent .selectedPlan{
    width: 100%;
  }
  .paymentSession .CircleButton.WhiteButton{
    width: 100%;
  }
}