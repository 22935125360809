.ConfirmAppointment .ConfirmAppointment-content .left-content {
  background-color: #fbfbf9;
  width: 55%;
  padding-top: 36px;
  padding-left: 13.9%;
  padding-right: 81px;
}
.ConfirmAppointment .ConfirmAppointment-content .right-content {
  background-color: #fff;
  width: 50%;
  padding-right: 13.2%;
}
.ConfirmAppointment .ConfirmAppointment-content {
  justify-content: space-between;
  display: flex;
}
.ConfirmAppointment .reserve-appointment {
  height: 57.6px;
  font-size: 14px;
  line-height: 1.37;
  letter-spacing: -0.19px;
  color: rgba(1, 54, 72, 0.5);
  width: 100%;
  margin-top: 56px;
  margin-bottom: 50px;
}
.ConfirmAppointment .reserve-appointment a {
  color: #3dbce7;
}
.ConfirmAppointment .CustomFooter {
  position: relative;
  padding-left: 60px;
  padding-right: 20px;
  padding-top: 62px;
}
.ConfirmAppointment .footer-block:after {
  content: '';
  position: absolute;
  left: 0;
  top: -1px;
  bottom: 0;
  width: 52.6%;
  background-color: #fbfbf9;
}
.ConfirmAppointment .CustomFooter .container {
  width: 100%;
}
.ConfirmAppointment .CustomFooter .footer-menu-ul {
  z-index: 1;
}
.ConfirmAppointment .left-content .title-heading {
  display: none;
}
.ConfirmAppointment .ReviewConfirm .reserve-appointment {
  display: none;
  margin-top: 24px;
}
@media (max-width: 1400px) {
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    padding-left: 100px;
    padding-right: 60px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content {
    padding-right: 60px;
  }
  .ConfirmAppointment .AppointmentRequest .back-arrow {
    left: -50px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    padding-left: 70px;
    padding-right: 30px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content {
    padding-right: 30px;
    padding-left: 30px;
  }
  .ConfirmAppointment .CustomFooter {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 1200px) {
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    width: 100%;
  }
  .ConfirmAppointment .footer-block:after {
    width: 50.6%;
  }
}
@media (max-width: 1200px) {
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    width: 55%;
  }
  .AppointmentRequest .appointment-map {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .ConfirmAppointment {
    padding-bottom: 118px;
  }
  .ConfirmAppointment .CustomFooter {
    padding-bottom: 41px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    width: 100%;
  }
  .ConfirmAppointment .CustomFooter {
    padding-top: 41px;
  }
  .ConfirmAppointment .ConfirmAppointment-content .right-content {
    background-color: #fbfbf9;
  }
  .ConfirmAppointment .CustomFooter {
    background-color: #fbfbf9;
  }
  .ConfirmAppointment .ConfirmAppointment-content {
    flex-wrap: wrap;
  }
  .ConfirmAppointment .footer-block:after {
    display: none;
  }
  .ReserveAppointment .divider-content .Divider {
    display: block;
  }
  .ConfirmAppointment .reserve-appointment {
    display: none;
  }
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    background-color: #eaf4eb;
    padding-top: 10px;
    padding-bottom: 32px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading {
    font-family: 'Heldane Display';
    font-size: 28px;
    line-height: 1.21;
    color: #fff;
    padding-top: 36px;
    position: relative;
    border-bottom: 1px solid #e0e4e7;
    padding-bottom: 18px;
    padding-right: 24px;
    padding-left: 24px;
    margin-bottom: 18px;
    display: block;
    background-color: #023648;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading .title-mobile {
    display: flex;
    justify-content: space-between;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .address {
    font-size: 16px;
    line-height: 1.25;
    color: #013648;
    opacity: 1;
    margin-top: 43px;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading img {
    width: 18px;
    /* position: absolute; */
    top: -20px;
    cursor: pointer;
    right: 0;
  }
  .ConfirmAppointment.showappointment-mobile .reserve-appointment {
    display: block;
  }
  .ConfirmAppointment.showappointment-mobile .AppointmentRequest .questionnaire {
    display: flex;
    flex-wrap: nowrap;
  }
  .ConfirmAppointment.showappointment-mobile .left-content {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 11;
    overflow: auto;
    padding-top: 112px;
    padding-bottom: 85px;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-overflow-scrolling: touch;
  }
  .ConfirmAppointment.showappointment-mobile .AppointmentRequest .appointment-doctor .doctor-img {
    height: 105px;
  }
  .ConfirmAppointment.showappointment-mobile .appointment-request-detail {
    border-bottom: solid 1px rgba(32, 58, 112, 0.08);
    padding-bottom: 20px;
  }
  .ConfirmAppointment.showappointment-mobile .reserve-appointment {
    display: none;
  }
  .ConfirmAppointment .ReviewConfirm .reserve-appointment {
    display: block;
  }
  .CustomFooter .menu-item-list {
    width: auto;
  }
}

@media (max-width: 413px) {
  .ConfirmAppointment {
    padding-bottom: 148px;
  }
  .ConfirmAppointment .CustomFooter,
  .ConfirmAppointment .ConfirmAppointment-content .right-content,
  .ConfirmAppointment .ConfirmAppointment-content .left-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ConfirmAppointment.showappointment-mobile .AppointmentRequest .questionnaire {
    flex-wrap: wrap;
  }
  .ConfirmAppointment.showappointment-mobile .left-content .title-heading {
    padding-right: 0;
  }
}
