.InsuranceCompany .dashboard-panle {
  padding: 0px 20px 40px 40px;
  justify-content: space-between;
}
.InsuranceCompany {
  width: calc(100% - 250px);
}
.InsuranceCompany .rtable {
  display: table;
  background-color: white;
  width: auto;
  border: 0px;
  width: 100%;
}
.InsuranceCompany .heading {
  display: table-row;
  font-weight: bold;
  background-color: #013648;
  color: white;
}
.InsuranceCompany .row {
  display: table-row;
  width: auto;
  clear: both;
}
.InsuranceCompany .cell {
  display: table-cell;
  border: solid;
  border-width: thin;
  width: 200px;
  padding: 15px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #013648;
  background-color: white;
  border-color: black;
  position: relative;
  border: 0;
  border-bottom: 1px solid #d8d8d8;
}
.InsuranceCompany .heading .cell {
  background-color: #013648;
  color: white;
}
.InsuranceCompany .heading .cell .sort-icon {
  margin-left: 10px;
  position: relative;
}
.InsuranceCompany .heading .cell .sort-icon IMG {
  width: 15px;
  top: -1px;
  position: absolute;
}
.InsuranceCompany .cell.edit-cell {
  width: 20px;
  text-align: center;
}
.InsuranceCompany .pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  padding-inline-start: 0px;
}
.InsuranceCompany .pagination LI {
  display: inline-block;
  cursor: pointer;
  color: black;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  padding: 10px 0px 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}
.InsuranceCompany .pagination LI A {
  padding: 0px 16px;
  display: inline-block;
}
.InsuranceCompany .pagination LI.active {
  background-color: #013648;
  color: white;
}
.InsuranceCompany .pagination LI.previous.disabled,
.InsuranceCompany .pagination LI.next.disabled {
  opacity: 0.5;
  cursor: unset;
}
.InsuranceCompany .pagination LI:hover:not(.active) {
  background-color: #ddd;
}
.InsuranceCompany .dc-symptoms-result {
  min-height: 33px;
  height: auto;
  min-width: 107px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  display: none;
  position: absolute;
  bottom: 7px;
  left: 40%;
  white-space: nowrap;
  max-height: 100px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}
.InsuranceCompany .dc-symptoms-result::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(255, 255, 255, 0);
}
.InsuranceCompany .dc-symptoms-result .dc-symptoms-result-inner {
  padding: 8px 10px 4px;
  display: inline-block;
  width: 100%;
}
.InsuranceCompany .dc-symptoms-result .dc-symptoms-inner {
  display: block;
  width: 100%;
}
.InsuranceCompany .Cell:hover .dc-symptoms-result {
  display: block;
  z-index: 3;
}
.InsuranceCompany .pointer {
  cursor: pointer;
}
.InsuranceCompany IMG.edit-icon {
  width: 24px;
  margin: auto;
  display: block;
  cursor: pointer;
}
.InsuranceCompany .search {
  margin: 15px 0px;
  float: right;
  display: flex;
}
/* .InsuranceCompany .search INPUT {
  padding: 5px;
  padding-right: 50px;
  font-family: Calibre Medium;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  color: #013648;
} */
.InsuranceCompany .search DIV {
  position: relative;
}
/* .InsuranceCompany .search BUTTON {
  padding: 5px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
} */
.InsuranceCompany .search BUTTON.btn-clear {
  position: absolute;
  right: 0;
  color: #013648;
  margin-top: 4px;
  margin-right: 0px;
  opacity: 0.7;
  background: transparent;
  border: none;
  padding: 5px 10px;
}
.InsuranceCompany .search button.btn-clear IMG {
  width: 15px;
}
.InsuranceCompany .btn-add-new {
  padding: 4px 20px 2px;
  font-family: Calibre Medium;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 1;
  background-color: #013648;
  border-color: #013648;
  color: white;
  cursor: pointer;
  margin: 15px 0px;
  border-radius: 5px;
  height: 40px;
  border: 0;
}
.InsuranceCompany .dataCount {
  font-size: 16px;
}
