.InsuranceTab {
  display: flex;
  margin: 0 -15px;
}
.InsuranceTab .rightpanle {
  padding: 0 15px;
  width: 53%;
}
@media screen and (max-width: 1200px) {
  .InsuranceTab .rightpanle {
    width: 100%;
  }
}
