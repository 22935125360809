.ActivityItem {
  display: inline-block;
  width: 100%;
  border-radius: 8px;
  border: solid 1px #f7f9f9;
  margin-bottom: 9px;
  padding: 19px 17px 12px 28px;
  position: relative;
  overflow: hidden;
}
.ActivityItem:after {
  content: '';
  width: 8px;
  background-color: #199daf;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
.ActivityItem.cancelled:after {
  background-color: #f37184;
}
.ActivityItem.cancelled {
  background-color: #f7f9f9;
}
.ActivityItem.pending:after {
  background-color: #f2962d;
}
.ActivityItem.cancelled .day,
.ActivityItem.cancelled .name,
.ActivityItem.cancelled .view-patient {
  text-decoration: line-through;
}
.ActivityItem.completed::after {
  background-color: #f1f1f1;
}
.ActivityItem .day {
  font-size: 20px;
  letter-spacing: 0.17px;
  color: #013648;
  position: relative;
  padding-right: 25px;
  margin-bottom: 5px;
}
.ActivityItem .day img {
  width: 20px;
  position: absolute;
  right: 0;
}
.ActivityItem .name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  line-height: 1.11;
  letter-spacing: 0.11px;
  color: #013648;
}
.ActivityItem A,
.ActivityItem .link-span {
  display: inline-block;
  width: 100%;
  line-height: 1.25;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #26acda;
  cursor: pointer;
}
.ActivityItem .view-patient {
  margin-bottom: 15px;
}
.ActivityItem .cancel-staus {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.11px;
  color: #798380;
}
