.newFooter{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.newFooter .main-title{
  font-family: 'Calibre Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #013648;
    opacity: .8;
}
.newFooter .each-block ul{
  padding: 0;
  list-style: none;
}
.newFooter .each-block ul li{
  margin-bottom: 5px;
  font-family: 'Calibre Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}
.newFooter .each-block ul li a {
  color: #013648;
  /* text-decoration: underline;
  text-decoration-style: dashed; */
  text-decoration-thickness: 1px;
  cursor: pointer;
}
.newFooter .each-block ul li a:hover {
  text-decoration-style: solid;
  text-decoration: underline;
}
.footer-sociallink{
  display: none;
  text-align: center;
}
.footer-sociallink.show{
  display: block
}
.mobilefooternew{
  color: #979ea6;
  text-align: center;
  font-size: 19px;
  padding: 20px 0;
  display: none
}
.mobilefooternew.show{
  display: block;
}
.mobilefooternew a{
  margin-right: 5px;
  margin-left: 5px;
  color: #013648;
  display: inline-block;
}
.mobilefooternew a:hover{
text-decoration: underline;
}
.avoid-margin{
  margin-top: 0 !important;
}
.avoid-margin .hideonmobile{
  display: none;
}
.footercopy-right{
  color: #979ea6;
}
.accessibilityWidget{
  color: #013648;
  text-decoration-thickness: 1px;
  cursor: pointer;
}
.accessibilityWidget:hover{
  text-decoration: underline;
}
@media (max-width: 1000px) {
  .hideonmobile,
  .new-footer-parent{
    display: none;
  }
  .mobilefooternew{
    display: block;
  }
  .footer-sociallink{
    display: block;
    text-align: center;
    
  }
  .footer-sociallink img{
    width: 30px;
    height: 30px;
    margin-right:5px;
    margin-left: 5px;
  }
}

@media (max-width: 991px) {
  .newFooter{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 600px) {
  .newFooter{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 500px) {
  .newFooter{
    display: grid;
    grid-template-columns: 1fr ;
  }
}