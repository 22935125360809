.WidgetTab {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 27px 36px 40px 40px;
  margin-bottom: 40px;
  width: 53%;
  min-width: 620px;
}
.WidgetTab .h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: #013648;
  letter-spacing: -0.2px;
  margin-bottom: 17px;
}
.WidgetTab .widget-textarea,
.WidgetTab .widget-textarea textarea {
  background-color: #f7f9f9;
  color: #72787d;
}
.WidgetTab .widget-textarea {
  padding: 18px 0px;
}
.WidgetTab .MyLink {
  color: #26acda;
}
.WidgetTab .heading {
  margin-bottom: 16px;
  margin-top: 23px;
}
.WidgetTab button {
  background-color: #013648;
  font-size: 20px;
  font-family: 'Calibre Medium';
  cursor: pointer;
  border: 0;
  border-radius: 2px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  text-align: center;
  color: #f5f5f2;
  padding: 14px 24px 10px 49px;
  position: relative;
}
.WidgetTab button img {
  position: absolute;
  width: 24px;
  left: 16px;
}
.WidgetTab .copy-link {
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.WidgetTab .subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: #013648;
  margin-bottom: 34px;
}
.WidgetTab .Textarea.form-group .label {
  display: none;
}
.WidgetTab .widget-textarea textarea {
  padding-left: 29px;
  padding-right: 34px;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.3px;
  color: #72787d;
  height: 150px;
}
.WidgetTab .Textarea.form-group .form-control:focus,
.WidgetTab .Textarea.form-group .form-control:not(:placeholder-shown) {
  border: 0;
  outline: 0;
}
.WidgetTab .Textarea.form-group {
  margin-bottom: 13px;
}
.WidgetTab .booking-widget .label-text {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #72787d;
  margin-bottom: 10px;
}
.GoogleMyBusinessAppointment{
  color: #72787d;
}
.GoogleMyBusinessAppointment h4{
  font-size: 18px;
    font-weight: 500;
    line-height: 1.33;
    color: #013648;
    letter-spacing: -0.2px;
    margin-bottom: 10px;
}
.GoogleMyBusinessAppointment p{
margin-top: 0;

}
@media (hover: hover) {
  .WidgetTab button:hover {
    opacity: 0.8;
  }
}
@media (max-width: 1024px) {
  .WidgetTab {
    min-width: 100%;
  }
}
@media (max-width: 413px) {
  .WidgetTab {
    padding: 20px 15px;
  }
}
