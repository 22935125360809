.CancelSubscription {
  width: 570px;
}
.CancelSubscription .modal-content-footer .CircleButton.WhiteButton {
  width: 190px;
}
.RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton{
  width: 175px;
}
.CancelSubscription .modal-content-footer {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}
.CancelSubscription .modal-content-footer{
  padding: 11px 15px 15px;
  border-top: .5px solid #01364824
}
@media screen and (max-width: 600px) {
  .RemoveStripeSubscription,
  .CancelSubscription {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .CancelSubscription .modal-content-footer{
    flex-direction: column-reverse;
  }
  .RemoveStripeSubscription .modal-content-footer .CircleButton.WhiteButton,
  .CancelSubscription .modal-content-footer .CircleButton.WhiteButton {
    width: 100%;
  }
 
}