.AddingProviderSeatModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 575px;
  margin: auto;
}
.AddingProviderSeatModal .modal-content-header {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 41px 10px 47px;
  /* border-bottom: 1px solid #f1f1f5; */
  background-color: #013648;
}
.AddingProviderSeatModal .modal-content-header .close {
  width: 18px;
  height: 18px;
  display: inline-block;
  cursor: pointer;
}
.AddingProviderSeatModal .modal-content-header .close IMG {
  width: 100%;
}
.AddingProviderSeatModal .modal-content-header .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.2px;
  color: #fff;
}
.AddingProviderSeatModal .modal-content-body {
  padding: 23px 41px 13px 47px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AddingProviderSeatModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.AddingProviderSeatModal .modal-content-footer {
  padding: 23px 47px 33px;
}
.AddingProviderSeatModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AddingProviderSeatModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}

.AddingProviderSeatModal .provider-description .description SPAN {
  color: #013648;
}
.AddingProviderSeatModal .provider-description .description {
  font-size: 16px;
  line-height: 1.44;
  letter-spacing: normal;
  color: #72787d;
}
.AddingProviderSeatModal .provider-description .name {
  font-size: 24px;
  font-family: 'Calibre Medium';
  line-height: 0.96;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 16px;
}
.AddingProviderSeatModal .provider-description .email {
  height: 30px;
  background-color: rgba(1, 54, 72, 0.1);
  display: inline-flex;
  padding: 0 14px;
  margin-bottom: 28px;
}
.AddingProviderSeatModal .provider-description .email IMG {
  width: 13px;
  margin-left: 10px;
  cursor: pointer;
}
.AddingProviderSeatModal .provider-description .email SPAN {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
  padding-top: 2px;
}
.AddingProviderSeatModal .modal-content-footer BUTTON {
  height: 32px;
  border-radius: 2px;
  font-size: 16px;
  font-family: 'Calibre Medium';
  line-height: 1.38;
  letter-spacing: -0.2px;
  text-align: center;
  color: #72787d;
  background-color: #f1f1f1;
  border: 0;
  margin-right: 10px;
  padding: 6px 15px 9px;
  cursor: pointer;
  outline: 0;
}
.AddingProviderSeatModal .modal-content-footer .save {
  background-color: #26acda;
  color: #ffffff;
}
.AddingProviderSeatModal .modal-content-footer {
  display: flex;
  justify-content: space-between;
}
.AddingProviderSeatModal .modal-content-footer IMG {
  object-fit: contain;
}
@media (max-width: 767px) {
  .AddingProviderSeatModal {
    width: 100%;
  }
  .AddingProviderSeatModal .modal-content-footer {
    flex-wrap: wrap;
  }
  .AddingProviderSeatModal .modal-content-footer IMG {
    margin-top: 15px;
    margin-left: auto;
  }
  .AddingProviderSeatModal .modal-content-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .AddingProviderSeatModal .modal-content-header,
  .AddingProviderSeatModal .modal-content-footer {
    padding-left: 15px;
    padding-right: 15px;
  }
}
