.ListyourpracticeIntro {
  background-color: #013648;
  display: flex;
  position: relative;
  overflow: hidden;
}
.ListyourpracticeIntro .Ac-left{
  width: 100%;
    padding-top: 150px;
    padding-left: 137px;
    padding-right: 137px;
    position: relative;
}
.ListyourpracticeIntro .Ac-left .practice-banner{
 display: grid;
 grid-template-columns: 60% 40%;
 padding: 0 100px;
 padding-bottom: 95px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .mainheadding {
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 50px;
line-height: 131.18%;
color: #FFFFFF;
margin-top: 10px;
margin-bottom: 10px;
padding-right: 10px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .textpara {
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 143.5%;
color: rgba(255, 255, 255, 0.6);
margin-top: 10px;
margin-bottom: 60px;
padding-right: 15px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .listyourpractice{
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 33.5px;
  padding: 13px 60px;
  color: #013648;
  font-size: 18px;
  padding-top: 17px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .landingpage{
  position: relative;
    float: right;
    top: 30px;
    width: 400px;
}
.ListyourpracticeIntro .Ac-left .practice-banner .design{
position: absolute;
}
.ListyourpracticeIntro .Ac-left .practice-banner .design1{
  right: -118px;
  top: 62px;
  }
  .ListyourpracticeIntro .Ac-left .practice-banner .design2{
    right: -68px;
    top: 161px;
    }
    .ListyourpracticeIntrobodyText .box div{
      padding: 10px
    }
    .img-listyourpractice .listyourpractice{
      display: none;
    }
    @media (max-width: 1400px) {
  .ListyourpracticeIntro .Ac-left .practice-banner{
    padding:0;
    padding-bottom: 50px;
}
    }
    @media (max-width: 1250px) {
      .ListyourpracticeIntro .Ac-left{
        padding-left:50px;
        padding-right:50px;
        padding-top: 150px;
    }
    
        }
        @media (max-width: 1200px) {
        .ListyourpracticeIntro .Ac-left .practice-banner .mainheadding{
          font-size: 45px;
      
        }
        .ListyourpracticeIntro .Ac-left .practice-banner  .text-div{
          padding: 30px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .landingpage{
          width: 100%;
        }
    
      }
      @media (max-width: 997px) {
        .ListyourpracticeIntro .Ac-left .practice-banner .mainheadding{
          font-size: 36px;
      
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .textpara{
          font-size: 20px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner  .text-div{
          padding: 20px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .mainheadding .text-div{
          padding: 30px;
        }
        .ListyourpracticeIntro .Ac-left .practice-banner .landingpage{
          width: 100%;
        }
    
      }
      @media (max-width: 767px) {
        .ListyourpracticeIntro .Ac-left{
          padding-left:50px;
          padding-right:50px;
          padding-top: 0;
      }
      .ListyourpracticeIntro .Ac-left .practice-banner  .text-div{
        padding: 0px;
        text-align: center;
      }
      .text-div .listyourpractice{
        display: none;
            }
            .ListyourpracticeIntro .Ac-left .practice-banner .textpara{
              margin-bottom: 15px;
            }
            .ListyourpracticeIntro .Ac-left .practice-banner{
              padding-bottom: 30px;
            }
            .img-listyourpractice{
              text-align: center;
            }
            .img-listyourpractice .listyourpractice{
              display: inline-block;
              margin-top: 50px;
            }
    }
    @media (max-width: 650px) {
      .ListyourpracticeIntro .Ac-left{
        padding-left:30px;
        padding-right:30px;
        padding-top: 0;
    }
    .Differentiateyourpractice .heading {
      font-size: 40px;
    }
    .Joinkalypsys .split-screeninput .form-row > .two-input{
      width: 100%;
    }
    .Joinkalypsys .split-screeninput .form-row {
      display: grid;
      justify-content:initial;
      width: 100%;
    }
    
    }
    @media (max-width: 650px) {
    .Team .title .heading2 {
      font-size: 40px;
      line-height: 1.17;
      text-align: center;
  }
}
  @media (max-width: 500px) {
    .Team .title .heading2 {
      font-size: 32px;
      line-height: 1.17;
  }
}
@media (max-width: 400px) {
  .Team .title .heading2 {
    font-size: 28px;
    line-height: 1.17;
}
}