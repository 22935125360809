.CalendarToolbar {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 17px;
  align-items: center;
}
.CalendarToolbar .date-range {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.11px;
  color: #013648;
}
.CalendarToolbar .date-range span {
  font-weight: normal;
}
.CalendarToolbar .cal-btn {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  margin: 0px 15px;
  cursor: pointer;
  display: inline-flex;
}
.CalendarToolbar .cal-btn img {
  margin: auto;
}
.CalendarToolbar .cal-btn:focus {
  outline: 0;
}
.CalendarToolbar .calendar-datepicker {
  display: inline-block;
  position: relative;
}
.CalendarToolbar .datepicker {
  position: absolute;
  z-index: 5;
  top: 40px;
  right: 0px;
}
.CalendarToolbar .arrow-btn {
  margin: 0px 5px;
}

@media (max-width: 767px) {
  .CalendarToolbar {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .CalendarToolbar > div {
    width: 100%;
  }
  .CalendarToolbar > div:last-child {
    text-align: right;
    margin-bottom: 10px;
  }
}
