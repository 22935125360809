.VideoSidebar {
  background: #ffffff;
  height: calc(100vh - 70px);
  overflow: hidden;
}
.VideoSidebar .video-sidebar-top {
  background-color: rgba(234, 244, 235, 0.3);
  padding: 25px 24px 22px;
}
.VideoSidebar .video-sidebar-top .room-name {
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #013648;
}
.VideoSidebar .video-sidebar-top .name {
  font-size: 20px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #013648;
}
.VideoSidebar .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: flex;
  width: 100%;
}
.VideoSidebar .tab-list-item {
  font-size: 18px;
  line-height: 1.12;
  letter-spacing: -0.2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 33px 10px 17px;
  flex: 1;
  text-align: center;
  color: #72787d;
}
.VideoSidebar .tab-list-item .tab-icon {
  margin-right: 7px;
  width: 16px;
}
.VideoSidebar .tab-list-item.tab-list-active {
  color: #013648;
  position: relative;
}
.VideoSidebar .tab-list-item.tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background-color: #013648;
}

@media (max-width: 767px) {
  .VideoSidebar {
    height: calc(100vh - 108px);
  }
  .VideoSidebar .video-sidebar-top {
    display: none;
  }
  .VideoSidebar .tab-list {
    position: fixed;
    left: auto;
    right: 30px;
    top: 0;
    z-index: 8;
    width: auto;
    text-align: right;
    border: 0;
  }
  /* .VideoSidebar .tab-content {
    display: none;
  } */
  .VideoSidebar .tab-list-item {
    width: auto;
    padding: 47px 0 32px;
    font-size: 21.6px;
    letter-spacing: -0.24px;
  }
  .VideoSidebar .tab-list-item:not(:last-child) {
    margin-right: 20px;
  }
  .VideoSidebar .tab-list-item .tab-icon {
    margin-right: 8px;
    width: 20px;
  }
  .VideoSidebar .tab-list-item .tab-icon IMG {
    width: 100%;
  }
}
@media (max-width: 485px) {
  .VideoSidebar .tab-list {
   top: 28px;
  }
}
@media (max-width: 413px) {
  .VideoSidebar .tab-list {
    right: 15px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .VideoSidebar .video-sidebar-top {
    padding: 10px 24px 10px;
  }
  .VideoSidebar .tab-list-item {
    padding: 13px 10px 10px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .VideoSidebar .tab-list-item {
    padding: 47px 0 38px;
  }
}
