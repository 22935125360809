.EachFeedback {
  margin-top: 36px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 49%;
  padding: 10px;
  grid-gap: 5px;
  /* gap: 10px; */
  display: flex;
  flex-direction: column;
  /* grid-template-columns: 40% 58%; */
  border: 1px solid #9993;
  border-radius: 5px;
}

.EachFeedback .col-left {
  display: flex;
  flex-direction: column;
  
    color: white;
   

}
.EachFeedback .col-left .content {
  padding: 10px;
  display: flex;
  /* grid-template-columns: 50% 50%; */
  flex-wrap: wrap;
  border-radius: 5px;
}
.EachFeedback .col-left .content .each-q{
  width: 50%;
}
.EachFeedback .col-left .content.completed{
  background-color: #013648;
 
}
.EachFeedback .col-left .content.pending{
background-color: #999;
}
.EachFeedback .col-left .each-q {
  display: flex;
  flex-direction: column;
}
.EachFeedback .col-left .each-q label.title {
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.EachFeedback .col-left .each-q label.ans {
  margin-bottom: 10px;
  font-size: 13px;
  opacity: .7;
}

.EachFeedback .col-right .each-block {
  padding: 10px;
  display: flex;
    flex-direction: column;
}
.EachFeedback .col-right .each-block label.title {
  font-size: 16px;
  font-weight: bold;
}
.EachFeedback .col-right .each-block  label.labeltext {
  font-size: 13px;
  color: #999;
  margin-top: 10px;
}
.EachFeedback .col-right .each-block  .slider-maindiv{
  padding: 5px;
  margin-bottom: 20px;
}
.EachFeedback .col-right .each-block textarea.textarea-comments:focus-visible{
border-color: #999 !important;
outline: none;
}
.EachFeedback .col-right .each-block .savebtn{
margin-left:0 !important;
}
.comment-label{
  font-weight: bold;
  font-size: 18px;
  color: #666;
  text-decoration: underline;
  margin: 15px 0;
}
.comment-text{
font-size: 16px;
color: #666;
}
.EachFeedback .auto-save{
  position: absolute;
    right: 0;
    bottom: 0px;
    font-size: 17px;
    color: #24ACD9;
}
@media screen and (max-width: 561px) {
  .EachFeedback {
    width: 100%;
  }
}
