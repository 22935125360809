.Differentiateyourpractice {
  margin-top:50px;
  text-align: center;
  margin-bottom: 80px;
}
.Differentiateyourpractice .heading {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 64px;
  line-height: 118.18%;
  max-width: 670px;
  color: #013648;
  margin: 0 auto;
  margin-bottom: 30px;
}
.Differentiateyourpractice .content {
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 143.5%;
  text-align: center;
  margin: 0 auto;
  color: #013648;
  width: 472px;
  margin-bottom: 34px;
}
.Differentiateyourpractice .googleLogo {
  margin: 0 auto;
  width: 238px;
}
.Differentiateyourpractice .Strengthen {
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 118.18%;
  text-align: center;
  color: #013648;
}
.Differentiateyourpractice .Ensureyouronline {
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 143.5%;
  text-align: center;
  color: #013648;
  width: 501px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.Differentiateyourpractice .listyourpractice {
  width: 193px;
  height: 41px;
  background: #013648;
  border: 1px solid #013648;
  box-sizing: border-box;
  border-radius: 33.5px;
  color: #fff;
  padding: 10px 42px;
  padding-top: 13px;
  margin-bottom: 30px;
  display: inline-block;
}
.TakingPoint-parent-professional .h3{
  font-family: 'Calibre Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 118.18%;
  text-align: center;
  color: #013648;
  margin-bottom: 20px;
}
.TakingPoint-parent-professional img{
  width: 230px;
}
.TakingPoint-parent-professional .paragraph{
  font-family:'Calibre Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 143.5%;
    text-align: center;
    color: #013648;
    width: 501px;
    margin: 0 auto;
    margin-bottom: 50px;
}
.Differentiateyourpractice .Acommitment {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 118.18%;
  text-align: center;
  color: #013648;
  margin-top: 0;
  margin-bottom: 20px;
}
.Differentiateyourpractice .price-per-month {
  width: 411px;
  height: 482px;
  background: rgba(86, 192, 218, 0.2);
  border: 1px solid #56c0da;
  box-sizing: border-box;
  border-radius: 9px;
  margin: 0 auto;
}
.Differentiateyourpractice .price-per-month .permonth {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 137%;
  margin-top: 40px;
  color: #3d93a5;
  margin-bottom: 20px;
}
.Differentiateyourpractice .price-per-month .discription {
  font-family: 'Gilroy-Regular';
  font-size: 18px;
  line-height: 147%;
  text-align: center;
  color: #013648;
  width: 254px;
  margin: 0 auto;
}
.Differentiateyourpractice .price-per-month .discription2box {
  background: rgba(255, 255, 255, 0.73);
  border: 1px solid #bed9df;
  box-sizing: border-box;
  border-radius: 5px;
  width: 352px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 106px;
  padding: 14px;
  margin-bottom: 50px;
  padding-top: 19px;
}
.Differentiateyourpractice .price-per-month .discription2box .discription {
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 153.5%;
  text-align: center;
  color: #e75d72;
  width: 272px;
}
.Differentiateyourpractice .price-per-month .listyour-practice {
  background: #013648;
  border: 1px solid #013648;
  box-sizing: border-box;
  border-radius: 33.5px;
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 95%;
color: #FFFFFF;
padding: 10px 60px;
}
.doctorprofiledashboard{
  width: 100%;
}
.commitment-fair-session{
  margin-top: 30px;
}
.TakingPoint-parent-professional{
  margin-bottom: 30px;
}
.googleLogo-div{
  width: 259px;
  margin: 0 auto;
  height: 68px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}
.googleLogo-div img{
  width: 74%;
  margin: 0 auto;
 }
 .googleLogo-div img.healthgrades-svg{
  width: 95%;
  top: -7px;
  position: inherit;
 }
 .googleLogo-div img.google-svg{
   width: 76%;
 }
@media (max-width: 1200px) {
  .Differentiateyourpractice .doctorprofiledashboard{
width: 100%;
}
}
@media (max-width: 948px) {
  .ListyourpracticeIntrobodyText .box{
    height: auto;
  }
  
}
@media (max-width: 767px) {
.ListyourpracticeIntro .Ac-left .practice-banner{
  grid-template-columns: 1fr;
}
.ListyourpracticeIntrobodyText .box div{
  margin-bottom: 30px;
}
.Differentiateyourpractice{
  margin-top: 0; 
 }
.ListyourpracticeIntrobodyText .box{
  grid-template-columns: 1fr;
  height: auto;
  
}
.Differentiateyourpractice .heading,
.Differentiateyourpractice .content,
.Differentiateyourpractice .Ensureyouronline,
.ListyourpracticeIntrobodyText .maintext{
  width: 100%;
  text-align: center;
}
.TakingPoint-parent-professional .paragraph{
  width: 100%;
}
.ListyourpracticeIntrobodyText .maintext{
  font-size: 27px;
}
.Differentiateyourpractice{
  margin-bottom: 40px;
}

.Differentiateyourpractice .heading{
  font-size: 45px;
}
.Differentiateyourpractice .content{
  font-size: 18px;
}
}
@media (max-width: 500px) {
.Differentiateyourpractice .price-per-month{
width: 100%;
}
.Differentiateyourpractice .price-per-month .discription2box{
  width: 95%;
  /* height: 119px; */
}
.Joinkalypsys .title .heading2{
  font-size: 42px;
}
}
@media (max-width: 400px) {
  .Differentiateyourpractice .Strengthen,
.TakingPoint-parent-professional .h3{
font-size: 19px;
}
.Differentiateyourpractice .heading{
  font-size: 30px;
}
.Differentiateyourpractice .Ensureyouronline,
.TakingPoint-parent-professional .paragraph{
  font-size: 16px;
}
.Differentiateyourpractice .price-per-month .discription2box .discription{
  width: 100%;
}
.Differentiateyourpractice .price-per-month .discription{
  width: 90%;
}
}
@media (max-width: 360px){
  .Differentiateyourpractice .price-per-month {
    height: 530px;
  }
}