.ReviewVisitModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 678px;
}
.ReviewVisitModal .error-text {
  position: absolute;
  right: 35px;
  bottom: 4px;
  font-size: 16px;
  line-height: 2.25;
  letter-spacing: -0.2px;
  text-align: center;
  color: #e85d72;
  width: 298px;
}
.ReviewVisitModal .anonymous-txt {
  font-size: 16px;
}
.ReviewVisitModal .Textarea .error-text {
  left: 0;
  right: auto;
  width: auto;
  bottom: -2px;
}
.ReviewVisitModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.ReviewVisitModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.ReviewVisitModal .modal-content-header .close img {
  width: 100%;
}
.ReviewVisitModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.ReviewVisitModal .modal-content-body {
  padding: 29px 35px;
  max-height: calc(100vh - 220px);
  overflow: auto;
  background-color: #fbfbf9;
  -webkit-overflow-scrolling: touch;
}
.ReviewVisitModal .modal-content-footer {
  padding: 23px 35px 33px;
  background-color: #fbfbf9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 46px;
  position: relative;
}
.ReviewVisitModal .modal-content-footer .CircleButton {
  width: 298px;
  height: 45px;
  border-radius: 22.5px;
  font-size: 18px;
  line-height: 45px;
  letter-spacing: -0.34px;
  text-align: center;
  font-family: 'Calibre Regular';
}
.ReviewVisitModal .Divider {
  margin-top: 10px;
}
.ReviewVisitModal .AppointmentItem {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
  padding: 0;
}
.ReviewVisitModal .AppointmentItem:last-child {
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.ReviewVisitModal .AppointmentItem .ap-dc-info {
  display: flex;
}
.ReviewVisitModal .AppointmentItem .ap-dc-info .dc-img {
  min-width: 67px;
  min-height: 100px;
  width: 67px;
  height: 100px;
}
.ReviewVisitModal .AppointmentItem .ap-dc-info .dc-img img {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.ReviewVisitModal .AppointmentItem .dc-info {
  padding-left: 26px;
}
.ReviewVisitModal .AppointmentItem .dc-info .name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.ReviewVisitModal .AppointmentItem .dc-info .dc-specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.ReviewVisitModal .AppointmentItem .dc-info .address {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  display: flex;
  align-items: flex-start;
  padding-top: 10px;
}
.ReviewVisitModal .AppointmentItem .dc-info .dc-guardian {
  padding-top: 23px;
}
.ReviewVisitModal .AppointmentItem .dc-info .address .icon,
.ReviewVisitModal .AppointmentItem .dc-info .dc-guardian .icon {
  width: 14px;
  margin-right: 14px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time {
  width: 120px;
  min-height: 120px;
  margin-left: auto;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  background-color: #198754;
  border: 0;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.38px;
  color: #fff;
  margin-bottom: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time span {
  display: inline-block;
  width: 100%;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time .day {
  font-family: 'Calibre Medium';
}
.ReviewVisitModal .AppointmentItem .ins-info {
  color: #26acda;
  cursor: pointer;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .book-appointment {
  position: relative;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .book-appointment .appointment-action {
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 0 10px 1px #eaeaea;
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  overflow: hidden;
}
.ReviewVisitModal .AppointmentItem .ap-dc-timeinfo {
  width: 200px;
  min-width: 200px;
}
.̀ReviewVisitModal .AppointmentItem.confirmed .ap-dc-timeinfo .appointment-time,
.ReviewVisitModal .AppointmentItem.completed .ap-dc-timeinfo .appointment-time {
  background-color: #198754;
  border-color: #198754;
  color: #f1f1f1;
}
.ReviewVisitModal .AppointmentItem.pending .ap-dc-timeinfo .appointment-time {
  border: solid 1px #013648;
  background-color: transparent;
  color: #013648;
}
.ReviewVisitModal .AppointmentItem.pending .dc-info .address,
.ReviewVisitModal .AppointmentItem.pending .dc-info .dc-guardian {
  color: #013648;
}

.ReviewVisitModal .recent-visit-info {
  padding-top: 12px;
}
.ReviewVisitModal .recent-visit-info .visit-description {
  position: relative;
}
.ReviewVisitModal .recent-visit-info .total-character {
  text-align: right;
  font-size: 16px;
  line-height: 1.58;
  letter-spacing: -0.21px;
  color: #b2bcc4;
  position: absolute;
  right: 0;
  bottom: 0;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-text {
  font-size: 18px;
  font-family: 'Calibre Medium';
  line-height: 1.68;
  letter-spacing: -0.18px;
  color: #013648;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list .answer {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list {
  border-bottom: solid 1px rgba(32, 58, 112, 0.08);
  padding-top: 33px;
}
.ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list:first-child {
  padding-top: 0;
}
.ReviewVisitModal .privacy-text {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.2px;
  color: #72787d;
  margin-top: 27px;
}
.ReviewVisitModal .Checkbox-control {
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  padding-left: 30px;
}
@media (max-width: 767px) {
  .ReviewVisitModal {
    width: 100%;
    border-radius: 0;
    min-width: 100%;
    max-width: 100%;
  }
  .ReviewVisitModal .modal-content-body {
    height: calc(100vh - 300px);
  }
  .ReviewVisitModal .modal-content-footer {
    padding-bottom: 30px;
  }
  .ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list .answer {
    flex-wrap: wrap;
  }
  .ReviewVisitModal .recent-visit-info .visit-feedback-rating .question-list .answer .AnswerRating {
    width: 100%;
  }
  .ReviewVisitModal .Checkbox-control {
    width: 100%;
    margin-bottom: 15px;
  }
  .ReviewVisitModal .modal-content-footer {
    flex-wrap: wrap;
  }
  .ReviewVisitModal .modal-content-footer .CircleButton {
    width: 100%;
  }
  .ReviewVisitModal .modal-content-footer .error-text {
    position: absolute;
    width: 100%;
    display: inline-block;
    text-align: center;
    left: 0;
    line-height: normal;
    bottom: 5px;
    margin-top: 10px;
  }
  .ReviewVisitModal .ap-dc-timeinfo {
    margin-bottom: 23px;
  }
  .ReviewVisitModal .AppointmentItem .ap-dc-timeinfo .appointment-time {
    margin-bottom: 0;
  }
  .ReviewVisitModal .AppointmentItem .dc-info .name {
    font-size: 18px;
  }
  .ReviewVisitModal .AppointmentItem .dc-info .dc-specialist {
    font-size: 16px;
  }
  .ReviewVisitModal .modal-content-body {
    padding: 29px 30px;
  }
  .ReviewVisitModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .ReviewVisitModal .AppointmentItem {
    padding-bottom: 30px;
  }
  .ReviewVisitModal .modal-content-header {
    padding: 0 20px 0 20px;
    height: 65px;
  }
  .ReviewVisitModal .modal-content-header .h3 {
    font-size: 24px;
  }
}
@media (max-width: 413px) {
  .ReviewVisitModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .ReviewVisitModal .modal-content-body {
    padding: 29px 20px;
  }
  .ReviewVisitModal .modal-content-body {
    max-height: calc(100vh - 222px);
  }
  .ReviewVisitModal .modal-content-footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
