.WaitingRoomBlock {
  background-color: #013648;
  padding: 97px 20px 45px;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.WaitingRoomBlock .sub-heading {
  opacity: 0.6;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: -0.13px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 4px;
}
.WaitingRoomBlock .heading2 {
  font-size: 36px;
  font-family: 'Heldane Display';
  font-weight: normal;
  line-height: 0.94;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 38px;
}
.WaitingRoomBlock .doctor-img {
  width: 127px;
  height: 189px;
  display: block;
  margin: 0 auto 40px;
}
.WaitingRoomBlock .doctor-img IMG {
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.WaitingRoomBlock .detail-block {
  border-top: 1px solid rgba(241, 241, 245, 0.3);
  padding-top: 25px;
  text-align: center;
  max-width: 250px;
  width: 100%;
  margin: 0 auto 90px;
}
.WaitingRoomBlock .detail-heading {
  position: relative;
  font-size: 20px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.08;
  letter-spacing: -0.22px;
  color: #ffffff;
  padding-left: 37px;
  margin-bottom: 16px;
  display: inline-block;
}
.WaitingRoomBlock .detail-heading .schedule-icon {
  position: absolute;
  left: 12px;
  top: 0;
  width: 18px;
  height: 18px;
}
.WaitingRoomBlock .appoinment-detail {
  text-align: center;
}
.WaitingRoomBlock .appoinment-detail SPAN {
  display: block;
}
.WaitingRoomBlock .appoinment-detail .name {
  opacity: 0.8;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #ffffff;
  margin-bottom: 4px;
}
.WaitingRoomBlock .appoinment-detail .date-time {
  opacity: 0.8;
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.WaitingRoomBlock .appoinment-detail .time-duration {
  opacity: 0.8;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.17px;
  color: #ffffff;
}
.WaitingRoomBlock .sub-heading2 {
  opacity: 0.6;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.WaitingRoomBlock .waiting-bottom {
  margin-top: 145px;
}
.WaitingRoomBlock .waiting-bottom .para {
  opacity: 0.29;
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-bottom: 15px;
}
.WaitingRoomBlock .appoinment-link {
  opacity: 0.61;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  text-align: center;
  color: #ffffff;
  text-decoration: underline;
}

@media (max-width: 1024px) {
  .WaitingRoomBlock .heading2 {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .WaitingRoomBlock {
    padding: 85px 30px 65px;
  }
  .WaitingRoomBlock .appoinment-wrapper {
    position: relative;
    padding-left: 100px;
    text-align: left;
    margin-bottom: 130px;
  }
  .WaitingRoomBlock .heading2 {
    font-size: 24px;
    line-height: 1.42;
    text-align: left;
    margin-bottom: 16px;
    font-family: 'Calibre Regular';
  }
  .WaitingRoomBlock .sub-heading {
    margin-bottom: 40px;
  }
  .WaitingRoomBlock .doctor-img {
    position: absolute;
    left: 0;
    top: -4px;
    width: 80px;
    height: 119px;
    margin: 0;
  }
  .WaitingRoomBlock .doctor-img IMG {
    border-radius: 2.5px;
    border: solid 0.6px #f1f1f1;
  }
  .WaitingRoomBlock .detail-block {
    max-width: 100%;
    text-align: left;
    margin: 0;
  }
  .WaitingRoomBlock .detail-heading {
    font-size: 16.4px;
    letter-spacing: -0.18px;
    margin-bottom: 20px;
    padding-left: 22px;
  }
  .WaitingRoomBlock .appoinment-detail {
    text-align: left;
  }
  .WaitingRoomBlock .sub-heading2 {
    max-width: 212px;
    width: 100%;
    margin: 0 auto;
  }
  .WaitingRoomBlock .detail-heading .schedule-icon {
    left: 0;
    top: -1px;
    width: 15px;
    height: 15px;
  }
  .WaitingRoomBlock .waiting-bottom {
    margin-top: 115px;
  }
  .WaitingRoomBlock .appoinment-detail .date-time {
    font-size: 15px;
  }
  .WaitingRoomBlock .appoinment-detail .time-duration {
    font-size: 15px;
  }
}

@media (max-width: 413px) {
  .WaitingRoomBlock {
    padding: 85px 15px 65px;
  }
  .WaitingRoomBlock .sub-heading {
    font-size: 21px;
  }
  .WaitingRoomBlock .sub-heading2 {
    font-size: 21px;
  }
}
