.AppointmentConfirmedModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 678px;
  margin: auto;
}
.AppointmentConfirmedModal .modal-content-header {
  height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.AppointmentConfirmedModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.AppointmentConfirmedModal .modal-content-header .close img {
  width: 100%;
}
.AppointmentConfirmedModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
.AppointmentConfirmedModal .modal-content-body {
  padding: 23px 82px 23px 90px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.AppointmentConfirmedModal .modal-content-footer {
  padding: 23px 82px 33px 90px;
  display: flex;
}
.AppointmentConfirmedModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.AppointmentConfirmedModal .DoctorAppointmentTime {
  display: flex;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .appointment-time {
  min-width: 120px;
  height: 140px;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  border: solid 0.8px #013648;
  background-color: #013648;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.45px;
  color: #f7fffc;
  margin-bottom: 9px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .appointment-time span {
  display: inline-block;
  width: 100%;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .appointment-time .day {
  font-family: 'Calibre Medium';
}
.AppointmentConfirmedModal .DoctorAppointmentTime .dc-info {
  padding-left: 32px;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name {
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  font-family: 'Calibre Regular';
  margin-bottom: 35px;
}
.AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name a {
  color: #26acda;
}
.AppointmentConfirmedModal .social-calender .social-calender-title {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.AppointmentConfirmedModal .social-calender .social-link {
  display: flex;
  margin: 0px -15px;
}
.AppointmentConfirmedModal .social-calender .social-link a {
  font-size: 18px;
  line-height: 1.11;
  color: #26acda;
  padding: 5px 15px 2px;
  display: inline-block;
  border-right: solid 1px #e0e0e0;
}
.AppointmentConfirmedModal .social-calender .social-link a:last-child {
  border-right: 0;
}
.AppointmentConfirmedModal .Divider {
  margin: 25px 0px;
}
.AppointmentConfirmedModal .Divider .container {
  width: auto;
}
.AppointmentConfirmedModal .doctor-details {
  display: flex;
}
.AppointmentConfirmedModal .doctor-details .dc-img {
  width: 120px;
  min-width: 120px;
  height: 179px;
}
.AppointmentConfirmedModal .doctor-details .dc-img img {
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AppointmentConfirmedModal .doctor-details .doctor-info {
  padding-left: 32px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name {
  margin-bottom: 28px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .h3 {
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .speciality {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address,
.AppointmentConfirmedModal .doctor-details .doctor-info .insurance,
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent {
  display: flex;
  margin-bottom: 22px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent {
  margin-bottom: 0px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .text,
.AppointmentConfirmedModal .doctor-details .doctor-info .insurance .text,
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent .text {
  font-size: 18px;
  line-height: 1.17;
  letter-spacing: -0.23px;
  color: #72787d;
  padding-left: 15px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .icon img,
.AppointmentConfirmedModal .doctor-details .doctor-info .insurance .icon img,
.AppointmentConfirmedModal .doctor-details .doctor-info .paitent .icon img {
  width: 17px;
  height: 20px;
  object-fit: contain;
  position: relative;
  top: -3px;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .icon img {
  position: static;
}
.AppointmentConfirmedModal .doctor-details .doctor-info .address .text > span {
  display: inline-block;
  width: 100%;
}
.AppointmentConfirmedModal .reschedule .que {
  font-size: 24px;
  line-height: 0.79;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 17px;
}
.AppointmentConfirmedModal .reschedule .decription {
  font-size: 18px;
  line-height: 1.11;
  color: #72787d;
}
.AppointmentConfirmedModal .modal-content-footer .CircleButton {
  width: 100%;
}
.AppointmentConfirmedModal .modal-content-footer .cancel {
  display: inline-block;
}
.AppointmentConfirmedModal .modal-content-footer .cancel .CircleButton.SecondaryButton {
  width: 136px;
  margin-left: 21px;
  color: #e85d72;
  border-color: #e85d72;
}

@media (hover: hover) {
  .AppointmentConfirmedModal .social-calender .social-link a:hover {
    opacity: 0.7;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name a:hover {
    opacity: 0.7;
  }
  .AppointmentConfirmedModal .modal-content-footer .cancel .CircleButton.SecondaryButton:hover {
    background-color: #e85d72;
    color: #ffffff;
  }
}

@media (max-width: 767px) {
  .DoctorAppointmentTime .dc-info .name {
    font-size: 18px;
  }
  .DoctorAppointmentTime .dc-info .dc-specialist {
    font-size: 16px;
  }
  .AppointmentConfirmedModal {
    width: 100%;
  }
  .AppointmentConfirmedModal .modal-content-body {
    padding: 23px 20px 23px 20px;
  }
  .AppointmentConfirmedModal .Divider {
    display: block;
  }
  .AppointmentConfirmedModal .modal-content-footer {
    padding: 10px 20px 10px 20px;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime {
    flex-wrap: wrap;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime .dc-info {
    padding-left: 0px;
    margin-top: 20px;
  }
  .AppointmentConfirmedModal .modal-content-header {
    padding-left: 20px;
  }
  .AppointmentConfirmedModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .AppointmentConfirmedModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
  .AppointmentConfirmedModal .modal-content-footer {
    flex-wrap: wrap;
  }
  .AppointmentConfirmedModal .modal-content-footer .CircleButton {
    width: 100%;
    margin-bottom: 10px;
  }
  .AppointmentConfirmedModal .modal-content-footer .cancel {
    width: 100%;
  }
  .AppointmentConfirmedModal .modal-content-footer .cancel .CircleButton.SecondaryButton {
    width: 100%;
    margin-left: 0;
  }
  .AppointmentConfirmedModal .modal-content-header {
    height: 65px;
  }
  .AppointmentConfirmedModal .doctor-details .dc-img {
    width: 70px;
    min-width: 70px;
    height: 115px;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info {
    padding-left: 15px;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .h3 {
    font-size: 18px;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info .doctor-name .speciality {
    font-size: 16px;
  }
  .AppointmentConfirmedModal .Divider .container {
    padding: 0;
  }
  .AppointmentConfirmedModal .DoctorAppointmentTime .dc-info .name {
    margin-bottom: 0;
  }
}
@media (max-width: 413px) {
  .AppointmentConfirmedModal .doctor-details {
    flex-wrap: wrap;
  }
  .AppointmentConfirmedModal .doctor-details .doctor-info {
    padding-left: 0px;
    margin-top: 15px;
  }
}
@supports (-webkit-touch-callout: none) {
  .AppointmentConfirmedModal .modal-content-footer .CircleButton {
    padding-top: 2px;
  }
}
