.AboutYourPracticeStepTwo .h3 {
  font-size: 36px;
  font-weight: normal;
  font-style: normal;
  line-height: 0.94;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 13px;
  font-family: HeldaneDisplay;
}
.AboutYourPracticeStepTwo .paragraph {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 1);
  margin-bottom: 26px;
}
.AboutYourPracticeStepTwo .form-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.AboutYourPracticeStepTwo .form-row .column-9 {
  width: 71.3%;
}
.AboutYourPracticeStepTwo .form-row .column-3 {
  width: 25.5%;
}
.AboutYourPracticeStepTwo .signup-form .Input-control {
  margin-bottom: 18px;
}
.AboutYourPracticeStepTwo .signup-btn .CircleButton {
  width: 100%;
  height: 45px;
  margin-top: 22px;
}
.AboutYourPracticeStepTwo .two-input .inner-label {
  color: rgba(1, 54, 72, 0.5);
}
.AboutYourPracticeStepTwo .Input-control .form-control {
  border-width: 1px;
}
.AboutYourPracticeStepTwo .Input-control.form-group .form-control:focus,
.AboutYourPracticeStepTwo .Input-control.form-group .form-control:not(:placeholder-shown) {
  border-width: 1px;
}
.AboutYourPracticeStepTwo .stripe-input {
  border-width: 1px;
  border: solid 1.5px rgba(1, 54, 72, 0.2);
  padding: 13px 15px 0px 15px;
  height: 52px;
  border-radius: 2px;
  background-color: #ffffff;
  font-size: 18px;
  font-family: 'Calibre Regular';
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}

@media (max-width: 1024px) {
  .AboutYourPracticeStepTwo .h3 {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .AboutYourPracticeStepTwo .h3 {
    font-size: 28px;
    line-height: 1.21;
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepTwo .paragraph {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 40px;
    letter-spacing: normal;
  }
  .AboutYourPracticeStepTwo .signup-form .Input-control {
    margin-bottom: 20px;
  }
  .AboutYourPracticeStepTwo .signup-btn .CircleButton {
    margin-top: 9px;
  }
}
