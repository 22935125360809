.MyDatetime .rdtPicker {
  width: 497px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.5);
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 24px 0;
  padding-top: 70px;
  padding-bottom: 0;
  font-family: 'Calibre Regular';
  cursor: auto;
}
.MyDatetime .rdtPicker td.rdtToday:before {
  display: none;
}
.MyDatetime .rdtPicker .rdtDays table {
  border-collapse: separate;
  border-spacing: 30px 10px;
}
.MyDatetime.months .rdtPicker,
.MyDatetime.years .rdtPicker {
  padding-top: 15px;
}
.MyDatetime.time .rdtPicker {
  padding-top: 30px;
  width: 307px;
  height: 200px;
}
.MyDatetime.time .rdtCounterSeparator {
  line-height: 117px;
}
.MyDatetime.days .rdtPicker .rdtSwitch {
  display: inline-block;
  position: absolute;
  left: 36px;
  right: 36px;
  width: 140px;
  top: 50px;
  text-align: left;
  border: 0;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: #013648;
}
.MyDatetime.days .rdtPicker tfoot {
  display: none;
}
.MyDatetime.days .rdtPicker th.rdtNext,
.MyDatetime.days .rdtPicker th.rdtPrev {
  position: absolute;
  right: 64px;
  width: 26px;
  height: 26px;
  border-radius: 6.4px;
  border: solid 0.8px #e2e2ea;
  top: 37px;
}
.MyDatetime.days .rdtPicker th.rdtNext {
  right: 30px;
}
.MyDatetime.days .rdtPicker .rdtSwitch:after {
  content: '';
  height: 1px;
  background-color: #e2e2ea;
  position: absolute;
  left: 132px;
  right: 0;
  top: 0px;
  width: 240px;
}
.MyDatetime.days .rdtPicker th.rdtNext span,
.MyDatetime.days .rdtPicker th.rdtPrev span {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  position: relative;
  left: -1px;
}
.MyDatetime.days .rdtPicker .dow {
  font-family: 'Calibre Medium';
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  color: #92929d;
}
.MyDatetime.days .rdtPicker td {
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  color: #013648;
}
.MyDatetime .rdtPicker td.rdtActive{
  width: 30px;
  height: 33px;
  border-radius: 5px;
  background-color: rgba(1, 54, 72, 0.17);
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #013648;
  padding-top: 5px;
}
.MyDatetime.time .rdtCounter .rdtCount {
  height: 40%;
  line-height: 40px;
}
.MyDatetime .rdtPicker td.rdtDisabled{
  background: none;
  color: #c7bebe;
  cursor: not-allowed;
}

@media (hover: hover) {
  .MyDatetime .rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #c7bebe;
    cursor: not-allowed;
  }
  .MyDatetime .rdtPicker td.rdtActive:hover {
    width: 30px;
    height: 33px;
    border-radius: 5px;
    background-color: rgba(1, 54, 72, 0.17);
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    color: #013648;
    padding-top: 5px;
  }
  .MyDatetime.days .rdtPicker .rdtSwitch:hover {
    background-color: transparent;
  }
  .MyDatetime .rdtPicker td.rdtDay:hover {
    background: #d4dde0;
    cursor: pointer;
    border-radius: 5px;
  }
}

@media (max-width: 413px) {
  .MyDatetime.days .rdtPicker th.rdtNext {
    right: 10px;
  }
  .MyDatetime.days .rdtPicker th.rdtPrev {
    right: 40px;
  }
  .MyDatetime.days .rdtPicker .rdtSwitch {
    left: 15px;
  }
}
