.PatientLandingHeader {
  background-color: transparent;
  height: 110px;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
}
.PatientLandingHeader .header-content {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
  position: relative;
}
.PatientLandingHeader .header-content .right-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.PatientLandingHeader .header-content .left-col {
  padding-left: 112px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .PatientLandingHeader .header-content .right-col .menu-content {
  margin-left: auto;
} */
.desktopPricing {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.22px;
  padding-right: 50px;
  border-right: 1px solid #ffffff2e;
}
.mobile-Pricing {
  display: none !important;
}
.PatientLandingHeader .header-content .precticewith A {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.38px;
  color: #023648;
  display: inline-block;
  margin-right: 50px;
  opacity: 1;
  background: #ffffff;
  border-radius: 26.5px;
  padding: 5px 30px;
  width: 183px;
  padding-top: 10px;
}
.PatientLandingHeader .header-content .right-col .menu-content A {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.56;
  letter-spacing: -0.34px;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN {
  display: inline-block;
  padding: 0px 18px;
  position: relative;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN:first-child {
  padding-left: 49px;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN::after {
  content: '';
  position: absolute;
  width: 1px;
  background: #b2bcc4;
  left: 0;
  top: 0;
  bottom: 0px;
}
.PatientLandingHeader .header-content .right-col .menu-content.afternnone SPAN:after {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN:last-child:after {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content > SPAN:last-child {
  padding-right: 0px;
}
.PatientLandingHeader .header-content .logo {
  width: 158px;
}
.PatientLandingHeader .header-content .logo A {
  position: relative;
  top: 5px;
  cursor: initial;
}
.PatientLandingHeader .header-content .logo A IMG {
  width: 169px;
  cursor: pointer;
}
.PatientLandingHeader .logowhite {
  display: block;
}
.PatientLandingHeader .logoblue {
  display: none;
}
.PatientLandingHeader .header-bottom {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content SPAN.login-mobile {
  display: none;
}
.PatientLandingHeader .header-content .right-col .menu-content .login-web {
  padding-left: 0;
  position: relative;
  cursor: pointer;
}
.PatientLandingHeader .login-web > SPAN {
  color: #ffffff;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: -0.22px;
  padding-left: 50px;
}
.PatientLandingHeader .login-web > SPAN IMG {
  position: relative;
  top: 2px;
  left: 2px;
}
.PatientLandingHeader .right-col .menu-content .login-web .dropdown-menu {
  width: 197px;
  border-radius: 2px;
  background-color: #ffffff;
  position: absolute;
  right: 25px;
  padding-left: 0;
  transition: 0.3s all linear;
  top: 50px;
  opacity: 0;
  visibility: hidden;
}
.PatientLandingHeader .right-col .menu-content .login-web:hover .dropdown-menu {
  top: 30px;
  opacity: 1;
  visibility: visible;
}
.PatientLandingHeader .right-col .menu-content .login-web SPAN:first-child IMG {
  transition: 0.3s all linear;
}
.PatientLandingHeader .right-col .menu-content .login-web:hover SPAN:first-child IMG {
  transform: rotate(180deg);
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu > SPAN,
.PatientLandingHeader .menu-content .login-web .dropdown-menu > SPAN:first-child {
  width: 100%;
  padding: 14px 19px;
  line-height: normal;
  display: inline-block;
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN A {
  font-size: 16px;
  width: 100%;
  display: inline-block;
  color: #013648;
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu A > SPAN {
  font-family: 'Calibre Medium';
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN:not(:last-child) {
  border-bottom: 1px solid #f1f1f5;
}
.PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN:after {
  display: none;
}
.PatientLandingHeader .mobile-menu {
  display: none;
}
.PatientLandingHeader .header-content .right-col {
  padding-right: 86px;
}
@media (hover: hover) {
  .PatientLandingHeader .header-content .precticewith A:hover {
    /* color: rgba(255,255,255,0.6); */
  }
  .PatientLandingHeader .header-content .right-col .menu-content A:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN:hover {
    color: rgba(255, 255, 255, 0.6);
  }
  .PatientLandingHeader .menu-content .login-web .dropdown-menu SPAN A:hover {
    color: rgba(1, 54, 72, 0.6);
  }
}

@media (max-width: 1200px) {
  .PatientLandingHeader .header-content .left-col {
    padding-left: 30px;
  }
  .PatientLandingHeader .header-content .right-col {
    padding-right: 30px;
  }
  .PatientLandingHeader .header-content .precticewith A {
    margin-right: 30px;
  }
}

@media (max-width: 1024px) {
  .PatientLandingHeader .header-content .logo A IMG {
    width: 130px;
  }
  .PatientLandingHeader .header-content .precticewith A {
    font-size: 18px;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN {
    padding: 0px 20px;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN:first-child {
    padding-left: 30px;
  }
}

@media (max-width: 767px) {
  .PatientLandingHeader {
    height: 110px;
    position: static;
    background-color: #013648;
  }
  .desktopPricing {
    display: none;
  }
  .mobile-Pricing {
    display: inline-block !important;
  }
  .PatientLandingHeader .header-content .right-col {
    transition: 0.3s all linear;
    position: absolute;
    right: 0;
    top: -69px;
    left: 0;
    transition: 0.3s all linear;
    padding: 0 30px 20px;
    background-color: #013648;
    width: 100%;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    flex-wrap: wrap;
  }
  .PatientLandingHeader .header-content .right-col A,
  .PatientLandingHeader .header-content .right-col BUTTON {
    color: #fff;
    text-align: center;
  }
  .PatientLandingHeader .header-content .right-col BUTTON {
    border: solid 1px #ffffff;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN,
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN:first-child {
    text-align: left;
    padding: 10px 0;
    display: inline-block;
    width: 100%;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN A {
    font-family: 'Calibre Regular';
    font-size: 24px;
    line-height: 1.83;
    letter-spacing: -0.24px;
    color: #fbfbf9;
  }
  .PatientLandingHeader .header-content .precticewith {
    display: none;
  }
  .PatientLandingHeader.menu-active {
    background-color: #013648;
  }
  .PatientLandingHeader.menu-active .header-content .right-col {
    display: block;
    top: 69px;
    opacity: 1;
    visibility: visible;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN {
    background-color: #fff;
  }
  .PatientLandingHeader .header-content .logo A IMG {
    width: 128px;
  }
  /*hamburger icon*/
  .PatientLandingHeader .mobile-menu SPAN {
    width: 20px;
    display: inline-block;
    height: 2px;
    background-color: #ffffff;
    position: absolute;
    transition: 0.3s all linear;
    border-radius: 4px;
  }
  .PatientLandingHeader .mobile-menu SPAN:first-child {
    top: 0;
  }
  .PatientLandingHeader .mobile-menu SPAN:nth-child(2) {
    top: 9px;
  }
  .PatientLandingHeader .mobile-menu SPAN:last-child {
    top: 18px;
    width: 10px;
  }
  .PatientLandingHeader .mobile-menu {
    width: 20px;
    height: 18px;
    position: absolute;
    top: 8px;
    cursor: pointer;
    right: 30px;
  }
  .PatientLandingHeader.menu-active .right-col {
    right: 0px;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN:last-child {
    display: none;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN {
    background-color: #fff;
    transform: rotate(45deg);
    top: 9px;
  }
  .PatientLandingHeader.menu-active .mobile-menu SPAN:nth-child(2) {
    transform: rotate(-45deg);
    top: 9px;
  }
  .PatientLandingHeader .header-content .right-col .menu-content > SPAN::after {
    display: none;
  }
  .PatientLandingHeader .header-content .right-col .menu-content {
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
  }
  .PatientLandingHeader .header-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding-top: 25px;
    padding-bottom: 25px;
    border-top: solid 1px rgba(255, 255, 255, 0.08);
  }
  .PatientLandingHeader .header-bottom .provider-login {
    width: 100%;
    margin-bottom: 27px;
  }
  .PatientLandingHeader .header-bottom .provider-login A {
    color: #fbfbf9 !important;
  }
  .PatientLandingHeader .header-bottom > SPAN {
    display: inline-flex;
  }
  .PatientLandingHeader .header-bottom > SPAN A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.38px;
    color: rgba(251, 251, 249, 0.5) !important;
    display: inline-block;
  }
  .PatientLandingHeader .header-bottom .list-practice A {
    font-family: 'Calibre Regular';
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: -0.2px;
    color: rgba(251, 251, 249, 0.5) !important;
  }
  .PatientLandingHeader .header-bottom > SPAN A > SPAN {
    color: #fbfbf9;
  }
  .PatientLandingHeader.menu-active + .PatientLanding {
    margin-top: 108px;
  }
  .PatientLandingHeader .logo .logowhite {
    display: block;
  }
  .PatientLandingHeader.menu-active .logo IMG {
    display: none;
  }
  .PatientLandingHeader.menu-active .logo .logowhite {
    display: block;
  }
  .PatientLandingHeader .header-content .right-col .menu-content SPAN.login-mobile {
    display: block;
  }
  .PatientLandingHeader .header-content .right-col .menu-content .login-web {
    display: none;
  }

  @media (hover: hover) {
    .PatientLandingHeader .mobile-menu:hover SPAN:last-child {
      width: 20px;
    }
  }
}
@media (max-width: 413px) {
  .PatientLandingHeader .header-content .left-col {
    padding-left: 15px;
  }
  .PatientLandingHeader .mobile-menu {
    right: 15px;
  }
  .PatientLandingHeader .header-content .right-col {
    padding: 0 15px 20px;
  }
}
