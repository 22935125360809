.PatientTab .patient-details {
  padding: 51px 30px;
  height: calc(100vh - 349px);
  overflow-y: auto;
}
.PatientTab .date-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: normal;
  color: #013648;
}
.PatientTab .time-duration {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.17px;
  color: #013648;
  margin-bottom: 21px;
}
.PatientTab .sidebar-bottom {
  min-height: 59px;
}
.PatientTab .patientname-block {
  margin-bottom: 15px;
}
.PatientTab .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.11;
  letter-spacing: -0.09px;
  color: #013648;
  width: 100%;
  display: block;
}
.PatientTab .patient-link {
  font-family: 'Calibre Regular';
  font-weight: normal;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.11px;
  color: #26acda;
  cursor: pointer;
}
.PatientTab .patient-address {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 21px;
}
.PatientTab .patient-data {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 38px;
  width: 180px;
  max-width: 100%;
}
.PatientTab .patient-data SPAN {
  display: block;
}
.PatientTab .questionnaire .heading3 {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: normal;
  color: #013648;
}
.PatientTab .questionnaire SPAN {
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: normal;
  color: #013648;
  display: block;
  margin-bottom: 1px;
}
.PatientTab .upcoming-appoinment {
  padding: 16px 25px 42px 30px;
  border-top: 1px solid #f1f1f5;
}
.PatientTab .appoinment-heading {
  font-size: 18px;
  line-height: 1.78;
  letter-spacing: -0.2px;
  color: #013648;
  padding-left: 31px;
  padding-right: 20px;
  position: relative;
}
.PatientTab .appoinment-heading SPAN {
  display: block;
}
.PatientTab .appoinment-heading .schedule-icon {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  top: 4px;
}
.PatientTab .appoinment-heading .down-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  height: 9px;
  top: 10px;
}
.PatientTab .appoinment-detail {
  padding-top: 15px;
  display: none;
}
.PatientTab .appoinment-detail.show-appoinment {
  display: block;
}
.PatientTab .appname-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  flex-wrap: wrap;
}
.PatientTab .appname-block .patient-name {
  font-size: 18px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  letter-spacing: 0.11px;
  line-height: 1.25;
  color: #013648;
}
.PatientTab .appname-block .patient-link {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.1px;
  color: #26acda;
}
.PatientTab .appname-block .appname-left {
  flex: 1;
  margin-right: 10px;
}
.PatientTab .appname-block .appoinment-time {
  font-size: 20px;
  line-height: normal;
  letter-spacing: 0.17px;
  color: #013648;
}
.PatientTab .start-videolink {
  padding: 4px 10px 0px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  display: inline-block;
}
.PatientTab .noroom-txt {
  font-size: 18px;
  line-height: normal;
  letter-spacing: normal;
  color: #a2a2a2;
}
.PatientTab .link-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.PatientTab .participant-video {
  display: none;
}
@media (hover: hover) {
  .PatientTab .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1199px) {
  .PatientTab .appoinment-heading {
    font-size: 16px;
    padding-left: 28px;
  }
  .PatientTab .appoinment-heading .schedule-icon {
    top: 2px;
  }
  .PatientTab .appoinment-heading .down-arrow {
    top: 8px;
  }
  .PatientTab .noroom-txt {
    font-size: 16px;
  }
  .PatientTab .patient-details {
    padding: 15px 23px 15px 24px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 10px 34px 20px 24px;
  }
}

@media (max-width: 767px) {
  .PatientTab .patient-details {
    padding: 33px 25px;
  }
  .PatientTab .appoinment-heading {
    font-size: 18px;
    padding-left: 31px;
  }
  .PatientTab .appoinment-heading .schedule-icon {
    top: 4px;
  }
  .PatientTab .appoinment-heading .down-arrow {
    top: 10px;
  }
  .PatientTab .noroom-txt {
    font-size: 18px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 13px 30px 20px;
  }
  .PatientTab .participant-video {
    width: 99px;
    height: 176px;
    border-radius: 4.8px;
    position: fixed;
    bottom: 189px;
    right: 24px;
    border: solid 0.5px #979797;
    background-color: #1d1b1b;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .PatientTab .participant-video VIDEO {
    border-radius: 4.8px;
    width: 100%;
    height: 100%;
    background-color: #1d1b1b;
  }
}

@media (max-width: 413px) {
  .PatientTab .patient-details {
    padding: 30px 15px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 13px 15px 20px;
  }
}

@media (max-width: 991px) and (orientation: landscape) {
  .PatientTab .patient-details {
    padding: 12px 23px 12px 24px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 10px 34px 10px 24px;
  }
  .PatientTab .appoinment-detail {
    padding-top: 8px;
  }
  .PatientTab .appname-block {
    margin-bottom: 8px;
  }
}

@media (max-width: 767px) and (orientation: landscape) {
  .PatientTab .patient-details {
    padding: 12px 25px;
  }
  .PatientTab .upcoming-appoinment {
    padding: 10px 30px;
  }
  .PatientTab .participant-video {
    bottom: 100px;
  }
}
