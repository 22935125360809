.RequestReviews {
  width: 100%;
}
.RequestReviews .main-section-content {
  padding: 0 20px 0 40px;
}
.RequestReviews .setting-tabs .tab-list {
  border-bottom: 1px solid #f1f1f5;
  padding-left: 0;
  margin: 0;
  display: inline-block;
  width: 100%;
}
.RequestReviews .setting-tabs .tab-list-item {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
  display: inline-block;
  margin-right: 38px;
  cursor: pointer;
  padding-bottom: 12px;
}
.RequestReviews .setting-tabs .tab-list-item:first-child {
  margin-left: 0;
}
.RequestReviews .setting-tabs .tab-list-active {
  background-color: #fafafb;
  border-width: 0px 0px 4px 0px;
  opacity: 1;
  color: #013648;
  position: relative;
}
.RequestReviews .setting-tabs .tab-list-active:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
  background-color: #013648;
  border-radius: 4px 4px 0px 0px;
}
.RequestReviews .ReviewAccounts-content {
  padding: 0 40px;
}
.RequestReviews .box-panel {
  padding: 33px 37px;
  background-color: #fff;
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  margin-top: 31px;
  margin-bottom: 30px;
}
.RequestReviews .tab-content {
  position: relative;
}
.RequestReviews .AfterAuthSubHeader .main-inner-content {
  padding-right: 495px;
}
.RequestReviews .AfterAuthSubHeader .main-inner-content .title SPAN:first-child {
  min-width: 160px;
}
.RequestReviews .ReviewAccounts-content {
  position: relative;
}

.RequestReviews .SendingReviewRequest {
  border-radius: 8px;
  background-color: #ffffff;
  padding: 11px 25px 18px;
  border: solid 1px #e2e2ea;
  display: inline-block;
  position: absolute;
  width: 418px;
  right: 40px;
  top: -90px;
}
.RequestReviews .SendingReviewRequest .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.RequestReviews .SendingReviewRequest .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.RequestReviews .SendingReviewRequest .sub-text A {
  color: #26acda;
}
.RequestReviews .addwebsite-btn {
  width: 124px;
  border-radius: 2px;
  background-color: #26acda;
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: -0.2px;
  color: #ffffff;
  font-family: 'Calibre Medium';
  border: 0;
  padding: 8px 0px 2px;
  cursor: pointer;
  outline: 0;
  margin-left: 15px;
}
.RequestReviews .googlenotconnectmsg{
  font-size: 30px;
  padding-top: 150px;
  text-align: center;
  color: #9EAEB6;
  font-style: italic;
}
.RequestReviews .send-request-btn {
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #26acda;
}
.RequestReviews .send-request-btn IMG {
  position: relative;
  top: -3px;
  left: -7px;
}

@media (max-width: 1400px) {
  .RequestReviews {
    width: calc(100% - 250px);
  }
  .RequestReviews .box-panle {
    padding: 40px 20px;
  }
  .RequestReviews .box-panle .search-review-list {
    right: 20px;
  }
  .RequestReviews .box-panle .search-review-list .Input-control {
    width: 230px;
  }
  .RequestReviews .SendingReviewRequest {
    width: 300px;
    top: -99px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 55px;
  }
}

@media (max-width: 1200px) {
  .RequestReviews .box-panle .search-review-list .Input-control {
    width: 200px;
  }
  .RequestReviews .box-panle .search-review-list {
    top: 36px;
  }
  .RequestReviews .main-section-content {
    padding: 0 20px 0 20px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .title SPAN:first-child {
    min-width: 134px;
  }
  .RequestReviews .SendingReviewRequest {
    padding: 11px 10px 18px;
  }
  .RequestReviews .SendingReviewRequest .approval-action SPAN {
    margin-right: 15px;
  }
  .RequestReviews .SendingReviewRequest .approval-action SPAN:last-child {
    margin-right: 0;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 450px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
  }
  .RequestReviews .SendingReviewRequest {
    top: -149px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 400px;
  }
}

@media (max-width: 1024px) {
  .RequestReviews .main-section-content {
    padding: 0 15px 0;
  }
  .RequestReviews .box-panle .search-review-list {
    text-align: right;
    position: relative;
    margin-bottom: 0px;
    top: -14px;
    right: 0;
  }
  .RequestReviews .ReviewAccounts-content {
    padding: 0px 15px;
  }
  .RequestReviews .box-panel {
    padding: 15px;
  }
  .RequestReviews {
    width: calc(100% - 200px);
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .title {
    flex-wrap: wrap;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .name {
    padding-left: 0;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-bottom: 100px;
    padding-right: 370px;
  }
  .RequestReviews .SendingReviewRequest {
    top: -150px;
    right: 15px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content .title SPAN:first-child {
    width: 100%;
  }
  .AfterAuthSubHeader .main-inner-content .name:after {
    display: none;
  }
}

@media (max-width: 767px) {
  .RequestReviews .box-panle {
    padding: 20px;
  }
  .RequestReviews .box-panle .search-review-list {
    margin-top: 10px;
  }
  .RequestReviews {
    width: 100%;
  }
  .RequestReviews .setting-tabs .tab-list-item {
    margin-right: 20px;
    font-size: 18px;
  }
  .RequestReviews .AfterAuthSubHeader .main-inner-content {
    padding-right: 0;
    padding-bottom: 20px;
  }
  .RequestReviews .SendingReviewRequest {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .RequestReviews .SendingReviewRequest .approval-action SPAN {
    margin-right: 10px;
  }
  .RequestReviews .setting-tabs .tab-list-item:first-child {
    margin-bottom: 10px;
  }
}

@media (max-width: 414px) {
  .RequestReviews .setting-tabs .tab-list-item:first-child {
    margin-bottom: 10px;
  }
  .RequestReviews .send-request-btn,
  .RequestReviews .addwebsite-btn {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
}
