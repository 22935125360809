.EditProvider {
  width: 100%;
}
.EditProvider .dashboard-panle {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: space-between;
}
.EditProvider .setting-approvalcard {
  border-radius: 8px;
  background-color: #fff;
  padding: 11px 15px 18px;
  border: 1px solid #e2e2ea;
  display: block;
  right: 0px;
  top: -175px;
  width: 375px;
  margin-left: auto;
  margin-bottom: 10px;
}
.EditProvider .setting-approvalcard .heading3 {
  font-size: 20px;
  line-height: 1.6;
  color: #013648;
}
.EditProvider .setting-approvalcard .sub-text {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
}
.EditProvider .setting-approvalcard .approval-action {
  display: block;
  margin-top: 10px;
}
.EditProvider .setting-approvalcard .approval-action SPAN {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0;
  color: #013648;
  display: inline-block;
  margin-right: 10px;
  width: 29%;
}
.EditProvider .setting-approvalcard .approval-action SPAN:last-child {
  margin-right: 0;
}
.EditProvider .setting-approvalcard .approval-action SPAN IMG {
  width: 20px;
  margin-right: 8px;
  position: relative;
  top: 6px;
}

@media screen and (max-width: 1366px) {
  .EditProvider .setting-approvalcard {
    padding: 11px 10px 18px;
    width: 330px;
  }
  .EditProvider .setting-approvalcard .approval-action SPAN {
    margin-right: 33px;
  }
  .EditProvider .setting-approvalcard .approval-action SPAN IMG {
    margin-right: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .EditProvider .setting-approvalcard {
    top: -225px;
  }
}

@media screen and (max-width: 767px) {
  .EditProvider .setting-approvalcard {
    position: static;
    width: 100%;
    margin-bottom: 15px;
  }
  .EditProvider .setting-approvalcard .approval-action SPAN {
    margin-right: 10px;
    width: auto;
  }
}
