.KalypsysAdvisors {
  padding: 85px 0px;
}
.KalypsysAdvisors .title .heading2 {
  font-family: 'Heldane Display';
font-style: normal;
font-weight: normal;
font-size: 38px;
line-height: 118.18%;
text-align: center;
color: #013648;
}

.KalypsysAdvisors .team-slider {
  margin-top: 28px;
}
.KalypsysAdvisors .team-slider .TeamCard .team-img{
  width: 215px;
  height: 223px;
  overflow: hidden;
}
.KalypsysAdvisors .team-slider .TeamCard .team-img img{
  object-fit: cover;
}
.KalypsysAdvisors .team-slider .TeamCard .name{
  margin-top: 25px;
  font-family:'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 118.18%;
  color: #013648;
}
.KalypsysAdvisors .team-slider .TeamCard .place{
  font-family: 'Heldane Display';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 118.18%;
  color: #013648;
}
.KalypsysAdvisors .team-slider .TeamCard .description{
  font-family: 'Calibre Regular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 118.18%;
width: 218px;
color: #013648;
margin-top: 20px;
}
.KalypsysAdvisors .team-slider .team-slider-content {
  padding: 0 0px;
}
.KalypsysAdvisors .slick-slider.team-slider .slick-track {
  margin-left: -45px;
}
@media (max-width: 1024px) {
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  .KalypsysAdvisors {
    padding: 50px 0;
  }
  .KalypsysAdvisors .title .heading2 {
    font-size: 38px;
  }
}
@media (max-width: 767px) {
  .KalypsysAdvisors {
    padding: 40px 0;
    overflow: hidden;
  }
  .KalypsysAdvisors .title .heading2 {
    font-size: 48px;
    line-height: 1.17;
  }
  .KalypsysAdvisors .title {
    text-align: left;
  }
  .KalypsysAdvisors .team-slider {
    width: 800px;
  }
  .KalypsysAdvisors .team-slider .team-slider-content {
    padding: 0 15px;
  }
  .KalypsysAdvisors .team-slider {
    margin-top: 40px;
  }
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: 155px;
  }
}
@media (max-width: 576px) {
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: auto;
  }
  .KalypsysAdvisors .slick-slider.team-slider .slick-track {
    margin-left: auto;
  }
  .KalypsysAdvisors .slick-initialized .slick-slide {
    width: 300px !important;
    padding-left: 30px;
  }
}
