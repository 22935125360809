.AddAvailabilityBreak {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
}
.AddAvailabilityBreak .dailog-header {
  padding: 20px 65px 15px 30px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.AddAvailabilityBreak .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.2;
  color: #fff;
}
.AddAvailabilityBreak .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.AddAvailabilityBreak .dailog-close-btn IMG {
  position: absolute;
  left: -26px;
  top: -7px;
}
.AddAvailabilityBreak .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.AddAvailabilityBreak .dailog-body {
  padding: 20px 32px 15px;
}
.AddAvailabilityBreak .dailog-body .Input-control .view-img {
  left: 0;
  width: 24px;
  height: 24px;
  top: 57%;
}
.AddAvailabilityBreak .dailog-body .Select-control SELECT {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.AddAvailabilityBreak .virtual-field .Checkbox-control {
  font-size: 18px;
}
.AddAvailabilityBreak .dailog-body .Select-control {
  width: 255px;
  margin-bottom: 34px;
}
.AddAvailabilityBreak .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.AddAvailabilityBreak .action-btn .error {
  color: red;
  font-size: 16px;
}
.AddAvailabilityBreak .action-btn {
  text-align: right;
}
.AddAvailabilityBreak .select-date-content {
  padding-left: 42px;
  position: relative;
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  margin-bottom: 34px;
  line-height: 1.8;
  z-index: 100;
  display: flex;
}
.AddAvailabilityBreak .gray-box {
  position: relative;
  z-index: 1;
}
.AddAvailabilityBreak .gray-box .gray-back {
  background: #e9ebf2;
  padding: 8px 12px 5px 9px;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
  position: absolute;
  left: -8px;
  top: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  border-radius: 10px;
  z-index: -1;
}
.AddAvailabilityBreak .select-date-content .icon {
  position: absolute;
  left: 0;
  top: 3px;
  width: 22px;
  height: 22px;
}
.AddAvailabilityBreak .select-date-content .icon IMG {
  width: 100%;
}
.AddAvailabilityBreak .select-date-content .select-time {
  padding-left: 0px;
  padding-right: 25px;
  font-weight: 700;
  display: inline-flex;
  width: 250px;
  position: relative;
  top: -2px;
}
.AddAvailabilityBreak .time-devider {
  display: inline-block;
  padding: 0 5px;
}
.AddAvailabilityBreak .TimeManual .Input-control {
  margin-bottom: 0;
}
.AddAvailabilityBreak .TimeManual .Input-control INPUT.form-control {
  display: inline-flex;
  padding-left: 10px;
  border: 1px solid black;
  height: 30px;
  padding-right: 10px;
}
.AddAvailabilityBreak .end-date {
  /* color: rgba(1, 54, 72, 0.5);
  min-width: 140px; */
}
.AddAvailabilityBreak .select-picker-content {
  position: relative;
  display: inline-block;
  min-width: 144px;
}
.AddAvailabilityBreak .select-picker-content .Datepicker-content {
  position: absolute;
  top: 35px;
  z-index: 99;
}
.AddAvailabilityBreak .Datepicker-content.time.end-time {
  position: absolute;
}
.AddAvailabilityBreak .datepicker-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
}
.AddAvailabilityBreak .location {
  position: relative;
  padding-left: 41px;
}
.AddAvailabilityBreak .dailog-body .location .Select-control {
  width: 100%;
  background-color: #f1f2f2;
}
.AddAvailabilityBreak .location .icon {
  position: absolute;
  left: 1px;
  top: 4px;
}
.AddAvailabilityBreak .dailog-body .location .Select-control SELECT {
  padding-left: 18px;
  height: 37px;
}
.AddAvailabilityBreak .dailog-body .location .Select-control:after {
  display: none;
}
.AddAvailabilityBreak .dailog-body .location .Select-control .arrow {
  border: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  top: 6px;
  right: 10px;
}
.AddAvailabilityBreak .dropdown {
  position: relative;
}
.AddAvailabilityBreak .dropdown .dropdown-menu {
  position: absolute;
  top: 80px;
  box-shadow: 0px 0px 14px 0px #d8d3d3;
  right: 0;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all linear;
  z-index: 9;
  background-color: #ffffff;
  min-width: 300px;
}
.AddAvailabilityBreak .dropdown .dropdown-menu SPAN {
  display: inline-block;
  width: 100%;
  padding: 18px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #013648;
  border-bottom: 1px solid #fafafb;
}
.AddAvailabilityBreak .dropdown .show-menu .dropdown-menu {
  top: 58px;
  opacity: 1;
  visibility: visible;
  min-width: 300px;
  z-index: 101;
}
.AddAvailabilityBreak .virtual-field {
  cursor: pointer;
}
.AddAvailabilityBreak .virtual-field .Checkbox-control {
  padding-left: 48px;
}
.AddAvailabilityBreak .cross-icon {
  position: absolute;
  width: 19px;
  top: 20px;
  cursor: pointer;
  right: 30px;
}
.AddAvailabilityBreak .disabled .Checkbox-control {
  color: rgba(1, 54, 72, 0.29);
}
.AddAvailabilityBreak .disabled .Checkbox-control INPUT:checked ~ .checkmark {
  background-color: rgba(1, 54, 72, 0.06);
}
.AddAvailabilityBreak .select-event-day-content IMG {
  position: relative;
  top: 6px;
}
.AddAvailabilityBreak .disabled .Select-control,
.AddAvailabilityBreak .disabled IMG {
  opacity: 0.29;
}
.AddAvailabilityBreak .cross-icon IMG {
  width: 100%;
}
.AddAvailabilityBreak .select-date-content .result {
  top: 31px;
}
@media (hover: hover) {
  .AddAvailabilityBreak .dropdown .dropdown-menu SPAN:hover {
    background-color: #f2f2f2;
  }
}
@media (max-width: 767px) {
  .AddAvailabilityBreak .select-date-content,
  .AddAvailabilityBreak .select-event-day-content {
    flex-wrap: wrap;
  }
  .AddAvailabilityBreak .select-picker-content {
    margin-bottom: 15px;
  }
  .AddAvailabilityBreak .dailog-body .Select-control {
    margin-bottom: 15px;
  }
  .AddAvailabilityBreak .select-event-day-content {
    margin-bottom: 34px;
  }
  .AddAvailabilityBreak .select-event-day-content .Select-control {
    width: 226px;
  }
  .AddAvailabilityBreak .select-event-day-content .Select-control SELECT {
    padding-left: 20px;
  }
  .AddAvailabilityBreak .dailog-body {
    padding: 20px 15px 15px;
  }
  .AddAvailabilityBreak .dailog-header {
    padding-left: 15px;
  }
}
