.VideoVisitBlock {
  display: block;
  width: 100%;
}
.VideoVisitBlock .video-visit-row {
  display: flex;
  flex-wrap: wrap;
}
.VideoVisitBlock .video-visit-row .video-left {
  flex: 1;
}
.VideoVisitBlock .video-visit-row .video-right {
  width: 320px;
}
.VideoVisitBlock .hide-sidebar {
  display: none;
}

@media (max-width: 1199px) {
  .VideoVisitBlock .video-visit-row .video-right {
    width: 270px;
  }
}

@media (max-width: 767px) {
  .VideoVisitBlock .video-visit-row .video-right {
    width: 100%;
  }
  .VideoVisitBlock .video-visit-row .video-left {
    flex: auto;
    width: 100%;
  }
}
