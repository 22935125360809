.ConfirmTemplate {
  background-color: #fff;
  /* border: solid 1px #e2e2ea; */
  border-radius: 4px;
  overflow: hidden;
  width: 586px;
  max-width: 100%;
  margin: auto;
}
.ConfirmTemplate .modal-content-body {
  padding: 29px 35px;
}
.ConfirmTemplate .modal-content-footer {
  padding: 23px 45px 33px;
}
.ConfirmTemplate .Divider {
  margin-top: 10px;
}
.ConfirmTemplate .confirm-msg {
  font-size: 20px;
}
.ConfirmTemplate .Divider .container {
  width: auto;
}
.ConfirmTemplate .modal-content-body {
  padding-bottom: 0;
}
.ConfirmTemplate .modal-content-footer {
  padding-top: 10px;
  padding-bottom: 15px;
  text-align: right;
}
.ConfirmTemplate .modal-content-footer .CircleButton {
  width: 100px;
  margin-left: 15px;
}
.ConfirmTemplate .modal-content-header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 32px;
}
.ConfirmTemplate .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
}
