.FileUpload {
  margin-bottom: 18px;
}
.FileUpload .label {
  font-family: 'Calibre Medium';
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  color: #013648;
  margin-bottom: 0;
  position: unset;
  top: unset;
  left: unset;
  transform: none;
}
.FileUpload .Fileuploadinput {
  width: 150px;
  height: 149px;
  border-radius: 4px;
  border: solid 1px rgba(1, 54, 72, 0.2);
  background-color: #f7f9f9;
  position: relative;
  display: flex;
}
.FileUpload .Fileuploadinput input {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 99;
}
.FileUpload .upload-img .Fileuploadinput img {
  height: auto;
  width: 102px;
  max-height: 100%;
}
.FileUpload .Fileuploadinput img {
  width: 61px;
  height: 39px;
  margin: auto;
}
.FileUpload .fileupload-wrapper {
  display: flex;
  align-items: flex-end;
}
.FileUpload .upload-link a {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: -0.3px;
  text-align: center;
  color: #3dbce7;
}
.FileUpload .upload-link .upload-txt {
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: rgba(1, 54, 72, 0.5);
}
.FileUpload .upload-link {
  padding-left: 19px;
}
.FileUpload .RemoveImage {
  display: none;
}
.FileUpload .upload-img .RemoveImage {
  position: ABSOLUTE;
  right: 6px;
  top: 7px;
  width: 15px;
  display: inline-block;
  cursor: pointer;
  display: block;
  z-index: 100;
}
.FileUpload .upload-link .Fileuploadinput {
  background-color: transparent;
  border: 0;
  width: auto;
  height: auto;
  cursor: pointer;
}
.FileUpload .upload-img .RemoveImage img {
  width: 100%;
}

@media (hover: hover) {
  .FileUpload .upload-link a:hover {
    color: #013648;
  }
}
