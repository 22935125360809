.PatientInsurance {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 40px 40px 37px;
  margin-bottom: 27px;
  max-width: 635px;
  width: 100%;
}
.PatientInsurance .h3 {
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 48px;
}
.PatientInsurance .heading2 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: normal;
  color: #013648;
  margin-bottom: 25px;
}
.PatientInsurance .PatientInsurance-block {
  margin-bottom: 25px;
}
.PatientInsurance .form-group {
  margin-bottom: 37px;
  padding: 0 12px;
}
.PatientInsurance .form-group.Upload {
  padding-left: 5px;
  margin-bottom: 0;
}
.PatientInsurance .form-group .label {
  line-height: 1.25;
  margin-bottom: 2px;
  display: block;
}
.PatientInsurance .Select-control .arrow{
  right: 33px;
}
.PatientInsurance .form-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.PatientInsurance .form-row .column6 {
  width: 50%;
}
.PatientInsurance .form-row .column9 {
  padding: 0 12px;
  width: calc(100% - 132px);
}
.PatientInsurance .form-row .column3 {
  padding: 0 12px;
  width: 132px;
}
.PatientInsurance .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  border-radius: 16px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: normal;
  font-family: 'Calibre Regular';
}
.PatientInsurance .form-group .light_text {
  color: rgba(1, 54, 72, 0.4);
}
.PatientInsurance .Input-control .error-text,
.PatientInsurance .Select-control .error-text{
  left: 15px;
}
.PatientInsurance .clear-btn{
display: block;
float: right;
cursor: pointer;
color: #26acda;
font-size: 17px;
}
@media screen and (max-width: 1200px) {
  .PatientInsurance {
    padding: 20px;
  }
}
@media screen and (max-width: 767px) {
  .PatientInsurance .form-row .column6,
  .PatientInsurance .form-row .column9,
  .PatientInsurance .form-row .column3 {
    width: 100%;
  }
  .PatientInsurance .Select-control .arrow{
    right: 21px;
  }
  .PatientInsurance .h3{
    font-size: 20px;
    margin-bottom: 22px;
  }
  .PatientInsurance .heading2{
    margin-bottom: 15px;
  }
}
