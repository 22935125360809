.ToolProcesses {
  padding: 0 40px;
}

.ToolProcesses .toolprocess-points .toolprocess-points-content {
  width: 32%;
  padding: 20px 24px 0;
  background-color: rgba(242, 150, 45, 0.1);
  border-radius: 4px;
  border: solid 1px #f2962d;
}
.ToolProcesses .toolprocess-points .toolprocess-points-content:nth-child(2) {
  border: solid 1px #26acda;
  background-color: rgba(38, 172, 218, 0.1);
}
.ToolProcesses .toolprocess-points .toolprocess-points-content:nth-child(3) {
  border: solid 1px #3d93a6;
  background-color: rgba(61, 147, 166, 0.1);
}
.ToolProcesses .toolprocess-points .toolprocess-points-content .paragraph {
  font-size: 18px;
  line-height: 1.22;
  color: #013648;
  margin-bottom: 32px;
  letter-spacing: -0.2px;
}
.ToolProcesses .toolprocess-points .toolprocess-points-content span {
  font-size: 36px;
  line-height: 1.56;
  color: #013648;
  font-family: 'Heldane Display';
  letter-spacing: -0.2px;
}
.ToolProcesses .toolprocess-points .toolprocess-points-content .h3 {
  font-family: 'Calibre Medium';
  font-size: 18px;
  line-height: 1.22;
  letter-spacing: 0.2px;
  color: #013648;
  margin: 6px 0 4px;
  font-weight: 500;
}
.ToolProcesses .toolprocess-points {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 991px) {
  .ToolProcesses {
    padding: 0 0px;
  }
}
@media (max-width: 767px) {  
  .ToolProcesses .toolprocess-points .toolprocess-points-content span {
    font-size: 48px;
  }
  .ToolProcesses .toolprocess-points .toolprocess-points-content .paragraph {
    font-size: 16px;
    line-height: 1.25;
    color: #013648;
  }
  .ToolProcesses .toolprocess-points .toolprocess-points-content {
    width: 100%;
    margin-bottom: 20px;
  }
  .ToolProcesses .toolprocess-points .toolprocess-points-content span {
    line-height: 1.6;
  }
}
