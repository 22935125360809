.VideoChat * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.VideoChat header {
    background: #f0293e;
    color: #fff;
    text-align: center;
    flex-grow: 0;
    margin-bottom: 2em;
}

.VideoChat h1 {
    font-weight: 300;
    padding: 0.4em 0;
}

.VideoChat #root {
    min-height: 100vh;
}

.VideoChat .app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.VideoChat main {
    background: #ffffff;
    flex-grow: 1;
    width: 100%;
    position: relative;
    display: inline-flex;
}

.VideoChat form {
    max-width: 300px;
    margin: 0 auto;
}

.VideoChat h2 {
    font-weight: 300;
    margin-bottom: 1em;
    text-align: center;
}

.VideoChat form>div {
    width: 100%;
    margin-bottom: 1em;
}

.VideoChat form>div>label {
    display: block;
    margin-bottom: 0.3em;
}

.VideoChat form>div>input {
    display: block;
    width: 100%;
    font-size: 16px;
    padding: 0.4em;
    border-radius: 6px;
    border: 1px solid #333e5a;
}

.VideoChat button {
    background: #333e5a;
    color: #fff;
    font-size: 16px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid transparent;
    margin: auto 10px;
}

.VideoChat button:hover {
    filter: brightness(150%);
}

.VideoChat .room {
    position: relative;
    width: 75%;
    padding: 10px;
}

.VideoChat .room>h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
}

.VideoChat .local-participant {
    text-align: center;
    margin-bottom: 2em;
    width: 100%;
}

.VideoChat .remote-participants {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 0 2em 2em;
    width: 100%;
    display: block;
}

.VideoChat .participant {
    background: #333e5a;
    padding: 10px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
}

.VideoChat .participant:last-child {
    margin-top: 10px;
}

.VideoChat .participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
}

.VideoChat video {
    width: 100%;
    max-width: 320px;
    display: block;
    margin: 0 auto;
    border-radius: 6px;
}

.VideoChat .video-action {
    width: 100%;
    display: inline-block;
}

.VideoChat .video-action button {
    float: right;
}

.VideoChat footer {
    background: #333e5a;
    color: #fff;
    text-align: center;
    flex-grow: 0;
    padding: 1em 0;
}

.VideoChat footer a {
    color: #fff;
}

.VideoChat .message {
    position: relative;
    width: 25%;
    padding: 10px;
}

.VideoChat .message .message-history {
    min-height: 500px;
    border: #cec8c8 2px solid;
    width: 100%;
    padding: 5px;
    max-height: 500px;
    overflow: scroll;
}

.VideoChat .message .chat {
    width: 80%;
    margin: 5px;
    padding: 5px;
    line-height: 20px;
    border-radius: 5px;
    font-size: 14px;
}

.VideoChat .message .my-chat {
    background-color: #658e29;
    color: white;
}

.VideoChat .message .remote-chat {
    background-color: #4e4f4e;
    color: white;
}

.VideoChat .message .system-chat {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.VideoChat .message .chat .name {
    font-weight: bolder;
    font-size: 15px;
    line-height: 20px;
}

.VideoChat .message .chat .date {
    width: 100%;
    display: block;
    text-align: end;
    font-size: 12px;
}

.VideoChat .message .send-msg-text {
    width: 100%;
}

.VideoChat .message .send-msg-btn {
    width: 100%;
    margin: 0;
}

.VideoChat .error-box {
    border: 2px solid gray;
    min-height: 200px;
    margin-top: 10px;
}

.VideoChat .error-box h4 {
    background: gray;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
}

.VideoChat .message .loading {
    position: absolute;
    top: 515px;
    width: 100%;
    z-index: 11;
}