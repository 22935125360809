.CircleButton.WhiteButton {
  width: 232px;
  height: 48px;
  border-radius: 24px;
  background-color: transparent;
  font-size: 18px;
  line-height: 48px;
  letter-spacing: -0.34px;
  color: #013648;
  font-family: 'Calibre Medium';
  border: 0px;
  opacity: 1;
  cursor: pointer;
  outline: 0;
  border: 1px solid #013648;
}
.CircleButton.SecondaryButton:focus,
.CircleButton.WhiteButton:focus,
.CircleButton.smallClose:focus {
  outline: 0;
}
.CircleButton.SecondaryButton,
.CircleButton.smallClose {
  border-radius: 24px;
  border: solid 1px #013648;
  width: 232px;
  height: 48px;
  font-family: 'Calibre Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.34px;
  color: #013648;
  background-color: transparent;
  cursor: pointer;
}
.CircleButton.WhiteButton[disabled] {
  opacity: 0.5;
  color: #d8d8d8;
  background-color: #013648;
}
.CircleButton.SecondaryButton[disabled],
.CircleButton.smallClose[disabled] {
  background-color: #013648;
  opacity: 0.3;
}
.CircleButton.smallClose {
  width: auto;
  padding: 1px 20px;
  margin: 5px;
}
.CircleButtonWrapper {
  width: fit-content;
}
.CircleButtonWrapper.with-close-icon {
  position: relative;
}
.CircleButtonWrapper.with-close-icon .CircleButton.smallClose {
  padding-right: 35px;
  z-index: 2;
  cursor: unset;
}
.CircleButtonWrapper .close-icon {
  position: absolute;
  height: 15px;
  top: 21px;
  right: 18px;
  cursor: pointer;
  z-index: 1;
}
@media (hover: hover) {
  .CircleButton.WhiteButton:hover {
    background-color: #013648;
    opacity: 1;
    border: 1px solid #013648;
    color: #fff;
  }
  .CircleButton.SecondaryButton:hover {
    background-color: #013648;
    color: #fff;
  }
  .CircleButton.WhiteButton[disabled]:hover {
    background-color: #013648;
    opacity: 0.5;
    color: #d8d8d8;
    border: 1px solid #013648;
    cursor: not-allowed;
  }
  .CircleButton.SecondaryButton[disabled]:hover {
    background-color: transparent;
    border: solid 1px #013648;
    color: #013648;
    opacity: 0.3;
    cursor: not-allowed;
  }
}
@supports (-webkit-touch-callout: none) {
  .CircleButton.WhiteButton {
    line-height: unset !important;
  }
}
@media (max-width: 767px) {
  .CircleButton.WhiteButton {
    background-color: #013648;
    color: #fff;
  }
}