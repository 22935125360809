.EditCompany {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
}
.EditCompany .modal-body-wrapper {
  background-color: white;
  height: auto;
  width: 100%;
  padding: 0px;
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  border-radius: 2px;
  border: solid 1.5px rgba(40, 84, 99, 0.2);
  background-color: #ffffff;
}
.EditCompany .dailog-header {
  padding: 22px 31px 5px;
  border-bottom: solid 1px #e9ebf2;
  display: flex;
  justify-content: space-between;
  background-color: #013648;
}
.EditCompany .cross-icon {
  position: absolute;
  width: 19px;
  top: 27px;
  cursor: pointer;
  right: 38px;
}
.EditCompany .cross-icon img {
  width: 100%;
}
.EditCompany .dailog-header .heading1 {
  font-size: 20px;
  line-height: 1.98;
  color: #fff;
}
.EditCompany .dailog-close-btn {
  width: 36px;
  height: 36px;
  background-color: transparent;
  border: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.EditCompany .dailog-close-btn img {
  position: absolute;
  left: -26px;
  top: -7px;
}
.EditCompany .dailog-close-btn:focus {
  outline: 0;
  box-shadow: none;
}
.EditCompany .dailog-body {
  padding: 20px 32px 15px;
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.EditCompany .dailog-body .Select select {
  border: 0;
  height: 35px;
  z-index: 2;
  position: relative;
  background-color: transparent;
  font-size: 18px;
}
.EditCompany .dailog-body .Select {
  width: 255px;
  margin-bottom: 34px;
}
.EditCompany .dailog-body .Select .arrow {
  border: 6px solid #92929d;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  top: 12px;
  z-index: 0;
  right: 18px;
}
.EditCompany .dailog-body .Input-control .form-control {
  opacity: 1;
  font-size: 18px;
  line-height: 1.33;
  color: #013648;
}
.EditCompany .action-btn {
  text-align: right;
  padding: 10px 32px 15px;
}
.EditCompany .action-btn.btn-change {
  padding-top: 0px;
  padding-right: 0px;
}
.EditCompany .CircleButton {
  width: 109px;
  height: 44px;
  font-size: 16px;
  line-height: 44px;
  margin-left: 15px;
}
.EditCompany .logo {
  width: 30px;
  position: absolute;
  left: 30px;
}
.EditCompany .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.EditCompany .link-add-new {
  line-height: 1.25;
  color: #26acda;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Calibre Medium';
}
.EditCompany .input-close {
  position: relative;
}
.EditCompany .input-close img {
  position: absolute;
  width: 15px;
  bottom: 42px;
  right: 15px;
  cursor: pointer;
}
.EditCompany .img-preview {
  max-width: 100%;
}
.EditCompany .action-btn.btn-change .CircleButton.SecondaryButton {
  background-color: #26acda;
}
