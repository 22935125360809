.ReviewTabs .tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
  margin: 0;
}
.ReviewTabs .no-desingn {
  text-align: center;
  font-size: 25px;
}
.ReviewTabs .review-list {
  padding: 40px 0 0;
}
.ReviewTabs .tab-list-item:nth-child(4) {
  display: none;
}
.ReviewTabs .info-wrapper .info-content {
  width: 200px;
  max-width: 200px;
  border-radius: 8px;
  box-shadow: inset 0 -1px 0 0 #dcdce0;
  background-color: #ffffff;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.34px;
  color: #72787d;
  padding: 12px;
  display: none;
  position: absolute;
  top: 0;
  /* right: 17px; */
  z-index: 3;
  text-align: left;
  border: 1px solid #e8e8e8;
}
.ReviewTabs .info-wrapper {
  position: relative;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
}
.ReviewTabs .info-wrapper img{
  position: absolute;
  top: -20px;
  width: 14px;
}
.ReviewTabs .info-wrapper:hover .info-content{
 display: block;
}
.ReviewTabs .tab-list-item {
  display: inline-block;
  list-style: none;
  padding: 0.5rem 0px 15px;
  margin: 0px 60px 0 0;
  font-family: 'Calibre Medium';
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  color: rgba(1, 54, 72, 0.3);
  position: relative;
  cursor: pointer;
}
.ReviewTabs .tab-list-item:first-child {
  margin-left: 0;
}
.ReviewTabs .tab-list-active {
  background-color: #fff;
  border: solid #013648;
  border-width: 0px 0px 4px 0px;
  color: rgb(1, 54, 72);
}
.ReviewTabs .tab-list-item .review-count {
  font-family: 'Calibre Medium';
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #f37184;
  position: absolute;
  right: -24px;
  top: -9px;
}
.ReviewTabs .tab-list-active .review-count {
  color: #72787d;
}
.ReviewTabs .review-sort-wrapper {
  margin-bottom: -20px;
  margin-top: -20px;
  text-align: right;
}
.ReviewTabs .review-sort-wrapper SPAN {
  padding: 3px 11px 2px;
  height: 24px;
  border-radius: 12px;
  border: solid 1px #203a70;
  font-family: 'Calibre Medium';
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.24px;
  text-align: center;
  color: #013648;
  display: inline-block;
  margin-left: 7px;
  cursor: pointer;
}
.ReviewTabs .review-sort-wrapper SPAN.active {
  background-color: #013648;
  color: #fff;
}

@media (hover: hover) {
  .ReviewTabs .review-sort-wrapper SPAN:hover {
    background-color: #013648;
    color: #fff;
  }
}

@media (max-width: 1366px) {
  .ReviewTabs .tab-list-item {
    font-size: 20px;
    margin: 0px 50px 0 0;
  }
}

@media (max-width: 1200px) {
  .ReviewTabs .tab-list-item {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .ReviewTabs .tab-list {
    margin: 0px -10px;
  }
  .ReviewTabs .tab-list-item,
  .ReviewTabs .tab-list-item:first-child {
    margin-right: 50px;
    margin-left: 20px;
    margin-top: 10px;
  }
  .ReviewTabs .review-sort-wrapper{
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  .ReviewTabs .tab-list {
    display: flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .ReviewTabs .tab-list-item {
    white-space: nowrap;
  }
  .ReviewTabs .info-wrapper .info-content{
    white-space: break-spaces ;
    right: -4px;
    top: -11px;
  }

    .ReviewTabs .tab-list.min-h{
min-height: 120px;
    }
  
}
