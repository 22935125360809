.ProviderOverview {
  border-radius: 8px;
  border: solid 1px #e2e2ea;
  background-color: #ffffff;
  padding: 33px 95px 37px 90px;
  margin-bottom: 27px;
}
.ProviderOverview .h3 {
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.33px;
  color: #013648;
  margin-bottom: 30px;
  font-family: 'Calibre Medium';
}
.ProviderOverview .Input-control.link-container .lookup-link {
  font-family: 'Calibre Medium';
  font-weight: 500;
}
.ProviderOverview .CircleButton.WhiteButton {
  width: 111px;
  height: 32px;
  font-size: 16px;
  line-height: 32px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  border-radius: 2px;
  margin-top: 5px;
}
.ProviderOverview .Textarea.form-group TEXTAREA {
  height: 162px;
}
.ProviderOverview .Textarea.form-group .label {
  display: block;
  line-height: 1.25;
  margin-bottom: 5px;
}
.ProviderOverview .Textarea.form-group {
  margin-bottom: 23px;
}
.ProviderOverview .Select-control .label {
  line-height: 1.25;
  margin-bottom: 8px;
  display: block;
}
.ProviderOverview .Input-control.form-group .label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderOverview .Select-control .arrow {
  top: 50px;
}
.ProviderOverview .Input-control.link-container .lookup-link {
  top: 38px;
}
.ProviderOverview .Input-control.link-container {
  margin-bottom: 55px;
  margin-top: 5px;
}
.ProviderOverview .Input-control.form-group.link-container .label {
  margin-bottom: 2px;
}
.confirm-seolink p{
  display: grid;
  grid-template-columns: 160px 1fr;
  padding-left: 15px;
}
.confirm-seolink p span{
  word-break: break-all;
}
.ProviderOverview .TagsInput .form-label {
  line-height: 1.25;
  margin-bottom: 12px;
}
.ProviderOverview .TagsInput.suffix-tag-input .outer-suggetion-box {
  flex: 1;
}
.ProviderOverview .TagsInput.suffix-tag-input .multiselectsearch-block {
  min-height: 52px;
  padding: 11px 15px;
}
.ProviderOverview .titleHeading {
  display: flex;
  justify-content: space-between;
}
.ProviderOverview .overview-bottom {
  display: flex;
}
.ProviderOverview .overview-bottom BUTTON {
  margin-left: auto;
}
.Provider-TabViewEach .Textarea,
.Provider-TabViewEach .TagsInput .form-col,
.Provider-TabViewEach .Input-control{
  scroll-margin-top: 80px;
}
.ProviderOverview .error-text input{
  color: #E85D72;
}
.custom-footer-warning{
  display: flex;
  flex-flow: column;
}
.ConfirmTemplate .custom-footer-warning .CircleButton{
  width: 100% !important;
  margin-bottom: 10px !important;
  margin-left: 0 !important;
}
@media screen and (max-width: 1366px) {
  .ProviderOverview {
    padding: 20px;
  }
}
@media screen and (max-width: 1200px) {
  .ProviderOverview .h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .ProviderOverview .Textarea.form-group .form-control:focus,
  .ProviderOverview .Textarea.form-group .form-control:not(:placeholder-shown) {
    padding-left: 10px;
  }
}

@media screen and (max-width: 413px) {
  .ProviderOverview {
    padding: 20px;
  }
  .confirm-seolink p{
    display: inherit;
  }
}
