.CreatePasswordModal {
  background-color: #fff;
  border-radius: 8px;
  /* border: solid 1px #e2e2ea; */
  border-radius: 8px;
  overflow: hidden;
  width: 488px;
  margin: auto;
}
.CreatePasswordModal .modal-content-header {
  height: auto;
  min-height: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #013648;
  padding: 0 20px 0 42px;
}
.CreatePasswordModal .modal-content-header .close {
  width: 27px;
  height: 27px;
  display: inline-block;
  cursor: pointer;
}
.CreatePasswordModal .modal-content-header .close img {
  width: 100%;
}
.CreatePasswordModal .modal-content-header .h3 {
  font-family: 'Heldane Display';
  font-size: 32px;
  line-height: 1.06;
  letter-spacing: -0.64px;
  color: #fff;
  word-break:break-word;
    padding: 10px;
    padding-left: 0;
}
.CreatePasswordModal .modal-content-body {
  padding: 23px 45px;
  max-height: calc(100vh - 244px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.CreatePasswordModal .modal-content-body .password-information {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  width: 322px;
  display: inline-block;
}
.CreatePasswordModal .modal-content-footer {
  padding: 23px 45px 33px;
}
.CreatePasswordModal .modal-content-footer .CircleButton.WhiteButton {
  width: 100%;
}
.CreatePasswordModal .modal-content-body .Input-control .view-img {
  width: 25px;
  height: 20px;
}
@media (max-width: 767px) {
  .CreatePasswordModal .modal-content-body .Input-control .view-img {
    top: calc(100% - 1.8rem);
  }
  @supports (-webkit-touch-callout: none) {
    .CreatePasswordModal .modal-content-footer .CircleButton.WhiteButton {
      padding-top: 2px;
    }
  }
}
@media (max-width: 575px) {
  .CreatePasswordModal {
    width: 100%;
  }
  .CreatePasswordModal .modal-content-body {
    padding: 20px;
  }
  .CreatePasswordModal .modal-content-body .password-information {
    width: 100%;
  }
  .CreatePasswordModal .modal-content-header {
    padding: 15px 20px;
    height: auto;
  }
  .CreatePasswordModal .modal-content-header .h3 {
    font-size: 24px;
  }
  .CreatePasswordModal .modal-content-header .close {
    width: 18px;
    height: 18px;
  }
}
