.Blog {
  padding: 131px 0;
}
.Blog .container {
  max-width: 808px;
  width: 100%;
  padding: 0 15px;
}
.Blog .main-header {
  font-size: 64px;
  line-height: 1.03;
  letter-spacing: normal;
  color: #013648;
  text-align: center;
  font-family: 'Heldane Display';
  font-weight: normal;
  margin-bottom: 92px;
}
.Blog .sub-header-title {
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.2px;
  color: #183b56;
  font-family: 'Calibre Medium';
  font-weight: 500;
  margin-bottom: 25px;
}
.Blog .title-txt {
  margin-bottom: 37px;
}
.Blog .para {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: rgba(1, 54, 72, 0.7);
  margin-bottom: 28px;
}

@media screen and (max-width: 1199px) {
  .Blog {
    padding: 90px 0;
  }
  .Blog .main-header {
    font-size: 56px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 991px) {
  .Blog {
    padding: 70px 0;
  }
  .Blog .main-header {
    font-size: 52px;
    margin-bottom: 55px;
  }
  .Blog .sub-header-title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .Blog .title-txt {
    margin-bottom: 27px;
  }
  .Blog .para {
    margin-bottom: 23px;
  }
}
@media screen and (max-width: 767px) {
  .Blog {
    padding: 50px 0;
  }
  .Blog .main-header {
    font-size: 46px;
    margin-bottom: 45px;
  }
  .Blog .sub-header-title {
    margin-bottom: 15px;
  }
  .Blog .title-txt {
    margin-bottom: 20px;
  }
  .Blog .para {
    font-size: 16px;
    margin-bottom: 20px;
  }
}
