.AppointmentItem {
  display: flex;
  padding: 27px 0 17px;
  justify-content: space-between;
  border-bottom: solid 1px rgba(1, 54, 72, 0.07);
}
.AppointmentItem:first-child {
  padding-bottom: 44px;
}
.AppointmentItem:last-child {
  border-bottom: 0;
}
.AppointmentItem .ap-dc-info {
  display: flex;
}
.AppointmentItem .ap-dc-info .dc-img {
  min-height: 120px;
  min-width: 80px;
  height: 120px;
  width: 80px;
  padding-top: 7px;
  cursor: pointer;
}
.AppointmentItem .ap-dc-info .dc-img img {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(1, 54, 72, 0.2);
  height: 100%;
  object-fit: cover;
}
.AppointmentItem .dc-info {
  padding-left: 24px;
  width: calc(100% - 80px);
}
.AppointmentItem .dc-info .name {
  font-family: 'Heldane Display';
  font-size: 24px;
  line-height: 1.42;
  color: #013648;
  cursor: pointer;
}
.AppointmentItem .dc-info .dc-specialist {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.24px;
  color: #013648;
  cursor: pointer;
}
.AppointmentItem .dc-info .address,
.AppointmentItem .dc-info .dc-guardian {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.2px;
  color: #72787d;
  display: flex;
  align-items: flex-start;
}
.AppointmentItem .dc-info .address {
  padding-top: 17px;
}
.AppointmentItem .dc-info .dc-guardian {
  padding-top: 23px;
}
.AppointmentItem .dc-info .address .icon,
.AppointmentItem .dc-info .dc-guardian .icon {
  width: 17px;
  margin-right: 14px;
  display: inline-block;
  position: relative;
  top: -2px;
}
.AppointmentItem .dc-info .dc-guardian .icon.insurance-icon {
  padding-top: 5px;
}
.AppointmentItem .ap-dc-timeinfo {
  width: 200px;
  min-width: 200px;
  text-align: right;
  padding-top: 5px;
}
.AppointmentItem .ap-dc-timeinfo .appointment-time {
  width: 110px;
  min-height: 110px;
  margin-left: auto;
  padding: 18px 11px;
  padding-right: 0;
  border-radius: 4px;
  border: solid 0.8px #013648;
  background-color: #013648;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: -0.38px;
  color: #f7fffc;
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}
.AppointmentItem .ap-dc-timeinfo .appointment-time span {
  display: inline-block;
  width: 100%;
}
.AppointmentItem .ap-dc-timeinfo .appointment-time .day {
  font-family: 'Calibre Medium';
}
.AppointmentItem .ap-dc-timeinfo .distance {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #b2bcc4;
  margin-bottom: 2px;
}
.AppointmentItem .ap-dc-timeinfo .book-appointment > span {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  text-align: right;
  color: #26acda;
  cursor: pointer;
}

.AppointmentItem .appointment-notification {
  margin-top: 18px;
  font-size: 18px;
  line-height: 1.28;
  letter-spacing: -0.23px;
  color: #013648;
  padding: 10px 38px 8px 19px;
  border-radius: 2px;
  background-color: rgba(1, 54, 72, 0.03);
  border-left: 6px solid #f2962d;
  position: relative;
}
.AppointmentItem .appointment-notification .notification-icon {
  width: 16px;
  position: absolute;
  right: 14px;
  top: 8px;
}
.AppointmentItem .appointment-notification span {
  display: inline-block;
  width: 100%;
}
.AppointmentItem .ins-info {
  color: #26acda;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.AppointmentItem .ap-dc-timeinfo .book-appointment {
  position: relative;
  margin-top: 9px;
}
.AppointmentItem .ap-dc-timeinfo .book-appointment .appointment-action {
  width: 197px;
  border-radius: 8px;
  background-color: #ffffff;
  text-align: left;
  box-shadow: 0 0 10px 1px #eaeaea;
  display: none;
  position: absolute;
  right: 0;
  top: 25px;
  overflow: hidden;
}
.AppointmentItem .menu-item {
  font-size: 16px;
  color: #013648;
  display: inline-block;
  width: 100%;
  padding: 20px 20px 17px;
  border-bottom: 1px solid #f1f1f5;
  cursor: pointer;
}
.AppointmentItem .menu-item:last-child {
  border-bottom: 0;
}
.AppointmentItem .menu-item.cancel {
  color: #e85d72;
}
.AppointmentItem.confirmed .ap-dc-timeinfo .appointment-time,
.AppointmentItem.completed .ap-dc-timeinfo .appointment-time {
  background-color: #198754;
  border-color: #198754;
  color: #f1f1f1;
}
.AppointmentItem.cancelled .ap-dc-timeinfo .appointment-time {
  border-color: #e85d72;
  background-color: #e85d72;
  color: #f7fffc;
}
.AppointmentItem.pending .ap-dc-timeinfo .appointment-time {
  border: solid 1.5px #013648;
  background-color: transparent;
  color: #013648;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 18px 11px;
  padding-right: 0;
  min-height: 110px;
  width: 110px;
  text-align: left;
}
.AppointmentItem.pending .dc-info .address,
.AppointmentItem.pending .dc-info .dc-guardian {
  color: #013648;
}
.AppointmentItem .show-appointment-details {
  display: block;
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.34px;
  color: #26acda;
  margin-top: 8px;
  cursor: pointer;
}
.AppointmentItem .ap-dc-timeinfo .book-appointment:hover .appointment-action {
  display: block;
}
.AppointmentItem .person-visit,
.AppointmentItem .video-visit {
  width: 100px;
  height: 27px;
  border-radius: 13.5px;
  background-color: #cce3eac9;
  font-family: 'Calibre Medium';
  font-size: 14px;
  line-height: 27px;
  letter-spacing: -0.26px;
  display: inline-flex;
  align-items: center;
  color: #013648;
  position: relative;
  padding-top: 5px;
  padding-left: 35px;
  margin-top: 13px;
}
.AppointmentItem .person-visit img,
.AppointmentItem .video-visit img {
  width: 27px;
  border: 0;
  position: absolute;
  left: 10px;
  top: -1px;
}
.AppointmentItem .video-visit {
  background-color: #eeebf5;
  color: #8d77ba;
  padding-left: 30px;
}
.AppointmentItem .video-visit img {
  left: 7px;
}
.AppointmentItem .start-videolink {
  padding: 5px 10px 2px;
  border-radius: 4px;
  border: solid 1px #8d77ba;
  max-width: 127px;
  width: 100%;
  font-size: 16px;
  font-family: 'Calibre Medium';
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.21px;
  color: #8d77ba;
  margin-bottom: 31px;
  margin-left: 10px;
}

@media (hover: hover) {
  .AppointmentItem .ap-dc-timeinfo .book-appointment > span:hover {
    color: rgba(38, 172, 218, 0.6);
  }
  .AppointmentItem .menu-item:hover {
    background-color: rgba(1, 54, 72, 0.1);
  }
  .AppointmentItem .start-videolink:hover {
    background: #8d77ba;
    color: #ffffff;
  }
}

@media (max-width: 1200px) {
  .AppointmentItem .ap-dc-timeinfo {
    width: 160px;
    min-width: 160px;
  }
}

@media (max-width: 767px) {
  .AppointmentItem .ap-dc-info {
    width: 100%;
  }
  .AppointmentItem .ap-dc-info .dc-img {
    padding-top: 0;
  }
  .AppointmentItem .ap-dc-timeinfo {
    padding-top: 0;
  }
  .AppointmentItem .appointment-notification {
    width: calc(100% + 101px);
    margin-left: -101px;
    margin-top: 32px;
    padding-top: 12px;
    padding-bottom: 4px;
  }
  .AppointmentItem .ins-info {
    text-decoration: underline;
    color: #013648;
  }
  .AppointmentItem .appointment-notification .notification-icon {
    width: 18px;
    top: 10px;
  }
  .AppointmentItem .dc-info .name {
    font-size: 18px;
    line-height: 1.22;
    margin-bottom: 4px;
  }
  .AppointmentItem .dc-info .dc-specialist {
    font-size: 16px;
    line-height: 1.58;
    letter-spacing: -0.21px;
  }
  .AppointmentItem .dc-info .address .icon,
  .dc-info .dc-guardian .icon {
    margin-right: 12px;
  }
  .AppointmentItem .AppointmentItem {
    flex-wrap: wrap;
  }
  .AppointmentItem .ap-dc-timeinfo {
    width: 100%;
    margin-top: 21px;
    position: relative;
    min-height: 130px;
    padding-left: 140px;
    margin-left: 0;
  }
  .AppointmentItem .ap-dc-timeinfo .distance {
    margin-top: 18px;
    margin-bottom: 11px;
  }
  .AppointmentItem .ap-dc-timeinfo .appointment-time {
    position: absolute;
    left: 0;
    top: 0;
  }
  .AppointmentItem {
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 20px;
  }
  .AppointmentItem:first-child {
    padding-bottom: 20px;
  }
  .AppointmentItem .dc-info {
    padding-left: 21px;
    padding-top: 2px;
  }
  .AppointmentItem .start-videolink {
    margin-left: 0px;
    margin-top: 20px;
  }
}

@media (max-width: 375px) {
  .AppointmentItem .ap-dc-timeinfo {
    padding-left: 125px;
    min-height: 115px;
  }
}
