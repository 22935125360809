.PatientSignUpStepTwo .select-appointment-date {
  position: relative;
}
.PatientSignUpStepTwo .select-appointment-date .datepicker {
  position: absolute;
  z-index: 9;
  top: 80px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime .rdtPicker {
  height: 350px;
  overflow: hidden;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch {
  top: 34px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker .rdtSwitch:after {
  top: 10px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker .dow {
  text-transform: uppercase;
  font-family: 'Calibre Semibold';
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker TD {
  line-height: 1.7;
  padding-top: 5px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker TH.rdtNext,
.PatientSignUpStepTwo .select-appointment-date .MyDatetime.days .rdtPicker TH.rdtPrev {
  top: 30px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime .rdtPicker .rdtDays TABLE THEAD TR .dow {
  border-spacing: 20px 5px;
}
.PatientSignUpStepTwo .select-appointment-date .MyDatetime .rdtPicker {
  padding-left: 0;
  padding-right: 0;
}
.PatientSignUpStepTwo .login-btn {
  margin-bottom: 47px;
  margin-top: 49px;
  width: 100%;
  height: 45px;
  line-height: 45px;
}
.PatientSignUpStepTwo .login-btn .CircleButton.WhiteButton {
  width: 100%;
}
.PatientSignUpStepTwo .login-bottom {
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  text-align: center;
  color: #013648;
}
.PatientSignUpStepTwo .login-bottom A {
  color: #3dbce7;
  font-weight: 500;
}
.PatientSignUpStepTwo .password-group .form-control {
  padding-right: 35px;
}
.PatientSignUpStepTwo .password-group .view-img {
  position: absolute;
  right: 17px;
  width: 28px;
  height: 21px;
  top: 50%;
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.PatientSignUpStepTwo .ca-social .social-icon {
  display: inline-block;
  max-width: 100%;
  width: 399px;
  height: 56px;
  border-radius: 2px;
  background-color: #ffffff;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #013648;
}
.PatientSignUpStepTwo .ca-social {
  margin-bottom: 21px;
}
.PatientSignUpStepTwo .ca-social .social-icon IMG {
  margin-right: 11px;
}
.PatientSignUpStepTwo .divider-content {
  position: relative;
  margin-bottom: 21px;
}
.PatientSignUpStepTwo .divider-content .Divider .container {
  width: 100%;
}
.PatientSignUpStepTwo .divider-content > SPAN {
  height: 28px;
  font-size: 18px;
  line-height: 1.56;
  letter-spacing: -0.34px;
  color: #72787d;
  position: absolute;
  left: 45%;
  top: -13px;
  background-color: #fbfbf9;
  padding: 9px;
  display: inline-block;
}
.PatientSignUpStepTwo .Input-control.transform-input INPUT {
  border: solid 1px #b2bcc4;
  border-radius: 2px;
}
.PatientSignUpStepTwo .Input-control.transform-input INPUT:focus {
  border: solid 1px #013648;
  box-shadow: none;
}
.PatientSignUpStepTwo .password-label {
  font-size: 16px;
  line-height: 1.19;
  letter-spacing: -0.3px;
  color: #b2bcc4;
  padding-left: 24px;
  width: 322px;
  max-width: 100%;
}
.PatientSignUpStepTwo .form-group-2-colum {
  display: flex;
  margin: 0 -6px;
}
.PatientSignUpStepTwo .form-group-2-colum .Input {
  margin-left: 6px;
  margin-right: 6px;
}
.PatientSignUpStepTwo .checkbox-block.privacy {
  margin-top: 42px;
}
.PatientSignUpStepTwo .checkbox-block {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.21px;
  color: #013648;
  padding-left: 45px;
  position: relative;
  margin-bottom: 18px;
}
.PatientSignUpStepTwo .checkbox-block A {
  color: #3dbce7;
}
.PatientSignUpStepTwo .checkbox-block .Checkbox-control {
  position: absolute;
  left: 17px;
  top: -1px;
}

@media (hover: hover) {
  .PatientSignUpStepTwo .login-bottom A:hover {
    color: #013648;
  }
}

@media (max-width: 1023px) {
  .PatientSignUpStepTwo .checkbox-block {
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .PatientSignUpStepTwo .login-btn {
    margin-bottom: 37px;
  }
}

@media (max-width: 767px) {
  .PatientSignUpStepTwo .checkbox-block {
    margin-top: 25px;
    margin-bottom: 40px;
  }
  .PatientSignUpStepTwo .login-btn {
    margin-bottom: 30px;
  }
  .PatientSignUpStepTwo .checkbox-block {
    padding-left: 30px;
  }
  .PatientSignUpStepTwo .checkbox-block .Checkbox-control{
    left: 0;
  }
}
